import gql from "graphql-tag";

export const CorrectiveActionFields = gql`
  fragment CorrectiveActionFields on CorrectiveAction {
    date
    userId
    taskId
    projectId
    message

    user {
      firstName
      lastName
    }
  }
`;

export const getCorrectiveActionsQuery = gql`
  ${CorrectiveActionFields}

  query GetCorrectiveActions($taskId: ID!) {
    asAdmin {
      getTask(id: $taskId) {
        correctiveActions {
          items {
            ...CorrectiveActionFields
          }
        }
      }
    }
  }
`;

export const addCorrectiveActionMutation = gql`
  ${CorrectiveActionFields}

  mutation AddCorrectiveAction($input: CreateCorrectiveActionRequest!) {
    asAdmin {
      addCorrectiveAction(input: $input) {
        ...CorrectiveActionFields
      }
    }
  }
`;
