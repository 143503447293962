import React from "react";
import styled, { css } from "styled-components";

export const DesktopOnly = styled.div`
  @media (max-width: 1622px) {
    display: none;
  }
`;

export const DesktopOnlySpan = styled.span`
  @media (max-width: 1622px) {
    display: none;
  }
`;

export const TabletOnly = styled.div`
  @media (min-width: 1621px) {
    display: none;
  }
`;
