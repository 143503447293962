import React, { useEffect } from "react";
import { Dialog } from "../Dialog";
import "@rmwc/theme/styles";
import { DialogActions, DialogButton, DialogContent } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import Palette from "../../palette.json";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import dayjs from "dayjs";
import useMultiTenant from "hooks/useMultiTenant";
import tinyColor from "tinycolor2";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { useForm } from "react-hook-form";
import { CommonForm, FormRow } from "components/CommonForm";
import { Date, Form, Time } from "components/Form";
import { dialogQueue } from "hooks/useDialogQueue";
import get from "lodash/get";

const Header = styled.div`
  > div {
    padding: 12px;
  }
`;

const Headings = styled.div`
  margin: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: ${Palette.DarkGrey};
`;

const SubTitle = styled.h4`
  margin-top: 8px;
  line-height: 1.5;
  font-size: 16px;
  color: ${Palette.LightGrey};
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${Palette.DarkGrey};
  font-size: 15px;

  .rmwc-icon {
    color: ${Palette.LightGrey};
    margin-right: 15px;
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${Palette.LightGrey};
`;

type Props = {
  shift?: UserShiftFields;
  onDelete?: (userShiftId: string) => void;
  onUpdate?: (userShift: UserShiftFields) => void;
  onClose: () => void;
};

type FormValuesT = {
  start: string;
  end: string;
};

export const CalendarShiftDetails = ({ shift, onClose, onDelete, onUpdate }: Props) => {
  const { deleteUserShift, updateUserShift } = useAdminApi();
  const { theme } = useMultiTenant();

  const backStrokeColor = tinyColor(theme.primary);
  backStrokeColor.lighten(50).desaturate(30).toString();

  const userName = `${shift?.user.firstName} ${shift?.user.lastName}`;
  const shiftDate = dayjs(shift?.fromDt).format("MMM D, YYYY");
  const shiftTime = dayjs(shift?.fromDt).format("h:mm a") + " - " + dayjs(shift?.toDt).format("h:mm a");

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      start: dayjs(shift?.fromDt).format("HH:mm"),
      end: dayjs(shift?.toDt).format("HH:mm"),
    },
  });

  const { formState, setValue, getValues } = form;

  const onDeleteShift = async () => {
    if (!window.confirm("Are you sure you want to delete this shift?")) {
      return;
    }

    const deletedShift = await deleteUserShift({
      id: shift.id,
      userId: shift.userId,
    });

    if (onDelete) {
      onDelete(deletedShift.id);
    }

    onClose();
  };

  const onUpdateShift = async () => {
    const values = getValues();

    const start = values.start.split(":");
    const end = values.end.split(":");

    try {
      const updateShift = await updateUserShift({
        id: shift.id,
        input: {
          fromDt: dayjs(shift?.fromDt).set("hour", parseInt(start[0])).set("minute", parseInt(start[1])).toISOString(),
          toDt: dayjs(shift?.toDt).set("hour", parseInt(end[0])).set("minute", parseInt(end[1])).toISOString(),
        },
      });

      if (onUpdate) {
        onUpdate(updateShift);
      }
    } catch (error) {
      console.error(error);

      if (error.message.includes("conflict with the shift")) {
        const message = get(
          error,
          "graphQLErrors[0].message",
          get(error, "message", "An unexpected error ocurred. Please try again."),
        );

        const hasConfirmed = await dialogQueue.confirm({
          title: message,
          acceptLabel: "Force",
          cancelLabel: "Cancel",
          body: "Do you still want to force the shift to update?",
          renderToPortal: true,
        });

        if (hasConfirmed) {
          const updatedShift = await updateUserShift({
            id: shift.id,
            input: {
              fromDt: dayjs(shift?.fromDt)
                .set("hour", parseInt(start[0]))
                .set("minute", parseInt(start[1]))
                .toISOString(),
              toDt: dayjs(shift?.toDt).set("hour", parseInt(end[0])).set("minute", parseInt(end[1])).toISOString(),
              force: true,
            },
          });
          if (onUpdate) {
            onUpdate(updatedShift);
          }
        }
      }
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    setValue("start", dayjs(shift?.fromDt).format("HH:mm"));
    setValue("end", dayjs(shift?.toDt).format("HH:mm"));
  }, [shift?.fromDt]);

  return (
    <Dialog open onClose={onClose}>
      <Header>
        <Headings>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              <Title>{userName}</Title>
              <SubTitle>{shiftDate}</SubTitle>
            </div>
          </div>
        </Headings>
        <CloseButton type="button">
          <Icon icon="close" onClick={onClose} />
        </CloseButton>
      </Header>
      <DialogContent style={{ minWidth: "30vw", padding: "10px 30px" }}>
        <DetailRow>
          <Icon icon="watch_later" />
          {shiftTime}
        </DetailRow>

        <Form form={form} onSubmit={onUpdateShift}>
          <FormRow>
            <Time name="start" label="Start Time" />
            <Time name="end" label="End Time" />
          </FormRow>
        </Form>
      </DialogContent>

      <DialogActions>
        <DialogButton icon="delete" theme={"error"} onClick={onDeleteShift}></DialogButton>
        <DialogButton raised onClick={onUpdateShift}>
          Save Shift
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
