import gql from "graphql-tag";

import { ProgressFields } from "./fragments";
import { TaskFields } from "./tasks";

export const ProjectFields = gql`
  ${ProgressFields}

  fragment ProjectFields on Project {
    id

    clientId
    aircraftId
    clientId

    name
    description

    startDate
    endDate

    hourlyRate
    estimateManHrs

    status

    location {
      id
      name
      address {
        street1
        street2
        city
        state
        zip
        country
      }
      geometry {
        type
        coordinates
      }
    }

    progress {
      ...ProgressFields
    }

    users {
      items {
        ct
        role
        userId
      }
    }
  }
`;

export const getProjectAndListTasksQuery = gql`
  ${ProjectFields}
  ${TaskFields}

  query GetProjectAndListTasks($projectId: ID!, $criteria: ListTasksCriteria!, $nextToken: String) {
    asAdmin {
      getProject(projectId: $projectId) {
        ...ProjectFields
      }

      listTasks(criteria: $criteria, nextToken: $nextToken) {
        items {
          ...TaskFields
        }
        nextToken
      }
    }
  }
`;

export const getProjectQuery = gql`
  ${ProjectFields}

  query GetProject($projectId: ID!, $clientId: ID!) {
    asAdmin {
      getProject(projectId: $projectId, clientId: $clientId) {
        ...ProjectFields
      }
    }
  }
`;

export const listProjectsQuery = gql`
  ${ProjectFields}

  query ListProjects($criteria: ListProjectsCriteria = {}, $nextToken: String = null) {
    asAdmin {
      listProjects(criteria: $criteria, nextToken: $nextToken) {
        items {
          ...ProjectFields
        }
      }
    }
  }
`;

export const addProjectMutation = gql`
  ${ProjectFields}

  mutation AddProject(
    $aircraftId: String!
    $clientId: String!
    $cameraId: String
    $description: String!
    $endDate: AWSDateTime!
    $hourlyRate: Float!
    $estimateManHrs: Float
    $name: String!
    $startDate: AWSDateTime!
    $status: ProjectStatus!
    $locationId: String
  ) {
    asAdmin {
      addProject(
        input: {
          clientId: $clientId
          cameraId: $cameraId
          description: $description
          endDate: $endDate
          startDate: $startDate
          hourlyRate: $hourlyRate
          estimateManHrs: $estimateManHrs
          name: $name
          aircraftId: $aircraftId
          status: $status
          locationId: $locationId
        }
      ) {
        ...ProjectFields
      }
    }
  }
`;

export const updateProjectMutation = gql`
  ${ProjectFields}

  mutation UpdateProject(
    $id: ID!
    $clientId: ID!
    $aircraftId: String
    $cameraId: String
    $description: String
    $endDate: AWSDateTime
    $hourlyRate: Float
    $estimateManHrs: Float
    $name: String
    $startDate: AWSDateTime
    $status: ProjectStatus
    $locationId: String
    $updateMessage: String
  ) {
    asAdmin {
      updateProject(
        clientId: $clientId
        id: $id
        input: {
          name: $name
          startDate: $startDate
          hourlyRate: $hourlyRate
          estimateManHrs: $estimateManHrs
          endDate: $endDate
          description: $description
          aircraftId: $aircraftId
          cameraId: $cameraId
          status: $status
          locationId: $locationId
          updateMessage: $updateMessage
        }
      ) {
        ...ProjectFields
      }
    }
  }
`;

export const deleteProjectMutation = gql`
  mutation DeleteProject($clientId: ID!, $id: ID!) {
    asAdmin {
      deleteProject(clientId: $clientId, id: $id) {
        id
      }
    }
  }
`;

export const addProjectUserMutation = gql`
  mutation AddProjectUser($input: CreateProjectUserRequest!) {
    asAdmin {
      addProjectUser(input: $input) {
        projectId
        userId
        role
        clientId
        ct
      }
    }
  }
`;

export const removeProjectUserMutation = gql`
  mutation RemoveProjectUser($projectId: ID!, $userId: ID!) {
    asAdmin {
      removeProjectUser(projectId: $projectId, userId: $userId) {
        projectId
        userId
      }
    }
  }
`;

export const addProjectFromTemplateMutation = gql`
  ${ProjectFields}

  mutation AddProjectFromTemplate($templateId: ID!, $copyUsers: Boolean, $input: CreateProjectRequest!) {
    asAdmin {
      addProjectFromTemplate(templateId: $templateId, copyUsers: $copyUsers, input: $input) {
        ...ProjectFields
      }
    }
  }
`;

export const completeProjectMutation = gql`
  mutation CompleteProject($projectId: ID!) {
    asAdmin {
      completeProject(id: $projectId) {
        id
        users {
          items {
            role
            userId
          }
        }
      }
    }
  }
`;
