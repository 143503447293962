import React, { useState } from "react";

import { useHistory } from "react-router";

import { ClientFields } from "types/admin/ClientFields";

import { useAdminApi } from "hooks/useAdminApi";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import useFiltered from "hooks/useFiltered";
import { usePagenator } from "hooks/usePagenator";

import { Button } from "components/Button";
import { SearchInput } from "../TextInput";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";

import { PagenatorControls } from "../PagenatorControls";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";
import EmptyState from "../EmptyState";
import { showErrorMessage } from "../../utilities/handleError";
import { useLocation } from "react-router-dom";

interface ClientListProps {
  items: ClientFields[];
}

export const ClientList: React.FC<ClientListProps> = ({ items }) => {
  const history = useHistory();
  const location = useLocation();
  const { deleteClient } = useAdminApi();

  const [search, setSearch] = useState<string>("");
  const filtered = useFiltered(items, (it) => !!it.name?.toLowerCase().includes(search.toLowerCase()));

  const { pageRange, ...controls } = usePagenator(8, filtered.length);

  const onDelete = async (client: ClientFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete customer: ${client.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    try {
      await deleteClient({ id: client.id });
    } catch (err) {
      const error = err as Error;
      showErrorMessage(error);
      return;
    }
    snackbar.notify({ title: "Customer removed successfully!" });
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Active Customers <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search by Name"
          data-test-id="search"
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />

        <Button data-test-id="btn-create" raised onClick={() => history.push(`/clients/new`, { referrer: "/clients" })}>
          Add Customer
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["Name", "Type", "Address", ""]} />

          <DataTableBody data-test-id="clients-list">
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={7}>
                  <EmptyState
                    icon="contact_page"
                    text={"No customers found. Add first client."}
                    buttonText={"New Customer"}
                    onClick={() => history.push(`/clients/new`, { referrer: "/clients" })}
                  />
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => {
              const address = `${it.address?.street1 ? `${it.address.street1} ` : ""}${
                it.address?.street2 ? `${it.address.street2} ` : ""
              }${it.address?.city ? `${it.address.city}, ` : ""}${it.address?.state ? `${it.address.state} ` : ""}${
                it.address?.zip ? `${it.address.zip} ` : ""
              }`;
              return (
                <DataTableRow key={i} onClick={() => history.push(`/clients/${it.id}`)}>
                  <DataTableCell>{it.name}</DataTableCell>
                  <DataTableCell>{it.clientType}</DataTableCell>
                  <DataTableCell>{address}</DataTableCell>
                  <DataTableCell>
                    <DataCellTools>
                      <IconButton
                        data-test-id="btn-edit"
                        icon="edit"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          history.push(`${location.pathname}/${it.id}/edit_`);
                        }}
                      />
                      <IconButton
                        data-test-id="btn-delete"
                        icon="delete"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onDelete(it);
                        }}
                      />
                    </DataCellTools>
                  </DataTableCell>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
