import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProject } from "hooks/params/useProject";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import dayjs from "dayjs";
import { TASK_STATUS_BACKGROUNDS } from "components/StatusLabel";
import Palette from "../../palette.json";
import { TaskStatus } from "types/admin/globalTypes";
import { formatHours } from "../../common/index";
import FullCalendar, { EventApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Container = styled.div`
  grid-column: 1 / -1;
  padding: 0;
  width: 100%;
  position: relative;
  min-height: 150px;
  max-height: 280px;

  .fc-event {
    padding: 5px 3px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
  }
  .fc-button-primary {
    background-color: ${Palette.LightestGrey};
    color: black;
    border: none;
  }

  th.fc-day-today {
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
  }

  .fc-col-header-cell-cushion {
    padding: 8px 0;
  }

  .fc-toolbar-chunk div {
    display: flex;
    align-items: center;

    h2 {
      font-size: 16px;
      padding-left: 16px;
    }
  }
`;

export const ProjectTimelineCalendar: React.FC<{ [key: string]: any }> = ({ ...props }) => {
  // Hooks
  const location = useLocation();
  const project = useProject();
  const { tasks } = useAdminApi();
  const history = useHistory();
  const projectTasks = tasks
    .filter((it) => it.projectId === project?.id && it.parentId === project.id && it.status !== TaskStatus.Deleted)
    .filter((it) => !!it.scheduledDate && !!it.dueDate)
    .sort((a, b) => dayjs(a.scheduledDate).diff(dayjs(b.scheduledDate)));

  const calendarRef = React.useRef<FullCalendar>(null);
  const initialDate = React.useMemo(() => dayjs().format("YYYY-MM-DD"), [])

  const events = projectTasks.map((it) => ({
    title: `${it.name} \u00a0\u00a0\u00a0\u00a0`,
    start: dayjs(it.scheduledDate).tz("UTC", true).format("YYYY-MM-DD"),
    end: dayjs(it.dueDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
    allDay: true,
    color: TASK_STATUS_BACKGROUNDS[it.status],
    extendedProps: {
      id: it.id,
      hoursLabel: `${formatHours(it.progress.completedHours)} /
    ${formatHours(it.estimateManHrs)} hrs`,
    },
  }));

  // UI
  if (!project) return <></>;

  return (
    <Container>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridWeek"
        initialDate={initialDate}
        events={events}
        height={300}
        visibleRange={{
          start: dayjs(project.startDate).format("YYYY-MM-DD"),
          end: dayjs(project.endDate).format("YYYY-MM-DD"),
        }}
        headerToolbar={{
          left: "prev,next,title",
          right: "",
        }}
        eventClick={({ event }) => {
          history.push(`${location.pathname}/${event.extendedProps.id}`);
        }}
        eventContent={({ event }) => {
          return PartCalendarCell(event);
        }}
        ref={calendarRef}
      />
    </Container>
  );
};

const PartCalendarCell = (event: EventApi) => {
  const HoursLabel = styled.span`
    opacity: 0.8;
    font-weight: 400;
  `;
  return (
    <div
      className="fc-event-main-frame"
      style={{
        display: "flex",
        alignItems: "center",
        overflowX: "clip",
        width: "100%",
      }}
    >
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">
          {event._def.title} <HoursLabel>{event._def.extendedProps.hoursLabel}</HoursLabel>
        </div>
      </div>
    </div>
  );
};
