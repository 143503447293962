import axios from "axios";
import { fileTypeFromMimeType } from "components/attachments/AttachmentFormDialog";
import { Button } from "components/Button";
import useMultiTenant from "hooks/useMultiTenant";
import { useQuery } from "hooks/useQuery";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import { EntityType } from "types/admin/globalTypes";
import { showErrorMessage } from "../../../utilities/handleError";
import "../../../stylesheets/signature-canvas.css";
import { LoadingAnonCoverPage } from "components/LoadingAnonCoverPage";
import { InvalidLinkPage } from "components/signatures/InvalidLinkPage";
import dayjs from "dayjs";
import { SuccessfulSignatureSubmission } from "components/signatures/SuccessfulSignatureSubmission";
import { PinEntryForm } from "components/PinEntryForm";
import Palette from "../../../palette.json";

export const ScreenWrapper = styled.div`
  display: flex;
  height: 100vh;
  position: fixed;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const CanvasWrapper = styled.div<{ [props: string]: any }>`
  position: relative;
  display: block;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
`;

const SignatureLine = styled.div`
  position: absolute;
  bottom: 30%;
  left: 0px;
  right: 0px;
  margin: 0 10%;
  border-bottom: 2px dashed ${Palette.LighterGrey};
  z-index: 9;
`;

const ControlsWrapper = styled.div<{ [props: string]: any }>`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Email = styled.div`
  margin-bottom: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const ButtonsWrapper = styled.div<{ [props: string]: any }>`
  display: flex;
  z-index: 11;

  > * + * {
    margin-left: 20px;
  }
`;

export const SignatureCapturePage: React.FC = () => {
  const { getUserSecretLinkKey, getUploadUrl, updateUserSignature, getUserEmail, tenantId } = useMultiTenant();
  const localKey = useQuery().get("sessionKey");
  const { userId } = useParams<{ userId: string }>();
  const [width, height] = useWindowSize();
  const [userEmail, setUserEmail] = React.useState<string | undefined>(undefined);
  const [loadingPage, setLoadingPage] = React.useState(true);
  const [signatureString, setSignatureString] = React.useState<string | undefined>(undefined);
  const [validSignature, setValidSignature] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const [submittingSignature, setSubmittingSignature] = React.useState(false);
  const [successfullySubmittedLink, setSuccessfullySubmittedLink] = React.useState(false);
  const [invalidLink, setInvalidLink] = React.useState(false);
  const [expiredLink, setExpiredLink] = React.useState(false);

  const sigRef = React.useRef<SignatureCanvas>(null);

  function dataURLtoBlob(dataurl: string) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const onSubmit = async (values: any) => {
    if (!signatureString) return;
    const pin = values.pin;
    if (!pin) return;

    setSubmittingSignature(true);

    const dataURL = signatureString;
    const file = dataURLtoBlob(dataURL);

    try {
      const upload = await getUploadUrl("image/png");

      axios.put(upload.url, file, {
        headers: { "content-type": file.type },
        onUploadProgress: (ev: ProgressEvent) => {
          console.log("✅", ev.loaded);
        },
      });

      await updateUserSignature(userId, localKey, pin, {
        id: upload.attachmentId,
        entityId: userId,
        entityType: EntityType.USER,
        fileName: "signature.png",
        fileType: fileTypeFromMimeType(file.type),
        mimeType: file.type,
        file: {
          bucket: upload.bucket,
          key: upload.key,
          region: upload.region,
        },
      });

      onClear();
      setSuccessfullySubmittedLink(true);
    } catch (error) {
      console.log(error);
      showErrorMessage(error);
    } finally {
      setSubmittingSignature(false);
    }
  };

  const onEnd = () => {
    if (sigRef.current?.isEmpty()) {
      setValidSignature(false);
      return;
    }

    setValidSignature(true);
  };

  const onClear = () => {
    sigRef.current?.clear();
    setValidSignature(false);
  };

  const onNextStep = () => {
    if (sigRef.current?.isEmpty()) return;
    setSignatureString(sigRef.current?.getTrimmedCanvas().toDataURL());

    setShowPin(true);
  };

  useEffect(() => {
    if (!localKey || !tenantId) {
      setInvalidLink(true);
      setLoadingPage(false);
      return;
    }
    getUserSecretLinkKey(userId).then((serverKey) => {
      setLoadingPage(false);
      if (localKey !== serverKey) {
        setInvalidLink(true);
      }

      const expirationDateString = serverKey.split(" ")[1];
      if (expirationDateString) {
        if (dayjs(expirationDateString).isBefore(dayjs())) {
          setExpiredLink(true);
        }
      } else {
        setInvalidLink(true);
      }
    });
  }, [localKey, tenantId]);

  useEffect(() => {
    getUserEmail(userId).then((email) => setUserEmail(email));
  }, [userId]);

  return (
    <div style={{ height: "100vh" }}>
      {loadingPage ? (
        <LoadingAnonCoverPage />
      ) : successfullySubmittedLink ? (
        <SuccessfulSignatureSubmission />
      ) : invalidLink || expiredLink ? (
        <InvalidLinkPage expired={expiredLink} />
      ) : showPin ? (
        <PinEntryForm submitting={submittingSignature} onSubmit={onSubmit} />
      ) : (
        <ScreenWrapper>
          <div className="signature-hint" style={{ zIndex: 100, textAlign: "center" }}>
            Rotate device on its side to sign.
          </div>

          <CanvasWrapper className={width / height > 3 ? "heightIsSmallestDimension" : "widthIsSmallestDimension"}>
            <SignatureLine className="signature-line" />

            <SignatureCanvas
              minWidth={Math.max(window.devicePixelRatio || 1, 1) * 0.75}
              penColor="#000"
              onEnd={onEnd}
              ref={sigRef}
              maxWidth={Math.max(window.devicePixelRatio || 1, 1) * 3}
              canvasProps={{ className: `signature-canvas ${submittingSignature ? "disabled" : ""}` }}
            />

            <ControlsWrapper className="signature-buttons">
              {userEmail && (
                <Email>
                  Signature for:
                  <br />
                  <div style={{ fontWeight: 500, marginTop: "5px" }}>{userEmail}</div>
                </Email>
              )}
              <ButtonsWrapper>
                <Button disabled={submittingSignature} outlined onClick={onClear}>
                  Clear
                </Button>
                <Button disabled={submittingSignature || !validSignature} raised onClick={onNextStep}>
                  Next
                </Button>
              </ButtonsWrapper>
            </ControlsWrapper>
          </CanvasWrapper>
        </ScreenWrapper>
      )}
    </div>
  );
};
