import React from "react";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

import { snackbar } from "hooks/useNotifications";

import { Label } from "../Label";
import { TextArea } from "../TextArea";

import { CommonForm, FormField, FormRow } from "../CommonForm";
import { Dialog } from "../Dialog";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

export interface FormValuesT {
  id?: string;

  projectId: string;
  taskId: string;

  isClientVisible: boolean;
  isTechnicianVisible: boolean;

  message: string;
}

export interface MessageFormDialogPropsT {
  action: "create" | "update";
}

const HIDDEN_FIELDS = ["projectId", "taskId", "isClientVisible", "isTechnicianVisible"];

export const MessageFormDialog: React.FC<MessageFormDialogPropsT> = ({ action }) => {
  const history = useHistory();

  const { addMessage } = useAdminApi();

  const project = useProject();
  const { task } = useTask();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      projectId: project?.id,
      taskId: task?.id,
      message: "",
      isClientVisible: false,
      isTechnicianVisible: true,
    },
  });
  const { formState, register } = form;
  const { errors } = formState;

  const title = action === "create" ? "New Message" : "Update Message";

  const onSubmit = form.handleSubmit(async (values) => {
    console.info(values);
    await addMessage(values);

    snackbar.notify({ title: "Message sent successfully!" });
    history.goBack();
  });

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm onSubmit={onSubmit}>
          {HIDDEN_FIELDS.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          <FormRow>
            <FormField>
              <Label htmlFor="message">Message</Label>
              <TextArea style={{ margin: 0 }} name="message" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Checkbox name="isClientVisible" inputRef={(e) => register(e)}>
                Customer Visible
              </Checkbox>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <Checkbox name="isTechnicianVisible" inputRef={(e) => register(e)}>
                Technician Visible
              </Checkbox>
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <DialogButton dense onClick={history.goBack}>
          Cancel
        </DialogButton>

        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit} disabled={Object.keys(errors).length !== 0}>
          Send
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
