import React, { useEffect, useState } from "react";
import { useAdminApi } from "hooks/useAdminApi";
import { PageContent, PageMain } from "components/PageStyles";
import styled from "styled-components";
import { useHistory } from "react-router";
import useLayout from "hooks/useLayout";
import { SigningList } from "components/signatures/SigningList";
import { PageHeader } from "components/PageHeader";
import { TabContainer } from "components/TabContainer";
import { Tab, TabBar } from "@rmwc/tabs";
import "@rmwc/tabs/styles";
import { Route, Switch, useLocation } from "react-router-dom";
import { SignatureFields } from "types/admin/SignatureFields";
import { PasswordResetPage } from "components/profile/PasswordResetPage";

export const ProfilePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { me, listUserSignatures } = useAdminApi();
  const { setBreadCrumbs } = useLayout();
  const [signatures, setSignatures] = useState<SignatureFields[]>([]);
  const [signaturesNextToken, setSignaturesNextToken] = useState<string | null>(null);

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Profile",
        path: "/profile",
      },
    ]);
  }, [setBreadCrumbs]);

  const PAGE_TABS = [
    {
      icon: "lock",
      label: "Password",
      path: `/profile/password`,
    },
    {
      icon: "history_edu",
      label: "Signatures",
      path: `/profile/signatures`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => it.path.startsWith("/profile/signatures")),
  );

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path)) return;
    history.replace(path);
  };

  const loadMoreSignatures = async () => {
    if (!signaturesNextToken) return;
    listUserSignatures(signaturesNextToken).then((res) => {
      setSignatures([...signatures, ...res.items]);
      setSignaturesNextToken(res.nextToken);
    });
  };

  useEffect(() => {
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(
      tabIndex === -1 ? PAGE_TABS.findIndex((it) => it.path.startsWith("/profile/signatures")) : tabIndex,
    );
  }, [location, PAGE_TABS]);

  useEffect(() => {
    listUserSignatures().then((res) => {
      setSignatures(res.items);
      setSignaturesNextToken(res.nextToken);
    });
  }, []);

  return (
    <>
      <PageHeader title={`Hello, ${me?.firstName}`} />

      <TabContainer>
        <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            <Route path="/profile/password">
              <PasswordResetPage />
            </Route>

            <Route>
              <SigningList signatures={signatures} loadMore={loadMoreSignatures} nextToken={signaturesNextToken} />
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
