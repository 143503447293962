import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Icon } from "@rmwc/icon";
import { ThemeProvider } from "@rmwc/theme";
import { Button } from "components/Button";
import { useAdminApi } from "hooks/useAdminApi";
import useMultiTenant from "hooks/useMultiTenant";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Palette from "../palette.json";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.background};
`;

const IFrame = styled.iframe<{ show?: boolean }>`
  width: 100%;
  height: 300px;
  transition: height 0.5s;
  pointer-events: auto;
  border: 1px solid lightgray;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;

  > * + * {
    margin-left: 1rem;
  }
`;

export const TermsAndConditionsAgreementPage: React.FC = () => {
  const { acceptTermsAndConditions } = useAdminApi();

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(true);

  const showTerms = () => {
    setShowPrivacyPolicy(false);
    setShowTermsAndConditions(true);
  };

  const showPrivacy = () => {
    setShowPrivacyPolicy(true);
    setShowTermsAndConditions(false);
  };

  return (
    <Root>
      <Dialog open preventOutsideDismiss>
        <DialogTitle>User Agreement</DialogTitle>

        <DialogContent>
          <p>Please accept the terms and conditions to continue. You must only accept this once per tenant.</p>
          <br />
          {showPrivacyPolicy && (
            <IFrame
              show={showPrivacyPolicy}
              src="https://www.foxtrotinsight.com/privacy-policy.html"
              title="Privacy Policy"
            />
          )}

          {showTermsAndConditions && (
            <IFrame
              show={showTermsAndConditions}
              src="https://www.foxtrotinsight.com/terms-and-conditions.html"
              title="Terms and Conditions"
            />
          )}

          <ButtonWrapper>
            <ThemeProvider options={{ primary: Palette.MediumGrey }}>
              <Button raised={showTermsAndConditions} onClick={showTerms}>
                Terms and Conditions
              </Button>
              <Button raised={showPrivacyPolicy} onClick={showPrivacy}>
                Privacy Policy
              </Button>
            </ThemeProvider>
          </ButtonWrapper>

          <DialogActions>
            <DialogButton
              raised
              onClick={acceptTermsAndConditions}
              icon={<Icon icon="check" />}
              data-test-id="accept-terms-and-conditions"
            >
              Accept
            </DialogButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Root>
  );
};
