import dayjs from "dayjs";
import { useMemo } from "react";
import { SortableColumnT } from "components/Table";

export interface SortFunction<T> {
  (a: T, b: T): number;
}

// * currently this is only used in the ProjectList component
// todo - 😬 this is gonna be a huge mess if we do any other tables...
export const sortByColumn = (column?: SortableColumnT) => (a: any, b: any) => {
  if (column?.label === "Due Date") {
    if (column?.sortDir === 1) {
      return dayjs(a.endDate).diff(dayjs(b.endDate));
    } else if (column?.sortDir === -1) {
      return dayjs(b.endDate).diff(dayjs(a.endDate));
    }
  }

  if (column?.label === "Customer") {
    if (column?.sortDir === 1) {
      return a.clientId?.localeCompare(b.clientId);
    } else if (column?.sortDir === -1) {
      return b.clientId?.localeCompare(a.clientId);
    }
  }

  if (column?.label === "Project Name") {
    if (column?.sortDir === 1) {
      return a.name.localeCompare(b.name);
    } else if (column?.sortDir === -1) {
      return b.name.localeCompare(a.name);
    }
  }

  if (column?.label === "Progress") {
    if (column?.sortDir === 1) {
      return a.progress?.progressTimePercentage - b.progress?.progressTimePercentage;
    } else if (column?.sortDir === -1) {
      return b.progress?.progressTimePercentage - a.progress?.progressTimePercentage;
    }
  }

  if (column?.label === "Hours") {
    if (column?.sortDir === 1) {
      return a.progress?.completedHours - b.progress?.completedHours;
    } else if (column?.sortDir === -1) {
      return b.progress?.completedHours - a.progress?.completedHours;
    }
  }

  if (column?.label === "Status") {
    if (column?.sortDir === 1) {
      return a.status.localeCompare(b.status);
    } else if (column?.sortDir === -1) {
      return b.status.localeCompare(a.status);
    }
  }
  return 1;
};
export const sortByProperty =
  <T>(prop: string) =>
  (a: T, b: T) => {
    if ((a as any)[prop] < (b as any)[prop]) return -1;
    if ((a as any)[prop] > (b as any)[prop]) return 1;
    return 0;
  };

const useSorted = <T>(source: T[], sortBy: SortFunction<T>) => {
  return useMemo(() => {
    return source.sort(sortBy);
  }, [source, sortBy]);
};

export default useSorted;
