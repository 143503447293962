import { useParams } from "react-router";
import { PartType } from "types/admin/globalTypes";
import { PartFields } from "types/admin/PartFields";
import { useAdminApi } from "../useAdminApi";
import { useAircraft } from "./useAircraft";
import { useTask } from "./useTask";
import { useTemplate } from "./useTemplate";

interface ParamsT {
  partId: string;
  subPartId: string;
  subSubPartId: string;
  taskId: string;
  templateId: string;
  aircraftId: string;
}

export const usePart = () => {
  const { partId, subPartId, subSubPartId, taskId, templateId, aircraftId } = useParams<ParamsT>();
  const { task } = useTask();
  const { entity: aircraft } = useAircraft();
  const { template, isTemplateTypeTask } = useTemplate();
  const { parts: baseParts } = useAdminApi();

  let currentPart: PartFields | undefined = undefined;
  let part: PartFields | undefined = undefined;
  let subPart: PartFields | undefined = undefined;
  let subSubPart: PartFields | undefined = undefined;
  let parts: PartFields[] = baseParts;
  let parentPart: PartFields | undefined = undefined;

  if (aircraftId && aircraft) {
    parts = aircraft.parts?.items || [];
  }

  if (templateId && template && isTemplateTypeTask) {
    parts = template.parts?.items || [];
  }

  if (taskId && task) {
    parts = (task.taskParts?.items || []) as PartFields[];
  }

  if (partId) {
    part = parts.find((it) => it.id === partId);
    currentPart = part;
    parentPart = part?.parentPart as PartFields;
  }
  if (subPartId) {
    subPart = currentPart?.parts?.items
      ? (currentPart.parts.items as PartFields[]).find((it) => it.id === subPartId)
      : undefined;
    currentPart = subPart;
    parentPart = subPart.parentPart as PartFields;
  }
  if (subSubPartId) {
    subSubPart = currentPart?.parts?.items
      ? (currentPart.parts.items as PartFields[]).find((it) => it.id === subSubPartId)
      : undefined;
    currentPart = subSubPart;
    parentPart = subSubPart.parentPart as PartFields;
  }

  parts = currentPart?.parts?.items ? (currentPart?.parts?.items as PartFields[]) : parts ? parts : [];

  return {
    currentPart,
    parentPart,
    allParts: baseParts,
    parts,
    part,
    subPart,
    subSubPart,
    partId,
    subPartId,
    subSubPartId,
    isRotable: currentPart?.type === PartType.Rotable,
    isExpendable: currentPart?.type === PartType.Expendable,
    isConsumable: currentPart?.type === PartType.Consumable,
    isAssembly: currentPart?.type === PartType.Assembly,
  };
};
