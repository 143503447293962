import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useProject } from "hooks/params/useProject";
import { Table, TableHead, TitleCount } from "components/Table";
import { StatusBlock } from "components/StatusBlock";
import styled from "styled-components";
import { Button } from "components/Button";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { useAdminApi } from "hooks/useAdminApi";
import { TaskStatus } from "types/admin/globalTypes";
import { CircularProgress } from "@rmwc/circular-progress";

// Styled Components
const Container = styled.div`
  padding: 10px 12px 0px;
  margin-left: 14px;
`;

const SpacedBetween = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const cols = ["Name", "Time Clocked / Estimated"];

export const PeopleProgressList: React.FC = () => {
  // Hooks
  const history = useHistory();
  const { users: allUsers, tasks, listProjectTimesheets } = useAdminApi();
  const project = useProject();

  const projectTasks = tasks.filter((task) => task.projectId === project.id && task.status !== TaskStatus.Deleted);
  const totalEstHours = projectTasks.reduce((acc, task) => acc + (task.estimateManHrs || 0), 0);

  // State
  const [usersProgress, setUsersProgress] = React.useState<any | null>(
    project.users?.items.map((it) => {
      return { progressHours: 0, ...allUsers.find((u) => u.id === it.userId) };
    }),
  );
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (allUsers.length === 0 || !project) return;

    listProjectTimesheets(project.id).then((timesheets) => {
      const userTimesheets: { [userId: string]: number } = {};
      timesheets.forEach((it) => {
        if (!userTimesheets[it.userId]) {
          userTimesheets[it.userId] = 0;
        }
        userTimesheets[it.userId] += it.amountMins / 60;
      });

      setUsersProgress(
        project.users?.items.map((it) => {
          return { progressHours: userTimesheets[it.userId] || 0, ...allUsers.find((u) => u.id === it.userId) };
        }),
      );

      setLoading(false);
    });
  }, [project, allUsers]);

  // UI
  return (
    <>
      <StatusBlock padding={false} style={{ width: "100%", backgroundColor: "white", paddingLeft: 0, paddingRight: 0 }}>
        <Container>
          <SpacedBetween style={{ marginBottom: "20px" }}>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <Title>People</Title> &nbsp;
              <TitleCount>({project.users?.items.length || 0})</TitleCount>
            </div>

            <Button raised onClick={() => history.push("people")}>
              Manage
            </Button>
          </SpacedBetween>
        </Container>

        <Table borderless="true">
          <DataTableContent>
            <TableHead {...{ cols }} />

            <DataTableBody data-test-id="people-progress-table">
              {usersProgress.map((it, i) => {
                const totalEstUserHours = tasks.reduce((acc, task) => {
                  if (task.users?.items?.find((u) => u.userId === it.id)) {
                    return acc + (task.estimateManHrs || 0);
                  }

                  return acc;
                }, 0);
                return (
                  <DataTableRow key={i}>
                    <DataTableCell>{it.name || `${it.firstName || ""} ${it.lastName || ""}`}</DataTableCell>
                    <DataTableCell data-test-id="progress-bar">
                      {loading ? (
                        <CircularProgress
                          className="loadingIndicator"
                          style={{ marginRight: "0.5rem" }}
                          {...{ progress: 0.25 }}
                        />
                      ) : (
                        <PeopleProgressListProgressBar
                          totalHours={totalEstUserHours}
                          completedHours={it.progressHours}
                        />
                      )}
                    </DataTableCell>
                  </DataTableRow>
                );
              })}
            </DataTableBody>
          </DataTableContent>
        </Table>
      </StatusBlock>

      <style>{`
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .loadingIndicator {
          animation: spin 1s linear infinite;
        }
      `}</style>
    </>
  );
};

export const PeopleProgressListProgressBar: React.FC<{ completedHours: number; totalHours: number }> = ({
  completedHours,
  totalHours,
}) => {
  // Styled Components
  const ProgressBarBackground = styled.div`
    display: flex;
    position: relative;
    padding: 4px 8px;
    background-color: #eff5ff;
    color: white;
    font-weight: 400;
    border-radius: 4px;
    font-size: 14px;
    overflow: hidden;
    z-index: 1;
  `;

  const ProgressBarProgress = styled.div<{ percentage: string }>`
    z-index: 2;
    position: absolute;
    background-color: #2d3142;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => props.percentage}%;
  `;

  const Bold = styled.span`
    font-weight: 500;
  `;

  const formatHours = (hours: number) => {
    const oneDecimal = hours.toFixed(1);
    if (oneDecimal.endsWith(".0")) {
      return `${hours.toFixed(0)}`;
    }
    return oneDecimal;
  };

  const formattedPercentage = (completedHours: number, totalHours: number) => {
    if (totalHours <= 0) {
      return "0";
    }

    return `${(completedHours / totalHours) * 100}`;
  };

  return (
    <div>
      <ProgressBarBackground>
        <ProgressBarProgress percentage={formattedPercentage(completedHours, totalHours)} />
        <div style={{ zIndex: 3, mixBlendMode: "difference" }}>
          <Bold>{formatHours(completedHours)}</Bold> &nbsp;of&nbsp; <Bold>{formatHours(totalHours)}</Bold> &nbsp;hours
        </div>
      </ProgressBarBackground>
    </div>
  );
};
