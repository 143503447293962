import React from "react";
import RbrAviationLogo from "../assets/images/foxtrot-insight-logo.png";
import { PageContent, PageMain } from "components/PageStyles";
import styled from "styled-components";
import { Button } from "components/Button";

const ErrorContainer = styled.div`
  max-width: 500px;
  text-align: center;
  margin: auto;
  margin-top: 10vh;

  > h2 {
    font-size: 24px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #555;
  }

  > h3 {
    font-size: 18px;
    margin-bottom: 2rem;
    color: #777;
  }
`;

export const TenantError: React.FC<{ isInvalid?: boolean }> = ({ isInvalid }) => {
  const ErrorContainer = styled.div`
    max-width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 10vh;

    > h2 {
      font-size: 24px;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-weight: bold;
      color: #555;
    }

    > h3 {
      font-size: 18px;
      margin-bottom: 2rem;
      color: #777;
    }
  `;

  return (
    <PageMain>
      <PageContent>
        <ErrorContainer>
          <img src={RbrAviationLogo} width="150" />
          {isInvalid ? (
            <>
              <h2>Invalid Tenant</h2>
              <h3>Confirm that you typed the URL correctly</h3>
              {/* TODO: Change to .net ? */}
              <a href="https://foxtrotinsight.net/">Go to FoxTrotInsight.net</a>
            </>
          ) : (
            <>
              <h2>Error Loading Dashboard</h2>
              <h3>Try again shortly</h3>
              <Button raised onClick={() => window.location.reload()}>
                Try Again
              </Button>
            </>
          )}
        </ErrorContainer>
      </PageContent>
    </PageMain>
  );
};
