import React from "react";

import { useFieldArray } from "react-hook-form";

import { Button } from "../Button";
import { Label } from "../Label";
import { TextInput } from "../TextInput";
import { FormField, FormRow, MultiItemSection, SectionTitle, AddItemContainer } from "../CommonForm";

import { TaskFormContentProps } from "./TaskFormDialog";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import styled from "styled-components";

import Palette from "../../palette.json";
import { useTask } from "hooks/params/useTask";

const FieldArrayTools = styled.div`
  display: flex;

  > .rmwc-icon {
    padding: 4px;
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
`;

export const TaskFormTodoContent: React.FC<TaskFormContentProps> = ({ form, style }) => {
  const { task } = useTask();
  const { control, errors, register } = form;
  const { fields, append, remove, move } = useFieldArray({ name: "todos", control, keyName: "fId" });

  const onAddClick = () => {
    append({ origId: "new", name: "" });
  };

  const moveUp = (i: number) => {
    if (i === 0) return;
    move(i, i - 1);
  };

  const moveDown = (i: number) => {
    if (i === fields.length - 1) return;
    move(i, i + 1);
  };

  return (
    <div {...{ style }}>
      <MultiItemSection>
        <SectionTitle>Todo</SectionTitle>

        {fields.map((field, i) => (
          <FormRow key={field.fId} style={{ marginBottom: "16px" }}>
            <input
              type="hidden"
              name={`todos.${i}.origId`}
              defaultValue={field.origId}
              ref={register({ required: true })}
            />

            <FormField style={{ marginRight: "8px" }}>
              <Label htmlFor={`todos.${i}.name`}>Name</Label>
              <TextInput
                name={`todos.${i}.name`}
                defaultValue={field.name}
                disabled={!!task?.signingStatus}
                ref={register({ required: { value: true, message: `Todo #${i + 1} name is required.` } })}
                isInvalid={`todos.${i}.name` in errors}
                style={{ width: "calc(100% - 24px)" }}
              />
            </FormField>

            <FormField>
              <Label>&nbsp;</Label>
              <FieldArrayTools style={{ display: "flex" }}>
                <IconButton type="button" icon="close" style={{ color: Palette.LightGrey }} onClick={() => remove(i)} />
                <IconButton
                  type="button"
                  disabled={!!task?.signingStatus}
                  icon="keyboard_arrow_up"
                  style={{ color: Palette.LightGrey }}
                  onClick={() => moveUp(i)}
                />
                <IconButton
                  type="button"
                  disabled={!!task?.signingStatus}
                  icon="keyboard_arrow_down"
                  style={{ color: Palette.LightGrey }}
                  onClick={() => moveDown(i)}
                />
              </FieldArrayTools>
            </FormField>
          </FormRow>
        ))}

        <AddItemContainer style={{ marginTop: "16px" }}>
          <Button icon="add_circle" type="button" disabled={!!task?.signingStatus} onClick={onAddClick}>
            Add Todo
          </Button>
        </AddItemContainer>
      </MultiItemSection>
    </div>
  );
};
