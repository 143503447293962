import React from "react";
import { PageHeader } from "components/PageHeader";

import { PageContent, PageMain } from "components/PageStyles";

import { TimesheetTable } from "components/timesheets/TimesheetTable";

export const TimesheetsPage: React.FC = () => {
  return (
    <>
      <PageHeader title={"Timesheets"} />

      <PageMain>
        <PageContent>
          <TimesheetTable filterType="InHangar" filterId="InHangar" />
        </PageContent>
      </PageMain>
    </>
  );
};
