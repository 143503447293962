import React from "react";

import { Label } from "../Label";
import { TextInput } from "../TextInput";
import { FormField, FormRow } from "../CommonForm";
import { TodoFormContentProps } from "./TodoFormDialog";

import "@rmwc/menu/styles";
import { useTemplate } from "hooks/params/useTemplate";
import { Text } from "../Form";

export const TodoFormInfoContent: React.FC<TodoFormContentProps> = ({ form, style }) => {
  const { template } = useTemplate();
  const isTemplate = !!template;

  const { formState, register } = form;
  const { errors } = formState;

  return (
    <div {...{ style }}>
      {!isTemplate && (
        <FormRow>
          <FormField style={{ flexGrow: 0 }}>
            <Label htmlFor="projectName">Project</Label>
            <TextInput disabled name="projectName" isInvalid={"projectName" in errors} ref={register} />
          </FormField>

          <FormField>
            <Label htmlFor="parentName">Parent Task</Label>
            <TextInput disabled name="parentName" isInvalid={"parentName" in errors} ref={register} />
          </FormField>
        </FormRow>
      )}

      <FormRow>
        <Text name="name" label="Todo Name" required />
      </FormRow>
    </div>
  );
};
