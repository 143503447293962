import jsPDF from "jspdf";
import dayjs from "dayjs";
import autoTable from "jspdf-autotable";
import { useAdminApi } from "hooks/useAdminApi";
import { TaskFields } from "types/admin/TaskFields";
import { ProjectFields } from "types/admin/ProjectFields";
import { SigningEntityType } from "types/admin/globalTypes";
import { downloadPDF, toDataURL } from "common";
import { PDFDocument } from "pdf-lib";
import { useCorrectiveActions } from "./useCorrectiveActions";

export default function useLogBookSticker() {
  const { getCorrectiveActions } = useCorrectiveActions();
  const { clients, entities, users, listTaskSignatures } = useAdminApi();

  const generate = async (task: TaskFields, project: ProjectFields, data?: any, download = true) => {
    const client = data ? data.client : clients.find((client) => client.id === project?.clientId);
    const entity = data ? data.entity : entities.find((entity) => entity.id === project?.aircraftId);
    const requestingManager = data ? data.requestingManager : users.find((user) => user.id === task?.requestingUserId);
    const actions = data
      ? data.task.correctiveActions
      : getCorrectiveActions
      ? await getCorrectiveActions({ taskId: task.id })
      : undefined;

    let signatures = !data ? await listTaskSignatures(task.id) : undefined;
    signatures = signatures
      ?.filter((it) => it.entityType === SigningEntityType.TaskLogBookSticker)
      .sort((a, b) => (dayjs(a.signedDate).isBefore(dayjs(b.signedDate)) ? -1 : 1));

    const doc = new jsPDF("landscape", "mm", "junior-legal");
    const startingPageCount = doc.getCurrentPageInfo().pageNumber;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 4.5;
    let [verticalOffset, horizontalOffset] = [margin, margin];
    let h = 0;
    let maxContentYOffset = 0;
    let innerBoxHeight = 0;
    const newPageNumbers = [startingPageCount];

    const drawHeader = () => {
      [verticalOffset, horizontalOffset] = [margin, margin];
      h = 0;

      //Inner Box Offset
      horizontalOffset += 5;
      verticalOffset += 3;

      //Task Name & id
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      let wrappedText = doc.splitTextToSize(`${task.name || ""} - ${task.orderIndex || ""}`.toUpperCase(), 50);
      h = doc.getTextDimensions(wrappedText).h;
      verticalOffset += 1;
      horizontalOffset += 4;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions(`${task.name || ""}`).h); // have to get dimensions for just one line because wrapped text behaves differently
      verticalOffset += h;
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      //Requesting Manager
      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      let text = `${requestingManager?.name || ""}`;
      h = doc.getTextDimensions(text).h;
      verticalOffset += h + 2;
      doc.text(text, horizontalOffset, verticalOffset);
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      //Log UID
      text = `Log UID#: ${task.id.slice(-7)}-${task.orderIndex || ""}`;
      h = doc.getTextDimensions(text).h;
      verticalOffset += h + 2;
      doc.text(text, horizontalOffset, verticalOffset);
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      //Work Order Name
      horizontalOffset += 50 + 10;
      doc.setTextColor(140, 140, 140);
      wrappedText = doc.splitTextToSize(project.name, 40);
      h = doc.getTextDimensions(wrappedText).h;
      verticalOffset = margin + 3 + 1;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Work Order").h); // have to get dimensions for just one line because wrapped text behaves differently
      verticalOffset += h;
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      // Work Order Date
      doc.setTextColor(0, 0, 0);
      text = `Work Order Due: ${dayjs(project.endDate).utc(true).format("MM/DD/YYYY")}`;
      h = doc.getTextDimensions(text).h;
      verticalOffset += h + 2;
      doc.text(text, horizontalOffset, verticalOffset);
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      // Task Date
      text = `Task Due: ${dayjs(task.dueDate).format("MM/DD/YYYY")}`;
      h = doc.getTextDimensions(text).h;
      verticalOffset += h + 2;
      doc.text(text, horizontalOffset, verticalOffset);
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset);

      // Entity
      horizontalOffset += 40 + 10;
      verticalOffset = margin + 3 + 1;
      let maxLength = pageWidth - margin - 5 - 30 - horizontalOffset;
      doc.setFont("helvetica", "bold");
      wrappedText = doc.splitTextToSize(`${entity.make}/${entity.model}`, maxLength);
      h = doc.getTextDimensions(wrappedText).h;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Make/Model").h);
      verticalOffset += h + 2;
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset - 2);

      //Customer Name
      doc.setFont("helvetica", "normal");
      wrappedText = doc.splitTextToSize(client.name, maxLength);
      h = doc.getTextDimensions(wrappedText).h;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Name").h);
      verticalOffset += h + 2;
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset - 2);

      //Customer Address
      const address = project.location?.address || client.address;
      wrappedText = doc.splitTextToSize(
        `${project.location?.name ? project.location?.name + "\n" : ""}${address.street1} ${address.city}, ${
          address.state
        } ${address.zip}`,
        maxLength,
      );
      h = doc.getTextDimensions(wrappedText).h;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Address").h);
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset + h);

      //Tail Number
      horizontalOffset += maxLength + 5;
      verticalOffset = margin + 3 + 1;
      maxLength = pageWidth - margin - 5 - 2 - horizontalOffset;
      doc.setFont("helvetica", "bold");
      wrappedText = doc.splitTextToSize(`Tail #: ${entity.tailNumber || ""}`, maxLength);
      h = doc.getTextDimensions(wrappedText).h;
      doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Tail #").h);
      verticalOffset += h + 2;
      maxContentYOffset = Math.max(maxContentYOffset, verticalOffset - 2);

      //TODO: Set up client contact person
      // if (project.clientContactUser?.name) {
      //   //Contact Person
      //   doc.setFont("helvetica", "regular");
      //   wrappedText = doc.splitTextToSize(project.clientContactUser.name, maxLength);
      //   h = doc.getTextDimensions(wrappedText).h;
      //   doc.text(wrappedText, horizontalOffset, verticalOffset + doc.getTextDimensions("Person").h);
      //   maxContentYOffset = Math.max(maxContentYOffset, verticalOffset + h);
      // }

      //Inner Box
      horizontalOffset = margin + 5;
      verticalOffset = margin + 3;
      innerBoxHeight = maxContentYOffset + 3 - verticalOffset;
      doc.rect(horizontalOffset, verticalOffset, pageWidth - horizontalOffset * 2, innerBoxHeight, "S");
    };

    drawHeader();

    // Signature Line
    const drawSignatureArea = async (x1: number, y: number, x2: number, signature: any | undefined, doc: jsPDF) => {
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.4);
      doc.line(x1, y, x2, y);

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(16);
      doc.setFont("times", "bold");
      h = doc.getTextDimensions("X").h;
      doc.text("X", x1 + 1, y - 1.5);

      doc.setFontSize(6);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(181, 181, 181);
      h = doc.getTextDimensions("Signature").h;
      doc.text("Signature", x1 + 8, y + 0.75 + h);

      doc.text("Print Name", x1 + (x2 - x1) / 2, y + 0.75 + h);

      doc.text("Date", x1 + (x2 - x1) - 10, y + 0.75 + h);

      // Actual Signatures and Signing Data
      if (signature) {
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        doc.text(signature.userName || "", x1 + (x2 - x1) / 2 + 2, y - 1);

        if (signature.signedDate) {
          doc.text(dayjs(signature.signedDate).format("MM/DD/YYYY"), x1 + (x2 - x1) - 12, y - 1);
        }

        if (signature.signatureAttachment.file.url) {
          const logoDataURL = await toDataURL(signature.signatureAttachment.file.url);
          const logoProperties = doc.getImageProperties(logoDataURL);
          const whRatio = logoProperties.width / logoProperties.height;
          const logoHeight = 6;
          const logoWidth = Math.min(logoHeight * whRatio, 32);
          doc.addImage(logoDataURL, "PNG", x1 + 8, y - 7, logoWidth, logoHeight);
        }

        if (signature.userAAndPNumber) {
          doc.setFontSize(6);
          doc.setFont("helvetica", "bold");
          doc.setTextColor(181, 181, 181);
          doc.text(`A&P #: ${signature.userAAndPNumber}`, x1 + 8, y + 5.5);
        }
      }
    };

    // Corrective Actions
    if (actions && actions.length > 0) {
      autoTable(doc, {
        startY: margin + 3,
        rowPageBreak: "avoid",
        headStyles: { fillColor: null, cellPadding: 0.8, minCellHeight: innerBoxHeight },
        showHead: "everyPage",
        alternateRowStyles: { fillColor: null },
        bodyStyles: { fontSize: 9, cellPadding: { top: 3, bottom: 3 }, fillColor: null, minCellWidth: 30 },
        margin: { left: margin + 5, right: margin + 5, top: 4, bottom: 20 + margin },
        head: [[""]],
        body: actions.map(({ message }) => [message]) || [],
        didDrawPage: (data) => {
          newPageNumbers.push(startingPageCount + 1);
          data.settings.margin.top = margin + 3;
        },
      });
    }

    const onlyUnique = (value, index, self) => {
      return self.indexOf(value) === index;
    };

    const uniquePageNumbers = newPageNumbers.filter(onlyUnique);
    for (const page of uniquePageNumbers) {
      doc.setPage(page);
      doc.setFontSize(25);
      doc.setFont("helvetica", "bold");

      // Page Numbers
      const relativePageIndex = page - startingPageCount;
      const text = `${relativePageIndex + 1}/${uniquePageNumbers.length}`;
      const w = doc.getTextDimensions(text).w;
      doc.setTextColor(160, 160, 160);
      doc.saveGraphicsState();
      doc.setGState(doc.GState({ opacity: 0.5 }));
      doc.text(text, pageWidth - w - 1, pageHeight - 1);
      doc.restoreGraphicsState();

      // Outer Box
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.4);
      doc.rect(margin, margin, pageWidth - margin * 2, pageHeight - margin * 2, "S");

      // Signature Lines
      const pageSignatures = signatures?.filter((sig) => sig.pageCount == relativePageIndex + 1);
      await drawSignatureArea(
        margin + 7,
        pageHeight - margin - 7,
        pageWidth / 2 - 5,
        pageSignatures?.length || 0 > 0 ? pageSignatures[0] : undefined,
        doc,
      );
      await drawSignatureArea(
        pageWidth / 2 + 5,
        pageHeight - margin - 7,
        pageWidth - margin - 5,
        pageSignatures?.length || 0 > 1 ? pageSignatures[1] : undefined,
        doc,
      );

      drawHeader();
    }

    // Convert pdf to PDFDocument from 'pdf-lib' library
    const newPDF = await PDFDocument.create();
    // Loading currently generated PDF doc as array buffer
    const docBlob = doc.output("arraybuffer");
    // Loading array buffer to pdf-lib library
    const docPDF = await PDFDocument.load(docBlob);
    const copiedPages = await newPDF.copyPages(
      docPDF,
      Array(docPDF.getPageCount())
        .fill(0)
        .map((it, i) => i),
    );
    // Copy pages of the generated PDF to the new PDF
    copiedPages.forEach((page) => newPDF.addPage(page));

    if (download) {
      // Save and download the new PDF
      const pdfBytes = await newPDF.save();
      downloadPDF(pdfBytes, `${task.name} Log Sticker.pdf`);
    }

    return { doc, fullPDF: newPDF };
  };
  return { generate };
}
