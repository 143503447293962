import React from "react";
import "@rmwc/icon/styles";
import Palette from "../../palette.json";
import useMultiTenant from "hooks/useMultiTenant";
import { FullScreenMessage } from "components/FullScreenMessage";

export const SigningSuccessScreen: React.FC = () => {
  return (
    <FullScreenMessage
      header="Success"
      message="You signatures have been recorded and saved successfully."
      icon="check_circle"
    />
  );
};
