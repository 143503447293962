import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { TimesheetFields } from "types/admin/TimesheetFields";

type Props = {
  start: Dayjs;
  end: Dayjs;
};

export const useTimesheets = ({ start, end }: Props) => {
  const { getTimesheets } = useAdminApi();
  const [timesheets, setTimesheets] = useState<TimesheetFields[]>([]);

  const startString = start.format();
  const endString = end.format();

  useEffect(() => {
    if (!startString || !endString) return;
    getTimesheets(dayjs(startString), dayjs(endString)).then((results) => {
      console.log("useTimesheets", { results });
      setTimesheets(results);
    });
  }, [startString, endString]);

  return timesheets;
};
