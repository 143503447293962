import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import numeral from "numeral";
import rand from "seedrandom";
import Palette from "../palette.json";
import writeXlsxFile from "write-excel-file";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import get from "lodash/get";
import {
  BillingType,
  SigningType,
  MarkupType,
  ProjectRole,
  TimeSheetEntryStatus,
  UserWorkPayType,
  UserRole,
  TemplateType,
  PartType,
} from "types/admin/globalTypes";
import { TimesheetFields } from "types/admin/TimesheetFields";

dayjs.extend(customParseFormat);

export const TEMPLATE_TYPE_TO_LABEL = {
  [TemplateType.Project]: "Work Order",
  [TemplateType.Task]: "Task",
};

export const PROJECT_ROLE_TO_LABEL = {
  [ProjectRole.technician]: "Technician",
  [ProjectRole.technicianInspector]: "Inspector",
  [ProjectRole.manager]: "Manager",
  [ProjectRole.customer]: "Customer",
};

export const USER_ROLE_TO_LABEL = {
  [UserRole.Admin]: "Administrator",
  [UserRole.AdminManager]: "Site Manager",
  [UserRole.User]: "User",
};

export const partTypeToLabel = (isAviation: boolean, type: PartType) => {
  const map = {
    [PartType.Consumable]: "Consumable",
    [PartType.Rotable]: "Rotable",
    [PartType.Assembly]: isAviation ? "Assembly" : "Kit",
    [PartType.Expendable]: "Expendable",
  };
  return map[type];
};

export const MARKUP_TYPE_TO_LABEL = {
  [MarkupType.percentage]: "Percentage",
  [MarkupType.dollar]: "Dollar",
};

const enumKeys = <T extends object, K extends keyof T = keyof T>(e: T): K[] =>
  Object.keys(e).filter((k) => Number.isNaN(+k)) as K[];

export const MARKUP_TYPE_OPTIONS = enumKeys(MarkupType).map((it) => ({
  label: MARKUP_TYPE_TO_LABEL[MarkupType[it]],
  value: MarkupType[it],
}));

export const BUILDING_TYPE_OPTIONS = [
  { label: "Industrial", value: "Industrial" },
  { label: "Commercial", value: "Commercial" },
  { label: "Residential", value: "Residential" },
];

export const getPartTypeOptions = (isAviation: boolean) => {
  const items = [
    { label: "Consumable", value: PartType.Consumable },
    { label: "Rotable", value: PartType.Rotable },
    { label: isAviation ? "Assembly" : "Kit", value: PartType.Assembly },
    { label: "Expendable", value: PartType.Expendable },
  ];
  return items.filter((it) => it.value !== PartType.Rotable || isAviation);
};

export const USER_ROLE_OPTIONS = enumKeys(UserRole).map((it) => ({
  label: USER_ROLE_TO_LABEL[UserRole[it]],
  value: UserRole[it],
}));

export const PROJECT_ROLE_OPTIONS = enumKeys(ProjectRole).map((it) => ({
  label: PROJECT_ROLE_TO_LABEL[ProjectRole[it]],
  value: ProjectRole[it],
}));

interface MarkupT {
  markupType?: MarkupType;
  markup?: string;
  quantity?: string;
  cost?: string;
}

export const readableBillingType = (billingType: BillingType) => {
  switch (billingType) {
    case BillingType.Fixed:
      return "Flat Rate";
    case BillingType.TANDM:
      return "Time & Materials";
  }
};

export const readableSigningType = (signingType: SigningType) => {
  switch (signingType) {
    case SigningType.RII:
      return "RII";
    case SigningType.Technician:
      return "Technician";
    case SigningType.TechnicianAndTechnician:
      return "Technician x2";
    case SigningType.TechnicianAndInspector:
      return "Technician & Inspector";
  }
};

export const readableTaskId = (taskId: string | undefined | null) => {
  switch (taskId) {
    case "InHangar":
      return "In Hangar";
    case "GeneralLabor":
      return "General Labor";
    case "Driving":
      return "Driving";
    default:
      return undefined;
  }
};

export const toDataURL = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function () {
      reject(new Error("Network Error"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", url);
    xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
    xhr.send();
  });
};

export const downloadPDF = (arrayBuffer: any, fileName: string) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([arrayBuffer], { type: "application/pdf" }));
  a.download = fileName;
  a.click();
};

export const getCost = (item: any): number => {
  const cost = (item?.markup ? item?.markupCost : item?.cost) || 0;

  // * quantity is already applied in markupCost
  if (item.quantity && !item?.markupCost) {
    return cost * item?.quantity ?? 0;
  }
  return cost;
};

export const calculateMarkupCost = (it: MarkupT) => {
  if (!it.markupType || !it.cost || !it.markup || !it.quantity) return 0.0;

  switch (it.markupType) {
    case MarkupType.dollar:
      return (parseFloat(it.cost) + parseFloat(it.markup)) * parseInt(it.quantity);
    case MarkupType.percentage:
      return (parseFloat(it.cost) + parseFloat(it.cost) * (parseFloat(it.markup) / 100.0)) * parseInt(it.quantity);
  }
};

export const formatHours = (hours: number) => {
  const formatted = hours === 0 ? "0" : numeral(hours).format("0.0");
  if (formatted.endsWith(".0")) {
    return formatted.substring(0, formatted.length - 2);
  }
  return formatted;
};

export const formatPrice = (v: number) => {
  return "$" + numeral(v).format("0,000.00");
};

const COLORS: string[] = Object.keys(Palette["Avatar"]).sort();

function isKeyof<T extends object>(obj: T, possibleKey: keyof any): possibleKey is keyof T {
  return possibleKey in obj;
}

export const getAvatarColor = (id: string) => {
  const rng = rand(id);
  const key = COLORS[Math.floor(rng.quick() * COLORS.length)];

  if (isKeyof(Palette["Avatar"], key)) {
    return Palette["Avatar"][key];
  }
  return "#ffffff";
};

export const twelveHourTimeFormat = (time: string) => {
  const [hours, minutes] = time.split(":");
  const h = parseInt(hours);
  const m = parseInt(minutes);
  const ampm = h >= 12 ? "PM" : "AM";
  let hh = h % 12;
  if (h === 0 || h === 12) {
    hh = 12;
  }
  return `${hh}:${minutes} ${ampm}`;
};

export const generateTimesheetsXLSX = async (
  timesheets: TimesheetFields[],
  users: any,
  type: "InHangar" | "tasks" = "InHangar",
) => {
  let header = {};

  if (type === "InHangar") {
    header = [
      { value: "Employee #" },
      { value: "Punch 1 Type" },
      { value: "Punch 1 Date" },
      { value: "Punch 1 Time" },
      { value: "Punch 2 Type" },
      { value: "Punch 2 Date" },
      { value: "Punch 2 Time" },
      { value: "Org Unit" },
    ];
  } else if (type === "tasks") {
    header = [
      { value: "Employee #" },
      { value: "Last Name" },
      { value: "FirstName" },
      { value: "Clock In Date" },
      { value: "Clock In Time" },
      { value: "Clock Out Date" },
      { value: "Clock Out Time" },
      { value: "Duration (hours)" },
      { value: "Project" },
      { value: "Task" },
    ];
  }

  const getDateTimeStrings = (dateTime: string) => {
    const parsed = dayjs(dateTime);
    const date = parsed.format("MM/DD/YYYY");
    const time = parsed.format("hh:mm A");
    return [date, time];
  };

  const rows: any[] = [header];

  const tasksAndProjects: { tasks: { [key: string]: string }; projects: { [key: string]: string } } = {
    tasks: {},
    projects: {},
  };
  timesheets.forEach((it) => {
    tasksAndProjects.tasks[it.taskId] = it.taskSummary;
    tasksAndProjects.projects[it.projectId] = it.projectSummary;
  });

  timesheets.forEach((sheet) => {
    // Prefer most up-to-date user info
    const user = users.find((ut) => ut.id === sheet?.userId) ?? sheet?.user;
    const payChex = user?.payChex;

    if (sheet.status && ![TimeSheetEntryStatus.Completed, TimeSheetEntryStatus.Approved].includes(sheet.status)) {
      return;
    }

    let [fromDate, fromTime] = getDateTimeStrings(sheet.fromDT);
    const [toDate, toTime] = getDateTimeStrings(sheet.toDT);
    let done = false;

    do {
      let [adjustedFromDate, adjustedFromTime, adjustedToDate, adjustedToTime] = [fromDate, fromTime, toDate, toTime];

      if (fromDate !== toDate) {
        adjustedFromDate = fromDate;
        adjustedToDate = fromDate;
        adjustedFromTime = fromTime;
        adjustedToTime = "11:59 PM";

        fromDate = dayjs(fromDate).add(1, "day").format("MM/DD/YYYY");
        console.log(fromDate);
        fromTime = "12:00 AM";
      } else {
        done = true;
      }

      const adjustedToDateObject = dayjs(`${adjustedToDate} ${adjustedToTime}`, "MM/DD/YYYY hh:mm A").utc(true).local();
      const adjustedFromDateObject = dayjs(`${adjustedFromDate} ${adjustedFromTime}`, "MM/DD/YYYY hh:mm A")
        .utc(true)
        .local();

      const hours = adjustedToDateObject.diff(adjustedFromDateObject, "hour", true);

      const projectSummary = sheet.projectSummary || tasksAndProjects.projects[sheet.projectId];
      const taskSummary =
        sheet.taskId === "GeneralLabor"
          ? readableTaskId(sheet.taskId)
          : sheet.taskSummary || tasksAndProjects.tasks[sheet.taskId];

      let row = {};
      if (type === "InHangar") {
        row = [
          {
            value: payChex?.id || "",
            type: String,
          },
          {
            value: "Clock In",
            type: String,
          },
          {
            value: adjustedFromDateObject.toDate(),
            type: Date,
            format: "mm/dd/yyyy",
          },
          {
            value: adjustedFromDateObject.toDate(),
            type: Date,
            format: "hh:mm AM/PM",
          },
          {
            value: "Clock Out",
            type: String,
          },
          {
            value: adjustedToDateObject.toDate(),
            type: Date,
            format: "mm/dd/yyyy",
          },
          {
            value: adjustedToDateObject.toDate(),
            type: Date,
            format: "hh:mm AM/PM",
          },
          {
            value: payChex?.orgUnit || "",
            type: String,
          },
        ];
      } else if (type === "tasks") {
        row = [
          {
            value: payChex?.id || "",
            type: String,
          },
          {
            value: user?.lastName || "",
            type: String,
          },
          {
            value: user?.firstName || "",
            type: String,
          },
          {
            value: adjustedFromDateObject.toDate(),
            type: Date,
            format: "mm/dd/yyyy",
          },
          {
            value: adjustedFromDateObject.toDate(),
            type: Date,
            format: "hh:mm AM/PM",
          },
          {
            value: adjustedToDateObject.toDate(),
            type: Date,
            format: "mm/dd/yyyy",
          },
          {
            value: adjustedToDateObject.toDate(),
            type: Date,
            format: "hh:mm AM/PM",
          },
          {
            value: hours,
            type: Number,
            format: "0.00",
          },
          {
            value: projectSummary,
            type: String,
          },
          {
            value: taskSummary,
            type: String,
          },
        ];
      }

      rows.push(row);
    } while (!done);
  });

  const fileName = type === "tasks" ? "foxtrot-task-time-report.xlsx" : "foxtrot-technician-timesheets-report.xlsx";
  await writeXlsxFile(rows, { fileName });
};

type EfficiencyData = {
  name: string;
  isSalaried: boolean;
  taskTime: number;
  clockedTime: number;
  efficiency: string;
};

export const generateEfficiencyXLSX = async (from: string, to: string, formattedData: EfficiencyData[]) => {
  const header = [
    {
      value: "Employee Name",
    },
    {
      value: "Salary Employee",
    },
    {
      value: "Task Time (Hours)",
    },
    {
      value: "Clocked Time (Hours)",
    },
    {
      value: "% Utilized",
    },
  ];
  const rows: any[] = [header];

  formattedData.forEach((data) => {
    const isSalaried = data.isSalaried ? "✓" : "";
    const row = [
      {
        value: data.name,
        type: String,
      },
      {
        value: isSalaried,
        type: String,
      },
      {
        value: data.taskTime,
        type: Number,
      },
      {
        value: data.clockedTime,
        type: Number,
        format: "0.00",
      },
      {
        value: data.efficiency,
        type: String,
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-task-efficiency-report-${from}-${to}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

type MoraleData = {
  name: string;
  date: Date;
  comment: string;
  emoji: string;
  rating: string;
};

export const generateMoraleXLSX = async (from: string, to: string, formattedData: MoraleData[]) => {
  const header = [
    {
      value: "Date",
    },
    {
      value: "Name",
    },
    {
      value: "Reaction",
    },
    {
      value: "Emoji",
    },
    {
      value: "Comment",
    },
  ];
  const rows: any[] = [header];

  formattedData.forEach((data) => {
    console.log(typeof data.date);
    const row = [
      {
        value: data.date,
        type: Date,
        format: "mm/dd/yyyy",
      },
      {
        value: data.name,
        type: String,
      },
      {
        value: data.rating,
        type: String,
      },
      {
        value: data.emoji,
        type: String,
      },
      {
        value: data.comment,
        type: String,
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-morale-report-${from}-${to}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export const generateProgressXLSX = async (date: string, projects: ProjectFields[]) => {
  const header = [
    {
      value: "Work Order",
    },
    {
      value: "Status",
    },
    {
      value: "Actual Hours",
    },
    {
      value: "Estimated Hours",
    },
    {
      value: "Efficiency",
    },
  ];
  const rows: any[] = [header];

  projects.forEach((it) => {
    const efficiency = it.estimateManHrs === 0 ? 0 : it.estimateManHrs / (it.progress.completedHours || 0);
    const row = [
      {
        value: it.name,
        type: String,
      },
      {
        value: it.status,
        type: String,
      },
      {
        value: Math.round(it.progress.completedHours * 100) / 100,
        type: Number,
        format: "0.00",
      },
      {
        value: Math.round(it.estimateManHrs * 100) / 100,
        type: Number,
        format: "0.00",
      },
      {
        value: Math.min(efficiency, 1),
        type: Number,
        format: "0%",
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-progress-report-${date}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export const generateCompletedProjectReportXLSX = async (
  tasks: TaskFields[],
  clientName?: string,
  workOrderName?: string,
) => {
  const headers = [
    {
      value: "Task",
    },
    {
      value: "Actual Hours",
    },
    {
      value: "Estimates Hours",
    },
    {
      value: "Efficiency",
    },
  ];
  const rows: any[] = [headers];

  tasks.forEach((task) => {
    const completed: number = get(task, "progress.completedHours", 0);
    const estimated: number = get(task, "estimateManHrs", 0);
    const roundedEstimate = Math.round(estimated * 100) / 100;

    let efficiency = 0;
    if (roundedEstimate) {
      efficiency = roundedEstimate / completed;
      efficiency = Math.min(efficiency, 1);
    }
    const row = [
      {
        value: task.name,
        type: String,
      },
      {
        value: completed,
        type: Number,
        format: "0.00",
      },
      {
        value: roundedEstimate,
        type: Number,
        format: "0.00",
      },
      {
        value: efficiency,
        type: Number,
        format: "0.0%",
      },
    ];
    rows.push(row);
  });

  const fileName = `Work Order Report - ${clientName} - ${workOrderName}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export const generateProjectProgressXLSX = async (date: string, projects: TaskFields[]) => {
  const header = [
    {
      value: "Task",
    },
    {
      value: "Actual Hours",
    },
    {
      value: "Estimated Hours",
    },
    {
      value: "Efficiency",
    },
  ];
  const rows: any[] = [header];

  projects.forEach((it) => {
    const efficiency = it.estimateManHrs === 0 ? 0 : it.estimateManHrs / (it.progress.completedHours || 0);
    const row = [
      {
        value: it.name,
        type: String,
      },
      {
        value: Math.round(it.progress.completedHours * 100) / 100,
        type: Number,
        format: "0.00",
      },
      {
        value: Math.round(it.estimateManHrs * 100) / 100,
        type: Number,
        format: "0.00",
      },
      {
        value: Math.min(efficiency, 1),
        type: Number,
        format: "0%",
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-work-order-progress-report-${date}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export interface UpdatedTimesheetFields extends TimesheetFields {
  isDeleted?: boolean;
}

export type TrendReportItem = {
  customerId: string;
  locationId: string;
  locationName: string;
  customerName: string;
  hrs: number;
  taskIds: string[];
  variance: number;
  tasksPerDay: number;
  hrsPerTask: number;
  avgTargetHrs: number;
  isRed: boolean;
};

export const generateTrendXLSX = async (from: string, to: string, formattedData: TrendReportItem[]) => {
  const header = [
    {
      value: "Variance",
    },
    {
      value: "Location",
    },
    {
      value: "Customer",
    },
    {
      value: "Avg. Tasks per Day",
    },
    {
      value: "Avg. Hrs Per Task Per Day",
    },
    {
      value: "Avg. Target Hrs",
    },
  ];
  const rows: any[] = [header];

  formattedData.forEach((it) => {
    // const row = `${it.variance}%, ${it.locationName}, ${it.customerName}, ${it.tasksPerDay}, ${it.hrsPerTask}, ${it.avgTargetHrs}`;
    const row = [
      {
        value: it.variance / 100,
        type: Number,
        format: "0.00%",
      },
      {
        value: it.locationName,
        type: String,
      },
      {
        value: it.customerName,
        type: String,
      },
      {
        value: it.tasksPerDay,
        type: Number,
        format: "0.00",
      },
      {
        value: it.hrsPerTask,
        type: Number,
        format: "0.00",
      },
      {
        value: it.avgTargetHrs,
        type: Number,
        format: "0.00",
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-trend-report-${from}-${to}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export type TrendLocationItem = {
  hrs: number;
  date: Date;
  taskIds: string[];
  userIds: string[];
};

export const generateLocationXLSX = async (from: string, to: string, formattedData: TrendLocationItem[]) => {
  const header = [
    {
      value: "Date",
    },
    {
      value: "Total Tasks",
    },
    {
      value: "Total Hours",
    },
    {
      value: "Technicians",
    },
  ];
  const rows: any[] = [header];

  formattedData.forEach((it) => {
    const row = [
      {
        value: it.date,
        type: Date,
        format: "mm/dd/yyyy",
      },
      {
        value: it.taskIds.length,
        type: Number,
      },
      {
        value: it.hrs,
        type: Number,
        format: "0.00",
      },
      {
        value: it.userIds.length,
        type: Number,
      },
    ];
    rows.push(row);
  });

  const fileName = `foxtrot-location-report-${from}-${to}.xlsx`;
  await writeXlsxFile(rows, { fileName });
};

export const flattenParts = (parts: any) => {
  console.log();
  return (
    parts?.reduce((partsAcc, part) => {
      if (part.type === PartType.Rotable || part.type === PartType.Expendable) {
        return [...partsAcc, ...(part.parts?.items || [])];
      }

      if (part.type === PartType.Assembly) {
        return [
          ...partsAcc,
          part,
          ...(part.parts?.items?.reduce((subPartsAcc, subPart) => {
            if (subPart.type === PartType.Rotable || subPart.type === PartType.Expendable) {
              return [...subPartsAcc, ...(subPart.parts?.items || [])];
            }

            return [...subPartsAcc, subPart];
          }, []) || []),
        ];
      }

      return [...partsAcc, part];
    }, []) || []
  );
};

export const flattenTaskParts = (tasks: any[]) => {
  return tasks.reduce((acc, it) => {
    return [
      ...acc,
      ...it.taskParts?.items.reduce((partsAcc, part) => {
        if (part.type === PartType.Rotable || part.type === PartType.Expendable) {
          return [
            ...partsAcc,
            ...(part.parts?.items || []).map((jt) => ({
              part: jt,
              task: it,
              path: `parts/${part.id}/parts/${jt.id}`,
            })),
          ];
        }

        if (part.type === PartType.Assembly) {
          return [
            ...partsAcc,
            ...part.parts?.items.reduce((subPartsAcc, subPart) => {
              if (subPart.type === PartType.Rotable || subPart.type === PartType.Expendable) {
                return [
                  ...subPartsAcc,
                  ...(subPart.parts?.items || []).map((jt) => ({
                    part: jt,
                    task: it,
                    path: `parts/${part.id}/part/${subPart.id}/parts/${jt.id}`,
                  })),
                ];
              }

              return [...subPartsAcc, { part: subPart, task: it, path: `parts/${part.id}/parts/${subPart.id}` }];
            }, []),
          ];
        }

        return [...partsAcc, { part, task: it, path: `parts/${part.id}` }];
      }, []),
    ];
  }, []);
};
