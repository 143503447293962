import { Checkbox } from "@rmwc/checkbox";
import React from "react";

export interface BreakdownCostsCheckboxPropsT {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BreakdownCostsCheckbox: React.FC<BreakdownCostsCheckboxPropsT> = ({ checked, setChecked }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
      <span
        style={{
          textAlign: "right",
          fontSize: "13px",
          lineHeight: "1.2",
          marginRight: "3px",
        }}
      >
        Show Hours & Rate
      </span>

      <Checkbox style={{ fontSize: "10px" }} checked={checked} onChange={() => setChecked(!checked)}></Checkbox>
    </div>
  );
};
