import React, { useEffect } from "react";
import { PageContent, PageMain } from "components/PageStyles";
import { PageHeader } from "components/PageHeader";
import SettingsForm from "components/SettingsForm";
import useLayout from "hooks/useLayout";

const pageTitle = "Settings";

export const SettingsPage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: pageTitle,
        path: "/settings",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeader title={pageTitle} />

      <PageMain>
        <PageContent>
          <SettingsForm />
        </PageContent>
      </PageMain>
    </>
  );
};
