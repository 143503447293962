import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useAdminApi } from "hooks/useAdminApi";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import uniqBy from "lodash/uniqBy";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import Palette from "../../palette.json";
import { useTask } from "hooks/params/useTask";
import { TaskScheduleShiftsForm } from "components/schedule/TaskScheduleShiftsForm";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { CalendarShiftDetails } from "components/schedule/CalendarShiftDetails";
import { dialogQueue } from "hooks/useDialogQueue";
import get from "lodash/get";
import { Select } from "components/Select";
import CalendarWrapper from "components/schedule/CalendarWrapper";

const UserSelectWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

interface UserShiftCalendarProps {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  loadShifts: (taskId: string, fromDt: dayjs.Dayjs, toDt: dayjs.Dayjs) => void;
  shifts: UserShiftFields[];
  users: any[];
  selectedUser: string;
  setSelectedUser: React.Dispatch<React.SetStateAction<string>>;
  onShiftAssigned: (shift: UserShiftFields) => void;
  onShiftUpdated: (userShift: UserShiftFields) => void;
  onShiftDeleted: (id: string) => void;
}

export const UserShiftCalendar: React.FC<UserShiftCalendarProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  shifts,
  users,
  selectedUser,
  setSelectedUser,
  loadShifts,
  onShiftAssigned,
  onShiftUpdated,
  onShiftDeleted,
}) => {
  const { projectId, taskId } = useParams<{ projectId: string; taskId: string }>();
  const { getTask, updateUserShift } = useAdminApi();
  const { task } = useTask();

  const [selectedShiftId, setSelectedShiftId] = React.useState<string | null>(null);
  const [events, setEvents] = React.useState<any[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null);
  const calendarRef = React.useRef<FullCalendar>(null);

  const filterOptions = [
    { label: "All shifts", value: "_all" },
    ...users
      .map((option) => ({
        value: option.userId,
        label: `${option.firstName} ${option.lastName}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const onDateClick = (info: any) => {
    const date = dayjs(info.dateStr);
    setSelectedDate(date);
  };

  useEffect(() => {
    if (!startDate || !endDate || !taskId) return;

    // Listing all shifts for the selected date range
    loadShifts(taskId, startDate, endDate);
  }, [startDate, endDate, taskId]);

  useEffect(() => {
    let filteredShifts: UserShiftFields[] = shifts;
    if (selectedUser !== "_all") {
      filteredShifts = shifts?.filter((shift) => shift.userId === selectedUser) || [];
    }

    setEvents(
      filteredShifts.map((it) => ({
        title: `${it.user.firstName} ${it.user.lastName}`,
        start: dayjs(it.fromDt).toISOString(),
        end: dayjs(it.toDt).toISOString(),
        allDay: false,
        color: Palette.Blue,
        extendedProps: { taskId: it.taskId, id: it.id, userId: it.userId },
      })),
    );
  }, [selectedUser, JSON.stringify(shifts)]);

  useEffect(() => {
    if (task || !taskId || !projectId) return;

    getTask(taskId).then((task) =>
      calendarRef.current.getApi().gotoDate(dayjs(task.scheduledDate).format("YYYY-MM-DD")),
    );
  }, []);

  const start = dayjs(task.scheduledDate).tz("UTC", true).startOf("day").format("YYYY-MM-DD");
  const end = dayjs(task.dueDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD");

  //Memoize to prevent infinite loop
  const validRange = React.useMemo(() => ({ start, end }), [start, end]);
  const initialDate = React.useMemo(() => dayjs().format("YYYY-MM-DD"), []);

  const handleDrag = async ({ event, revert }) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Change shift date/time?",
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) {
      revert();
      return;
    }

    await attemptShiftUpdate(
      event.extendedProps.id,
      dayjs(event.start).toISOString(),
      dayjs(event.end).toISOString(),
      revert,
    );
  };

  const attemptShiftUpdate = async (
    id: string,
    fromDt: string,
    toDt: string,
    revert: any,
    force?: boolean,
    error?: any,
  ) => {
    if (force) {
      const message = get(
        error,
        "graphQLErrors[0].message",
        get(error, "message", "An unexpected error ocurred. Please try again."),
      );
      const hasConfirmed = await dialogQueue.confirm({
        title: message,
        acceptLabel: "Force",
        cancelLabel: "Cancel",
        body: "Do you still want to force the shift to update?",
      });

      if (!hasConfirmed) {
        revert();
        return;
      }
    }

    try {
      const updatedShift = await updateUserShift({
        id,
        input: {
          fromDt,
          toDt,
          force: force || false,
        },
      });
      onShiftUpdated(updatedShift);
    } catch (error) {
      if (error.message.includes("conflict with the shift") && !force) {
        await attemptShiftUpdate(id, fromDt, toDt, revert, true, error);
        return;
      }

      console.error(error);
      revert();
    }
  };

  return (
    <CalendarWrapper>
      {selectedDate && (
        <TaskScheduleShiftsForm
          date={selectedDate}
          userShifts={events.filter((it) => dayjs(it.start).isSame(selectedDate, "day"))}
          onAssign={(shift) => onShiftAssigned(shift)}
          onClose={() => setSelectedDate(null)}
        />
      )}

      {selectedShiftId && (
        <CalendarShiftDetails
          shift={shifts.find((it) => it.id === selectedShiftId)}
          onClose={() => setSelectedShiftId(null)}
          onDelete={(id) => onShiftDeleted(id)}
          onUpdate={(shift) => onShiftUpdated(shift)}
        />
      )}

      <UserSelectWrapper>
        <Select
          name="selected_user"
          options={filterOptions}
          value={selectedUser}
          onChange={(event: any) => setSelectedUser(event.target.value)}
          outlined
        />
      </UserSelectWrapper>

      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        contentHeight={"auto"}
        dateClick={onDateClick}
        events={events}
        validRange={validRange}
        dragScroll={false}
        initialDate={initialDate}
        editable
        eventDrop={handleDrag}
        eventResize={handleDrag}
        headerToolbar={{
          left: "prev,next,title",
          right: "",
        }}
        eventClick={({ event }) => {
          setSelectedShiftId(event.extendedProps.id);
        }}
        datesSet={(dates) => {
          setStartDate(dayjs(dates.start).utc(true));
          setEndDate(dayjs(dates.end).utc(true));
        }}
        ref={calendarRef}
      />
    </CalendarWrapper>
  );
};
