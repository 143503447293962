import { useEffect, useState } from "react";

const useLocalState = <T>(key: string, def: any): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || "") : def);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default useLocalState;
