import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";

import useLayout from "hooks/useLayout";

import { useAdminApi } from "hooks/useAdminApi";
import { useTemplate } from "hooks/params/useTemplate";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { TabContainer } from "components/TabContainer";

import { TaskFormDialog } from "components/tasks/TaskFormDialog";
import { ProjectTemplateFormDialog } from "components/templates/ProjectTemplateFormDialog";
import { ProjectTemplateEstimateFormDialog } from "components/templates/ProjectTemplateEstimateFormDialog";

import { TemplateTasksPage } from "./tasks";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";
import { TemplateTaskCostsPage } from "./tasks/[taskId]/costs";
import { TemplateTaskAttachmentsPage } from "./tasks/[taskId]/attachments";
import { TaskList } from "components/tasks/TaskList";
import { TemplateType } from "types/admin/globalTypes";
import { AttachmentFormDialog } from "components/attachments/AttachmentFormDialog";
import { TodoFormDialog } from "components/tasks/TodoFormDialog";
import { Button } from "components/Button";
import useMultiTenant from "hooks/useMultiTenant";

import * as Pages from "../../../pages";
import { PartsList } from "components/parts/PartsList";
import { PartFormDialog } from "components/parts/PartFormDialog";
import { UserList } from "components/users/UserList";
import { UserFields } from "types/admin/UserFields";
import { UserAssignDialog } from "components/users/UserAssignDialog";
import useTranslations from "hooks/useTranslations";
import { TaskUserMultiAssignDialog } from "components/users/TaskUserMultiAssignDialog";

interface ParamsT {
  templateId: string;
}
export const TemplateViewPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const { templateId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { templateTasks: allTasks, getTemplateTasks, users } = useAdminApi();
  const { template, isTemplateTypeTask } = useTemplate();
  const templateTasks = allTasks.filter((it) => it?.parentId === template?.id);

  const [taskNextToken, setTaskNextToken] = useState<string | null | undefined>(null);
  const { theme } = useMultiTenant();

  let PAGE_TABS = [
    {
      icon: "checklist",
      label: isTemplateTypeTask ? "Todos" : "Tasks",
      path: isTemplateTypeTask ? `/templates/${templateId}/todos` : `/templates/${templateId}/tasks`,
    },
    {
      icon: "people",
      label: "People",
      path: `/templates/${templateId}/people`,
    },
  ];

  if (isTemplateTypeTask) {
    PAGE_TABS = [
      ...PAGE_TABS,
      ...[
        {
          icon: "construction",
          label: tCommon("Parts"),
          path: `/templates/${templateId}/parts`,
        },
        {
          icon: "paid",
          label: "Costs",
          path: `/templates/${templateId}/costs`,
        },
        {
          icon: "upload_file",
          label: "Attachments",
          path: `/templates/${templateId}/attachments`,
        },
      ],
    ];
  }

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    const getTasks = async () => {
      const { next } = await getTemplateTasks(templateId, taskNextToken);
      if (next) {
        setTaskNextToken(next);
      }
    };
    getTasks();
  }, [taskNextToken]);

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!template) return;

    setBreadCrumbs([
      {
        label: "Templates",
        path: "/templates",
      },
      {
        label: template?.name,
        path: `/templates/${templateId}`,
      },
    ]);
  }, [setBreadCrumbs, template, templateId]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit") || location.pathname.endsWith("/new"))
      return;
    history.replace(path);
  };

  if (!template) return <Route component={Pages.Error404} />;

  return (
    <>
      <Route exact path="/templates/:templateId/parts/new">
        <PartFormDialog action="assign" />
      </Route>
      <Route exact path="/templates/:templateId/people/:userId/multiassign">
        <TaskUserMultiAssignDialog />
      </Route>
      <Route exact path="/templates/:templateId/edit-project">
        <ProjectTemplateFormDialog action="update" />
      </Route>
      <Route exact path="/templates/:templateId/people/add">
        <UserAssignDialog />
      </Route>
      <Route exact path="/templates/:templateId/tasks/:taskId/people/add_">
        <UserAssignDialog />
      </Route>
      <Route exact path={`/templates/:templateId/todos/new`}>
        <TodoFormDialog action="create" />
      </Route>
      <Route exact path="/templates/:templateId/tasks/new">
        <TaskFormDialog action="create" />
      </Route>
      <Route exact path={`/templates/:templateId/tasks/:taskId/edit_`}>
        <TaskFormDialog action="update" />
      </Route>
      <Route exact path="/templates/:templateId/tasks/estimate">
        <ProjectTemplateEstimateFormDialog />
      </Route>
      <Route exact path="/templates/:templateId/edit-task">
        <TaskFormDialog action="update" />
      </Route>
      <Route path="/templates/:templateId/attachments/new">
        <AttachmentFormDialog action="create" />
      </Route>

      <PageHeader
        compact
        editable
        title={`${template?.name}`}
        description={template?.description}
        onEditClick={() =>
          history.push(`/templates/${templateId}/${isTemplateTypeTask ? "edit-task" : "edit-project"}`)
        }
        templateType={template?.type}
      >
        {!isTemplateTypeTask && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              raised
              onClick={() => {
                history.push(`/templates/${templateId}/tasks/estimate`);
              }}
            >
              Generate Estimate
            </Button>

            <Button
              raised
              onClick={() => {
                history.push(`/work-orders/new?template=${templateId}`);
              }}
              style={{ marginTop: "1rem", backgroundColor: "white", color: theme.primary }}
            >
              Create Work Order
            </Button>
          </div>
        )}
      </PageHeader>
      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>
      <PageMain>
        <PageContent>
          <Switch>
            <Route path="/templates/:templateId/parts" component={PartsList} />
            <Route path="/templates/:templateId/people">
              <UserList
                items={(template.users?.items || []).map((it) => users.find((i) => i.id === it.userId)) as UserFields[]}
              />
            </Route>
            <Route path="/templates/:templateId/costs" component={TemplateTaskCostsPage} />
            <Route path="/templates/:templateId/attachments" component={TemplateTaskAttachmentsPage} />

            <Route path="/templates/:templateId/attachments/new">
              <AttachmentFormDialog action="create" />
            </Route>

            {template?.type === TemplateType.Project ? (
              <Route path={["/templates/:templateId", "/templates/:templateId/tasks"]} component={TemplateTasksPage} />
            ) : (
              <Route path={["/templates/:templateId", "/templates/:templateId/todos"]}>
                <TaskList items={templateTasks} />
              </Route>
            )}
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
