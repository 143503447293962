import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import dayjs from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { TimesheetFields } from "types/admin/TimesheetFields";
import { useHistory } from "react-router-dom";
import { Button } from "components/Button";
import Palette from "../../palette.json";

const Heading = styled.header`
  text-align: center;
  margin: 10px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
`;

const ChartWrapper = styled.div`
  cursor: pointer;

  .recharts-wrapper {
    margin: auto;
  }
`;

const colors = [
  Palette.Blue,
  Palette.Green,
  Palette.Red,
  Palette.Avatar.Purple,
  Palette.Brown,
  Palette.Yellow,
  Palette.Avatar.Lime,
];

function filterTimesheetsByProject(
  timesheets: TimesheetFields[],
  projects: ReturnType<typeof useAdminApi>["projects"],
) {
  if (projects.length === 0) return timesheets;
  return timesheets.filter((timesheet) => projects.find((project) => project.id === timesheet.projectId));
}

const UtilizationChart = ({ startDate, endDate }: { startDate?: dayjs.Dayjs; endDate?: dayjs.Dayjs }) => {
  const history = useHistory();
  const { getTimesheets, userFilteredLocations, projects } = useAdminApi();
  const [_unfilteredTimesheets, setTimesheets] = useState<TimesheetFields[]>([]);
  const [stackedBars, setStackedBars] = useState<boolean>(true);

  const timesheets = _unfilteredTimesheets.filter((timesheet) => {
    if (userFilteredLocations.locationFilterActive === false) return true;
    return projects.find((project) => project.id === timesheet.projectId);
  });

  useEffect(() => {
    console.log("useEffect: UtilizationChart");
    const start = dayjs().startOf("week");
    const end = dayjs().endOf("week");
    getTimesheets(start, end).then((results) => {
      setTimesheets(results);
    });
  }, []);

  useEffect(() => {
    console.log("useEffect: UtilizationChart", { startDate, endDate, userFilteredLocations });
    getTimesheets(startDate, endDate).then((results) => {
      setTimesheets(results);
    });
  }, [startDate, endDate]);

  const data: any = {};

  const inputDates =
    startDate && endDate
      ? [...Array(endDate.diff(startDate, "day") + 1)].map((item, i) => startDate.add(i, "day").format("M/D"))
      : [];
  const dates =
    startDate && endDate
      ? inputDates
      : [...Array(7)].map((item, i) => dayjs().endOf("week").subtract(i, "day").format("M/D")).reverse();
  dates.forEach((date) => {
    data[date] = {};
  });

  let projectNames = [];
  const hangarHrs = {};

  timesheets.forEach((it) => {
    const name = it.projectSummary;
    const hrs = it.amountMins > 0 ? it.amountMins / 60 : 0;
    const date = dayjs(it.toDT)?.format("M/D");
    if (!hangarHrs[date]) hangarHrs[date] = 0;
    if (!data[date]) return;
    projectNames.push(name);
    if (it.taskId === "InHangar") {
      hangarHrs[date] += hrs;
    } else {
      if (!data[date][name]) data[date][name] = 0;
      data[date][name] += hrs;
    }
  });

  projectNames = [...new Set(projectNames)];
  const bars = [...projectNames, "Unused"];

  const formattedData = [];
  Object.entries(data).map(([date, projects]) => {
    const projectData = {};
    let totalTaskHrs = 0;
    const dateHangarHrs = hangarHrs[date] || 0;
    Object.entries(projects).map(([name, taskHrs]: any) => {
      totalTaskHrs += taskHrs;
      projectData[name] = Math.round(taskHrs * 100) / 100;
    });
    const unusedHrs = Math.round((dateHangarHrs - totalTaskHrs || 0) * 100) / 100;
    formattedData.push({
      date,
      Unused: unusedHrs > 0 ? unusedHrs : 0,
      ...projectData,
    });
  });

  return (
    <div>
      <ChartWrapper onClick={() => history.push("/utilization")}>
        <Heading style={{ position: "relative" }}>
          <div>
            <Button
              raised
              style={{ position: "absolute", right: 0, marginRight: "2rem" }}
              onClick={(ev) => {
                ev.stopPropagation();
                setStackedBars(!stackedBars);
              }}
            >
              {stackedBars ? "Inline" : "Stack"}
            </Button>
            Utilization (hours)
          </div>
        </Heading>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            data={formattedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid stroke="#e3e3ed" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            {bars.map((item, i) => {
              return (
                <Bar
                  dataKey={item}
                  key={i}
                  stackId={stackedBars ? "stackId" : null}
                  fill={item === "Unused" ? "#A0A0A0" : colors[i % colors.length]}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </div>
  );
};

export default UtilizationChart;
