import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";
import { useClient } from "./useClient";

interface ParamsT {
  locationId: string;
}
export const useLocationItem = () => {
  const params = useParams<ParamsT>();
  const { locationId } = params;

  const { locations } = useAdminApi();
  const client = useClient();

  if (client) {
    return client.locations?.items?.map((it) => it.location)?.find((it) => it.id === locationId);
  }

  return locations.find((it) => it.id === locationId);
};
