import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useSettings } from "hooks/useSettings";
import { Label } from "./Label";
import { Button } from "./Button";
import { snackbar } from "hooks/useNotifications";
import { AddressInput, BillingType, SigningType, UserRole } from "types/admin/globalTypes";
import SimpleCard from "./SimpleCard";
import { readableBillingType, readableSigningType } from "../common";
import { showErrorMessage } from "../utilities/handleError";
import { useAdminApi } from "hooks/useAdminApi";
import { Form, FormRow, Text, Email, Phone, CountryCode, Number, Select, Checkbox, Textarea } from "components/Form";
import "@rmwc/checkbox/styles";
import styled from "styled-components";
import { FormField } from "./CommonForm";
import { IconButton } from "@rmwc/icon-button";
import Palette from "../palette.json";
import { ShiftFields } from "types/admin/ShiftFields";
import useMultiTenant from "hooks/useMultiTenant";
import _ from "lodash";

const FieldArrayTools = styled.div`
  display: flex;

  > .rmwc-icon {
    margin-top: 6px;
    padding: 4px;
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
`;

interface FormValuesT {
  name?: string;
  email?: string;
  phoneNumber?: string;
  countryCode?: string;
  address: AddressInput;
  terms: string;
  defaultPartMarkup: number;
  defaultSubcontractorMarkup: number;
  defaultLaborHourlyRate: number;
  defaultBillingType: BillingType;
  enablePassDownReport: boolean;
  defaultSigningType?: SigningType | null;
  shifts: ShiftFields[];
}

function SettingsForm() {
  const { settings, updateSettings, fetchSettings } = useSettings();
  const { me, addShifts, updateShifts, deleteShifts } = useAdminApi();
  const { tenantId } = useMultiTenant();
  const [changesDetected, setChangesDetected] = React.useState(false);

  const billingTypeOpts = [];
  for (const [_, v] of Object.entries(BillingType)) {
    billingTypeOpts.push({ label: readableBillingType(v), value: v });
  }

  const signingTypeOpts = [];
  for (const [_, v] of Object.entries(SigningType)) {
    signingTypeOpts.push({ label: readableSigningType(v), value: v });
  }

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      name: settings?.name,
      email: settings?.email,
      countryCode: settings?.phoneNumber?.replace(/^(\+\d{1,2})(\d{3})(\d{3})(\d{4})$/, "$1") || "+1",
      phoneNumber: settings?.phoneNumber?.slice(-10) || "",
      address: {
        street1: settings?.address?.street1,
        street2: settings?.address?.street2,
        city: settings?.address?.city,
        state: settings?.address?.state,
        country: settings?.address?.country || "United States",
        zip: settings?.address?.zip,
      },
      terms: settings?.terms,
      defaultPartMarkup: settings?.defaultPartMarkup || 0,
      defaultSubcontractorMarkup: settings?.defaultSubcontractorMarkup || 0,
      defaultLaborHourlyRate: settings?.defaultLaborHourlyRate || 0,
      defaultBillingType: settings?.defaultBillingType || BillingType.Fixed,
      shifts: settings?.shifts?.items || [],
    },
  });

  const { setValue, watch, control } = form;
  const { fields, append, remove } = useFieldArray({ name: "shifts", control });

  const watchAll = watch();

  const onSubmit = async (data: FormValuesT) => {
    data.defaultPartMarkup = parseFloat(data?.defaultPartMarkup?.toString() || "0");
    data.defaultSubcontractorMarkup = parseFloat(data?.defaultSubcontractorMarkup?.toString() || "0");
    data.defaultSigningType = data?.defaultSigningType || null;

    const shiftsInput = data.shifts;

    data = {
      ...data,
      phoneNumber: `${data.countryCode}${data.phoneNumber}`,
    };

    delete data.countryCode;
    delete data.shifts;

    // all other fields are required, just remove this from data if no value
    if (!data.address.street2) {
      delete data.address.street2;
    }

    const addShiftsData =
      shiftsInput?.filter((it) => !it.id || !settings.shifts?.items.find((jt) => jt.id === it.id)) || [];
    const updateShiftsData =
      shiftsInput?.filter((it) => {
        const existingShift = settings?.shifts?.items.find((jt) => jt.id === it.id);
        return (
          it.id &&
          existingShift &&
          (existingShift.name !== it.name ||
            existingShift.startTime !== it.startTime ||
            existingShift.endTime !== it.endTime)
        );
      }) || [];
    const deleteShiftsData = settings?.shifts?.items.filter((it) => !shiftsInput?.find((jt) => jt.id === it.id));
    console.log(addShiftsData, updateShiftsData, deleteShiftsData);

    try {
      await Promise.all([
        updateSettings({
          settings: data,
        }),
        addShiftsData?.length > 0 &&
          addShifts({
            input: addShiftsData.map((it) => ({
              ownerId: tenantId,
              name: it.name,
              startTime: it.startTime,
              endTime: it.endTime,
            })),
          }),
        updateShiftsData?.length > 0 &&
          updateShifts({
            input: updateShiftsData.map((it) => ({
              id: it.id,
              name: it.name,
              startTime: it.startTime,
              endTime: it.endTime,
            })),
          }),
        deleteShiftsData?.length > 0 &&
          deleteShifts({
            ids: deleteShiftsData.map((it) => it.id),
          }),
      ]);
      snackbar.notify({ title: "Settings updated successfully!" });
      await fetchSettings();
    } catch (error) {
      showErrorMessage(error);
      return;
    }
  };

  useEffect(() => {
    if (!settings) return;
    setValue("name", settings?.name);
    setValue("email", settings?.email);
    setValue("address", settings?.address);
    setValue("phoneNumber", settings?.phoneNumber?.slice(-10));
    setValue("terms", settings?.terms);
    setValue("defaultPartMarkup", settings?.defaultPartMarkup);
    setValue("defaultSubcontractorMarkup", settings?.defaultSubcontractorMarkup);
    setValue("defaultLaborHourlyRate", settings?.defaultLaborHourlyRate);
    setValue("defaultBillingType", settings?.defaultBillingType);
    setValue("defaultSigningType", settings?.defaultSigningType || "");
    setValue("enablePassDownReport", settings?.enablePassDownReport || false);
    setValue("shifts", settings?.shifts?.items || []);
  }, [settings]);

  const checkForChanges = (): boolean => {
    const values = form.getValues();
    if (!settings || !values) return false;

    for (const key of Object.keys(values).filter((it) => !["countryCode"].includes(it) && !!settings[it])) {
      let settingsValue = settings[key] as any;
      const formValue = values[key] as any;

      if (key === "phoneNumber") {
        settingsValue = settingsValue.slice(-10);
      }

      if (!!settingsValue && (typeof settingsValue === "object" || Array.isArray(settingsValue))) {
        if (settingsValue.items) {
          settingsValue = settingsValue.items;
        }
        settingsValue = _.omit(settingsValue, ["__typename"]);

        for (const itKey of Object.keys(settingsValue)) {
          if (!!settingsValue[itKey] && typeof settingsValue[itKey] === "object") {
            for (const itKey2 of Object.keys(settingsValue[itKey]).filter(
              (it) => !["__typename", "ownerId"].includes(it),
            )) {
              if (settingsValue[itKey][itKey2] != formValue[itKey][itKey2]) {
                return true;
              }
            }
          } else if (settingsValue[itKey] != formValue[itKey]) {
            return true;
          }
        }
      } else if (formValue != settingsValue) {
        return true;
      }
    }

    if ((values.shifts?.length || 0) !== (settings?.shifts?.items?.length || 0)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const changed = checkForChanges();
    if (changesDetected !== changed) {
      setChangesDetected(!changesDetected);
    }
  }, [watchAll]);

  const isManager = me?.userRole === UserRole.AdminManager;

  return (
    <div style={{ maxWidth: 900 }}>
      <Prompt
        when={changesDetected}
        message="Make sure you have saved all of your changes. Press 'OK' to leave without saving."
      />
      <div>
        <Form form={form} onSubmit={onSubmit}>
          <SimpleCard heading="Info">
            <FormRow>
              <Text name="name" label="Company Name" disabled={isManager} required />
              <Email name="email" label="Email Address" disabled={isManager} required />
              <CountryCode name="countryCode" disabled={isManager} fieldStyles={{ display: "none" }} hidden required />
              <Phone name="phoneNumber" label="Phone Number" disabled={isManager} required />
            </FormRow>
          </SimpleCard>

          <SimpleCard heading="Address">
            <FormRow>
              <Text name="address.street1" label="Street 1" disabled={isManager} required />
              <Text name="address.street2" label="Street 2" disabled={isManager} />
            </FormRow>

            <FormRow>
              <Text name="address.city" label="City" disabled={isManager} required />
              <Text name="address.state" label="State" disabled={isManager} required />
              <Text name="address.zip" label="Zip Code" disabled={isManager} required />
              <Text name="address.country" label="Country" disabled={isManager} required />
            </FormRow>
          </SimpleCard>

          <SimpleCard heading="Tasks">
            <FormRow col={2}>
              <Number name="defaultPartMarkup" label="Default Part Markup (%)" disabled={isManager} />
              <Number name="defaultSubcontractorMarkup" label="Default Subcontractor Markup (%)" disabled={isManager} />
            </FormRow>

            <FormRow col={2}>
              <Select
                name="defaultBillingType"
                label="Default Billing Type"
                options={billingTypeOpts}
                disabled={isManager}
              />
              <Number name="defaultLaborHourlyRate" label="Default Labor Hourly Rate ($/hr)" disabled={isManager} />
            </FormRow>
            <FormRow col={2}>
              <Select
                name="defaultSigningType"
                label="Default Required Signing Type"
                options={signingTypeOpts}
                disabled={isManager}
                placeholder="None"
              />
              <div style={{ width: "50%" }} />
            </FormRow>
          </SimpleCard>

          <SimpleCard heading="Shifts">
            {fields.map((it, i) => (
              <div key={it.id}>
                <Text name={`shifts[${i}].id`} defaultValue={it.id} disabled={isManager} type="hidden" />
                <FormRow>
                  <Text name={`shifts.${i}.name`} label="Name" defaultValue={it.name} disabled={isManager} required />
                  <Text
                    type="time"
                    name={`shifts.${i}.startTime`}
                    defaultValue={it.startTime}
                    label="Start Time"
                    disabled={isManager}
                    required
                  />
                  <Text
                    type="time"
                    name={`shifts.${i}.endTime`}
                    defaultValue={it.endTime}
                    label="End Time"
                    disabled={isManager}
                    required
                  />
                  <FormField>
                    <Label>&nbsp;</Label>
                    <FieldArrayTools>
                      <IconButton
                        type="button"
                        icon="close"
                        style={{ color: Palette.LightGrey }}
                        onClick={() => remove(i)}
                      />
                    </FieldArrayTools>
                  </FormField>
                </FormRow>
              </div>
            ))}

            <div style={{ marginBottom: "16px" }}>
              <Button icon="add_circle" type="button" onClick={() => append({})}>
                Add Shift
              </Button>
            </div>
          </SimpleCard>

          <SimpleCard heading="Notifications">
            <div>
              <Label>Emails</Label>
              <FormRow>
                <Checkbox name="enablePassDownReport" label="Daily Pass Down Report" style={{ fontSize: "10px" }} />
              </FormRow>
            </div>
          </SimpleCard>

          <SimpleCard heading="Estimates">
            <FormRow style={{ display: "block" }}>
              <Textarea name="terms" label="Terms and Conditions" style={{ fontSize: "10px" }} />
              <Label style={{ textTransform: "none", fontSize: "13px", fontWeight: 400, lineHeight: 1.5 }}>
                Terms and Conditions are displayed at the bottom of estimates. Changing the text here will permanently
                change it for every admin user. If you only want to temporarily change the &quot;Terms and
                Conditions&quot; text for just one estimate, press &quot;Cancel&quot; and enter the text in the text
                area provided on the dialog that appears when you click &quot;Generate Estimate&quot;.
              </Label>
            </FormRow>
          </SimpleCard>

          <FormRow>
            <Button disabled={me?.userRole === UserRole.AdminManager} raised>
              Save Settings
            </Button>
          </FormRow>
        </Form>
      </div>
    </div>
  );
}

export default SettingsForm;
