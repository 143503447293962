import React from "react";
import { PageHeader } from "components/PageHeader";
import "@rmwc/circular-progress/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import { PageContent, PageMain } from "components/PageStyles";
import useLayout from "hooks/useLayout";
import { ProjectStatusLabel } from "components/StatusLabel";
import { ProjectStatus } from "types/admin/globalTypes";
import { generateProgressXLSX } from "../../common";
import dayjs from "dayjs";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";

export const ProgressPage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();
  const { projects } = useAdminApi();
  const history = useHistory();
  const acceptedProjects = projects.filter((project) => project.status === ProjectStatus.Accepted);

  const handleDownload = async () => {
    await generateProgressXLSX(dayjs().format("MM/DD/YYYY"), acceptedProjects);
  };

  React.useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Progress Report",
        path: "/progress",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeader title="Progress Report" />
      <PageMain>
        <PageContent>
          <TableContainer>
            <TableTitle>
              <TableToolbarTitle>
                Active Work Orders <TitleCount>({acceptedProjects.length})</TitleCount>
              </TableToolbarTitle>
              <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
                Download Report
              </Button>
            </TableTitle>

            <Table>
              <DataTableContent>
                <TableHead cols={["Work Order", "Status", "Actual Hours", "Estimated Hours", "Efficiency"]}></TableHead>
                <DataTableBody data-test-id="pulse-reports-list">
                  {acceptedProjects.map((it) => {
                    const efficiency =
                      it.estimateManHrs === 0
                        ? 0
                        : Math.round((it.estimateManHrs / (it.progress.completedHours || 0)) * 100);
                    return (
                      <DataTableRow key={it.id} onClick={() => history.push(`/progress/${it.id}`)}>
                        <DataTableCell>{it.name}</DataTableCell>
                        <DataTableCell>
                          <ProjectStatusLabel status={it.status}>{it.status}</ProjectStatusLabel>
                        </DataTableCell>
                        <DataTableCell>{Math.round(it.progress.completedHours * 100) / 100}</DataTableCell>
                        <DataTableCell>{Math.round(it.estimateManHrs * 100) / 100}</DataTableCell>
                        <DataTableCell>{Math.min(efficiency, 100)}%</DataTableCell>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTableContent>
            </Table>
          </TableContainer>
        </PageContent>
      </PageMain>
    </>
  );
};
