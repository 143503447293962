import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import { snackbar } from "hooks/useNotifications";
import { useFirebase } from "hooks/useFirebase";
import { useHistory } from "react-router";
import { Form, FormRow } from "components/Form";
import { FormField } from "components/CommonForm";
import { Label } from "components/Label";
import { PasswordInput } from "components/TextInput";
import { Button } from "components/Button";
import { showErrorMessage } from "utilities/handleError";

const HiddenField = styled.input``;
HiddenField.defaultProps = { type: "hidden" };

interface FormValuesT {
  id: string;

  currentPassword: string;

  password: string;
  passwordConfirmation: string;
}

export const PasswordResetPage: React.FC = () => {
  const history = useHistory();
  const { user, signInWithEmailAndPassword } = useFirebase();
  const { me } = useAdminApi();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });
  const { formState, handleSubmit, register, reset } = form;
  const { isValid } = formState;

  const doSubmit = handleSubmit(async (values) => {
    if (!user) return;

    if (values.password !== values.passwordConfirmation) {
      snackbar.notify({ title: "Passwords do not match!" });
      return;
    }

    try {
      await signInWithEmailAndPassword(user.email || "", values.currentPassword);
      await user.updatePassword(values.password);
      reset();
      snackbar.notify({ title: "Password changed successfully!" });
    } catch (error) {
      showErrorMessage(error);
    }
  });

  return (
    <Form form={form} onSubmit={doSubmit} style={{ margin: "0", maxWidth: "320px" }}>
      <HiddenField name="id" value={me?.id} ref={register({ required: true })} />

      <FormRow>
        <FormField>
          <Label htmlFor="currentPassword">Current Password</Label>
          <PasswordInput name="currentPassword" ref={register({ required: true })} />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Label htmlFor="password">Password</Label>
          <PasswordInput name="password" ref={register({ required: true })} />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <Label htmlFor="passwordConfirmation">Confirm</Label>
          <PasswordInput name="passwordConfirmation" ref={register({ required: true })} />
        </FormField>
      </FormRow>

      <Button raised disabled={!isValid}>
        Update Password
      </Button>
    </Form>
  );
};
