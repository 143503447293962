import { arrayMoveImmutable } from "array-move";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { DeleteTaskVariables } from "types/admin/DeleteTask";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import { TaskStatus } from "types/admin/globalTypes";

import { useTask } from "hooks/params/useTask";
import { useAdminApi } from "hooks/useAdminApi";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import useFiltered from "hooks/useFiltered";
import useSorted, { sortByProperty } from "hooks/useSorted";

import { Button } from "components/Button";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";
import { TASK_STATUS_DISPLAY_STRINGS, TaskStatusLabel } from "../StatusLabel";

import "@rmwc/avatar/styles";

import { Badge, BadgeAnchor } from "@rmwc/badge";
import "@rmwc/badge/styles";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { Menu, MenuItem, MenuSurfaceAnchor, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import dayjs from "dayjs";

import styled from "styled-components";

import { useProject } from "hooks/params/useProject";
import { useTemplate } from "hooks/params/useTemplate";
import { FocusModeProvider, useFocusMode } from "hooks/useFocusMode";
import { useLocation } from "react-router-dom";
import { showErrorMessage } from "utilities/handleError";
import Palette from "../../palette.json";
import EmptyState from "../EmptyState";
import { TableAssignmentCell } from "../TableAssignmentCell";
import { TableHoursCell } from "../TableHoursCell";
import { TableProgressCell } from "../TableProgressCell";
import TaskListFocusMode from "./TaskListFocusMode";
import LineThrough from "components/LineThrough";
import DisabledWrapper from "components/DisabledWrapper";

export const TaskNameCell = styled.td`
  padding: 0 16px 0 8px !important;

  > div {
    display: flex;
    align-items: center;
    vertical-align: middle;

    .rmwc-icon {
      :first-of-type {
        margin-left: 16px;
      }
      color: ${Palette.LightGrey};
    }

    .rmwc-badge {
      padding: 0 4px;
      min-width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
      font-size: 10px;
      background-color: ${Palette.MediumGrey};
    }

    .drag-handle {
      margin: 0px 8px 0px 0px !important;
      cursor: grab;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

const ScheduledDateCell = styled.td<{ date: dayjs.Dayjs }>`
  color: ${(p) => (p.date.isBefore(dayjs()) ? Palette.Red : Palette.MediumGrey)};
`;

const DragHandle = SortableHandle(() => <Icon icon="drag_indicator" className="drag-handle" />);

const SortableItem = SortableElement(
  (props: { it: TaskFields; index: number; urlSegment: string; onEdit?: any; onDelete?: any }) => {
    const { it, urlSegment, onEdit, onDelete } = props;
    const { focusModeIsEnabled, toggleFocusMode } = useFocusMode();
    const [menuOpen, setMenuOpen] = useState(false);
    const { clockInUser, clockOutUser } = useAdminApi();
    const { template, isTemplateTypeTask } = useTemplate();
    const project = useProject();
    const { task: parentTask } = useTask();
    const history = useHistory();

    const isTemplate = !!template;
    const isTodoList = !!parentTask || isTemplateTypeTask;
    const isTaskDeleted = it.status === TaskStatus.Deleted;
    const isTaskCompleted = it.status === TaskStatus.Completed;
    const isRequested = it.status === TaskStatus.Requested;
    const isWorkOrderTasks = project && !isTodoList && !isTemplate && !parentTask;
    console.log(it.status);

    const tableRowStyle = {
      ...(isRequested && { backgroundColor: `${Palette.Red}20` }),
      ...(isRequested && { "&:hover": { backgroundColor: `${Palette.Red}30 !important` } }),
      ...(isTaskCompleted && { backgroundColor: `${Palette.UltraLightGrey}` }),
    };

    const toggleClockUserWithUserId = async (userId: string, action: "Clock In" | "Clock Out") => {
      try {
        if (action === "Clock Out") {
          await clockOutUser({ userId, taskId: it.id, toDt: dayjs().toISOString() });
        } else {
          await clockInUser({ userId, taskId: it.id, fromDt: dayjs().toISOString() });
        }
      } catch (error) {
        showErrorMessage(error);
      }
    };

    return (
      <DataTableRow style={tableRowStyle}>
        <TaskNameCell
          className="rmwc-data-table__cell"
          data-test-id="task-name-cell"
          onClick={() => {
            if (!parentTask && !isTemplateTypeTask) {
              if (focusModeIsEnabled) {
                toggleFocusMode();
              }
              history.push(`/${isTemplate ? "templates" : "work-orders"}/${it.parentId}/tasks/${it.id}`);
            }
          }}
        >
          <div>
            <DisabledWrapper enabled={isTaskCompleted}>
              <DragHandle />
            </DisabledWrapper>
            <div>
              <LineThrough enabled={it.status === TaskStatus.Completed}>
                <span>{it?.orderIndex}</span> - {it.name}
              </LineThrough>
            </div>
            {!parentTask && (
              <>
                {(it.messages?.items || []).length > 0 && (
                  <BadgeAnchor>
                    <IconButton
                      icon="message"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(
                          `/${isTemplate ? "templates" : "work-orders"}/${it.parentId}/tasks/${it.id}/messages`,
                        );
                      }}
                    />
                    <Badge inset="12px" style={{ backgroundColor: Palette.Red }} label={it.messages?.items.length} />
                  </BadgeAnchor>
                )}
                {(it.attachments?.items || []).length > 0 && (
                  <BadgeAnchor>
                    <IconButton
                      icon="attachment"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(
                          `/${isTemplate ? "templates" : "work-orders"}/${it.parentId}/tasks/${it.id}/attachments`,
                        );
                      }}
                    />
                    <Badge inset="12px" style={{ backgroundColor: Palette.Red }} label={it.attachments?.items.length} />
                  </BadgeAnchor>
                )}
              </>
            )}
          </div>
        </TaskNameCell>

        {!isTodoList && (
          <>
            {!isTaskDeleted ? (
              <TableAssignmentCell
                project={project}
                task={it}
                urlSegment={urlSegment}
                onClickAvatar={toggleClockUserWithUserId}
              />
            ) : (
              <DataTableCell></DataTableCell>
            )}

            {!isWorkOrderTasks && <TableHoursCell task={it} />}

            {!isTemplate && typeof it.progress?.progressTimePercentage === "number" && <TableProgressCell task={it} />}
            {(!isWorkOrderTasks || focusModeIsEnabled) && (
              <DataTableCell>
                <TaskStatusLabel status={it.status || TaskStatus.OnHold}>
                  {TASK_STATUS_DISPLAY_STRINGS[it.status || TaskStatus.OnHold]}
                </TaskStatusLabel>
              </DataTableCell>
            )}
          </>
        )}
        {!isTemplate && !isTodoList && (
          <ScheduledDateCell className="rmwc-data-table__cell" date={dayjs(it.dueDate)}>
            <LineThrough enabled={isTaskCompleted}>
              {it.dueDate ? dayjs(it.dueDate).tz("UTC").format("MM/DD/YYYY") : "-"}
            </LineThrough>
          </ScheduledDateCell>
        )}

        {!isTaskDeleted && (
          <DataTableCell>
            <DataCellTools>
              {!parentTask && !isTemplateTypeTask && !focusModeIsEnabled && (
                <DisabledWrapper enabled={isTaskCompleted}>
                  <IconButton
                    icon="today"
                    data-test-id="edit-btn"
                    onClick={() => history.push(`/work-orders/${project.id}/tasks/${it.id}/schedule`)}
                  />
                </DisabledWrapper>
              )}

              <MenuSurfaceAnchor>
                <Menu open={menuOpen} onClose={(evt) => setMenuOpen(false)} renderToPortal>
                  <MenuItem onClick={() => onEdit(it)} data-test-id="edit-btn">
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => onDelete(it)} data-test-id="btn-delete">
                    Delete
                  </MenuItem>
                </Menu>

                <IconButton icon="more_vert" data-test-id="edit-btn" onClick={() => setMenuOpen(true)} />
              </MenuSurfaceAnchor>
            </DataCellTools>
          </DataTableCell>
        )}
      </DataTableRow>
    );
  },
);

const SortableTableBody = SortableContainer(
  (props: { items: TaskFields[]; isTodoList: boolean; urlSegment: string; onEdit: any; onDelete: any; onAdd: any }) => {
    const { items, isTodoList, urlSegment, onAdd, onEdit, onDelete } = props;

    return (
      <DataTableBody data-test-id="task-list">
        {items.length === 0 ? (
          <DataTableRow>
            <DataTableCell colSpan={7}>
              <EmptyState
                icon="checklist"
                text={isTodoList ? "No todos found. Add first todo." : "No tasks found. Add first task."}
                buttonText={isTodoList ? "New todo" : "New Task"}
                data-test-id={isTodoList ? "create-todo-btn" : "create-task-btn"}
                onClick={onAdd}
              />
            </DataTableCell>
          </DataTableRow>
        ) : (
          items.map((it, index) =>
            it.status !== TaskStatus.Deleted ? (
              <SortableItem
                key={`item-${it?.id}`}
                urlSegment={urlSegment}
                index={index}
                it={it}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ) : (
              <SortableItem key={`item-${it?.id}`} urlSegment={urlSegment} index={index} it={it} />
            ),
          )
        )}
      </DataTableBody>
    );
  },
);

interface TaskListProps {
  project?: ProjectFields;
  items: TaskFields[];
}

export const TaskList: React.FC<TaskListProps> = ({ project, items }) => {
  return (
    <FocusModeProvider>
      <TaskListInner project={project} items={items} />
    </FocusModeProvider>
  );
};

const TaskListInner: React.FC<TaskListProps> = ({ project, items }) => {
  const history = useHistory();
  const location = useLocation();

  const { template, isTemplateTypeTask, isTemplateTypeProject } = useTemplate();
  const { deleteTask, updateTaskOrder, getProjectAndListTasks, getTemplateTasks } = useAdminApi();
  const { task: parentTask } = useTask();
  const { toggleFocusMode, focusModeIsEnabled } = useFocusMode();

  const isTemplate = !!template;
  const isTodoList = !!parentTask || isTemplateTypeTask;
  const isTaskDeleted = parentTask?.status === TaskStatus.Deleted;
  const isWorkOrderTasks = project && !isTodoList && !isTemplate && !parentTask;

  const urlSegment = isTemplate ? `/templates/${template?.id}` : `/work-orders/${project?.id}`;
  const sorted = useSorted(items, (a, b) => {
    // sort the completed tasks to the bottom
    if (a.status === TaskStatus.Completed && b.status !== TaskStatus.Completed) return 1;
    if (a.status !== TaskStatus.Completed && b.status === TaskStatus.Completed) return -1;
    return 0;
  });

  const filterByStatusItems = [
    "All",
    TaskStatus.Requested,
    TaskStatus.Upcoming,
    TaskStatus.InProgress,
    TaskStatus.OnHold,
    TaskStatus.Declined,
    TaskStatus.Completed,
    TaskStatus.Deleted,
  ];
  const [filterByStatus, setFilterByStatus] = useState<number>(0);

  const filtered = useFiltered(sorted, (it) => {
    if (filterByStatus === 0) return true && it.status !== TaskStatus.Deleted;
    return it.status === filterByStatusItems[filterByStatus];
  });

  const cols = ["Name"];

  if (!parentTask) {
    if (isTemplate) {
      if (!isTemplateTypeTask) {
        ["Assigned To", "Estimated Hours", "Status"].forEach((it) => cols.push(it));
      }
    } else {
      if (isWorkOrderTasks) {
        if (focusModeIsEnabled) {
          ["Assigned To", "Progress", "Status"].forEach((it) => cols.push(it));
        } else {
          ["Assigned To", "Progress"].forEach((it) => cols.push(it));
        }
      } else {
        ["Assigned To", "Hours", "Progress", "Status"].forEach((it) => cols.push(it));
      }
    }
  }

  if (!isTemplate && !isTodoList) {
    ["Due Date", ""].map((it) => cols.push(it));
  }

  const onEdit = (task: TaskFields) => {
    if (isTodoList) {
      if (template) {
        history.push(`/templates/${template.id}/tasks/${task.parentId}/edit?tab=Todos`);
      } else {
        history.push(`/work-orders/${project.id}/tasks/${task.parentId}/edit?tab=Todos`);
      }
    } else {
      history.push(`${location.pathname}/${task.id}/edit_`);
    }
  };

  const onDelete = async (task: TaskFields) => {
    let hasConfirmed = false;

    if (isTodoList) {
      hasConfirmed = await dialogQueue.confirm({
        title: "Are you sure?",
        body: `Are you sure you want to delete todo: ${task.name}`,
        acceptLabel: "Confirm",
        cancelLabel: "Cancel",
      });
    } else {
      hasConfirmed = await dialogQueue.confirm({
        title: "Are you sure?",
        body: `Are you sure you want to delete task: ${task.name}`,
        acceptLabel: "Confirm",
        cancelLabel: "Cancel",
      });
    }

    if (!hasConfirmed) return;

    const variables = task as DeleteTaskVariables;
    variables.projectId = task.projectId ?? "";
    try {
      await deleteTask(variables, isTemplateTypeProject, isTemplateTypeTask, isTodoList);
    } catch (error) {
      showErrorMessage(error);
      return;
    }

    if (isTodoList) {
      snackbar.notify({ title: "Todo removed successfully!" });
    } else {
      snackbar.notify({ title: "Task removed successfully!" });
    }

    setTimeout(() => {
      // get fresh data
      fetchFreshData();
    }, 4000);
  };

  const getTableTitle = () => {
    if (isTemplate) {
      if (isTodoList) {
        return "Todos";
      } else {
        return "Tasks";
      }
    }

    if (isTodoList) {
      return "Todos";
    } else {
      return "Tasks";
    }
  };

  const openNewTaskDialog = () => {
    if (isTemplateTypeTask) {
      history.push(`${urlSegment}/todos/new`);
      return;
    }

    history.push(`${urlSegment}/tasks/${parentTask ? parentTask.id + "/todos/" : ""}new`);
  };

  const onSortEnd = (event: any) => {
    const { oldIndex, newIndex } = event;
    const sorted = arrayMoveImmutable(filtered, oldIndex, newIndex);
    const updatedArray = sorted.map((it, index) => {
      it.orderIndex = index + 1;
      return it;
    });
    updateTaskOrder(updatedArray);
  };

  const fetchFreshData = () => {
    if (project?.id) {
      getProjectAndListTasks({
        projectId: project.id,
        criteria: {
          parentId: parentTask?.id ?? project.id,
        },
      });
    } else if (template?.id) {
      getTemplateTasks(template.id);
    }
  };

  useEffect(() => {
    fetchFreshData();
  }, []);

  return (
    <TaskListFocusMode>
      <TableContainer>
        <TableTitle>
          <TableToolbarTitle>
            {getTableTitle()} <TitleCount>({filtered.length})</TitleCount>
          </TableToolbarTitle>

          {!isTodoList && (
            <SimpleMenu
              anchorCorner="bottomStart"
              focusOnOpen={false}
              handle={
                <Button outlined trailingIcon="unfold_more">
                  {filterByStatus === 0
                    ? "All"
                    : TASK_STATUS_DISPLAY_STRINGS[filterByStatusItems[filterByStatus] as TaskStatus]}
                </Button>
              }
              onSelect={(ev) => setFilterByStatus(ev.detail.index)}
            >
              {filterByStatusItems.map((it, i) => (
                <MenuItem key={i}>{i === 0 ? "All" : TASK_STATUS_DISPLAY_STRINGS[it as TaskStatus]}</MenuItem>
              ))}
            </SimpleMenu>
          )}

          {!isTaskDeleted && (
            <Button raised onClick={openNewTaskDialog} data-test-id="task-list-new-btn" style={{ marginRight: 12 }}>
              New {isTemplateTypeTask ? "Todo" : parentTask ? "Todo" : "Task"}
            </Button>
          )}

          {!isTodoList && !isTemplate && (
            <div>
              <IconButton
                icon={focusModeIsEnabled ? "fullscreen_exit" : "fullscreen"}
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleFocusMode();
                }}
              />
            </div>
          )}
        </TableTitle>

        <Table>
          <DataTableContent>
            <TableHead {...{ cols }} />
            <SortableTableBody
              items={filtered}
              isTodoList={isTodoList}
              urlSegment={urlSegment}
              useDragHandle
              onSortEnd={onSortEnd}
              onEdit={onEdit}
              onDelete={onDelete}
              onAdd={openNewTaskDialog}
            />
          </DataTableContent>
        </Table>
      </TableContainer>
    </TaskListFocusMode>
  );
};
