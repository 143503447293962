import React, { useState } from "react";
import { useHistory } from "react-router";

import { EntityType, FileType } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useTask } from "hooks/params/useTask";
import { useFieldArray, useForm } from "react-hook-form";

import { Dialog } from "../Dialog";

import { Form, FormRow, Text } from "components/Form";

import { FileUploadInput } from "../FileUploadInput";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import axios from "axios";
import numeral from "numeral";

import Palette from "../../palette.json";
import { useTemplate } from "hooks/params/useTemplate";
import { usePart } from "hooks/params/usePart";

export const fileTypeFromMimeType = (mimeType: string): FileType => {
  if (mimeType.toLowerCase().startsWith("video/")) {
    return FileType.Video;
  } else if (mimeType.toLowerCase().startsWith("image/")) {
    return FileType.Image;
  }

  return FileType.Document;
};

export interface FormValuesT {
  uploads: {
    fileName: string;
    fileSize: number;
    mimeType: string;
  }[];
}

export interface AttachmentFormDialogPropsT {
  action: "create" | "update";
}

const HIDDEN_FIELD_NAMES: string[] = [];

const UploadProgress: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div
      style={{ width: "40px", height: "40px", display: "flex", justifyContent: "space-around", alignItems: "center" }}
    >
      {progress === 1 ? (
        <Icon style={{ width: "22px", color: Palette.Green }} icon="checkmark" />
      ) : (
        <CircularProgress {...{ progress }} />
      )}
    </div>
  );
};

export const AttachmentFormDialog: React.FC<AttachmentFormDialogPropsT> = ({ action }) => {
  const history = useHistory();

  const { task: parentTask } = useTask();
  const { currentPart } = usePart();
  const { template } = useTemplate();

  const { addAttachment, getUploadUrl } = useAdminApi();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      uploads: [],
    },
  });

  const { control, register } = form;

  const uploads = useFieldArray({ control, name: "uploads" });
  const [progressItems, setProgressItems] = useState<{ fileName: string; progress: number }[]>([]);

  const title = action === "create" ? "Add Attachments" : "Update Attachment";

  const onUploadProgress = (fileName: string, loaded: number, total: number) => {
    const fieldIndex = progressItems.findIndex((it) => it.fileName === fileName);
    let progress = (1.0 / total) * loaded;
    if (progress >= 0.99) {
      progress = 1;
    }
    console.info(fileName, fieldIndex, progress);
    setProgressItems((p) => p.map((it) => (it.fileName === fileName ? { fileName, progress } : it)));
  };

  const onFileSelected = async (files: File[]) => {
    if (!parentTask && !template && !currentPart) return;

    for (const file of files) {
      const upload = await getUploadUrl(file.type);

      uploads.append({ fileName: file.name, fileSize: file.size, mimeType: file.type });
      setProgressItems((p) => [...p, { fileName: file.name, progress: 0 }]);

      // Debounce for above state refresh ...
      setTimeout(() => {
        axios.put(upload.url, file, {
          headers: { "content-type": file.type },
          onUploadProgress: (ev: ProgressEvent) => onUploadProgress(file.name, ev.loaded, ev.total),
        });
      }, 500);

      const entityId = currentPart?.id || parentTask?.id || template?.id;

      if (!entityId) return;

      const response = await addAttachment(
        {
          input: {
            id: upload.attachmentId,
            entityId,
            entityType: currentPart ? EntityType.PART : EntityType.TASK,
            fileName: file.name,
            fileType: fileTypeFromMimeType(file.type),
            mimeType: file.type,
            file: {
              bucket: upload.bucket,
              key: upload.key,
              region: upload.region,
            },
          },
        },
        parentTask?.id,
        template?.id,
      );
    }
  };

  const onSubmit = form.handleSubmit(async (values) => {
    console.info("***", values);
  });

  const onClose = (ev: DialogOnCloseEventT) => {
    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit}>
          {HIDDEN_FIELD_NAMES.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          {uploads.fields.map(({ fileName, fileSize, mimeType }, i) => (
            <FormRow key={i} style={{ display: "flex", alignItems: "end" }}>
              <Text name={`uploads.${i}.fileName`} label="File name" defaultValue={fileName} required disabled />
              <Text
                name={`uploads.${i}.fileName`}
                label="Size"
                defaultValue={numeral(fileSize).format("0.00b")}
                style={{ width: "5rem" }}
                disabled
              />
              <Text name={`uploads.${i}.mimeType`} label="File name" defaultValue={mimeType} required disabled />
              <UploadProgress progress={progressItems.find((it) => it.fileName === fileName)?.progress || 0} />
            </FormRow>
          ))}

          <FileUploadInput onChange={onFileSelected} />
        </Form>
      </DialogContent>

      <DialogActions>
        <div style={{ flexGrow: 1 }} />

        <DialogButton isDefaultAction disabled={!!progressItems.find((it) => it.progress !== 1)} action="accept">
          Close
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
