import React, { useEffect } from "react";
import useLayout from "hooks/useLayout";
import { PageContent, PageMain } from "components/PageStyles";
import { PageHeader } from "components/PageHeader";
import { ProjectCalendar } from "components/schedule/ProjectCalendar";

export const SchedulePage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: "Schedule", path: "/schedule" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeader title="Schedule" />

      <PageMain>
        <PageContent>
          <ProjectCalendar />
        </PageContent>
      </PageMain>
    </>
  );
};
