import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import "@rmwc/icon-button/styles";
import { Button } from "components/Button";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle } from "components/Table";
import { TextInput } from "components/TextInput";
import dayjs, { Dayjs } from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { DashboardContainer } from "pages/dashboard";
import UtilizationChart from "pages/dashboard/UtilizationChart";
import React from "react";
import { useHistory } from "react-router-dom";
import { TimesheetFields } from "types/admin/TimesheetFields";
import { UserWorkPayType } from "types/admin/globalTypes";
import { generateEfficiencyXLSX } from "../../common";
import styled from "styled-components";

const FullWidthDiv = styled.div`
  grid-column: span 3 / span 3;
`;

export const UtilizationTable = () => {
  const history = useHistory();
  const { getTimesheets } = useAdminApi();
  const [timesheets, setTimesheets] = React.useState<TimesheetFields[]>([]);
  const [from, setFrom] = React.useState<Dayjs>(dayjs().startOf("week"));
  const [to, setTo] = React.useState<Dayjs>(dayjs().endOf("week"));
  const fromString = from.format();
  const toString = to.format();

  React.useEffect(() => {
    getTimesheets(dayjs(fromString), dayjs(toString)).then((results) => {
      setTimesheets(results);
    });
  }, [fromString, toString]);

  const daysInBetween = to.diff(from, "day");
  const workableDays = Math.ceil(daysInBetween / 7) * 5;
  const clockedInTimesheets = timesheets.filter((it) => ["InHangar", "Driving"].includes(it?.taskId || ""));
  const taskTimesheets = timesheets.filter((it) => !["InHangar", "Driving"].includes(it?.taskId || ""));

  // get all inHangarTimesheets grouped by user
  const clockedInTimesheetsByUser = clockedInTimesheets.reduce((acc, it) => {
    const user = it.user;
    const userId = user?.id;
    if (!userId) {
      return acc;
    }
    if (!acc[userId]) {
      acc[userId] = [];
    }
    acc[userId].push(it);
    return acc;
  }, {} as { [userId: string]: TimesheetFields[] });

  const taskTimesheetsByUser = taskTimesheets.reduce((acc, it) => {
    const user = it.user;
    const userId = user?.id;
    if (!userId) {
      return acc;
    }
    if (!acc[userId]) {
      acc[userId] = [];
    }
    acc[userId].push(it);
    return acc;
  }, {} as { [userId: string]: TimesheetFields[] });

  const data = [];
  for (const key of Object.keys(taskTimesheetsByUser)) {
    const clockedInSheets = clockedInTimesheetsByUser[key];
    const taskSheets = taskTimesheetsByUser[key];
    const [firstSheet] = taskSheets;
    const user = firstSheet?.user;

    let taskTime = taskSheets?.reduce((acc, it) => acc + (it?.amountMins || 0) / 60, 0) || 0;
    taskTime = Math.round(taskTime * 100) / 100;

    let clockedTime =
      user?.type !== UserWorkPayType.Salary
        ? clockedInSheets?.reduce((acc, it) => acc + (it?.amountMins || 0) / 60, 0) || 0
        : 8 * workableDays;
    clockedTime = Math.round(clockedTime * 100) / 100;

    let percent = clockedTime ? (taskTime / clockedTime) * 100 : 0;
    percent = Math.round(percent * 100) / 100;
    const efficiency = `${percent}%`;

    data.push({
      userId: user?.id,
      name: `${user?.firstName} ${user?.lastName}`,
      isSalaried: user?.type === UserWorkPayType.Salary,
      taskTime,
      clockedTime,
      efficiency,
    });
  }

  const handleDownload = async () => {
    await generateEfficiencyXLSX(from.format("MM/DD/YYYY"), to.format("MM/DD/YYYY"), data);
  };

  return (
    <DashboardContainer>
      <FullWidthDiv>
        <UtilizationChart startDate={from} endDate={to} />

        <TableContainer>
          <TableTitle>
            <TableToolbarTitle>
              <TextInput
                type="date"
                value={from.format("YYYY-MM-DD")}
                onChange={(e) => {
                  const newFrom = dayjs(e.target.value, "YYYY-MM-DD");
                  if (!newFrom.isValid()) {
                    return;
                  }
                  setFrom(newFrom.startOf("day"));
                }}
                data-test-id="from-date-input"
              />
              &nbsp;⟶&nbsp;
              <TextInput
                type="date"
                value={to.format("YYYY-MM-DD")}
                onChange={(e) => {
                  const newTo = dayjs(e.target.value, "YYYY-MM-DD");
                  if (!newTo.isValid()) {
                    return;
                  }
                  setTo(dayjs(newTo.endOf("day")));
                }}
                data-test-id="to-date-input"
              />
            </TableToolbarTitle>
            <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
              Download Report
            </Button>
          </TableTitle>

          <Table>
            <DataTableContent>
              <TableHead cols={["Employee Name", "Task Time (Hours)", "Clocked Time (Hours)", "% Utilized"]} />
              <DataTableBody data-test-id="projects-list">
                {data.length === 0 && (
                  <DataTableRow>
                    <DataTableCell colSpan={4}>
                      <span>No data available for the specified period.</span>
                    </DataTableCell>
                  </DataTableRow>
                )}
                {data.map((it, i) => (
                  <DataTableRow key={i}>
                    <DataTableCell
                      onClick={() => {
                        history.push(`/people/${it.userId}`);
                      }}
                    >
                      {it.name}
                    </DataTableCell>
                    <DataTableCell>{it.taskTime}</DataTableCell>
                    <DataTableCell>{it.clockedTime}</DataTableCell>
                    <DataTableCell>{it.efficiency}</DataTableCell>
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTableContent>
          </Table>
        </TableContainer>
      </FullWidthDiv>

      {/* <div>
        <UtilizationChart startDate={from} endDate={to} />
      </div> */}
    </DashboardContainer>
  );
};
