import React from "react";
import { PageHeader } from "components/PageHeader";

import { PageContent, PageMain } from "components/PageStyles";

import { TrendList } from "./TrendList";

export const TrendsPage: React.FC = () => {
  return (
    <>
      <PageHeader title={"Trend Report"} />

      <PageMain>
        <PageContent>
          <TrendList />
        </PageContent>
      </PageMain>
    </>
  );
};
