import React from "react";

import { Button } from "./Button";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";
import { CircularProgress } from "@rmwc/circular-progress";
import { ThemeProvider } from "@rmwc/theme";

interface ExportXLSXButtonProps {
  onClick: () => void;
  showLabel?: boolean;
  loading: boolean;
  [key: string]: any;
}

export const ExportXLSXButton: React.FC<ExportXLSXButtonProps> = ({ onClick, showLabel = true, loading, ...props }) => {
  return (
    <ThemeProvider options={{ primary: Palette.Green }}>
      <Button
        {...props}
        raised
        onClick={onClick}
        data-test-id="btn-complete-project"
        style={{ padding: "10px", marginRight: 0 }}
      >
        <span style={{ display: "flex", alignItems: "center", color: "white" }}>
          {!loading ? (
            <Icon icon="cloud_download" style={{ marginRight: showLabel ? "0.5rem" : "0rem" }} />
          ) : (
            <ThemeProvider options={{ primary: "#fff" }}>
              <CircularProgress
                className="loadingIndicator"
                style={{ marginRight: showLabel ? "0.5rem" : "0rem" }}
                {...{ progress: 0.25 }}
              />
            </ThemeProvider>
          )}
          {showLabel && "Export Excel"}
        </span>
      </Button>
    </ThemeProvider>
  );
};
