import React from "react";
import { useProject } from "hooks/params/useProject";
import { TaskCalendar } from "components/schedule/TaskCalendar";
import { useTask } from "hooks/params/useTask";

export const ProjectSchedulePage: React.FC = () => {
  const project = useProject();
  const { task } = useTask();

  if (!project && !task) return <></>;

  return <TaskCalendar />;
};
