import React from "react";

import { formatPrice, getCost } from "../../common";

import { PartFields } from "types/admin/PartFields";

import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "components/Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import Palette from "../../palette.json";
import { useHistory } from "react-router-dom";
import { useProject } from "hooks/params/useProject";
import useTranslations from "hooks/useTranslations";

const totalStyle = {
  fontWeight: 500,
  fontSize: "16px",
};

export interface PartListFields {
  part: PartFields;
  name: string;
  taskId: string;
}

interface PartListProps {
  items: (PartListFields | null)[];
  total?: number;
}

export const PartList: React.FC<PartListProps> = ({ items, total }) => {
  const { tCommon, tFeature } = useTranslations();
  const project = useProject();
  const history = useHistory();

  if (!project) return <></>;

  const cols: string[] = [
    // "Task Name",
    tCommon("PartNumber"),
    "Description",
    "Quantity",
    "Unit Price",
    "Lead Time",
    "Billable",
    "Cost",
  ];

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {tCommon("Parts")} <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        {/* <Button raised onClick={() => history.replace(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Parts`)}>
          Manage
        </Button> */}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={cols.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {items.map((it, i) => (
              <DataTableRow onClick={() => history.push(`/work-orders/${project.id}/tasks/${it?.taskId}`)} key={i}>
                {/* <DataTableCell>{it?.name}</DataTableCell> */}
                <DataTableCell>{it?.part?.number}</DataTableCell>
                <DataTableCell>{it?.part?.name}</DataTableCell>
                <DataTableCell>{it?.part?.quantity}</DataTableCell>
                <DataTableCell>{formatPrice(getCost(it?.part) as number)}</DataTableCell>
                <DataTableCell>{it?.part?.leadTimeDays}</DataTableCell>
                <DataTableCell>
                  <Checkbox disabled checked={it?.part?.isBillable || false} />
                </DataTableCell>
                <DataTableCell>
                  {it?.part?.isBillable ? formatPrice((getCost(it.part) * it.part.quantity) as number) : "-"}
                </DataTableCell>
              </DataTableRow>
            ))}

            <DataTableRow>
              <DataTableCell style={totalStyle}>{tFeature("PartList.totalLabel")}</DataTableCell>
              {[...Array(cols.length - 2).keys()].map((col, i) => (
                <DataTableCell key={i} />
              ))}
              <DataTableCell style={totalStyle}>{formatPrice(total || 0)}</DataTableCell>
            </DataTableRow>
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
