import React from "react";
import { useHistory } from "react-router-dom";
import { useTask } from "hooks/params/useTask";
import { useProject } from "hooks/params/useProject";
import { TaskList } from "./TaskList";
import { TaskFields } from "types/admin/TaskFields";
import { TaskMessagesPage } from "pages/projects/[projectId]/tasks/[taskId]/messages";
import { TaskAttachmentsPage } from "pages/projects/[projectId]/tasks/[taskId]/attachments";
import styled from "styled-components";
import { CorrectiveActionsList } from "components/correctiveAction/CorrectiveActionsList";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 3rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
`;

interface TaskDetailsProps {
  correctiveActions?: any[];
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ correctiveActions }) => {
  const project = useProject();
  const { task } = useTask();

  return (
    <Grid>
      <TaskList project={project} items={task.tasks?.items as TaskFields[]} />
      <TaskMessagesPage />
      <CorrectiveActionsList correctionActions={correctiveActions} />
      <TaskAttachmentsPage />
    </Grid>
  );
};
