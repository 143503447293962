import dayjs from "dayjs";
import { Period } from "components/DateRangePicker";

export const usePeriodDates = (period: any) => {
  if (period === Period.Today) {
    const start = dayjs().startOf("day");
    const end = dayjs().endOf("day");
    const prevStart = start.subtract(1, "day");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }

  if (period === Period.ThisWeek) {
    const duration = 7;
    const start = dayjs().startOf("week");
    const end = dayjs().endOf("week");
    const prevStart = start.subtract(duration, "day");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }

  if (period === Period.Last7Days) {
    const duration = 7;
    const start = dayjs().startOf("day").subtract(duration, "day");
    const end = dayjs().endOf("day");
    const prevStart = start.subtract(duration, "day");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }

  if (period === Period.ThisMonth) {
    const start = dayjs().startOf("month");
    const end = dayjs().endOf("month");
    const prevStart = start.subtract(1, "month");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }

  if (period === Period.Last30Days) {
    const duration = 30;
    const start = dayjs().startOf("day").subtract(duration, "day");
    const end = dayjs().endOf("day");
    const prevStart = start.subtract(duration, "day");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }

  if (!!period.startDate && !!period.endDate) {
    const start = dayjs(period.startDate);
    const end = dayjs(period.endDate);
    const duration = end.diff(start, "day");
    const prevStart = start.subtract(duration, "day");
    const prevEnd = start.subtract(1, "second");
    return { start, end, prevStart, prevEnd };
  }
};
