import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { Tooltip } from "@rmwc/tooltip";
import "@rmwc/tooltip/styles";
import useLayout from "hooks/useLayout";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { TabBarRightActions, TabBarWithShadow, TabContainer } from "components/TabContainer";
import { TaskPeoplePage } from "./people";
import { TaskCostsPage } from "./costs";
import { TaskTimesheetsPage } from "./timesheets";
import { AttachmentFormDialog } from "components/attachments/AttachmentFormDialog";
import { Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";
import { TaskTemplateFormDialog } from "components/templates/TaskTemplateFormDialog";
import { MakeTemplateButton } from "components/MakeTemplateButton";
import { TodoFormDialog } from "components/tasks/TodoFormDialog";
import { TaskStatus } from "types/admin/globalTypes";
import { StatusBlockProgress } from "components/StatusBlockProgress";
import { DisplayFlex } from "components/Utilities";
import { DiscrepancyIndicator } from "components/timesheets/TimesheetStyles";
import { useTimeSheets } from "hooks/useTimeSheets";
import { Menu, MenuItem, MenuSurfaceAnchor, SimpleMenu } from "@rmwc/menu";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import Palette from "../../../../../palette.json";
import { DesktopOnly, TabletOnly } from "components/Responsive";
import * as Pages from "../../../../../pages";
import { PartsList } from "components/parts/PartsList";
import { PartFormDialog } from "components/parts/PartFormDialog";
import useTranslations from "hooks/useTranslations";
import { TaskSchedulePage } from "./schedule";
import { Button } from "components/Button";
import { Icon } from "@rmwc/icon";
import { useParams } from "react-router-dom";
import { CorrectiveActionFormDialog } from "components/correctiveAction/CorrectiveActionFormDialog";
import useInspectionForm, { AttachmentType } from "hooks/useInspectionForm";
import useLogBookSticker from "hooks/useLogBookSticker";
import { TaskDetails } from "components/tasks/TaskDetails";
import { TaskFormDialog } from "components/tasks/TaskFormDialog";
import { CorrectiveActionsProvider, useCorrectiveActions } from "hooks/useCorrectiveActions";
import { CompleteTaskButton } from "components/CompleteTaskButton";
import { List } from "@rmwc/list";
import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";
import { showErrorMessage } from "utilities/handleError";
import { useAdminApi } from "hooks/useAdminApi";

export const ProjectTaskViewPageWrapper: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();

  return (
    <CorrectiveActionsProvider taskId={taskId}>
      <ProjectTaskViewPage />
    </CorrectiveActionsProvider>
  );
};

const ProjectTaskViewPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const { correctiveActions, getCorrectiveActions } = useCorrectiveActions();
  const history = useHistory();
  const location = useLocation();
  const { projectId, taskId } = useParams<{ projectId: string; taskId: string }>();

  const { setBreadCrumbs } = useLayout();
  const { discrepancyFound, setFilterId, setFilterType } = useTimeSheets();
  const { completeTask } = useAdminApi();

  const project = useProject();
  const { task } = useTask();

  const { generate } = useInspectionForm();
  const { generate: generateSticker } = useLogBookSticker();

  const resourcePrefix = `/work-orders/${projectId}/tasks/${taskId}`;

  const PAGE_TABS = [
    {
      icon: "list",
      label: "Details",
      path: `${resourcePrefix}/details`,
    },
    {
      icon: "people",
      label: "People",
      path: `${resourcePrefix}/people`,
    },
    {
      icon: "today",
      label: "Schedule",
      path: `${resourcePrefix}/schedule`,
    },
    {
      icon: "construction",
      label: tCommon("Parts"),
      path: `${resourcePrefix}/parts`,
    },
    {
      icon: "today",
      label: "Time Tracking",
      path: `${resourcePrefix}/timesheets`,
    },
    {
      icon: "paid",
      label: "Costs",
      path: `${resourcePrefix}/costs`,
    },
  ];
  const DROPDOWN_BUTTON_MENU = [
    {
      label: "Todo",
      icon: "assignment_add",
      id: "todo",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/${taskId}/todos/new`);
      },
    },
    {
      label: "Corrective Action",
      icon: "add_circle",
      id: "corrective-action",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/${taskId}/corrective-action/new`);
      },
    },
    {
      label: "Message",
      icon: "post_add",
      id: "message",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/${taskId}/messages/new`);
      },
    },
    {
      label: "Attachment",
      icon: "attach_file",
      id: "attachment",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/${taskId}/attachments/new`);
      },
    },
    {
      label: "Inspection Form",
      icon: "file_download",
      id: "inspection-form",
      onClick: () => {
        generate(task, project, null, AttachmentType.TaskAndPartsAttachments);
      },
    },
    {
      label: "Log Book Sticker",
      icon: "file_download",
      id: "log-book-sticker",
      onClick: () => {
        generateSticker(task, project);
      },
    },
  ];

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  const [loadingCompleteTask, setLoadingCompleteTask] = useState<boolean>(false);

  const onCompleteTask = async (projectId: string) => {
    const ConfirmBody = () => {
      return (
        <div>
          <p>Are you sure you want to complete this task?</p>
          <List>
            <li>All technicians will be unassigned from this task</li>
          </List>
        </div>
      );
    };

    const hasConfirmed = await dialogQueue.confirm({
      title: "Complete Task",
      body: ConfirmBody(),
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    setLoadingCompleteTask(true);

    try {
      await completeTask({ taskId });
      snackbar.notify({ title: "Task complete!" });
      history.push(`/work-orders/${project.id}/tasks`);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!project || !task) return;

    setBreadCrumbs([
      {
        label: "Work Orders",
        path: "/work-orders",
      },
      {
        label: project?.name,
        path: `/work-orders/${project?.id}`,
      },
      {
        label: `${task.orderIndex} - ${task.name}`,
        path: `${resourcePrefix}`,
      },
    ]);
  }, [setBreadCrumbs, project, task]);

  useEffect(() => {
    if (!task) return;
    setFilterId(task?.id);
    setFilterType("task");
  }, [task]);

  useEffect(() => {
    if (!taskId) return;
    getCorrectiveActions({ taskId });
  }, [taskId]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    // Prevent history change when certain buttons are clicked
    if (
      location.pathname.startsWith(path) ||
      location.pathname.endsWith("/edit") ||
      location.pathname.endsWith("/new") ||
      location.pathname.endsWith("/template")
    )
      return;
    history.replace(path);
  };

  if (!project || !task) {
    return <Route component={Pages.Error404} />;
  }

  return (
    <>
      <Route path="/work-orders/:projectId/tasks/:taskId/corrective-action/new">
        <CorrectiveActionFormDialog />
      </Route>

      <Route path="/work-orders/:projectId/tasks/:taskId/attachments/new">
        <AttachmentFormDialog action="create" />
      </Route>

      <Route path="/work-orders/:projectId/tasks/:taskId/parts/new">
        <PartFormDialog action="assign" />
      </Route>

      <Route exact path={`/work-orders/:projectId/tasks/:taskId/todos/new`}>
        <TodoFormDialog action="create" />
      </Route>

      <Route exact path="/work-orders/:projectId/tasks/:taskId/template">
        <TaskTemplateFormDialog />
      </Route>

      <Route exact path={`/work-orders/:projectId/tasks/:taskId/edit`}>
        <TaskFormDialog action="update" />
      </Route>

      <PageHeader
        compact
        editable
        title={`${task.orderIndex} - ${task.name}`}
        subtitle={project.name}
        description={task.description}
        onEditClick={() => history.push(`${resourcePrefix}/edit`)}
        onSubtitleClick={() => history.push(`/work-orders/${project.id}`)}
      >
        <StatusBlockProgress />
      </PageHeader>

      <TabContainer>
        <TabBarWithShadow
          {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }}
          style={{ maxWidth: "80%" }}
          onActivate={onTabActivated}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              height: "100%",
            }}
          >
            <SimpleMenu
              renderToPortal
              anchorCorner="bottomStart"
              focusOnOpen={false}
              handle={
                <Button
                  data-test-id="new-button"
                  outlined
                  trailingIcon="unfold_more"
                  style={{
                    height: "48px",
                    padding: "0 16px",
                    marginBottom: "8px",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Icon icon="library_add" style={{ color: "gray" }} />
                    <div style={{ marginLeft: "8px" }}>New</div>
                  </span>
                </Button>
              }
            >
              {DROPDOWN_BUTTON_MENU.map((it, i) => {
                return (
                  <MenuItem key={i} onClick={it.onClick}>
                    <Icon icon={it.icon} style={{ marginRight: "8px" }} />
                    {it.label}
                  </MenuItem>
                );
              })}
            </SimpleMenu>
          </div>

          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              <DisplayFlex alignItems="center">
                <DisplayFlex>{it.label}</DisplayFlex>
                {it.label === "Time Tracking" && discrepancyFound && (
                  <Tooltip content="Discrepancy found">
                    <DiscrepancyIndicator style={{ marginLeft: "8px" }} />
                  </Tooltip>
                )}
              </DisplayFlex>
            </Tab>
          ))}
        </TabBarWithShadow>

        <TabBarRightActions>
          <TabletOnly>
            <MenuSurfaceAnchor>
              <Menu
                open={menuOpen}
                onSelect={(evt) => console.log(evt.detail.index)}
                onClose={() => setMenuOpen(false)}
                renderToPortal
              >
                <MenuItem onClick={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/template`)}>
                  New Task Template
                </MenuItem>
              </Menu>

              <IconButton
                icon="more_vert"
                onClick={() => setMenuOpen(!menuOpen)}
                style={{ color: Palette.LightGrey, fontSize: 24, zIndex: 0 }}
              />
            </MenuSurfaceAnchor>
          </TabletOnly>

          <DesktopOnly>
            <MakeTemplateButton
              type="Task"
              onClick={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/template`)}
            />
            {task?.status !== TaskStatus.Completed && (
              <CompleteTaskButton loading={loadingCompleteTask} onClick={() => onCompleteTask(task.id)} />
            )}
          </DesktopOnly>
        </TabBarRightActions>
      </TabContainer>

      <PageMain style={{ paddingTop: "32px" }}>
        <PageContent>
          <Switch>
            <Route path="/work-orders/:projectId/tasks/:taskId/costs">
              <TaskCostsPage />
            </Route>

            <Route path="/work-orders/:projectId/tasks/:taskId/parts">
              <PartsList />
            </Route>

            <Route path="/work-orders/:projectId/tasks/:taskId/people">
              <TaskPeoplePage />
            </Route>

            <Route path="/work-orders/:projectId/tasks/:taskId/schedule" exact component={TaskSchedulePage} />

            <Route path="/work-orders/:projectId/tasks/:taskId/timesheets" component={TaskTimesheetsPage} />

            <Route
              path={[
                "/work-orders/:projectId/tasks/:taskId/todos",
                "/work-orders/:projectId/tasks/:taskId/attachments",
                "/work-orders/:projectId/tasks/:taskId/messages",
              ]}
            >
              <TaskDetails correctiveActions={correctiveActions} />
            </Route>

            <Route path="/work-orders/:projectId/tasks/:taskId/details">
              <TaskDetails correctiveActions={correctiveActions} />
            </Route>

            <Route exact path="/work-orders/:projectId/tasks/:taskId">
              <TaskDetails correctiveActions={correctiveActions} />
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
