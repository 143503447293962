import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormRow, Number } from "components/Form";
import { Button } from "./Button";
import { ScreenWrapper } from "pages";

interface FormValuesT {
  pin: number;
}

export const PinEntryForm: React.FC<{ onSubmit?: (pin: string) => void; submitting?: boolean }> = ({
  onSubmit,
  submitting,
}) => {
  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      pin: undefined,
    },
  });

  return (
    <ScreenWrapper>
      <Form form={form} onSubmit={onSubmit}>
        <FormRow col={1}>
          <Number name="pin" label="Numeric Pin" maxLength={6} minLength={4} required />
        </FormRow>

        <FormRow col={1}>
          <Button raised disabled={submitting}>
            Submit
          </Button>
        </FormRow>
      </Form>
    </ScreenWrapper>
  );
};
