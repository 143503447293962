import * as React from "react";
import { useAdminApi } from "hooks/useAdminApi";
import { Dayjs } from "dayjs";

type Props = {
  start: Dayjs;
  end: Dayjs;
  prevStart: Dayjs;
  prevEnd: Dayjs;
};

const scoreMorale = (morale: string[]) => {
  if (!Array.isArray(morale)) return 0;
  const filtered = morale.filter((it) => ["Positive", "Neutral", "Negative"].includes(it));
  const total = filtered.reduce((acc, rating) => {
    if (rating === "Positive") return acc + 100;
    if (rating === "Neutral") return acc + 66;
    if (rating === "Negative") return acc + 33;
  }, 0);
  const avg = filtered.length > 0 ? total / filtered.length : 0;
  return Math.round(avg);
};

export const useMorale = ({ start, end, prevStart, prevEnd }: Props) => {
  const { getPulseReportsAndTimeSheets } = useAdminApi();
  const [morale, setMorale] = React.useState<string[]>([]);
  const [prevMorale, setPrevMorale] = React.useState<string[]>([]);

  const startStr = start.toISOString();
  const endStr = end.toISOString();
  const prevStartStr = prevStart.toISOString();
  const prevEndStr = prevEnd.toISOString();

  React.useEffect(() => {
    getPulseReportsAndTimeSheets({
      criteria: {
        startDate: startStr,
        endDate: endStr,
      },
      fromDt: startStr,
      toDt: endStr,
      limit: 500,
    }).then((reports) => {
      setMorale(reports?.pulseReports?.map(({ rating }) => rating));
    });
    getPulseReportsAndTimeSheets({
      criteria: {
        startDate: prevStartStr,
        endDate: prevEndStr,
      },
      fromDt: prevStartStr,
      toDt: prevEndStr,
      limit: 500,
    }).then((reports) => {
      setPrevMorale(reports?.pulseReports?.map(({ rating }) => rating));
    });
  }, [startStr, endStr]);
  return { morale: scoreMorale(morale), prevMorale: scoreMorale(prevMorale) };
};
