import React from "react";
import { Route } from "react-router";

import { useTask } from "hooks/params/useTask";

import { MessageFormDialog } from "components/messages/MessageFormDialog";
import { MessageList } from "components/messages/MessageList";

export const TaskMessagesPage: React.FC = () => {
  const { task } = useTask();

  return (
    <>
      <Route path="/work-orders/:projectId/tasks/:taskId/messages/new">
        <MessageFormDialog action="create" />
      </Route>

      <MessageList items={task?.messages?.items || []} />
    </>
  );
};
