import React, { createContext, useContext, useState } from "react";

interface ScreenDimmerContextT {
  isDimmed: boolean;
  setIsDimmed?(v: boolean): void;
}

const ScreenDimmerContext = createContext<ScreenDimmerContextT>({ isDimmed: false });

export const ScreenDimmerProvider: React.FC = ({ children }) => {
  const [isDimmed, setIsDimmed] = useState<boolean>(false);

  return (
    <ScreenDimmerContext.Provider
      value={{
        isDimmed,
        setIsDimmed,
      }}
    >
      {children}
    </ScreenDimmerContext.Provider>
  );
};

export default () => useContext(ScreenDimmerContext);
