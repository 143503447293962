import { Icon } from "@rmwc/icon";
import React from "react";
import Palette from "../palette.json";
import { Button } from "./Button";

interface EmptyStateProps {
  text?: string;
  icon: string;
  onClick?: () => void;
  buttonText?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ text, icon, onClick, buttonText }) => {
  return (
    <div style={{ padding: "48px 0", color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
      <Icon style={{ width: "28px", color: Palette.LightGrey }} icon={icon} />
      <div style={{ marginTop: 8 }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div>{text}</div>
          {buttonText && onClick && (
            <div style={{ marginTop: 24 }}>
              <Button raised onClick={onClick}>
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
