import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Portal } from "@rmwc/base";
import useMultiTenant, { MultiTenantProvider } from "./hooks/useMultiTenant";
import { FirebaseProvider } from "./hooks/useFirebase";
import { AdminApiProvider } from "./hooks/useAdminApi";
import { dialogQueue } from "./hooks/useDialogQueue";
import { NotificationsProvider, snackbar } from "./hooks/useNotifications";
import { Routes } from "./Routes";
import { RMWCProvider } from "@rmwc/provider";
import { ThemeProvider as RMWCThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";
import { DialogQueue } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { SnackbarQueue } from "@rmwc/snackbar";
import "@rmwc/snackbar/styles";
import { ThemeProvider } from "styled-components";
import AppDefaultTheme from "./theme.json";

import { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import Weekday from "dayjs/plugin/weekday";
import "utilities/posthog";

import "./stylesheets/reset.css";
import "./stylesheets/index.css";
import { TimeSheetsProvider } from "./hooks/useTimeSheets";
import { SettingsProvider } from "./hooks/useSettings";
import { TemporaryUserProvider } from "hooks/useTemporaryUser";
import { ScreenDimmerProvider } from "hooks/useScreenDimmer";
import FullScreenDim from "components/FullScreenDim";

const TENANT_PREFIX = window.location.hostname.split(".")[0];

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(dayOfYear);
dayjs.extend(Weekday);

const AppComponent: React.FC = () => {
  const { tenantId, tenantInfo, theme } = useMultiTenant();

  useEffect(() => {
    if (!tenantInfo) return;
    window.document.title = tenantInfo.name.charAt(0).toUpperCase() + tenantInfo.name.slice(1);
  }, [tenantInfo]);

  if (!tenantId) return <></>;

  return (
    <ScreenDimmerProvider>
      <FullScreenDim />
      <FirebaseProvider {...{ tenantId }}>
        <ThemeProvider theme={theme}>
          <RMWCProvider icon={{ basename: "material-icons" }}>
            <RMWCThemeProvider options={{ ...AppDefaultTheme, ...theme }}>
              <AdminApiProvider>
                <SettingsProvider>
                  <TemporaryUserProvider>
                    <TimeSheetsProvider>
                      <div style={{ zIndex: 99999 }}>
                        <Toaster />
                      </div>

                      <SnackbarQueue timeout={0} messages={snackbar.messages} />
                      <DialogQueue preventOutsideDismiss dialogs={dialogQueue.dialogs} />

                      <Router>
                        <NotificationsProvider>
                          <Routes />
                        </NotificationsProvider>
                      </Router>

                      <Portal />
                    </TimeSheetsProvider>
                  </TemporaryUserProvider>
                </SettingsProvider>
              </AdminApiProvider>
            </RMWCThemeProvider>
          </RMWCProvider>
        </ThemeProvider>
      </FirebaseProvider>
    </ScreenDimmerProvider>
  );
};

export const App: React.FC = () => (
  <MultiTenantProvider prefix={TENANT_PREFIX === "localhost" ? "foxtrot" : TENANT_PREFIX}>
    <AppComponent />
  </MultiTenantProvider>
);
