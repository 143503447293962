import React from "react";

import { useProject } from "hooks/params/useProject";

import { PartList } from "components/projects/PartList";
import { ToolList, ToolListFields } from "components/projects/ToolList";
import { SummaryCard } from "components/SummaryCard";
import numeral from "numeral";
import { PartType, TaskStatus } from "types/admin/globalTypes";
import { SubcontractorList } from "components/tasks/SubcontractorList";
import { LaborList } from "components/projects/LaborList";
import { useAdminApi } from "hooks/useAdminApi";
import { getCost } from "../../../common";
import useTranslations from "hooks/useTranslations";

export const formatPrice = (v: number) => {
  return "$" + numeral(v).format("0,0.00");
};

export const ProjectCostsPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const project = useProject();
  const { tasks: allTasks } = useAdminApi();

  if (!project || !allTasks) return <></>;

  const tasks = allTasks.filter(
    (it) => it.projectId === project?.id && it.parentId === project.id && it.status !== TaskStatus.Deleted,
  );

  const partsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.taskParts?.items.reduce((partTotal, part) => {
        let quantity = part.quantity;
        // If part is serializable and has children parts, the quantity is the number of children parts
        if (part.parts.items.length > 0 && (part.type === PartType.Expendable || part.type === PartType.Rotable)) {
          quantity = part.parts.items.length;
        }
        return partTotal + (part?.isBillable ? (getCost(part) || 0) * quantity : 0);
      }, 0) || 0)
    );
  }, 0);

  const laborTotal = tasks.reduce((total, it) => {
    return total + (it.amount || 0);
  }, 0);

  const toolsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.tools?.reduce((toolTotal, tool) => {
        return toolTotal + (tool?.isBillable ? getCost(tool) || 0 : 0);
      }, 0) || 0)
    );
  }, 0);

  const partsItems: any[] = tasks
    .map((it) => {
      return it?.taskParts?.items
        ?.map((part) => {
          if (part.parts.items.length > 0 && (part.type === PartType.Expendable || part.type === PartType.Rotable)) {
            part.quantity = part.parts.items.length;
          }
          return { part, taskId: it.id, name: it.name };
        })
        .flat();
    })
    .flat()
    .filter((it) => !!it);

  const toolsItems: ToolListFields[] = tasks
    .map((it) => {
      return it?.tools
        ?.map((tool) => {
          return { tool, taskId: it.id, name: it.name };
        })
        .flat();
    })
    .flat()
    .filter((it) => !!it);

  const subcontractorsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.subcontractors?.items?.reduce((subTotal, sub) => {
        return subTotal + (getCost(sub) || 0);
      }, 0) || 0)
    );
  }, 0);

  const subcontractorsItems = tasks
    .map((it) => {
      return it?.subcontractors?.items.flat();
    })
    .flat()
    .filter((it) => !!it);

  const total = (partsTotal || 0) + (laborTotal || 0) + (toolsTotal || 0) + (subcontractorsTotal || 0);

  return (
    <>
      <div style={{ display: "flex", overflowX: "auto" }}>
        <SummaryCard
          label={tCommon("Parts")}
          showBackground={true}
          value={partsTotal ? formatPrice(partsTotal as number) : "$0"}
        />
        <SummaryCard
          label="Labor"
          showBackground={true}
          value={laborTotal ? formatPrice(laborTotal as number) : "$0"}
        />
        <SummaryCard
          label={tCommon("Tools")}
          showBackground={true}
          value={toolsTotal ? formatPrice(toolsTotal as number) : "$0"}
        />
        {/* Add back in future */}
        <SummaryCard
          label="Subcontractors"
          value={subcontractorsTotal ? formatPrice(subcontractorsTotal as number) : "$0"}
          showBackground={true}
        />
        <SummaryCard label="Total Task Costs" value={formatPrice(total as number)} />
      </div>
      <div style={{ margin: "2rem" }} />
      <PartList total={partsTotal} items={partsItems} />
      <div style={{ margin: "2rem" }} />
      <LaborList total={laborTotal || 0} tasks={tasks} />
      <div style={{ margin: "2rem" }} />
      <ToolList total={toolsTotal} items={toolsItems} />
      <div style={{ margin: "2rem" }} />
      <SubcontractorList total={subcontractorsTotal} items={subcontractorsItems || []} />
    </>
  );
};
