import React from "react";

import styled from "styled-components";

import BannerImage from "../../assets/images/signin-banner.jpg";

import RbrAviationLogo from "../../assets/images/foxtrot-insight-logo.png";
import useMultiTenant from "hooks/useMultiTenant";

const Root = styled.div<{ logoUrl: string }>`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.background};

  background-image: url(${(p) => p.logoUrl});
  background-size: 200px;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    background-position-x: 48px;
    background-position-y: 48px;
  }

  background-position-x: 50%;
  background-position-y: 48px;
`;
const Container = styled.div`
  @media (min-width: 768px) {
    padding-right: 400px;
  }

  padding-right: 0px;

  height: 400px;

  border-radius: 0.25rem;

  background-color: white;

  @media (min-width: 768px) {
    background: white url(${BannerImage});
    background-position-x: calc(100% + 43px);
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
const Content = styled.div`
  @media (min-width: 768px) {
    margin-right: 40px;
  }
  width: 100%;
`;

export const SignInLayout: React.FC = ({ children }) => {
  const { tenantInfo } = useMultiTenant();

  return (
    <Root logoUrl={tenantInfo?.logoImageAttachment?.file?.url ?? RbrAviationLogo}>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Root>
  );
};
