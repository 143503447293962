import React from "react";
import styled from "styled-components";
import Palette from "../../palette.json";
import { Icon } from "@rmwc/icon";
import { Link } from "react-router-dom";
import { Tooltip } from "@rmwc/tooltip";

const Wrapper = styled.div`
  margin: 0 42px 22px 0;
  flex-grow: 0;
  max-width: 280px;
`;

const Heading = styled.h5`
  font-size: 16px;
  font-weight: 500;
  color: #3e3f42;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px 16px 24px;
  margin-top: 10px;
  border: solid 1px #eaedf3;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
`;

const Primary = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
  justify-content: center;
`;

const PrimaryValue = styled.span`
  font-size: 30px;
  font-weight: 500;
  padding-right: 12px;
`;

const Change = styled.span`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  gap: 2px;
  align-items: center;
`;

const Properties = styled.div`
  display: flex;
  margin-left: auto;
  > a {
    color: inherit;
    text-decoration: none;
  }
`;

const Property = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 12px;
  gap: 4px;

  &:not(:last-child) {
    border-right: solid 1px #eaedf3;
  }
`;

const PropertyLabel = styled.span`
  font-size: 12px;
  color: ${Palette.LightGrey};
`;

const PropertyValue = styled.span`
  font-size: 18px;
  padding: 6px 0;
`;

type Props = {
  heading: string;
  value: number;
  oldValue: number;
  tooltip?: string;
  properties?: {
    label: string;
    value: number;
    suffix?: string;
    url?: string;
    color?: string;
  }[];
};

export const InsightBlock = ({ heading, value, oldValue, properties, tooltip }: Props) => {
  let change = (value - oldValue) / oldValue;
  if (!isNaN(change) && !isFinite(change)) change = 1;
  if (isNaN(change)) change = 0;
  change = Math.round(change * 100);

  return (
    <Wrapper style={{ minWidth: properties?.length ? "250px" : "140px" }}>
      <Heading>{heading}</Heading>
      <Box>
        <Primary>
          {tooltip ? (
            <Tooltip content={tooltip}>
              <PrimaryValue>{value || 0}</PrimaryValue>
            </Tooltip>
          ) : (
            <PrimaryValue>{value || 0}</PrimaryValue>
          )}
          <Change style={{ color: change === 0 ? Palette.LightGrey : change > 0 ? Palette.Green : Palette.Red }}>
            <Icon
              icon={change === 0 ? "remove" : change > 0 ? "arrow_upward" : "arrow_downward"}
              style={{ fontSize: "14px", fontWeight: "bold" }}
            />
            {Math.abs(change)}%
          </Change>
        </Primary>
        <Properties>
          {properties?.map(({ label, value, url, suffix, color }) =>
            url ? (
              <Link to={url} key={label}>
                <Property>
                  <PropertyLabel>{label}</PropertyLabel>
                  <PropertyValue>{value}</PropertyValue>
                </Property>
              </Link>
            ) : (
              <Property key={label}>
                <PropertyLabel>{label}</PropertyLabel>
                <PropertyValue style={{ color: color || "" }}>
                  {value !== Infinity ? value || 0 : "n/a"}
                  {value !== Infinity && (suffix || "")}
                </PropertyValue>
              </Property>
            ),
          )}
        </Properties>
      </Box>
    </Wrapper>
  );
};
