import React from "react";

import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

import { AttachmentList } from "components/attachments/AttachmentList";

export const TaskAttachmentsPage: React.FC = () => {
  const project = useProject();
  const { task: parentTask } = useTask();

  if (!project || !parentTask) return <></>;

  return (
    <>
      <AttachmentList type="task" items={parentTask.attachments?.items || []} />
    </>
  );
};
