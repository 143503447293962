import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useForm, UseFormMethods } from "react-hook-form";

import Palette from "../../palette.json";

import { BillingType, MarkupType, TaskType } from "types/admin/globalTypes";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";

import { TabContainer } from "../TabContainer";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import { TodoFormInfoContent } from "./TodoFormInfoContent";
import { Form } from "../Form";
import { showErrorMessage } from "utilities/handleError";

interface TodoItem {
  origId?: string;
  name: string;
}

interface PartValuesT {
  name: string;
  number: string;
  cost: string;
  leadTimeDays: string;
  quantity: string;
  isBillable: boolean;
  markupType?: MarkupType;
  markup?: string;
  markupCost?: string;
}

export interface TodoFormValuesT {
  id?: string;

  parentId: string;
  projectId: string;

  name: string;
  description: string;

  amount: number;
  billing: BillingType;

  taskType: TaskType;

  // Internally used variables.
  parentName: string;
  projectName: string;
}

export interface TodoFormContentProps {
  style: React.CSSProperties;
  form: UseFormMethods<TodoFormValuesT>;
  isTemplate?: boolean;
}

interface TodoFormDialogProps {
  action: "create" | "update";
}

const HIDDEN_FIELD_NAMES = ["parentId", "projectId", "description"];

export const TodoFormDialog: React.FC<TodoFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string; isNew: boolean }>();

  const { addTask, assignUserToTask, updateTask, tasks: allTasks } = useAdminApi();

  const project = useProject();
  const { template, isTemplateTypeTask, isTemplateTypeProject } = useTemplate();
  const { task } = useTask();

  const tasks = task ? task.tasks?.items || [] : allTasks.filter((it) => it.parentId === project?.id);
  const tabs = ["Information"];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(locationState && locationState.isNew ? 1 : 0);

  const form = useForm<TodoFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      id: "",
      parentId: "",
      projectId: "",
      name: "",
      description: "",
      amount: 0,
      billing: BillingType.Fixed,
      taskType: TaskType.Scheduled,
      projectName: "",
      parentName: "",
    },
  });

  const { formState, register, setValue, watch, getValues } = form;
  const { errors, isValid } = formState;

  const setupTaskFromTemplate = () => {
    if (task) {
      setValue("parentId", task.id);
    } else if (template) {
      setValue("parentId", template.id);
    }
  };

  const setupTaskForProject = () => {
    if (!project) return;

    setValue("parentId", project.id);
    setValue("projectId", project.id);

    setValue("projectName", project.name);
    setValue("parentName", task ? `${task.orderIndex} - ${task.name}` : project.name);

    if (action === "create") {
      setValue("parentId", task ? task.id : project.id);
    }

    if (action === "update") {
      if (!task) return;

      setValue("id", task.id);
      setValue("parentId", task.parentId);
      setValue("name", task.name);
      setValue("description", task.description);
      setValue("taskType", task.taskType);
    }
  };

  const onSubmit = async () => {
    const allValues = getValues();
    const submission: any = { ...allValues };

    delete submission.projectName;
    delete submission.parentName;

    try {
      if (action === "create") {
        submission.orderIndex = tasks?.length + 1 || 1;

        const task = await addTask(
          {
            input: {
              ...submission,
            },
          },
          isTemplateTypeProject,
          isTemplateTypeTask,
          true,
        );

        if (!task) return;

        history.goBack();
        return;
      }

      if (action === "update") {
        if (!task) return;

        await updateTask(
          {
            id: task.id,
            parentId: task.parentId,
            input: {
              ...submission,
            },
          },
          true,
          isTemplateTypeProject,
          isTemplateTypeTask,
        );

        if (locationState?.referrer === "/work-orders/new") {
          history.push(`/work-orders/${project?.id}`);
          return;
        }

        history.goBack();
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onTabActivated = (ev: any) => {
    setActiveTabIndex(ev.detail.index);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) return;

    console.info(errors);
  }, [errors]);

  useEffect(() => {
    if (!project) return;
    setupTaskForProject();
  }, [project, task]);

  useEffect(() => {
    if (!template) return;
    setupTaskFromTemplate();
  }, [template, task]);

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>Create Todo</DialogTitle>
      <TabContainer style={{ paddingLeft: "30px" }}>
        <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
          {tabs.map((it, i) => (
            <Tab key={i}>{it}</Tab>
          ))}
        </TabBar>
        {action === "create" && (
          <div
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
            onClick={(ev) => ev.stopPropagation()}
          />
        )}
      </TabContainer>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit} data-test-id="todo-form">
          {HIDDEN_FIELD_NAMES.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          <TodoFormInfoContent
            style={{ display: activeTabIndex === 0 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton onClick={history.goBack} outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        {(!task || activeTabIndex === tabs.length - 1) && (
          <DialogButton raised disabled={!isValid} onClick={onSubmit} data-test-id="todo-form-create-todo-button">
            Create Todo
          </DialogButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
