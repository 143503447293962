import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ProjectTemplateEstimateFormValues } from "components/templates/ProjectTemplateEstimateFormDialog";
import { TaskFields } from "types/admin/TaskFields";
import { toDataURL } from "common";
import i18next from "../i18n";
import { MarkupType, PartType } from "types/admin/globalTypes";

const getCost = (task: any, itemizedCosts: boolean, templateTasksAndPartsAndTools: any[]) => {
  let itsCost = 0;
  if (task.totalPartToolSubCost) {
    itsCost = task.totalPartToolSubCost!;
  } else if (task.hourlyRate && task.estimateManHrs) {
    if (itemizedCosts) {
      itsCost = task.hourlyRate! * task.estimateManHrs!;
    } else {
      // need to combine the parts, tools, subcontractors, and labor costs
      // little bit of formatting to make things easier
      templateTasksAndPartsAndTools = templateTasksAndPartsAndTools.map((t) => {
        if (t.id) {
          t.taskId = t.id;
        }
        return t;
      });
      const taskCost = task.hourlyRate! * task.estimateManHrs!;
      const totalPartToolSubCost = templateTasksAndPartsAndTools.reduce((acc, cur) => {
        if (cur.taskId === task.taskId) {
          return acc + (cur.totalPartToolSubCost || 0);
        }
        return acc;
      }, 0);
      console.log("taskCost:", taskCost);
      console.log("totalPartToolSubCost:", totalPartToolSubCost);
      itsCost = taskCost + totalPartToolSubCost;
    }
  }
  return itsCost;
};

const generateEstimate = async (
  values: ProjectTemplateEstimateFormValues,
  name: string,
  logoImageURL: string | null | undefined,
  templateTasks: TaskFields[],
  logo: string,
  estimateId: string,
  breakdownCosts: boolean | false,
  itemizedCosts: boolean | false,
  completion: () => void,
) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  let [verticalOffset, horizontalOffset] = [0, 0];
  let { h, w } = { h: 0, w: 0 };

  const logoDataURL = await toDataURL(logoImageURL ?? logo);
  const logoProperties = doc.getImageProperties(logoDataURL);
  const whRatio = logoProperties.width / logoProperties.height;
  const logoWidth = 55;
  const logoHeight = logoWidth / whRatio;
  doc.addImage(logoDataURL, "PNG", 16, 12, logoWidth, logoHeight);
  verticalOffset = 12 + 3;
  horizontalOffset = 16 + logoWidth + 4;

  doc.setFontSize(10);
  if (values.companyName) {
    doc.setFont("helvetica", "bold");
    doc.text(values.companyName, horizontalOffset, verticalOffset);
    h = doc.getTextDimensions(values.companyName).h;
    verticalOffset += h + 1;
  }

  doc.setFont("helvetica", "normal");
  let addressAndEmail = [] as string[];
  if (values.companyAddress) {
    addressAndEmail = values.companyAddress.trim().split(/\r\n|\n\r|\n|\r/);
  }
  if (values.companyEmail) {
    addressAndEmail.push(values.companyEmail);
  }
  if (values.companyPhone) {
    addressAndEmail.push(values.companyPhone);
  }
  doc.text(addressAndEmail, horizontalOffset, verticalOffset, {
    lineHeightFactor: 1.3,
  });

  horizontalOffset = 16;
  verticalOffset += logoHeight + 2;
  doc.setFontSize(18);
  doc.setTextColor(95, 143, 183);
  h = doc.getTextDimensions("ESTIMATE").h;
  doc.text("ESTIMATE", horizontalOffset, verticalOffset);

  verticalOffset += h + 4;
  doc.setTextColor(0);
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  h = doc.getTextDimensions("ESTIMATE GENERATED FOR").h;
  doc.text("ESTIMATE GENERATED FOR", horizontalOffset, verticalOffset);

  const rightSideInset = 56;

  doc.setFont("helvetica", "bold");
  h = doc.getTextDimensions("ESTIMATE #").h;
  w = doc.getTextDimensions("ESTIMATE #").w;
  horizontalOffset = pageWidth - rightSideInset - w;
  doc.text("ESTIMATE #", horizontalOffset, verticalOffset);

  horizontalOffset = pageWidth - rightSideInset + 2;
  doc.setFont("helvetica", "normal");
  doc.text(estimateId, horizontalOffset, verticalOffset);

  verticalOffset += h + 1;

  horizontalOffset = 16;
  const customerName = values.customerName?.trim();
  if (customerName) {
    h = doc.getTextDimensions(customerName).h;
    doc.text(customerName, horizontalOffset, verticalOffset);
    verticalOffset += h + 1;
  }

  const customerAddress = values.customerAddress?.trim().split(/\r\n|\n\r|\n|\r/) ?? [];
  const customerAddressHeight = verticalOffset + (customerAddress.length - 1) * (h + 1);
  doc.text(customerAddress, horizontalOffset, verticalOffset, {
    lineHeightFactor: 1.34,
  });

  if (customerName) {
    verticalOffset -= h + 1;
  }

  doc.setFont("helvetica", "bold");
  w = doc.getTextDimensions("DATE").w;
  horizontalOffset = pageWidth - rightSideInset - w;
  doc.text("DATE", horizontalOffset, verticalOffset);

  horizontalOffset = pageWidth - rightSideInset + 2;
  doc.setFont("helvetica", "normal");
  const date = new Date(values.date);
  doc.text(date.toLocaleDateString(), horizontalOffset, verticalOffset);

  verticalOffset += h + 1;

  doc.setFont("helvetica", "bold");
  w = doc.getTextDimensions("DUE DATE").w;
  horizontalOffset = pageWidth - rightSideInset - w;
  doc.text("DUE DATE", horizontalOffset, verticalOffset);

  horizontalOffset = pageWidth - rightSideInset + 2;
  doc.setFont("helvetica", "normal");
  if (values.dueDate) {
    const dueDate = new Date(values.dueDate);
    doc.text(dueDate.toLocaleDateString(), horizontalOffset, verticalOffset);
  } else {
    doc.text("", horizontalOffset, verticalOffset);
  }

  verticalOffset += h + 1;

  doc.setFont("helvetica", "bold");
  w = doc.getTextDimensions("TERMS").w;
  horizontalOffset = pageWidth - rightSideInset - w;
  doc.text("TERMS", horizontalOffset, verticalOffset);

  horizontalOffset = pageWidth - rightSideInset + 2;
  doc.setFont("helvetica", "normal");
  doc.text(values.terms ?? "", horizontalOffset, verticalOffset);

  if (customerAddressHeight > verticalOffset) {
    console.log(customerAddressHeight, verticalOffset);
    verticalOffset = customerAddressHeight;
  }

  verticalOffset += h + 4;
  doc.setLineWidth(0.3);
  doc.setDrawColor(95, 143, 183);
  doc.line(16, verticalOffset, pageWidth - 16, verticalOffset);

  verticalOffset += h + 2;
  horizontalOffset = 16;
  doc.setFont("helvetica", "bold");
  doc.text("TAIL #", horizontalOffset, verticalOffset);

  horizontalOffset += 65;
  doc.text("PO NUMBER", horizontalOffset, verticalOffset);

  horizontalOffset += 65;
  doc.text("AIRPORT", horizontalOffset, verticalOffset);

  verticalOffset += h + 1;
  horizontalOffset = 16;
  doc.setFont("helvetica", "normal");
  doc.text(values.tailNumber ?? "", horizontalOffset, verticalOffset);

  horizontalOffset += 65;
  doc.text(values.poNumber ?? "", horizontalOffset, verticalOffset);

  horizontalOffset += 65;
  doc.text(values.airport ?? "", horizontalOffset, verticalOffset);

  verticalOffset += h + 5;

  const formattedTemplateTasks = [] as any[];
  let i = 1;
  const padding = 2;
  const alternatingColor = [245, 245, 245];
  const moneyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

  let total = 0;
  const templateTasksAndPartsAndTools: any[] = [...templateTasks];

  for (const task of templateTasks) {
    const parts = task.taskParts?.items ?? [];
    const tools = task?.tools ?? [];
    const subcontractors = task?.subcontractors?.items ?? [];

    for (const part of parts) {
      if (part?.isBillable || part.type === PartType.Assembly) {
        let partCost = part?.cost ?? 0;
        if (part.markupType === MarkupType.percentage) {
          partCost = partCost * (1 + part.markup / 100);
        } else if (part.markupType === MarkupType.dollar) {
          partCost += part.markup;
        }

        templateTasksAndPartsAndTools.push({
          taskId: task.id,
          name: `${part?.name} - ${part?.number}`,
          description: i18next.t("PartsMaterials"),
          totalPartToolSubCost: partCost * (part.quantity || 1),
        });
      }
    }

    for (const tool of tools) {
      let toolCost = tool?.cost ?? 0;
      if (tool.markupType === MarkupType.percentage) {
        toolCost = toolCost * (1 + tool.markup / 100);
      } else if (tool.markupType === MarkupType.dollar) {
        toolCost += tool.markup;
      }

      if (tool?.isBillable) {
        templateTasksAndPartsAndTools.push({
          taskId: task.id,
          name: `${tool?.name} - ${tool?.number}`,
          description: i18next.t("Tools"),
          totalPartToolSubCost: toolCost * (tool.quantity || 1),
        });
      }
    }

    for (const sub of subcontractors) {
      let subCost = sub?.cost ?? 0;
      if (sub.markupType === MarkupType.percentage) {
        subCost = subCost * (1 + sub.markup / 100);
      } else if (sub.markupType === MarkupType.dollar) {
        subCost += sub.markup;
      }

      templateTasksAndPartsAndTools.push({
        taskId: sub.taskId,
        name: `${sub?.name}`,
        description: `Subcontractor - ${sub?.description}`,
        totalPartToolSubCost: subCost,
      });
    }
  }

  console.log("tasks, parts, and tools:", templateTasksAndPartsAndTools);

  for (const task of templateTasksAndPartsAndTools) {
    const itsCost = getCost(task, itemizedCosts, templateTasksAndPartsAndTools);

    if (!itemizedCosts) {
      if (task.totalPartToolSubCost) {
        continue;
      }
    }

    if (itsCost === 0) {
      continue;
    }

    formattedTemplateTasks.push({
      activity: {
        content: task.name ?? "-",
        styles: {
          valign: "bottom",
          cellPadding: { bottom: task.description ? 0 : padding, top: padding, left: padding },
          fontStyle: "bold",
          ...(i % 2 === 0 && { fillColor: alternatingColor }),
        },
      },
      ...(breakdownCosts && {
        quantity: {
          content: task.estimateManHrs ?? "-",
          styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
          rowSpan: task.description ? 2 : 1,
        },
      }),
      ...(breakdownCosts && {
        rate: {
          content: task.hourlyRate ? moneyFormatter.format(task.hourlyRate!) : "-",
          styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
          rowSpan: task.description ? 2 : 1,
        },
      }),
      amount: {
        content: itsCost > 0 ? moneyFormatter.format(itsCost) : "-",
        styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
        rowSpan: task.description ? 2 : 1,
      },
    });

    total += itsCost;

    if (task.description) {
      formattedTemplateTasks.push({
        activity: {
          content: task.description,
          styles: {
            valign: "top",
            cellPadding: { top: 0, bottom: padding, left: padding },
            ...(i % 2 === 0 && { fillColor: alternatingColor }),
          },
        },
        quantity: {
          content: "",
          styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
        },
        rate: {
          content: "",
          styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
        },
        amount: {
          content: "",
          styles: { ...(i % 2 === 0 && { fillColor: alternatingColor }) },
        },
      });
    }

    i++;
  }

  const headPadding = { top: 2, bottom: 2 };

  autoTable(doc, {
    head: [
      {
        activity: {
          content: "ACTIVITY",
          ...(!breakdownCosts && { styles: { halign: "left", cellPadding: { ...headPadding, left: 2 } } }),
        },
        ...(breakdownCosts && { quantity: { content: "# OF HOURS" } }),
        ...(breakdownCosts && { rate: { content: "RATE" } }),
        amount: {
          content: "AMOUNT",
          ...(!breakdownCosts && { styles: { halign: "right", cellPadding: { ...headPadding, right: 8 } } }),
        },
      },
    ],
    body: formattedTemplateTasks,
    startY: verticalOffset,
    theme: "plain",
    headStyles: {
      fontStyle: "normal",
      cellPadding: headPadding,
      textColor: [95, 143, 183],
      fillColor: [222, 233, 240],
      halign: "center",
    },
    styles: { minCellWidth: 30, valign: "top", halign: "right", cellPadding: { right: 8, top: 2 } },
    columnStyles: {
      activity: { halign: "left", cellWidth: 80 },
    },
  });

  verticalOffset = (doc as any).lastAutoTable.finalY + 8;
  doc.setLineDashPattern([1, 1], 0);
  doc.setLineWidth(0.37);
  doc.setDrawColor(95, 143, 183);
  doc.line(16, verticalOffset, pageWidth - 16, verticalOffset);

  verticalOffset += 8;
  horizontalOffset = 16;

  if (values.bottomTerms) {
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions(values.bottomTerms, {
      maxWidth: pageWidth * 0.55 - 18,
    }).h;

    // Go to a new page if the bottom text has a chance of going off the page
    if (verticalOffset + h >= doc.internal.pageSize.getHeight() - 16) {
      doc.addPage();
      verticalOffset = 16;
    }

    doc.text(values.bottomTerms, horizontalOffset, verticalOffset, {
      maxWidth: pageWidth * 0.55 - 18,
      lineHeightFactor: 1.3,
    });
  }

  horizontalOffset = pageWidth * 0.55 + 2;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text("BALANCE DUE", horizontalOffset, verticalOffset);

  const totalAmountFormatted = moneyFormatter.format(total);
  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  w = doc.getTextDimensions(totalAmountFormatted).w;
  horizontalOffset = pageWidth - 16 - w;
  doc.text(totalAmountFormatted, horizontalOffset, verticalOffset);

  const newDoc = await Promise.all([doc.save(`Estimate - ${values.customerName} - ${name}.pdf`)]);

  completion();
};

export default generateEstimate;
