import React from "react";

import { formatPrice } from "../../common";

import { ToolFields } from "types/admin/ToolFields";

import { Button } from "components/Button";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import Palette from "../../palette.json";
import { TaskFields_subcontractors_items } from "types/admin/TaskFields";
import { BillingType } from "types/admin/globalTypes";
import { useHistory } from "react-router-dom";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

const totalStyle = {
  fontWeight: 500,
  fontSize: "16px",
};

interface SubcontractorListProps {
  items: (TaskFields_subcontractors_items | null)[];
  total?: number;
}

export const SubcontractorList: React.FC<SubcontractorListProps> = ({ items, total }) => {
  const cols: string[] = ["Name", "Description", "Price", "Cost"];
  const history = useHistory();
  const project = useProject();
  const { task } = useTask();

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Subcontractors <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        {project?.id && task?.id && (
          <Button
            raised
            onClick={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Subcontractors`)}
          >
            Manage
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={cols.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {items.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>{it?.name}</DataTableCell>
                <DataTableCell>{it?.description}</DataTableCell>
                {it?.cost && <DataTableCell>{formatPrice(it?.cost)}</DataTableCell>}
                {it?.markupCost && <DataTableCell>{formatPrice(it?.markupCost)}</DataTableCell>}
              </DataTableRow>
            ))}
            <DataTableRow>
              <DataTableCell style={totalStyle}>Total Subcontractor Cost</DataTableCell>
              {[...Array(cols.length - 2).keys()].map((col, i) => (
                <DataTableCell key={i} />
              ))}
              <DataTableCell style={totalStyle}>{formatPrice(total || 0)}</DataTableCell>
            </DataTableRow>
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
