import React from "react";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import styled from "styled-components";
import { StatusBlock, StatusBlockTitle } from "./StatusBlock";
import tinyColor from "tinycolor2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useMultiTenant from "hooks/useMultiTenant";

const Indicator = styled.div`
  width: 5px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: 0.5rem;
  background-color: orange;
  border-radius: 100%;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;

  @keyframes ping {
    75%,
    100% {
      transform: scale(1.5);
    }
  }
`;

export const StatusBlockProgress = () => {
  const { progress, isInProgress, activeUsers } = useLiveTimeSheets();
  const { theme } = useMultiTenant();

  const backStrokeColor = tinyColor(theme.primary);
  backStrokeColor.lighten(50).desaturate(30).toString();

  return (
    <StatusBlock>
      <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
        <StatusBlockTitle>Progress</StatusBlockTitle>
        {isInProgress && <Indicator />}
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "8px auto 16px",
          }}
        >
          <div style={{ width: 70 }}>
            <CircularProgressbar
              text={`${(progress * 100).toFixed(0)}%`}
              value={progress * 100}
              strokeWidth={11}
              styles={buildStyles({
                pathColor: theme.primary,
                trailColor: backStrokeColor,
                textSize: "23px",
                textColor: "black",
              })}
            />
          </div>
        </div>
        <div style={{ fontSize: "13px" }}>
          {activeUsers} active technician{activeUsers === 1 ? "" : "s"}
        </div>
      </div>
    </StatusBlock>
  );
};
