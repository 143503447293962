import React, { useEffect } from "react";
import { Route, useHistory } from "react-router";

import useLayout from "hooks/useLayout";

import "@rmwc/icon-button/styles";

import { PageHeader } from "components/PageHeader";
import { PageMain, PageContent } from "components/PageStyles";
import { PartFormDialog } from "components/parts/PartFormDialog";
import { PartsList } from "components/parts/PartsList";
import useTranslations from "hooks/useTranslations";

export const PartsPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: tCommon("Parts"), path: "/parts" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path="/parts/new">
        <PartFormDialog action={"create"} onClose={() => history.goBack()} />
      </Route>

      <PageHeader title={tCommon("Parts")} />

      <PageMain>
        <PageContent>
          <PartsList />
        </PageContent>
      </PageMain>
    </>
  );
};
