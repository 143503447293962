import React from "react";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";
import Palette from "../palette.json";
import styled from "styled-components";

const ProgressWrapper = styled.div`
  position: relative;
  margin-left: 8px;
`;

const Indicator = styled.div`
  position: absolute;
  right: -6px;
  top: 0;
  width: 5px;
  height: 5px;
  background-color: orange;
  border-radius: 100%;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;

  @keyframes ping {
    75%,
    100% {
      transform: scale(1.5);
    }
  }
`;

type Props = {
  progress: number;
  isInProgress?: boolean;
  color: string;
};

export const MiniProgress = ({ progress, isInProgress, color }: Props) => {
  return (
    <ProgressWrapper>
      <CircularProgress progress={progress} style={{ zIndex: 2, color }} />
      <ThemeProvider options={{ primary: Palette.LighterGrey }}>
        <CircularProgress progress={1} style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }} />
        {isInProgress && <Indicator></Indicator>}
      </ThemeProvider>
    </ProgressWrapper>
  );
};
