import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useForm } from "react-hook-form";

import { ClientType, ClientAddressInput } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";

import { snackbar } from "hooks/useNotifications";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { Form, FormRow, Text, Select } from "components/Form";

import { ThemeProvider } from "@rmwc/theme";

import Palette from "../../palette.json";
import LoadingIndicator from "../LoadingIndicator";
import { showErrorMessage } from "../../utilities/handleError";

interface FormValuesT {
  id?: string;
  clientType: ClientType;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  name: string;
}

interface ClientFormDialogProps {
  action: "create" | "update";
  renderToPortal?: boolean;
  onClose?: (clientId?: string) => void;
}

export const ClientFormDialog: React.FC<ClientFormDialogProps> = ({ action, renderToPortal, onClose: onTheClose }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string }>();

  const { addClient, updateClient } = useAdminApi();
  const client = useClient();

  const clientTypeOpts = [
    { label: "Company", value: ClientType.Company },
    { label: "Individual", value: ClientType.Individual },
  ];

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientType: client ? client.clientType || ClientType.Company : ClientType.Company,
      name: client ? client.name : "",
      street1: client ? client.address?.street1 : "",
      street2: client ? client.address?.street2 : "",
      city: client ? client.address?.city : "",
      state: client ? client.address?.state : "",
      zip: client ? client.address?.zip : "",
      country: client ? client.address?.country : "",
    },
  });
  const { formState, setValue } = form;
  const { isDirty } = formState;
  const [loading, setLoading] = React.useState<boolean>(false);

  const title = action === "create" ? "Create New Customer" : "Update Customer";

  useEffect(() => {
    if (!setValue || !client) return;
    setValue("clientType", client.clientType || ClientType.Company);
    setValue("name", client.name || "");
    setValue("street1", client.address?.street1 || "");
    setValue("street2", client.address?.street2 || "");
    setValue("city", client.address?.city || "");
    setValue("state", client.address?.state || "");
    setValue("zip", client.address?.zip || "");
    setValue("country", client.address?.country || "");
  }, [setValue, client]);

  const onSubmit = form.handleSubmit(async (values: FormValuesT) => {
    setLoading(true);

    try {
      if (action === "create") {
        const client = await addClient({
          name: values.name,
          clientType: values.clientType,
          address: {
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: values.country,
          },
        });
        snackbar.notify({ title: "Customer created successfully!" });
        if (client) {
          if (onTheClose) {
            onTheClose(client?.id);
          } else {
            history.replace(locationState?.referrer ? locationState?.referrer : `/work-orders/new`);
          }
        }
      } else if (action === "update") {
        if (!client) return;
        await updateClient({
          id: client.id,
          name: values.name,
          clientType: values.clientType,
          address: {
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: values.country,
          },
        });
        history.goBack();
        snackbar.notify({ title: "Customer updated successfully!" });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  });

  const onClose = () => {
    if (onTheClose) {
      onTheClose();
      return;
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss renderToPortal>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit}>
          <FormRow>
            <Select name="clientType" label="Customer Type" options={clientTypeOpts} outlined />
          </FormRow>
          <FormRow>
            <Text name="name" label="Name" required />
          </FormRow>
          <FormRow style={{ marginTop: "1rem" }}>
            <Text name="street1" label="Street 1" />
            <Text name="street2" label="Street 2" />
          </FormRow>

          <FormRow>
            <Text name="city" label="City" />
            <Text name="state" label="State" />
          </FormRow>

          <FormRow>
            <Text name="zip" label="Zip" />
            <Text name="country" label="Country" />
          </FormRow>
        </Form>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton outlined onClick={onClose}>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        <DialogButton
          data-test-id="submit"
          raised
          disabled={!isDirty || loading}
          onClick={onSubmit}
          icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
        >
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
