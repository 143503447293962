import { useTranslation } from "react-i18next";

export default function useTranslations() {
  const { t: tCommon, i18n } = useTranslation();
  const { t: tFeature } = useTranslation("feature");

  const language = i18n.language;
  const isAviation = language === "en-aviation" || !language;

  return {
    tCommon,
    tFeature,
    isAviation,
  };
}
