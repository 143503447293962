import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";

import { snackbar } from "hooks/useNotifications";

import { Form, FormRow, Text } from "../Form";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { AssignPartInput, PartEntityType } from "types/admin/globalTypes";
import { usePart } from "hooks/params/usePart";
import { PartFields } from "types/admin/PartFields";
import _ from "lodash";
import { useTask } from "hooks/params/useTask";

interface PartFormValuesT {
  serialNumber: string | null;
}

interface PartSerialNumberFormDialogProps {
  renderToPortal?: boolean;
  onClose?: (part?: PartFields | undefined) => void;
  partId?: string | undefined;
}

export const PartSerialNumberFormDialog: React.FC<PartSerialNumberFormDialogProps> = ({
  renderToPortal,
  onClose: onTheClose,
}) => {
  const history = useHistory();
  const { task } = useTask();
  const { currentPart, partId, subPartId, subSubPartId } = usePart();
  const { assignPartToParent } = useAdminApi();

  const form = useForm<PartFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      serialNumber: "",
    },
  });

  const { formState, setValue, watch } = form;
  const { errors } = formState;

  useEffect(() => {
    if (!setValue) return;

    setValue("serialNumber", "");
  }, [setValue]);

  const onSubmit = async () => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      const error = firstError as any;
      snackbar.notify({ icon: "warning", title: error.message });
      return;
    }

    if (!currentPart) return;

    const values = form.getValues();

    let createdPart: PartFields | undefined;

    try {
      console.log(currentPart);
      const input = {
        parentPartId: currentPart!.parentPartId || currentPart!.id,
        parentEntityType: PartEntityType.Part,
        serialNumber: values.serialNumber,
        cost: currentPart!.cost,
        leadTimeDays: currentPart.leadTimeDays,
        quantity: null,
        isBillable: currentPart!.isBillable,
        markupType: currentPart!.markupType,
        markup: currentPart!.markup,
        markupCost: currentPart!.markupCost,
      } as AssignPartInput;

      await assignPartToParent(
        {
          parentEntityId: currentPart!.id,
          input,
        },
        partId,
        subPartId,
        subSubPartId,
        task?.id,
      );

      if (onTheClose) {
        onTheClose(createdPart);
      } else {
        history.goBack();
      }
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
      return;
    }
  };

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    if (onTheClose) {
      onTheClose();
      return;
    }

    if (ev.detail.action === "close") {
      history.goBack();
      return;
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss renderToPortal={renderToPortal}>
      <DialogTitle>Add New Part Serial Number</DialogTitle>

      <DialogContent style={{ minWidth: "30vw" }}>
        <Form form={form} onSubmit={onSubmit}>
          <FormRow>
            <Text name="serialNumber" label="Serial Number" required />
          </FormRow>
        </Form>
      </DialogContent>

      <DialogActions>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={(event) => onClose(event as any)}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit}>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
