import React, { useState } from "react";
import { LocationFields } from "types/admin/LocationFields";
import useSorted, { sortByProperty } from "hooks/useSorted";
import useFiltered from "hooks/useFiltered";
import { TextInput } from "../TextInput";
import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { IconButton } from "@rmwc/icon-button";
import EmptyState from "components/EmptyState";
import { useHistory } from "react-router-dom";
import { formatAddress } from "utilities/location";
import { useAdminApi } from "hooks/useAdminApi";
import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";
import { LocationClientsAvatars } from "./LocationClientsAvatars";
import { Button } from "@rmwc/button";

const cols = ["Name", "Clients", "Address"];
interface LocationListProps {
  items: LocationFields[];
}

export const LocationList: React.FC<LocationListProps> = ({ items }) => {
  const { deleteLocation, clients } = useAdminApi();
  const history = useHistory();

  const [searchString, setSearchString] = useState<string>("");
  const sorted = useSorted(items, sortByProperty("name"));
  const filtered = useFiltered(
    sorted,
    (it) =>
      (it.name || "").toLowerCase().includes(searchString.toLowerCase()) ||
      !!it.clients?.items?.find((it) => (it.clientName || "").toLowerCase().includes(searchString.toLowerCase())),
  );

  const onNewLocation = (event?: any) => {
    event.stopPropagation();
    const path = `/locations/new`;
    history.push(path);
  };

  const onDelete = async (item: LocationFields) => {
    if (!item) return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete location: ${item.name || item.id}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteLocation({ id: item.id });
    snackbar.notify({ title: "Location removed successfully!" });
    history.replace("/locations");
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {"Locations "}
          <TitleCount>({filtered.length})</TitleCount>
        </TableToolbarTitle>

        <TextInput
          placeholder="Search by Name or Client"
          data-test-id="search"
          value={searchString}
          onChange={(ev) => setSearchString(ev.target.value)}
        />

        <Button raised onClick={onNewLocation} style={{ marginLeft: "16px" }}>
          New Location
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={cols} />
          <DataTableBody data-test-id="locations-list">
            {!filtered.length && (
              <DataTableRow>
                <DataTableCell colSpan={cols.length}>
                  <EmptyState
                    icon="assignment"
                    text={"No locations found. Add first location."}
                    buttonText={"New Location"}
                    onClick={onNewLocation}
                  />
                </DataTableCell>
              </DataTableRow>
            )}

            {filtered.map((it, i) => (
              <DataTableRow key={i} onClick={() => history.push(`/locations/${it.id}/details`)}>
                <DataTableCell>{it.name}</DataTableCell>
                <DataTableCell>
                  <LocationClientsAvatars
                    location={it}
                    clients={it.clients?.items || []}
                    onClickAvatar={async () => history.push(`locations/${it.id}/assign`)}
                  />
                </DataTableCell>
                <DataTableCell>{formatAddress(it.address)}</DataTableCell>

                <DataTableCell>
                  <DataCellTools>
                    <IconButton
                      icon="edit"
                      data-test-id="edit-btn"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(`/locations/${it.id}/edit`);
                      }}
                    />
                    <IconButton
                      icon="delete"
                      data-test-id="delete-btn"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onDelete(it);
                      }}
                    />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
