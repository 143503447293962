import React from "react";

import { DataTableCell } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import "@rmwc/circular-progress/styles";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import { TaskFields } from "types/admin/TaskFields";
import { useTemplate } from "hooks/params/useTemplate";
import { ProjectFields } from "types/admin/ProjectFields";

interface TableProgressCellProps {
  project?: ProjectFields;
  task?: TaskFields;
}

export const TableHoursCell: React.FC<TableProgressCellProps> = ({ project, task }) => {
  const { template } = useTemplate();
  const isTemplate = !!template;
  const { totalTime } = useLiveTimeSheets(project?.id, task?.id);
  const estimatedHours = project?.estimateManHrs || task?.estimateManHrs || 0;

  return (
    <DataTableCell>
      {isTemplate
        ? estimatedHours
        : `${totalTime > 1 || totalTime === 0 ? Math.floor(totalTime) : totalTime.toFixed(2)}/${estimatedHours.toFixed(
            2,
          )}`}
    </DataTableCell>
  );
};
