import gql from "graphql-tag";

export const setTermsAndConditionsMutation = gql`
  mutation SetTermsAndConditions {
    asAdmin {
      setTermsAndConditionsAgreement {
        userId
        acceptedAt
      }
    }
  }
`;
