import React from "react";

import { Theme } from "@rmwc/theme";
import { ThemePropT } from "@rmwc/types";

import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";

import styled from "styled-components";
import { TaskStatus } from "types/admin/globalTypes";
import { TaskStatusLabel, TASK_STATUS_DISPLAY_STRINGS } from "components/StatusLabel";

const Heading = styled(Typography)<any>`
  padding: 20px 30px;
`;
Heading.defaultProps = { use: "headline3" };

const ThemeBlocks = styled.div`
  flex-grow: 1;

  padding: 20px 30px;

  display: flex;
  flex-wrap: wrap;

  background-color: white;

  span {
    padding: 20px;
    min-width: 200px;
    text-align: center;
    border: 1px solid black;
  }
`;

const StatusList = styled.ul`
  margin: 1rem 4rem;

  > li {
    margin: 1rem 0;
  }
`;

export const DebugPage: React.FC = () => {
  return (
    <>
      <Heading>Typography</Heading>
      <ThemeBlocks>
        <Typography use="headline1">headline1</Typography>
        <Typography use="headline2">headline2</Typography>
        <Typography use="headline3">headline3</Typography>
        <Typography use="headline4">headline4</Typography>
        <Typography use="headline5">headline5</Typography>
        <Typography use="headline6">headline6</Typography>
        <Typography use="subtitle1">subtitle1</Typography>
        <Typography use="subtitle2">subtitle2</Typography>
        <Typography use="body1">body1</Typography>
        <Typography use="body2">body2</Typography>
        <Typography use="caption">caption</Typography>
        <Typography use="button">button</Typography>
        <Typography use="overline">overline</Typography>
      </ThemeBlocks>

      <Heading>Theme</Heading>
      <ThemeBlocks style={{ backgroundColor: "#ddd" }}>
        {(
          [
            "primary",
            "secondary",
            "error",
            "background",
            "surface",
            "primaryBg",
            "secondaryBg",
            "textPrimaryOnBackground",
            "textSecondaryOnBackground",
            "textHintOnBackground",
            "textDisabledOnBackground",
            "textIconOnBackground",
            "textPrimaryOnLight",
            "textSecondaryOnLight",
            "textHintOnLight",
            "textDisabledOnLight",
            "textIconOnLight",
          ] as ThemePropT[]
        ).map((theme, i) => (
          <Theme use={theme} key={i}>
            {theme}
          </Theme>
        ))}
      </ThemeBlocks>

      <ThemeBlocks style={{ backgroundColor: "#333" }}>
        {(
          [
            "onPrimary",
            "onSecondary",
            "onError",
            "textPrimaryOnDark",
            "textSecondaryOnDark",
            "textHintOnDark",
            "textDisabledOnDark",
            "textIconOnDark",
          ] as ThemePropT[]
        ).map((theme, i) => (
          <Theme use={theme} key={i}>
            {theme}
          </Theme>
        ))}
      </ThemeBlocks>

      <StatusList>
        {Object.keys(TaskStatus).map((it, i) => (
          <li key={i}>
            <TaskStatusLabel status={it as TaskStatus}>{TASK_STATUS_DISPLAY_STRINGS[it as TaskStatus]}</TaskStatusLabel>
          </li>
        ))}
      </StatusList>
    </>
  );
};
