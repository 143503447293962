import React from "react";
import styled from "styled-components";

const ReportCard = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(41, 22, 22, 0.12);
  border-top: 2px solid ${(props) => props.theme.primary};
`;

const Heading = styled.header`
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  border-bottom: solid 1px #eee;
`;

const Body = styled.div`
  padding: 24px;
`;

interface SimpleCardProps {
  children: any;
  heading: any;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ children, heading }) => {
  return (
    <div>
      <ReportCard>
        <Heading>{heading}</Heading>
        <Body>{children}</Body>
      </ReportCard>
    </div>
  );
};

export default SimpleCard;
