import React, { useEffect } from "react";
import styled from "styled-components";
import CustomerAppIcon from "components/CustomerAppPromo";
import { PageHeader } from "components/PageHeader";

import { PageContent, PageMain } from "components/PageStyles";
import TechnicianAppIcon from "components/TechnicianAppPromo";
import { useFirebase } from "hooks/useFirebase";
import useMultiTenant from "hooks/useMultiTenant";

const LogoutButton = styled.div`
  text-decoration: underline;
  font-weight: bold;
  margin-top: 1rem;
  cursor: pointer;
`;

const RowHeader = styled.h3`
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const AppRow = styled.div`
  display: flex;
  align-items: top;
  margin-top: 1.8rem;

  > * + * {
    margin-left: 1rem;
  }
`;

export const InvalidUserRolePage: React.FC = () => {
  const { signOut } = useFirebase();
  const { theme } = useMultiTenant();

  return (
    <>
      <PageHeader title={"Oops! You don't have the required access."} />

      <PageMain>
        <PageContent>
          <div style={{ fontSize: "18px" }}>
            <div>
              As a Technician or Customer, you have access to the iOS or Android apps. This web portal is for the
              management team.
            </div>
            <LogoutButton role="button" style={{ color: theme.primary }} onClick={signOut}>
              Log out
            </LogoutButton>

            <RowHeader>Download</RowHeader>
            <AppRow>
              <TechnicianAppIcon showLabel={true} />
              <CustomerAppIcon showLabel={true} />
            </AppRow>
          </div>
        </PageContent>
      </PageMain>
    </>
  );
};
