import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useFiltered from "hooks/useFiltered";
import { usePagenator } from "hooks/usePagenator";
import { SearchInput } from "../TextInput";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "components/Table";
import { PagenatorControls } from "../PagenatorControls";
import "@rmwc/icon-button/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { SigningEntityType } from "types/admin/globalTypes";
import dayjs from "dayjs";
import Palette from "../../palette.json";
import { SignatureFields } from "types/admin/SignatureFields";
import { useAdminApi } from "hooks/useAdminApi";

interface SigningListProps {
  signatures: SignatureFields[];
  loadMore?: () => void;
  nextToken?: string;
}

export const SigningList: React.FC<SigningListProps> = ({ signatures, loadMore, nextToken }) => {
  const history = useHistory();
  const { getTask, tasks } = useAdminApi();
  const [search, setSearch] = useState<string>("");
  const filtered = useFiltered(signatures, (it) => !!it.task?.name.toLowerCase().includes(search.toLowerCase()));
  const groupedSignatures = filtered.reduce(
    (acc, cur) => {
      if (acc[cur.entityId]) {
        acc[cur.entityId] = {
          signedDate: cur.signedDate,
          task: cur.task,
          inspectionSignatures: [
            ...acc[cur.entityId].inspectionSignatures,
            ...(cur.entityType === SigningEntityType.TaskInspectionForm ? [cur] : []),
          ],
          logBookSignatures: [
            ...acc[cur.entityId].logBookSignatures,
            ...(cur.entityType === SigningEntityType.TaskLogBookSticker ? [cur] : []),
          ],
          stamps: [
            ...acc[cur.entityId].stamps,
            ...(cur.entityType === SigningEntityType.TaskInspectionFormStamp ? [cur] : []),
          ],
        };
      } else {
        acc.entityCount += 1;
        acc[cur.entityId] = {
          signedDate: cur.signedDate,
          task: cur.task,
          inspectionSignatures: cur.entityType === SigningEntityType.TaskInspectionForm ? [cur] : [],
          logBookSignatures: cur.entityType === SigningEntityType.TaskLogBookSticker ? [cur] : [],
          stamps: cur.entityType === SigningEntityType.TaskInspectionFormStamp ? [cur] : [],
        };
      }
      return acc;
    },
    { entityCount: 0 },
  );

  const { pageRange, ...controls } = usePagenator(10, groupedSignatures.entityCount);

  useEffect(() => {
    if (!nextToken) return;
    if (controls.pageCurrent === controls.pageCount) {
      if (!loadMore) return;
      loadMore();
    }
  }, [controls.pageCurrent, nextToken]);

  const COLS = ["Task Name", "Date", "# Signatures - Inspection", "# Signatures - Log Book", "# Todos Stamped"];

  const onRowClick = async (id: string) => {
    let task = tasks.find((it) => it.id === id);
    if (task) {
      history.push(`/work-orders/${task.projectId}/tasks/${task.id}`);
      return;
    }

    task = await getTask(id);
    if (!task) return;

    history.push(`/work-orders/${task.projectId}/tasks/${task.id}`);
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Signed Tasks <TitleCount>({groupedSignatures.entityCount})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search by Name"
          data-test-id="search"
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={COLS} />

          <DataTableBody data-test-id="signatures-list">
            {groupedSignatures.entityCount === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={4} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  No Signatures
                </DataTableCell>
              </DataTableRow>
            )}

            {Object.keys(groupedSignatures)
              .filter((it) => it !== "entityCount")
              .slice(...pageRange)
              .map((it, i) => (
                <DataTableRow key={i} onClick={() => onRowClick(it)}>
                  <DataTableCell>{groupedSignatures[it].task.name}</DataTableCell>
                  <DataTableCell>{dayjs(groupedSignatures[it].signedDate).format("YYYY-MM-DD")}</DataTableCell>
                  <DataTableCell>{groupedSignatures[it].inspectionSignatures.length}</DataTableCell>
                  <DataTableCell>{groupedSignatures[it].logBookSignatures.length}</DataTableCell>
                  <DataTableCell>{groupedSignatures[it].stamps.length}</DataTableCell>
                </DataTableRow>
              ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
