import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const usePagenator = (pageSize: number, itemCount: number) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const [pageCount, setPageCount] = useState<number>(1);
  const [pageRange, setPageRange] = useState<number[]>([0, 0]);

  const pageCurrent = parseInt(search.get("p") || "1");

  useEffect(() => {
    const pageCount = Math.ceil(itemCount / pageSize);
    setPageCount(pageCount);
  }, [pageSize, itemCount]);

  useEffect(() => {
    setPageRange([(pageCurrent - 1) * pageSize, (pageCurrent - 1) * pageSize + pageSize]);
  }, [pageSize, pageCurrent]);

  return { pageCurrent, pageCount, pageRange };
};
