import React from "react";

import { Button } from "./Button";

import { DataTable, DataTableHead, DataTableHeadCell, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import styled, { css } from "styled-components";

import Palette from "../palette.json";

export const TableTitle = styled.div`
  padding: 16px 24px 18px;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 500;

  border-radius: 4px 4px 0 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-top: 2px solid ${(props) => props.theme.primary};
  border-bottom: none;

  text-transform: none;

  color: ${Palette.DarkGrey};
  background-color: white;

  ${Button} {
    margin: 0 4px;
  }

  .mdc-menu-surface--anchor {
    margin-right: 12px;

    button {
      display: flex;
      min-width: 10rem;

      > span {
        flex-grow: 1;
        text-align: left;
      }
    }
  }
`;

export const TableContainer = styled.div<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: column;

  ${TableTitle} {
    ${(p) =>
      p.isVertical &&
      css`
        td {
          padding-right: 64px;
        }
      `}
    ${(p) =>
      !p.isVertical &&
      css`
        border-top: 2px solid ${(props) => props.theme.primary};
      `}
  }
`;

export const PageControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PageCounter = styled.span`
  margin: 0 8px;
`;

export const Table = styled(DataTable)<any>`
  width: 100%;

  table-layout: fixed;

  overflow-y: hidden;

  ${(props) =>
    props.borderless &&
    css`
      border-right-style: none;
      border-left-style: none;
      border-bottom-style: none;
    `}

  thead {
    th {
      height: auto;
      padding: 14px 16px;
      font-size: 12px;
      font-weight: 500;
      color: ${Palette.LightGrey};
      text-transform: uppercase;

      :first-of-type {
        padding-left: 32px;
      }
    }
  }

  td {
    padding: 0 16px;
    overflow: inherit;
    cursor: pointer;

    :first-of-type {
      padding-left: 32px;
    }

    > a {
      color: ${(props) => props.theme.primary};
      opacity: 0.75;
      font-weight: 500;
      text-decoration: none;
    }
  }
`;
export const TableToolbarCell = styled.th``;
export const TableToolbar = styled.div`
  padding: 16px 30px 18px;
  display: flex;
  align-items: center;

  ${Button} {
    margin: 0 4px;
  }

  .mdc-menu-surface--anchor {
    margin-right: 48px;
    button {
      width: 15rem;
    }
  }
`;
export const TableToolbarTitle = styled.div`
  flex-grow: 1;
  text-align: left;
`;
export const DataCellTools = styled.div`
  color: ${Palette.LightGrey};

  display: flex;
  justify-content: flex-end;

  .mdc-icon {
    width: 20px;
    height: 20px;
  }
`;
export const TitleCount = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${Palette.LightGrey};
`;

export interface SortableColumnT {
  label: string;
  sortDir?: number;
}

interface TableHeadProps {
  cols: (string | SortableColumnT)[];
  sortBy?: (column: any) => void;
}

export const TableHead: React.FC<TableHeadProps> = ({ cols, sortBy }) => {
  return (
    <>
      <DataTableHead>
        <DataTableRow>
          {cols.map((it: any, i) => (
            <DataTableHeadCell
              key={i}
              sort={typeof it === "object" && it.sortDir}
              onSortChange={(sortDir) => {
                if (typeof it === "object" && typeof sortBy === "function") {
                  sortBy({ column: it, sortDir });
                }
              }}
            >
              {it?.label ?? it}
            </DataTableHeadCell>
          ))}
        </DataTableRow>
      </DataTableHead>
    </>
  );
};
