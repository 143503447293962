import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from "react";
import { useAdminApi } from "./useAdminApi";
import { AddCorrectiveActionVariables } from "types/admin/AddCorrectiveAction";
import { GetCorrectiveActionsVariables } from "types/admin/GetCorrectiveActions";

interface CorrectiveActionsContextT {
  correctiveActions: any[];
  addCorrectiveAction: (input: AddCorrectiveActionVariables) => Promise<void>;
  getCorrectiveActions: (input: { taskId: string }) => Promise<any>;
}

export const CorrectiveActionsContext = createContext({ correctiveActions: [] } as CorrectiveActionsContextT);
export const useCorrectiveActions = () => useContext(CorrectiveActionsContext);

type Props = PropsWithChildren<{
  taskId: string;
}>;

export const CorrectiveActionsProvider: React.FC<Props> = ({ taskId, children }: Props) => {
  const [correctiveActions, setCorrectiveActions] = useState<any[]>([]);
  const { addCorrectiveAction: addCorrectiveActionAdmin, getCorrectiveActions: getCorrectiveActionsAdmin } =
    useAdminApi();

  useEffect(() => {
    setCorrectiveActions([]);
    getCorrectiveActions({ taskId }).then(setCorrectiveActions);
  }, [taskId]);

  const addCorrectiveAction = async (input: AddCorrectiveActionVariables) => {
    const action = await addCorrectiveActionAdmin(input);
    setCorrectiveActions((actions) => [action, ...actions]);
  };

  const getCorrectiveActions = async (input: GetCorrectiveActionsVariables) => {
    const actions = await getCorrectiveActionsAdmin(input);
    setCorrectiveActions(actions);
    return actions;
  };

  return (
    <CorrectiveActionsContext.Provider
      value={{
        correctiveActions,
        addCorrectiveAction,
        getCorrectiveActions,
      }}
    >
      {children}
    </CorrectiveActionsContext.Provider>
  );
};
