import gql from "graphql-tag";

export const NotificationFields = gql`
  fragment NotificationFields on UserNotification {
    id
    userId

    entity
    entityId
    entityKey
    entityType

    description
    changes

    notificationEvent

    postedAt
    readAt
  }
`;

export const onNotificationSubscription = gql`
  ${NotificationFields}

  subscription OnNotification($tenantId: ID!, $entityType: NotificationEntityType) {
    onNotification(tenantId: $tenantId, entityType: $entityType) {
      ...NotificationFields
    }
  }
`;
