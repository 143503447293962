import React from 'react'
import styled from 'styled-components';

type Props = {
  children: React.ReactNode
  enabled: boolean
}

const DisabledWrapper = styled.div<Props>`
  pointer-events: ${props => props.enabled ? 'none' : 'auto'};
  opacity: ${props => props.enabled ? '0.5' : '1'};
`;

export default DisabledWrapper