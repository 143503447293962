import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { snackbar } from "hooks/useNotifications";
import { Label } from "../Label";
import { TextArea } from "../TextArea";
import { CommonForm, FormField, FormRow } from "../CommonForm";
import { Dialog } from "../Dialog";
import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { useParams } from "react-router-dom";
import { showErrorMessage } from "utilities/handleError";
import { useCorrectiveActions } from "hooks/useCorrectiveActions";

export interface FormValuesT {
  message: string;
  projectId: string;
  taskId: string;
}

const HIDDEN_FIELDS = ["projectId", "taskId"];

export const CorrectiveActionFormDialog: React.FC = () => {
  const history = useHistory();
  const { projectId, taskId } = useParams<{ projectId: string; taskId: string }>();
  const { addCorrectiveAction } = useCorrectiveActions();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      projectId: projectId,
      taskId: taskId,
      message: "",
    },
  });
  const { formState, register, setValue } = form;
  const { errors } = formState;

  useEffect(() => {
    if (!projectId || !taskId) return;
    setValue("projectId", projectId);
    setValue("taskId", taskId);
  }, [taskId, projectId]);

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      await addCorrectiveAction({ input: values });
      snackbar.notify({ title: "Corrective Action submitted successfully." });
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    }
  });

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>Add Corrective Action</DialogTitle>

      <DialogContent style={{ minWidth: "30vw" }}>
        <CommonForm onSubmit={onSubmit}>
          {HIDDEN_FIELDS.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          <FormRow>
            <FormField>
              <Label htmlFor="message">Message</Label>
              <TextArea style={{ margin: 0 }} name="message" ref={register({ required: true })} />
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <DialogButton dense onClick={history.goBack}>
          Cancel
        </DialogButton>

        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit} disabled={Object.keys(errors).length !== 0}>
          Send
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
