import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { TimeSheetDiscrepancyStatus, TimeSheetEntryStatus } from "types/admin/globalTypes";
import { useAdminApi } from "hooks/useAdminApi";
import { TimesheetFields } from "types/admin/TimesheetFields";

type Props = {
  start: Dayjs;
  end: Dayjs;
};

export const useDiscrepancies = ({ start, end }: Props) => {
  const { getTimesheets } = useAdminApi();
  const [timesheets, setTimesheets] = React.useState<TimesheetFields[]>([]);

  const startString = start.format();
  const endString = end.format();

  React.useEffect(() => {
    if (!startString || !endString) return;
    getTimesheets(dayjs(startString), dayjs(endString)).then((results) => {
      setTimesheets(results);
    });
  }, [startString, endString]);

  const discreps = timesheets?.filter(
    (it) =>
      !!(it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending && it.autoClockOut) ||
      it.conflictingTimesheets?.items?.length !== 0 ||
      it.status !== TimeSheetEntryStatus.Approved,
  ).length;

  const actions = timesheets?.filter(
    (it) => it.history?.changes?.filter((history) => history.userId !== it.userId)?.length > 0,
  ).length;

  return { discreps, actions };
};
