import React from "react";
import { PageHeader } from "components/PageHeader";
import "@rmwc/circular-progress/styles";
import dayjs from "dayjs";
import { TableContainer, TableTitle } from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import { TextInput } from "components/TextInput";
import { SearchInput } from "components/TextInput";
import { PageContent, PageMain } from "components/PageStyles";
import useLayout from "hooks/useLayout";
import MoraleChart from "./chart";
import { useLocation } from "react-router";
import useFiltered from "hooks/useFiltered";
import { Button } from "components/Button";
import { generateMoraleXLSX } from "../../common";
import { ReactionSelect } from "./ReactionSelect";
import PulseReportsTable from "components/users/PulseReportsTable";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import { UserFields } from "types/admin/UserFields";

export const formatPulseReports = (
  input: any,
  users: UserFields[],
  projects: ProjectFields[],
  allTasks: TaskFields[],
): any[] => {
  for (const report of input?.pulseReports) {
    report.date = dayjs(report.date).toDate();
    report.user = users.filter((u) => u.id === report.userId)[0];
    report.name = `${report.user?.firstName} ${report.user?.lastName}`;

    if (report.rating) {
      const key = report.rating.toLowerCase() as keyof typeof PulseReportTaskRating;
      report.emoji = PulseReportTaskRating[key];
    }

    report.projectsAndTasks = [];

    for (const task of report?.tasks) {
      const foundProject = task.projectId ? projects.filter((p) => p.id === task.projectId)[0] : null;
      const [foundTask] = allTasks.filter((t) => t.id === task.taskId);
      const hours = input?.timeSheets
        .filter((t: any) => t.taskId === task.taskId && t.userId === task.userId)
        .reduce((acc: any, cur: any) => (acc + cur.amountMins ? cur.amountMins * 60 : 0), 0);

      // add project to report.projectsAndTasks if not already in array
      if (foundProject && !report.projectsAndTasks.find((p: any) => p.project === foundProject.name)) {
        report.projectsAndTasks.push({
          project: foundProject.name,
          tasks: [],
        });
      }

      for (const it of report.projectsAndTasks) {
        if (foundProject?.name === it.project) {
          it.tasks.push({
            task: foundTask?.name,
            hours,
          });
        }

        // sort it.tasks by hours
        it.tasks = it.tasks.sort((a: any, b: any) => {
          if (a?.hours < b?.hours) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  }

  return input?.pulseReports;
};
export interface Props {
  filterType?: "project" | "task" | "InHangar";
  filterId?: string;
}

enum PulseReportTaskRating {
  negative = "👎",
  neutral = "👍",
  positive = "🎉",
}

export const MoralePage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();
  const [reports, setReports] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [type, setType] = React.useState<number>(0);

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const defaultFromDate = search.get("fromDate") ? dayjs(search.get("fromDate")) : dayjs().startOf("week");
  const defaultToDate = search.get("toDate") ? dayjs(search.get("toDate")) : dayjs().endOf("week");

  const [fromDate, setFromDate] = React.useState(defaultFromDate);
  const [toDate, setToDate] = React.useState(defaultToDate);

  const { getPulseReportsAndTimeSheets, users, projects, tasks: allTasks } = useAdminApi();

  let filtered = useFiltered(reports, (it) => !!it.name?.toLowerCase().includes(searchValue.toLowerCase()));
  if (type > 0) {
    filtered = filtered.filter((it) => {
      if (type === 1) return it.rating === "Positive";
      if (type === 2) return it.rating === "Neutral";
      if (type === 3) return it.rating === "Negative";
    });
  }
  const reportData = filtered.map((it) => ({
    name: it.name,
    date: it.date,
    emoji: it.emoji,
    rating: it.rating,
    comment: it.comment || "",
  }));

  const handleDownload = async () => {
    await generateMoraleXLSX(fromDate.format("MM/DD/YYYY"), toDate.format("MM/DD/YYYY"), reportData);
  };

  React.useEffect(() => {
    getPulseReportsAndTimeSheets({
      criteria: {
        startDate: fromDate.toISOString(),
        endDate: toDate.toISOString(),
      },
      fromDt: fromDate.toISOString(),
      toDt: toDate.toISOString(),
      limit: 100,
      nextToken: null,
    }).then((them) => {
      setReports(formatPulseReports(them, users, projects, allTasks));
      setIsLoading(false);
    });
  }, [getPulseReportsAndTimeSheets, fromDate, toDate, allTasks]);

  React.useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Morale Report",
        path: "/morale",
      },
    ]);
  }, []);

  return (
    <>
      <PageHeader title="Morale Report">
        <MoraleChart data={reports} />
      </PageHeader>
      <PageMain>
        <PageContent>
          <TableContainer>
            <TableTitle>
              <TextInput
                type="date"
                value={fromDate.format("YYYY-MM-DD")}
                onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD").startOf("day"))}
              />
              &nbsp;⟶&nbsp;
              <TextInput
                type="date"
                value={toDate.format("YYYY-MM-DD")}
                onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD").endOf("day"))}
              />
              <SearchInput
                placeholder="Search by Name"
                data-test-id="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                style={{ marginLeft: "15px" }}
              />
              <ReactionSelect value={type} onChange={setType} />
              <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
                Download Report
              </Button>
            </TableTitle>

            <PulseReportsTable isLoading={isLoading} reports={filtered} />
          </TableContainer>
        </PageContent>
      </PageMain>
    </>
  );
};
