import React from "react";

import { formatPrice, readableBillingType } from "../../common";
import Palette from "../../palette.json";

import { Button } from "components/Button";

import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { TaskFields } from "types/admin/TaskFields";
import { useProject } from "hooks/params/useProject";
import { useHistory } from "react-router-dom";

const totalStyle = {
  fontWeight: 500,
  fontSize: "16px",
};

interface LaborListProps {
  tasks: TaskFields[];
  total?: number;
}

export const LaborList: React.FC<LaborListProps> = ({ tasks, total }) => {
  const project = useProject();
  const history = useHistory();

  if (!project) return <></>;

  const cols: string[] = ["Billing Type", "Hourly Rate", "Estimated Hours", "Cost"];

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>Labor</TableToolbarTitle>

        {/* <Button raised onClick={() => history.replace(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Labor`)}>
          Manage
        </Button> */}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody>
            {tasks.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={cols.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}
            {tasks.map((task, i) => (
              <DataTableRow onClick={() => history.push(`/work-orders/${project.id}/tasks/${task?.id}`)} key={i}>
                {/* <DataTableCell>{task?.name}</DataTableCell> */}
                <DataTableCell>{task?.billing ? readableBillingType(task?.billing) : ""}</DataTableCell>
                <DataTableCell>{task?.hourlyRate}</DataTableCell>
                <DataTableCell>{task?.estimateManHrs}</DataTableCell>
                <DataTableCell>{formatPrice((task?.amount || 0) as number)}</DataTableCell>
              </DataTableRow>
            ))}
            <DataTableRow>
              <DataTableCell style={totalStyle}>Total Labor Cost</DataTableCell>
              {[...Array(cols.length - 2).keys()].map((col, i) => (
                <DataTableCell key={i} />
              ))}
              <DataTableCell style={totalStyle}>{formatPrice(total || 0)}</DataTableCell>
            </DataTableRow>
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
