import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";

import { snackbar } from "hooks/useNotifications";

import { Form, FormRow, Text, Number, Select, Toggle } from "components/Form";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { AssignPartInput, MarkupType, PartEntityType, PartInput, PartType } from "types/admin/globalTypes";
import { usePart } from "hooks/params/usePart";
import { MARKUP_TYPE_OPTIONS, getPartTypeOptions } from "../../common";
import { useSettings } from "hooks/useSettings";
import { PartFields } from "types/admin/PartFields";
import { AutocompleteInput } from "../AutocompleteInput";
import { showErrorMessage } from "../../utilities/handleError";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import useTranslations from "hooks/useTranslations";

interface PartFormValuesT {
  name: string;
  serialNumber: string | null;
  type: PartType | null;
  number: string | null;
  cost: number | null;
  leadTimeDays: number | null;
  quantity: number | null;
  isBillable: boolean | null;
  markupType: MarkupType | null;
  markup: number | null;
}

interface PartFormDialogProps {
  action: "create" | "assign";
  renderToPortal?: boolean;
  onClose?: (part?: PartFields | undefined) => void;
  partId?: string | undefined;
}

export const PartFormDialog: React.FC<PartFormDialogProps> = ({ action, renderToPortal, onClose: onTheClose }) => {
  const { tCommon, isAviation } = useTranslations();
  const history = useHistory();
  const { task } = useTask();
  const { template, isTemplateTypeTask } = useTemplate();
  const { settings } = useSettings();

  const { partId, subPartId, subSubPartId, currentPart, isAssembly } = usePart();
  const { addPart, parts: allParts, assignPartToParent, assignAssemblyToParent } = useAdminApi();

  const [selectedPart, setSelectedPart] = useState<PartFields | null>(null);

  let sorted: { value: string; label: string }[] = [];

  if (isAssembly || task || template) {
    sorted = allParts
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((it) => (currentPart?.type === PartType.Assembly ? it.type !== PartType.Assembly : true))
      .filter((it) => !currentPart?.parts?.items.find((p) => p.parentPartId === it.id))
      .filter((it) => !currentPart?.parts?.items.find((p) => p.number === it.number))
      .filter((it) => !task?.taskParts?.items.find((p) => p.parentPartId === it.id))
      .filter((it) => !template?.parts?.items.find((p) => p.parentPartId === it.id))
      .map((it) => ({
        value: it.id,
        label: `${it.number ? `${it.number} - ` : ""}${it.name} (${
          it.type === PartType.Consumable ? it.quantity || 0 : it.type
        })`,
      }));
  }

  const form = useForm<PartFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      name: "",
      serialNumber: null,
      type: PartType.Consumable,
      number: "",
      cost: 0.0,
      leadTimeDays: 0,
      quantity: 0,
      isBillable: false,
      markupType: MarkupType.percentage,
      markup: settings.defaultPartMarkup || 0,
    },
  });

  const { formState, setValue, watch } = form;
  const { errors } = formState;

  const partType = watch("type");
  const isBillable = watch("isBillable");
  const markupType = watch("markupType");

  const title = action === "create" ? "Create New Part" : tCommon("AddPart");

  useEffect(() => {
    if (markupType === MarkupType.percentage) {
      setValue("markup", settings.defaultPartMarkup || 0);
    } else {
      setValue("markup", 0);
    }
  }, [settings.defaultPartMarkup, markupType]);

  useEffect(() => {
    if (!partType) return;

    if (partType !== PartType.Assembly) {
      setValue("serialNumber", null);
    }

    if (partType === PartType.Consumable) {
      setValue("quantity", 0);
    }

    if (partType === PartType.Rotable) {
      setValue("quantity", 0);
    }
  }, [partType]);

  const onPartSelected = (id: string) => {
    const part = allParts.find((it) => it.id === id);
    if (!part) return;
    setSelectedPart(part);

    setValue("name", part.name);
    setValue("type", part.type);
    setValue("number", part.number);
    setValue("cost", part.cost);
    setValue("leadTimeDays", part.leadTimeDays);
    setValue("quantity", part.type === PartType.Consumable ? part.quantity : null);
    setValue("isBillable", part.isBillable);
    setValue("markupType", part.markupType);
    setValue("markup", part.markup ?? (settings.defaultPartMarkup || 0));
  };

  const onSubmit = async () => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      const error = firstError as any;
      snackbar.notify({ icon: "warning", title: error.message });
      return;
    }

    if (action === "assign" && !selectedPart) return;
    const values = form.getValues();

    // Set unused values to null
    const allValues: any = {
      ...values,
    };

    let createdPart: PartFields | undefined;

    try {
      if (action === "create") {
        createdPart = await addPart({ input: allValues as PartInput });
      } else if (action === "assign") {
        const parentEntityType = currentPart
          ? isAssembly
            ? PartEntityType.Assembly
            : PartEntityType.Part
          : task
          ? PartEntityType.Task
          : template && isTemplateTypeTask
          ? PartEntityType.TaskTemplate
          : PartEntityType.Aircraft;
        const parentEntityId = currentPart?.id || task?.id || template?.id;
        if (!parentEntityId) return;

        if (selectedPart?.type === PartType.Assembly) {
          const input = {
            parentPartId: selectedPart!.id,
            parentEntityType,
            leadTimeDays: allValues.leadTimeDays,
          };
          await assignAssemblyToParent({ parentEntityId, input }, task?.id, template?.id);
        } else {
          const input = {
            parentPartId: selectedPart!.id,
            parentEntityType,
            cost: allValues.cost,
            leadTimeDays: allValues.leadTimeDays,
            quantity: selectedPart!.type === PartType.Consumable ? allValues.quantity : null,
            isBillable: allValues.isBillable,
            markupType: allValues.markupType,
            markup: allValues.markup,
          } as AssignPartInput;

          await assignPartToParent({ parentEntityId, input }, partId, subPartId, subSubPartId, task?.id, template?.id);
        }
      }

      if (onTheClose) {
        onTheClose(createdPart);
      } else {
        history.goBack();
      }
    } catch (err) {
      showErrorMessage(err);
      return;
    }
  };

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    if (onTheClose) {
      onTheClose();
      return;
    }

    if (ev.detail.action === "close") {
      history.goBack();
      return;
    }

    history.goBack();
  };

  const validateInt = (value: number) => {
    return value >= 0 || `Cannot be negative`;
  };

  const partTypeOptions = getPartTypeOptions(isAviation);

  return (
    <Dialog open preventOutsideDismiss renderToPortal={renderToPortal}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent style={{ minWidth: "30vw" }}>
        {action === "assign" && (
          <div style={{ marginBottom: 24 }}>
            <AutocompleteInput
              absolute
              dropdownMaxHeight={"200px"}
              placeholder="Search for a part in the inventory..."
              testId="part-search"
              options={[...sorted] as any}
              select={(id) => onPartSelected(id)}
            />
          </div>
        )}
        <Form form={form} onSubmit={onSubmit}>
          <FormRow>
            <Select
              outlined
              disabled={action === "assign"}
              name="type"
              label={tCommon("PartType")}
              options={partTypeOptions}
              defaultValue={PartType.Consumable}
            />
          </FormRow>

          <FormRow>
            {action !== "assign" && <Text name="name" label="Name" required />}
            <Text name="number" label="Number" disabled={action === "assign"} required />
          </FormRow>

          {partType === PartType.Assembly && !task && (
            <FormRow>
              <Text name="serialNumber" label="Serial Number" required />
            </FormRow>
          )}

          <FormRow>
            <Number
              name="leadTimeDays"
              label="Lead Time (Days)"
              validate={validateInt}
              disabled={action === "assign" && !selectedPart}
              required
            />
          </FormRow>

          {(partType === PartType.Rotable || partType === PartType.Consumable || partType === PartType.Expendable) && (
            <FormRow>
              <Number
                name="cost"
                label="Cost"
                validate={validateInt}
                disabled={action === "assign" && !selectedPart}
                required
              />
              <Toggle
                name="isBillable"
                label="Billable?"
                disabled={action === "assign" && !selectedPart}
                defaultValue={true}
                onChange={(evt: any) => setValue("isBillable", !!evt.currentTarget.checked)}
              />
            </FormRow>
          )}

          {(partType === PartType.Rotable || partType === PartType.Consumable || partType === PartType.Expendable) && (
            <FormRow>
              <Select
                outlined
                name="markupType"
                label="Markup Type"
                options={MARKUP_TYPE_OPTIONS}
                disabled={!isBillable || (action === "assign" && !selectedPart)}
                defaultValue={MarkupType.percentage}
              />
              <Number
                name="markup"
                label="Markup"
                validate={validateInt}
                required
                disabled={!isBillable || (action === "assign" && !selectedPart)}
              />
            </FormRow>
          )}

          {partType === PartType.Consumable && (
            <Number
              name="quantity"
              label="Quantity"
              validate={validateInt}
              disabled={action === "assign" && !selectedPart}
              required
            />
          )}
        </Form>
      </DialogContent>

      <DialogActions>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={(event) => onClose(event as any)}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit} data-test-id="part-save-btn">
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
