import { useParams } from "react-router";
import { TemplateType } from "types/admin/globalTypes";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  templateId: string;
}
export const useTemplate = () => {
  const { templateId } = useParams<ParamsT>();

  const { templates, getTemplate } = useAdminApi();
  const template = templates.find((it) => it.id === templateId);
  const isTemplateTypeTask = template?.type === TemplateType.Task;
  const isTemplateTypeProject = template?.type === TemplateType.Project;

  return { template, isTemplateTypeTask, isTemplateTypeProject, templateId, getTemplate };
};
