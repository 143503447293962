import styled, { css } from "styled-components";

import Palette from "../palette.json";

export const TextInput = styled.input<{ isInvalid?: boolean }>`
  padding: 8px 16px;

  border: 1px solid ${Palette.BorderColor};
  border-radius: 4px;

  font-size: 14px;
  font-weight: normal;
  color: ${Palette.DarkGrey};

  outline: none;

  ::placeholder {
    color: ${Palette.LightGrey};
  }

  ${(p) =>
    p.isInvalid &&
    css`
      color: ${Palette.Red};
      border-color: ${Palette.Red};
    `}
`;
TextInput.defaultProps = { type: "text" };

export const PasswordInput = styled(TextInput)``;
PasswordInput.defaultProps = { type: "password" };

export const SearchInput = styled(TextInput)``;

export const DateInput = styled(TextInput)``;
DateInput.defaultProps = { type: "date" };
