import React from "react";
import styled from "styled-components";

const width = 85;

const Caption = styled.figcaption`
  font-weight: 500;
  max-width: ${width * 2}px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  line-height: 1.2;
`;

interface CustomerAppIconProps {
  showLabel?: boolean;
}

const CustomerAppIcon: React.FC<CustomerAppIconProps> = ({ showLabel }) => {
  return (
    <figure>
      <a
        href="https://apps.apple.com/us/app/foxtrot-insight-customer/id1533986776?itscg=30200&amp;itsct=apps_box_appicon"
        style={{
          width: `${width}px`,
          height: `${width}px`,
          borderRadius: "22%",
          overflow: "hidden",
          display: "inline-block",
          verticalAlign: "middle",
        }}
      >
        <img
          src="https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/c8/97/12/c897128c-4c5e-478a-496f-93fc39cd8662/AppIcon-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-85-220.png/540x540bb.jpg"
          alt="FoxTrot Insight Technician"
          style={{
            width: `${width}px`,
            height: `${width}px`,
            borderRadius: "22%",
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        />
      </a>

      {showLabel && <Caption>FoxTrot Insight Customer</Caption>}
    </figure>
  );
};

export default CustomerAppIcon;
