import React, { useState } from "react";
import { useHistory } from "react-router";
import { ProjectFields } from "types/admin/ProjectFields";
import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";
import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";
import toast from "react-hot-toast";
import useFiltered from "hooks/useFiltered";
import useSorted, { sortByColumn } from "hooks/useSorted";
import { Button } from "components/Button";
import { ProjectStatusLabel } from "components/StatusLabel";
import { Text, Inner } from "components/TableProgressCell";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { LinearProgress } from "@rmwc/linear-progress";
import "@rmwc/linear-progress/styles";
import {
  DataCellTools,
  SortableColumnT,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import dayjs from "dayjs";
import { TextInput } from "components/TextInput";
import { DeleteProjectVariables } from "types/admin/DeleteProject";
import { TableProgressCell } from "components/TableProgressCell";
import { TableHoursCell } from "components/TableHoursCell";
import EmptyState from "components/EmptyState";
import { useAircraft } from "hooks/params/useAircraft";
import useTranslations from "hooks/useTranslations";
import { showErrorMessage } from "utilities/handleError";
import useWorkOrderSummary from "hooks/useWorkOrderSummary";
import { Tooltip } from "@rmwc/tooltip";
import styled from "styled-components";
import useScreenDimmer from "hooks/useScreenDimmer";
import { useLocation } from "react-router-dom";

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 6px;
  }
`;

interface ProjectListProps {
  items: ProjectFields[];

  hiddenFields?: string[];
}

export const ProjectList: React.FC<ProjectListProps> = ({ items, hiddenFields = [] }) => {
  const { tCommon } = useTranslations();

  const location = useLocation();
  const history = useHistory();
  const { setIsDimmed } = useScreenDimmer();
  const { me } = useAdminApi();
  const { generate } = useWorkOrderSummary();
  const { clients, deleteProject } = useAdminApi();
  const client = useClient();
  const { entity: aircraft } = useAircraft();

  const [searchString, setSearchString] = useState<string>("");
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);

  const defaultColumnLabels = [
    { label: "Work Order Name", sortDir: 0 },
    { label: "Customer", sortDir: 0 },
    { label: "Progress", sortDir: 0 },
    { label: "Hours", sortDir: 0 },
    { label: "Status", sortDir: 0 },
    { label: "Due Date", sortDir: -1 },
    { label: "", sortDir: 0 },
  ].filter((it) => !hiddenFields.includes(it.label));

  const [columnLabels, setColumnLabels] = useState<(string | SortableColumnT)[]>(defaultColumnLabels);
  const [sortedByColumn, setSortedByColumn] = useState<SortableColumnT | undefined>(
    defaultColumnLabels[5] as SortableColumnT,
  );

  const sorted = useSorted(items, sortByColumn(sortedByColumn));

  const [filter, setFilter] = useState<number>(0);
  const filtered = useFiltered(sorted, (it) => {
    if (searchString.length > 0) {
      const clientName = clients.find((jt) => it.clientId === jt.id)?.name;
      console.info(clientName);

      if (
        !it.name.toLowerCase().startsWith(searchString.toLowerCase()) &&
        !clientName?.toLowerCase().startsWith(searchString.toLowerCase())
      )
        return false;
    }

    if (filter === 1) {
      return !!it.users?.items.find((jt) => jt.userId === me?.id);
    }

    return true;
  });

  const FILTER_OPTS = ["All", "Owned by Me"];

  const onNewProject = () => {
    if (!client) return history.push("/work-orders/new", { referrer: "/work-orders" });
    history.push(`/clients/${client.id}/work-orders/new`, { referrer: `/clients/${client.id}/work-orders` });
  };

  const downloadSummaryPDF = async (ev: any, it: any) => {
    ev.stopPropagation();
    setDownloadingPDF(true);
    setIsDimmed(true);

    await toast.promise(generate(it), {
      loading: (
        <LoadingWrapper>
          <div>Downloading...</div>
          <LinearProgress />
        </LoadingWrapper>
      ),
      success: "Downloaded!",
      error: "Error downloading",
    });

    setDownloadingPDF(false);
    setIsDimmed(false);
  };

  const onDelete = async (project: ProjectFields) => {
    let hasConfirmed = false;

    hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete work order: ${project.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    try {
      await deleteProject(project as DeleteProjectVariables);
      snackbar.notify({ title: "Work order removed successfully!" });
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onEdit = async (project: ProjectFields) => {
    history.push(`${location.pathname}/${project.id}/edit_`);
  };

  const sortProjectBy = (input: any) => {
    setColumnLabels(
      defaultColumnLabels.map((it) => {
        if (typeof it === "string") return it;
        if (it.label === input.column.label) {
          const theColumn = {
            label: it.label,
            sortDir: input.sortDir,
          };
          setSortedByColumn(theColumn);
          return theColumn;
        }
        return {
          label: it.label,
          sortDir: 0,
        };
      }),
    );
  };

  console.log(columnLabels.length - hiddenFields.length);

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {filter === 1 ? "My Work Orders" : aircraft ? `${tCommon("Aircraft")} Work Orders` : "All Work Orders"}{" "}
          <TitleCount>({filtered.length})</TitleCount>
        </TableToolbarTitle>

        <TextInput
          placeholder={`Search by Name${!client ? " or Customer" : ""}`}
          data-test-id="search"
          value={searchString}
          onChange={(ev) => setSearchString(ev.target.value)}
        />

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button outlined trailingIcon="unfold_more">
              {FILTER_OPTS[filter]}
            </Button>
          }
          onSelect={(ev) => setFilter(ev.detail.index)}
        >
          {FILTER_OPTS.map((it, i) => (
            <MenuItem key={i}>{it}</MenuItem>
          ))}
        </SimpleMenu>

        {!aircraft && (
          <Button raised onClick={onNewProject}>
            New Work Order
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead
            cols={columnLabels.filter((it) => typeof it !== "string" || !hiddenFields.includes(it))}
            sortBy={(event) => sortProjectBy(event)}
          />
          <DataTableBody data-test-id="projects-list">
            {filtered.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={columnLabels.length - hiddenFields.length}>
                  <EmptyState
                    icon="assignment"
                    text={"No work orders found. Add first work order."}
                    buttonText={"New Work Order"}
                    onClick={onNewProject}
                  />
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.map((it, i) => (
              <DataTableRow key={i} onClick={() => history.push(`/work-orders/${it.id}`)}>
                {!hiddenFields.includes("Work Order Name") && <DataTableCell>{it.name}</DataTableCell>}
                {!hiddenFields.includes("Customer") && (
                  <DataTableCell>{clients.find((jt) => jt.id === it.clientId)?.name}</DataTableCell>
                )}
                {!hiddenFields.includes("Progress") && typeof it.progress?.progressTimePercentage === "number" ? (
                  <TableProgressCell project={it} />
                ) : (
                  <DataTableCell>
                    <Inner>
                      <Text style={{ textAlign: "right" }}>0%</Text>
                    </Inner>
                  </DataTableCell>
                )}

                {!hiddenFields.includes("Hours") && <TableHoursCell project={it} />}

                <DataTableCell>
                  {!hiddenFields.includes("Status") && it.status ? (
                    <ProjectStatusLabel status={it.status}>{it.status}</ProjectStatusLabel>
                  ) : (
                    <div />
                  )}
                </DataTableCell>
                {!hiddenFields.includes("Due Date") && (
                  <DataTableCell>{dayjs(it.endDate).tz("UTC", true).format("l")}</DataTableCell>
                )}
                {!hiddenFields.includes("") && (
                  <DataTableCell>
                    <DataCellTools>
                      <Tooltip content="Download Summary">
                        <IconButton
                          disabled={downloadingPDF}
                          icon="download"
                          onClick={(ev) => downloadSummaryPDF(ev, it)}
                        />
                      </Tooltip>
                      <IconButton
                        disabled={downloadingPDF}
                        icon="edit"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onEdit(it);
                        }}
                      />
                      <IconButton
                        disabled={downloadingPDF}
                        icon="delete"
                        data-test-id="btn-delete"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onDelete(it);
                        }}
                      />
                    </DataCellTools>
                  </DataTableCell>
                )}
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
