import React from "react";
import { PageHeader } from "components/PageHeader";
import "@rmwc/circular-progress/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import { PageContent, PageMain } from "components/PageStyles";
import useLayout from "hooks/useLayout";
import { TaskStatus } from "types/admin/globalTypes";
import { generateProjectProgressXLSX } from "../../../common";
import dayjs from "dayjs";
import { Button } from "components/Button";
import { useProject } from "hooks/params/useProject";

interface ParamsT {
  projectId: string;
}

export const WorkOrderProgressPage: React.FC = () => {
  const project = useProject();
  const { setBreadCrumbs } = useLayout();
  const { tasks: allTasks } = useAdminApi();
  const tasks = allTasks
    .filter((it) => it.projectId === project?.id && it.parentId === project.id)
    .filter((it) => it.status !== TaskStatus.Deleted);

  const handleDownload = async () => {
    await generateProjectProgressXLSX(dayjs().format("MM/DD/YYYY"), tasks);
  };

  React.useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!project) return;

    setBreadCrumbs([
      {
        label: "Progress Report",
        path: "/progress",
      },
      {
        label: project.name,
        path: `/progress/${project.id}`,
      },
    ]);
  }, [project, setBreadCrumbs]);

  return (
    <>
      <PageHeader title={project?.name} description="Progress Report" />
      <PageMain>
        <PageContent>
          <TableContainer>
            <TableTitle>
              <TableToolbarTitle>
                Tasks <TitleCount>({tasks.length})</TitleCount>
              </TableToolbarTitle>
              <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
                Download Report
              </Button>
            </TableTitle>

            <Table>
              <DataTableContent>
                <TableHead cols={["Task", "Actual Hours", "Estimated Hours", "Efficiency"]}></TableHead>
                <DataTableBody data-test-id="pulse-reports-list">
                  {tasks.map((it) => {
                    const efficiency =
                      it.estimateManHrs === 0
                        ? 0
                        : Math.round((it.estimateManHrs / (it.progress.completedHours || 0)) * 100);
                    return (
                      <DataTableRow key={it.id}>
                        <DataTableCell>{it.name}</DataTableCell>
                        <DataTableCell>{Math.round(it.progress.completedHours * 100) / 100}</DataTableCell>
                        <DataTableCell>{Math.round(it.estimateManHrs * 100) / 100}</DataTableCell>
                        <DataTableCell>{Math.min(efficiency, 100)}%</DataTableCell>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTableContent>
            </Table>
          </TableContainer>
        </PageContent>
      </PageMain>
    </>
  );
};
