import gql from "graphql-tag";

import { AttachmentFields } from "./attachments";
import { ProgressFields } from "./fragments";
import { MessageFields } from "./messages";

export const TaskUserFields = gql`
  fragment TaskUserFields on TaskUser {
    taskId
    userId
    user {
      name
    }
  }
`;

const SubTaskPartFields = gql`
  ${AttachmentFields}

  fragment SubTaskPartFields on Part {
    id
    parentPartId
    parentEntityId
    parentEntityType
    type
    serialNumber
    manufactureDate
    expirationDate
    hoursSinceOverhaul
    hoursSinceBrush
    hoursSinceBearing
    name
    number
    quantity
    leadTimeDays
    isBillable
    cost
    markup
    markupCost
    markupType

    attachments {
      items {
        ...AttachmentFields
      }
    }
  }
`;

export const TaskPartFields = gql`
  ${SubTaskPartFields}

  fragment TaskPartFields on Part {
    ...SubTaskPartFields
    parts {
      items {
        ...SubTaskPartFields
        parts {
          items {
            ...SubTaskPartFields

            parentPart {
              ...SubTaskPartFields
            }
          }
        }

        parentPart {
          ...SubTaskPartFields
        }
      }
    }

    parentPart {
      ...SubTaskPartFields
    }
  }
`;

export const ToolFields = gql`
  fragment ToolFields on Tool {
    name
    number

    quantity

    leadTimeDays
    expirationDate
    isBillable
    cost

    markup
    markupCost
    markupType
  }
`;

export const SubcontractorFields = gql`
  fragment SubcontractorFields on Subcontractor {
    subcontractorId
    taskId
    name
    cost
    description
    markupType
    markup
    markupCost
  }
`;

export const TaskFields = gql`
  ${AttachmentFields}
  ${ProgressFields}
  ${MessageFields}

  ${TaskPartFields}
  ${ToolFields}
  ${SubcontractorFields}

  fragment TaskFields on Task {
    id

    parentId
    projectId

    orderIndex

    name
    description

    taskType
    signingType
    signingStatus

    amount
    estimateManHrs
    hourlyRate

    dueDate
    scheduledDate

    status
    inReview

    requestStatus
    requestingUserId

    taskParts {
      items {
        ...TaskPartFields
      }
    }
    tools {
      ...ToolFields
    }
    subcontractors {
      items {
        ...SubcontractorFields
      }
    }

    userShiftsAfterNow: userShifts(time: AfterNow) {
      items {
        fromDt
        toDt
      }
    }
    userShiftsUntilNow: userShifts(time: UntilNow) {
      items {
        fromDt
        toDt
      }
    }

    billing

    behindSchedule

    attachments {
      items {
        ...AttachmentFields
      }
    }

    messages {
      items {
        ...MessageFields
      }
    }

    progress {
      ...ProgressFields
    }

    tasks {
      items {
        id
        parentId
        projectId

        orderIndex

        name
        description

        taskType

        amount
        estimateManHrs

        dueDate
        scheduledDate

        status
        inReview

        requestStatus
        requestingUserId

        billing

        behindSchedule
      }
    }

    users {
      items {
        userId
        user {
          name
        }
      }
    }
  }
`;

export const listTasksQuery = gql`
  ${TaskFields}

  query ListTasks($criteria: ListTasksCriteria!, $nextToken: String) {
    asAdmin {
      listTasks(criteria: $criteria, nextToken: $nextToken) {
        items {
          ...TaskFields
        }
        nextToken
      }
    }
  }
`;

export const listTasksAndPartsQuery = gql`
  query ListTasksAndParts($criteria: ListTasksCriteria!) {
    asAdmin {
      listTasks(criteria: $criteria) {
        items {
          id
          projectId
          taskParts {
            items {
              id
              name
              leadTimeDays
              parts {
                items {
                  id
                  name
                  leadTimeDays
                  parts {
                    items {
                      id
                      name
                      leadTimeDays
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getTaskQuery = gql`
  ${TaskFields}

  query GetTask($id: ID!) {
    asAdmin {
      getTask(id: $id) {
        ...TaskFields
      }
    }
  }
`;

export const addTaskMutation = gql`
  ${TaskFields}

  mutation AddTask($input: CreateTaskRequest!) {
    asAdmin {
      addTask(input: $input) {
        ...TaskFields
      }
    }
  }
`;

export const updateTaskMutation = gql`
  ${TaskFields}

  mutation UpdateTask($input: UpdateTaskRequest!, $id: ID!, $parentId: ID!) {
    asAdmin {
      updateTask(id: $id, input: $input, parentId: $parentId) {
        ...TaskFields
      }
    }
  }
`;

export const deleteTaskMutation = gql`
  mutation DeleteTask($parentId: ID!, $id: ID!, $projectId: ID!) {
    asAdmin {
      deleteTask(parentId: $parentId, id: $id, projectId: $projectId) {
        id
      }
    }
  }
`;

export const assignUserToTaskMutation = gql`
  ${TaskUserFields}

  mutation AssignUserToTask($taskId: ID!, $userId: ID!) {
    asAdmin {
      assignUserToTask(taskId: $taskId, userId: $userId) {
        ...TaskUserFields
      }
    }
  }
`;

export const removeTaskUserMutation = gql`
  mutation RemoveTaskUser($id: ID!, $userId: ID!) {
    asAdmin {
      removeTaskUser(id: $id, userId: $userId) {
        taskId
        userId
      }
    }
  }
`;

export const assignUserToTasksMutation = gql`
  mutation AssignUserToTasks($taskIds: [ID!]!, $userId: ID!) {
    asAdmin {
      assignUserToTasks(taskIds: $taskIds, userId: $userId) {
        taskId
      }
    }
  }
`;

export const removeUserFromTasksMutation = gql`
  mutation RemoveUserFromTasks($taskIds: [ID!]!, $userId: ID!) {
    asAdmin {
      removeUserFromTasks(ids: $taskIds, userId: $userId) {
        taskId
      }
    }
  }
`;

export const addTaskFromTemplateMutation = gql`
  ${TaskFields}

  mutation AddTaskFromTemplate($templateId: ID!, $copyUsers: Boolean, $input: CreateTaskRequest!) {
    asAdmin {
      addTaskFromTemplate(templateId: $templateId, copyUsers: $copyUsers, input: $input) {
        ...TaskFields
      }
    }
  }
`;

export const addSubcontractorMutation = gql`
  ${SubcontractorFields}

  mutation AddSubcontractor($input: CreateSubcontractorRequest!, $taskId: ID!) {
    asAdmin {
      addSubcontractor(input: $input, taskId: $taskId) {
        ...SubcontractorFields
      }
    }
  }
`;

export const updateSubcontractorMutation = gql`
  ${SubcontractorFields}

  mutation UpdateSubcontractor($input: CreateSubcontractorRequest!, $id: ID!, $taskId: ID!) {
    asAdmin {
      updateSubcontractor(id: $id, input: $input, taskId: $taskId) {
        ...SubcontractorFields
      }
    }
  }
`;

export const deleteSubcontractorMutation = gql`
  ${SubcontractorFields}

  mutation DeleteSubcontractor($id: ID!, $taskId: ID!) {
    asAdmin {
      deleteSubcontractor(id: $id, taskId: $taskId) {
        ...SubcontractorFields
      }
    }
  }
`;

export const completeTaskMutation = gql`
  mutation CompleteTask($taskId: ID!) {
    asAdmin {
      completeTask(id: $taskId) {
        id
      }
    }
  }
`;
