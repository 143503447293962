import React, { useState } from "react";
import { useHistory } from "react-router";

import { AircraftFields } from "types/admin/AircraftFields";

import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import useFiltered from "hooks/useFiltered";
import { usePagenator } from "hooks/usePagenator";

import { Button } from "../Button";
import { SearchInput } from "../TextInput";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";
import { PagenatorControls } from "../PagenatorControls";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import useTranslations from "hooks/useTranslations";

interface AircraftListProps {
  items: AircraftFields[];
}

export const AircraftList: React.FC<AircraftListProps> = ({ items }) => {
  const history = useHistory();
  const { tCommon, tFeature } = useTranslations();

  const { deleteEntity } = useAdminApi();
  const client = useClient();

  const [filter, setFilter] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const FILTER_OPTS = ["All", ...new Set(items.map((it) => it.make))];
  const filtered = useFiltered(items, (it) => {
    const includesQuery =
      !!it.tailNumber?.toLowerCase().includes(search.toLowerCase()) ||
      !!it.model?.toLowerCase().includes(search.toLowerCase()) ||
      !!it.make?.toLowerCase().includes(search.toLowerCase());

    if (filter === 0) {
      return includesQuery;
    }

    return it.make === FILTER_OPTS[filter] && includesQuery;
  });

  const { pageRange, ...controls } = usePagenator(8, filtered.length);

  const onDelete = async (aircraft: AircraftFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete aircraft: ${aircraft.tailNumber}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteEntity({ id: aircraft.id });
    snackbar.notify({ title: tFeature("AircraftList.deleteSuccess") });
  };

  const cols = [tCommon("Make"), tCommon("Model"), tCommon("TailNumber"), tCommon("SerialNumber")];

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {tCommon("Aircrafts")} <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder={tFeature("AircraftList.searchPlaceholder")}
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button outlined trailingIcon="unfold_more">
              {FILTER_OPTS[filter]}
            </Button>
          }
          onSelect={(ev) => setFilter(ev.detail.index)}
        >
          {FILTER_OPTS.map((it, i) => (
            <MenuItem key={i}>{it}</MenuItem>
          ))}
        </SimpleMenu>

        <Button raised onClick={() => history.push(`/clients/${client?.id}/entities/new`)}>
          {tFeature("AircraftList.createButton")}
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={cols} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={4} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  {tFeature("AircraftList.noResults")}
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => (
              <>
                <DataTableRow key={i}>
                  <DataTableCell onClick={() => history.push(`/clients/${client?.id}/entities/${it.id}`)}>
                    {it.make}
                  </DataTableCell>
                  <DataTableCell>{it.model || it.type}</DataTableCell>
                  <DataTableCell>{it.tailNumber}</DataTableCell>
                  <DataTableCell>{it.type}</DataTableCell>
                  <DataTableCell>
                    <DataCellTools>
                      <IconButton
                        icon="edit"
                        onClick={() => history.push(`/clients/${client?.id}/entities/${it.id}/edit`)}
                      />
                      <IconButton icon="delete" onClick={() => onDelete(it)} />
                    </DataCellTools>
                  </DataTableCell>
                </DataTableRow>
              </>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
