import React from "react";
import styled from "styled-components";

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: 4rem;
  animation: bounce 500ms infinite;

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
`;

export const FoxtrotLoadingView: React.FC = () => {
  return (
    <PageWrapper>
      <Logo>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 65">
          <g filter="url(#a)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.5 43.3A12.6 12.6 0 0 0 13.2 64H39V51.1h-6.2"
              fill="#FF9300"
            />
          </g>
          <g filter="url(#b)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2 22.2a3.3 3.3 0 0 1 1.7 6.2h-.2L3.4 43.3C3 44 2.7 45 2.7 46c0 2.5 1.8 4.7 4.3 5.2h25.7A34.3 34.3 0 0 0 19.4.2 16 16 0 0 1 7 18.4l-.2.2v.4c0 1.8 1.4 3.2 3.2 3.2h6.2Z"
              fill="url(#c)"
            />
          </g>
          <g filter="url(#d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.3.2c.2.9.2 1.8.2 2.6 0 7.7-5.4 14.1-12.6 15.6l-.1.2v.4c0 1.8 1.4 3.2 3.1 3.2h6.5a18.9 18.9 0 0 1 16 28.9h.2A34.3 34.3 0 0 0 19.3.2Z"
              fill="url(#e)"
            />
          </g>
          <mask id="f" maskUnits="userSpaceOnUse" x="38" y="51" width="14" height="13">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.9 51h12.8v13H39V51Z" fill="#fff" />
          </mask>
          <g filter="url(#g)" mask="url(#f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.9 51c7 0 12.8 5.9 12.8 13H39V51Z" fill="#fff" />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.6 37.3c0-10.4-8.5-18.9-19-18.9H9.1c-.5 0-1-.1-1.5-.4l-1.5.4-.1.2v.4c0 1.8 1.4 3.2 3.1 3.2h6.5c9.8 0 17.8 7.5 18.8 17v-1.9Z"
            fill="url(#h)"
          />
          <defs>
            <filter
              id="a"
              x="-1.4"
              y="33.3"
              width="40.3"
              height="31.6"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="10.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow_0_1093" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dx="-2" dy="-10" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 0.905882 0 0 0 0 0.321569 0 0 0 0 0 0 0 0 1 0" />
              <feBlend in2="effect1_innerShadow_0_1093" result="effect2_innerShadow_0_1093" />
            </filter>
            <filter
              id="b"
              x="2.7"
              y="-6.8"
              width="36.2"
              height="57.9"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="-7" />
              <feGaussianBlur stdDeviation="14.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.152941 0 0 0 0 0 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow_0_1093" />
            </filter>
            <filter
              id="d"
              x="6.7"
              y="-6.8"
              width="32.1"
              height="57.9"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="-7" />
              <feGaussianBlur stdDeviation="14.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.152941 0 0 0 0 0 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow_0_1093" />
            </filter>
            <filter
              id="g"
              x="36.9"
              y="41.1"
              width="14.9"
              height="23.9"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="10.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow_0_1093" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dx="-2" dy="-10" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix values="0 0 0 0 0.905882 0 0 0 0 0.321569 0 0 0 0 0 0 0 0 0.699082 0" />
              <feBlend in2="effect1_innerShadow_0_1093" result="effect2_innerShadow_0_1093" />
            </filter>
            <linearGradient id="c" x1="4.7" y1="-46.3" x2="-40.9" y2="23.1" gradientUnits="userSpaceOnUse">
              <stop stopColor="#D71D00" />
              <stop offset="1" stopColor="#FF9300" />
            </linearGradient>
            <linearGradient id="e" x1="-.2" y1="10.2" x2="22.3" y2="33.3" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF9300" />
              <stop offset="1" stopColor="#D71D00" />
            </linearGradient>
            <linearGradient id="h" x1="20.5" y1="7.5" x2=".4" y2="34.5" gradientUnits="userSpaceOnUse">
              <stop stopColor="#fff" />
              <stop offset="1" stopColor="#FF6800" />
            </linearGradient>
          </defs>
        </svg>
      </Logo>
    </PageWrapper>
  );
};
