import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";

import "@rmwc/icon-button/styles";
import useLayout from "hooks/useLayout";
import { PageHeader } from "components/PageHeader";
import { PageMain, PageContent } from "components/PageStyles";
import { usePart } from "hooks/params/usePart";
import * as Pages from "../../../../../../../../";
import { TabBar, Tab } from "@rmwc/tabs";
import { TabContainer } from "components/TabContainer";
import { DisplayFlex } from "components/Utilities";
import { AttachmentList } from "components/attachments/AttachmentList";
import { Switch, useParams } from "react-router-dom";
import { PartsDetailView } from "components/parts/PartsDetailView";
import { useAircraft } from "hooks/params/useAircraft";
import { useClient } from "hooks/params/useClient";
import useTranslations from "hooks/useTranslations";
import styled from "styled-components";

const AttachmentsListWrapper = styled.div`
  > * + * {
    margin-top: 2rem;
  }
`;

export const AircraftSubSubPartDetailPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const { currentPart, parentPart, part, partId, subPartId, subSubPartId, subPart } = usePart();
  const { aircraftId } = useParams<{ aircraftId: string }>();
  const { entity: aircraft, getEntity } = useAircraft();
  const client = useClient();
  const location = useLocation();
  const { setBreadCrumbs } = useLayout();

  const resourcePrefix = `/clients/${client?.id}/entities/${aircraft?.id}/parts/${partId}/parts/${subPartId}/parts/${subSubPartId}`;
  const PAGE_TABS: { icon: string; label: string; path: string }[] = [];
  if (currentPart) {
    PAGE_TABS.push({
      icon: "info",
      label: "Details",
      path: `${resourcePrefix}/details`,
    });

    PAGE_TABS.push({
      icon: "upload_file",
      label: "Attachments",
      path: `${resourcePrefix}/attachments`,
    });
  }

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (!aircraftId) return;
    getEntity({ id: aircraftId });
  }, [aircraftId]);

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!currentPart || !part || !subPart || !aircraft || !client) return;

    setBreadCrumbs([
      {
        label: "Customers",
        path: "/clients",
      },
      {
        label: client.name,
        path: `/clients/${client.id}`,
      },
      {
        label: tCommon("Aircrafts"),
        path: `/clients/${client.id}/entities`,
      },

      {
        label: aircraft?.model || aircraft?.type || aircraft?.tailNumber || "",
        path: `/clients/${client.id}/entities/${aircraft.id}`,
      },
      { label: tCommon("Parts"), path: `/clients/${client.id}/entities/${aircraft.id}/parts` },
      { label: part.name, path: `/clients/${client.id}/entities/${aircraft.id}/parts/${part.id}/parts` },
      {
        label: subPart.name,
        path: `/clients/${client.id}/entities/${aircraft.id}/parts/${part.id}/parts/${subPart.id}/parts`,
      },
      { label: currentPart.name, path: `${resourcePrefix}/parts` },
    ]);
  }, [setBreadCrumbs, currentPart]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit")) return;
    history.replace(path);
  };

  if (!currentPart) return <Route component={Pages.Error404} />;

  return (
    <>
      <PageHeader title={currentPart.name} description={currentPart.type} />

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              <DisplayFlex alignItems="center">
                <DisplayFlex>{it.label}</DisplayFlex>
              </DisplayFlex>
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            <Route path="/clients/:clientId/entities/:aircraftId/parts/:partId/parts/:subPartId/parts/:subSubPartId/details">
              <PartsDetailView />
            </Route>

            <Route path="/clients/:clientId/entities/:aircraftId/parts/:partId/parts/:subPartId/parts/:subSubPartId/attachments">
              <AttachmentsListWrapper>
                <AttachmentList type="aircraft" items={currentPart.attachments?.items || []} />
                {(parentPart?.attachments?.items?.length || 0) > 0 && (
                  <AttachmentList type="parentPart" items={parentPart?.attachments?.items || []} />
                )}
              </AttachmentsListWrapper>
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
