import gql from "graphql-tag";
import { AttachmentFields } from "./attachments";

export const SubPartFields = gql`
  ${AttachmentFields}

  fragment SubPartFields on Part {
    id
    parentPartId
    parentEntityId
    parentEntityType
    type
    status
    serialNumber
    manufactureDate
    expirationDate
    hoursSinceOverhaul
    hoursSinceBrush
    hoursSinceBearing
    name
    number
    quantity
    leadTimeDays
    isBillable
    cost
    markup
    markupCost
    markupType

    attachments {
      items {
        ...AttachmentFields
      }
    }
  }
`;

export const PartFields = gql`
  ${SubPartFields}

  fragment PartFields on Part {
    ...SubPartFields
    parts {
      items {
        ...SubPartFields
        parts {
          items {
            ...SubPartFields

            parentPart {
              ...SubPartFields
            }
          }
        }

        parentPart {
          ...SubPartFields
        }
      }
    }

    parentPart {
      ...SubPartFields
    }
  }
`;

export const listPartsQuery = gql`
  ${PartFields}

  query ListParts($nextToken: String) {
    asAdmin {
      listParts(nextToken: $nextToken) {
        items {
          ...PartFields
        }
        nextToken
      }
    }
  }
`;

export const assignPartToParentMutation = gql`
  ${PartFields}

  mutation AssignPart($parentEntityId: ID!, $input: AssignPartInput!) {
    asAdmin {
      assignPartToParent(parentEntityId: $parentEntityId, input: $input) {
        ...PartFields
      }
    }
  }
`;

export const assignAssemblyToParentMutation = gql`
  ${PartFields}

  mutation AssignAssembly($parentEntityId: ID!, $input: AssignAssemblyInput!) {
    asAdmin {
      assignAssemblyToParent(parentEntityId: $parentEntityId, input: $input) {
        ...PartFields
      }
    }
  }
`;

export const unassignPartFromParentMutation = gql`
  mutation UnassignPart($id: ID!) {
    asAdmin {
      unassignPartFromParent(id: $id) {
        id
        parentEntityId
      }
    }
  }
`;

export const addPartMutation = gql`
  ${PartFields}

  mutation AddPart($input: PartInput!) {
    asAdmin {
      addPart(input: $input) {
        ...PartFields
      }
    }
  }
`;

export const updatePartMutation = gql`
  ${PartFields}

  mutation UpdatePart($id: ID!, $parentEntityId: ID, $input: UpdatePartInput!) {
    asAdmin {
      updatePart(id: $id, parentEntityId: $parentEntityId, input: $input) {
        ...PartFields
      }
    }
  }
`;

export const deletePartMutation = gql`
  ${PartFields}

  mutation DeletePart($id: ID!, $parentEntityId: ID) {
    asAdmin {
      deletePart(id: $id, parentEntityId: $parentEntityId) {
        ...PartFields
      }
    }
  }
`;
