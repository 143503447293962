// FocusModeContext.tsx

import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

interface FocusModeContextValue {
  focusModeIsEnabled: boolean;
  toggleFocusMode: () => void;
}

const FocusModeContext = createContext<FocusModeContextValue | undefined>(undefined);

interface FocusModeProviderProps {
  children: ReactNode;
}

export const FocusModeProvider: React.FC<FocusModeProviderProps> = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const toggleFocusMode = () => {
    setIsEnabled((prev) => !prev);
    localStorage.setItem("foxtrot.focusMode", JSON.stringify(!isEnabled));
  };

  useEffect(() => {
    const focusMode = localStorage.getItem("foxtrot.focusMode");
    if (focusMode) {
      setIsEnabled(JSON.parse(focusMode));
    }
  }, []);

  return (
    <FocusModeContext.Provider value={{ focusModeIsEnabled: isEnabled, toggleFocusMode }}>
      {children}
    </FocusModeContext.Provider>
  );
};

export const useFocusMode = (): FocusModeContextValue => {
  const context = useContext(FocusModeContext);
  if (!context) {
    throw new Error("useFocusMode must be used within a FocusModeProvider");
  }
  return context;
};
