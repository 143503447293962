import gql from "graphql-tag";

export const ProgressFields = gql`
  fragment ProgressFields on Aggregate {
    calculatedCompletedCost
    calculatedEstimatedCost
    calculatedEstimateHrs

    completedHours
    completedTaskCount

    progressPercentage
    progressTimePercentage

    taskCount
  }
`;
