import { Dialog as BaseDialog, DialogProps } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import styled from "styled-components";

import Palette from "../palette.json";

export const Dialog = styled(BaseDialog)<React.PropsWithChildren<DialogProps>>`
  .mdc-dialog__surface {
    max-width: none;
  }
  .mdc-dialog__title {
    margin: 0 25px 20px !important;
    padding: 0 !important;
    line-height: 1;
    border: 0;
  }

  .mdc-dialog__content {
    padding: 20px 30px 20px;
    border-top: 1px solid #eaedf3;
    max-width: 80vw;
    background: #fbfbfd;
  }

  .mdc-dialog__actions {
    padding: 20px 30px;
    border-top: 1px solid #eaedf3;

    button {
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: normal;
      text-transform: none;

      &.mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        color: white;
      }

      &.mdc-button--outlined {
        color: ${Palette.DarkGrey};
      }
    }
  }

  .mdc-menu-surface--open {
    max-height: 30vh !important;

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: ${Palette.MediumGrey};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${Palette.DarkGrey};
    }
  }
`;
