import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";

import "@rmwc/checkbox/styles";

import { CreateTaskTemplateRequest } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";

import { Form, FormRow, Number, Text, Checkbox } from "components/Form";
import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { ThemeProvider } from "@rmwc/theme";
import Palette from "../../palette.json";
import { snackbar } from "hooks/useNotifications";

interface RequestTypes extends CreateTaskTemplateRequest {
  copyAssignedUsers: boolean;
}

export const TaskTemplateFormDialog = () => {
  const history = useHistory();

  const { addTaskTemplate } = useAdminApi();

  const { template } = useTemplate();
  const { task } = useTask();

  const form = useForm<RequestTypes>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      name: "",
      estimateManHrs: 0,
      hourlyRate: 0,
    },
  });

  const { setValue } = form;

  const onSubmit = form.handleSubmit(async ({ copyAssignedUsers, ...values }) => {
    console.log(values);
    if (task?.id) {
      const response = await addTaskTemplate(task?.id, !!copyAssignedUsers, {
        ...values,
      });
      if (response) {
        history.push(`/templates/${response.id}`);
        snackbar.notify({ title: "Template created successfully" });
      }
    }
  });

  useEffect(() => {
    if (template) {
      setValue("name", `${template?.name}`, { shouldDirty: true, shouldValidate: true });
      setValue("estimateManHrs", `${template?.estimateManHrs}`, { shouldDirty: true, shouldValidate: true });
      setValue("hourlyRate", `${template?.hourlyRate}`, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue("name", `Template of ${task?.name}`, { shouldDirty: true, shouldValidate: true });
      setValue("estimateManHrs", `${task?.estimateManHrs}`, { shouldDirty: true, shouldValidate: true });
      setValue("hourlyRate", `${task?.hourlyRate}`, { shouldDirty: true, shouldValidate: true });
    }
  }, [template]);

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>Create Task Template</DialogTitle>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit} data-test-id="task-template-form">
          <FormRow>
            <Text name="name" label="Template Name" required />
          </FormRow>
          <FormRow col={2}>
            <Number name="estimateManHrs" label="Estimated Hours" required />
            <Number name="hourlyRate" label="Hourly Rate" required />
          </FormRow>
          <FormRow>
            <Checkbox name="copyAssignedUsers" label="Copy assigned users" />
          </FormRow>
        </Form>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton onClick={history.goBack} outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit}>
          Create Template
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
