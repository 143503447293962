import jsPDF from "jspdf";
import { useAdminApi } from "hooks/useAdminApi";
import { ProjectFields } from "types/admin/ProjectFields";
import dayjs from "dayjs";
import useInspectionForm, { AttachmentType } from "./useInspectionForm";
import { TaskStatus } from "types/admin/globalTypes";
import useLogBookSticker from "./useLogBookSticker";
import { PDFDocument } from "pdf-lib";
import { downloadPDF } from "common";

export function truncate(string: string, length: number) {
  if (!string) return "";
  return string.length > length ? `${string.substring(0, length)}...` : string;
}

export default function useWorkOrderSummary() {
  const { clients, entities, tasks: allTasks } = useAdminApi();
  const { generate: generateTaskInspectionForm } = useInspectionForm();
  const { generate: generateLogBookSticker } = useLogBookSticker();

  const generate = async (project: ProjectFields, allDocs = true, includeAttachments = false) => {
    const client = clients.find((client) => client.id === project?.clientId);
    const entity = entities.find((entity) => entity.id === project?.aircraftId);
    const tasks = allTasks.filter((task) => task.projectId === project.id);

    const doc = new jsPDF("portrait", "mm", "letter");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let verticalOffset = 6;
    const horizontalOffset = 4;
    let h = 0;
    let text = "";

    doc.setFontSize(9);
    doc.setFont("helvetica");
    doc.text("WORK ORDER SUMMARY", horizontalOffset, verticalOffset);

    //Box 1
    doc.setLineWidth(0.4);
    doc.rect(horizontalOffset, verticalOffset + 2, 140, 36, "S");

    //Project Name
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    h = doc.getTextDimensions(project.name).h;
    verticalOffset += h + 4;
    doc.text(project.name || "", 6, verticalOffset);

    //Line
    doc.setLineWidth(0.2);
    doc.line(horizontalOffset + 2, verticalOffset + 3, pageWidth / 4, verticalOffset + 3, "S");

    //Client Name
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0);
    h = doc.getTextDimensions(client.name).h;
    verticalOffset += h + 5;
    doc.text(client.name || "", 6, verticalOffset);

    //Address
    if (client?.address?.street1) {
      doc.setFontSize(9);
      h = doc.getTextDimensions(client.address.street1).h;
      verticalOffset += h + 3;
      doc.text(client.address.street1, 6, verticalOffset);
    }
    if (client?.address?.street1) {
      h = doc.getTextDimensions(client.address.street2).h;
      verticalOffset += h + 1;
      doc.text(client.address.street2, 6, verticalOffset);
    }
    if (client?.address?.city && client?.address?.state) {
      text = `${client.address.city}, ${client.address.state}`;
      h = doc.getTextDimensions(text || "").h;
      verticalOffset += h + 1;
      doc.text(text || "", 6, verticalOffset);
    }
    if (client?.address?.zip) {
      h = doc.getTextDimensions(client.address.zip).h;
      verticalOffset += h + 1;
      doc.text(client.address.zip, 6, verticalOffset);
    }

    //Due date
    text = `Due: ${dayjs(project.endDate).format("MM/DD/YYYY")}`;
    h = doc.getTextDimensions(text || "").h;
    doc.text(text || "", 119, 12);

    //Description box
    doc.setLineWidth(0.2);
    verticalOffset = 50;
    doc.rect(4, 50, 140, 28, "S");

    //Description heading
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions("Work Description").h;
    verticalOffset += h + 2;
    doc.text("Work Description", 6, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(horizontalOffset + 2, verticalOffset + 3, 142, verticalOffset + 3, "S");

    //Description
    doc.setFontSize(9);
    h = doc.getTextDimensions(project.description || "").h;
    verticalOffset += h + 6;
    doc.text(project.description || "", 6, verticalOffset, { maxWidth: 136 });

    //Aircraft box
    doc.setLineWidth(0.2);
    doc.rect(148, 8, 64, 70, "S");

    //Aircraft heading
    verticalOffset = 8;
    text = `${entity.make ? `${entity.make}${entity.model ? ` ${entity.model}` : ""}` : entity.type} -- ${
      entity.tailNumber || entity.serialNumber
    }`;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions(text).h;
    verticalOffset += h + 2;
    doc.text(text, 150, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(150, verticalOffset + 3, 150 + 60, verticalOffset + 3, "S");

    //Aircraft details
    doc.setFontSize(9);
    text = `Make: ${entity.make}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 6;
    doc.text(text || "", 150, verticalOffset);

    text = `Model: ${entity.model}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Serial Number: ${entity.serialNumber}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Tail Number: ${entity.tailNumber}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Aiframe Cycles: ${entity.airframeCycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Airframe Hours: ${entity.airframeHours}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 1 Total Time: ${entity.engine1TotalTime}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 1 Cycles: ${entity.engine1Cycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 2 Total Time: ${entity.engine2TotalTime}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 2 Cycles: ${entity.engine2Cycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `APU Hobbs: ${entity.APUHobbs}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    //Tasks heading
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions("Tasks").h;
    verticalOffset += h + 8;
    doc.text("Tasks", 6, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(horizontalOffset + 2, verticalOffset + 3, pageWidth - 6, verticalOffset + 3, "S");

    verticalOffset += 2;
    tasks?.forEach(({ name, description, users }) => {
      verticalOffset += 2;
      doc.setFontSize(9);
      h = doc.getTextDimensions(name || "").h;
      verticalOffset += h + 2;
      doc.text(name || "", 6, verticalOffset);

      if (description) {
        h = doc.getTextDimensions(description).h;
        verticalOffset += h + 1;
        doc.text(description, 6, verticalOffset);
      }

      text = `Assigned: ${users?.items?.map(({ user }) => user.name)?.join(", ")}`;
      h = doc.getTextDimensions(text || "").h;
      verticalOffset += h + 1;
      const textLines = doc.splitTextToSize(text, pageWidth - 6);
      doc.text(textLines || "", 6, verticalOffset);

      if (verticalOffset > pageHeight - 30) {
        h = doc.getTextDimensions("...continued on next page" || "").h;
        verticalOffset += h + 3;
        doc.text("...continued on next page" || "", 6, verticalOffset);
        doc.addPage();
        verticalOffset = 0;

        //Tasks heading
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        h = doc.getTextDimensions("Tasks").h;
        verticalOffset += h + 4;
        doc.text("Tasks (continued)", 6, verticalOffset);

        //Line
        doc.setLineWidth(0.4);
        doc.line(horizontalOffset + 2, verticalOffset + 3, pageWidth - 6, verticalOffset + 3, "S");
      }
    });

    const appendPDF = async (toPDF: PDFDocument, arrayBuffer?: ArrayBuffer, pdf?: PDFDocument) => {
      // Loading array buffer to pdf-lib library
      const docPDF = arrayBuffer ? await PDFDocument.load(arrayBuffer) : pdf;
      const copiedPages = await toPDF.copyPages(
        docPDF,
        Array(docPDF.getPageCount())
          .fill(0)
          .map((it, i) => i),
      );
      // Copy pages of the generated PDF to the new PDF
      copiedPages.forEach((page) => toPDF.addPage(page));
    };

    // Convert pdf to PDFDocument from 'pdf-lib' library
    const newPDF = await PDFDocument.create();
    await appendPDF(newPDF, doc.output("arraybuffer"));

    if (allDocs) {
      const filteredTasks = tasks.filter((it) => ![TaskStatus.Declined, TaskStatus.Deleted].includes(it.status));
      for (const task of filteredTasks) {
        const inspectionForm = (
          await generateTaskInspectionForm(task, project, null, AttachmentType.TaskAndPartsAttachments, false)
        ).fullPDF;
        await appendPDF(newPDF, null, inspectionForm);
        const logBookSticker = (await generateLogBookSticker(task, project, null, false)).fullPDF;
        await appendPDF(newPDF, null, logBookSticker);
      }
    }

    // Save and download the new PDF
    const pdfBytes = await newPDF.save();
    downloadPDF(pdfBytes, `${project.name} Work Order Summary.pdf`);
  };
  return { generate };
}
