import React from "react";

import "@rmwc/data-table/styles";
import "@rmwc/circular-progress/styles";
import "@rmwc/theme/styles";

import { StatusBlock, StatusBlockTitle } from "./StatusBlock";
import { useAircraft } from "hooks/params/useAircraft";

export const WorkOrdersSummary = () => {
  const { entity: aircraft } = useAircraft();
  const activeProjects = aircraft?.activeProjects?.items || [];

  return (
    <StatusBlock>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <StatusBlockTitle>Active Work Orders</StatusBlockTitle>
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "8px auto 16px",
          }}
        >
          {activeProjects.slice(0, 3).map((it, i) => (
            <div key={i}>{it.name}</div>
          ))}
        </div>
      </div>
    </StatusBlock>
  );
};
