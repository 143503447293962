import React from "react";
import styled from "styled-components";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Scatter, ResponsiveContainer } from "recharts";
import { ProjectFields } from "types/admin/ProjectFields";
import Palette from "../../palette.json";
import { useHistory } from "react-router-dom";

const ChartWrapper = styled.div`
  cursor: pointer;

  .recharts-wrapper {
    margin: auto;
  }
`;

type Props = {
  projects: ProjectFields[];
};

const ProgressChart = ({ projects }: Props) => {
  const history = useHistory();

  const data = projects.map((it) => {
    const actual = Math.round((it.progress?.completedHours || 0) * 100) / 100;
    const est = Math.round((it.estimateManHrs || 0) * 100) / 100;
    return {
      name: it.name,
      "Actual Hours": actual,
      "Est. Hours": est,
      fill: actual < est ? Palette.MediumGrey : Palette.Red,
    };
  });

  return (
    <ChartWrapper onClick={() => history.push("/progress")}>
      <ResponsiveContainer width="100%" height={projects?.length * 75}>
        <ComposedChart
          layout="vertical"
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 40,
          }}
        >
          <CartesianGrid stroke="#e3e3ed" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" style={{ fontSize: "85%", lineHeight: "2em", fill: "#000" }} />
          <Tooltip labelFormatter={() => null} />
          <Bar dataKey="Actual Hours" barSize={20} />
          <Scatter dataKey="Est. Hours" shape={<Dot />} />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

const Dot = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 512" {...props} fill="#333" height={20} y={props.y - 6}>
    <path d="M32 512c-17.67 0-32-14.33-32-32V32c0-17.67 14.33-32 32-32s32 14.33 32 32v448C64 497.7 49.67 512 32 512z" />
  </svg>
);

export default ProgressChart;
