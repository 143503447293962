import React from "react";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../../palette.json";
import { PartType } from "types/admin/globalTypes";
import { Tooltip } from "@rmwc/tooltip";
import { partTypeToLabel } from "common";
import useTranslations from "hooks/useTranslations";

interface IconProps {
  type: PartType | null;
}

const iconMap = {
  [PartType.Assembly]: "view_in_ar",
  [PartType.Consumable]: "oil_barrel",
  [PartType.Expendable]: "swap_horiz",
  [PartType.Rotable]: "published_with_changes",
};

export const PartIcon: React.FC<IconProps> = ({ type }) => {
  const { isAviation } = useTranslations();
  if (!type) return null;
  const icon = iconMap[type];
  const label = partTypeToLabel(isAviation, type);
  return (
    <Tooltip content={label}>
      <Icon
        icon={{ icon, size: "xsmall" }}
        style={{ color: Palette.LightGrey, verticalAlign: "text-bottom", marginRight: 10 }}
      />
    </Tooltip>
  );
};
