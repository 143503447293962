import React from "react";
import { useHistory, useLocation } from "react-router";

import { PageControls, PageCounter } from "./Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

interface PagenatorControlsProps {
  pageCount: number;
}

export const PagenatorControls: React.FC<PagenatorControlsProps> = ({ pageCount }) => {
  const history = useHistory();

  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const pageCurrent = parseInt(search.get("p") || "1");

  const onNext = () => {
    history.push(location.pathname + "?p=" + (pageCurrent + 1));
  };
  const onPrev = () => {
    history.push(location.pathname + (pageCurrent === 2 ? "" : "?p=" + (pageCurrent - 1)));
  };

  return (
    <PageControls>
      <IconButton icon="navigate_before" disabled={pageCurrent === 1} onClick={onPrev} />

      <PageCounter>
        {pageCurrent} of {pageCount === 0 ? 1 : pageCount}
      </PageCounter>

      <IconButton icon="navigate_next" disabled={pageCurrent === pageCount} onClick={onNext} />
    </PageControls>
  );
};
