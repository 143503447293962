import gql from "graphql-tag";
import { ParentShiftFields } from "./shifts";

export const TenantSettingsFields = gql`
  ${ParentShiftFields}
  fragment TenantSettingsFields on TenantSettings {
    id
    name
    email
    phoneNumber
    address {
      street1
      street2
      city
      state
      country
      zip
    }
    terms
    estimateCount
    defaultPartMarkup
    defaultSubcontractorMarkup
    defaultLaborHourlyRate
    defaultBillingType
    enablePassDownReport
    defaultSigningType
    shifts {
      items {
        ...ParentShiftFields
      }
    }
  }
`;

export const getTenantSettingsQuery = gql`
  ${TenantSettingsFields}
  query GetTenantSettingsQuery {
    asAdmin {
      getTenantSettings {
        ...TenantSettingsFields
      }
    }
  }
`;

export const incrementTemplateEstimateCountMutation = gql`
  mutation IncrementTemplateEstimateCountMutation {
    asAdmin {
      incrementTemplateEstimateCount {
        estimateCount
      }
    }
  }
`;

export const setTenantSettingsMutation = gql`
  ${TenantSettingsFields}
  mutation SetTenantSettingsMutation($settings: TenantSettingsInput!) {
    asAdmin {
      setTenantSettings(settings: $settings) {
        ...TenantSettingsFields
      }
    }
  }
`;
