import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";

import { useFirebase } from "./hooks/useFirebase";
import posthog from "posthog-js";

import { Layout } from "./components/layout";
import { SignInLayout } from "./components/layout/signin";

import * as Pages from "./pages";
import useMultiTenant from "hooks/useMultiTenant";

export const Routes: React.FC = () => {
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect") || location.pathname;
  const { user, isLoading } = useFirebase();
  const { tenantInfo } = useMultiTenant();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  if (isLoading) return <></>;

  if (location.pathname.endsWith("/signature-capture")) {
    return <Route exact path="/people/:userId/signature-capture" component={Pages.SignatureCapturePage} />;
  }

  if (location.pathname.endsWith("/sign")) {
    return <Route exact path="/work-orders/:projectId/tasks/:taskId/sign" component={Pages.SignDocumentPage} />;
  }

  if (location.pathname.endsWith("/sign/reset")) {
    return (
      <SignInLayout>
        <Route exact path={`/work-orders/:projectId/tasks/:taskId/sign/reset`} component={Pages.SignInResetPage} />
      </SignInLayout>
    );
  }

  // Check for logged out condition, and redirect to signin page.
  if (!user && !["/reset", "/signin"].includes(location.pathname)) {
    return <Redirect to={`/signin?redirect=${redirect}`} />;
  }

  // Sign in layout formatted routes.
  if (["/reset", "/signin"].includes(location.pathname)) {
    // We're logged in, so lets redirect to home.
    if (user) {
      if (redirect) {
        let relativePath: string;
        try {
          const url = new URL(redirect);
          [relativePath] = url.pathname.match(/\/.*/) as string[];
        } catch (e) {
          console.error(e);
          relativePath = redirect;
        }
        if (!["/reset", "/signin"].includes(relativePath)) {
          return <Redirect to={relativePath || "/"} />;
        }
        return <Redirect to="/" />;
      } else {
        return <Redirect to="/" />;
      }
    }

    return (
      <SignInLayout>
        <Switch>
          <Route exact path={`/reset`} component={Pages.SignInResetPage} />
          <Route exact path={`/signin`} component={Pages.SignInPage} />
        </Switch>
      </SignInLayout>
    );
  }

  if (!tenantInfo.termsAndConditionsAgreement) {
    return <Pages.TermsAndConditionsAgreementPage />;
  }

  if (location.pathname === "/") return <Redirect to="/dashboard" />;

  // Global dashboard layout formatted routes.
  return (
    <Layout>
      <Switch>
        <Route path="/dashboard" component={Pages.DashboardPage} />
        <Route path="/morale" component={Pages.MoralePage} />
        <Route exact path="/trends" component={Pages.TrendsPage} />
        <Route path="/trends/:locationId" component={Pages.LocationPage} />
        <Route exact path="/progress" component={Pages.ProgressPage} />
        <Route path="/progress/:projectId" component={Pages.WorkOrderProgressPage} />
        <Route path="/settings" component={Pages.SettingsPage} />

        <Route exact path={["/clients", "/clients/new", "/clients/:clientId/edit_"]} component={Pages.ClientsPage} />
        <Route exact path="/clients/:clientId/entities/new" component={Pages.ClientViewPage} />
        <Route exact path="/clients/:clientId/entities/:aircraftId/edit/" component={Pages.ClientViewPage} />
        <Route
          path="/clients/:clientId/entities/:aircraftId/parts/:partId/parts/:subPartId/parts/:subSubPartId"
          component={Pages.AircraftSubSubPartDetailPage}
        />
        <Route
          path="/clients/:clientId/entities/:aircraftId/parts/:partId/parts/:subPartId"
          component={Pages.AircraftSubPartDetailPage}
        />
        <Route path="/clients/:clientId/entities/:aircraftId/parts/:partId" component={Pages.AircraftPartDetailPage} />
        <Route path="/clients/:clientId/entities/:aircraftId" component={Pages.AircraftViewPage} />
        <Route path="/clients/:clientId" component={Pages.ClientViewPage} />
        <Route exact path="/clients/:clientId/work-orders/:projectId/edit_" component={Pages.ClientViewPage} />

        <Route
          exact
          path={[
            "/work-orders/:projectId/tasks",
            "/work-orders/:projectId/tasks/new",
            "/work-orders/:projectId/tasks/estimate",
            "/work-orders/:projectId/tasks/:taskId/edit_",
            "/work-orders/:projectId/tasks/:taskId/people/add_",
          ]}
          component={Pages.ProjectViewPage}
        />

        <Route exact path={["/parts", "/parts/new"]} component={Pages.PartsPage} />
        <Route exact path="/parts/:partId/parts/new" component={Pages.PartDetailPage} />
        <Route exact path="/parts/:partId/parts/:subPartId/parts/new" component={Pages.SubPartDetailPage} />
        <Route path="/parts/:partId/parts/:subPartId/parts/:subSubPartId" component={Pages.SubSubPartDetailPage} />
        <Route path="/parts/:partId/parts/:subPartId" component={Pages.SubPartDetailPage} />
        <Route path="/parts/:partId" component={Pages.PartDetailPage} />

        <Route
          exact
          path="/work-orders/:projectId/tasks/:taskId/parts/new"
          component={Pages.ProjectTaskViewPageWrapper}
        />
        <Route
          exact
          path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts/new"
          component={Pages.TaskPartDetailPage}
        />
        <Route
          exact
          path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/new"
          component={Pages.TaskSubPartDetailPage}
        />
        <Route
          path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/:subSubPartId"
          component={Pages.TaskSubSubPartDetailPage}
        />
        <Route
          path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts/:subPartId"
          component={Pages.TaskSubPartDetailPage}
        />
        <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId" component={Pages.TaskPartDetailPage} />
        <Route path="/work-orders/:projectId/tasks/:taskId" component={Pages.ProjectTaskViewPageWrapper} />

        <Route
          exact
          path={["/work-orders", "/work-orders/new", "/work-orders/:projectId/edit_"]}
          component={Pages.ProjectsPage}
        />

        <Route path="/work-orders/:projectId" component={Pages.ProjectViewPage} />

        <Route
          exact
          path={[
            "/templates/:templateId/tasks",
            "/templates/:templateId/tasks/new",
            "/templates/:templateId/tasks/estimate",
            "/templates/:templateId/tasks/:taskId/people/add_",
          ]}
          component={Pages.TemplateViewPage}
        />

        {/* WORK ORDER TEMPLATES */}
        <Route exact path="/templates/:templateId/tasks/:taskId/parts/new" component={Pages.TemplateTaskViewPage} />
        <Route
          exact
          path="/templates/:templateId/tasks/:taskId/parts/:partId/parts/new"
          component={Pages.TemplatePartDetailPage}
        />
        <Route
          exact
          path="/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/new"
          component={Pages.TemplateSubPartDetailPage}
        />
        <Route
          path="/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/:subSubPartId"
          component={Pages.TemplateSubSubPartDetailPage}
        />
        <Route
          path="/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId"
          component={Pages.TemplateSubPartDetailPage}
        />
        <Route path="/templates/:templateId/tasks/:taskId/parts/:partId" component={Pages.TemplatePartDetailPage} />

        <Route exact path={["/templates/:templateId/tasks/:taskId/edit_"]} component={Pages.TemplateViewPage} />
        <Route path="/templates/:templateId/tasks/:taskId" component={Pages.TemplateTaskViewPage} />

        {/* TASK TEMPLATES */}
        <Route exact path="/templates/:templateId/parts/new" component={Pages.TemplateViewPage} />
        <Route exact path="/templates/:templateId/parts/:partId/parts/new" component={Pages.TemplatePartDetailPage} />
        <Route
          exact
          path="/templates/:templateId/parts/:partId/parts/:subPartId/parts/new"
          component={Pages.TemplateSubPartDetailPage}
        />
        <Route
          exact
          path="/templates/:templateId/parts/:partId/parts/:subPartId/parts/:subSubPartId/parts/new"
          component={Pages.TemplateSubSubPartDetailPage}
        />
        <Route
          path="/templates/:templateId/parts/:partId/parts/:subPartId/parts/:subSubPartId"
          component={Pages.TemplateSubSubPartDetailPage}
        />
        <Route
          path="/templates/:templateId/parts/:partId/parts/:subPartId"
          component={Pages.TemplateSubPartDetailPage}
        />
        <Route path="/templates/:templateId/parts/:partId" component={Pages.TemplatePartDetailPage} />

        <Route
          exact
          path={["/templates/:templateId/edit-task_", "/templates/:templateId/edit-project_"]}
          component={Pages.TemplatesPage}
        />
        <Route path="/templates/:templateId" component={Pages.TemplateViewPage} />

        {/* PAGES */}
        <Route exact path="/work-orders" component={Pages.DashboardPage} />
        <Route path="/schedule/:projectId?" component={Pages.SchedulePage} />
        <Route path="/timesheets" component={Pages.TimesheetsPage} />
        <Route path="/utilization" component={Pages.UtilizationPage} />
        <Route path="/locations/:locationId/details" component={Pages.LocationDetailsPage} />
        <Route path="/locations" component={Pages.LocationsPage} />
        <Route path="/templates" component={Pages.TemplatesPage} />
        <Route exact path="/people" component={Pages.PeoplePage} />
        <Route exact path="/people/new" component={Pages.PeoplePage} />
        <Route path="/people/:userId" component={Pages.PeopleDetailPage} />
        <Route path="/profile" component={Pages.ProfilePage} />
        <Route path="/bigscreen" component={Pages.BigScreenPage} />
        <Route path="/debug" component={Pages.DebugPage} />
        <Route path="/reports" component={Pages.ReportsPage} />
        <Route component={Pages.Error404} />
      </Switch>
    </Layout>
  );
};
