import { SignInLayout } from "components/layout/signin";
import useTemporaryUser from "hooks/useTemporaryUser";
import React, { useEffect } from "react";
import { SignInPage } from "./signin";
import { useParams } from "react-router-dom";
import useLogBookSticker from "hooks/useLogBookSticker";
import "./../../../../../../stylesheets/pdf-viewer.css";
import styled from "styled-components";
import useInspectionForm, { AttachmentType } from "hooks/useInspectionForm";
import { LoadingAnonCoverPage } from "components/LoadingAnonCoverPage";
import { FullScreenMessage } from "components/FullScreenMessage";
import { DialogQueue, createDialogQueue } from "@rmwc/dialog";
import { LogBookStickerPDFViewer } from "components/signatures/LogBookStickerPDFViewer";
import { InspectionFormPDFViewer } from "components/signatures/InspectionFormPDFViewer";
import { ProjectRole, SigningEntityType, SigningType } from "types/admin/globalTypes";
import { showErrorMessage } from "utilities/handleError";
import { SigningConfirmationScreen } from "components/signatures/SigningConfirmationScreen";
import { SigningSuccessScreen } from "components/signatures/SigningSuccessScreen";
import { SigningFailureScreen } from "components/signatures/SigningFailureScreen";
import { LinearProgress } from "@rmwc/linear-progress";
import dayjs from "dayjs";
import { Button } from "components/Button";
import { Icon } from "@rmwc/icon";
import { snackbar } from "hooks/useNotifications";

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

const ToolBarWrapper = styled.div<{ [props: string]: any }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  width: 100%;
`;

const PaginatedButtonsWrapper = styled.div`
  position: fixed;
  display: flex;
  width: calc(100% - 4rem);
  max-width: 600px;
  padding: 0 2rem;
  justify-content: space-between;
  bottom: 30px;
`;

enum VisibleContent {
  TaskInspectionForm,
  LogBookSticker,
  Confirmation,
  Success,
  Failure,
}

export const SignDocumentPage: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const { generate: generateSticker } = useLogBookSticker();
  const { generate: generateInspectionForm } = useInspectionForm();
  const {
    client,
    user: tempUser,
    validateSignature,
    getSigningUser,
    getLogBookStickerData,
    getInspectionData,
    listTaskSignatures,
    addSignature,
    addSignatures,
  } = useTemporaryUser();
  const { dialogs, prompt } = createDialogQueue();

  const [task, setTask] = React.useState<null | any>(null);
  const [signingUser, setSigningUser] = React.useState<any | null>(null);
  const [signaturesAndStamps, setSignaturesAndStamps] = React.useState<any[]>([]);
  const [todosInfo, setTodosInfo] = React.useState<any | null>(null);
  const [visibleContent, setVisibleContent] = React.useState<VisibleContent>(VisibleContent.TaskInspectionForm);
  const onFirstPage = visibleContent === VisibleContent.TaskInspectionForm;

  const [logBookStickerPDFString, setLogBookStickerPDFString] = React.useState<string | null>(null);
  const [inspectionFormPDFString, setInspectionFormPDFString] = React.useState<string | null>(null);
  const [logBookStickerPDFNumPages, setLogBookStickerPDFNumPages] = React.useState<number | null>(1);
  const [inspectionFormPDFNumPages, setInspectionFormPDFNumPages] = React.useState<number | null>(1);
  const [logBookStickerPDFPageNumber, setLogBookStickerPDFPageNumber] = React.useState<number>(1);
  const [inspectionFormPDFPageNumber, setInspectionFormPDFPageNumber] = React.useState<number>(1);

  const [loadingPage, setLoadingPage] = React.useState(true);
  const [unauthorized, setUnauthorized] = React.useState(false);
  const [notAssigned, setNotAssigned] = React.useState(false);
  const [signing, setSigning] = React.useState(false);
  const [signingNotRequired, setSigningNotRequired] = React.useState(false);

  const totalNumPages = logBookStickerPDFNumPages + inspectionFormPDFNumPages;
  const currentOverallPage = visibleContent === VisibleContent.TaskInspectionForm ? 1 : logBookStickerPDFPageNumber + 1;

  useEffect(() => {
    if (signing) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  }, [signing]);

  useEffect(() => {
    console.log(task);
  }, [task?.id]);

  useEffect(() => {
    if (!tempUser || !client) return;

    getSigningUser(taskId)
      .then((user) => {
        setSigningUser(user);

        listTaskSignatures(taskId).then((it) => setSignaturesAndStamps(it));

        getLogBookStickerData(taskId)
          .then(async (data) => {
            const { doc } = await generateSticker(data.task, data.project, data, false);
            const dataString = doc.output("datauristring");
            setLogBookStickerPDFString(dataString);
          })
          .catch((error) => {
            if (error.message.includes("Unauthorized")) {
              setUnauthorized(true);
            }

            if (error.message.includes("does not require signing")) {
              setSigningNotRequired(true);
            }
          })
          .finally(() => setLoadingPage(false));

        getInspectionData(taskId)
          .then(async (data) => {
            setTask(data.task);
            const { doc, todosInfo } = await generateInspectionForm(
              data.task,
              data.project,
              data,
              AttachmentType.None,
              false,
            );
            setTodosInfo(todosInfo);
            const dataString = doc.output("datauristring");
            setInspectionFormPDFString(dataString);
          })
          .catch((error) => {
            if (error.message.includes("Unauthorized")) {
              setUnauthorized(true);
            }

            if (error.message.includes("does not require signing")) {
              setSigningNotRequired(true);
            }
          })
          .finally(() => setLoadingPage(false));
      })
      .catch((error) => {
        if (error.message.includes("Unauthorized")) {
          setNotAssigned(true);
        }
        setLoadingPage(false);
      });
  }, [tempUser, client]);

  if (!tempUser) {
    return (
      <SignInLayout>
        <SignInPage />
      </SignInLayout>
    );
  }

  const onPrevious = () => {
    if (visibleContent === VisibleContent.LogBookSticker && logBookStickerPDFPageNumber === 1) {
      setVisibleContent(VisibleContent.TaskInspectionForm);
      return;
    }

    setLogBookStickerPDFPageNumber((prev) => prev - 1);
  };

  const onNext = () => {
    if (visibleContent === VisibleContent.TaskInspectionForm) {
      const stamps = signaturesAndStamps.filter((sig) => sig.entityType === SigningEntityType.TaskInspectionFormStamp);
      const inspectionSignatures = signaturesAndStamps.filter(
        (sig) => sig.entityType === SigningEntityType.TaskInspectionForm,
      );
      const mySignature = inspectionSignatures.find((sig) => sig.userId === tempUser.uid);

      try {
        switch (task.signingType) {
          case SigningType.Technician:
            if (inspectionSignatures.length === 0) throw new Error("Signing is required to view the next page");
            break;
          case SigningType.TechnicianAndTechnician:
            if (inspectionSignatures.length < 2) {
              if (!mySignature) {
                throw new Error("Signing is required to view the next page");
              }
            }
            break;
          case SigningType.TechnicianAndInspector:
            if (inspectionSignatures.length < 2) {
              if (!mySignature) {
                throw new Error("Signing is required to view the next page");
              }
            }
            break;
          case SigningType.RII:
            if (inspectionSignatures.length < 2) {
              if (!mySignature) {
                throw new Error("Signing is required to view the next page");
              }
            }
            if (signingUser.projectRole === ProjectRole.technicianInspector) {
              if (stamps.length !== todosInfo.coordinates.length) {
                throw new Error("Please stamp all todos to continue");
              }
            }
            break;
        }
      } catch (error) {
        snackbar.notify({ title: error.message, icon: "warning" });
        return;
      }

      setVisibleContent(VisibleContent.LogBookSticker);
      setLogBookStickerPDFPageNumber(1);
      return;
    }

    if (logBookStickerPDFPageNumber === logBookStickerPDFNumPages) {
      setVisibleContent(VisibleContent.Confirmation);
      return;
    }

    const logStickerSignatures = signaturesAndStamps.filter(
      (sig) => sig.entityType === SigningEntityType.TaskLogBookSticker,
    );
    const mySignature = logStickerSignatures.find((sig) => sig.userId === tempUser.uid);

    try {
      switch (task.signingType) {
        case SigningType.Technician:
          if (logStickerSignatures.length === 0) throw new Error("Signing is required to view the next page");
          break;
        case SigningType.TechnicianAndTechnician:
          if (logStickerSignatures.length < 2) {
            if (!mySignature) {
              throw new Error("Signing is required to view the next page");
            }
          }
          break;
        case SigningType.TechnicianAndInspector:
          if (logStickerSignatures.length < 2) {
            if (!mySignature) {
              throw new Error("Signing is required to view the next page");
            }
          }
          break;
        case SigningType.RII:
          if (logStickerSignatures.length < 2) {
            if (!mySignature) {
              throw new Error("Signing is required to view the next page");
            }
          }
          break;
      }
    } catch (error) {
      snackbar.notify({ title: error.message, icon: "warning" });
      return;
    }

    setLogBookStickerPDFPageNumber((prev) => Math.min(prev + 1, logBookStickerPDFNumPages));
  };

  const addEphemeralStamp = async (todoId: string) => {
    if (signingUser?.projectRole !== ProjectRole.technicianInspector) {
      snackbar.notify({ title: "You are not an inspector", icon: "warning" });
      return;
    }

    setSignaturesAndStamps((it) => [
      ...it,
      {
        signedDate: new Date().toISOString(),
        entityId: taskId,
        todoId: todoId,
        pageCount: -1,
        userId: tempUser.uid,
        userName: signingUser.name,
        entityType: SigningEntityType.TaskInspectionFormStamp,
      },
    ]);
  };

  const addEphemeralSignature = async () => {
    const pdfPageNumber =
      visibleContent === VisibleContent.TaskInspectionForm ? inspectionFormPDFPageNumber : logBookStickerPDFPageNumber;

    setSigning(true);
    try {
      if (visibleContent === VisibleContent.TaskInspectionForm) {
        const signature = await validateSignature(taskId, SigningEntityType.TaskInspectionForm);

        setSignaturesAndStamps((it) => [
          ...it,
          {
            signedDate: new Date().toISOString(),
            userId: tempUser.uid,
            userName: signingUser.name,
            entityId: taskId,
            entityType: SigningEntityType.TaskInspectionForm,
            signatureAttachment: signature,
          },
        ]);
      } else if (visibleContent === VisibleContent.LogBookSticker) {
        const signature = await validateSignature(taskId, SigningEntityType.TaskLogBookSticker);

        setSignaturesAndStamps((it) => [
          ...it,
          {
            signedDate: new Date().toISOString(),
            entityId: taskId,
            userId: tempUser.uid,
            userName: signingUser.name,
            pageCount: logBookStickerPDFPageNumber,
            entityType: SigningEntityType.TaskLogBookSticker,
            signatureAttachment: signature,
            pageNumber: pdfPageNumber,
          },
        ]);
      }
    } catch (error) {
      setSigning(false);
      showErrorMessage(error);
    } finally {
      setSigning(false);
    }
  };

  const submitAllSignatures = async () => {
    const pin = await prompt({
      title: "Enter your pin",
      acceptLabel: "Submit",
      inputProps: { type: "number" },
    });

    if (!pin) return;

    setLoadingPage(true);

    try {
      const results = await Promise.allSettled([
        addSignature(taskId, SigningEntityType.TaskInspectionForm, pin),
        addSignatures(taskId, SigningEntityType.TaskLogBookSticker, pin, logBookStickerPDFNumPages),
        addSignatures(taskId, SigningEntityType.TaskInspectionFormStamp, pin, todosInfo.coordinates.length),
      ]);

      let erroredOut = true;
      for (const result of results) {
        if (result.status === "fulfilled") {
          erroredOut = false;
          break;
        }
      }

      if (erroredOut) {
        throw new Error("Failed to submit signatures");
      }

      setVisibleContent(VisibleContent.Success);
    } catch (error) {
      setVisibleContent(VisibleContent.Failure);
      showErrorMessage(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const logBookSignaturesAndStamps = signaturesAndStamps
    .filter((sig) => sig.entityType === SigningEntityType.TaskLogBookSticker)
    .sort((a, b) => (dayjs(a.signedDate).isBefore(dayjs(b.signedDate)) ? -1 : 1));
  const inspectionFormSignatures = signaturesAndStamps
    .filter((sig) =>
      [SigningEntityType.TaskInspectionForm, SigningEntityType.TaskInspectionFormStamp].includes(sig.entityType),
    )
    .sort((a, b) => (dayjs(a.signedDate).isBefore(dayjs(b.signedDate)) ? -1 : 1));

  return (
    <>
      {loadingPage ? (
        <LoadingAnonCoverPage />
      ) : notAssigned ? (
        <FullScreenMessage
          icon="block"
          header="Unauthorized User"
          message="You are not assigned to this task."
          subMessage="Only a system administrator can grant you assign you to a task from the FoxTrot Administrator app."
        />
      ) : unauthorized ? (
        <FullScreenMessage
          icon="block"
          header="Unauthorized User"
          message="You do not have the proper signing permissions necessary for viewing this page."
          subMessage="Only a system administrator can grant you signing authority from the FoxTrot Administrator app."
        />
      ) : signingNotRequired ? (
        <FullScreenMessage
          icon="block"
          header="Signing is not required"
          message="This task does not require signing."
        />
      ) : (
        <PageWrapper>
          {visibleContent === VisibleContent.TaskInspectionForm ? (
            <>
              {inspectionFormPDFString && (
                <InspectionFormPDFViewer
                  style={{ pointerEvents: signing ? "none" : "all" }}
                  onSignatureClick={addEphemeralSignature}
                  onStampClick={addEphemeralStamp}
                  onLoadSuccess={(numPages) => setInspectionFormPDFNumPages(numPages)}
                  currentPage={inspectionFormPDFPageNumber}
                  todosInfo={todosInfo}
                  taskSigningType={task?.signingType}
                  signingUser={signingUser}
                  signaturesAndStamps={inspectionFormSignatures}
                  pdfString={inspectionFormPDFString}
                />
              )}
            </>
          ) : visibleContent === VisibleContent.LogBookSticker ? (
            <>
              {logBookStickerPDFString && (
                <LogBookStickerPDFViewer
                  style={{ pointerEvents: signing ? "none" : "all" }}
                  onSignatureClick={addEphemeralSignature}
                  onLoadSuccess={(numPages) => setLogBookStickerPDFNumPages(numPages)}
                  currentPage={logBookStickerPDFPageNumber}
                  taskSigningType={task?.signingType}
                  signatures={logBookSignaturesAndStamps}
                  pdfString={logBookStickerPDFString}
                />
              )}
            </>
          ) : visibleContent === VisibleContent.Confirmation ? (
            <SigningConfirmationScreen onConfirm={submitAllSignatures}>
              {" "}
              <Button
                style={{ alignSelf: "center" }}
                onClick={() => {
                  setVisibleContent(VisibleContent.TaskInspectionForm);
                }}
              >
                Back to Start
              </Button>
            </SigningConfirmationScreen>
          ) : visibleContent === VisibleContent.Success ? (
            <SigningSuccessScreen />
          ) : visibleContent === VisibleContent.Failure ? (
            <SigningFailureScreen onClick={() => setVisibleContent(VisibleContent.Confirmation)} />
          ) : (
            <div>Something went wrong</div>
          )}
        </PageWrapper>
      )}

      {logBookStickerPDFString &&
        inspectionFormPDFString &&
        (visibleContent === VisibleContent.TaskInspectionForm || visibleContent === VisibleContent.LogBookSticker) && (
          <>
            <ToolBarWrapper>
              <LinearProgress style={{ width: "12rem" }} progress={currentOverallPage / totalNumPages} buffer={1} />

              <PaginatedButtonsWrapper>
                <Button outlined={onFirstPage} raised={!onFirstPage} onClick={onPrevious}>
                  <Icon icon="chevron_left" />
                </Button>

                <Button raised onClick={onNext}>
                  <Icon icon="chevron_right" />
                </Button>
              </PaginatedButtonsWrapper>
            </ToolBarWrapper>
          </>
        )}

      <DialogQueue dialogs={dialogs} />
    </>
  );
};
