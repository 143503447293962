const dev = {
  firebase: {
    apiKey: "AIzaSyByyqjxC6ZEaOOicuTRTYFGU-BtBR_tfXw",
    authDomain: "foxtrot-dev-383b3.firebaseapp.com",
    databaseURL: "https://foxtrot-dev-383b3.firebaseio.com",
    projectId: "foxtrot-dev-383b3",
    storageBucket: "foxtrot-dev-383b3.appspot.com",
    messagingSenderId: "1010642923807",
    appId: "1:1010642923807:web:c048d22f803cf10728c02a",
    measurementId: "G-ZJYY9PPYM6",
  },
  aws: {
    API: {
      apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      endpoints: [
        {
          name: "FoxtrotAPI",
          endpoint: process.env.REACT_APP_LOOKUP_API_URL,
        },
      ],
    },
  },
  webPush: {
    publicKey: "BJCGvnYROAa3VGb-4A79wNNX-JR2OOGuUS5NSzuYXM9UV42Vmlk3Ed2SQNHJ1I18BOSYyXRR-Qy032nFJQ9Azfc",
  },
};

const prod = {
  firebase: {
    apiKey: "AIzaSyAEZBT9Nwb-sXJhLwQ4qF1ggcuhyqvRrJE",
    authDomain: "foxtrot-prod.firebaseapp.com",
    databaseURL: "https://foxtrot-prod.firebaseio.com",
    projectId: "foxtrot-prod",
    storageBucket: "foxtrot-prod.appspot.com",
    messagingSenderId: "898806114282",
    appId: "1:898806114282:web:a853c42c4c2abefd897493",
    measurementId: "G-QP0HDKGPRV",
  },
  aws: {
    API: {
      apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      endpoints: [
        {
          name: "FoxtrotAPI",
          endpoint: process.env.REACT_APP_LOOKUP_API_URL,
        },
      ],
    },
  },
  webPush: {
    publicKey: "",
  },
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev;
