import React, { useEffect, useState } from "react";

import { Controller } from "react-hook-form";

import { ProjectRole, SigningType, TaskStatus } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useTask } from "hooks/params/useTask";

import { sortByProperty } from "hooks/useSorted";

import { Button } from "../Button";
import { Label } from "../Label";
import { FormField } from "../CommonForm";
import { FormRow, Text, Select, Textarea, Date } from "components/Form";
import { TaskFormContentProps } from "./TaskFormDialog";

import "@rmwc/avatar/styles";

import "@rmwc/menu/styles";

import { useTemplate } from "hooks/params/useTemplate";
import { UserFormDialog } from "../users/UserFormDialog";
import useMultiTenant from "hooks/useMultiTenant";
import { AutocompleteInput } from "../AutocompleteInput";
import { useProject } from "hooks/params/useProject";
import { readableSigningType } from "common";

export const TaskFormInfoContent: React.FC<TaskFormContentProps> = ({ form, style, action, setActiveTabIndex }) => {
  const { users } = useAdminApi();
  const project = useProject();
  const { template, isTemplateTypeTask } = useTemplate();
  const isTemplate = !!template;

  const { task } = useTask();
  const { theme } = useMultiTenant();

  const [showUserFormDialog, setShowUserFormDialog] = useState(false);

  const { control, formState, setValue, watch } = form;
  const { errors } = formState;

  const statusOpts = [
    TaskStatus.Requested,
    TaskStatus.Upcoming,
    TaskStatus.InProgress,
    TaskStatus.OnHold,
    TaskStatus.Declined,
    TaskStatus.Completed,
  ].map((it) => {
    // use spaces instead of camel case
    const option = it.replace(/([A-Z])/g, " $1");
    return {
      value: it,
      label: option,
    };
  });

  const signingTypeOpts = [];
  for (const [_, v] of Object.entries(SigningType)) {
    signingTypeOpts.push({ label: readableSigningType(v), value: v });
  }

  const assignedTo = watch("assignedTo");
  const sorted = users
    .filter((it) => {
      const projectUser = project?.users?.items.find((u) => u.userId === it.id);
      const isProjectTechnician = [ProjectRole.technician, ProjectRole.technicianInspector].includes(projectUser?.role);
      return (
        isProjectTechnician ||
        (!projectUser && [ProjectRole.technician, ProjectRole.technicianInspector].includes(it.projectRole)) ||
        !it.projectRole
      );
    })
    .filter((it) => !!it.name)
    .filter((it) => !assignedTo.includes(it.id))
    .sort(sortByProperty("name"))
    .map((it) => ({
      value: it.id,
      label: it.name,
    }));

  useEffect(() => {
    const FIELDS = ["projectName", "parentName", "assignedTo", "name", "status", "scheduledDate", "dueDate"];

    if (FIELDS.filter((it) => it in errors).length > 0) setActiveTabIndex(0);
  }, [errors]);

  const onUserSelected = (userId: string) => {
    if (userId === "all") {
      const usersToAssign: string[] = [];
      const projectUsers = sorted.filter((it) => project?.users?.items.find((u) => u.userId === it.value));
      for (const user of projectUsers) {
        const userAlreadyAssigned = !!assignedTo.find((it) => it === user.value);
        if (!userAlreadyAssigned) {
          usersToAssign.push(user.value);
        }
      }
      setValue("assignedTo", [...assignedTo, ...usersToAssign]);
      return;
    }

    const user = sorted.filter((it) => it.value === userId)[0];
    if (!user) return;
    if (assignedTo.find((it) => it === user.value)) return;

    console.log("onUserSelected", [...assignedTo, user.value]);
    setValue("assignedTo", [...assignedTo, user.value], { shouldDirty: true });
  };

  return (
    <div {...{ style }}>
      {!isTemplate && (
        <FormRow col={task?.parentId !== task?.projectId ? 2 : 1}>
          <Text name="projectName" label="Project" disabled required />
          {task?.parentId !== task?.projectId && <Text name="parentName" label="Parent Task" disabled required />}
        </FormRow>
      )}

      <FormRow>
        <Text
          name="name"
          label={isTemplateTypeTask ? "Template Name" : "Task Name"}
          required
          disabled={!!task?.signingStatus}
        />
      </FormRow>

      <FormRow>
        <Select
          name="signingType"
          label="Signing Type"
          options={signingTypeOpts}
          placeholder="None"
          disabled={!!task?.signingStatus}
        />
      </FormRow>

      <FormRow>
        <Textarea name="description" label="Instructions" style={{ marginBottom: 0 }} />
      </FormRow>

      {!isTemplate && (
        <div>
          <FormRow>
            <FormField style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 4 }}>
                <Label htmlFor="assignedTo">Assigned Users ({assignedTo.length})</Label>
                <Button
                  type="button"
                  style={{ marginBottom: "0", padding: 0, height: "auto", fontSize: "12px" }}
                  data-test-id="task-add-new-user"
                  onClick={() => {
                    setShowUserFormDialog(true);
                  }}
                >
                  Add New
                </Button>
              </div>

              {showUserFormDialog && (
                <UserFormDialog
                  action="create"
                  renderToPortal
                  onGoBack={() => setShowUserFormDialog(false)}
                  onAddUser={(id: string) => setValue("assignedTo", [...assignedTo, id], { shouldDirty: true })}
                />
              )}
              <Controller
                name="assignedTo"
                {...{ control }}
                render={() => (
                  <AutocompleteInput
                    options={
                      [
                        ...(sorted.length !== 0
                          ? [
                              {
                                value: "all",
                                label: "All Work Order Technicians",
                              },
                            ]
                          : []),
                        ...sorted,
                      ] as any
                    }
                    select={(id) => onUserSelected(id)}
                    absolute={false}
                    shouldClearOnSelect
                  />
                )}
              />
            </FormField>
          </FormRow>

          {assignedTo.length > 0 && (
            <FormRow style={{ marginTop: -20 }}>
              <div style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}>
                {assignedTo.map((it, i) => (
                  <div style={{ display: "flex", alignItems: "center" }} key={i}>
                    <p
                      style={{ paddingRight: "1rem", color: theme.primary, fontSize: 14 }}
                      onClick={() =>
                        setValue(
                          "assignedTo",
                          assignedTo.filter((jt) => jt !== it),
                        )
                      }
                    >
                      {users.find((jt) => jt.id === it)?.name || ""}
                    </p>

                    {i !== assignedTo.length - 1 && (
                      <div
                        style={{
                          marginRight: "1rem",
                          backgroundColor: "black",
                          width: "5px",
                          height: "5px",
                          borderRadius: "9999px",
                        }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </FormRow>
          )}

          {action === "update" && (
            <FormRow>
              <Select name="status" options={statusOpts} disabled={!!task?.signingStatus} />
            </FormRow>
          )}
        </div>
      )}

      {!isTemplate && (
        <FormRow>
          <Date name="scheduledDate" label="Scheduled Date" required disabled={!!task?.signingStatus} />
          <Date name="dueDate" label="Due Date" required disabled={!!task?.signingStatus} />
        </FormRow>
      )}
    </div>
  );
};
