import React from "react";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import styled from "styled-components";
import useMultiTenant from "hooks/useMultiTenant";

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingAnonCoverPage: React.FC = () => {
  const { theme } = useMultiTenant();

  return (
    <PageWrapper>
      <Icon
        icon="autorenew"
        className="loadingIndicator"
        style={{ fontSize: "6rem", marginBottom: "3rem", color: theme.primary }}
      />
    </PageWrapper>
  );
};
