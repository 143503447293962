import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router";
import styled from "styled-components";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Palette from "../../../palette.json";

// hooks
import useLayout from "hooks/useLayout";
import { useUser } from "hooks/params/useUser";
import { useAdminApi } from "hooks/useAdminApi";
import { dialogQueue } from "hooks/useDialogQueue";

// components
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { UserFormDialog } from "components/users/UserFormDialog";
import SimpleCard from "components/SimpleCard";
import PulseReportsTable from "components/users/PulseReportsTable";
import { TableContainer, TableTitle, TableToolbarTitle } from "components/Table";
import LaborAssignmentsTable from "components/users/LaborAssignmentsTable";
import { TextInput } from "components/TextInput";
import { Button } from "components/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { TimesheetReviewDialog } from "components/timesheets/TimesheetReviewDialog";
import { useTimeSheets } from "hooks/useTimeSheets";
import { formatPulseReports } from "pages/morale";
import { snackbar } from "hooks/useNotifications";
import { useParams } from "react-router-dom";
import { twelveHourTimeFormat } from "common";
import { Icon } from "@rmwc/icon";
import { AddTenantShiftDialog } from "components/shifts/AddTenantShiftModal";
import { AvailableShiftFields } from "types/admin/AvailableShiftFields";
import { showErrorMessage } from "utilities/handleError";
import { ProjectRole } from "types/admin/globalTypes";
import useMultiTenant from "hooks/useMultiTenant";

dayjs.extend(isBetween);

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: calc(55% - 60px) 45%;
  grid-gap: 60px;

  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

const ShiftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * + * {
    margin-top: 0.5rem;
  }
`;

const TimeText = styled.span`
  color: gray;
`;

const RemoveButton = styled(Icon)<{ [props: string]: any }>`
  color: ${Palette.LightGrey};
  font-size: 18px;
  cursor: pointer;
`;

const InfoRow = styled.div`
  display: flex;
`;

const InfoColumn = styled.div`
  width: 50%;
`;

const InfoLabel = styled.p`
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.textHintOnBackground};
`;

const SignatureButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 16px;
  }
`;

const InfoDetail = styled.p``;

const InfoCard = () => {
  const user = useUser();
  const { userId } = useParams<{ userId: string }>();
  const { getUser, deleteShifts, sendSignatureSignUpEmail } = useAdminApi();
  const { theme } = useMultiTenant();
  const [showAddShiftDialog, setShowAddShiftDialog] = useState(false);

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, []);

  const sendSignatureEmail = async () => {
    const ConfirmBody = () => {
      return (
        <div>
          <p>Are you sure you want to send the signature signup email to {user?.name || user?.email || ""}?</p>
        </div>
      );
    };

    const hasConfirmed = await dialogQueue.confirm({
      title: `Send Signature Signup Email`,
      body: ConfirmBody(),
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    try {
      await sendSignatureSignUpEmail(userId);
      snackbar.notify({ title: "Email sent!" });
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onRemove = async (shift: AvailableShiftFields) => {
    const ConfirmBody = () => {
      return (
        <div>
          <p>Are you sure you want to unassign this user from {shift.parentShift.name}?</p>
        </div>
      );
    };

    const hasConfirmed = await dialogQueue.confirm({
      title: `Unassign ${user.firstName || ""} ${user.lastName || ""}`,
      body: ConfirmBody(),
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    try {
      await deleteShifts({ ids: [shift.id] });
    } catch (error) {
      snackbar.notify({ icon: "warning", title: error.message });
    }

    getUser(userId);
  };

  return (
    <>
      {showAddShiftDialog && <AddTenantShiftDialog setShowAddShiftDialog={setShowAddShiftDialog} />}

      <SimpleCard heading="Info">
        <div style={{ paddingBottom: 32, display: "flex", flexDirection: "column", gap: 30 }}>
          <InfoRow>
            <InfoColumn>
              <InfoLabel>Email Address</InfoLabel>
              <InfoDetail>
                <a href={`mailto:${user?.email}`} style={{ color: "#3e3f42" }}>
                  {user?.email}
                </a>
              </InfoDetail>
            </InfoColumn>
            <InfoColumn>
              <InfoLabel>Phone Number</InfoLabel>
              <InfoDetail>
                <a href={`tel:${user?.phoneNumber}`} style={{ color: "#3e3f42" }}>
                  {user?.phoneNumber}
                </a>
              </InfoDetail>
            </InfoColumn>
          </InfoRow>

          <InfoRow>
            <InfoColumn>
              <InfoLabel>Role</InfoLabel>
              <InfoDetail>{user?.userRole}</InfoDetail>
            </InfoColumn>

            <InfoColumn>
              <InfoLabel>Payroll Identifier</InfoLabel>
              <InfoDetail>{user?.payChex?.id}</InfoDetail>
            </InfoColumn>
          </InfoRow>

          <InfoRow>
            <InfoColumn>
              <InfoLabel>Pay Type</InfoLabel>
              <InfoDetail>{user?.type}</InfoDetail>
            </InfoColumn>

            <InfoColumn>
              <InfoLabel>Org Unit</InfoLabel>
              <InfoDetail>{user?.payChex?.orgUnit}</InfoDetail>
            </InfoColumn>
          </InfoRow>

          {(user?.hasSigningAuthority || user?.projectRole === ProjectRole.technicianInspector) && (
            <InfoRow>
              <InfoColumn>
                <InfoDetail>
                  <SignatureButtonWrapper>
                    <Button onClick={sendSignatureEmail} raised>
                      Send Signature Sign Up Email
                    </Button>
                    <Icon style={{ color: theme?.primary }} icon={!!user?.signature?.id ? "check_circle" : "error"} />
                  </SignatureButtonWrapper>
                </InfoDetail>
              </InfoColumn>
            </InfoRow>
          )}

          <InfoRow>
            <div style={{ width: "100%" }}>
              <InfoLabel>Available Shifts</InfoLabel>
              {user?.availableShifts?.items.length > 0 && (
                <div style={{ display: "flex", marginTop: "12px", alignItems: "center" }}>
                  <ShiftColumn>
                    {user.availableShifts.items
                      .filter((it) => it.parentShift)
                      .map((shift, i) => (
                        <div key={i}>{shift.parentShift.name}</div>
                      ))}{" "}
                  </ShiftColumn>

                  <ShiftColumn style={{ marginLeft: "48px" }}>
                    {user.availableShifts.items
                      .filter((it) => it.parentShift)
                      .map((shift, i) => (
                        <TimeText key={i}>
                          {twelveHourTimeFormat(shift.parentShift.startTime)} -{" "}
                          {twelveHourTimeFormat(shift.parentShift.endTime)}
                        </TimeText>
                      ))}{" "}
                  </ShiftColumn>

                  <ShiftColumn style={{ marginLeft: "72px" }}>
                    {user.availableShifts.items
                      .filter((it) => it.parentShift)
                      .map((shift, i) => (
                        <RemoveButton icon="close" key={i} onClick={() => onRemove(shift as AvailableShiftFields)} />
                      ))}{" "}
                  </ShiftColumn>
                </div>
              )}

              <Button
                icon="add_circle"
                type="button"
                onClick={() => setShowAddShiftDialog(true)}
                style={{ marginTop: "20px", marginLeft: "-8px" }}
              >
                Add Shift
              </Button>
            </div>
          </InfoRow>
        </div>
      </SimpleCard>
    </>
  );
};

const PulseReportsCard = () => {
  const user = useUser();
  const userId = useParams<{ userId: string }>().userId;
  const { getPulseReportsAndTimeSheets, users, tasks: allTasks, projects } = useAdminApi();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userId || !projects || !allTasks) return;
    const fromDate = dayjs().subtract(14, "day");
    const toDate = dayjs();

    getPulseReportsAndTimeSheets({
      criteria: {
        userId,
      },
      fromDt: fromDate.toISOString(),
      toDt: toDate.toISOString(),
      limit: 10,
      scanIndexForward: false,
      nextToken: null,
    }).then((them) => {
      const formatted = formatPulseReports(them, users, projects, allTasks);
      const myPulseReports = formatted.filter((r) => r.userId === userId);
      setReports(myPulseReports);
    });
  }, [userId, projects.length, allTasks.length]);

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>Morale Reports</TableToolbarTitle>
      </TableTitle>

      <PulseReportsTable isLoading={loading} reports={reports} shrinkToFit />
    </TableContainer>
  );
};

const LaborAssignmentsCard = ({ fromDate, toDate, setFromDate, setToDate }) => {
  const history = useHistory();
  const user = useUser();
  const { timesheets } = useTimeSheets();
  const { tasks } = useAdminApi();
  const [userTasks, setUserTasks] = useState([]);
  const [utilizationPercentage, setUtilizationPercentage] = useState(0);

  const filtered = timesheets.filter((it) => it.userId === user.id);

  useEffect(() => {
    setUserTasks(
      tasks
        .filter((task) => {
          const ids = task.users.items.map((it) => it.userId);
          return ids.includes(user?.id);
        })
        .filter((task) => {
          const scheduledDate = dayjs(task.scheduledDate);
          const dueDate = dayjs(task.dueDate);
          return scheduledDate.isBetween(fromDate, toDate) || dueDate.isBetween(fromDate, toDate);
        }),
    );
  }, [tasks.length, fromDate, toDate]);

  useEffect(() => {
    // compare taskId = 'InHangar'
    const inHangarMinutes = filtered.reduce((acc, curr) => {
      const taskId = curr.taskId;
      if (taskId === "InHangar") {
        return acc + curr.amountMins;
      }
      return acc;
    }, 0);

    const taskMinutes = filtered.reduce((acc, curr) => {
      const taskId = curr.taskId;
      if (taskId !== "InHangar") {
        return acc + curr.amountMins;
      }
      return acc;
    }, 0);

    setUtilizationPercentage(inHangarMinutes > 0 ? (taskMinutes / inHangarMinutes) * 100 : 0);
  }, [filtered, fromDate, toDate]);

  return (
    <TableContainer>
      <TableTitle>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TableToolbarTitle>Labor &amp; Assignments</TableToolbarTitle>

            <div style={{ marginTop: 18, display: "flex" }}>
              <TextInput
                type="date"
                value={fromDate.format("YYYY-MM-DD")}
                onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD").startOf("day"))}
                data-test-id="from-date-input"
              />
              &nbsp;⟶&nbsp;
              <TextInput
                type="date"
                value={toDate.format("YYYY-MM-DD")}
                onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD").endOf("day"))}
                data-test-id="to-date-input"
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <p style={{ color: Palette.MediumGrey, fontSize: 14, textAlign: "center", marginBottom: 10 }}>
                Utilization
              </p>
              <div style={{ position: "relative", width: 50 }}>
                <CircularProgressbar
                  text={`${utilizationPercentage.toFixed(0)}%`}
                  value={utilizationPercentage}
                  strokeWidth={11}
                  styles={buildStyles({
                    textSize: "23px",
                    textColor: "black",
                  })}
                />
              </div>
            </div>

            <div>
              <Button onClick={() => history.push(`/people/${user.id}/timesheets`)} raised>
                View Timesheets
              </Button>
            </div>
          </div>
        </div>
      </TableTitle>

      <LaborAssignmentsTable tasks={userTasks} userId={user.id} />
    </TableContainer>
  );
};

const PeopleDetailTimeSheetReviewDialog = () => {
  const user = useUser();
  const {
    timesheets,
    isLoading,
    onTimeSheetApprove,
    onTimeSheetsApprove,
    onTimeSheetDelete,
    setFromDate: setFromDateTimeSheets,
    setToDate: setToDateTimeSheets,
  } = useTimeSheets();

  const filteredTimeSheets = timesheets
    .filter((it) => it.userId === user.id)
    .filter((sheet) => sheet.taskId === "InHangar");

  return (
    <TimesheetReviewDialog
      {...{ timesheets: filteredTimeSheets, isLoading }}
      onTimeSheetApprove={onTimeSheetApprove}
      onTimeSheetsApprove={onTimeSheetsApprove}
      onDelete={onTimeSheetDelete}
    />
  );
};

export const PeopleDetailPage: React.FC = () => {
  const history = useHistory();
  const { setFromDate: setFromDateTimeSheets, setToDate: setToDateTimeSheets } = useTimeSheets();
  const { setBreadCrumbs } = useLayout();
  const user = useUser();
  const [fromDate, setFromDate] = useState(dayjs().subtract(7, "day"));
  const [toDate, setToDate] = useState(dayjs());

  const fullName = !!user ? `${user.firstName} ${user.lastName}` : "";

  useEffect(() => {
    if (fromDate) {
      setFromDateTimeSheets(fromDate);
    }
    if (toDate) {
      setToDateTimeSheets(toDate);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      { label: "People", path: "/people" },
      { label: fullName, path: `/people/${user?.id}` },
    ]);
  }, [setBreadCrumbs, fullName]);

  return (
    <>
      <Route exact path="/people/:userId/edit">
        <UserFormDialog action="update" />
      </Route>
      <Route exact path="/people/:userId/timesheets">
        <PeopleDetailTimeSheetReviewDialog />
      </Route>

      <PageHeader title={fullName} editable onEditClick={() => history.push(`/people/${user?.id}/edit`)} />

      <PageMain>
        <PageContent>
          <CardsGrid>
            <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
              <InfoCard />
              <PulseReportsCard />
            </div>

            <div style={{ marginTop: 34 }}>
              <LaborAssignmentsCard
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </div>
          </CardsGrid>
        </PageContent>
      </PageMain>
    </>
  );
};
