import React from "react";
import styled from "styled-components";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import dayjs from "dayjs";

const ChartWrapper = styled.div`
  width: 60%;
  position: absolute;
  top: 0;
  right: 64px;
  opacity: 0.9;
`;

type Props = {
  data: {
    date: string;
    rating: string;
    [x: string]: any;
  }[];
};

type ReportsByDate = {
  [x: string]: string[];
};

const MoraleChart = ({ data }: Props) => {
  const dates: ReportsByDate = {};

  data.forEach((it) => {
    const date = dayjs(it.date).format("YYYY-MM-DD");
    if (!dates[date]) dates[date] = [];
    dates[date].push(it.rating);
  });

  const formattedData = Object.entries(dates).map(([date, ratings]) => {
    const sum = ratings.reduce((acc, rating) => {
      if (rating === "Positive") return (acc += 100);
      if (rating === "Neutral") return (acc += 66);
      if (rating === "Negative") return (acc += 33);
    }, 0);
    return {
      date: dayjs(date).format("M/D"),
      average: Math.round(sum / ratings.length),
    };
  });

  if (formattedData.length === 0) return null;

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height={150}>
        <LineChart
          data={formattedData}
          margin={{
            top: 20,
            bottom: 20,
            right: 5,
          }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="average" stroke="#FF521B" strokeWidth={3} />
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default MoraleChart;
