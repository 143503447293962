import { useParams } from "react-router";

import { TaskFields } from "types/admin/TaskFields";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  projectId: string;
  taskId: string;
}

interface UseTaskProps {
  task?: TaskFields;
  taskId?: string;
  parentId?: string;
  getTask?: (id: string) => Promise<TaskFields>;
}

export const useTask = (): UseTaskProps => {
  const { taskId, projectId } = useParams<ParamsT>();

  const { tasks, getTask } = useAdminApi();
  // return tasks.find((it: TaskFields) => it.parentId === projectId && it.id === taskId);
  return {
    task: tasks.find((it: TaskFields) => it.id === taskId) as TaskFields,
    taskId,
    getTask,
    parentId: projectId,
  };
};
