import React, { useEffect, useMemo } from "react";
import { Dialog } from "../Dialog";
import "@rmwc/theme/styles";
import { DialogContent } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import Palette from "../../palette.json";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { TaskStatusLabel } from "components/StatusLabel";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import { PROJECT_STATUS_BACKGROUNDS } from "components/StatusLabel";
import { MiniProgress } from "components/MiniProgress";
import { useTask } from "hooks/params/useTask";
import { useProject } from "hooks/params/useProject";
import { useSettings } from "hooks/useSettings";
import { getAvatarColor, twelveHourTimeFormat } from "../../common/index";
import { ShiftFields } from "types/admin/ShiftFields";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { showErrorMessage } from "../../utilities/handleError";
import { Avatar, AvatarGroup } from "@rmwc/avatar";
import { DisplayFlex } from "components/Utilities";
import { PlainButton } from "components/Button";
import { toast } from "react-hot-toast";

const Header = styled.div`
  > div {
    padding: 12px;
  }
`;

const Headings = styled.div`
  margin: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Status = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: ${Palette.DarkGrey};
`;

const Dates = styled.h4`
  margin-top: 8px;
  line-height: 1.5;
  font-size: 16px;
  color: ${Palette.LightGrey};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${Palette.LightGrey};
`;

const ShiftWrapper = styled.div`
  padding: 6px 12px;
  border-radius: 10px;
  background-color: ${Palette.UltraLightGrey};
  margin-bottom: 12px;
`;

const ShiftHeader = styled.h3`
  display: flex;
  margin-bottom: 8px;

  > * + * {
    margin-left: 16px;
  }
`;

const ShiftName = styled.span`
  color: black;
  font-weight: 500;
`;

type Props = {
  date: dayjs.Dayjs;
  userShifts: any[];
  onClose: () => void;
  onAssign?: (shift: UserShiftFields) => void;
};

export const TaskScheduleShiftsForm = ({ onClose, userShifts, date, onAssign }: Props) => {
  const { projectId, taskId } = useParams<{ projectId: string; taskId: string }>();
  const project = useProject();
  const { users, addUserShift } = useAdminApi();
  const { task } = useTask();
  const { settings } = useSettings();
  const { progress } = useLiveTimeSheets(projectId, taskId);

  const taskUsers = task?.users?.items.map((it) => users.find((u) => u.id === it.userId)) || [];

  // get list of users with shifts today

  const assignUserToShift = async (userId: string, shift: ShiftFields) => {
    const fromHours = Number(shift.startTime.split(":")[0]);
    const fromMinutes = Number(shift.startTime.split(":")[1]);
    const toHours = Number(shift.endTime.split(":")[0]);
    const toMinutes = Number(shift.endTime.split(":")[1]);
    const fromDt = date.set("hour", fromHours).set("minute", fromMinutes).set("second", 0);
    const toDt = date.set("hour", toHours).set("minute", toMinutes).set("second", 0);

    try {
      const newUserShift = await addUserShift({
        input: {
          userId,
          taskId,
          projectId,
          fromDt: fromDt.toISOString(),
          toDt: toDt.toISOString(),
        },
      });
      toast.success("Shift assigned!");
      if (onAssign) {
        onAssign(newUserShift);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <Header>
        <Headings>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              <Status>
                <TaskStatusLabel status={task.status}>{task.status}</TaskStatusLabel>
                <MiniProgress progress={progress} color={PROJECT_STATUS_BACKGROUNDS[project.status]} />
                {parseInt((progress * 100).toString())}%
              </Status>
              <Title>Add Shift for {task?.name || project.name}</Title>
              <Dates>{date.format("MMMM D")}</Dates>
            </div>
          </div>
        </Headings>
        <CloseButton type="button">
          <Icon icon="close" onClick={onClose} />
        </CloseButton>
      </Header>
      <DialogContent style={{ minWidth: "30vw", padding: "10px 30px" }}>
        <h2>Schedule Shifts</h2>
        <div style={{ marginTop: "8px" }}>
          {settings.shifts?.items
            .filter((it) => taskUsers.find((u) => u.availableShifts?.items.find((s) => s.parentShiftId === it.id)))
            .sort((a, b) => a.startTime.localeCompare(b.startTime))
            .map((shift, i) => (
              <ShiftWrapper key={i}>
                <ShiftHeader>
                  <ShiftName>{shift.name}</ShiftName>
                  <div style={{ fontSize: "14px" }}>
                    {twelveHourTimeFormat(shift.startTime)} - {twelveHourTimeFormat(shift.endTime)}
                  </div>
                </ShiftHeader>

                <div>
                  <AvatarGroup dense>
                    {taskUsers.map((user, i) => {
                      const couldWork = user?.availableShifts.items.find((it) => it.parentShiftId === shift.id);
                      const overlappingShifts = userShifts.find((it) => {
                        const isUser = it.extendedProps.userId === user.id;
                        if (!isUser) {
                          return false;
                        }

                        const userShiftStart = dayjs(it.start).format("HH:mm");
                        const userShiftEnd = dayjs(it.end).format("HH:mm");

                        if (
                          (userShiftStart >= shift.startTime && userShiftStart < shift.endTime) ||
                          (userShiftEnd > shift.startTime && userShiftEnd <= shift.endTime) ||
                          (shift.startTime >= userShiftStart && shift.startTime < userShiftEnd) ||
                          (shift.endTime > userShiftStart && shift.endTime <= userShiftEnd)
                        ) {
                          return true;
                        }

                        return false;
                      });
                      const isAvailable = couldWork && !overlappingShifts;

                      return (
                        <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <PlainButton
                            disabled={!isAvailable}
                            onClick={() => assignUserToShift(user.id, shift as ShiftFields)}
                          >
                            <DisplayFlex alignItems="center">
                              <Avatar
                                key={user.id}
                                style={{
                                  color: "white",
                                  backgroundColor: isAvailable ? getAvatarColor(user.id) : Palette.LightGrey,
                                }}
                                name={user.name}
                              />
                              <div style={{ marginLeft: 8, color: isAvailable ? "black" : Palette.LightGrey }}>
                                {user.name}
                              </div>
                            </DisplayFlex>
                          </PlainButton>
                        </div>
                      );
                    })}
                  </AvatarGroup>
                </div>
              </ShiftWrapper>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
