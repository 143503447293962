import { snackbar } from "hooks/useNotifications";
import get from "lodash/get";

export const showErrorMessage = (error: any) => {
  const message = get(
    error,
    "graphQLErrors[0].message",
    get(error, "message", "An unexpected error ocurred. Please try again."),
  );
  snackbar.notify({ title: message, icon: "error" });
};
