import styled from "styled-components";

export const TextArea = styled.textarea`
  margin: 0 0 24px 0;
  padding: 8px 16px;

  height: 6rem;

  border: 1px solid #e2e5ed;
  border-radius: 4px;

  font-size: 14px;
  font-weight: normal;
  color: #3e3f42;

  outline: none;
`;
