import React, { useState } from "react";
import { useHistory } from "react-router";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import { AttachmentFields } from "types/admin/AttachmentFields";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

import useSorted, { sortByProperty } from "hooks/useSorted";
import useFiltered from "hooks/useFiltered";

import { Button } from "../Button";
import { SearchInput } from "../TextInput";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";
import { useTemplate } from "hooks/params/useTemplate";
import { TaskStatus } from "types/admin/globalTypes";
import { usePart } from "hooks/params/usePart";
import { useAircraft } from "hooks/params/useAircraft";

const iconFromMimeType = (mimeType: string): string => {
  if (mimeType.toLowerCase().startsWith("image/")) {
    return "image";
  } else if (mimeType.toLowerCase().startsWith("video/")) {
    return "video";
  }

  return "description";
};

export interface AttachmentListPropsT {
  items: AttachmentFields[];
  type: "parentPart" | "task" | "template" | "part" | "aircraft";
}

export const AttachmentList: React.FC<AttachmentListPropsT> = ({ items, type }) => {
  const history = useHistory();

  const { deleteAttachment } = useAdminApi();

  const { currentPart, part, subPart, subSubPart } = usePart();
  const project = useProject();
  const { entity: aircraft } = useAircraft();
  const { task: parentTask } = useTask();
  const isTaskDeleted = parentTask?.status === TaskStatus.Deleted;
  const { template, isTemplateTypeTask } = useTemplate();

  let resourceUrl = "";

  if (type === "task") {
    resourceUrl = `/work-orders/${project?.id}/tasks/${parentTask?.id}`;
  } else if (type === "part") {
    resourceUrl = "";
  } else if (type === "template") {
    resourceUrl = isTemplateTypeTask
      ? `/templates/${template?.id}`
      : `/templates/${template?.id}/tasks/${parentTask?.id}`;
  }

  if (subSubPart) {
    resourceUrl = `${resourceUrl}/parts/${part!.id}/parts/${subPart!.id}/parts/${subSubPart.id}`;
  } else if (subPart) {
    resourceUrl = `${resourceUrl}/parts/${part!.id}/parts/${subPart.id}`;
  } else if (part) {
    resourceUrl = `${resourceUrl}/parts/${part.id}`;
  }

  const [search, setSearch] = useState<string>("");

  const sorted = useSorted<AttachmentFields>(items || [], sortByProperty("name"));
  const filtered = useFiltered(sorted, (it) => !!it?.fileName?.toLowerCase().includes(search.toLowerCase()));

  const onDelete = async (item: AttachmentFields) => {
    if (type === "parentPart") return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete attachment: ${item.fileName}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    await deleteAttachment(item, parentTask?.id, template?.id);
    snackbar.notify({ title: "Attachment removed successfully!" });
  };

  const openAddAttachmentDialog = () => {
    history.push(`${resourceUrl}/attachments/new`);
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {type === "parentPart" ? "Parent Part " : ""} Attachments <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search by name or type"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
        {!isTaskDeleted && !aircraft && type !== "parentPart" && (
          <Button raised onClick={openAddAttachmentDialog}>
            Add Attachment
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["", "Name", ""]} />

          <DataTableBody>
            {filtered.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Attachments -
                </DataTableCell>
              </DataTableRow>
            )}

            {filtered.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell style={{ width: "4rem" }}>
                  <Icon style={{ color: Palette.LightGrey }} icon={iconFromMimeType(it.mimeType)} />
                </DataTableCell>

                <DataTableCell>
                  <a href={it.file.url || undefined} target="_blank" rel="noopener noreferrer">
                    {it.fileName}
                  </a>
                </DataTableCell>
                <DataTableCell>
                  {!isTaskDeleted && type !== "parentPart" && (
                    <DataCellTools>
                      <IconButton icon="delete" onClick={() => onDelete(it)} />
                    </DataCellTools>
                  )}
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
