import * as React from "react";
import { Button } from "components/Button";
import styled from "styled-components";
import { DateRangePicker, Period } from "components/DateRangePicker";
import { MenuSurfaceAnchor, MenuSurface } from "@rmwc/menu";
import dayjs from "dayjs";

import "@rmwc/menu/styles";

const Container = styled.div`
  display: inline-block;
  margin-left: 30px;
  vertical-align: text-top;
`;

const options = Object.values(Period);

type Props = {
  value: Period | { startDate: Date; endDate: Date };
  onChange: (value: Period | { startDate: Date; endDate: Date }) => void;
};

export const PeriodSelect = ({ value, onChange }: Props) => {
  const [open, setOpen] = React.useState(false);
  const period = value as any;

  return (
    <Container>
      <MenuSurfaceAnchor>
        <MenuSurface open={open} onClose={() => setOpen(false)} renderToPortal>
          <DateRangePicker onChange={onChange} close={() => setOpen(false)} />
        </MenuSurface>

        <Button outlined trailingIcon="unfold_more" onClick={() => setOpen((o) => !o)}>
          {options.includes(period)
            ? period
            : `${dayjs(period.startDate).format("MMM	D")} - ${dayjs(period.endDate).format("MMM D")}`}
        </Button>
      </MenuSurfaceAnchor>
    </Container>
  );
};
