import React, { createContext, useContext, useEffect, useState } from "react";

import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

import {
  getTenantByPrefixQuery,
  getUploadUrlQuery,
  getUserEmailQuery,
  getUserSecretLinkKeyQuery,
  resetUserPasswordQuery,
  updateUserSignatureMutation,
} from "../queries/lookup/lookup";
import { GetTenantByPrefix } from "types/lookup/GetTenantByPrefix";
import { TenantInfo } from "types/lookup/TenantInfo";

import styled from "styled-components";
import { ResetUserPassword } from "types/lookup/ResetUserPassword";

import { TenantError } from "components/TenantError";
import i18next from "../i18n";
import { CreateAttachmentRequest, IndustryType } from "types/lookup/globalTypes";
import { GetUserSecretLinkKey } from "types/lookup/GetUserSecretLinkKey";
import { GetUploadUrl } from "types/lookup/GetUploadUrl";
import { UpdateUserSignature, UpdateUserSignatureVariables } from "types/lookup/UpdateUserSignature";
import { GetUserEmail } from "types/lookup/GetUserEmail";

interface ThemeOptionsT {
  [key: string]: string;

  primary: string;
  secondary: string;
  background: string;
}

interface MultiTenantContextT {
  tenantId?: string | null;
  tenantInfo?: TenantInfo | null;
  setTenantInfo?: React.Dispatch<any>;
  theme: ThemeOptionsT;
  getUploadUrl?(mimeType: string): Promise<any | undefined>;
  getUserEmail?(userId: string): Promise<string | undefined>;
  resetUserPassword?(email: string): Promise<boolean | undefined>;
  getUserSecretLinkKey?(userId: string): Promise<string | undefined>;
  updateUserSignature?(
    userId: string,
    key: string,
    pin: string,
    attachment: CreateAttachmentRequest,
  ): Promise<any | undefined>;
}

const LoadingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background: #042459;
`;

export const MultiTenantContext = createContext<MultiTenantContextT>({
  theme: {
    primary: "#1665d8",
    secondary: "#00a1ff",
    background: "#042459",
  },
});

// XXX - Store tenant information into LocalStorage to decrease page loading time.
export const MultiTenantProvider: React.FC<{ prefix: string }> = ({ prefix, children }) => {
  const [tenantId, setTenantId] = useState<string | undefined | null>(undefined);
  const [tenantInfo, setTenantInfo] = useState<TenantInfo | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [client, setClient] = useState<AWSAppSyncClient<any> | null>(null);

  const [theme, setTheme] = useState<ThemeOptionsT>({
    primary: "#1665d8",
    secondary: "#00a1ff",
    background: "#042459",
    textPrimaryOnBackground: "#9ea0a5",
    textSecondaryOnBackground: "#9ea0a5",
    textHintOnBackground: "#9ea0a5",
    error: "#e6492d",
  });

  const getUploadUrl = async (mimeType: string) => {
    if (!tenantId) return;

    const response = await client?.query<GetUploadUrl>({
      query: getUploadUrlQuery,
      variables: { mimeType, tenantId },
      fetchPolicy: "no-cache",
    });
    return response?.data.asAnon.getUploadUrl;
  };

  const getUserEmail = async (userId: string) => {
    if (!tenantId) return;

    const response = await client?.query<GetUserEmail>({
      query: getUserEmailQuery,
      variables: { tenantId, userId },
      fetchPolicy: "no-cache",
    });
    return response.data?.asAnon?.getUserEmail || undefined;
  };

  const getUserSecretLinkKey = async (userId: string) => {
    if (!tenantId) return;

    const response = await client?.query<GetUserSecretLinkKey>({
      query: getUserSecretLinkKeyQuery,
      variables: { tenantId, userId },
      fetchPolicy: "no-cache",
    });
    return response.data?.asAnon?.getUserSecretLinkKey || undefined;
  };

  const updateUserSignature = async (userId: string, key: string, pin: string, attachment: CreateAttachmentRequest) => {
    if (!tenantId) return;

    const response = await client?.mutate<UpdateUserSignature>({
      mutation: updateUserSignatureMutation,
      variables: { tenantId, userId, key, attachment, pin },
    });
    return response.data?.asAnon?.updateUserSignature || undefined;
  };

  const resetUserPassword = async (email: string) => {
    const response = await client?.query<ResetUserPassword>({
      query: resetUserPasswordQuery,
      variables: {
        email,
        tenantId,
      },
      fetchPolicy: "no-cache",
    });
    return response?.data.asAnon?.resetUserPassword.success;
  };

  const getTenant = async () => {
    if (!client) return;

    const result = await client.query<GetTenantByPrefix>({
      query: getTenantByPrefixQuery,
      variables: { prefix },
      fetchPolicy: "no-cache",
    });

    const ret = result?.data?.asAnon?.getTenantByPrefix;

    if (!ret) {
      console.warn("Failed to get multi-tenant info.");
      setTenantId(null);
      return;
    }

    const { id, theme } = ret;

    setTenantId(id || null);
    setTenantInfo(ret);
    console.info("TENANT_INFO:", ret);

    if (theme?.primaryColor && theme.secondaryColor) {
      setTheme({
        primary: theme.primaryColor,
        secondary: theme.secondaryColor,
        background: theme.secondaryColor,
        textPrimaryOnBackground: "#9ea0a5",
        textSecondaryOnBackground: "#9ea0a5",
        textHintOnBackground: "#9ea0a5",
        error: "#e6492d",
      });
    }
  };

  useEffect(() => {
    if (tenantId !== undefined) return;

    const appSyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_LOOKUP_API_URL,
      region: "us-east-1",
      auth: {
        type: AUTH_TYPE.API_KEY, // or type: awsconfig.aws_appsync_authenticationType,
        apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      },
      offlineConfig: {
        keyPrefix: "lookupApiPersist",
      },
      disableOffline: false,
    });
    setClient(appSyncClient);
  }, [tenantId, prefix]);

  useEffect(() => {
    if (!client) return;

    (async function () {
      try {
        await getTenant();
      } catch (error) {
        console.log("Failed to get tenant info. Gonna try again.", error);
        setHasError(true);
      }
    })();
  }, [client]);

  useEffect(() => {
    // todo - need to check if industryType is Aviation or Janitorial
    if (tenantInfo?.industryType === IndustryType.Janitorial) {
      i18next.changeLanguage("en-janitorial");
    } else {
      i18next.changeLanguage("en-aviation");
    }
  }, [tenantInfo]);

  if (tenantId === undefined) return <LoadingScreen />;
  if (tenantId === null) return <TenantError isInvalid />;
  if (hasError) return <TenantError />;

  return (
    <MultiTenantContext.Provider
      value={{
        tenantId,
        tenantInfo,
        setTenantInfo,
        theme,
        getUploadUrl,
        getUserEmail,
        resetUserPassword,
        getUserSecretLinkKey,
        updateUserSignature,
      }}
    >
      {children}
    </MultiTenantContext.Provider>
  );
};

export default (): MultiTenantContextT => useContext(MultiTenantContext);
