import React, { useState } from "react";
import { UseFormMethods, useFieldArray } from "react-hook-form";
import { MarkupType, PartType } from "../../types/admin/globalTypes";
import { TaskFormContentProps, TaskFormValuesT } from "./TaskFormDialog";
import { Button } from "../Button";
import { MultiItemSection, SectionTitle } from "../CommonForm";
import { Number, Hidden, FormRow, Toggle, Select } from "../Form";
import "@rmwc/switch/styles";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import { AutocompleteInput } from "../AutocompleteInput";
import { useAdminApi } from "../../hooks/useAdminApi";
import { MARKUP_TYPE_OPTIONS } from "../../common";
import { PartIcon } from "components/parts/PartIcon";
import { useTask } from "hooks/params/useTask";

export const PartsFormFields: React.FC<{
  title: string;
  form: UseFormMethods<TaskFormValuesT>;
}> = ({ title, form }) => {
  const { task } = useTask();

  const { control, watch } = form;
  const { fields, append, remove } = useFieldArray({ name: "parts", control });
  const [displayedRows, setDisplayedRows] = useState<any[]>([]);
  const [justAdded, setJustAdded] = useState(false);

  const values = watch("parts");
  const selectedPartIds = values?.map((part) => part.parentPartId) || [];
  const { parts: allParts } = useAdminApi();

  let sorted: { value: string; label: string }[] = [];

  sorted = allParts
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((part) => !selectedPartIds.includes(part.id))
    .map((it) => ({
      value: it.id,
      label: `${it.number ? `${it.number} - ` : ""}${it.name} (${
        it.type === PartType.Consumable ? it.quantity || 0 : it.type
      })`,
    }));

  const markupTypeOpts: { label: string; value: string }[] = [];

  for (const [k, v] of Object.entries(MarkupType)) {
    markupTypeOpts.push({ label: k[0].toUpperCase() + k.slice(1), value: v });
  }

  const clickedCollapsibleList = (index: number) => {
    setJustAdded(false);
    if (displayedRows.includes(index)) {
      setDisplayedRows(displayedRows.filter((i) => i !== index));
    } else {
      setDisplayedRows([...displayedRows, index]);
    }
  };

  const collapseAll = () => {
    setDisplayedRows([]);
  };

  const onRemoveClick = (index: number) => {
    remove(index);
    collapseAll();
    setJustAdded(false);
  };

  const validateInt = (label: string, value: number) => {
    return value >= 0 || `${label} cannot be negative`;
  };

  const onPartSelected = (id: string) => {
    const part = allParts.find((it) => it.id === id);
    if (!part) return;
    const { name, number, quantity, cost, type, leadTimeDays, markupType, markup, isBillable, ...rest } = part;
    append({ parentPartId: id, name, number, quantity, cost, type, leadTimeDays, markupType, markup, isBillable });
    setJustAdded(true);
    collapseAll();
    //Hack to fix defaultValue not being applied until second render for toggle field
    setTimeout(() => form.setValue(`parts.${values.length}.isBillable`, isBillable), 100);
  };

  return (
    <MultiItemSection>
      <SectionTitle>{title}</SectionTitle>

      <AutocompleteInput
        absolute
        disabled={!!task?.signingStatus}
        dropdownMaxHeight={"200px"}
        placeholder={task?.signingStatus ? "Searching is disabled" : "Search for a part in the inventory..."}
        testId="part-search"
        options={[...sorted] as any}
        select={(id) => onPartSelected(id)}
        shouldClearOnSelect
      />

      <div style={{ marginBottom: 24, minHeight: 200 }}>
        {fields.map((field, i) => {
          const part = values[i];
          const type = part?.type;
          const heading = (
            <>
              <PartIcon type={part?.type} /> {part?.name} - {part?.number}
            </>
          );
          return (
            <div key={field.id}>
              <CollapsibleList
                handle={
                  <SimpleListItem text={heading} metaIcon="chevron_right" onClick={() => clickedCollapsibleList(i)} />
                }
                open={displayedRows.includes(i) || (i === fields.length - 1 && justAdded)}
              >
                <div style={{ padding: 16 }}>
                  <Hidden name={`parts.${i}.id`} />
                  <Hidden name={`parts.${i}.parentPartId`} defaultValue={field.parentPartId} />
                  <Hidden name={`parts.${i}.type`} defaultValue={field.type} required />
                  <Hidden name={`parts.${i}.name`} defaultValue={field.name} required />
                  <Hidden name={`parts.${i}.number`} defaultValue={field.number} required />
                  <FormRow>
                    <Number
                      defaultValue={field.leadTimeDays}
                      label="Lead Time (Days)"
                      name={`parts.${i}.leadTimeDays`}
                      validate={(value: number) => validateInt(`Part #${i + 1} lead time`, value)}
                      disabled={task?.signingStatus}
                    />
                  </FormRow>

                  {(type === PartType.Rotable || type === PartType.Consumable || type === PartType.Expendable) && (
                    <FormRow col={2}>
                      <Number
                        name={`parts.${i}.cost`}
                        label="Cost"
                        defaultValue={field.cost}
                        validate={(value: number) => validateInt(`Part #${i + 1} cost`, value)}
                        disabled={task?.signingStatus}
                        required
                      />
                      <Toggle
                        name={`parts.${i}.isBillable`}
                        label="Billable?"
                        defaultValue={field.isBillable}
                        disabled={task?.signingStatus}
                      />
                    </FormRow>
                  )}

                  {(type === PartType.Rotable || type === PartType.Consumable || type === PartType.Expendable) && (
                    <FormRow col={2}>
                      <Select
                        outlined
                        name={`parts.${i}.markupType`}
                        defaultValue={field.markupType}
                        label="Markup Type"
                        options={MARKUP_TYPE_OPTIONS}
                        disabled={!part.isBillable || task?.signingStatus}
                      />
                      <Number
                        name={`parts.${i}.markup`}
                        defaultValue={field.markup}
                        label="Markup"
                        validate={(value: number) => validateInt(`Part #${i + 1} markup`, value)}
                        disabled={!part.isBillable || task?.signingStatus}
                      />
                    </FormRow>
                  )}

                  {type === PartType.Consumable && (
                    <Number
                      defaultValue={field.quantity}
                      label="Quantity"
                      name={`parts.${i}.quantity`}
                      validate={(value: number) => validateInt(`Part #${i + 1} cost`, value)}
                      disabled={task?.signingStatus}
                    />
                  )}

                  <FormRow style={{ marginTop: 12, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ color: "red" }}
                      disabled={!!task?.signingStatus}
                      icon="remove_circle"
                      type="button"
                      onClick={() => onRemoveClick(i)}
                    >
                      Remove
                    </Button>
                  </FormRow>
                </div>
              </CollapsibleList>
            </div>
          );
        })}
      </div>
    </MultiItemSection>
  );
};

export const TaskFormPartsContent: React.FC<TaskFormContentProps> = ({ form, style }) => {
  return (
    <div {...{ style }}>
      <PartsFormFields {...{ form }} title={`Assign Parts`} />
    </div>
  );
};
