import { PageDescription, PageKicker, PageTitle } from "components/PageHeader";
import { useProject } from "hooks/params/useProject";
import { useAdminApi } from "hooks/useAdminApi";
import { useFocusMode } from "hooks/useFocusMode";
import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  position: absolute;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #fff;
`;

const Header = styled.div`
  margin-bottom: 12px;
`;

const TaskListFocusMode = ({ children }: Props) => {
  const { focusModeIsEnabled } = useFocusMode();
  const { clients } = useAdminApi();
  const project = useProject();
  const client = clients.find((it) => it.id === project?.clientId);

  if (!focusModeIsEnabled) {
    return <>{children}</>;
  }

  return (
    <Wrapper>
      <Header>
        <PageKicker>{client.name}</PageKicker>
        <PageTitle>{project.name}</PageTitle>
        <PageDescription>{project.description}</PageDescription>
      </Header>
      <div>{children}</div>
    </Wrapper>
  );
};

export default TaskListFocusMode;
