import React from "react";
import { useHistory } from "react-router";
import { MessageFields } from "types/admin/MessageFields";
import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { Button } from "../Button";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";
import {
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import dayjs from "dayjs";
import Palette from "../../palette.json";
import { TaskStatus } from "types/admin/globalTypes";
import styled from "styled-components";

const TimeWrapper = styled(DataTableCell)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface MessageListPropsT {
  items: MessageFields[];
}

export const MessageList: React.FC<MessageListPropsT> = ({ items }) => {
  const history = useHistory();
  const { users } = useAdminApi();
  const project = useProject();
  const { task: parentTask } = useTask();

  const isTaskDeleted = parentTask?.status === TaskStatus.Deleted;

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Messages <TitleCount>({parentTask?.messages?.items.length})</TitleCount>
        </TableToolbarTitle>

        {!isTaskDeleted && (
          <Button
            raised
            onClick={() => history.push(`/work-orders/${project?.id}/tasks/${parentTask?.id}/messages/new`)}
          >
            New Message
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>User</DataTableHeadCell>
              <DataTableHeadCell style={{ padding: "0 24px" }}>Body</DataTableHeadCell>
              <DataTableHeadCell>Posted At</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Messages -
                </DataTableCell>
              </DataTableRow>
            )}

            {[...items].reverse().map((it, i) => (
              <DataTableRow id="table-pickle" key={i}>
                <DataTableCell>{users.find((jt) => jt.id === it.userId)?.name}</DataTableCell>
                <DataTableCell style={{ whiteSpace: "normal", padding: "5px 24px", width: "100%" }}>
                  {it.message}
                </DataTableCell>
                <TimeWrapper>
                  <time>
                    {dayjs(it.postedAt).format("MMMM D")}
                    {dayjs().isSame(dayjs(it.postedAt), "year") ? "" : dayjs(it.postedAt).format("[,] YYYY")}
                  </time>
                  <time>{dayjs(it.postedAt).format("h:m a")}</time>
                </TimeWrapper>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
