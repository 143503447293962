import React from "react";
import { useProject } from "hooks/params/useProject";
import numeral from "numeral";
import { PartType, TaskStatus } from "types/admin/globalTypes";
import { useAdminApi } from "hooks/useAdminApi";
import { getCost } from "./../common";
import useTranslations from "hooks/useTranslations";
import { StatusBlock } from "./StatusBlock";
import styled from "styled-components";
import { Button } from "./Button";
import DonutChart from "react-donut-chart";
import useMultiTenant from "hooks/useMultiTenant";
import tinyColor from "tinycolor2";
import Palette from "../palette.json";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  padding: 10px 12px 0px;
  margin-left: 14px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 4px;
`;

const SpacedBetween = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

const CostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-items: start;
  column-gap: 50px;
  row-gap: 30px;
`;

const CostGroup = styled.div<{ color?: string }>((props) => ({
  "> * + *": {
    marginTop: "16px",
  },
  color: props.color || "inherit",
}));

const CostValue = styled.div`
  font-size: 22px;
`;

const CostLabel = styled.div`
  font-size: 14px;
`;

export const formatPrice = (v: number) => {
  return "$" + numeral(v).format("0,0.00");
};

export const CostsDiagramOverview: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const project = useProject();
  const { theme } = useMultiTenant();
  const { tasks: allTasks } = useAdminApi();

  const tasks = allTasks.filter(
    (it) => it.projectId === project?.id && it.parentId === project.id && it.status !== TaskStatus.Deleted,
  );

  const partsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.taskParts?.items.reduce((partTotal, part) => {
        let quantity = part.quantity;
        // If part is serializable and has children parts, the quantity is the number of children parts
        if (part.parts.items.length > 0 && (part.type === PartType.Expendable || part.type === PartType.Rotable)) {
          quantity = part.parts.items.length;
        }

        return partTotal + (part?.isBillable ? (getCost(part) || 0) * quantity : 0);
      }, 0) || 0)
    );
  }, 0);

  const laborTotal = tasks.reduce((total, it) => {
    return total + it.amount || 0;
  }, 0);

  const toolsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.tools?.reduce((toolTotal, tool) => {
        return toolTotal + (tool?.isBillable ? getCost(tool) || 0 : 0);
      }, 0) || 0)
    );
  }, 0);

  const subcontractorsTotal = tasks.reduce((total, it) => {
    return (
      total +
      (it?.subcontractors?.items?.reduce((subTotal, sub) => {
        return subTotal + getCost(sub) || 0;
      }, 0) || 0)
    );
  }, 0);

  const total = (partsTotal || 0) + (laborTotal || 0) + (toolsTotal || 0) + (subcontractorsTotal || 0);

  return (
    <>
      <StatusBlock
        padding={false}
        data-test-id="costs-overview-diagram"
        style={{ width: "100%", backgroundColor: "white" }}
      >
        <Container>
          <SpacedBetween style={{ marginBottom: "44px" }}>
            <Title>Costs</Title>

            <Button raised onClick={() => history.push("costs")}>
              All Costs
            </Button>
          </SpacedBetween>

          <SpacedBetween>
            <div>
              <CostGroup data-test-id="costs-total" style={{ marginBottom: "48px" }}>
                <div style={{ fontSize: "32px" }}>{formatPrice(total)}</div>
                <div style={{ fontSize: "16px", color: Palette.LightGrey }}>Total Costs</div>
              </CostGroup>

              <CostGrid style={{ marginBottom: "32px" }}>
                <CostGroup data-test-id="parts-costs" color={theme.secondary}>
                  <CostValue>{formatPrice(partsTotal)}</CostValue>
                  <CostLabel>Parts</CostLabel>
                </CostGroup>

                <CostGroup data-test-id="tools-costs" color={Palette.DarkGrey}>
                  <CostValue>{formatPrice(toolsTotal)}</CostValue>
                  <CostLabel>Tools</CostLabel>
                </CostGroup>

                <CostGroup data-test-id="labor-costs" color={theme.primary}>
                  <CostValue>{formatPrice(laborTotal)}</CostValue>
                  <CostLabel>Labor</CostLabel>
                </CostGroup>

                <CostGroup data-test-id="subcontractor-costs" color={Palette.LightGrey}>
                  <CostValue>{formatPrice(subcontractorsTotal)}</CostValue>
                  <CostLabel>Subcontractors</CostLabel>
                </CostGroup>
              </CostGrid>
            </div>

            <div style={{ width: "35%", height: "140px", marginRight: "8px" }}>
              <DonutChart
                interactive={false}
                legend={false}
                strokeColor={null}
                innerRadius={0.7}
                outerRadius={1}
                width={140}
                colors={[theme.primary, theme.secondary, Palette.DarkGrey, Palette.LightGrey]}
                data={[laborTotal, partsTotal, toolsTotal, subcontractorsTotal].map((it) => ({
                  label: "",
                  value: (it / total) * 100,
                }))}
              />
            </div>
          </SpacedBetween>
        </Container>
      </StatusBlock>
    </>
  );
};
