import React, { useEffect } from "react";
import { Route } from "react-router";

import { ClientFormDialog } from "components/clients/ClientFormDialog";
import { ClientList } from "components/clients/ClientList";

import { useAdminApi } from "hooks/useAdminApi";
import useLayout from "hooks/useLayout";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";

export const ClientsPage: React.FC = () => {
  const { clients } = useAdminApi();
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Customers",
        path: "/clients",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path="/clients/new">
        <ClientFormDialog action="create" />
      </Route>

      <Route exact path="/clients/:clientId/edit_">
        <ClientFormDialog action="update" />
      </Route>

      <PageHeader title="Customers" />

      <PageMain>
        <PageContent>
          <ClientList items={clients} />
        </PageContent>
      </PageMain>
    </>
  );
};
