import React, { useEffect } from "react";
import RbrAviationLogo from "../assets/images/foxtrot-insight-logo.png";
import { PageContent, PageMain } from "components/PageStyles";
import useMultiTenant from "hooks/useMultiTenant";
import styled from "styled-components";
import { Button } from "components/Button";
import { useHistory } from "react-router";
import { useAdminApi } from "hooks/useAdminApi";
import { FoxtrotLoadingView } from "components/FoxtrotLoadingView";

export const Error404: React.FC = () => {
  const history = useHistory();
  const { tenantInfo } = useMultiTenant();
  const { isLoading, isInitialLoading } = useAdminApi();
  const logoUrl = tenantInfo?.logoImageAttachment?.file?.url ?? RbrAviationLogo;

  const ErrorContainer = styled.div`
    max-width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 10vh;

    > h2 {
      font-size: 24px;
      margin: 2rem 0;
      font-weight: bold;
    }
  `;

  if (isLoading || isInitialLoading) return <FoxtrotLoadingView />;

  return (
    <PageMain>
      <PageContent>
        <ErrorContainer>
          <img src={logoUrl} width="150" />
          <h2>Oops! That page doesn&apos;t exist</h2>
          <Button raised onClick={() => history.goBack()}>
            Go Back
          </Button>
        </ErrorContainer>
      </PageContent>
    </PageMain>
  );
};
