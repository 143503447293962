import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { useTimesheets } from "./useTimesheets";
import { readableTaskId } from "common";

type Props = {
  start: Dayjs;
  end: Dayjs;
  prevStart: Dayjs;
  prevEnd: Dayjs;
};

type DayItem = {
  date: string;
  hrs: number;
  taskIds: string[];
};

export const useTrendStats = ({ start, end, prevStart, prevEnd }: Props) => {
  const { tasks } = useAdminApi();
  const currentTimesheets = useTimesheets({ start, end });
  const prevTimesheets = useTimesheets({ start: prevStart, end: prevEnd });

  const filteredTimesheets = currentTimesheets.filter((it) => it.taskId && !!readableTaskId(it.taskId));
  const filteredPrevTimesheets = prevTimesheets.filter((it) => it.taskId && !!readableTaskId(it.taskId));

  const calculateHrsPerTaskPerDay = (timesheets) => {
    const items: DayItem[] = [];

    timesheets.forEach((it) => {
      const hrs = it.amountMins / 60;
      const date = dayjs(it.toDT).format("MM/DD/YYYY");
      const taskId = it.taskId;
      const index = items.findIndex((it) => it.date === date);
      if (index === -1) {
        items.push({
          date,
          hrs,
          taskIds: [taskId],
        });
      } else {
        items[index].hrs += hrs;
        items[index].taskIds = [...items[index].taskIds, taskId];
      }
    });

    return (
      items.reduce((acc, it) => {
        const uniqueTaskIds = [...new Set(it.taskIds)];
        return acc + (it.hrs / uniqueTaskIds.length || 0);
      }, 0) / items.length
    );
  };

  const avgDailyHrsPerTask = calculateHrsPerTaskPerDay(filteredTimesheets);
  const avgPrevDailyHrsPerTask = calculateHrsPerTaskPerDay(filteredPrevTimesheets);

  const taskIds = filteredTimesheets.map((it) => it.taskId);
  const uniqueTaskIds = [...new Set(taskIds)];
  const sumDailyTargetHrs = uniqueTaskIds.reduce((acc, taskId) => {
    const task = tasks.find((task) => task.id === taskId);
    if (!task) return acc;
    const taskDays = dayjs(task.dueDate).diff(task.scheduledDate, "day") + 1 || 1;
    const targetHrs = task?.estimateManHrs || 0;
    return acc + targetHrs / taskDays;
  }, 0);
  const avgTargetHrs = sumDailyTargetHrs / uniqueTaskIds.length;
  const variance = ((avgDailyHrsPerTask - avgTargetHrs) / avgTargetHrs) * 100;
  const varianceRounded = Math.round(variance * 100) / 100;

  return {
    average: {
      current: Math.round(avgDailyHrsPerTask * 100) / 100,
      prev: Math.round(avgPrevDailyHrsPerTask * 100) / 100,
    },
    target: Math.round(avgTargetHrs * 100) / 100,
    variance: varianceRounded,
  };
};
