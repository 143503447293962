import React from "react";
import { Route } from "react-router";
import { PartFormDialog } from "components/parts/PartFormDialog";
import { PartsList } from "components/parts/PartsList";

export const TemplateTaskPartsPage: React.FC = () => {
  return (
    <>
      <Route path="/templates/:templateId/tasks/:taskId/parts/new">
        <PartFormDialog action="assign" />
      </Route>

      <PartsList />
    </>
  );
};
