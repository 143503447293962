import React, { useEffect, useState } from "react";
import validator from "validator";
import { useHistory, useParams } from "react-router";

import generateEstimate from "utilities/estimateGenerator";
import { useForm } from "react-hook-form";
import { useAdminApi } from "hooks/useAdminApi";

import { Label } from "../Label";
import { Dialog } from "../Dialog";
import { Form, FormRow, Text, Date, CountryCode, Phone, Textarea } from "components/Form";
import logo from "../../assets/images/foxtrot_pdf_logo.png";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";

import "@rmwc/checkbox/styles";
import { Divider } from "../../pages/signin";
import useMultiTenant from "hooks/useMultiTenant";
import dayjs from "dayjs";
import { TaskFields } from "types/admin/TaskFields";
import { snackbar } from "hooks/useNotifications";
import { useProject } from "hooks/params/useProject";
import { BreakdownCostsCheckbox } from "../BreakdownCostsCheckbox";
import { ShowItemizedEstimateCheckbox } from "../ShowItemizedEstimateCheckbox";
import { useSettings } from "hooks/useSettings";
import useTranslations from "hooks/useTranslations";

interface ProjectEstimateFormDialogProps {
  items: TaskFields[];
  setGeneratingEstimate: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ProjectEstimateFormDialogValues {
  companyName: string;
  companyAddress?: string;
  companyPhone?: string;
  companyCountryCode?: string;
  companyEmail?: string;
  customerName?: string;
  customerAddress?: string;
  date: string;
  dueDate?: string;
  terms?: string;
  tailNumber?: string;
  poNumber?: string;
  airport?: string;
  bottomTerms?: string;
}

export const ProjectEstimateFormDialog: React.FC<ProjectEstimateFormDialogProps> = ({
  items,
  setGeneratingEstimate,
}) => {
  const { tCommon } = useTranslations();
  const { projectId } = useParams<{ projectId?: string }>();
  const project = useProject();
  const history = useHistory();
  const { settings } = useSettings();

  const form = useForm<ProjectEstimateFormDialogValues>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      companyName: "",
      companyAddress: "",
      companyCountryCode: settings?.phoneNumber
        ? settings!.phoneNumber.replace(/^(\+\d{1,2})(\d{3})(\d{3})(\d{4})$/, "$1")
        : "+1",
      companyPhone: settings?.phoneNumber ? settings!.phoneNumber.slice(-10) : "",
      companyEmail: "",
      customerName: "",
      customerAddress: "",
      date: dayjs().tz("UTC", true).format("YYYY-MM-DD"),
      terms: "",
      tailNumber: "",
      poNumber: "",
      airport: "",
    },
  });

  const { formState, register, watch, setValue, clearErrors, setError, handleSubmit } = form;
  const { isDirty } = formState;

  const [breakdownCosts, setBreakdownCosts] = useState(false);
  const [showItemizedEstimateCheckboxIsChecked, setShowItemizedEstimateCheckboxIsChecked] = useState(false);

  const watchEmail = watch("companyEmail");

  const { tenantInfo } = useMultiTenant();
  const {
    incrementTemplateEstimateCount,
    getTenantSettings,
    clients: allClients,
    entities: allAircraft,
    listTasks,
  } = useAdminApi();
  const [client] = allClients.filter((c) => c.id === project?.clientId);
  const [aircraft] = allAircraft.filter((a) => a.id === project?.aircraftId);

  useEffect(() => {
    if (!client?.id) return;

    setValue("customerName", client.name, { shouldDirty: true, shouldValidate: true });
    setValue("customerAddress", client.address, { shouldDirty: true, shouldValidate: true });
  }, [client]);

  useEffect(() => {
    if (!aircraft?.id) return;

    setValue("tailNumber", aircraft.tailNumber, { shouldDirty: true, shouldValidate: true });
  }, [aircraft]);

  useEffect(() => {
    if (!settings.name) return;

    setValue("companyName", settings.name, { shouldDirty: true, shouldValidate: true });
    setValue("companyEmail", settings.email, { shouldDirty: true, shouldValidate: true });
    setValue("companyPhone", settings?.phoneNumber?.slice(-10), { shouldDirty: true, shouldValidate: true });
    setValue(
      "companyAddress",
      `${
        settings?.address?.street2
          ? `${settings?.address?.street1}\n${settings?.address?.street2}`
          : settings?.address?.street1
      }\n${settings?.address?.city}, ${settings?.address?.state} ${settings?.address?.zip}`,
      { shouldDirty: true, shouldValidate: true },
    );
    setValue("bottomTerms", settings?.terms, { shouldDirty: true, shouldValidate: true });
  }, [settings]);

  useEffect(() => {
    if ((watchEmail ?? []).length === 0) {
      clearErrors("companyEmail");
      return;
    }
    if (validator.isEmail(watchEmail ?? "")) {
      setValue("companyEmail", watchEmail);
    } else {
      setError("companyEmail", {
        type: "manual",
        message: "Invalid email",
      });
    }
  }, [watchEmail]);

  const generateRandomId = (length = 10) => {
    let result = "";
    const pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const poolLength = pool.length;
    for (let i = 0; i < length; i++) {
      result += pool.charAt(Math.floor(Math.random() * poolLength));
    }
    return result;
  };

  const onSubmit = handleSubmit(async (values: ProjectEstimateFormDialogValues) => {
    let estimateNumber: string;
    setGeneratingEstimate(true);
    try {
      estimateNumber = (await incrementTemplateEstimateCount()).toString();
    } catch (err) {
      const error = err as Error;
      console.error(error);
      estimateNumber = generateRandomId();
    }
    values = { ...values, companyPhone: `${values.companyCountryCode}${values.companyPhone}` };

    try {
      // This updates the list of tasks to most current
      await listTasks(projectId);
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }

    generateEstimate(
      values,
      project?.name ?? "",
      tenantInfo?.logoImageAttachment?.file.url,
      items,
      logo,
      estimateNumber,
      breakdownCosts,
      showItemizedEstimateCheckboxIsChecked,
      () => {
        console.log("Generated estimate");
        setGeneratingEstimate(false);
        snackbar.notify({ title: "Estimate generated", icon: "check" });
        history.push(`/work-orders/${projectId}/tasks`);
      },
    );
  });

  return (
    <>
      <div style={{ zIndex: 99 }}>
        <Dialog open preventOutsideDismiss>
          <DialogTitle>Generate Estimate from Work Order</DialogTitle>

          <DialogContent style={{ width: "50vw" }}>
            <Form form={form} onSubmit={onSubmit}>
              <FormRow>
                <Text name="companyName" label="Company Name" required />
                <Text name="companyEmail" label="Company Email Address" required />
              </FormRow>
              <FormRow style={{ display: "block" }}>
                <Label htmlFor="companyPhone">Company Phone Number</Label>
                <div style={{ display: "flex", maxWidth: "200px" }}>
                  <CountryCode name="companyCountryCode" required />
                  <Phone name="companyPhone" required />
                </div>
              </FormRow>
              <FormRow>
                <Text name="companyAddress" label="Company Address" required />
              </FormRow>
              <Divider />
              <FormRow>
                <Text name="customerName" label="Company Name" required />
              </FormRow>
              <FormRow>
                <Text name="customerAddress" label="Customer Address" required />
              </FormRow>
              <Divider />
              <FormRow>
                <Date name="date" label="Date" required />
                <Date name="dueDate" label="Due Date" required />
              </FormRow>
              <FormRow>
                <Text name="terms" label="Terms" required />
                <Text name="tailNumber" label={tCommon("TailNumber")} required />
              </FormRow>
              <FormRow>
                <Text name="airport" label="Airport" required />
                <Text name="poNumber" label="PO Number" required />
              </FormRow>

              <FormRow style={{ display: "block" }}>
                <Textarea
                  name="bottomTerms"
                  label="Terms and Conditions"
                  style={{ fontSize: "10px", marginBottom: "5px" }}
                />
                <Label style={{ textTransform: "none", fontSize: "13px", fontWeight: 400, lineHeight: 1.5 }}>
                  Changing the text here will only affect this estimate and will not be changed for any other admin
                  users.
                </Label>
              </FormRow>
            </Form>
          </DialogContent>

          <DialogActions>
            <ThemeProvider options={{ primary: Palette.MediumGrey }}>
              <DialogButton onClick={history.goBack} outlined>
                Cancel
              </DialogButton>
            </ThemeProvider>

            <div style={{ flexGrow: 1 }} />

            <ShowItemizedEstimateCheckbox
              checked={showItemizedEstimateCheckboxIsChecked}
              setChecked={setShowItemizedEstimateCheckboxIsChecked}
            />

            <BreakdownCostsCheckbox checked={breakdownCosts} setChecked={setBreakdownCosts} />

            <DialogButton onClick={onSubmit} raised disabled={!isDirty}>
              Generate Estimate
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
