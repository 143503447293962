import React, { useEffect } from "react";
import { Route } from "react-router";
import useLayout from "hooks/useLayout";
import { useAdminApi } from "hooks/useAdminApi";
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { ProjectList } from "components/projects/ProjectList";
import { ProjectFormDialog } from "components/projects/ProjectFormDialog";

export const ProjectsPage: React.FC = () => {
  const { projects } = useAdminApi();
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Work Orders",
        path: "/work-orders",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path="/work-orders/new">
        <ProjectFormDialog action="create" />
      </Route>

      <Route exact path="/work-orders/:projectId/edit_">
        <ProjectFormDialog action="update" />
      </Route>

      <PageHeader title="Work Orders" />

      <PageMain>
        <PageContent>
          <ProjectList items={projects} />
        </PageContent>
      </PageMain>
    </>
  );
};

export * from "./[projectId]";
