import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ProjectRole } from "types/admin/globalTypes";
import { UserFields } from "types/admin/UserFields";

import { PROJECT_ROLE_OPTIONS } from "../../common";

import { snackbar } from "hooks/useNotifications";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

import useFiltered from "hooks/useFiltered";
import useSorted, { sortByProperty } from "hooks/useSorted";

import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { Select } from "../Select";
import { TextInput } from "../TextInput";

import { TaskUserAssignList } from "./TaskUserAssignList";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogOnCloseEventT,
  DialogTitle as DialogTitleBase,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import styled from "styled-components";
import { useTemplate } from "hooks/params/useTemplate";
import { ProjectUserAssignList } from "./ProjectUserAssignList";
import { TaskFields_users_items } from "types/admin/TaskFields";

const DialogTitle = styled(DialogTitleBase)`
  display: flex;
  align-items: center;

  border: none;

  > span {
    margin: 20px 3rem 0 0;
    flex-grow: 1;
  }

  > button {
    margin: 20px 0 0;
  }
`;

const FilterControls = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: 1rem;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const UserAssignDialog: React.FC = () => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string }>();

  const {
    users,
    addProjectUser,
    removeProjectUser,
    removeTaskUser,
    assignUserToTask,
    assignUserToTaskTemplate,
    unassignUserToTaskTemplate,
  } = useAdminApi();

  const project = useProject();
  const { task } = useTask();
  const { template, isTemplateTypeTask, isTemplateTypeProject } = useTemplate();

  const sorted = useSorted(users, sortByProperty("name"));

  const [allowAll, setAllowAll] = useState<boolean>(template ? true : false);
  const [filterByRole, setFilterByRole] = useState<ProjectRole | "all">("all");

  const [searchString, setSearchString] = useState<string>("");

  const isTask = !!task;

  const filtered = useFiltered(sorted, (it) => {
    const canUserWorkAtLocation = () => {
      if (project.location) {
        if (it.locations?.length) {
          if (!it.locations.find((jt) => jt.id === project.location.id)) {
            return false;
          }
        }
      }
      return true;
    };

    if (task) {
      if (allowAll) {
        if (!canUserWorkAtLocation()) {
          return false;
        }
      } else if (!project.users?.items.find((jt) => jt.userId === it.id)) {
        return false;
      }
    }

    // project
    if (!project?.users?.items.find((jt) => jt.userId === it.id)) {
      if (!canUserWorkAtLocation()) {
        return false;
      }
    }

    return (
      it.name?.toLowerCase().includes(searchString.toLowerCase()) ||
      it.email.toLowerCase().includes(searchString.toLowerCase())
    );
  });

  const onAssignUser = async (role: ProjectRole | null, user: UserFields) => {
    if (isTemplateTypeTask && template) {
      await assignUserToTaskTemplate({ taskTemplateId: template.id, userId: user.id });
    }

    if (task) {
      await assignUserToTask({ taskId: task.id, userId: user.id });
      snackbar.notify({ title: "User assigned to task successfully." });
      return;
    }

    if (!role) {
      snackbar.notify({ icon: "warning", title: "Please select a work order role!" });
      return;
    }

    if (project || isTemplateTypeProject) {
      await addProjectUser({
        input: { projectId: project?.id || template.id, userId: user.id, clientId: project?.clientId ?? "", role },
      });
      snackbar.notify({ title: "User assigned to work order successfully." });
    }
  };

  const onRemoveUser = async (user: UserFields) => {
    if (isTemplateTypeTask && template) {
      await unassignUserToTaskTemplate({ taskTemplateId: template.id, userId: user.id });
    }

    if (task) {
      await removeTaskUser({ id: task.id, userId: user.id });
      snackbar.notify({ title: "User removed from task successfully." });
      return;
    }

    if (project || isTemplateTypeProject) {
      await removeProjectUser(project?.id || template?.id, user.id);
      snackbar.notify({ title: "User removed from work order successfully." });
    }
  };

  if (!project && !template) return <></>;

  return (
    <Dialog preventOutsideDismiss renderToPortal open>
      <DialogTitle>
        <span>Manage {isTask ? "Task" : isTemplateTypeTask ? "Template Task" : "Work Order"} Users</span>
        {project && (
          <Button raised onClick={() => history.replace(`/work-orders/${project.id}/people/new`, locationState)}>
            Create New
          </Button>
        )}
      </DialogTitle>

      <DialogContent>
        <FilterControls>
          <TextInput
            placeholder="Search by name or email."
            value={searchString}
            onChange={(ev) => setSearchString(ev.target.value)}
          />
          <Select
            outlined
            options={[{ label: "All", value: "all" }, ...PROJECT_ROLE_OPTIONS]}
            value={filterByRole}
            onChange={(ev: any) => setFilterByRole(ev.detail.value)}
          />
          {!template && task && (
            <Checkbox
              label="Work Order Only"
              checked={!allowAll}
              onChange={(ev) => setAllowAll(!ev.currentTarget.checked)}
            />
          )}
        </FilterControls>

        {isTemplateTypeTask && template ? (
          <TaskUserAssignList
            items={filtered}
            assigned={(template.users?.items || []) as unknown as TaskFields_users_items[]}
            projectUsers={[]}
            onAssignUser={onAssignUser}
            onRemoveUser={onRemoveUser}
          />
        ) : !task ? (
          <ProjectUserAssignList
            items={filtered}
            assigned={project?.users?.items || template?.users?.items || []}
            onAssignUser={onAssignUser}
            onRemoveUser={onRemoveUser}
          />
        ) : (
          <TaskUserAssignList
            items={filtered}
            assigned={task.users?.items || []}
            projectUsers={project?.users?.items || []}
            onAssignUser={onAssignUser}
            onRemoveUser={onRemoveUser}
          />
        )}
      </DialogContent>

      <DialogActions>
        <DialogButton onClick={history.goBack}>Close</DialogButton>
        <DialogButton raised isDefaultAction action="accept" onClick={history.goBack}>
          Done
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
