import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import React from "react";
import { UtilizationTable } from "./UtilizationTable";

export const UtilizationPage: React.FC = () => {
  return (
    <>
      <PageHeader title={"Utilization Report"} />

      <PageMain>
        <PageContent>
          <UtilizationTable />
        </PageContent>
      </PageMain>
    </>
  );
};
