import gql from "graphql-tag";

import { AttachmentFields } from "./attachments";
import { PartFields } from "./parts";
import { SubcontractorFields } from "./tasks";

export const TemplateFields = gql`
  ${AttachmentFields}
  ${SubcontractorFields}
  ${PartFields}

  fragment TemplateFields on Template {
    id
    name
    type
    hourlyRate
    estimateManHrs
    signingType
    description
    billing
    attachments {
      items {
        ...AttachmentFields
      }
    }
    tools {
      name
      number
      cost
      leadTimeDays
      expirationDate
      quantity
      isBillable
      markupType
      markup
      markupCost
    }
    users {
      items {
        userId
        role
      }
    }
    parts {
      items {
        ...PartFields
      }
    }
    subcontractors {
      items {
        ...SubcontractorFields
      }
    }
  }
`;

export const TemplateTaskUserFields = gql`
  fragment TemplateTaskUserFields on TemplateTaskUser {
    userId
    taskId
    role
  }
`;

export const getTemplateQuery = gql`
  ${TemplateFields}

  query GetTemplate($id: ID!) {
    asAdmin {
      getTemplate(id: $id) {
        ...TemplateFields
      }
    }
  }
`;

export const listTemplatesQuery = gql`
  ${TemplateFields}

  query ListTemplates($type: TemplateType, $limit: Int, $nextToken: String, $scanIndexForward: Boolean) {
    asAdmin {
      listTemplates(type: $type, limit: $limit, nextToken: $nextToken, scanIndexForward: $scanIndexForward) {
        items {
          ...TemplateFields
        }
      }
    }
  }
`;

export const addProjectTemplateMutation = gql`
  ${TemplateFields}

  mutation AddProjectTemplate($projectId: ID!, $copyUsers: Boolean, $input: CreateProjectTemplateRequest!) {
    asAdmin {
      addProjectTemplate(projectId: $projectId, copyUsers: $copyUsers, input: $input) {
        ...TemplateFields
      }
    }
  }
`;

export const updateProjectTemplateMutation = gql`
  ${TemplateFields}

  mutation UpdateProjectTemplate($id: ID!, $input: UpdateProjectTemplateRequest!) {
    asAdmin {
      updateProjectTemplate(id: $id, input: $input) {
        ...TemplateFields
      }
    }
  }
`;

export const deleteProjectTemplateMutation = gql`
  mutation DeleteProjectTemplate($id: ID!) {
    asAdmin {
      deleteProjectTemplate(id: $id)
    }
  }
`;

export const addTaskTemplateMutation = gql`
  ${TemplateFields}

  mutation AddTaskTemplate($taskId: ID!, $copyUsers: Boolean, $input: CreateTaskTemplateRequest!) {
    asAdmin {
      addTaskTemplate(taskId: $taskId, copyUsers: $copyUsers, input: $input) {
        ...TemplateFields
      }
    }
  }
`;

export const assignUserToTaskTemplateMutation = gql`
  ${TemplateTaskUserFields}

  mutation AssignUserToTaskTemplate($taskTemplateId: ID!, $userId: ID!) {
    asAdmin {
      assignUserToTaskTemplate(taskTemplateId: $taskTemplateId, userId: $userId) {
        ...TemplateTaskUserFields
      }
    }
  }
`;

export const unassignUserToTaskTemplateMutation = gql`
  ${TemplateTaskUserFields}

  mutation UnassignUserToTaskTemplate($taskTemplateId: ID!, $userId: ID!) {
    asAdmin {
      removeTaskTemplateUser(id: $taskTemplateId, userId: $userId) {
        ...TemplateTaskUserFields
      }
    }
  }
`;

export const updateTaskTemplateMutation = gql`
  ${TemplateFields}

  mutation UpdateTaskTemplate($id: ID!, $input: UpdateTaskTemplateRequest!) {
    asAdmin {
      updateTaskTemplate(id: $id, input: $input) {
        ...TemplateFields
      }
    }
  }
`;

export const deleteTaskTemplateMutation = gql`
  mutation DeleteTaskTemplate($id: ID!) {
    asAdmin {
      deleteTaskTemplate(id: $id)
    }
  }
`;
