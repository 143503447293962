import { LocationFields_address } from "types/admin/LocationFields";

export const formatAddress = (address: LocationFields_address) => {
  const { street1, street2, city, state, zip } = address;

  let addressString = "";
  if (street1) {
    addressString += street1;
  }
  if (street2) {
    addressString += `, ${street2}`;
  }
  if (city) {
    addressString += ` ${city}`;
  }
  if (state) {
    addressString += `, ${state}`;
  }
  if (zip) {
    addressString += ` ${zip}`;
  }
  return addressString;
};
