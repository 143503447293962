import React from "react";

import { UserFields } from "types/admin/UserFields";
import { TaskFields_users_items } from "types/admin/TaskFields";

import { Button } from "../Button";

import { Table, TableContainer } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list";
import "@rmwc/list/styles";

import Palette from "../../palette.json";
import { ProjectRole } from "types/admin/globalTypes";
import { ProjectFields_users_items } from "types/admin/ProjectFields";
//import { USER_ROLE_TO_LABEL } from "../../common";

interface TaskUserListPropsT {
  items: UserFields[];

  assigned: TaskFields_users_items[];
  projectUsers: ProjectFields_users_items[];
  onAssignUser(role: ProjectRole | null, user: UserFields): void;
  onRemoveUser(user: UserFields): void;
}

export const TaskUserAssignList: React.FC<TaskUserListPropsT> = ({
  items,
  assigned,
  projectUsers,
  onAssignUser,
  onRemoveUser,
}) => {
  const filtered = items.filter((it) => projectUsers.find((pu) => pu.userId === it.id)?.role !== ProjectRole.customer);

  return (
    <TableContainer>
      <Table>
        <DataTableContent>
          <DataTableBody data-test-id="task-user-assign-list">
            {!filtered.length && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No People -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>
                  <ListItemText>
                    <ListItemPrimaryText style={{ marginTop: "-20px" }}>{it.name}</ListItemPrimaryText>
                    <ListItemSecondaryText>{it.email}</ListItemSecondaryText>
                  </ListItemText>
                </DataTableCell>
                <DataTableCell>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      data-test-id="assign-button"
                      style={{ width: "7rem" }}
                      outlined={!assigned.find((jt) => jt.userId === it.id)}
                      raised={!!assigned.find((jt) => jt.userId === it.id)}
                      onClick={() =>
                        assigned.find((jt) => jt.userId === it.id)
                          ? onRemoveUser(it)
                          : onAssignUser(ProjectRole.technician, it)
                      }
                    >
                      {!!assigned.find((jt) => jt.userId === it.id) ? "Assigned" : "Assign"}
                    </Button>
                  </div>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
