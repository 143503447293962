import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "stylesheets/date-range-picker.css";
import { DateRangePicker as DateRangePickerBase } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";
import useMultiTenant from "hooks/useMultiTenant";

export enum Period {
  Today = "Today",
  ThisWeek = "This Week",
  Last7Days = "Last 7 Days",
  ThisMonth = "This Month",
  Last30Days = "Last 30 Days",
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

function createStaticRanges(ranges: any[]) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

const defaultStaticRanges = createStaticRanges([
  {
    label: Period.Today,
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: Period.ThisWeek,
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: Period.Last7Days,
    range: () => ({
      startDate: addDays(defineds.startOfToday, -7),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: Period.ThisMonth,
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: Period.Last30Days,
    range: () => ({
      startDate: addDays(defineds.startOfToday, -30),
      endDate: defineds.endOfToday,
    }),
  },
]);

const rangeToPeriod = (startDate: Date, endDate: Date): Period | undefined => {
  const foundRange = defaultStaticRanges.find(
    (range) => isSameDay(range.range().startDate, startDate) && isSameDay(range.range().endDate, endDate),
  );

  return foundRange ? (foundRange.label as Period) : undefined;
};

type SelectionRange = {
  startDate: Date;
  endDate: Date;
  key: string;
};

interface DateRangePickerProps {
  style?: React.CSSProperties;
  onChange: (value: Period | { startDate: Date; endDate: Date }) => void;
  close?: () => void;
  [key: string]: any;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ onChange, close, ...props }) => {
  const { theme } = useMultiTenant();
  const [selectionRange, setSelectionRange] = React.useState<SelectionRange>({
    startDate: defineds.startOfWeek,
    endDate: defineds.endOfWeek,
    key: "selection",
  });

  const handleSelect = (ranges: any) => {
    const selection = ranges.selection;
    const formattedRange = {
      startDate: selection.startDate,
      endDate: endOfDay(selection.endDate),
      key: "selection",
    };
    setSelectionRange(formattedRange);

    const predefinedRange = rangeToPeriod(formattedRange.startDate, formattedRange.endDate);
    onChange(predefinedRange || { startDate: formattedRange.startDate, endDate: formattedRange.endDate });

    if (predefinedRange) close?.();
  };

  return (
    <div {...props}>
      <DateRangePickerBase
        ranges={[selectionRange]}
        inputRanges={[]}
        staticRanges={defaultStaticRanges}
        onChange={handleSelect}
        rangeColors={[theme.primary]}
      />
    </div>
  );
};

export default DateRangePicker;
