import React from "react";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";
import { AddTimeSheetEntry_asAdmin_addTimeSheetEntry_history } from "types/admin/AddTimeSheetEntry";
import { useAdminApi } from "hooks/useAdminApi";
import { Avatar } from "@rmwc/avatar";
import { getAvatarColor, readableTaskId } from "../common";
import dayjs from "dayjs";
import { Label } from "./Label";
import { TimesheetFields } from "types/admin/TimesheetFields";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

const AvatarContainer = styled.div`
  display: flex;
  color: #000;
`;

const ConflictingTimesheetsContainer = styled.div`
  > * + * {
    margin-top: 8px;
  }

  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 70px;
`;

const ConflictingTimesheetWarning = styled.div`
  display: flex;
  align-items: center;
  color: ${Palette.Red};
  padding: 8px 16px;
  background-color: ${Palette.Red}2f;
  border-radius: 6px;

  > * + * {
    margin-left: 8px;
  }
`;

interface TimesheetHistoryProps {
  timesheetId: string;
  history: AddTimeSheetEntry_asAdmin_addTimeSheetEntry_history;
  timesheet: TimesheetFields;
}

export const TimesheetHistory: React.FC<TimesheetHistoryProps> = ({ timesheetId, history, timesheet }) => {
  const { conflictingTimesheets } = timesheet;
  const { users } = useAdminApi();
  const routerHistory = useHistory();
  const { taskId, userId } = useParams<{ taskId: string; userId: string }>();

  return (
    <table>
      <Label style={{ marginBottom: "18px" }}>History</Label>
      <tbody data-test-id="timesheet-history">
        {history?.changes &&
          history.changes.length > 0 &&
          history.changes.map((change, index) => {
            const user = users.find((u) => u.id === change.userId);
            const userName = user?.firstName ? `${user?.firstName} ${user?.lastName}` : change.userName || "Unknown";
            let previousFromDT: any = null;
            let previousToDT: any = null;
            let previousTaskId: any = null;
            if (history.changes && index !== history.changes.length - 1) {
              previousFromDT = history.changes[index + 1].fromDT;
              previousToDT = history.changes[index + 1].toDT;
              previousTaskId = history.changes[index + 1].taskId;
            }
            return (
              <tr key={index}>
                <AvatarContainer>
                  <Avatar
                    style={{
                      color: "white",
                      backgroundColor: getAvatarColor(change.userId),
                      marginRight: "16px",
                    }}
                    name={userName}
                  />
                  <td>
                    {change.autoClockOut ? (
                      <span style={{ display: "flex", marginBottom: "7px" }}>
                        <span style={{ fontWeight: "bold" }}>Automatic Clockout</span>
                        &nbsp;was initiated on {dayjs(change.md).format("MMMM DD, YYYY, h:mm A")}
                      </span>
                    ) : (
                      <span style={{ display: "flex", marginBottom: "7px" }}>
                        <span style={{ fontWeight: "bold" }}>{userName}</span>
                        &nbsp;made updates on {dayjs(change.md).format("MMMM DD, YYYY, h:mm A")}
                      </span>
                    )}

                    {previousFromDT != change.fromDT && (
                      <>
                        <span style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
                          <span>Start:&nbsp;</span>
                          {previousFromDT && (
                            <>
                              <span
                                style={{
                                  padding: "2px 8px",
                                  color: "black",
                                  backgroundColor: Palette.LightestGrey,
                                  borderRadius: "4px",
                                }}
                              >
                                {dayjs(previousFromDT).format("MMMM DD, YYYY, h:mm A")}
                              </span>
                              <Icon icon="arrow_right_alt" style={{ margin: "0 10px", color: Palette.LightGrey }} />
                            </>
                          )}
                          <span
                            style={{
                              padding: "2px 8px",
                              color: "black",
                              backgroundColor: Palette.LightestGrey,
                              borderRadius: "4px",
                            }}
                          >
                            {dayjs(change.fromDT).format("MMMM DD, YYYY, h:mm A")}
                          </span>
                        </span>
                      </>
                    )}

                    {previousToDT != change.toDT && (
                      <>
                        <span style={{ display: "flex", alignItems: "center", marginBottom: 7 }}>
                          <span>End:&nbsp;</span>
                          {previousToDT && (
                            <>
                              <span
                                style={{
                                  padding: "2px 8px",
                                  color: "black",
                                  backgroundColor: Palette.LightestGrey,
                                  borderRadius: "4px",
                                }}
                              >
                                {dayjs(previousToDT).format("MMMM DD, YYYY, h:mm A")}
                              </span>
                              <Icon icon="arrow_right_alt" style={{ margin: "0 10px", color: Palette.LightGrey }} />
                            </>
                          )}
                          <span
                            style={{
                              padding: "2px 8px",
                              color: "black",
                              backgroundColor: Palette.LightestGrey,
                              borderRadius: "4px",
                            }}
                          >
                            {dayjs(change.toDT).format("MMMM DD, YYYY, h:mm A")}
                          </span>
                        </span>
                      </>
                    )}

                    {change.taskId && previousTaskId !== change.taskId && (
                      <>
                        {change.projectSummary && (
                          <span style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
                            <span>Assigned to project&nbsp;</span>
                            <span
                              style={{
                                padding: "2px 8px",
                                color: "black",
                                backgroundColor: Palette.LightestGrey,
                                borderRadius: "4px",
                              }}
                            >
                              {change.projectSummary}
                            </span>
                          </span>
                        )}

                        <span style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
                          <span>Assigned to task&nbsp;</span>
                          <span
                            style={{
                              padding: "2px 8px",
                              color: "black",
                              backgroundColor: Palette.LightestGrey,
                              borderRadius: "4px",
                            }}
                          >
                            {change.taskSummary || readableTaskId(change.taskId)}
                          </span>
                        </span>
                      </>
                    )}

                    {previousToDT === change.toDT &&
                      previousFromDT === change.fromDT &&
                      previousTaskId === change.taskId && <span>No changes made in update</span>}
                  </td>
                </AvatarContainer>
              </tr>
            );
          })}

        <tr>
          {!!conflictingTimesheets?.items?.length && (
            <ConflictingTimesheetsContainer>
              {conflictingTimesheets.items.map((timesheet) => {
                const timesheetUserName = timesheet.creator?.firstName
                  ? `${timesheet.creator?.firstName} ${timesheet.creator?.lastName}`
                  : "Unknown";
                return (
                  <ConflictingTimesheetWarning
                    key={timesheet.id}
                    onClick={() =>
                      routerHistory.push(
                        `/work-orders/${timesheet.projectId}/tasks/${timesheet.taskId}/timesheets/${userId}/review`,
                      )
                    }
                  >
                    <Icon icon={{ icon: "warning", size: "xsmall" }} />
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Overlapping with timesheet created by &nbsp;
                        <AvatarContainer style={{ alignItems: "center", fontWeight: 500 }}>
                          <Avatar
                            style={{
                              color: "white",
                              backgroundColor: getAvatarColor(timesheet.creator?.id),
                              marginRight: "4px",
                            }}
                            name={timesheetUserName}
                          />
                          <span>{timesheetUserName}</span>
                        </AvatarContainer>
                      </div>

                      {timesheet.taskId !== taskId && (
                        <span style={{ fontWeight: "bold" }}>
                          {timesheet.projectSummary} - {timesheet.taskSummary}
                        </span>
                      )}
                    </div>
                  </ConflictingTimesheetWarning>
                );
              })}
            </ConflictingTimesheetsContainer>
          )}
        </tr>
      </tbody>
    </table>
  );
};
