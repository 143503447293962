import React, { useEffect, useState } from "react";

import { TextInput } from "./TextInput";

import { FormField } from "./CommonForm";

import "@rmwc/elevation/styles";
import styled from "styled-components";

const OptionButton = styled.button`
  background-color: #fbfbfd;
  border: 0;
  box-shadow: none;
  padding: 10px 12px;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
  }

  &:focus {
    outline: none;
    background-color: #eee;
  }
`;

export const SelectedValueText = styled.div`
  color: ${(props) => props.theme.primary};
  fontsize: 16;
  font-weight: 500;
  pointer-events: none;
`;

export interface SelectOptionT {
  value: string;
  label: string;
}

export interface AutocompleteInputProps {
  options: SelectOptionT[];
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  select: (id: string) => void;
  onChange?: (value: string) => void;
  ref?: any;
  name?: string;
  absolute?: boolean;
  hideable?: boolean;
  shouldClearOnSelect?: boolean;
  dropdownMaxHeight?: string;
  testId?: string;
}

const sortedOptions = (options?: any[]) => {
  options = options || [];
  return options.sort((a, b) => {
    if (a.value === "all") return -1;
    if (b.value === "all") return 1;
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};

const AbsoluteDropdownWrapper = styled.div<{ maxHeight?: string }>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  max-height: ${(props) => props.maxHeight || "100px"};
  overflow-y: auto;
  overflow-x: hidden;
`;

const DropdownWrapper = styled.div`
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  options,
  select,
  onChange,
  disabled,
  value,
  placeholder,
  name,
  ref,
  absolute,
  hideable,
  shouldClearOnSelect,
  dropdownMaxHeight,
  testId,
}) => {
  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<SelectOptionT[]>([]);
  const [activeOption, setActiveOption] = useState<SelectOptionT | undefined>();
  const [dropdownItemsFocused, setDropdownItemsFocused] = useState<number>(0);

  const choose = (choice?: SelectOptionT) => {
    setActiveOption(choice);
    setDropdownItemsFocused(0);
  };

  const incrementDropdownItemsFocused = () => {
    setDropdownItemsFocused((prev) => prev + 1);
  };

  const decrementDropdownItemsFocused = () => {
    setDropdownItemsFocused((prev) => prev - 1);
  };

  const onInputFocus = () => {
    setDropdownIsActive(true);
  };

  const onInputBlur = () => {
    setTimeout(() => {
      setDropdownIsActive(false);
    }, 200);
  };

  // * setup initial options
  useEffect(() => {
    if (!options) return;
    if (query.length) {
      setFilteredOptions(sortedOptions(options.filter((it) => it.label?.toLowerCase().includes(query.toLowerCase()))));
    } else {
      setFilteredOptions(sortedOptions(options));
    }
  }, [options]);

  // * watch for changes to `query` and update filteredOptions
  useEffect(() => {
    if (onChange) {
      onChange(query);
    }

    if (query.length) {
      setFilteredOptions(sortedOptions(options.filter((it) => it.label?.toLowerCase().includes(query.toLowerCase()))));
    } else {
      setFilteredOptions(sortedOptions(options));
    }
  }, [query]);

  // * handle opening/closing of dropdown
  useEffect(() => {
    if (query.length > 0 && !activeOption) {
      setDropdownIsActive(true);
    }
  }, [setDropdownIsActive, query, filteredOptions]);

  // * handle choosing option
  useEffect(() => {
    if (!activeOption) return;
    if (shouldClearOnSelect) {
      setQuery("");
    } else {
      setQuery(activeOption.label);
    }
    select(activeOption.value);
    setDropdownIsActive(false);
  }, [activeOption]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ marginBottom: 8 }}>
          <FormField style={{ marginRight: 0, position: "relative" }}>
            <TextInput
              disabled={disabled || false}
              name={name}
              value={query}
              onChange={(evt) => setQuery(evt.currentTarget.value)}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              placeholder={value ? "" : placeholder ?? "Start typing..."}
              data-test-id={testId}
              ref={ref}
            />
            {query.length === 0 && !dropdownIsActive && (
              <SelectedValueText
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  alignItems: "center",
                  left: "13px",
                }}
              >
                {value ?? ""}
              </SelectedValueText>
            )}
          </FormField>
        </div>

        {(((hideable ?? true) && (dropdownIsActive || dropdownItemsFocused > 0)) || !(hideable ?? true)) &&
          filteredOptions.length > 0 &&
          (absolute ? (
            <AbsoluteDropdownWrapper maxHeight={dropdownMaxHeight}>
              <ul data-test-id="autocomplete-dropdown">
                {filteredOptions.map((it, index) => (
                  <li key={index} style={{ width: "100%" }}>
                    <OptionButton
                      tabIndex={0}
                      type="button"
                      onFocus={() => incrementDropdownItemsFocused()}
                      onBlur={() => setTimeout(() => decrementDropdownItemsFocused(), 200)}
                      onClick={() => choose(it)}
                    >
                      {it.label}
                    </OptionButton>
                  </li>
                ))}
              </ul>
            </AbsoluteDropdownWrapper>
          ) : (
            <DropdownWrapper>
              <ul data-test-id="autocomplete-dropdown">
                {filteredOptions.map((it, index) => (
                  <li key={index} style={{ width: "100%" }}>
                    <OptionButton
                      type="button"
                      style={{ ...(it.value === "all" && { fontWeight: 600 }) }}
                      tabIndex={0}
                      onFocus={() => incrementDropdownItemsFocused()}
                      onBlur={() => setTimeout(() => decrementDropdownItemsFocused(), 200)}
                      onClick={() => choose(it)}
                    >
                      {it.label}
                    </OptionButton>
                  </li>
                ))}
              </ul>
            </DropdownWrapper>
          ))}
      </div>
    </>
  );
};
