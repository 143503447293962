import styled from "styled-components";
import Palette from "../../palette.json";

const CalendarWrapper = styled.div`
  .fc-event {
    margin-top: 4 px;
    padding: 2px 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  .fc-col-header-cell {
    font-weight: normal;
    padding: 4px 0;
  }
  th.fc-day-today {
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
  }
  .fc-button-primary {
    background-color: ${Palette.LightestGrey};
    color: black;
    border: none;
  }
  .fc-button-primary:hover,
  .fc-button-active {
    background-color: ${Palette.LighterGrey} !important;
    color: black !important;
    border: none;
  }
  .fc-daygrid-day-frame {
    min-height: 140px;
  }

  .fc-toolbar-chunk div {
    display: flex;
    align-items: center;

    h2 {
      font-size: 16px;
      padding-left: 16px;
    }
  }
`;

export default CalendarWrapper;