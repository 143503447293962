import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import dayjs from "dayjs";

import { TimesheetFields } from "types/admin/TimesheetFields";

import { DialogActions, DialogButton, DialogContent, DialogOnClosedEventT, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import Palette from "../../palette.json";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import styled from "styled-components";

import TimesheetDiscrepancyPreview from "./TimesheetDiscrepancyPreview";

import { TimeSheetDiscrepancyStatus } from "types/admin/globalTypes";
import { UpdateTimeSheetEntryDiscrepancyVariables } from "types/admin/UpdateTimeSheetEntryDiscrepancy";

import { useProject } from "hooks/params/useProject";
import { Button } from "@rmwc/button";

interface ParamsT {
  userId: string;
  activityId: string;
}

interface ActionItemPickerProps {
  type: string;
  defaultValue: any;
  label: string;
  onChange: any;
}

interface TimesheetActivityEditDialogProps {
  timesheets: TimesheetFields[];
  timesheetId?: string;
  onBack?: () => void;
  onSave: any;
  onDiscrepancyUpdate?: any;
}

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionItemPicker = styled(TextField)<React.PropsWithChildren<ActionItemPickerProps>>`
  margin-bottom: 16px;
`;

export const TimesheetActivityEditDialog: React.FC<TimesheetActivityEditDialogProps> = ({
  timesheets,
  timesheetId,
  onBack,
  onSave,
  onDiscrepancyUpdate,
}) => {
  const history = useHistory();
  const { activityId } = useParams<ParamsT>();
  const [timesheet] = timesheets.filter((activity) => activity.id === (timesheetId || activityId));
  const discrepancy = timesheet?.discrepancy;
  const [clockInDateTime, setClockInDateTime] = useState(timesheet?.fromDT);
  const [clockOutDateTime, setClockOutDateTime] = useState(timesheet?.toDT);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [discrepancyStatus, setDiscrepancyStatus] = useState<TimeSheetDiscrepancyStatus | null | undefined>();
  const [adminMessage, setAdminMessage] = useState("");

  const onClose = async () => {
    history.goBack();
  };

  const cancel = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  const save = () => {
    if (!timesheet) return;

    onSave({
      id: timesheet.id,
      userId: timesheet?.userId,
      input: {
        userId: timesheet?.userId,
        projectId: timesheet.projectId,
        taskId: timesheet.taskId,
        fromDT: new Date(clockInDateTime),
        toDT: new Date(clockOutDateTime),
      },
    });

    if (
      onDiscrepancyUpdate &&
      discrepancy &&
      discrepancyStatus != null &&
      discrepancyStatus !== TimeSheetDiscrepancyStatus.Pending
    ) {
      const updatedDiscrepancy = {
        id: timesheet.id,
        userId: timesheet?.user?.id,
        input: {
          discrepancy: {
            status: discrepancyStatus,
          },
        },
      } as UpdateTimeSheetEntryDiscrepancyVariables;

      if (adminMessage) {
        updatedDiscrepancy.input.discrepancy.adminMessage = adminMessage;
      }
      onDiscrepancyUpdate(updatedDiscrepancy);
      cancel();
    } else {
      cancel();
    }
  };

  const onClockInUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockInDateTime(datetime);
    }
  };

  const onClockOutUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockOutDateTime(datetime);
    }
  };

  const onUpdateAdminMessage = (value: any) => {
    const message = value.currentTarget.value;
    setAdminMessage(message);
  };

  const onDiscrepancyApprove = () => {
    setDiscrepancyStatus(TimeSheetDiscrepancyStatus.Approved);
  };

  const onDiscrepancyDeny = () => {
    setDiscrepancyStatus(TimeSheetDiscrepancyStatus.Denied);
  };

  const dateFieldsAreDisabled = () => {
    if (discrepancy?.status === TimeSheetDiscrepancyStatus.Pending) {
      return discrepancyStatus !== TimeSheetDiscrepancyStatus.Approved;
    }
    return false;
  };

  useEffect(() => {
    // * if there's a discrepancy, check if status is denied and admin message has a value
    if (discrepancy) {
      if (discrepancyStatus === TimeSheetDiscrepancyStatus.Denied && adminMessage !== "") {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    }

    // * if clock in and clock out datetimes have been changed, save button is enabled
    if (clockInDateTime !== timesheet?.fromDT || clockOutDateTime !== timesheet?.toDT) {
      setSaveButtonDisabled(false);
    }

    // * if clock in time is greater than clock out time, save button is disabled
    if (new Date(clockInDateTime) > new Date(clockOutDateTime)) {
      setSaveButtonDisabled(true);
    }
  }, [clockInDateTime, clockOutDateTime, adminMessage, discrepancy]);

  return (
    <Dialog open preventOutsideDismiss renderToPortal={!!timesheetId}>
      <DialogTitle>Editing timesheet hours</DialogTitle>

      <DialogContent>
        {typeof timesheet !== "undefined" && (
          <div>
            {discrepancy && discrepancy?.status === TimeSheetDiscrepancyStatus.Pending && (
              <TimesheetDiscrepancyPreview
                timesheet={timesheet}
                deny={onDiscrepancyDeny}
                approve={onDiscrepancyApprove}
                status={discrepancyStatus}
              />
            )}

            <div
              style={{
                opacity: dateFieldsAreDisabled() ? 0.5 : 1,
                pointerEvents: dateFieldsAreDisabled() ? "none" : "all",
              }}
            >
              <ActionsWrapper>
                <ActionItemPicker
                  type="datetime-local"
                  data-test-id="clock-in"
                  defaultValue={dayjs(clockInDateTime).format("YYYY-MM-DDTHH:mm:ss")}
                  label={"Clock in"}
                  onChange={onClockInUpdate}
                />

                <ActionItemPicker
                  type="datetime-local"
                  data-test-id="clock-out"
                  defaultValue={dayjs(clockOutDateTime).format("YYYY-MM-DDTHH:mm:ss")}
                  label={"Clock out"}
                  onChange={onClockOutUpdate}
                />
              </ActionsWrapper>
            </div>
            {discrepancyStatus === TimeSheetDiscrepancyStatus.Denied && (
              <ActionsWrapper>
                <ActionItemPicker
                  type="text"
                  defaultValue=""
                  label={"Message to technician"}
                  onChange={onUpdateAdminMessage}
                />
              </ActionsWrapper>
            )}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton danger onClick={cancel}>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        <ThemeProvider options={{ primary: Palette.Green }}>
          <DialogButton
            isDefaultAction
            raised
            onClick={save}
            disabled={saveButtonDisabled}
            data-test-id="btn-activity-save"
          >
            Save
          </DialogButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
