import React from "react";
import dayjs from "dayjs";

import {
  DiscrepancyIndicator,
  DiscrepancyHeader,
  DiscrepancyWrapper,
  DiscrepancyDetails,
  DiscrepancyDescription,
  DiscrepancyDates,
  DiscrepancyDate,
  DiscrepancyActions,
} from "./TimesheetStyles";

import { Button } from "../Button";
import { TimeSheetDiscrepancyStatus } from "types/admin/globalTypes";

interface TimesheetDiscrepancyPreviewProps {
  timesheet: any;
  deny: any;
  approve: any;
  status: TimeSheetDiscrepancyStatus | null | undefined;
}

const TimesheetDiscrepancyPreview: React.FC<TimesheetDiscrepancyPreviewProps> = function ({
  timesheet,
  deny,
  approve,
  status,
}) {
  const discrepancy = timesheet?.discrepancy;

  const fromDTCorrectedFormatted = discrepancy?.fromDTCorrected
    ? dayjs(discrepancy?.fromDTCorrected).format("MM/DD/YYYY hh:mm A")
    : null;
  const toDTCorrectedFormatted = discrepancy?.toDTCorrected
    ? dayjs(discrepancy?.toDTCorrected).format("MM/DD/YYYY hh:mm A")
    : null;

  return (
    <div>
      <DiscrepancyWrapper>
        <DiscrepancyHeader>
          <DiscrepancyIndicator />
          <h3>
            Discrepancy by {timesheet?.user?.firstName} {timesheet?.user?.lastName}
          </h3>
        </DiscrepancyHeader>
        <DiscrepancyDetails>
          <DiscrepancyDates>
            {fromDTCorrectedFormatted && (
              <DiscrepancyDate>
                <span>Clock in:</span> <span>{fromDTCorrectedFormatted}</span>
              </DiscrepancyDate>
            )}
            {toDTCorrectedFormatted && (
              <DiscrepancyDate>
                <span>Clock out:</span> <span>{toDTCorrectedFormatted}</span>
              </DiscrepancyDate>
            )}
          </DiscrepancyDates>
          {discrepancy?.description && <DiscrepancyDescription>{discrepancy?.description}</DiscrepancyDescription>}
        </DiscrepancyDetails>
        <DiscrepancyActions>
          <Button unelevated={status === TimeSheetDiscrepancyStatus.Denied} onClick={deny}>
            Deny
          </Button>
          <Button unelevated={status === TimeSheetDiscrepancyStatus.Approved} onClick={approve}>
            Approve
          </Button>
        </DiscrepancyActions>
      </DiscrepancyWrapper>
    </div>
  );
};

export default TimesheetDiscrepancyPreview;
