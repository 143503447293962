import gql from "graphql-tag";
import { LocationFields } from "./locations";

export const ClientFields = gql`
  ${LocationFields}

  fragment ClientFields on Client {
    id
    clientType
    name
    address {
      city
      country
      state
      street1
      street2
      zip
    }
    locations {
      items {
        locationId
        location {
          ...LocationFields
        }
      }
    }
    settings {
      defaultPartMarkup
      defaultSubcontractorMarkup
      defaultLaborHourlyRate
      defaultBillingType
    }
  }
`;

export const listClientsQuery = gql`
  ${ClientFields}

  query ListClients {
    asAdmin {
      listClients {
        items {
          ...ClientFields
        }
      }
    }
  }
`;

export const getClientQuery = gql`
  ${ClientFields}

  query GetClient($id: ID!) {
    asAdmin {
      getClient(id: $id) {
        ...ClientFields
      }
    }
  }
`;

export const addClientMutation = gql`
  ${ClientFields}

  mutation AddClient($name: String = "", $address: ClientAddressInput, $clientType: ClientType = Company) {
    asAdmin {
      addClient(input: { clientType: $clientType, name: $name, address: $address }) {
        ...ClientFields
      }
    }
  }
`;

export const updateClientMutation = gql`
  ${ClientFields}

  mutation UpdateClient($id: ID!, $address: ClientAddressInput, $clientType: ClientType!, $name: String!) {
    asAdmin {
      updateClient(id: $id, input: { address: $address, clientType: $clientType, name: $name }) {
        ...ClientFields
      }
    }
  }
`;

export const deleteClientMutation = gql`
  mutation DeleteClient($id: ID!) {
    asAdmin {
      deleteClient(id: $id) {
        id
      }
    }
  }
`;

export const assignLocationToClientMutation = gql`
  ${LocationFields}

  mutation AssignLocationToClient($clientId: ID!, $locationId: ID!) {
    asAdmin {
      assignLocationToClient(clientId: $clientId, locationId: $locationId) {
        clientId
        locationId
        clientName
        location {
          ...LocationFields
        }
      }
    }
  }
`;

export const unassignLocationFromClientMutation = gql`
  mutation UnassignLocationFromClient($clientId: ID!, $locationId: ID!) {
    asAdmin {
      unassignLocationFromClient(clientId: $clientId, locationId: $locationId) {
        clientId
        locationId
      }
    }
  }
`;

export const updateClientSettingsMutation = gql`
  mutation UpdateClientSettings($clientId: ID!, $settings: ClientSettingsInput!) {
    asAdmin {
      setClientSettings(clientId: $clientId, settings: $settings) {
        defaultPartMarkup
        defaultSubcontractorMarkup
        defaultLaborHourlyRate
        defaultBillingType
      }
    }
  }
`;
