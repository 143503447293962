import React from "react";

import { useProject } from "hooks/params/useProject";

import { TaskList } from "components/tasks/TaskList";

import { CostsDiagramOverview } from "components/CostsOverviewDiagram";
import { ProjectTimelineCalendar } from "components/projects/ProjectTimelineCalendar";
import { PeopleProgressList } from "components/users/PeopleProgressList";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 25px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
`;

export const ProjectTasksPage: React.FC = () => {
  const { tasks: allTasks } = useAdminApi();

  const project = useProject();

  const tasks = allTasks.filter((it) => it.projectId === project?.id && it.parentId === project.id);

  if (!project) return <></>;

  return (
    <DashboardContainer>
      <ProjectTimelineCalendar />

      <div style={{ overflow: "auto" }}>
        <TaskList project={project} items={tasks} />
      </div>

      <div>
        <CostsDiagramOverview />
        <PeopleProgressList />
      </div>
    </DashboardContainer>
  );
};
