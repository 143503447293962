import React, { useState } from "react";

import { Controller, UseFormMethods, useFieldArray } from "react-hook-form";

import { MarkupType } from "types/admin/globalTypes";

import { TaskFormContentProps, TaskFormValuesT } from "./TaskFormDialog";

import { Button } from "../Button";
import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { FormField, FormRow, MultiItemSection, SectionTitle, AddItemContainer } from "../CommonForm";
import { Date } from "components/Form";

import { Switch } from "@rmwc/switch";
import "@rmwc/switch/styles";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";

import styled from "styled-components";
import useTranslations from "hooks/useTranslations";
import { useTask } from "hooks/params/useTask";
import dayjs from "dayjs";

const PartForm = styled.div`
  ${FormRow} {
    margin: 0;
  }
`;

export const ToolsFormFields: React.FC<{
  title: string;
  keyPrefix: "tools";
  labelPrefix: string;
  form: UseFormMethods<TaskFormValuesT>;
}> = ({ title, keyPrefix, labelPrefix, form }) => {
  const { task } = useTask();

  const { control, errors, register, setValue, watch } = form;
  const { fields, append, remove } = useFieldArray({ name: keyPrefix, control });
  const [displayedRows, setDisplayedRows] = useState<any[]>([]);
  const [justAdded, setJustAdded] = useState(false);

  const values = watch("tools");

  const markupTypeOpts: { label: string; value: string }[] = [];

  for (const [k, v] of Object.entries(MarkupType)) {
    markupTypeOpts.push({ label: k[0].toUpperCase() + k.slice(1), value: v });
  }

  const clickedCollapsibleList = (index: number) => {
    setJustAdded(false);
    if (displayedRows.includes(index)) {
      setDisplayedRows(displayedRows.filter((i) => i !== index));
    } else {
      setDisplayedRows([...displayedRows, index]);
    }
  };

  const collapseAll = () => {
    setDisplayedRows([]);
  };

  const onAddClick = () => {
    setJustAdded(true);
    collapseAll();
    append({ name: "", number: "", quantity: "1", cost: "0.00", isBillable: false, leadTimeDays: "1" });
  };

  const onRemoveClick = (index: number) => {
    remove(index);
    collapseAll();
    setJustAdded(false);
  };

  const onBillableChanged = (n: string, v: boolean, i: number) => {
    setValue(
      keyPrefix,
      fields.map(({ id, ...it }, j) => (i === j ? { ...(values?.[i] || it), isBillable: v } : it)),
    );
    fields[i].isBillable = v;
  };

  return (
    <MultiItemSection>
      <SectionTitle>{title}</SectionTitle>

      <div style={{ marginBottom: 24 }}>
        {fields.map((field, i) => (
          <PartForm key={field.id}>
            <CollapsibleList
              handle={
                <SimpleListItem
                  text={values[i]?.name}
                  metaIcon="chevron_right"
                  onClick={() => clickedCollapsibleList(i)}
                />
              }
              open={displayedRows.includes(i) || (i === fields.length - 1 && justAdded)}
            >
              <div style={{ padding: 16 }}>
                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.name`}>{labelPrefix} Name</Label>
                    <TextInput
                      name={`${keyPrefix}.${i}.name`}
                      disabled={!!task?.signingStatus}
                      isInvalid={`${keyPrefix}.${i}.name` in errors}
                      defaultValue={field.name}
                      ref={register({ required: { value: true, message: `Tool #${i + 1} name is required.` } })}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.number`}>{labelPrefix} Number</Label>
                    <TextInput
                      name={`${keyPrefix}.${i}.number`}
                      disabled={!!task?.signingStatus}
                      isInvalid={`${keyPrefix}.${i}.number` in errors}
                      defaultValue={field.number}
                      ref={register}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.quantity`}>{labelPrefix} Quantity</Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.quantity`}
                      ref={register({
                        required: { value: true, message: `Tool #${i + 1} quantity is required.` },
                        min: { value: 1, message: `Tool #${i + 1} quantity must be greater than 1.` },
                      })}
                      isInvalid={`${keyPrefix}.${i}.quantity` in errors}
                      disabled={!!task?.signingStatus}
                      defaultValue={field.quantity}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.cost`}>{labelPrefix} Cost</Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.cost`}
                      defaultValue={field.cost}
                      ref={register({
                        required: { value: true, message: `Tool #${i + 1} cost is required.` },
                        min: { value: 0, message: `Tool #${i + 1} cost must not be a negative number.` },
                      })}
                      disabled={!!task?.signingStatus}
                      isInvalid={`${keyPrefix}.${i}.cost` in errors}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.leadTimeDays`}>Lead Time (Days)</Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.leadTimeDays`}
                      defaultValue={field.leadTimeDays}
                      isInvalid={`${keyPrefix}.${i}.leadTimeDays` in errors}
                      disabled={!!task?.signingStatus}
                      ref={register({
                        required: { value: true, message: `Tool #${i + 1} lead time is required` },
                        min: {
                          value: 0,
                          message: `Tool #${i + 1} lead time must not be a negative number.`,
                        },
                      })}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.isBillable`}>Billable?</Label>
                    <Controller
                      {...{ control }}
                      name={`${keyPrefix}.${i}.isBillable`}
                      defaultValue={field.isBillable}
                      disabled={!!task?.signingStatus}
                      render={(f) => (
                        <Switch
                          defaultChecked={f.value}
                          onChange={() => onBillableChanged(`${keyPrefix}.${i}.isBillable`, !f.value, i)}
                        />
                      )}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <Date
                      name={`${keyPrefix}.${i}.expirationDate`}
                      label="Expiration Date"
                      defaultValue={
                        field.expirationDate ? dayjs(field.expirationDate).tz("UTC", true).format("YYYY-MM-DD") : null
                      }
                      required
                      disabled={!!task?.signingStatus}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.markupType`}>Markup Type</Label>
                    <Controller
                      {...{ control }}
                      name={`${keyPrefix}.${i}.markupType`}
                      defaultValue={MarkupType.percentage}
                      disabled={!values?.[i]?.isBillable || !!task?.signingStatus}
                      as={<Select outlined options={markupTypeOpts} />}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.markup`}>Markup {values?.[i]?.markupType}</Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.markup`}
                      defaultValue={field.markup}
                      disabled={!values?.[i]?.isBillable || !!task?.signingStatus}
                      isInvalid={`${keyPrefix}.${i}.markup` in errors}
                      ref={register({
                        required: { value: true, message: `Tool #${i + 1} markup value is required.` },
                        min: { value: 0, message: `Tool #${i + 1} markup value must not be a negative number.` },
                      })}
                    />
                  </FormField>
                </FormRow>

                <FormRow style={{ marginTop: 12, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ color: "red" }}
                    icon="remove_circle"
                    type="button"
                    disabled={!!task?.signingStatus}
                    onClick={() => onRemoveClick(i)}
                  >
                    Remove
                  </Button>
                </FormRow>
              </div>
            </CollapsibleList>
          </PartForm>
        ))}
      </div>

      <AddItemContainer>
        <Button icon="add_circle" type="button" disabled={!!task?.signingStatus} onClick={onAddClick}>
          Add {labelPrefix}
        </Button>
      </AddItemContainer>
    </MultiItemSection>
  );
};

export const TaskFormToolsContent: React.FC<TaskFormContentProps> = ({ form, style }) => {
  const { tCommon } = useTranslations();

  return (
    <div {...{ style }}>
      <ToolsFormFields {...{ form }} title={tCommon("Tools")} keyPrefix="tools" labelPrefix="Tool" />
    </div>
  );
};
