import { useAdminApi } from "hooks/useAdminApi";
import { ProjectFields } from "types/admin/ProjectFields";

type Props = {
  periodProjects: ProjectFields[];
  prevProjects: ProjectFields[];
};

export const useActiveUsers = ({ periodProjects, prevProjects }: Props) => {
  const { tasks } = useAdminApi();

  const processUsers = (projects: ProjectFields[]) => {
    const projectIds = projects.map((project) => project.id);
    let userIds = [];

    projects.forEach((project) => {
      const ids = project.users?.items?.map((user) => user.userId) || [];
      userIds = [...userIds, ...ids];
    });

    tasks.forEach((task) => {
      if (projectIds.includes(task.projectId)) {
        const ids = task.users?.items?.map((user) => user.userId) || [];
        userIds = [...userIds, ...ids];
      }
    });

    return [...new Set(userIds)]; //Remove duplicate userIds
  };

  const activeUserCount = processUsers(periodProjects)?.length || 0;
  const prevUserAccount = processUsers(prevProjects)?.length || 0;

  return { activeUserCount, prevUserAccount };
};
