/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BillingType {
  Fixed = "Fixed",
  TANDM = "TANDM",
}

export enum ClientType {
  Company = "Company",
  Individual = "Individual",
}

export enum EntityType {
  AIRCRAFT = "AIRCRAFT",
  MESSAGE = "MESSAGE",
  PART = "PART",
  TASK = "TASK",
  TENANT = "TENANT",
  USER = "USER",
}

export enum FileType {
  Document = "Document",
  Image = "Image",
  Video = "Video",
}

export enum MarkupType {
  dollar = "dollar",
  percentage = "percentage",
}

export enum NotificationEntityType {
  PART = "PART",
  PARTATTACHMENT = "PARTATTACHMENT",
  PROJECT = "PROJECT",
  PROJECTUSER = "PROJECTUSER",
  TASK = "TASK",
  TASKATTACHMENT = "TASKATTACHMENT",
  TASKMESSAGE = "TASKMESSAGE",
  TASKUSER = "TASKUSER",
  TIMESHEETENTRY = "TIMESHEETENTRY",
}

export enum NotificationEvent {
  INSERT = "INSERT",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
}

export enum PartEntityType {
  Aircraft = "Aircraft",
  Assembly = "Assembly",
  Part = "Part",
  Task = "Task",
  TaskTemplate = "TaskTemplate",
}

export enum PartStatus {
  Active = "Active",
  Completed = "Completed",
  Inactive = "Inactive",
  Overhaul = "Overhaul",
}

export enum PartType {
  Assembly = "Assembly",
  Consumable = "Consumable",
  Expendable = "Expendable",
  Rotable = "Rotable",
}

export enum ProjectRole {
  customer = "customer",
  manager = "manager",
  technician = "technician",
  technicianInspector = "technicianInspector",
}

export enum ProjectStatus {
  Accepted = "Accepted",
  Completed = "Completed",
  Pending = "Pending",
}

export enum PulseReportTaskRating {
  Negative = "Negative",
  Neutral = "Neutral",
  Positive = "Positive",
}

export enum RequestTaskStatus {
  Grounding = "Grounding",
  Recommended = "Recommended",
  Regulatory = "Regulatory",
}

export enum SigningEntityType {
  TaskInspectionForm = "TaskInspectionForm",
  TaskInspectionFormStamp = "TaskInspectionFormStamp",
  TaskLogBookSticker = "TaskLogBookSticker",
}

export enum SigningStatus {
  AwaitingSigning = "AwaitingSigning",
  Signed = "Signed",
}

export enum SigningType {
  RII = "RII",
  Technician = "Technician",
  TechnicianAndInspector = "TechnicianAndInspector",
  TechnicianAndTechnician = "TechnicianAndTechnician",
}

export enum TaskFlag {
  OnHold = "OnHold",
  Warning = "Warning",
}

export enum TaskStatus {
  Completed = "Completed",
  Declined = "Declined",
  Deleted = "Deleted",
  InProgress = "InProgress",
  OnHold = "OnHold",
  Requested = "Requested",
  Upcoming = "Upcoming",
}

export enum TaskType {
  Scheduled = "Scheduled",
  Squat = "Squat",
}

export enum TemplateType {
  Project = "Project",
  Task = "Task",
}

export enum TimeSheetDiscrepancyStatus {
  Approved = "Approved",
  Denied = "Denied",
  Pending = "Pending",
}

export enum TimeSheetEntryStatus {
  Approved = "Approved",
  Completed = "Completed",
  InProgress = "InProgress",
}

export enum UserRole {
  Admin = "Admin",
  AdminManager = "AdminManager",
  User = "User",
}

export enum UserWorkPayType {
  Hourly = "Hourly",
  Salary = "Salary",
}

export interface AddressInput {
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export interface ApproveTimeSheetEntryRequest {
  status?: TimeSheetEntryStatus | null;
}

export interface AssignAssemblyInput {
  parentPartId: string;
  parentEntityType: PartEntityType;
  leadTimeDays: number;
  status?: PartStatus | null;
  serialNumber?: string | null;
}

export interface AssignPartInput {
  parentPartId: string;
  parentEntityType: PartEntityType;
  status?: PartStatus | null;
  serialNumber?: string | null;
  cost: number;
  leadTimeDays: number;
  shippingTimeDays?: number | null;
  quantity?: number | null;
  isBillable: boolean;
  markupType: MarkupType;
  markup?: number | null;
  markupCost?: number | null;
  manufactureDate?: any | null;
  expirationDate?: any | null;
  hoursSinceOverhaul?: number | null;
  hoursSinceBrush?: number | null;
  hoursSinceBearing?: number | null;
}

export interface ClientAddressInput {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
}

export interface ClientSettingsInput {
  defaultPartMarkup?: number | null;
  defaultSubcontractorMarkup?: number | null;
  defaultLaborHourlyRate?: number | null;
  defaultBillingType?: BillingType | null;
}

export interface CreateAircraftRequest {
  make: string;
  model: string;
  clientId: string;
  tailNumber?: string | null;
  serialNumber?: string | null;
  airframeCycles?: number | null;
  airframeHours?: number | null;
  engine1TotalTime?: number | null;
  engine1Cycles?: number | null;
  engine2TotalTime?: number | null;
  engine2Cycles?: number | null;
  APUHobbs?: number | null;
}

export interface CreateAttachmentRequest {
  id: string;
  file: S3ObjectInput;
  fileType?: FileType | null;
  fileName?: string | null;
  mimeType?: string | null;
  entityType: EntityType;
  entityId: string;
}

export interface CreateCorrectiveActionRequest {
  taskId: string;
  projectId: string;
  message: string;
}

export interface CreateLocationRequest {
  name: string;
  address: AddressInput;
  geometry: GeometryInput;
}

export interface CreateProjectRequest {
  name: string;
  clientId: string;
  description?: string | null;
  aircraftId?: string | null;
  cameraId?: string | null;
  estimateManHrs?: number | null;
  estimatedCost?: number | null;
  hourlyRate: number;
  startDate?: any | null;
  endDate?: any | null;
  status: ProjectStatus;
  locationId?: string | null;
}

export interface CreateProjectTemplateRequest {
  name: string;
  description?: string | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
}

export interface CreateProjectUserRequest {
  clientId: string;
  projectId: string;
  userId: string;
  role: ProjectRole;
}

export interface CreateShiftRequest {
  ownerId: string;
  name?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  parentShiftId?: string | null;
}

export interface CreateSubcontractorRequest {
  name: string;
  cost?: number | null;
  description?: string | null;
  markupType?: string | null;
  markup?: number | null;
  markupCost?: number | null;
  subcontractorId?: string | null;
}

export interface CreateTaskRequest {
  name: string;
  parentId: string;
  projectId?: string | null;
  description?: string | null;
  taskNumber?: string | null;
  behindSchedule?: boolean | null;
  signingType?: SigningType | null;
  inReview?: boolean | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
  status?: TaskStatus | null;
  taskType?: TaskType | null;
  billing?: BillingType | null;
  amount?: number | null;
  dueDate?: any | null;
  scheduledDate?: any | null;
  orderIndex?: number | null;
  issues?: string[] | null;
  flag?: TaskFlag | null;
  partName?: string | null;
  partNumber?: string | null;
  toolName?: string | null;
  toolNumber?: string | null;
  tools?: ToolInput[] | null;
}

export interface CreateTaskTemplateRequest {
  name: string;
  description?: string | null;
  signingType?: SigningType | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
  copyAssignedUsers?: boolean | null;
}

export interface CreateTimeEntrySheetRequest {
  fromDT: any;
  toDT: any;
  projectId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  projectSummary?: string | null;
  taskSummary?: string | null;
  manuallyCreated?: boolean | null;
}

export interface CreateUserShiftRequest {
  userId: string;
  taskId: string;
  projectId: string;
  fromDt: any;
  toDt: any;
  force?: boolean | null;
}

export interface GeometryInput {
  type: string;
  coordinates: (number | null)[];
}

export interface GetPulseReportCriteria {
  userId?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface ListProjectsCriteria {
  clientId?: string | null;
}

export interface ListTasksCriteria {
  parentId?: string | null;
  status?: (TaskStatus | null)[] | null;
}

export interface LocationInfoInput {
  id: string;
  name: string;
}

export interface MessageCreateRequest {
  message?: string | null;
  taskId: string;
  projectId: string;
  isClientVisible?: boolean | null;
  isTechnicianVisible?: boolean | null;
}

export interface PartInput {
  name: string;
  status?: PartStatus | null;
  type: PartType;
  serialNumber?: string | null;
  manufactureDate?: any | null;
  expirationDate?: any | null;
  hoursSinceOverhaul?: number | null;
  hoursSinceBrush?: number | null;
  hoursSinceBearing?: number | null;
  number: string;
  cost?: number | null;
  leadTimeDays?: number | null;
  shippingTimeDays?: number | null;
  quantity?: number | null;
  isBillable?: boolean | null;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

export interface PayChexInput {
  id?: string | null;
  orgUnit?: string | null;
}

export interface S3ObjectInput {
  bucket: string;
  region: string;
  key: string;
}

export interface TemplatePartInput {
  name: string;
  number?: string | null;
  cost?: number | null;
  leadTimeDays?: number | null;
  shippingTimeDays?: number | null;
  quantity: number;
  isBillable: boolean;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

export interface TenantSettingsInput {
  name?: string | null;
  email?: any | null;
  phoneNumber?: string | null;
  address?: AddressInput | null;
  terms?: string | null;
  defaultPartMarkup?: number | null;
  defaultSubcontractorMarkup?: number | null;
  defaultLaborHourlyRate?: number | null;
  defaultBillingType?: BillingType | null;
  enablePassDownReport?: boolean | null;
  defaultSigningType?: SigningType | null;
}

export interface ToolInput {
  name: string;
  number?: string | null;
  cost?: number | null;
  leadTimeDays?: number | null;
  expirationDate?: any | null;
  quantity: number;
  isBillable: boolean;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

export interface UpdateAircraftRequest {
  make?: string | null;
  model?: string | null;
  clientId: string;
  tailNumber?: string | null;
  serialNumber?: string | null;
  airframeCycles?: number | null;
  airframeHours?: number | null;
  engine1TotalTime?: number | null;
  engine1Cycles?: number | null;
  engine2TotalTime?: number | null;
  engine2Cycles?: number | null;
  APUHobbs?: number | null;
}

export interface UpdateDiscrepancyRequest {
  status?: TimeSheetDiscrepancyStatus | null;
  adminMessage?: string | null;
}

export interface UpdateLocationRequest {
  name?: string | null;
  address?: AddressInput | null;
  geometry?: GeometryInput | null;
}

export interface UpdatePartInput {
  name: string;
  status?: PartStatus | null;
  serialNumber?: string | null;
  manufactureDate?: any | null;
  expirationDate?: any | null;
  hoursSinceOverhaul?: number | null;
  hoursSinceBrush?: number | null;
  hoursSinceBearing?: number | null;
  number: string;
  cost?: number | null;
  leadTimeDays?: number | null;
  shippingTimeDays?: number | null;
  quantity?: number | null;
  isBillable?: boolean | null;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

export interface UpdateProjectTemplateRequest {
  name?: string | null;
  description?: string | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
}

export interface UpdateShiftRequest {
  id: string;
  name?: string | null;
  startTime?: string | null;
  endTime?: string | null;
}

export interface UpdateTaskRequest {
  name?: string | null;
  taskNumber?: string | null;
  behindSchedule?: boolean | null;
  description?: string | null;
  inReview?: boolean | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
  status?: TaskStatus | null;
  taskType?: TaskType | null;
  billing?: BillingType | null;
  signingType?: SigningType | null;
  amount?: number | null;
  dueDate?: any | null;
  scheduledDate?: any | null;
  orderIndex?: number | null;
  flag?: TaskFlag | null;
  issues?: string[] | null;
  partName?: string | null;
  partNumber?: string | null;
  toolName?: string | null;
  toolNumber?: string | null;
  parts?: PartInput[] | null;
  tools?: ToolInput[] | null;
}

export interface UpdateTaskTemplateRequest {
  name?: string | null;
  description?: string | null;
  estimateManHrs?: number | null;
  signingType?: SigningType | null;
  hourlyRate?: number | null;
  billing?: BillingType | null;
  amount?: number | null;
  taskNumber?: string | null;
  parts?: TemplatePartInput[] | null;
  tools?: ToolInput[] | null;
}

export interface UpdateTimeSheetDiscrepancyRequest {
  discrepancy: UpdateDiscrepancyRequest;
}

export interface UpdateTimeSheetEntryRequest {
  userId?: string | null;
  projectId?: string | null;
  projectSummary?: string | null;
  taskId?: string | null;
  taskSummary?: string | null;
  fromDT?: any | null;
  toDT?: any | null;
  status?: TimeSheetEntryStatus | null;
}

export interface UpdateUserShiftRequest {
  fromDt?: any | null;
  toDt?: any | null;
  force?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
