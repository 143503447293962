import React, { useEffect, useState } from "react";
import { useProject } from "hooks/params/useProject";
import dayjs from "dayjs";
import Palette from "../palette.json";
import { StatusBlock, StatusBlockTitle, StatusBlockValue, StatusLabel } from "./StatusBlock";
import { ProjectStatus, TaskStatus } from "types/admin/globalTypes";
import { useAdminApi } from "hooks/useAdminApi";
import tinyColor from "tinycolor2";
import useMultiTenant from "hooks/useMultiTenant";
import styled from "styled-components";
import { Tooltip } from "@rmwc/tooltip";

const PredictedDate = styled.div<{ color: string }>`
  align-self: end;
  color: ${(props) => props.color};
  font-weight: 500;
  border-radius: 5px;
  padding: 3px 6px;
  background-color: ${(props) => tinyColor(props.color).lighten(44).toString()};
  transform: translateX(10px);
`;

enum DueDateStatus {
  Pending = "Pending",
  OnSchedule = "On Schedule",
  BehindSchedule = "Behind Schedule",
  Completed = "Completed",
}

export const StatusBlockDueDate: React.FC<{ predictedEndDate?: dayjs.Dayjs }> = ({ predictedEndDate }) => {
  // Hooks
  const project = useProject();
  const { tasks } = useAdminApi();

  // State
  const [theStatus, setTheStatus] = useState<DueDateStatus>();
  const [theStatusColor, setTheStatusColor] = useState<string>();

  // Variables
  const today = new Date();

  useEffect(() => {
    // mark as Completed
    if (project?.status === ProjectStatus.Completed) {
      setTheStatus(DueDateStatus.Completed);
      setTheStatusColor(Palette.Green);
      return;
    }

    if (project?.status === ProjectStatus.Pending) {
      setTheStatus(DueDateStatus.Pending);
      setTheStatusColor(Palette.Cyan);
      return;
    }

    // mark as an OnSchedule or BehindSchedule

    if (project?.endDate && today > new Date(project?.endDate)) {
      setTheStatus(DueDateStatus.BehindSchedule);
      setTheStatusColor(Palette.Red);
    } else {
      setTheStatus(DueDateStatus.OnSchedule);
      setTheStatusColor(Palette.Violet);
    }

    // check if all tasks in list are status = upcoming

    if (!tasks.find((it) => it.parentId === project?.id && it.status !== TaskStatus.Completed)) {
      setTheStatus(DueDateStatus.Completed);
      setTheStatusColor(Palette.Green);
    }
  }, [project, tasks]);

  if (!project) return <></>;

  const predictedEndDateColor = predictedEndDate?.isAfter(dayjs(project.endDate)) ? Palette.Red : Palette.Green;
  return (
    <StatusBlock style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <StatusBlockTitle>Due Date</StatusBlockTitle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {predictedEndDate && (
          <Tooltip content="Predicted End Date">
            <PredictedDate color={predictedEndDateColor}>{predictedEndDate.format("MM/DD")}</PredictedDate>
          </Tooltip>
        )}
        <StatusBlockValue>{dayjs(project.endDate).tz("UTC", true).format("MM/DD")}</StatusBlockValue>
      </div>
      <StatusLabel style={{ backgroundColor: theStatusColor }}>{theStatus}</StatusLabel>
    </StatusBlock>
  );
};
