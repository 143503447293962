import React, { useEffect } from "react";

import useLayout from "hooks/useLayout";

import { ScheduleChart } from "components/schedule/ScheduleChart";

import styled from "styled-components";

const Root = styled.div``;

export const BigScreenPage: React.FC = () => {
  const { setStandalone } = useLayout();

  useEffect(() => {
    if (!setStandalone) return;

    setStandalone(true);
    return () => setStandalone(false);
  }, [setStandalone]);

  return (
    <Root>
      <ScheduleChart />
    </Root>
  );
};
