import React, { useEffect } from "react";
import { Route } from "react-router";
import useLayout from "hooks/useLayout";
import { useAdminApi } from "hooks/useAdminApi";
import { PageHeader } from "components/PageHeader";
import { LocationFormDialog } from "components/locations/LocationFormDialog";
import { useHistory } from "react-router-dom";
import { LocationAssignClientListDialog } from "components/locations/LocationAssignClientListDialog";
import { LocationDetailsPageView } from "components/locations/LocationDetailsPageView";
import { useLocationItem } from "hooks/params/useLocationItem";

export const LocationDetailsPage: React.FC = () => {
  const history = useHistory();
  const { setBreadCrumbs } = useLayout();
  const location = useLocationItem();
  const { clients } = useAdminApi();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      { label: "Locations", path: "/locations" },
      {
        label: location?.name || "Details",
        path: `/locations/${location?.id}/details`,
      },
    ]);
  }, [setBreadCrumbs, location]);

  return (
    <>
      <Route exact path={["/locations/:locationId/details/edit"]}>
        <LocationFormDialog action="update" />
      </Route>

      <Route exact path={["/locations/:locationId/details/assign"]}>
        <LocationAssignClientListDialog clients={clients} />
      </Route>

      <LocationDetailsPageView />
    </>
  );
};
