import React, { useState } from "react";
import "@rmwc/data-table/styles";

import "@rmwc/circular-progress/styles";
import { ProjectFields } from "types/admin/ProjectFields";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import { useAdminApi } from "hooks/useAdminApi";
import { TaskFields } from "types/admin/TaskFields";
import styled from "styled-components";
import Palette from "../palette.json";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { getAvatarColor } from "../common";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { useFocusMode } from "hooks/useFocusMode";
import DisabledWrapper from "./DisabledWrapper";
import { TaskStatus } from "types/admin/globalTypes";

const AssignmentCell = styled.td`
  > div {
    display: flex;
    align-items: center;

    .mdc-icon-button {
      width: 24px;
      height: 24px;
      padding: 3px;
      color: ${Palette.LightGrey};
      font-size: 16px;
      border: 1px solid ${Palette.LightGrey};
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
`;

const CustomAvatarGroup = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
  padding: 0 4px;
`;

const CustomAvatar = styled.div<{ color: string; clockedIn?: "Here" | "Somewhere else"; size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color, clockedIn }) => (clockedIn === "Somewhere else" ? color : "white")};
  font-weight: 600;
  background-color: ${({ color, clockedIn }) =>
    clockedIn === "Here" ? color : clockedIn === "Somewhere else" ? "none" : Palette.LighterGrey};
  letter-spacing: 0;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 100%;
  font-size: 0.89em;
  font-family: Roboto;
  border: 2px solid ${({ color, clockedIn }) => (!!clockedIn ? color : Palette.LighterGrey)};

  transition-property: filter, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  -webkit-transition: filter, transform 250ms ease-in-out;
  -moz-transition: filter, transform 250ms ease-in-out;
  -ms-transition: filter, transform 250ms ease-in-out;
  -o-transition: filter, transform 250ms ease-in-out;

  :hover {
    filter: brightness(0.85);
    transform: scale(1.1);
  }
`;

interface TableAssignmentCellProps {
  project?: ProjectFields;
  task: TaskFields;
  urlSegment: string;
  onClickAvatar?: (userId: string, action: "Clock In" | "Clock Out") => Promise<void>;
}

export const TableAssignmentCell: React.FC<TableAssignmentCellProps> = ({ project, task, onClickAvatar }) => {
  const history = useHistory();
  const location = useLocation();
  const { inProgressTimesheets } = useLiveTimeSheets(project?.id, task?.id);
  const { users } = useAdminApi();
  const { focusModeIsEnabled } = useFocusMode();

  const [loadingUserIds, setLoadingUserIds] = useState<string[]>([]);

  const checkIfUserIsActive = (userId: string) => {
    const [exists] = inProgressTimesheets.filter((it) => {
      if (it.projectId !== project?.id) return;
      if (it.taskId !== task?.id) return;
      return it.userId === userId;
    });
    return !!exists;
  };

  const checkIfUserIsActiveOnAnotherTask = (userId: string) => {
    const [timesheet] = inProgressTimesheets.filter((it) => {
      if (!it.projectId) return;
      if (it.taskId === task?.id) return;
      return it.userId === userId;
    });
    return timesheet;
  };

  const initials = (name?: string) => {
    if (!name) return "?";

    const names = name.split(" ");
    if (names.length === 1) return names[0].charAt(0);
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };

  const clickAvatar = async (userId: string, userIsActive: boolean) => {
    setLoadingUserIds((prev) => [...prev, userId]);
    const action = userIsActive ? "Clock Out" : "Clock In";
    await onClickAvatar?.(userId, action);
    setLoadingUserIds((prev) => prev.filter((it) => it !== userId));
  };

  return (
    <AssignmentCell className="rmwc-data-table__cell">
      <div style={{ paddingTop: focusModeIsEnabled ? "12px" : "0", paddingBottom: focusModeIsEnabled ? "12px" : "0" }}>
        <DisabledWrapper enabled={task.status === TaskStatus.Completed}>
          <IconButton
            style={{ width: focusModeIsEnabled ? 40 : 26, height: focusModeIsEnabled ? 40 : 26 }}
            icon="add"
            onClick={() => history.push(`${location.pathname}/${task.id}/people/add_`)}
          />
        </DisabledWrapper>

        <CustomAvatarGroup>
          {task?.users?.items.map((it: { userId: string }) => {
            const userIsActiveOnThisTask = checkIfUserIsActive(it.userId);
            const userActiveOnAnotherTask = checkIfUserIsActiveOnAnotherTask(it.userId);
            const active = userIsActiveOnThisTask || !!userActiveOnAnotherTask;
            const color = getAvatarColor(it.userId);

            return (
              <DisabledWrapper key={it.userId} enabled={task.status === TaskStatus.Completed}>
                <CustomAvatar
                  onClick={() => clickAvatar(it.userId, userIsActiveOnThisTask)}
                  size={focusModeIsEnabled ? "40px" : "22px"}
                  color={color}
                  title={`${users.find((jt) => jt.id === it.userId)?.name || ""} - ${active ? `Active` : `Inactive`}${!!userActiveOnAnotherTask
                    ? " - " + userActiveOnAnotherTask.projectSummary + " - " + userActiveOnAnotherTask.taskSummary
                    : ""
                    }`}
                  clockedIn={userIsActiveOnThisTask ? "Here" : !!userActiveOnAnotherTask ? "Somewhere else" : undefined}
                >
                  {loadingUserIds.includes(it.userId) ? (
                    <LoadingIndicator theme="secondary" size={15} style={{ marginTop: "4px" }} />
                  ) : (
                    <p>{initials(users.find((jt) => jt.id === it.userId)?.name)}</p>
                  )}
                </CustomAvatar>
              </DisabledWrapper>
            );
          })}
        </CustomAvatarGroup>
      </div>
    </AssignmentCell>
  );
};
