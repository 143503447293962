import styled from "styled-components";
import Palette from "../palette.json";

import { StatusLabel as StatusLabelBase } from "./StatusLabel";

export const StatusBlock = styled.div<{ padding?: boolean }>`
  margin: 0 6px;
  ${(p) => (p.padding === false ? "" : "padding: 12px 8px;")}

  width: 152px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid #eaedf3;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }

  .mdc-linear-progress {
    margin-bottom: 16px;
  }

  a {
    flex-grow: 1;
    display: flex;
    margin: 8px;
    border: 1px solid ${Palette.DarkGrey};
  }

  img {
    flex-grow: 1;
    width: 100%;
    background-color: ${Palette.LightGrey};
  }
`;

export const StatusBlockTitle = styled.h5`
  margin: 4px 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${Palette.DarkGrey};
`;

export const StatusBlockValue = styled.h6`
  margin: 0 0 10px;
  font-size: 34px;
  font-weight: normal;
  color: ${Palette.DarkGrey};
`;

export const StatusLabel = styled(StatusLabelBase)`
  padding: 8px 16px;
  font-size: 12px;
  background-color: ${Palette.Red};
  text-align: center;
`;
