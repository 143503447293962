import React, { useState } from "react";

import { Controller, UseFormMethods, useFieldArray } from "react-hook-form";

import { BillingType, MarkupType } from "types/admin/globalTypes";

import { TaskFormContentProps, TaskFormValuesT } from "./TaskFormDialog";

import { Button } from "../Button";
import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { FormField, FormRow, MultiItemSection, SectionTitle, AddItemContainer } from "../CommonForm";

import "@rmwc/switch/styles";

import styled from "styled-components";
import { readableBillingType } from "../../common";
import { TextArea } from "../TextArea";

import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import { useAdminApi } from "hooks/useAdminApi";
import { useTask } from "hooks/params/useTask";
import { useSettings } from "hooks/useSettings";
import { useClient } from "hooks/params/useClient";

const PartForm = styled.div`
  ${FormRow} {
    margin: 0;
  }
`;

export const SubcontractorsFormFields: React.FC<{
  title: string;
  keyPrefix: "subcontractors";
  labelPrefix: string;
  form: UseFormMethods<TaskFormValuesT>;
}> = ({ title, keyPrefix, labelPrefix, form }) => {
  const { task } = useTask();
  const { settings } = useSettings();
  const { control, errors, register, setValue, watch } = form;
  const { fields, append, remove } = useFieldArray({ name: keyPrefix, control });
  const [displayedRows, setDisplayedRows] = useState<any[]>([]);
  const [justAdded, setJustAdded] = useState(false);

  const client = useClient();

  const markupTypeOpts: { label: string; value: string }[] = [];
  for (const [k, v] of Object.entries(MarkupType)) {
    markupTypeOpts.push({ label: k[0].toUpperCase() + k.slice(1), value: v });
  }

  const watchedItems = watch(keyPrefix);

  const clickedCollapsibleList = (index: number) => {
    setJustAdded(false);
    if (displayedRows.includes(index)) {
      setDisplayedRows(displayedRows.filter((i) => i !== index));
    } else {
      setDisplayedRows([...displayedRows, index]);
    }
  };

  const collapseAll = () => {
    setDisplayedRows([]);
  };

  // TODO
  const onAddClick = () => {
    setJustAdded(true);
    collapseAll();
    append({
      id: undefined,
      subcontractorId: undefined,
      name: "",
      cost: 0,
      description: "",
      markupType: MarkupType.percentage,
      markup: client?.settings?.defaultSubcontractorMarkup || settings.defaultSubcontractorMarkup,
    });
  };

  const onRemoveClick = async (index: number) => {
    collapseAll();
    setJustAdded(false);
    remove(index);
  };

  return (
    <MultiItemSection>
      <SectionTitle>{title}</SectionTitle>

      <div style={{ marginBottom: 24 }}>
        {fields.map((field, i) => (
          <PartForm key={`${field.id}-${i}`}>
            <input
              hidden
              name={`${keyPrefix}.${i}.subcontractorId`}
              defaultValue={field.subcontractorId}
              ref={register({ required: true })}
            />

            <CollapsibleList
              handle={
                <SimpleListItem
                  text={watchedItems[i]?.name}
                  metaIcon="chevron_right"
                  onClick={() => clickedCollapsibleList(i)}
                />
              }
              open={displayedRows.includes(i) || (i === fields.length - 1 && justAdded)}
            >
              <div style={{ padding: 16 }}>
                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.name`}>Name</Label>
                    <TextInput
                      name={`${keyPrefix}.${i}.name`}
                      isInvalid={`${keyPrefix}.${i}.name` in errors}
                      defaultValue={field.name}
                      disabled={!!task?.signingStatus}
                      ref={register({
                        required: { value: true, message: `Subcontractor #${i + 1} name is required.` },
                      })}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.cost`}>Cost</Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.cost`}
                      defaultValue={field.cost}
                      disabled={!!task?.signingStatus}
                      ref={register({
                        required: { value: true, message: `Subcontractor #${i + 1} cost is required.` },
                        min: { value: 0, message: `Subcontractor #${i + 1} cost must not be a negative number.` },
                      })}
                      isInvalid={`${keyPrefix}.${i}.cost` in errors}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField style={{ flexGrow: 1 }}>
                    <Label htmlFor={`${keyPrefix}.${i}.description`}>Description</Label>
                    <TextArea
                      name={`${keyPrefix}.${i}.description`}
                      disabled={!!task?.signingStatus}
                      ref={register({ required: false })}
                      defaultValue={field.description}
                      style={{ marginBottom: 0 }}
                    />
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.markupType`}>Markup Type</Label>
                    <Controller
                      {...{ control }}
                      name={`${keyPrefix}.${i}.markupType`}
                      defaultValue={field.markupType || MarkupType.percentage}
                      disabled={!!task?.signingStatus}
                      as={<Select outlined options={markupTypeOpts} />}
                    />
                  </FormField>

                  <FormField>
                    <Label htmlFor={`${keyPrefix}.${i}.markup`}>
                      Markup {field.markupType || MarkupType.percentage}
                    </Label>
                    <TextInput
                      type="number"
                      name={`${keyPrefix}.${i}.markup`}
                      defaultValue={field.markup}
                      isInvalid={`${keyPrefix}.${i}.markup` in errors}
                      disabled={!!task?.signingStatus}
                      ref={register({
                        required: { value: true, message: `Subcontractor #${i + 1} markup value is required.` },
                        min: {
                          value: 0,
                          message: `Subcontractor #${i + 1} markup value must not be a negative number.`,
                        },
                      })}
                    />
                  </FormField>
                </FormRow>

                <Button
                  style={{ float: "right", color: "red" }}
                  disabled={!!task?.signingStatus}
                  icon="remove_circle"
                  type="button"
                  onClick={() => onRemoveClick(i)}
                >
                  Remove
                </Button>
              </div>
            </CollapsibleList>
          </PartForm>
        ))}
      </div>

      <AddItemContainer>
        <Button icon="add_circle" type="button" disabled={!!task?.signingStatus} onClick={onAddClick}>
          Add {labelPrefix}
        </Button>
      </AddItemContainer>
    </MultiItemSection>
  );
};

export const TaskFormSubcontractorsContent: React.FC<TaskFormContentProps> = ({ form, style }) => {
  return (
    <div {...{ style }}>
      <SubcontractorsFormFields
        {...{ form }}
        title="Subcontractors"
        keyPrefix="subcontractors"
        labelPrefix="Subcontractor"
      />
    </div>
  );
};
