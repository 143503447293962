import React, { useEffect, useState } from "react";
import { TemplatePreview } from "../templates/TemplatePreview";

import { ProjectFields } from "types/admin/ProjectFields";
import { useAdminApi } from "hooks/useAdminApi";
import { TemplateType } from "types/admin/globalTypes";
import styled from "styled-components";
import { TemplateFields } from "types/admin/TemplateFields";
import { TextInput } from "../TextInput";
import { AutocompleteInput, SelectOptionT } from "../AutocompleteInput";
import { Link, useHistory } from "react-router-dom";

interface ProjectFormStepOneProps {
  project?: ProjectFields;
  setWhichTemplate: any;
  whichTemplate: any;
  shouldCopyAssignedUsers: boolean;
  setShouldCopyAssignedUsers: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
`;

export const ProjectFormStepOne: React.FC<ProjectFormStepOneProps> = ({
  project,
  setWhichTemplate,
  whichTemplate,
  shouldCopyAssignedUsers,
  setShouldCopyAssignedUsers,
}) => {
  const { templates } = useAdminApi();
  const history = useHistory();
  const [chooseTemplateOptions, setChooseTemplateOptions] = useState<SelectOptionT[]>([]);
  const [theTemplate, setTheTemplate] = useState<TemplateFields>();
  const [copyAssignedUsers, setCopyAssignedUsers] = useState(shouldCopyAssignedUsers);

  useEffect(() => {
    setChooseTemplateOptions(
      templates.filter((it) => it.type === TemplateType.Project).map((it) => ({ value: it.id, label: it.name })),
    );
  }, [templates]);

  useEffect(() => {
    const [it] = templates.filter((t) => whichTemplate === t.id);
    setTheTemplate(it);

    if (it?.id) {
      // add to query param while keeping others
      const search = new URLSearchParams(window.location.search);
      search.set("template", it.id);
      history.push({
        search: search.toString(),
      });
    }
  }, [whichTemplate]);

  useEffect(() => {
    setShouldCopyAssignedUsers(copyAssignedUsers);
  }, [copyAssignedUsers]);

  return (
    <Wrapper>
      {!theTemplate && (
        <>
          {chooseTemplateOptions.length > 0 ? (
            <>
              <h2 style={{ marginBottom: 4 }}>Choose a template</h2>
              <AutocompleteInput
                hideable={false}
                options={chooseTemplateOptions}
                select={(id) => setWhichTemplate(id)}
              />
            </>
          ) : (
            <>
              <h2 style={{ marginBottom: 4 }}>No templates found.</h2>
              <p>Get started by creating a template from an existing work order.</p>
            </>
          )}
        </>
      )}

      {theTemplate && (
        <>
          <h2 style={{ marginBottom: 8 }}>{theTemplate?.name}</h2>

          <TemplatePreview
            project={project}
            template={theTemplate}
            style={{ opacity: theTemplate ? 1 : 0 }}
            copyAssignedUsers={copyAssignedUsers}
            setCopyAssignedUsers={setCopyAssignedUsers}
          />
        </>
      )}
    </Wrapper>
  );
};
