import { Select as SelectBase, SelectHTMLProps, SelectProps } from "@rmwc/select";
import "@rmwc/select/styles";

import styled from "styled-components";

export const Select = styled(SelectBase)<SelectProps & SelectHTMLProps>`
  .mdc-select__anchor {
    height: auto;
    background-color: white !important;

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
  }

  .mdc-notched-outline {
    border: none !important;
    outline: none;
  }

  .mdc-select__dropdown-icon {
    bottom: calc(50% - 12px);
  }

  &.mdc-select--activated .mdc-select__dropdown-icon {
    transform: rotate(180deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-select__selected-text {
    padding: 8px 12px !important;
    height: auto;
    font-size: 14px;
    line-height: 14px;
    border: solid 1px #d8dce6;
    border-radius: 4px;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-list-item {
    padding: 8px 12px !important;
    height: auto;
    font-size: 14px !important;
    line-height: 14px;
  }
`;
