import React from "react";

import { Button } from "./Button";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";

interface CompleteProjectReportButtonProps {
  onClick: () => void;
}

export const CompleteProjectReportButton: React.FC<CompleteProjectReportButtonProps> = ({ onClick }) => {
  return (
    <Button raised onClick={onClick} data-test-id="btn-complete-project" style={{ padding: "10px", marginRight: 0 }}>
      <span style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="file_download" style={{ marginRight: 8, color: "white" }} />
        Download Report
      </span>
    </Button>
  );
};
