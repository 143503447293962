import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router";
import { snackbar } from "hooks/useNotifications";
import { dialogQueue } from "hooks/useDialogQueue";
import useLayout from "hooks/useLayout";
import { useAdminApi } from "hooks/useAdminApi";
import { useLocationItem } from "hooks/params/useLocationItem";
import { Button } from "../Button";
import { PageMain } from "../PageStyles";
import { MapView } from "../MapView";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { MainArea, LocationTools, LocationAddressItem, LocationInfo, LocationName } from "./LocationComponents";

export const LocationDetailsPageView: React.FC = () => {
  const history = useHistory();
  const { setBreadCrumbs } = useLayout();
  const { deleteLocation } = useAdminApi();
  const location = useLocationItem();

  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState<number>(8);

  useEffect(() => {
    if (!setBreadCrumbs) return;

    if (!location || !location.geometry) {
      setBreadCrumbs([{ label: "Locations", path: "/locations" }]);
      setCenter({ lat: 0, lng: 0 });
      setZoom(10);
      return;
    }

    const [lng, lat] = location.geometry?.coordinates;

    setBreadCrumbs([
      { label: "Locations", path: "/locations" },
      { label: location.name || `Location: ${location.id}`, path: `/locations/${location.id}` },
    ]);

    if (!lat || !lng) return;
    setCenter({ lat, lng });
    setZoom(15);
  }, [setBreadCrumbs, location]);

  const onDelete = async () => {
    if (!location) return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete location: ${location.name || location.id}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteLocation({ id: location.id });
    snackbar.notify({ title: "Location removed successfully!" });
    history.replace(`/locations/${location.id}/details`);
  };

  if (!location) return <Redirect to={`/locations`} />;

  return (
    <>
      <PageMain style={{ flexDirection: "row-reverse" }}>
        <MainArea>
          {!!location && (
            <LocationTools style={{ padding: "18px" }}>
              <Button
                data-test-id="btn-assign"
                raised
                onClick={() => history.push(`/locations/${location.id}/details/assign`)}
              >
                Assign to Clients
              </Button>

              <Button data-test-id="btn-delete" raised danger onClick={() => onDelete()}>
                Remove Location
              </Button>
            </LocationTools>
          )}

          <LocationInfo data-test-id="item-info">
            <LocationName>
              {location.name}
              <IconButton
                data-test-id="btn-update"
                icon="edit"
                onClick={() => history.push(`/locations/${location.id}/details/edit`)}
              />
            </LocationName>
            <LocationAddressItem>{location.address?.street1}</LocationAddressItem>
            <LocationAddressItem>{location.address?.street2}</LocationAddressItem>
            <LocationAddressItem>{location.address?.city}</LocationAddressItem>
            <LocationAddressItem>{location.address?.state}</LocationAddressItem>
            <LocationAddressItem>{location.address?.zip}</LocationAddressItem>
            <LocationAddressItem>{location.address?.country}</LocationAddressItem>
          </LocationInfo>

          <MapView center={center} zoom={zoom} items={[location]} />
        </MainArea>
      </PageMain>
    </>
  );
};
