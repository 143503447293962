import React, { useState, useMemo } from "react";

import { Table, TableContainer } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";
import { ClientFields } from "types/admin/ClientFields";
import { DialogActions, DialogButton, DialogContent, DialogTitle as DialogTitleBase } from "@rmwc/dialog";
import "@rmwc/data-table/styles";
import "@rmwc/list/styles";

import { TextInput } from "components/TextInput";
import { Dialog } from "../Dialog";
import { ListItemPrimaryText, ListItemText } from "@rmwc/list";
import { Button } from "components/Button";
import { useLocationItem } from "hooks/params/useLocationItem";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAdminApi } from "hooks/useAdminApi";

const DialogTitle = styled(DialogTitleBase)`
  display: flex;
  align-items: center;

  border: none;

  > span {
    margin: 20px 3rem 0 0;
    flex-grow: 1;
  }

  > button {
    margin: 20px 0 0;
  }
`;

const FilterControls = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: 1rem;
    :last-child {
      margin-right: 0;
    }
  }
`;

interface LocationAssignClientListDialogPropsT {
  clients: ClientFields[];
}

export const LocationAssignClientListDialog: React.FC<LocationAssignClientListDialogPropsT> = ({ clients }) => {
  const history = useHistory();
  const location = useLocationItem();
  const { assignLocationToClient, unassignLocationFromClient } = useAdminApi();
  const [searchString, setSearchString] = useState<string>("");

  const filtered = useMemo(
    () => clients.filter((it) => it.name.toLowerCase().includes(searchString.toLowerCase())),
    [searchString],
  );

  const onButtonClick = async (client: ClientFields, assigned: boolean) => {
    if (assigned) {
      await unassignLocationFromClient({ clientId: client.id, locationId: location.id });
      return;
    }

    await assignLocationToClient({ clientId: client.id, locationId: location.id });
  };

  return (
    <Dialog renderToPortal open>
      <DialogTitle>
        <span>Manage Clients</span>
      </DialogTitle>

      <DialogContent style={{ minWidth: "500px" }}>
        <FilterControls>
          <TextInput
            placeholder="Search by name."
            value={searchString}
            onChange={(ev) => setSearchString(ev.target.value)}
          />
        </FilterControls>

        <TableContainer>
          <Table>
            <DataTableContent>
              <DataTableBody>
                {!clients?.length ? (
                  <DataTableRow>
                    <DataTableCell
                      colSpan={3}
                      style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                    >
                      - No Clients -
                    </DataTableCell>
                  </DataTableRow>
                ) : (
                  <>
                    {filtered.map((client) => {
                      const assigned = !!location.clients?.items.find((it) => it.clientId === client.id);

                      return (
                        <DataTableRow key={client.id}>
                          <DataTableCell>
                            <ListItemText>
                              <ListItemPrimaryText style={{ marginTop: "-20px" }}>{client.name}</ListItemPrimaryText>
                            </ListItemText>
                          </DataTableCell>
                          <DataTableCell alignEnd>
                            <Button
                              style={{ width: "7rem" }}
                              outlined={!assigned}
                              raised={assigned}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                onButtonClick(client, assigned);
                              }}
                            >
                              {assigned ? "Assigned" : "Assign"}
                            </Button>
                          </DataTableCell>
                        </DataTableRow>
                      );
                    })}
                  </>
                )}

                <div style={{ height: "200px" }}></div>
              </DataTableBody>
            </DataTableContent>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <DialogButton onClick={history.goBack}>Close</DialogButton>
        <DialogButton raised isDefaultAction action="accept" onClick={history.goBack}>
          Done
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
