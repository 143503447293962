import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";
import { useAircraft } from "hooks/params/useAircraft";

import { snackbar } from "hooks/useNotifications";

import { Form, FormRow, Text, Number, Select } from "components/Form";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { useClient } from "hooks/params/useClient";
import { AddAircraftVariables } from "types/admin/AddAircraft";
import { UpdateAircraftVariables } from "types/admin/UpdateAircraft";
import useTranslations from "hooks/useTranslations";
import { BUILDING_TYPE_OPTIONS } from "../../common";

interface FormValuesT {
  id?: string;

  make: string;
  model: string;
  clientId: string;
  tailNumber?: string;
  serialNumber?: string;
  airframeCycles?: number;
  airframeHours?: number;
  engine1TotalTime?: number;
  engine1Cycles?: number;
  engine2TotalTime?: number;
  engine2Cycles?: number;
  APUHobbs?: number;
}

interface AircraftFormDialogProps {
  action: "create" | "update";
  renderToPortal?: boolean;
  clientId?: string;
  onClose?: (aircraftId?: string) => void;
}

const HIDDEN_FIELDS = ["clientId"];

export const AircraftFormDialog: React.FC<AircraftFormDialogProps> = ({
  action,
  renderToPortal,
  onClose: onTheClose,
  clientId: clientIdProp,
}) => {
  const history = useHistory();
  const { tCommon, tFeature, isAviation } = useTranslations();

  const { addEntity, updateEntity } = useAdminApi();
  const { entity: aircraft } = useAircraft();
  const client = useClient();

  const form = useForm<FormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      clientId: aircraft?.clientId || client?.id || clientIdProp || "",
      make: isAviation ? aircraft?.make || "" : "Industrial",
      model: aircraft?.model || aircraft?.type || "",
      serialNumber: aircraft?.serialNumber || "",
      tailNumber: aircraft?.tailNumber || "",
      airframeCycles: aircraft?.airframeCycles || 0,
      airframeHours: aircraft?.airframeHours || 0,
      engine1TotalTime: aircraft?.engine1TotalTime || 0,
      engine1Cycles: aircraft?.engine1Cycles || 0,
      engine2TotalTime: aircraft?.engine2TotalTime || 0,
      engine2Cycles: aircraft?.engine2Cycles || 0,
      APUHobbs: aircraft?.APUHobbs || 0,
    },
  });

  const { handleSubmit, register, setValue } = form;

  const title =
    action === "create" ? tFeature("AircraftFormDialog.createHeading") : tFeature("AircraftFormDialog.editHeading");

  useEffect(() => {
    if (!setValue || !aircraft) return;

    setValue("clientId", client?.id || clientIdProp);
    setValue("serialNumber", aircraft.serialNumber);
    setValue("tailNumber", aircraft.tailNumber);
    setValue("make", aircraft.make);
    setValue("model", aircraft.model || aircraft.type);
    setValue("airframeCycles", aircraft.airframeCycles || 0);
    setValue("airframeHours", aircraft.airframeHours || 0);
    setValue("engine1TotalTime", aircraft.engine1TotalTime || 0);
    setValue("engine1Cycles", aircraft.engine1Cycles || 0);
    setValue("engine2TotalTime", aircraft.engine2TotalTime || 0);
    setValue("engine2Cycles", aircraft.engine2Cycles || 0);
    setValue("APUHobbs", aircraft.APUHobbs || 0);
  }, [setValue, aircraft, client, clientIdProp]);

  const onSubmit = handleSubmit(async (values: FormValuesT) => {
    try {
      if (action === "create") {
        console.log({ input: values as unknown as AddAircraftVariables });
        const aircraft = await addEntity({
          input: values,
        });
        snackbar.notify({ title: tFeature("AircraftFormDialog.createSuccess") });

        if (aircraft) {
          if (onTheClose) {
            onTheClose(aircraft?.id);
          } else {
            history.replace(`/clients/${client?.id}/work-orders/new?aircraftId=${aircraft.id}`);
          }
        } else {
          history.goBack();
        }
      } else if (action === "update") {
        if (!aircraft) return;

        await updateEntity({
          id: aircraft.id,
          input: { ...(values as unknown as UpdateAircraftVariables), clientId: values.clientId },
        });
        snackbar.notify({ title: tFeature("AircraftFormDialog.editSuccess") });

        if (aircraft) {
          if (onTheClose) {
            onTheClose(aircraft?.id);
          }
        } else {
          history.goBack();
        }
      }
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
      return;
    }
  });

  const onClose = () => {
    if (onTheClose) {
      onTheClose();
      return;
    }
    history.goBack();
  };

  const validateInt = (value: number) => {
    return value >= 0 || `Cannot be negative`;
  };

  return (
    <Dialog open preventOutsideDismiss renderToPortal={renderToPortal}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit}>
          {HIDDEN_FIELDS.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register({ required: true })} />
          ))}
          <FormRow>
            {isAviation ? (
              <Text name="make" label={tCommon("Make")} required />
            ) : (
              <Select outlined name="make" label={tCommon("Make")} options={BUILDING_TYPE_OPTIONS} />
            )}
            <Text name="model" label={tCommon("Model")} required />
          </FormRow>

          <FormRow>
            <Text name="serialNumber" label={tCommon("SerialNumber")} required />
            <Text name="tailNumber" label={tCommon("TailNumber")} required />
          </FormRow>

          {isAviation && (
            <>
              <FormRow>
                <Number name="airframeCycles" label="Airframe Cycles" validate={validateInt} required />
                <Number name="airframeHours" label="Airframe Hours" validate={validateInt} required />
              </FormRow>

              <FormRow>
                <Number name="engine1TotalTime" label="Engine 1 Total Time" validate={validateInt} required />
                <Number name="engine1Cycles" label="Engine 1 Cycles" validate={validateInt} required />
              </FormRow>

              <FormRow>
                <Number name="engine2TotalTime" label="Engine 2 Total Time" validate={validateInt} required />
                <Number name="engine2Cycles" label="Engine 2 Cycles" validate={validateInt} required />
              </FormRow>

              <FormRow>
                <Number name="APUHobbs" label="APU Hobbs" validate={validateInt} required />
              </FormRow>
            </>
          )}
        </Form>
      </DialogContent>

      <DialogActions>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={onClose}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton onClick={onSubmit} raised>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
