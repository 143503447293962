import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";

import "@rmwc/icon-button/styles";
import useLayout from "hooks/useLayout";
import { PageHeader } from "components/PageHeader";
import { PageMain, PageContent } from "components/PageStyles";
import { usePart } from "hooks/params/usePart";
import * as Pages from "../../../../../../";
import { TabBar, Tab } from "@rmwc/tabs";
import { TabContainer } from "components/TabContainer";
import { DisplayFlex } from "components/Utilities";
import { AttachmentList } from "components/attachments/AttachmentList";
import { AttachmentFormDialog } from "components/attachments/AttachmentFormDialog";
import { Switch } from "react-router-dom";
import { PartsDetailView } from "components/parts/PartsDetailView";
import { PartsList } from "components/parts/PartsList";
import { PartFormDialog } from "components/parts/PartFormDialog";
import { PartSerialNumberFormDialog } from "components/parts/PartSerialNumberFormDialog";
import { PartSerialNumberAssignFormDialog } from "components/parts/PartSerialNumberAssignFormDialog";
import { useTask } from "hooks/params/useTask";
import { useProject } from "hooks/params/useProject";
import useTranslations from "hooks/useTranslations";
import styled from "styled-components";

const AttachmentsListWrapper = styled.div`
  > * + * {
    margin-top: 2rem;
  }
`;

export const TaskPartDetailPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const { currentPart, parentPart, part, partId, isRotable, isExpendable, isConsumable } = usePart();
  const project = useProject();
  const { task, getTask, taskId, parentId } = useTask();
  const location = useLocation();
  const { setBreadCrumbs } = useLayout();

  const resourcePrefix = `/work-orders/${project?.id}/tasks/${task?.id}/parts/${partId}`;
  const PAGE_TABS: { icon: string; label: string; path: string }[] = [];
  if (currentPart) {
    if (!isConsumable) {
      PAGE_TABS.push({
        icon: "construction",
        label: tCommon("Parts"),
        path: `${resourcePrefix}/parts`,
      });
    }

    PAGE_TABS.push({
      icon: "info",
      label: "Details",
      path: `${resourcePrefix}/details`,
    });

    PAGE_TABS.push({
      icon: "upload_file",
      label: "Attachments",
      path: `${resourcePrefix}/attachments`,
    });
  }

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (!taskId) return;
    getTask(taskId);
  }, [taskId]);

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!part || !project || !task) return;

    setBreadCrumbs([
      { label: "Work Orders", path: "/work-orders" },
      { label: project.name, path: `/work-orders/${project.id}` },
      { label: `${task.orderIndex} - ${task.name}`, path: `/work-orders/${project.id}/tasks/${task.id}/parts` },
      { label: part.name, path: `${resourcePrefix}/parts` },
    ]);
  }, [setBreadCrumbs, currentPart]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit")) return;
    history.replace(path);
  };

  if (!currentPart) return <Route component={Pages.Error404} />;

  return (
    <>
      <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts/new">
        {(isRotable || isExpendable) && currentPart.parentPartId ? (
          <PartSerialNumberAssignFormDialog onClose={() => history.goBack()} />
        ) : isRotable || isExpendable ? (
          <PartSerialNumberFormDialog onClose={() => history.goBack()} />
        ) : (
          <PartFormDialog action={"assign"} onClose={() => history.goBack()} />
        )}
      </Route>

      <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId/attachments/new">
        <AttachmentFormDialog action="create" />
      </Route>

      <PageHeader title={currentPart.name} description={currentPart.type} />

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              <DisplayFlex alignItems="center">
                <DisplayFlex>{it.label}</DisplayFlex>
              </DisplayFlex>
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            {!isConsumable && (
              <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId/parts">
                <PartsList />
              </Route>
            )}

            <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId/details">
              <PartsDetailView />
            </Route>

            <Route path="/work-orders/:projectId/tasks/:taskId/parts/:partId/attachments">
              <AttachmentsListWrapper>
                <AttachmentList type="task" items={currentPart.attachments?.items || []} />
                {!!parentPart?.attachments?.items?.length && (
                  <AttachmentList type="parentPart" items={parentPart?.attachments?.items || []} />
                )}
              </AttachmentsListWrapper>
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
