import useScreenDimmer from "hooks/useScreenDimmer";
import React from "react";
import styled from "styled-components";

const Overlay = styled.div<{ isDimmed: boolean }>`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: ${(props) => (props.isDimmed ? "0.5" : "0")};
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  pointer-events: ${(props) => (props.isDimmed ? "auto" : "none")};
`;

export const FullScreenDim = () => {
  const { isDimmed } = useScreenDimmer();

  return <Overlay isDimmed={isDimmed} />;
};

export default FullScreenDim;
