import React from "react";
import { DataTableCell } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { ProjectFields } from "types/admin/ProjectFields";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import { TaskFields } from "types/admin/TaskFields";
import styled from "styled-components";
import { PROJECT_STATUS_BACKGROUNDS, TASK_STATUS_BACKGROUNDS } from "./StatusLabel";
import { MiniProgress } from "components/MiniProgress";
import { TaskStatus } from "types/admin/globalTypes";
import LineThrough from "./LineThrough";

interface TableProgressCellProps {
  project?: ProjectFields;
  task?: TaskFields;
}

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  min-width: 56px;
`;

export const TableProgressCell: React.FC<TableProgressCellProps> = ({ project, task }) => {
  const { progress, isInProgress } = useLiveTimeSheets(project?.id, task?.id);

  const formattedProgress = Math.floor(progress * 100).toFixed(0);

  const color = task?.status
    ? TASK_STATUS_BACKGROUNDS[task.status]
    : project?.status
      ? PROJECT_STATUS_BACKGROUNDS[project.status]
      : "gray";

  return (
    <DataTableCell>
      <Inner>
        <LineThrough enabled={task?.status === TaskStatus.Completed}>
          <Text style={{ textAlign: "right", ...(isInProgress && { fontWeight: 800 }) }}>{formattedProgress}%</Text>
        </LineThrough>
        {progress > 0 && <MiniProgress progress={progress} isInProgress={isInProgress} color={color} />}
      </Inner>
    </DataTableCell>
  );
};
