import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { useAdminApi } from "hooks/useAdminApi";
import Palette from "../../palette.json";
import { TaskStatus } from "types/admin/globalTypes";

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding: 24px;
  background-color: #3e3f42;
  border-radius: 13px;
  color: #fff;
`;

const Intro = styled.div`
  max-width: 270px;
  margin-right: 90px;

  @media screen and (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  display: flex;

  @media screen and (max-width: 1600px) {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  margin-bottom: 12px;
  color: #9ea0a5;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`;

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;

  span {
    margin-bottom: 4px;
    display: block;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 10;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;

  > .rmwc-icon {
    padding: 4px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #fff;
  }
`;

const Steps = styled.div`
  display: flex;
`;

const StepInner = styled.div`
  display: flex;
  align-items: flex-start;

  ${SubTitle} {
    margin-bottom: 5px;
  }
`;

const StepDescription = styled.p`
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s ease;

  /* @media screen and (max-width: 1600px) {
    display: none;
  }  */
`;

const NumberWrapper = styled.div`
  margin-right: 20px;
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  display: flex;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  border-radius: 99999px;
  background-color: ${(p) => p.theme.primary};
  transition: all 0.3s ease;
`;

const Step = styled.button`
  max-width: 230px;
  padding: 0;
  margin: 0;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  text-align: left;
  cursor: pointer;
  font-family: "Roboto", sans-serif;

  &:not(:last-child) {
    margin-right: 75px;
  }

  @media screen and (max-width: 1600px) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &[data-disabled="true"] {
    pointer-events: none;

    ${Number} {
      background-color: #545557;
      color: #fff;
    }

    #{SubTitle},
    #{StepDescription} {
      color: #878b92 !important;
    }
  }
`;

interface ProjectChecklistStepProps {
  number: string;
  title: string;
  description: string;
  href?: string;
  disabled: boolean;
}

const ProjectChecklistStep: React.FC<ProjectChecklistStepProps> = ({ href, number, title, description, disabled }) => {
  const history = useHistory();

  return (
    <Step type="button" onClick={() => href && history.push(href)} data-disabled={disabled}>
      <StepInner>
        <NumberWrapper>
          <Number>{number}</Number>
        </NumberWrapper>
        <div>
          <SubTitle>{title}</SubTitle>
          <StepDescription>{description}</StepDescription>
        </div>
      </StepInner>
    </Step>
  );
};

export const ProjectChecklist: React.FC = ({}) => {
  const location = useLocation();
  const project = useProject();
  const { task: parentTask } = useTask();
  const { tasks: allTasks } = useAdminApi();
  const tasks = allTasks
    .filter((task) => task.projectId === project?.id)
    .filter((task) => task.status !== TaskStatus.Deleted);

  const displayedStorageKey = `FoxTrot.projectChecklist.displayed.${project?.id}`;
  const exploredStorageKey = `FoxTrot.projectChecklist.explored.${project?.id}`;

  const displayedFromStorage = localStorage.getItem(displayedStorageKey);
  const [displayed, setDisplayed] = useState(true);
  const users = project?.users?.items || [];
  const hasTasks = tasks.length > 0;
  const hasCustomers = users?.filter((user) => user.role === "customer").length > 0;
  const hasExplored = localStorage.getItem(exploredStorageKey) === "true";

  let percentComplete = "0%";
  const completedSteps = [hasTasks, hasCustomers, hasExplored];
  const completedStepsCount = completedSteps.filter((step) => step).length;
  const totalSteps = completedSteps.length;
  if (totalSteps > 0) {
    percentComplete = `${Math.round((completedStepsCount / totalSteps) * 100)}%`;
  }

  const close = () => {
    localStorage.setItem(displayedStorageKey, "false");
    setDisplayed(false);
  };

  const open = () => {
    localStorage.setItem(displayedStorageKey, "true");
    setDisplayed(true);
  };

  useEffect(() => {
    const checkedEstimate = location.pathname.includes("/estimate");
    const checkedCosts = location.pathname.includes("/costs");
    const checkedTimesheets = location.pathname.includes("/timesheets");
    const checkedTemplates = location.pathname.includes("/templates");

    if (checkedEstimate || checkedCosts || checkedTimesheets || checkedTemplates) {
      // if done with previous two steps and url changes, mark explored as true
      if (hasTasks && hasCustomers) {
        localStorage.setItem(exploredStorageKey, "true");
      }
    }
  }, [location]);

  useEffect(() => {
    if (displayedFromStorage === "true") {
      open();
    }
    if (displayedFromStorage === "false") {
      close();
    }
  }, []);

  useEffect(() => {
    if (hasExplored) {
      close();
    }
  }, [hasExplored]);

  if (!displayed) {
    return <></>;
  }

  return (
    <Wrapper>
      <Row>
        <Intro>
          <Title>Work Order Checklist</Title>
          <SubTitle>
            <span>Your work order is {percentComplete} ready.</span>
            <span>Complete these to get started.</span>
          </SubTitle>
        </Intro>
        <Steps>
          <ProjectChecklistStep
            disabled={hasTasks}
            number="1"
            title="Add Tasks"
            href={`/work-orders/${project?.id}/tasks/${parentTask ? parentTask.id + "/todos/" : ""}new`}
            description="You will add task details and assign technicians."
          />
          <ProjectChecklistStep
            disabled={hasCustomers}
            number="2"
            title="Add customers"
            href={`/work-orders/${project?.id}/people/add`}
            description="Add a few users who need visibility to the work order."
          />
          <ProjectChecklistStep
            disabled={hasExplored}
            number="3"
            title="Explore"
            description="Estimates, templates, time tracking, and more!"
          />
        </Steps>
      </Row>

      <CloseButton onClick={close}>
        <Icon icon="close" style={{ color: Palette.LightGrey }} />
        <span className="sr-only">Close</span>
      </CloseButton>
    </Wrapper>
  );
};
