import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";

import { TimesheetFields } from "types/admin/TimesheetFields";

import { useUser } from "hooks/params/useUser";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { Table, TableContainer, TableHead, DataCellTools } from "../Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import dayjs from "dayjs";
import numeral from "numeral";

import Palette from "../../palette.json";
import { readableTaskId } from "../../common";

import { Tooltip } from "@rmwc/tooltip";
import "@rmwc/tooltip/styles";

import { TimeSheetDiscrepancyStatus, TimeSheetEntryStatus } from "types/admin/globalTypes";
import useMultiTenant from "hooks/useMultiTenant";
import { ApproveTimeSheetEntryVariables } from "types/admin/ApproveTimeSheetEntry";
import { DisplayFlex } from "../Utilities";
import { snackbar } from "hooks/useNotifications";
import { Checkbox } from "@rmwc/checkbox";
import { useProject } from "hooks/params/useProject";
import { TimesheetActivityAddDialog } from "./TimesheetActivityAddDialog";
import { useTimeSheets } from "hooks/useTimeSheets";
import { CollapsibleList } from "@rmwc/list";
import { TimesheetHistory } from "../TimesheetHistory";
import { TimesheetActivityEditDialog } from "./TimesheetActivityEditDialog";
import { Icon } from "@rmwc/icon";

interface ParamsT {
  userId: string;
}

interface TimesheetReviewDialogProps {
  timesheets: TimesheetFields[];
  isLoading: boolean;
  onTimeSheetApprove: any;
  onTimeSheetsApprove: any;
  onDelete: any;
  toDate?: any;
  fromDate?: any;
}

export const TimesheetReviewDialog: React.FC<TimesheetReviewDialogProps> = ({
  timesheets,
  isLoading,
  onTimeSheetApprove,
  onTimeSheetsApprove,
  onDelete,
  toDate,
  fromDate,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { userId } = useParams<ParamsT>();
  const { projectId, taskId } = useParams<{ projectId?: string; taskId?: string }>();

  const { theme } = useMultiTenant();
  const user = useUser();
  const filtered = timesheets.filter((it) => it.userId === userId);
  const [assignments, setAssignments] = useState<any[]>([]);

  const { onTimeSheetAdd, onTimeSheetSave, onTimesheetDiscrepancyUpdate, setToDate, setFromDate } = useTimeSheets();
  const project = useProject();

  const [showEditTimesheetDialog, setShowEditTimesheetDialog] = useState(false);
  const [timesheetId, setTimesheetId] = useState<string>("");
  const [showAddTimesheetDialog, setShowAddTimesheetDialog] = useState(false);
  const [displayedRows, setDisplayedRows] = useState<string[]>([]);
  const columns = ["Date", ...(!project ? ["Location"] : []), ...(project ? ["Work Order"] : []), "Task", "Duration"];

  useEffect(() => {
    if (fromDate) {
      setFromDate(fromDate);
    }
    if (toDate) {
      setToDate(toDate);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    const assignments = [];
    for (const timesheet of filtered) {
      if (timesheet.status === TimeSheetEntryStatus.Approved) {
        assignments.push({
          timesheet,
          approved: true,
        });
      } else {
        assignments.push({
          timesheet,
          approved: false,
        });
      }
    }
    setAssignments(assignments);
  }, [timesheets]);

  const onClose = async () => {
    history.goBack();
  };

  const onConfirm = () => {
    onClose();
  };

  const approve = async (it: any) => {
    const updates: ApproveTimeSheetEntryVariables = {
      id: it.id,
      userId: it.userId,
      input: {
        status:
          it.status === TimeSheetEntryStatus.Approved ? TimeSheetEntryStatus.Completed : TimeSheetEntryStatus.Approved,
      },
    };
    onTimeSheetApprove(updates, it.discrepancy);
  };

  const saveChanges = async () => {
    const changes = assignments.filter((it) => {
      const wasApproved = it.timesheet.status === TimeSheetEntryStatus.Approved;
      const isApproved = it.approved;

      if (!wasApproved && isApproved) {
        return true;
      } else if (wasApproved && !isApproved) {
        return true;
      }
      return false;
    });

    const approvedIds = changes.filter((it) => it.approved).map((it) => it.timesheet.id);
    const unapprovedIds = changes.filter((it) => !it.approved).map((it) => it.timesheet.id);

    if (approvedIds.length > 0) {
      const updates = {
        ids: approvedIds,
        userId: userId,
        input: {
          status: TimeSheetEntryStatus.Approved,
        },
      };
      await onTimeSheetsApprove(updates);
    }

    if (unapprovedIds.length > 0) {
      const updates = {
        ids: unapprovedIds,
        userId: userId,
        input: {
          status: TimeSheetEntryStatus.Completed,
        },
      };
      await onTimeSheetsApprove(updates);
    }

    if (unapprovedIds.length > 0 || approvedIds.length > 0) {
      snackbar.notify({ title: "Timesheets updated", icon: "check" });
    }
  };

  const edit = (it: any) => {
    console.log("editing: ", `${match.url}/${it.id}/edit`);
    setTimesheetId(it.id);
    setShowEditTimesheetDialog(true);
  };

  const getDuration = (amountMins: number | null) => {
    if (!amountMins) return 0;

    if (amountMins < 60) {
      if (amountMins === 1) {
        return `${amountMins} minute`;
      } else {
        return `${amountMins} minutes`;
      }
    }
    return `${numeral(amountMins / 60).format("0.0")} hours`;
  };

  const clickedCollapsibleList = (id: string) => {
    if (displayedRows.includes(id)) {
      setDisplayedRows(displayedRows.filter((i) => i !== id));
    } else {
      openCollapsibleList(id);
    }
  };

  const openCollapsibleList = (id: string) => {
    if (!displayedRows.includes(id)) {
      const rowsInConflict = filtered.find((ts) => ts.id === id)?.conflictingTimesheets.items.map((it) => it.id);
      const rowsToDisplay = [...rowsInConflict].reduce((acc, curr) => {
        if (!displayedRows.includes(curr)) {
          acc.push(curr);
        }
        return acc;
      }, [] as string[]);
      setDisplayedRows([...displayedRows, ...rowsToDisplay, id]);
    }
  };

  return (
    <Dialog open preventOutsideDismiss>
      {showAddTimesheetDialog && (
        <TimesheetActivityAddDialog
          {...{ timesheets, isLoading, projectId, taskId }}
          onBack={() => {
            setShowAddTimesheetDialog(false);
          }}
          onSave={onTimeSheetAdd}
        />
      )}

      {showEditTimesheetDialog && timesheetId && (
        <TimesheetActivityEditDialog
          {...{ timesheets, isLoading, timesheetId }}
          onBack={() => {
            setTimesheetId("");
            setShowEditTimesheetDialog(false);
          }}
          onSave={onTimeSheetSave}
          onDiscrepancyUpdate={onTimesheetDiscrepancyUpdate}
        />
      )}

      <DialogTitle>{user?.name}</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table>
            <DataTableContent>
              <TableHead cols={columns} />

              <DataTableBody data-test-id="timesheet-review-list">
                {isLoading && (
                  <DataTableRow>
                    <DataTableCell colSpan={4}>
                      <CircularProgress />
                    </DataTableCell>
                  </DataTableRow>
                )}
                {filtered.map((it, i) => (
                  <div key={it.id} style={{ display: "contents" }}>
                    <DataTableRow>
                      <DataTableCell>{dayjs(it.fromDT).format("L")}</DataTableCell>
                      {!project && <DataTableCell>{it.startLocation?.name}</DataTableCell>}
                      {project && <DataTableCell>{it.projectSummary || readableTaskId(it.taskId)}</DataTableCell>}
                      <DataTableCell>{it.taskSummary || readableTaskId(it.taskId)}</DataTableCell>
                      <DataTableCell>
                        <DisplayFlex alignItems="center">
                          <DisplayFlex>
                            {it.status === TimeSheetEntryStatus.InProgress || !it.toDT
                              ? "In Progress"
                              : getDuration(it?.amountMins)}
                          </DisplayFlex>
                          {it.discrepancy && it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending && (
                            <Tooltip content="Discrepancy found">
                              <Icon
                                icon={{ icon: "warning", size: "xsmall" }}
                                onClick={() => edit(it)}
                                style={{
                                  color: "var(--mdc-theme-error)",
                                  marginLeft: "10px",
                                }}
                              />
                            </Tooltip>
                          )}

                          {!!it.conflictingTimesheets?.items?.length && (
                            <Tooltip content="Conflicting timesheets">
                              <Icon
                                icon={{ icon: "view_timeline", size: "xsmall" }}
                                onClick={() => openCollapsibleList(it.id)}
                                style={{
                                  color: "var(--mdc-theme-error)",
                                  marginLeft: "10px",
                                }}
                              />
                            </Tooltip>
                          )}

                          {it.autoClockOut && (
                            <Tooltip content="Clocked out automatically">
                              <Icon
                                icon={{ icon: "alarm_on", size: "xsmall" }}
                                onClick={() => edit(it)}
                                style={{
                                  color: Palette.Blue,
                                  marginLeft:
                                    it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending ? "6px" : "10px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </DisplayFlex>
                      </DataTableCell>
                      <DataTableCell>
                        <DataCellTools>
                          {it.id && it.history && (
                            <IconButton
                              icon="expand_more"
                              onClick={() => clickedCollapsibleList(it.id)}
                              style={{
                                color: theme.primary,
                                ...(displayedRows.includes(it.id) && { transform: "rotate(180deg)" }),
                                transitionProperty: "all",
                                transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                                transitionDuration: "300ms",
                              }}
                            />
                          )}
                          <Checkbox
                            checked={assignments.find((jt) => jt.timesheet.id === it.id)?.approved || false}
                            onClick={() => {
                              if (it.status === TimeSheetEntryStatus.InProgress) {
                                snackbar.notify({ title: "Timesheet is in progress", icon: "warning" });
                                return;
                              }

                              if (it.autoClockOut && it.history?.changes?.length <= 1) {
                                snackbar.notify({ title: "Handle auto clock out first", icon: "warning" });
                                return;
                              }

                              if (it.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending) {
                                snackbar.notify({ title: "Handle discrepancy first", icon: "warning" });
                                return;
                              }
                              const checked = assignments.find((jt) => jt.timesheet.id === it.id)?.approved || false;
                              if (checked) {
                                setAssignments([
                                  ...assignments.filter((jt) => jt.timesheet.id !== it.id),
                                  { timesheet: it, approved: false },
                                ]);
                              } else {
                                setAssignments([
                                  ...assignments.filter((jt) => jt.timesheet.id !== it.id),
                                  { timesheet: it, approved: true },
                                ]);
                              }
                            }}
                          />

                          <IconButton icon="edit" onClick={() => edit(it)} />
                          <IconButton icon="delete" onClick={() => onDelete(it)} />
                        </DataCellTools>
                      </DataTableCell>
                    </DataTableRow>
                    {it.id && it.history && (
                      <DataTableCell style={{ height: 0 }} colSpan={columns.length}>
                        <CollapsibleList
                          style={{ padding: "0 32px" }}
                          handle={<></>}
                          open={displayedRows.includes(it.id)}
                        >
                          <TimesheetHistory timesheetId={it.id} timesheet={it} history={it.history} />
                        </CollapsibleList>
                      </DataTableCell>
                    )}
                  </div>
                ))}
              </DataTableBody>
            </DataTableContent>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton danger onClick={onClose}>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        {/* <Checkbox style={{ marginRight: "20px", fontSize: "14px" }} label="Create Another" /> */}

        {!(projectId && !taskId) && (
          <DialogButton
            danger
            onClick={() => {
              setShowAddTimesheetDialog(true);
            }}
          >
            Add New
          </DialogButton>
        )}

        <DialogButton style={{ marginRight: "1rem" }} raised onClick={saveChanges}>
          Save All
        </DialogButton>

        <ThemeProvider options={{ primary: Palette.Green }}>
          <DialogButton isDefaultAction raised onClick={onConfirm}>
            Done
          </DialogButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
