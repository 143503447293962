import React, { useEffect, useState } from "react";

import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import { AutocompleteInput } from "../AutocompleteInput";
import { useSettings } from "hooks/useSettings";
import { snackbar } from "hooks/useNotifications";
import { useUser } from "hooks/params/useUser";
import { useParams } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogButton } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { twelveHourTimeFormat } from "common";
import Palette from "../../palette.json";
import { Icon } from "@rmwc/icon";

const Wrapper = styled.div`
  min-height: 200px;
  max-height: 400px;
  height: 100%;
`;

const SelectedShiftWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 24px;
  padding: 16px;
  border-radius: 10px;
  background-color: ${Palette.UltraLightGrey};
`;

const ShiftSpan = styled.span`
  font-size: 19px;
  color: black;
  font-weight: bold;
`;

const TimeSpan = styled.span`
  font-size: 14px;
  color: ${Palette.MediumGrey};
`;

const RemoveButton = styled(Icon)<{ [props: string]: any }>`
  color: black;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
`;

interface AddTenantShiftDialogProps {
  setShowAddShiftDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddTenantShiftDialog: React.FC<AddTenantShiftDialogProps> = ({ setShowAddShiftDialog }) => {
  const { addShifts, getUser } = useAdminApi();
  const user = useUser();
  const { userId } = useParams<{ userId: string }>();
  const { settings, fetchSettings } = useSettings();
  const [selectedShiftId, setSelectedShiftId] = useState<string | undefined>(undefined);

  const shiftOptions =
    settings.shifts?.items
      .filter((it) => !user.availableShifts?.items.find((jt) => jt.parentShift?.id === it.id))
      .filter((it) => it.id !== selectedShiftId)
      .map((it) => ({
        label: `${it.name} \u00a0\u00a0\u00a0\u00a0 ${twelveHourTimeFormat(it.startTime)} - ${twelveHourTimeFormat(
          it.endTime,
        )}`,
        value: it.id,
      })) || [];

  const onSubmit = async () => {
    if (!selectedShiftId) {
      snackbar.notify({ icon: "warning", title: "You must select a shift." });
      return;
    }

    try {
      await addShifts({ input: [{ ownerId: userId, parentShiftId: selectedShiftId }] });
    } catch (error) {
      snackbar.notify({ icon: "warning", title: error.message });
    }

    getUser(userId);
    setShowAddShiftDialog(false);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Dialog renderToPortal open preventOutsideDismiss>
      <DialogTitle>Assign Shift to {user.name}</DialogTitle>

      <DialogContent style={{ minWidth: "29vw", maxWidth: "100%" }}>
        <Wrapper>
          <AutocompleteInput
            testId="shift-search"
            hideable
            shouldClearOnSelect
            options={shiftOptions}
            select={(id) => setSelectedShiftId(id)}
          />

          {selectedShiftId &&
            (() => {
              const shift = settings.shifts?.items.find((it) => it.id === selectedShiftId);
              if (!shift) return <></>;
              return (
                <SelectedShiftWrapper>
                  <ShiftSpan>{shift.name}</ShiftSpan>
                  <TimeSpan>
                    {twelveHourTimeFormat(shift.startTime)} - {twelveHourTimeFormat(shift.endTime)}
                  </TimeSpan>

                  <RemoveButton
                    icon="close"
                    style={{ right: 10, top: 10 }}
                    onClick={() => setSelectedShiftId(undefined)}
                  />
                </SelectedShiftWrapper>
              );
            })()}
          {shiftOptions.length === 0 && !selectedShiftId && <div>No shifts to assign</div>}
        </Wrapper>
      </DialogContent>

      <DialogActions style={{ padding: "16px" }}>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={() => setShowAddShiftDialog(false)}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton raised disabled={!selectedShiftId} onClick={onSubmit} data-test-id="shift-assign-save-btn">
          Assign
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
