import React, { useState } from "react";

import { LocationFields } from "types/admin/LocationFields";

import { useLocationItem } from "hooks/params/useLocationItem";

import useSorted, { sortByProperty } from "hooks/useSorted";
import useFiltered from "hooks/useFiltered";

import { TextInput } from "../TextInput";

import { Table, TableContainer } from "components/Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow as DataTableRowBase } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import styled, { css } from "styled-components";

import Palette from "../../palette.json";

const DataTableRow = styled(DataTableRowBase)<{ isSelected?: boolean }>`
  border-bottom: 1px solid #dfdfdf;

  border-left: 4px solid white;

  ${(p) =>
    p.isSelected &&
    css`
      border-left: 4px solid ${(props) => props.theme.primary};
    `}
`.withComponent("tr");

const DataSearchCell = styled(DataTableCell)`
  margin: 0 !important;
  padding: 0 !important;

  background-color: white;

  display: flex;

  input {
    margin: 0;
    flex-grow: 1;
    border: none;
    border-radius: 0;
  }
`;

interface ClientLocationListProps {
  items: LocationFields[];

  onItemClick?: (item: LocationFields) => void;
}

export const ClientLocationList: React.FC<ClientLocationListProps> = ({ items, onItemClick }) => {
  const location = useLocationItem();

  const [search, setSearch] = useState<string>("");

  const sorted = useSorted(items, sortByProperty("name"));

  const filtered = useFiltered(sorted, (it) => (it.name || "").toLowerCase().includes(search.toLowerCase()));

  return (
    <TableContainer isVertical style={{ width: "360px" }}>
      <Table>
        <DataTableContent>
          <DataTableBody data-test-id="locations-list">
            <DataTableRow>
              <DataSearchCell>
                <TextInput placeholder="Search by Name" value={search} onChange={(ev) => setSearch(ev.target.value)} />
              </DataSearchCell>
            </DataTableRow>

            {filtered.length === 0 && (
              <DataTableRow style={{ backgroundColor: "#fbfbfd" }}>
                <DataTableCell
                  style={{
                    backgroundColor: "#fbfbfd",
                    color: Palette.LightGrey,
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {filtered.map((it, i) => (
              <DataTableRow key={i} isSelected={it.id === location?.id} onClick={() => onItemClick && onItemClick(it)}>
                <DataTableCell>{it.name}</DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
