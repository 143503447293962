import React from "react";
import { CSSProperties } from "styled-components";
import { useAdminApi } from "hooks/useAdminApi";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import { TemplateFields } from "types/admin/TemplateFields";
import { FormField, FormRow } from "../CommonForm";
import { Label } from "../Label";
import { TextInput } from "../TextInput";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

interface TemplatePreviewProps {
  project?: ProjectFields;
  template?: TemplateFields;
  style?: CSSProperties;
  copyAssignedUsers?: boolean;
  setCopyAssignedUsers: (copyAssignedUsers: boolean) => void;
}

export const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  project,
  template,
  style,
  copyAssignedUsers,
  setCopyAssignedUsers,
}) => {
  const { tasks: allTasks } = useAdminApi();

  let tasks: TaskFields[] = [];
  if (project) {
    tasks = allTasks.filter((it) => it.projectId === project?.id && it.parentId === project.id);
  } else if (template) {
    tasks = allTasks.filter((it) => it.parentId === template.id);
  }

  return (
    <div style={style}>
      <FormRow>
        <FormField style={{ flexGrow: 1 }}>
          <Label htmlFor="estimateManHrs">Estimated Hours</Label>
          <TextInput
            name="estimateManHrs"
            value={project?.estimateManHrs ?? template?.estimateManHrs ?? ""}
            disabled={true}
          />
        </FormField>
        <FormField style={{ flexGrow: 1 }}>
          <Label htmlFor="hourlyRate">Hourly Rate</Label>
          <TextInput name="hourlyRate" value={project?.hourlyRate ?? template?.hourlyRate ?? ""} disabled={true} />
        </FormField>
      </FormRow>

      <div style={{ marginTop: -12, marginBottom: 4 }}>
        <FormField style={{ flexGrow: 1, marginLeft: -8, marginTop: 8 }}>
          <Checkbox checked={copyAssignedUsers} onChange={(evt) => setCopyAssignedUsers(!!evt.currentTarget.checked)}>
            Copy assigned users
          </Checkbox>
        </FormField>
      </div>

      {tasks && tasks.length > 0 && (
        <div>
          <Label>Tasks</Label>
          <ul>
            {tasks.reverse().map((it: any) => (
              <div key={it?.id}>
                <h2 style={{ color: "#9ea0a5" }}>
                  {it?.orderIndex} - {it?.name}
                </h2>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
