import React from "react";

import { useTemplate } from "hooks/params/useTemplate";
import { TaskList } from "components/tasks/TaskList";
import { useAdminApi } from "hooks/useAdminApi";

export const TemplateTasksPage: React.FC = () => {
  const { tasks: allTasks } = useAdminApi();

  const { template } = useTemplate();
  const tasks = allTasks.filter((it) => it.parentId === template?.id);

  if (!template) return <></>;

  return (
    <>
      <TaskList items={tasks} />
    </>
  );
};
