import React, { useEffect, useState, useMemo } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";

import { Tooltip } from "@rmwc/tooltip";
import "@rmwc/tooltip/styles";

import "@rmwc/circular-progress/styles";
import "@rmwc/linear-progress/styles";

import useLayout from "hooks/useLayout";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { dialogQueue } from "hooks/useDialogQueue";
import { toast } from "react-hot-toast";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { TabContainer, TabBarRightActions } from "components/TabContainer";

import { TaskFormDialog } from "components/tasks/TaskFormDialog";
import { ProjectTemplateFormDialog } from "components/templates/ProjectTemplateFormDialog";

import { DiscrepancyIndicator } from "components/timesheets/TimesheetStyles";

import { ProjectTasksPage } from "./tasks";
import { ProjectPeoplePage } from "./people";
import { ProjectSchedulePage } from "./schedule";
import { ProjectTimesheetsPage } from "./timesheets";
import { ProjectCostsPage } from "./costs";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import { StatusBlockProgress } from "components/StatusBlockProgress";
import { StatusBlockDueDate } from "components/StatusBlockDueDate";
import { DisplayFlex } from "components/Utilities";
import { useTimeSheets } from "hooks/useTimeSheets";
import { CompleteProjectButton } from "components/CompleteProjectButton";
import { CompleteProjectReportButton } from "components/CompleteProjectReportButton";
import { snackbar } from "hooks/useNotifications";
import { ProjectEstimateFormDialog } from "components/projects/ProjectEstimateFormDialog";
import { ProjectStatus, TaskStatus } from "types/admin/globalTypes";
import CompleteProjectReportDialog from "components/CompleteProjectReportDialog";
import styled from "styled-components";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/icon-button/styles";
import { ProjectChecklist } from "components/projects/ProjectChecklist";
import * as Pages from "../../../pages";
import { Button } from "components/Button";
import { Icon } from "@rmwc/icon";
import { estimateTaskProgress } from "utilities/taskEstimator";
import useWorkOrderSummary from "hooks/useWorkOrderSummary";
import { showErrorMessage } from "utilities/handleError";
import { LoadingWrapper } from "components/projects/ProjectList";
import { LinearProgress } from "@rmwc/linear-progress";
import useScreenDimmer from "hooks/useScreenDimmer";
import { ProjectFormDialog } from "components/projects/ProjectFormDialog";
import { UserAssignDialog } from "components/users/UserAssignDialog";

const List = styled.ul`
  margin: 16px 0;
  list-style: disc inside none;
`;

const DesktopOnly = styled.div`
  @media (max-width: 1440px) {
    display: none;
  }
`;

const TabletOnly = styled.div`
  @media (min-width: 1441px) {
    display: none;
  }
`;

interface ParamsT {
  projectId: string;
}
export const ProjectViewPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { setIsDimmed } = useScreenDimmer();
  const { projectId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { clients, completeProject, getProjectAndListTasks } = useAdminApi();
  const project = useProject();

  const { tasks: allTasks } = useAdminApi();
  const tasks = allTasks
    .filter((it) => it.projectId === project?.id && it.parentId === project?.id)
    .filter((it) => it.status !== TaskStatus.Deleted);

  const client = clients.find((it) => it.id === project?.clientId);

  const [loadingCompleteProject, setLoadingCompleteProject] = useState<boolean>(false);
  const [generatingEstimate, setGeneratingEstimate] = useState<boolean>(false);
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);

  const { discrepancyFound, setFilterId, setFilterType } = useTimeSheets();

  const { generate } = useWorkOrderSummary();

  const downloadSummaryPDF = async (it: any) => {
    setDownloadingPDF(true);
    setIsDimmed(true);

    await toast.promise(generate(project), {
      loading: (
        <LoadingWrapper>
          <div>Downloading...</div>
          <LinearProgress />
        </LoadingWrapper>
      ),
      success: "Downloaded!",
      error: "Error downloading",
    });

    setDownloadingPDF(false);
    setIsDimmed(false);
  };

  const PAGE_TABS = [
    {
      icon: "list",
      label: "Overview",
      path: `/work-orders/${projectId}/tasks`,
    },
    {
      icon: "person",
      label: "People",
      path: `/work-orders/${projectId}/people`,
    },
    {
      icon: "group",
      label: "Schedule",
      path: `/work-orders/${projectId}/schedule`,
    },
    {
      icon: "today",
      label: "Time Tracking",
      path: `/work-orders/${projectId}/timesheets`,
    },
    {
      icon: "paid",
      label: "Costs",
      path: `/work-orders/${projectId}/costs`,
    },
  ];

  const DROPDOWN_BUTTON_MENU = [
    {
      label: "Task",
      icon: "add_circle",
      id: "new-task",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/new`);
      },
    },
    {
      label: project?.status === ProjectStatus.Completed ? "Download Report" : "Complete Work Order",
      icon: project?.status === ProjectStatus.Completed ? "file_download" : "done_outline",
      id: "complete-project",
      onClick: () => {
        if (project?.status === ProjectStatus.Completed) {
          setShowProjectFormDialog(true);
          return;
        }

        onCompleteProject(projectId);
      },
    },
    {
      label: "Estimate",
      icon: "file_download",
      id: "estimate",
      onClick: () => {
        history.push(`/work-orders/${projectId}/tasks/estimate`);
      },
    },
    {
      label: "Summary",
      icon: "file_download",
      id: "summary",
      onClick: () => downloadSummaryPDF(project),
    },
    {
      label: "Work Order Template",
      icon: "add_circle",
      id: "template",
      onClick: () => {
        history.push(`/work-orders/${projectId}/template`);
      },
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  const [showProjectFormDialog, setShowProjectFormDialog] = useState<boolean>(false);

  const onCompleteProject = async (projectId: string) => {
    const incompleteTasks = tasks.filter((it) => it.status !== TaskStatus.Completed);
    const ConfirmBody = () => {
      return (
        <div>
          <p>Are you sure you want to complete this work order?</p>
          <List>
            <li>All technicians will be unassigned from this work order</li>
            {incompleteTasks?.length > 0 && <li>There are {incompleteTasks.length} incomplete tasks</li>}
          </List>
        </div>
      );
    };

    const hasConfirmed = await dialogQueue.confirm({
      title: "Complete Work Order",
      body: ConfirmBody(),
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    setLoadingCompleteProject(true);

    try {
      await completeProject({ projectId });
      snackbar.notify({ title: "Work Order complete!" });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoadingCompleteProject(false);
    }
  };

  useEffect(() => {
    getProjectAndListTasks({
      projectId: projectId,
      criteria: { parentId: projectId },
    });
  }, []);

  const predictedEndDate = useMemo(() => {
    if (!project || !tasks) return;
    return estimateTaskProgress(project, tasks);
  }, [project?.id, tasks.length]);

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!project) return;

    setBreadCrumbs([
      {
        label: "Work Orders",
        path: "/work-orders",
      },
      {
        label: project?.name,
        path: `/work-orders/${projectId}`,
      },
    ]);
  }, [setBreadCrumbs, project, projectId]);

  useEffect(() => {
    if (!project) return;
    setFilterId(project?.id);
    setFilterType("project");
  }, [project]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit") || location.pathname.endsWith("/new"))
      return;
    history.replace(path);
  };

  if (!project) return <Route component={Pages.Error404} />;

  return (
    <>
      <Route exact path="/work-orders/:projectId/edit">
        <ProjectFormDialog action="update" />
      </Route>
      <Route exact path="/work-orders/:projectId/tasks/new">
        <TaskFormDialog action="create" />
      </Route>
      <Route exact path={`/work-orders/:projectId/tasks/:taskId/edit_`}>
        <TaskFormDialog action="update" />
      </Route>
      <Route exact path="/work-orders/:projectId/template">
        <ProjectTemplateFormDialog action="create" />
      </Route>
      <Route exact path="/work-orders/:projectId/tasks/estimate">
        <ProjectEstimateFormDialog setGeneratingEstimate={setGeneratingEstimate} items={tasks} />
      </Route>
      <Route exact path="/work-orders/:projectId/tasks/:taskId/people/add_">
        <UserAssignDialog />
      </Route>

      <PageHeader
        compact
        editable
        title={project.name}
        subtitle={client?.name}
        description={project.description}
        onEditClick={() => history.push(`/work-orders/${projectId}/edit`)}
        onSubtitleClick={() => history.push(`/clients/${client?.id}`)}
      >
        <StatusBlockDueDate predictedEndDate={predictedEndDate} />
        <StatusBlockProgress />
      </PageHeader>
      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              height: "100%",
            }}
          >
            <SimpleMenu
              renderToPortal
              anchorCorner="bottomStart"
              focusOnOpen={false}
              handle={
                <Button
                  data-test-id="new-button"
                  outlined
                  trailingIcon="unfold_more"
                  style={{
                    height: "48px",
                    padding: "0 16px",
                    marginBottom: "8px",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Icon icon="library_add" style={{ color: "gray" }} />
                    <div style={{ marginLeft: "8px" }}>New</div>
                  </span>
                </Button>
              }
            >
              {DROPDOWN_BUTTON_MENU.map((it, i) => {
                return it.id === "complete-project" ? (
                  <TabletOnly key={i}>
                    <MenuItem
                      disabled={downloadingPDF}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        it.onClick();
                      }}
                    >
                      <Icon icon={it.icon} style={{ marginRight: "8px", color: "black" }} />
                      {it.label}
                    </MenuItem>
                  </TabletOnly>
                ) : (
                  <MenuItem
                    key={i}
                    disabled={downloadingPDF}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      it.onClick();
                    }}
                  >
                    <Icon icon={it.icon} style={{ marginRight: "8px", color: "black" }} />
                    {it.label}
                  </MenuItem>
                );
              })}
            </SimpleMenu>
          </div>

          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              <DisplayFlex alignItems="center">
                <DisplayFlex>{it.label}</DisplayFlex>
                {it.label === "Time Tracking" && discrepancyFound && (
                  <Tooltip content="Discrepancy found">
                    <DiscrepancyIndicator style={{ marginLeft: "8px" }} />
                  </Tooltip>
                )}
              </DisplayFlex>
            </Tab>
          ))}
          <TabBarRightActions>
            <DesktopOnly>
              {project?.status !== ProjectStatus.Completed ? (
                <CompleteProjectButton loading={loadingCompleteProject} onClick={() => onCompleteProject(projectId)} />
              ) : (
                <>
                  {showProjectFormDialog && (
                    <CompleteProjectReportDialog onClose={() => setShowProjectFormDialog(false)} />
                  )}
                  <CompleteProjectReportButton onClick={() => setShowProjectFormDialog(true)} />
                </>
              )}
            </DesktopOnly>
          </TabBarRightActions>
        </TabBar>
      </TabContainer>
      <PageMain>
        <PageContent>
          <ProjectChecklist />

          <Switch>
            <Route path="/work-orders/:projectId/people" component={ProjectPeoplePage} />
            <Route path="/work-orders/:projectId/schedule" exact component={ProjectSchedulePage} />
            <Route path="/work-orders/:projectId/timesheets" component={ProjectTimesheetsPage} />
            <Route path="/work-orders/:projectId/costs" component={ProjectCostsPage} />
            <Route path={["/work-orders/:projectId", "/work-orders/:projectId/tasks"]} component={ProjectTasksPage} />
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
