import dayjs, { Dayjs } from "dayjs";
import React, { createContext, useContext, useState, useEffect } from "react";
import { TimesheetFields, TimesheetFields_discrepancy } from "types/admin/TimesheetFields";
import { useAdminApi } from "./useAdminApi";
import { snackbar } from "hooks/useNotifications";
import { dialogQueue } from "hooks/useDialogQueue";
import { TimeSheetEntryStatus, TimeSheetDiscrepancyStatus, CreateTimeEntrySheetRequest } from "types/admin/globalTypes";
import { ApproveTimeSheetEntryVariables } from "types/admin/ApproveTimeSheetEntry";
import { ApproveTimeSheetEntriesVariables } from "types/admin/ApproveTimeSheetEntries";
import { DeleteTimeSheetEntryVariables } from "types/admin/DeleteTimeSheetEntry";
import { UpdateTimeSheetEntryVariables } from "types/admin/UpdateTimeSheetEntry";
import { UpdateTimeSheetEntryDiscrepancyVariables } from "types/admin/UpdateTimeSheetEntryDiscrepancy";

interface TimeSheetsContextT {
  isLoading: boolean;
  fetchTimeSheets: (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => Promise<void>;
  timesheets: TimesheetFields[];
  allTimesheets: TimesheetFields[];
  mergedTimeSheets: TimesheetFields[];
  discrepancyFound: boolean;
  toDate: Dayjs;
  fromDate: Dayjs;
  setToDate: any;
  setFromDate: any;
  filterType: string;
  setFilterType: any;
  setFilterId: any;
  onTimeSheetApprove: any;
  onTimeSheetsApprove: any;
  onTimeSheetAdd: any;
  onTimeSheetDelete: any;
  onTimeSheetSave: any;
  onTimesheetDiscrepancyUpdate: any;
}

export const TimeSheetsContext = createContext({ isLoading: true } as TimeSheetsContextT);
export const useTimeSheets = () => useContext(TimeSheetsContext);

export const TimeSheetsProvider: React.FC = ({ children }) => {
  const [filterType, setFilterType] = useState("");
  const [filterId, setFilterId] = useState("");

  const {
    projects,
    tasks,
    updatedTimesheet,
    getProject,
    getTask,
    getTimesheets,
    addTimesheet,
    deleteTimeSheetEntry,
    updateTimeSheetEntry,
    updateTimeSheetEntryDiscrepancy,
    approveTimeSheetEntry,
    approveTimeSheetEntries,
  } = useAdminApi();

  const [timesheets, setTimesheets] = useState<TimesheetFields[]>([]);
  const [allTimesheets, setAllTimesheets] = useState<TimesheetFields[]>([]);
  const [mergedTimeSheets, setMergedTimeSheets] = useState<TimesheetFields[]>([]);

  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(dayjs().startOf("week"));
  const [toDate, setToDate] = useState<dayjs.Dayjs>(dayjs().endOf("week"));

  const [isLoading, setLoading] = useState<boolean>(true);
  const [discrepancyFound, setDiscrepancyFound] = useState(false);

  let filter: (it?: TimesheetFields) => boolean = () => true;
  if (filterType === "project") {
    filter = (timesheet?: TimesheetFields) => timesheet?.projectId === filterId;
  } else if (filterType === "task") {
    filter = (timesheet?: TimesheetFields) => timesheet?.taskId === filterId;
  } else if (filterType === "InHangar") {
    filter = (timesheet?: TimesheetFields) => timesheet?.taskId === filterId; //|| timesheet?.taskId === "Driving";
  }

  useEffect(() => {
    // Adding/ updating timesheets as they come in
    if (!updatedTimesheet) return;
    const { isDeleted, ...timesheet } = updatedTimesheet;
    const isNew = !allTimesheets.find((it) => it.id === timesheet?.id);

    if (!timesheet.projectSummary) {
      timesheet.projectSummary = projects.find((p) => p.id === timesheet.projectId)?.name || null;
    }

    if (!timesheet.taskSummary) {
      timesheet.taskSummary = tasks.find((t) => t.id === timesheet.taskId)?.name || null;
    }

    let updatedTimesheets = [...allTimesheets];

    if (isDeleted) {
      updatedTimesheets = updatedTimesheets.filter((it) => it.id !== timesheet.id);
    } else {
      updatedTimesheets = updatedTimesheets.map((ts) => (ts.id === timesheet?.id ? timesheet : ts));

      if (isNew) updatedTimesheets.push(timesheet);
    }

    setAllTimesheets(updatedTimesheets);
    setTimesheets(updatedTimesheets.filter(filter));
  }, [updatedTimesheet]);

  useEffect(() => {
    fetchTimeSheets(fromDate, toDate);
  }, [fromDate, toDate, projects, filterType, filterId]);

  useEffect(() => {
    const results: TimesheetFields[] = [];
    timesheets.forEach((it, index) => {
      const fIndex = results.findIndex((jt) => jt.userId === it.userId);

      if (it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending) {
        setDiscrepancyFound(true);
      }

      if (fIndex === -1) {
        results.push({ ...it });
      } else {
        const mergeWith: any = results[fIndex];
        mergeWith.amountMins = (mergeWith.amountMins || 0) + (it.amountMins || 0);

        if (it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending) {
          mergeWith.discrepancy = {};
          mergeWith.discrepancy.status = TimeSheetDiscrepancyStatus.Pending;
        }

        if (it?.status !== TimeSheetEntryStatus.Approved) {
          mergeWith.status = TimeSheetEntryStatus.Completed;
        }
      }
    });

    setMergedTimeSheets(results);

    return () => {
      setDiscrepancyFound(false);
    };
  }, [timesheets]);

  const fetchTimeSheets = async (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
    setLoading(true);
    setAllTimesheets([]);

    try {
      const fetchedTimesheets = await getTimesheets(fromDate, toDate);
      setAllTimesheets(fetchedTimesheets);
      setTimesheets(fetchedTimesheets.filter(filter));
    } catch (error) {
      console.log("🚨 Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const onWeekPrev = () => {
    setTimesheets([]);
    setFromDate((p) => p.subtract(1, "week"));
    setToDate((p) => p.subtract(1, "week"));
  };
  const onWeekNext = () => {
    setTimesheets([]);
    setFromDate((p) => p.add(1, "week"));
    setToDate((p) => p.add(1, "week"));
  };
  const gotoThisWeek = () => {
    setTimesheets([]);
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const onTimeSheetSave = async (input: UpdateTimeSheetEntryVariables) => {
    input.input.status = TimeSheetEntryStatus.Completed;
    const updatedTimesheet = await updateTimeSheetEntry(input);

    // * update ui with latest data
    const index = timesheets.findIndex((it) => it.id === updatedTimesheet.id);
    const updatedTimesheets = timesheets.filter((it) => it.id !== updatedTimesheet.id);
    updatedTimesheets.splice(index, 0, updatedTimesheet); //For inserting element at array index
    setTimesheets(updatedTimesheets.filter(filter));
    snackbar.notify({ title: "Timesheet activity updated successfully!" });
    fetchTimeSheets(fromDate, toDate);
  };

  const onTimeSheetAdd = async (input: CreateTimeEntrySheetRequest) => {
    const updatedTimesheet = await addTimesheet(input);
    if (!updatedTimesheet) return;
    // * update ui with latest data
    setTimesheets([...timesheets, updatedTimesheet]);
    snackbar.notify({ title: "Timesheet activity added successfully!" });
    fetchTimeSheets(fromDate, toDate);
  };

  const onTimeSheetApprove = async (
    input: ApproveTimeSheetEntryVariables,
    discrepancy: TimesheetFields_discrepancy,
  ) => {
    if (discrepancy?.status === TimeSheetDiscrepancyStatus.Pending) {
      snackbar.notify({ title: "Timesheet has a discrepancy", icon: "warning" });
      return;
    }

    try {
      await approveTimeSheetEntry(input);

      setTimesheets(
        timesheets.map((it) => {
          if (input.id === it.id) {
            it.status = input.input.status || TimeSheetEntryStatus.Completed;
          }
          return it;
        }),
      );
      const snackMessage =
        input.input.status === TimeSheetEntryStatus.Approved ? "Timesheet was approved" : "Timesheet was unapproved";
      snackbar.notify({ title: snackMessage });
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
    }
    fetchTimeSheets(fromDate, toDate);
  };

  const onTimeSheetsApprove = async (input: ApproveTimeSheetEntriesVariables) => {
    const timesheetIds = await approveTimeSheetEntries(input);

    setTimesheets(
      timesheets.map((it) => {
        if (timesheetIds.includes(it.id || "")) {
          it.status = input.input.status || TimeSheetEntryStatus.Completed;
        }
        return it;
      }),
    );
    fetchTimeSheets(fromDate, toDate);
  };

  const onTimesheetDiscrepancyUpdate = async (input: UpdateTimeSheetEntryDiscrepancyVariables) => {
    const updatedTimesheet = await updateTimeSheetEntryDiscrepancy(input);

    // * update ui with latest data
    setTimesheets(
      timesheets.map((it) => {
        if (updatedTimesheet.id === it.id) {
          it.fromDT = updatedTimesheet?.fromDT;
          it.toDT = updatedTimesheet?.toDT;
          it.amountMins = updatedTimesheet?.amountMins;

          if (it.discrepancy) {
            it.discrepancy.status = updatedTimesheet?.discrepancy?.status;
          }
        }
        return it;
      }),
    );

    snackbar.notify({ title: "Timesheet activity updated successfully!" });

    fetchTimeSheets(fromDate, toDate);
  };

  const onTimeSheetDelete = async (sheet: TimesheetFields) => {
    let hasConfirmed = false;

    hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete this timesheet entry?`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
      renderToPortal: true,
    });

    if (!hasConfirmed) return;

    await deleteTimeSheetEntry(sheet as DeleteTimeSheetEntryVariables);
    snackbar.notify({ title: "Timesheet activity removed successfully!" });

    // remove entry from timesheets
    setTimesheets(timesheets.filter((it) => it.id !== sheet.id));
    fetchTimeSheets(fromDate, toDate);
  };

  return (
    <TimeSheetsContext.Provider
      value={{
        isLoading,
        fetchTimeSheets,
        timesheets,
        allTimesheets,
        mergedTimeSheets,
        filterType,
        setFilterType,
        setFilterId,
        toDate,
        fromDate,
        discrepancyFound,
        setToDate,
        setFromDate,
        onTimeSheetAdd,
        onTimeSheetApprove,
        onTimeSheetsApprove,
        onTimeSheetDelete,
        onTimeSheetSave,
        onTimesheetDiscrepancyUpdate,
      }}
    >
      {children}
    </TimeSheetsContext.Provider>
  );
};
