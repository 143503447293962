import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import "@rmwc/avatar/styles";
import { PROJECT_STATUS_BACKGROUNDS } from "components/StatusLabel";
import { CalendarDetails } from "components/schedule/CalendarDetails";
import dayjs from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { dialogQueue } from "hooks/useDialogQueue";
import useSorted, { sortByProperty } from "hooks/useSorted";
import React from "react";
import CalendarWrapper from "./CalendarWrapper";


export function ProjectCalendar() {
  const [projectId, setProjectId] = React.useState<string | null>();
  const { projects, updateProject } = useAdminApi();

  const sorted = useSorted(projects, sortByProperty("name"));

  const events = sorted.map((it) => ({
    title: it.name,
    start: dayjs(it.startDate).tz("UTC", true).format("YYYY-MM-DD"),
    end: dayjs(it.endDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
    allDay: true,
    color: PROJECT_STATUS_BACKGROUNDS[it.status],
    extendedProps: { projectId: it.id },
  }));

  const handleDrag = async ({ event, revert }) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Change work order dates?",
      body: `Are you sure you want to change the dates for this work order? This may affect tasks within the work order.`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) {
      revert();
      return;
    }

    const { start, end } = event;
    const { projectId } = event.extendedProps;
    const startDate = dayjs(start);
    const endDate = dayjs(end).subtract(1, "day");
    const project = projects.find((it) => it.id === projectId);
    updateProject({ ...project, startDate, endDate });
  };

  return (
    <CalendarWrapper>
      {projectId && (
        <CalendarDetails project={projects.find((it) => it.id === projectId)} onClose={() => setProjectId(null)} />
      )}

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        timeZone="UTC"
        editable
        initialView="dayGridWeek"
        contentHeight="auto"
        events={events}
        eventDrop={handleDrag}
        eventResize={handleDrag}
        eventClick={({ event: { extendedProps } }) => {
          setProjectId(extendedProps.projectId);
        }}
        headerToolbar={{
          left: "prev,next,title",
          right: "dayGridWeek,dayGridMonth",
        }}
      />
    </CalendarWrapper>
  );
}
