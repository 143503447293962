import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  clientId: string;
  projectId: string;
}
export const useClient = () => {
  const params = useParams<ParamsT>();
  const { clientId } = params;
  const { projectId } = params;
  const { clients, projects } = useAdminApi();

  if (clientId) {
    const client = clients.find((it) => it.id === clientId);
    return client;
  } else if (projectId) {
    const clientId = projects.find((it) => it.id === projectId)?.clientId;
    if (clientId) {
      const client = clients.find((it) => it.id === clientId);
      return client;
    }
  }
};
