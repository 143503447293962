import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { useFirebase } from "hooks/useFirebase";
import useLayout, { LayoutProvider } from "hooks/useLayout";

import { useAdminApi } from "hooks/useAdminApi";

import { Button } from "../Button";

import { NavigationDrawer } from "./NavigationDrawer";

import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { MenuItem, MenuOnSelectEventT, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import { SimpleListItem } from "@rmwc/list";
import "@rmwc/list/styles";

import styled from "styled-components";

import Palette from "../../palette.json";
import { DesktopOnly, DesktopOnlySpan, TabletOnly } from "../Responsive";

import { InvalidUserRolePage } from "../../pages";
import type { LocationFields } from "types/admin/LocationFields";
import type { UserFields_locations } from "types/admin/UserFields";
import { Select } from "components/Select";

const Root = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
`;
const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfd;
`;
const Header = styled.div`
  padding: 10px 0;
  z-index: 5;

  display: flex;
  align-items: center;

  background-color: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
`;
const BreadCrumbs = styled.ul`
  flex-grow: 1;
  margin-left: 32px;
  display: flex;

  > li {
    display: flex;
    align-items: center;
    margin: 0;

    a {
      font-size: 14px;
      font-weight: normal;
      color: ${Palette.MediumGrey};
      text-decoration: none;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }

  > li > .rmwc-icon {
    margin: 0 10px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
  }

  > li:last-of-type {
    a {
      font-weight: 500;
      color: ${(props) => props.theme.primary};
    }
  }
`;
const UserActions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin: 0 8px;
  }

  .rmwc-avatar {
    margin: 0 14px 0 0;
    color: white;
    background-color: ${(props) => props.theme.primary};

    @media (max-width: 1622px) {
      margin: 0;
    }
  }
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
`;
const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DropdownButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LayoutComponent: React.FC = ({ children }) => {
  const history = useHistory();

  const { signOut } = useFirebase();
  const { me, locations, userFilteredLocations, setUserFilteredLocations } = useAdminApi();

  const locationsToShow: (LocationFields | UserFields_locations)[] =
    me && "locations" in me && me.locations.length > 0 ? me.locations : locations;

  // Sync userFilteredLocations with me.locations on first render
  //   useEffect(() => {
  //     console.log("useEffect: userFilteredLocations: me.locations", me.locations);
  //     if (userFilteredLocations.initialized === true || locationsToShow.length === 0) return;

  //     console.log("useEffect: userFilteredLocations: setting userFilteredLocations");
  //     setUserFilteredLocations({
  //       initialized: true,
  //       locationFilterActive: true,
  //       locationIds: [locationsToShow[0].id],
  //     });
  //   }, [locationsToShow]);

  const { isDrawerExpanded, setDrawerExpanded, isStandalone, breadCrumbs } = useLayout();

  const quickMenuItems = [
    {
      icon: "addchart",
      label: "Work Order",
      action: () => history.push("/work-orders/new"),
    },
    {
      icon: "person_add",
      label: "Customer",
      action: () => history.push("/clients/new"),
    },
    {
      icon: "person_add",
      label: "Technician",
      action: () => history.push("/people/new?role=technician"),
    },
    {
      icon: "person_add",
      label: "Manager",
      action: () => history.push("/people/new?role=manager"),
    },
  ];

  const userMenuItems = [
    {
      label: "Profile",
      action: () => history.push("/profile"),
    },
    {
      label: "Logout",
      action: () => signOut(),
    },
  ];

  const onAddNewUserItemSelected = (ev: MenuOnSelectEventT) => {
    quickMenuItems[ev.detail.index].action();
  };

  const onUserMenuItemSelected = (ev: MenuOnSelectEventT) => {
    userMenuItems[ev.detail.index].action();
  };

  if (isStandalone) {
    return <>{children}</>;
  }

  return (
    <Root>
      {me && <NavigationDrawer expanded={isDrawerExpanded} setExpanded={setDrawerExpanded} />}

      {me ? (
        <Main>
          <Header>
            <BreadCrumbs>
              {breadCrumbs.map((it, i) => (
                <li key={i}>
                  <Link to={it.path}>{it.label}</Link>
                  {i < breadCrumbs.length - 1 && <Icon icon="chevron_right" style={{ margin: "2px" }} />}
                </li>
              ))}
            </BreadCrumbs>

            <UserActions>
              {/* location select */}
              <Select
                outlined
                value={
                  userFilteredLocations.locationFilterActive
                    ? userFilteredLocations.locationIds[0]
                    : locationsToShow.length === 1
                    ? locationsToShow[0].id
                    : "all"
                }
                options={
                  locationsToShow.length === 1
                    ? locationsToShow.map((location) => ({
                        label: location.name,
                        value: location.id,
                      }))
                    : [
                        {
                          label: "All Locations",
                          value: "all",
                        },
                        ...locationsToShow.map((location) => ({
                          label: location.name,
                          value: location.id,
                        })),
                      ]
                }
                onChange={(e) => {
                  setUserFilteredLocations({
                    initialized: true,
                    locationFilterActive: true,
                    locationIds: e.currentTarget.value === "all" ? [] : [e.currentTarget.value],
                  });
                }}
              />

              <SimpleMenu
                anchorCorner="bottomStart"
                focusOnOpen={false}
                handle={
                  <Button outlined trailingIcon="unfold_more">
                    <DropdownButtonWrapper>
                      <Icon icon="library_add" style={{ color: "gray", marginRight: "8px" }} />
                      <DesktopOnly>New</DesktopOnly>
                      <TabletOnly>New</TabletOnly>
                    </DropdownButtonWrapper>
                  </Button>
                }
                onSelect={onAddNewUserItemSelected}
              >
                {quickMenuItems.map((it, i) => (
                  <SimpleListItem style={{ width: "150px" }} key={i} tabIndex={0} role="menuitem" graphic={it.icon}>
                    {it.label}
                  </SimpleListItem>
                ))}
              </SimpleMenu>

              <SimpleMenu
                anchorCorner="bottomStart"
                focusOnOpen={false}
                handle={
                  <Button trailingIcon="unfold_more">
                    {me.firstName && <Avatar size="large" name={`${me?.firstName} ${me?.lastName}`} />}
                    <DesktopOnlySpan>
                      {me.firstName ? `${me?.firstName} ${me?.lastName}` : "Unknown User"}
                    </DesktopOnlySpan>
                  </Button>
                }
                onSelect={onUserMenuItemSelected}
              >
                {userMenuItems.map((it, i) => (
                  <MenuItem key={i}>{it.label}</MenuItem>
                ))}
              </SimpleMenu>
            </UserActions>
          </Header>

          <Container>
            <Page>{children}</Page>
          </Container>
        </Main>
      ) : (
        <Main>
          <Container>
            <Page>
              <InvalidUserRolePage />
            </Page>
          </Container>
        </Main>
      )}
    </Root>
  );
};

export const Layout: React.FC = ({ children }) => {
  return (
    <LayoutProvider>
      <LayoutComponent>{children}</LayoutComponent>
    </LayoutProvider>
  );
};
