import React from "react";
import { useHistory } from "react-router-dom";

// components
import { CircularProgress } from "@rmwc/circular-progress";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import { Button } from "components/Button";
import EmptyState from "components/EmptyState";
import { Table, TableHead } from "components/Table";
import { TableHoursCell } from "components/TableHoursCell";
import { TableProgressCell } from "components/TableProgressCell";
import { TaskNameCell } from "components/tasks/TaskList";
import { TaskFields } from "types/admin/TaskFields";

type Props = {
  tasks: TaskFields[];
  isLoading?: boolean;
  userId?: string;
};

const LaborAssignmentsTable = ({ tasks, userId, isLoading }: Props) => {
  const history = useHistory();

  console.log(tasks);

  return (
    <Table>
      <DataTableContent>
        <TableHead cols={["Task", "Hours", "Progress"]}></TableHead>

        <DataTableBody data-test-id="labor-assignments-list">
          {tasks.length === 0 && (
            <DataTableRow>
              <DataTableCell colSpan={5}>
                {isLoading ? <CircularProgress /> : <EmptyState icon="assessment" text={"No tasks found."} />}
              </DataTableCell>
            </DataTableRow>
          )}

          {tasks.map((it, i) => (
            <DataTableRow key={i}>
              <TaskNameCell className="rmwc-data-table__cell" onClick={() => history.push(`/work-orders/${it.projectId}/tasks/${it.id}`)}>
                <div style={{ paddingLeft: 8 }}>
                  <span>{it?.orderIndex}</span> - {it.name}
                </div>
              </TaskNameCell>
              <TableHoursCell task={it} />
              {typeof it.progress?.progressTimePercentage === "number" && <TableProgressCell task={it} />}
              <DataTableCell>
                <Button
                  style={{ width: "7rem", marginLeft: 16 }}
                  onClick={() => history.push(`/work-orders/${it.projectId}/tasks/${it.id}/timesheets/${userId}/review`)}
                  data-test-id="btn-review"
                >
                  Review
                </Button>
              </DataTableCell>
            </DataTableRow>
          ))}
        </DataTableBody>
      </DataTableContent>
    </Table>
  );
};

export default LaborAssignmentsTable;
