import React from "react";

import { Button } from "./Button";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";

interface MakeTemplateButtonProps {
  onClick: () => void;
  type: "Work Order" | "Task";
}

export const MakeTemplateButton: React.FC<MakeTemplateButtonProps> = ({ onClick, type = "Work Order" }) => {
  return (
    <Button outlined onClick={onClick} data-test-id="btn-make-template" style={{ padding: "10px", marginRight: 0 }}>
      <span style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="file_copy" style={{ marginRight: 8, color: Palette.LightGrey }} />
        New {type} Template
      </span>
    </Button>
  );
};
