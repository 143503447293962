import React from "react";
import { useAdminApi } from "hooks/useAdminApi";
import { Icon } from "@rmwc/icon";
import Palette from "../../palette.json";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import useSorted, { sortByProperty } from "hooks/useSorted";
import { ActivityTypeSelect } from "./ActivityTypeSelect";
import ReportCard from "./ReportCard";

const Event = styled.li`
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  color: ${Palette.MediumGrey};
  line-height: 18px;
  width: 100%;
  -webkit-font-smoothing: antialiased;

  time {
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }

  strong,
  a {
    color: ${Palette.DarkGrey};
    font-weight: bold;
  }
`;

const Message = styled.p`
  padding: 8px;
  background-color: #f5f6fa;
  border-radius: 4px;
  color: black;
  font-size: 13px;
  margin-top: 7px;
  opacity: 0.8;
  width: 80%;
`;

const iconMap = {
  message: "mode_comment",
  attachment: "attach_file",
  user: "person",
};

export const Activity: React.FC = () => {
  const [type, setType] = React.useState<number>(0);
  const { tasks, users, projects } = useAdminApi();

  const events = tasks.reduce((acc, it) => {
    let messages = [];
    if ([0, 2].includes(type)) {
      messages = it.messages?.items?.map((message) => ({
        type: "message",
        date: message.postedAt,
        user: users.find((user) => user.id === message.userId)?.name,
        message: message.message,
        taskName: it.name,
        taskId: it.id,
        projectId: it.projectId,
        projectName: projects.find((project) => project.id === it.projectId)?.name,
      }));
    }
    let attachments = [];
    if ([0, 1].includes(type)) {
      attachments = it.attachments?.items?.map((attachment) => ({
        type: "attachment",
        date: attachment.ct,
        fileName: attachment.fileName,
        url: attachment.file.url,
        taskName: it.name,
        taskId: it.id,
        projectId: it.projectId,
        projectName: projects.find((project) => project.id === it.projectId)?.name,
      }));
    }
    return [...acc, ...(attachments || []), ...(messages || [])];
  }, []);

  const userEvents = projects.reduce((acc, it) => {
    const projectUsers = it.users?.items?.map(({ userId, ct }) => ({
      type: "user",
      userId,
      date: ct,
      user: users.find((user) => user.id === userId)?.name,
      projectId: it.id,
      projectName: it.name,
    }));
    return [...acc, ...(projectUsers || [])];
  }, []);

  const combinedEvents = [0, 3].includes(type) ? [...events, ...userEvents] : events;
  const sortedEvents = useSorted(combinedEvents, sortByProperty("date")).reverse();
  const truncatedEvents = sortedEvents.slice(0, 20);

  return (
    <ReportCard
      heading={
        <>
          Recent Activity
          <ActivityTypeSelect value={type} onChange={setType} />
        </>
      }
    >
      <ul>
        {truncatedEvents.length === 0 && <em>No recent activity.</em>}
        {truncatedEvents.map(({ type, date, user, ...event }) => (
          <Event key={date}>
            <Icon icon={{ icon: iconMap[type], size: "xsmall" }} style={{ color: Palette.Blue }} />
            <div style={{ width: "100%" }}>
              {type === "message" && (
                <>
                  <strong>{user}</strong> commented on&nbsp;
                  <Link to={`/work-orders/${event.projectId}/tasks/${event.taskId}`}>{event.taskName}</Link> in&nbsp;
                  <Link to={`/work-orders/${event.projectId}`}>{event.projectName}</Link>
                </>
              )}
              {type === "attachment" && (
                <>
                  <a href={event.url} target="_blank" rel="noreferrer">
                    {event.fileName}
                  </a>
                  &nbsp; was added to&nbsp;
                  <Link to={`/work-orders/${event.projectId}/tasks/${event.taskId}`}>{event.taskName}</Link> in&nbsp;
                  <Link to={`/work-orders/${event.projectId}`}>{event.projectName}</Link>
                </>
              )}
              {type === "user" && (
                <>
                  <strong>{user}</strong> was added to&nbsp;
                  <Link to={`/work-orders/${event.projectId}`}>{event.projectName}</Link>
                </>
              )}
              <time>{dayjs(date).format("MMM DD, YYYY [at] h:mm a")}</time>
              {type === "message" && <Message>{event.message}</Message>}
            </div>
          </Event>
        ))}
      </ul>
    </ReportCard>
  );
};
