import React, { useEffect } from "react";

import "@rmwc/icon-button/styles";

import { PageContent, PageMain } from "components/PageStyles";

import { TemplatesTable } from "components/templates/TemplatesTable";
import useLayout from "hooks/useLayout";
import { PageHeader } from "components/PageHeader";
import { Route } from "react-router-dom";
import { TaskFormDialog } from "components/tasks/TaskFormDialog";
import { ProjectTemplateFormDialog } from "components/templates/ProjectTemplateFormDialog";

export const TemplatesPage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Templates",
        path: "/templates",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path="/templates/:templateId/edit-task_">
        <TaskFormDialog action="update" />
      </Route>
      <Route exact path="/templates/:templateId/edit-project_">
        <ProjectTemplateFormDialog action="update" />
      </Route>

      <PageHeader compact title="Templates" />

      <PageMain>
        <PageContent>
          <TemplatesTable />
        </PageContent>
      </PageMain>
    </>
  );
};
