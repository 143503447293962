import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { LocationFields } from "types/admin/LocationFields";

import useLayout from "hooks/useLayout";

import { useLocationItem } from "hooks/params/useLocationItem";

import { PageHeader } from "../PageHeader";
import { PageMain } from "../PageStyles";

import { ClientLocationList } from "./ClientLocationList";

import { MapView } from "../MapView";

import styled from "styled-components";

import { useLocations } from "hooks/params/useLocations";
import { useClient } from "hooks/params/useClient";
import { Button } from "components/Button";
import { ClientAssignLocationDialog } from "components/clients/ClientAssignLocationDialog";
import { Route } from "react-router-dom";
import { useAdminApi } from "hooks/useAdminApi";
import { MainArea, LocationTools, LocationAddressItem, LocationInfo, LocationName } from "./LocationComponents";

const ListContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

export const ClientLocationsPageView: React.FC = () => {
  const history = useHistory();

  const { setBreadCrumbs } = useLayout();
  const clientLocations = useLocations();
  const { locations } = useAdminApi();
  const client = useClient();
  const item = useLocationItem();

  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState<number>(8);

  useEffect(() => {
    if (!client) return;
    const firstLocation = locations[0];
    if (!firstLocation) return;
    history.push(`/clients/${client.id}/locations/${firstLocation.id}`);
  }, []);

  useEffect(() => {
    if (!setBreadCrumbs) return;

    if (!item || !item.geometry) {
      setCenter({ lat: 0, lng: 0 });
      setZoom(10);
      return;
    }

    const [lng, lat] = item.geometry?.coordinates;

    if (!lat || !lng) return;
    setCenter({ lat, lng });
    setZoom(15);
  }, [setBreadCrumbs, item]);

  const onItemClick = (item?: LocationFields) => {
    if (client && item) {
      history.push(`/clients/${client.id}/locations/${item.id}`);
      return;
    }
    history.push(`/clients/${client.id}/locations`);
  };

  return (
    <>
      <Route exact path={["/clients/:clientId/locations/assign"]}>
        <ClientAssignLocationDialog locations={locations} />
      </Route>

      {!client && <PageHeader title={`Locations (${clientLocations.length})`} />}

      <PageMain style={{ flexDirection: "row-reverse" }}>
        <MainArea>
          <LocationTools>
            <Button
              data-test-id="btn-assign"
              raised
              onClick={() => history.push(`/clients/${client.id}/locations/assign`)}
            >
              Assign Locations
            </Button>
          </LocationTools>

          {item && (
            <LocationInfo data-test-id="item-info">
              <LocationName>{item.name}</LocationName>
              <LocationAddressItem>{item.address?.street1}</LocationAddressItem>
              <LocationAddressItem>{item.address?.street2}</LocationAddressItem>
              <LocationAddressItem>{item.address?.city}</LocationAddressItem>
              <LocationAddressItem>{item.address?.state}</LocationAddressItem>
              <LocationAddressItem>{item.address?.zip}</LocationAddressItem>
              <LocationAddressItem>{item.address?.country}</LocationAddressItem>
            </LocationInfo>
          )}

          <MapView center={center} zoom={zoom} items={clientLocations} onItemClick={onItemClick} />
        </MainArea>

        <ListContainer>
          <ClientLocationList items={clientLocations} onItemClick={onItemClick} />
        </ListContainer>
      </PageMain>
    </>
  );
};
