import React, { useRef, useState } from "react";

import styled from "styled-components";

import Palette from "../palette.json";

export interface FileUploadInputPropsT {
  onChange(files: File[]): void;
}

const DropArea = styled.div<{ isActive: boolean }>`
  position: relative;

  padding: 12px 0;

  display: flex;
  justify-content: space-around;

  background-color: white;
  border: dashed 1px #e2e5ed;

  > input[type="file"] {
    display: none;
  }
`;

const DropLabel = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;

  color: ${Palette.LightGrey};
`;

const DropZone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FileUploadInput: React.FC<FileUploadInputPropsT> = ({ onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isActive, setActive] = useState<boolean>(false);

  const onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
  };

  const onDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setActive(true);
  };

  const onDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setActive(false);
  };

  const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setActive(false);
    onChange(Array.from(ev.dataTransfer.files));
  };

  const onFileInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    if (ev.target.files && ev.target.files.length > 0) {
      onChange(Array.from(ev.target.files));
    }
  };

  return (
    <DropArea {...{ isActive }}>
      <input multiple type="file" ref={inputRef} onChange={onFileInputChange} />

      <DropLabel>Drag and Drop file/s here.</DropLabel>
      <DropZone
        onClick={() => inputRef.current?.click()}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      />
    </DropArea>
  );
};
