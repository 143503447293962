import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  aircraftId: string;
}
export const useAircraft = () => {
  const params = useParams<ParamsT>();
  const { aircraftId } = params;

  const { entities, getEntity } = useAdminApi();

  return {
    entity: entities.find((it) => it.id === aircraftId),
    getEntity,
  };
};
