import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormRow, Select, Number } from "components/Form";
import { Button } from "./Button";
import { snackbar } from "hooks/useNotifications";
import SimpleCard from "./SimpleCard";
import { readableBillingType } from "../common";
import { useClient } from "hooks/params/useClient";
import { BillingType } from "types/admin/globalTypes";
import { useAdminApi } from "hooks/useAdminApi";

interface FormValuesT {
  defaultPartMarkup: number;
  defaultSubcontractorMarkup: number;
  defaultLaborHourlyRate: number;
  defaultBillingType: BillingType;
}

function SettingsForm() {
  const client = useClient();
  const { getClient, updateClientSettings } = useAdminApi();

  useEffect(() => {
    if (!client) return;
    (async () => {
      await getClient({ id: client.id });
    })();
  }, []);

  const billingTypeOpts = [];
  for (const [_, v] of Object.entries(BillingType)) {
    billingTypeOpts.push({ label: readableBillingType(v), value: v });
  }

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      defaultPartMarkup: 0,
      defaultSubcontractorMarkup: 0,
      defaultLaborHourlyRate: 0,
      defaultBillingType: BillingType.Fixed,
    },
  });

  const { setValue } = form;

  const onSubmit = async (data: FormValuesT) => {
    if (!client) return;
    data.defaultPartMarkup = parseFloat(data?.defaultPartMarkup?.toString() || "0");
    data.defaultSubcontractorMarkup = parseFloat(data?.defaultSubcontractorMarkup?.toString() || "0");
    data.defaultLaborHourlyRate = parseFloat(data?.defaultLaborHourlyRate?.toString() || "0");

    await updateClientSettings({
      clientId: client.id,
      settings: data,
    });
    snackbar.notify({ title: "Customer settings updated successfully!" });
  };

  useEffect(() => {
    if (!client) return;

    setValue("defaultPartMarkup", client.settings?.defaultPartMarkup);
    setValue("defaultSubcontractorMarkup", client.settings?.defaultSubcontractorMarkup);
    setValue("defaultLaborHourlyRate", client.settings?.defaultLaborHourlyRate);
    setValue("defaultBillingType", client.settings?.defaultBillingType);
  }, [client]);

  return (
    <div style={{ maxWidth: 900 }}>
      <div>
        <Form form={form} onSubmit={onSubmit}>
          <SimpleCard heading="Tasks">
            <FormRow col={2}>
              <Number name="defaultPartMarkup" label="Default Part Markup (%)" />
              <Number name="defaultSubcontractorMarkup" label="Default Subcontractor Markup (%)" />
            </FormRow>

            <FormRow col={2}>
              <Select name="defaultBillingType" label="Default Billing Type" options={billingTypeOpts} />
              <Number name="defaultLaborHourlyRate" label="Default Labor Hourly Rate ($/hr)" />
            </FormRow>
          </SimpleCard>

          <FormRow>
            <Button raised>Save Settings</Button>
          </FormRow>
        </Form>
      </div>
    </div>
  );
}

export default SettingsForm;
