import React from "react";
import { Button } from "../Button";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../../palette.json";
import styled from "styled-components";
import useMultiTenant from "hooks/useMultiTenant";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  text-align: center;
  padding: 1rem 2rem;
  margin: 0 2rem;
  border-radius: 0.5rem;
  background-color: #f1f1f1;
  max-width: 500px;
`;

const Header = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.3rem;
`;

const Message = styled.p`
  font-size: 16px;
  line-height: 1.2;
`;

export const SuccessfulSignatureSubmission: React.FC = () => {
  const { theme } = useMultiTenant();

  return (
    <PageWrapper>
      <TextWrapper>
        <Icon icon="check_circle" style={{ fontSize: "4rem", marginBottom: "2rem", color: theme.primary }} />
        <Header>Success</Header>
        <Message>Your signature has been successfully submitted.</Message>
      </TextWrapper>
    </PageWrapper>
  );
};
