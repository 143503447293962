import React from "react";
import styled from "styled-components";

const Card = styled.div`
  margin-bottom: 20px;
  max-width: 900;
  background-color: #fff;
  padding: 32px 32px 0;
  border-radius: 4px;
  border: 1px solid rgba(41, 22, 22, 0.12);
  border-top: 2px solid ${(props) => props.theme.primary};
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

interface SimpleCardProps {
  children: any;
  heading: string;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ children, heading }) => {
  return (
    <div>
      <Heading>{heading}</Heading>

      <Card>
        <div>{children}</div>
      </Card>
    </div>
  );
};

export default SimpleCard;
