import styled from "styled-components";

import Palette from "../palette.json";

export const CommonForm = styled.form<{ [props: string]: any }>`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormRowGrid = styled.div`
  margin: 0 0 24px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const FormField = styled.div`
  margin: 0 20px 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  :last-of-type {
    margin-right: 0;
  }

  input:not([type="checkbox"]) {
    height: auto;
    padding: 8px 12px;
    color: ${Palette.MediumGrey} !important;
    font-size: 14px;

    :disabled {
      color: ${Palette.LightGrey} !important;
    }
  }

  .mdc-form-field {
    .mdc-switch {
    }

    label {
      padding-left: 12px;
      font-size: 14px;
      font-weight: normal;
      color: ${Palette.DarkGrey};
    }
  }

  .mdc-text-field {
    padding: 8px 0;

    height: auto;

    border: 1px solid #d8dce6;
    border-radius: 4px;

    color: ${Palette.DarkGrey} !important;

    background-color: white;

    input {
      height: auto;
      padding: 0 12px;
      color: ${Palette.DarkGrey} !important;
      font-size: 14px;
    }
  }
`;

export const MultiItemSection = styled.div`
  padding-bottom: 24px;

  :not(:last-of-type) {
    margin-bottom: 32px;
    border-bottom: 1px solid #eaedf3;
  }
`;

export const SectionTitle = styled.h5`
  margin-bottom: 12px;

  font-size: 14px;
  font-weight: 500;
`;

export const AddItemContainer = styled.div`
  margin-top: -16px;

  button {
    .rmwc-icon {
      font-size: 20px;
    }
    .mdc-button__label {
      padding: 3px 0;
      color: ${Palette.MediumGrey};
      font-size: 14px;
    }
  }
`;

export const StyledErrorMessage = styled.div`
  margin-top: 4px;
  color: ${Palette.Red};
  font-size: 85%;
`;
