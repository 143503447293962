import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  userId: string;
}
export const useUser = () => {
  const params = useParams<ParamsT>();
  const { userId } = params;

  const { users } = useAdminApi();
  const user = users.find((it) => it.id === userId);

  return user;
};
