import React, { useState } from "react";
import { useHistory } from "react-router";
import { ProjectFields } from "types/admin/ProjectFields";
import useSorted, { sortByColumn } from "hooks/useSorted";
import { Button } from "components/Button";
import { ProjectStatusLabel } from "components/StatusLabel";
import "@rmwc/icon-button/styles";
import {
  SortableColumnT,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import dayjs from "dayjs";
import { TableProgressCell, Text, Inner } from "components/TableProgressCell";
import EmptyState from "components/EmptyState";
import { ProjectStatus } from "types/admin/globalTypes";

interface ProjectListProps {
  items: ProjectFields[];
  projectStatus?: ProjectStatus;
  setProjectStatus?: React.Dispatch<React.SetStateAction<ProjectStatus>>;
}

export const ProjectList: React.FC<ProjectListProps> = ({ items, projectStatus, setProjectStatus }) => {
  const history = useHistory();

  const defaultColumnLabels = [
    { label: "Work Order Name", sortDir: 0 },
    { label: "Progress", sortDir: -1 },
    { label: "Status", sortDir: 0 },
    { label: "Due Date", sortDir: 0 },
  ];

  const [columnLabels, setColumnLabels] = useState<(string | SortableColumnT)[]>(defaultColumnLabels);
  const [sortedByColumn, setSortedByColumn] = useState<SortableColumnT | undefined>(
    defaultColumnLabels[5] as SortableColumnT,
  );
  const FILTER_OPTS = Object.keys(ProjectStatus).map((it) => it);
  const projectStatusLabel = {
    [ProjectStatus.Accepted]: "Active",
    [ProjectStatus.Pending]: "Pending",
    [ProjectStatus.Completed]: "Completed",
  };

  const sorted = useSorted(items, sortByColumn(sortedByColumn));

  const onNewProject = () => {
    history.push("/work-orders/new", { referrer: "/dashboard" });
  };

  const sortProjectBy = (input: any) => {
    setColumnLabels(
      defaultColumnLabels.map((it) => {
        if (typeof it === "string") return it;
        if (it.label === input.column.label) {
          const theColumn = {
            label: it.label,
            sortDir: input.sortDir,
          };
          setSortedByColumn(theColumn);
          return theColumn;
        }
        return {
          label: it.label,
          sortDir: 0,
        };
      }),
    );
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {projectStatusLabel[projectStatus] ?? "Active"} Work Orders &nbsp;
          <TitleCount>({sorted.length})</TitleCount>
        </TableToolbarTitle>

        {projectStatus && (
          <SimpleMenu
            anchorCorner="bottomStart"
            focusOnOpen={false}
            handle={
              <Button outlined trailingIcon="unfold_more">
                {projectStatus}
              </Button>
            }
            onSelect={(ev) => setProjectStatus(ev.detail.item.textContent as ProjectStatus)}
          >
            {FILTER_OPTS.map((it, i) => (
              <MenuItem key={i}>{it}</MenuItem>
            ))}
          </SimpleMenu>
        )}

        <Button raised onClick={onNewProject}>
          New Work Order
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead
            cols={columnLabels.filter((it) => typeof it !== "string")}
            sortBy={(event) => sortProjectBy(event)}
          />
          <DataTableBody data-test-id="projects-list">
            {sorted.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={columnLabels.length}>
                  <EmptyState
                    icon="assignment"
                    text={"No active orders found. Create one now."}
                    buttonText={"New Work Order"}
                    onClick={onNewProject}
                  />
                </DataTableCell>
              </DataTableRow>
            )}
            {sorted.map((it, i) => (
              <DataTableRow key={i} onClick={() => history.push(`/work-orders/${it.id}`)}>
                <DataTableCell>{it.name}</DataTableCell>
                {typeof it.progress?.progressTimePercentage === "number" ? (
                  <TableProgressCell project={it} />
                ) : (
                  <DataTableCell>
                    <Inner>
                      <Text style={{ textAlign: "right" }}>0%</Text>
                    </Inner>
                  </DataTableCell>
                )}

                <DataTableCell>
                  <ProjectStatusLabel status={it.status}>{it.status}</ProjectStatusLabel>
                </DataTableCell>
                <DataTableCell>{dayjs(it.endDate).tz("UTC", true).format("l")}</DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
