import React, { useEffect } from "react";

import { BillingType } from "types/admin/globalTypes";

import { TaskFormContentProps } from "./TaskFormDialog";

import { FormRow, Number, Select } from "components/Form";
import { useTask } from "hooks/params/useTask";

export const TaskFormLaborContent: React.FC<TaskFormContentProps> = ({ form, style, setActiveTabIndex }) => {
  const { task } = useTask();

  const { formState, watch } = form;
  const { errors } = formState;

  const billingType = watch("billing");

  const readableBillingType = (billingType: BillingType) => {
    switch (billingType) {
      case BillingType.Fixed:
        return "Flat Rate";
      case BillingType.TANDM:
        return "Hourly Billing";
    }
  };
  const billingTypeOpts = [];
  for (const [_, v] of Object.entries(BillingType)) {
    billingTypeOpts.push({ label: readableBillingType(v), value: v });
  }

  useEffect(() => {
    const FIELDS = ["billing", "amount", "estimateManHrs", "hourlyRate"];

    if (FIELDS.filter((it) => it in errors).length > 0) setActiveTabIndex(1);
  }, [errors]);

  const validateInt = (value: number, label: string) => {
    return value >= 0 || `${label} cannot be negative`;
  };

  return (
    <div {...{ style }}>
      <FormRow col={2}>
        <Number name="estimateManHrs" label="Est. Hours" validate={validateInt} disabled={!!task?.signingStatus} />
      </FormRow>

      <FormRow col={2}>
        <Select name="billing" label="Billing Type" options={billingTypeOpts} disabled={!!task?.signingStatus} />
      </FormRow>

      <FormRow col={2}>
        {billingType === BillingType.TANDM && (
          <Number name="hourlyRate" label="Hourly Rate" validate={validateInt} disabled={!!task?.signingStatus} />
        )}
        <Number name="amount" label="Billing Amount" validate={validateInt} disabled={!!task?.signingStatus} />
      </FormRow>
    </div>
  );
};
