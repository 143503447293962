import React, { useEffect } from "react";

import { DeepMap, FieldError, useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";
import { useAircraft } from "hooks/params/useAircraft";

import { snackbar } from "hooks/useNotifications";

import { Form, FormRow, Text, Number, Select } from "components/Form";

import { useClient } from "hooks/params/useClient";
import { UpdateAircraftVariables } from "types/admin/UpdateAircraft";
import { useParams } from "react-router-dom";
import SimpleCard from "../SimpleCard";
import { Button } from "@rmwc/button";
import useTranslations from "hooks/useTranslations";
import { BUILDING_TYPE_OPTIONS } from "../../common";

interface FormValuesT {
  id?: string;

  make: string;
  model: string;
  clientId: string;
  tailNumber?: string;
  serialNumber?: string;
  airframeCycles?: number;
  airframeHours?: number;
  engine1TotalTime?: number;
  engine1Cycles?: number;
  engine2TotalTime?: number;
  engine2Cycles?: number;
  APUHobbs?: number;
}

interface ParamsT {
  clientId: string;
  aircraftId: string;
}

const HIDDEN_FIELDS = ["clientId"];

export const AircraftDetailsView: React.FC = () => {
  const { tCommon, tFeature, isAviation } = useTranslations();
  const { updateEntity } = useAdminApi();

  const { clientId } = useParams<ParamsT>();
  const { entity: aircraft } = useAircraft();
  const client = useClient();

  const form = useForm<FormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      clientId: aircraft?.clientId || client?.id || clientId || "",
      make: aircraft?.make || "",
      model: aircraft?.model || aircraft?.type || "",
      serialNumber: aircraft?.serialNumber || "",
      tailNumber: aircraft?.tailNumber || "",
      airframeCycles: aircraft?.airframeCycles || 0,
      airframeHours: aircraft?.airframeHours || 0,
      engine1TotalTime: aircraft?.engine1TotalTime || 0,
      engine1Cycles: aircraft?.engine1Cycles || 0,
      engine2TotalTime: aircraft?.engine2TotalTime || 0,
      engine2Cycles: aircraft?.engine2Cycles || 0,
      APUHobbs: aircraft?.APUHobbs || 0,
    },
  });

  const { register, setValue, formState } = form;
  const { errors } = formState;

  useEffect(() => {
    if (!setValue || !aircraft) return;

    setValue("clientId", client?.id || clientId);
    setValue("serialNumber", aircraft.serialNumber);
    setValue("tailNumber", aircraft.tailNumber);
    setValue("make", aircraft.make);
    setValue("model", aircraft.model || aircraft.type);
    setValue("airframeCycles", aircraft.airframeCycles || 0);
    setValue("airframeHours", aircraft.airframeHours || 0);
    setValue("engine1TotalTime", aircraft.engine1TotalTime || 0);
    setValue("engine1Cycles", aircraft.engine1Cycles || 0);
    setValue("engine2TotalTime", aircraft.engine2TotalTime || 0);
    setValue("engine2Cycles", aircraft.engine2Cycles || 0);
    setValue("APUHobbs", aircraft.APUHobbs || 0);
  }, [setValue, aircraft, client, clientId]);

  const showFormErrors = (errors: DeepMap<FormValuesT, FieldError>) => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      const error = firstError as any;
      snackbar.notify({ icon: "warning", title: error.message });
      return true;
    }
    return false;
  };

  const onInvalid = (errors: any) => {
    showFormErrors(errors);
  };

  const onSubmit = async (values: FormValuesT) => {
    if (showFormErrors(errors)) return;

    try {
      if (!aircraft) return;

      await updateEntity({
        id: aircraft.id,
        input: { ...(values as unknown as UpdateAircraftVariables), clientId: values.clientId },
      });
      snackbar.notify({ title: tFeature("AircraftDetailsView.editSuccess") });
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
      return;
    }
  };

  const validateInt = (value: number) => {
    return value >= 0 || `Cannot be negative`;
  };

  return (
    <div style={{ maxWidth: 900 }}>
      <Form form={form} onSubmit={onSubmit} onInvalid={onInvalid}>
        {HIDDEN_FIELDS.map((it, i) => (
          <input key={i} type="hidden" name={it} ref={register({ required: true })} />
        ))}
        <SimpleCard heading="General Information">
          <FormRow>
            {isAviation ? (
              <Text name="make" label={tCommon("Make")} required />
            ) : (
              <Select outlined name="make" label={tCommon("Make")} options={BUILDING_TYPE_OPTIONS} />
            )}
            <Text name="model" label={tCommon("Model")} required />
          </FormRow>

          <FormRow>
            <Text name="serialNumber" label={tCommon("SerialNumber")} required />
            <Text name="tailNumber" label={tCommon("TailNumber")} required />
          </FormRow>
        </SimpleCard>

        {isAviation && (
          <SimpleCard heading="Maintenance">
            <FormRow>
              <Number name="airframeCycles" label="Airframe Cycles" validate={validateInt} required />
              <Number name="airframeHours" label="Airframe Hours" validate={validateInt} required />
            </FormRow>

            <FormRow>
              <Number name="engine1TotalTime" label="Engine 1 Total Time" validate={validateInt} required />
              <Number name="engine1Cycles" label="Engine 1 Cycles" validate={validateInt} required />
            </FormRow>

            <FormRow>
              <Number name="engine2TotalTime" label="Engine 2 Total Time" validate={validateInt} required />
              <Number name="engine2Cycles" label="Engine 2 Cycles" validate={validateInt} required />
            </FormRow>

            <FormRow>
              <Number name="APUHobbs" label="APU Hobbs" validate={validateInt} required />
            </FormRow>
          </SimpleCard>
        )}

        <FormRow>
          <Button raised>{tFeature("AircraftDetailsView.editButton")}</Button>
        </FormRow>
      </Form>
    </div>
  );
};
