import React, { useEffect } from "react";
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import "@rmwc/theme/styles";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import { Table, TableContainer, TableHead, TableTitle } from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import useLayout from "hooks/useLayout";
import { TextInput } from "components/TextInput";
import { useParams } from "react-router";
import { TimesheetFields } from "types/admin/TimesheetFields";
import { generateLocationXLSX } from "../../../common";
import { Button } from "components/Button";

type Item = {
  date: Date;
  hrs: number;
  userIds: string[];
  taskIds: string[];
};

interface ParamsT {
  locationId: string;
}

export const LocationPage: React.FC = () => {
  const { locationId } = useParams<ParamsT>();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const defaultFromDate = search.get("fromDate") ? dayjs(search.get("fromDate")) : dayjs().startOf("week");
  const defaultToDate = search.get("toDate") ? dayjs(search.get("toDate")) : dayjs().endOf("week");
  const [fromDate, setFromDate] = React.useState(defaultFromDate);
  const [toDate, setToDate] = React.useState(defaultToDate);
  const { clients, projects, getTimesheets } = useAdminApi();
  const [timesheets, setTimesheets] = React.useState<TimesheetFields[]>([]);

  const locations = clients.reduce((acc, client) => {
    const clientLocations = client.locations.items;
    return [...acc, ...clientLocations];
  }, []);

  const locationTimesheets = timesheets.filter((it) => {
    if (!it.taskId || ["InHangar", "Driving"].includes(it.taskId)) return false;
    const project = projects.find((project) => project.id === it.projectId);
    const taskLocationId = project?.location?.id;
    return taskLocationId === locationId;
  });

  const items: Item[] = [];

  locationTimesheets.forEach((it) => {
    const hrs = it.amountMins / 60;
    const date = dayjs(it.toDT).toDate();
    const userId = it.user?.id;
    const taskId = it.taskId;
    const index = items.findIndex((it) => dayjs(date).format("MM/DD/YYYY") === dayjs(date).format("MM/DD/YYYY"));
    if (index === -1) {
      items.push({
        date,
        hrs,
        userIds: [userId],
        taskIds: [taskId],
      });
    } else {
      items[index].hrs += hrs;
      items[index].userIds = [...items[index].userIds, userId];
      items[index].taskIds = [...items[index].taskIds, taskId];
    }
  });

  const formattedItems = items.map((it) => {
    return {
      ...it,
      hrs: Math.round(it.hrs * 100) / 100,
      userIds: [...new Set(it.userIds || [])],
      taskIds: [...new Set(it.taskIds || [])],
    };
  });

  const handleDownload = async () => {
    await generateLocationXLSX(fromDate.format("MM/DD/YYYY"), toDate.format("MM/DD/YYYY"), formattedItems);
  };

  const startString = fromDate.format();
  const endString = toDate.format();

  React.useEffect(() => {
    if (!startString || !endString) return;
    getTimesheets(dayjs(startString), dayjs(endString)).then((results) => {
      setTimesheets(results);
    });
  }, [startString, endString]);

  const { setBreadCrumbs } = useLayout();

  const currentLocation = locations.find((it) => it.id === locationId);
  const locationName = currentLocation?.name;

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Trend Report",
        path: "/trends",
      },
      {
        label: locationName,
        path: `/trends/${locationId}`,
      },
      {
        label: `${fromDate.format("MMM D")} - ${toDate.format("MMM D")}`,
        path: "/trends",
      },
    ]);
  }, [toDate, fromDate, locationName]);
  return (
    <>
      <PageHeader title={currentLocation?.name} />

      <PageMain>
        <PageContent>
          <TableContainer>
            <TableTitle>
              <TextInput
                type="date"
                value={fromDate.format("YYYY-MM-DD")}
                onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD").startOf("day"))}
                data-test-id="from-date-input"
              />
              &nbsp;⟶&nbsp;
              <TextInput
                type="date"
                value={toDate.format("YYYY-MM-DD")}
                onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD").endOf("day"))}
                data-test-id="to-date-input"
              />
              <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
                Download Report
              </Button>
            </TableTitle>

            <Table>
              <DataTableContent>
                <TableHead cols={["Date", "Total Tasks", "Total Hours", "Total Techs"]}></TableHead>
                <DataTableBody data-test-id="trend-list">
                  {formattedItems.map((it, i) => {
                    return (
                      <DataTableRow key={i}>
                        <DataTableCell>{dayjs(it.date).format("MM/DD/YYYY")}</DataTableCell>
                        <DataTableCell>{it.taskIds.length}</DataTableCell>
                        <DataTableCell>{it.hrs}</DataTableCell>
                        <DataTableCell>{it.userIds.length}</DataTableCell>
                      </DataTableRow>
                    );
                  })}
                </DataTableBody>
              </DataTableContent>
            </Table>
          </TableContainer>
        </PageContent>
      </PageMain>
    </>
  );
};
