import React from "react";
import { CircularProgress } from "@rmwc/circular-progress";

interface LoadingIndicatorProps {
  style?: React.CSSProperties;
  size?: number;
  theme?: string;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ style, ...props }) => {
  return (
    <div>
      <CircularProgress {...props} className="loadingIndicator" style={{ ...style }} {...{ progress: 0.25 }} />
    </div>
  );
};

export default LoadingIndicator;
