import { useAdminApi } from "../useAdminApi";
import { useClient } from "./useClient";

export const useLocations = () => {
  const { locations } = useAdminApi();
  const client = useClient();

  if (client) {
    return client.locations?.items?.map((it) => it.location) || [];
  }

  return locations;
};
