import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router";
import { Switch } from "react-router-dom";

import useLayout from "hooks/useLayout";
import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";

import { TabContainer } from "components/TabContainer";

import { AircraftList } from "components/aircraft/AircraftList";
import { AircraftFormDialog } from "components/aircraft/AircraftFormDialog";

import { ProjectList } from "components/projects/ProjectList";
import { ProjectFormDialog } from "components/projects/ProjectFormDialog";

import { UserList } from "components/users/UserList";

import { ClientFormDialog } from "components/clients/ClientFormDialog";

import { Tab, TabBar } from "@rmwc/tabs";
import "@rmwc/tabs/styles";
import { ClientLocationsPageView } from "components/locations/ClientLocationsPageView";
import ClientSettingsForm from "components/ClientSettingsForm";

import { UserFormDialog } from "components/users/UserFormDialog";

import * as Pages from "../../../pages";
import { ProjectRole } from "types/admin/globalTypes";
import useTranslations from "hooks/useTranslations";

interface ParamsT {
  clientId: string;
}

export const ClientViewPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { entities: allAircraft, projects, users, deleteClient } = useAdminApi();

  const client = useClient();
  const aircraft = allAircraft.filter((it) => it.clientId === client?.id);

  const PAGE_TABS = [
    {
      icon: "",
      label: "Work Orders",
      path: `/clients/${clientId}/work-orders`,
    },
    {
      icon: "",
      label: tCommon("Aircrafts"),
      path: `/clients/${clientId}/entities`,
    },
    {
      icon: "",
      label: "People",
      path: `/clients/${clientId}/people`,
    },
    {
      icon: "",
      label: "Locations",
      path: `/clients/${clientId}/locations`,
    },
    {
      icon: "",
      label: "Settings",
      path: `/clients/${clientId}/settings`,
    },
  ];

  const [activeTabIndex, setTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path) || "work-orders"),
  );

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs || !client) return;
    setBreadCrumbs([
      {
        label: "Customers",
        path: "/clients",
      },
      {
        label: client.name,
        path: `/clients/${client.id}`,
      },
    ]);
  }, [setBreadCrumbs, client]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/new")) return;
    history.replace(path);
  };

  const onDelete = async () => {
    if (!client) return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete customer: ${client?.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteClient({ id: client?.id });
    snackbar.notify({ title: "Customer removed successfully!" });
    history.goBack();
  };

  if (!client) return <Route component={Pages.Error404} />;

  return (
    <>
      <Route exact path="/clients/:clientId/edit">
        <ClientFormDialog action="update" />
      </Route>

      <Route exact path="/clients/:clientId/entities/new">
        <AircraftFormDialog action="create" onClose={() => history.replace(`/clients/${clientId}/entities`)} />
      </Route>

      <Route exact path="/clients/:clientId/entities/:aircraftId/edit">
        <AircraftFormDialog action="update" onClose={() => history.replace(`/clients/${clientId}/entities`)} />
      </Route>

      <Route exact path="/clients/:clientId/people/new">
        <UserFormDialog action="create" />
      </Route>

      <Route exact path="/clients/:clientId/work-orders/new">
        <ProjectFormDialog action="create" />
      </Route>

      <Route exact path="/clients/:clientId/work-orders/:projectId/edit_">
        <ProjectFormDialog action="update" />
      </Route>

      <PageHeader
        editable
        title={client?.name || ""}
        subtitle={client?.clientType ? client.clientType : "Client"}
        onEditClick={() => history.push(`/clients/${client?.id}/edit`)}
      />

      <TabContainer>
        <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i}>{it.label}</Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <Switch>
          <Route
            exact
            path={[
              "/clients/:clientId/locations",
              "/clients/:clientId/locations/assign",
              "/clients/:clientId/locations/:locationId",
            ]}
          >
            <ClientLocationsPageView />
          </Route>
          <PageContent>
            <Route path="/clients/:clientId/entities">
              <AircraftList key="aircraft" items={aircraft} />
            </Route>
            <Route exact path="/clients/:clientId/settings">
              <ClientSettingsForm key="settings" />
            </Route>
            <Route path="/clients/:clientId/people">
              <UserList
                key="people"
                items={users.filter((it) => it.clientId === client?.id && it.projectRole === ProjectRole.customer)}
              />
            </Route>
            <Route
              exact
              path={[
                "/clients/:clientId",
                "/clients/:clientId/edit",
                "/clients/:clientId/work-orders",
                "/clients/:clientId/work-orders/:projectId/edit_",
              ]}
            >
              <ProjectList
                key="projects"
                hiddenFields={["Customer"]}
                items={projects.filter((it) => it.clientId === client?.id)}
              />
            </Route>
          </PageContent>
        </Switch>
      </PageMain>
    </>
  );
};
