import gql from "graphql-tag";
import { ParentShiftFields } from "./shifts";

export const AvailableShiftFields = gql`
  ${ParentShiftFields}
  fragment AvailableShiftFields on Shift {
    id
    parentShiftId
    parentShift {
      ...ParentShiftFields
    }
  }
`;

export const UserFields = gql`
  ${AvailableShiftFields}
  fragment UserFields on User {
    id

    clientId

    firstName
    lastName

    type
    hasSigningAuthority

    email
    phoneNumber

    name

    signature {
      id
    }

    locations {
      id
      name
    }

    isRootAdministrator
    userRole
    projectRole

    clients {
      items {
        id
      }
    }

    payChex {
      id
      orgUnit
    }

    aAndPNumber

    availableShifts {
      items {
        ...AvailableShiftFields
      }
    }
  }
`;

export const getMeQuery = gql`
  ${UserFields}

  query GetMeQuery {
    asAdmin {
      getMe {
        ...UserFields
      }
    }
  }
`;

export const getUserQuery = gql`
  ${UserFields}

  query GetUserQuery($id: ID!) {
    asAdmin {
      getUser(id: $id) {
        ...UserFields
      }
    }
  }
`;

export const listUsersQuery = gql`
  ${UserFields}

  query ListUsers {
    asAdmin {
      listUsers {
        items {
          ...UserFields
        }
      }
    }
  }
`;

export const addUserMutation = gql`
  ${UserFields}

  mutation AddUser(
    $clientId: String = ""
    $projectId: String = ""
    $projectRole: ProjectRole = null
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $userRole: UserRole!
    $payChex: PayChexInput
    $type: UserWorkPayType!
    $hasSigningAuthority: Boolean
    $locations: [LocationInfoInput!]!
    $aAndPNumber: String
  ) {
    asAdmin {
      addUser(
        input: {
          clientId: $clientId
          projectId: $projectId
          projectRole: $projectRole
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          userRole: $userRole
          payChex: $payChex
          type: $type
          hasSigningAuthority: $hasSigningAuthority
          locations: $locations
          aAndPNumber: $aAndPNumber
        }
      ) {
        ...UserFields
      }
    }
  }
`;

export const updateUserMutation = gql`
  ${UserFields}

  mutation UpdateUser(
    $id: ID!
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $userRole: UserRole!
    $projectRole: ProjectRole
    $payChex: PayChexInput
    $type: UserWorkPayType!
    $hasSigningAuthority: Boolean
    $locations: [LocationInfoInput!]!
    $aAndPNumber: String
    $resendEmail: Boolean = false
  ) {
    asAdmin {
      updateUser(
        id: $id
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          payChex: $payChex
          userRole: $userRole
          projectRole: $projectRole
          type: $type
          hasSigningAuthority: $hasSigningAuthority
          locations: $locations
          aAndPNumber: $aAndPNumber
        }
        resendEmail: $resendEmail
      ) {
        ...UserFields
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation DeleteUser($id: ID!) {
    asAdmin {
      deleteUser(id: $id) {
        id
      }
    }
  }
`;
