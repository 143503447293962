import { TaskFields } from "types/admin/TaskFields";
import dayjs from "dayjs";
import { ProjectRole, ProjectStatus, TaskStatus } from "types/admin/globalTypes";
import { ProjectFields } from "types/admin/ProjectFields";
//@typescript-eslint/ban-ts-comment
import ztable from "ztable";

export const estimateTaskProgress = (project: ProjectFields, tasks: TaskFields[]) => {
  if (!tasks.length || [ProjectStatus.Completed, ProjectStatus.Pending].includes(project.status)) {
    return;
  }

  if (dayjs(project.startDate).isAfter(dayjs())) {
    return;
  }

  const PESSIMISTIC_RATE = 1.5;
  const OPTIMISTIC_RATE = 0.6;
  const RATE_DIFFERENCE = PESSIMISTIC_RATE - OPTIMISTIC_RATE;

  let projectTotalShiftHours = 0;

  const taskEstimates = tasks
    ?.map((task) => {
      // PERT
      const userShiftsWithDuration = task.userShiftsUntilNow?.items.map((it) => ({
        ...it,
        duration: dayjs(it.toDt).diff(dayjs(it.fromDt), "hour", true),
      })) as any[];
      const userShiftsEstHours = userShiftsWithDuration?.reduce((acc, cur) => acc + cur.duration, 0);
      projectTotalShiftHours += userShiftsEstHours;

      if (!userShiftsEstHours) return undefined;

      const likelyDurationHours = (userShiftsEstHours * (4 + PESSIMISTIC_RATE + OPTIMISTIC_RATE)) / 6;
      const variance =
        userShiftsWithDuration.reduce((acc, cur) => acc + Math.sqrt((cur.duration * RATE_DIFFERENCE) / 6), 0) /
        userShiftsWithDuration.length;

      // PACE
      const paceVariance = ((task.progress.completedHours || 0) - userShiftsEstHours) / userShiftsEstHours;
      const numDaysElapsedSoFar = dayjs().utc(true).diff(dayjs(task.scheduledDate), "day", true);
      const expectedPace = userShiftsEstHours / numDaysElapsedSoFar;

      // userShiftsAfterNow is totally getting the wrong number here -> this was because I was falling back on predicted hours - hours worked
      const remainingHours = task.userShiftsAfterNow?.items.reduce(
        (acc, cur) => acc + dayjs(cur.toDt).diff(dayjs(cur.fromDt), "hour", true),
        0,
      );
      projectTotalShiftHours += remainingHours;

      if (remainingHours === 0) {
        return {
          id: task.id,
          pert: { likelyDurationHours, variance },
          pace: undefined,
        };
      }

      const expectedDaysLeft = remainingHours / expectedPace;
      const adjustDays = expectedDaysLeft * paceVariance;
      const daysDiff = Math.ceil(expectedDaysLeft + adjustDays);
      const predictedEndDate = dayjs().add(daysDiff, "days");

      if (predictedEndDate.isBefore(dayjs(project.startDate))) {
        return undefined;
      }

      return {
        id: task.id,
        pert: { likelyDurationHours, variance },
        pace: {
          currentActualHours: task.progress.completedHours,
          dueDate: task.dueDate,
          projectedEndDate: predictedEndDate,
          isComplete: task.status === TaskStatus.Completed,
        },
      };
    })
    .filter((it) => !!it);

  // Only show tasks that have a legit projectedEndDate, and that are not already complete
  const sortedTasks = taskEstimates
    ?.filter((it) => it.pace?.projectedEndDate)
    ?.filter((it) => !it.pace?.isComplete)
    ?.sort((a, b) => a.pace.projectedEndDate.diff(b.pace.projectedEndDate, "day", true));
  if (!sortedTasks.length) return;

  const workOrderForecastFinishDate = sortedTasks[sortedTasks.length - 1].pace.projectedEndDate;
  const totalLikelyDuration = taskEstimates.reduce((acc, cur) => acc + cur.pert.likelyDurationHours, 0);
  const totalVariance = taskEstimates.reduce((acc, cur) => acc + cur.pert.variance, 0);
  const standardDeviation = Math.sqrt(totalVariance);

  let totalHoursCheck = project.estimateManHrs || 0;
  const checkProbability = () => {
    const numberToZed = (totalHoursCheck - totalLikelyDuration) / standardDeviation;
    const zed = ztable(numberToZed);
    if (zed < 0.9) {
      totalHoursCheck++;
      checkProbability();
    }
  };
  checkProbability();

  const dailyHours = projectTotalShiftHours / dayjs(project.endDate).diff(dayjs(project.startDate), "day", true);
  const probableDays = totalHoursCheck / dailyHours;
  const probableDate = dayjs(project.startDate).add(probableDays, "days");

  const [earlierDate, laterDate] = workOrderForecastFinishDate.isBefore(probableDate)
    ? [workOrderForecastFinishDate, probableDate]
    : [probableDate, workOrderForecastFinishDate];
  const daysDifference = laterDate.diff(earlierDate, "day");

  const likelyFinishDate = earlierDate.add(daysDifference / 2, "day");

  if (likelyFinishDate.isAfter(dayjs(project.startDate)) || likelyFinishDate.isSame(dayjs(project.startDate))) {
    return likelyFinishDate;
  }
};
