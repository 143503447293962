import React from "react";
import { CommonForm, StyledErrorMessage, FormField, FormRow as Row, FormRowGrid } from "./CommonForm";
import { Label } from "./Label";
import { FormProvider, useFormContext, Controller } from "react-hook-form";
import { TextInput } from "./TextInput";
import { ErrorMessage } from "@hookform/error-message";
import { Select as SelectInput } from "components/Select";
import { Checkbox as CheckboxInput } from "@rmwc/checkbox";
import { TextArea as TextareaInput } from "./TextArea";
import { Switch } from "@rmwc/switch";

type FormProps = {
  children: React.ReactNode;
  form: any;
  onSubmit: (callback: any) => void;
  onInvalid?: (callback: any) => void;
  [x: string]: any;
};

type InputProps = {
  name: string;
  label?: string;
  fieldStyles?: React.CSSProperties;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  validate?: any;
  messagePrefix?: string;
  [x: string]: any;
};

export const Form = ({ children, form: { handleSubmit, ...form }, onSubmit, onInvalid, ...props }: FormProps) => (
  <FormProvider {...form}>
    <CommonForm onSubmit={handleSubmit(onSubmit, onInvalid)} {...props}>
      {children}
    </CommonForm>
  </FormProvider>
);

export const Text = ({
  label,
  name,
  required,
  fieldStyles,
  validate,
  requiredMsg,
  maxLength,
  minLength,
  ...props
}: InputProps) => {
  const { register, errors } = useFormContext();
  return (
    <FormField style={fieldStyles}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <TextInput
        name={name}
        type="text"
        {...props}
        ref={register({
          minLength: {
            value: minLength,
            message: `Must be at least ${minLength} character${minLength !== 1 ? "s" : ""}`,
          },
          maxLength: { value: maxLength, message: `Must be ${maxLength} characters or less` },
          required: required ? requiredMsg || `${label} is required` : false,
          validate: validate ? (value) => validate(value, label, name) : undefined,
        })}
      />
      <ErrorMessage errors={errors} as={<StyledErrorMessage />} name={name} />
    </FormField>
  );
};

export const Textarea = ({ label, name, required, requiredMsg, fieldStyles, ...props }: InputProps) => {
  const { register, errors } = useFormContext();
  return (
    <FormField style={fieldStyles}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <TextareaInput
        name={name}
        {...props}
        ref={register({ required: required ? requiredMsg || `${label} is required` : false })}
      />
      <ErrorMessage errors={errors} as={<StyledErrorMessage />} name={name} />
    </FormField>
  );
};

export const Hidden = ({ name, required, ...props }: InputProps) => {
  const { register } = useFormContext();
  return (
    <TextInput
      type="hidden"
      name={name}
      {...props}
      ref={register({ required: required ? `${name} is required` : false })}
    />
  );
};

export const Select = ({ label, name, defaultValue, ...props }: InputProps) => {
  const { control } = useFormContext();
  return (
    <FormField>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Controller {...{ control }} defaultValue={defaultValue} name={name} as={<SelectInput {...props} />} />
    </FormField>
  );
};

export const Checkbox = ({ label, name, fieldStyles, defaultValue, ...props }: InputProps) => {
  const { control } = useFormContext();
  return (
    <FormField style={fieldStyles}>
      <Controller
        {...{ control }}
        name={name}
        defaultValue={defaultValue || false}
        render={({ onChange, onBlur, value, name, ref }) => (
          <CheckboxInput
            name={name}
            onBlur={onBlur}
            onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
            checked={value}
            inputRef={ref}
            {...props}
          >
            {label}
          </CheckboxInput>
        )}
      />
    </FormField>
  );
};

export const Toggle = ({ label, name, fieldStyles, defaultValue, ...props }: InputProps) => {
  const { errors, control } = useFormContext();
  return (
    <FormField>
      <Label htmlFor={name}>{label}</Label>
      <Controller
        {...{ control }}
        name={name}
        defaultValue={defaultValue}
        isInvalid={name in errors}
        render={({ onChange, onBlur, value, name, ref }) => (
          <Switch
            name={name}
            onBlur={onBlur}
            checked={value}
            inputRef={ref}
            onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
            {...props}
          />
        )}
      />
    </FormField>
  );
};

export const Email = (props: InputProps) => <Text type="email" {...props} />;
export const Number = (props: InputProps) => <Text type="number" {...props} />;
export const Date = (props: InputProps) => <Text type="date" {...props} />;
export const Time = (props: InputProps) => <Text type="time" {...props} />;

export const Phone = ({ label, name, required, requiredMsg, ...props }: InputProps) => {
  const { register, errors, setValue } = useFormContext();

  const validatePhoneNumber = (value: string) => {
    const cleanedValue = value.replace(/\D+/g, "");
    setValue(name, cleanedValue);

    const phoneNumberRegex = /^(\d{10})$/;
    if (cleanedValue.match(phoneNumberRegex)) {
      return true;
    } else {
      return "Not a valid phone number";
    }
  };

  return (
    <FormField>
      {label && <Label htmlFor={name}>{label}</Label>}
      <TextInput
        name={name}
        type="text"
        isInvalid={name in errors}
        ref={register({
          required: required ? requiredMsg || `${label} is required` : false,
          validate: validatePhoneNumber,
        })}
        {...props}
      />
      <ErrorMessage errors={errors} as={<StyledErrorMessage />} name={name} />
    </FormField>
  );
};

export const CountryCode = ({ label, name, required, requiredMsg, fieldStyles, ...props }: InputProps) => {
  const { register, errors, setValue } = useFormContext();

  const validateCountryCode = (value: string) => {
    const cleanedValue = value.replace(/\D+/g, "").slice(0, 2);
    setValue(name, cleanedValue);

    const countryCodeRegex = /^(\d{1,2})$/;
    if (cleanedValue.match(countryCodeRegex)) {
      const formatted = cleanedValue.replace(/^(\d{1,2})$/, "+$1");
      setValue(name, formatted);
      return true;
    } else {
      return "Not a valid country code";
    }
  };

  return (
    <FormField style={{ marginRight: "2px", ...fieldStyles }}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <TextInput
        name={name}
        isInvalid={name in errors}
        style={{ width: "30px" }}
        ref={register({
          required: required ? requiredMsg || `${label} is required` : false,
          validate: validateCountryCode,
        })}
        {...props}
      />
      <ErrorMessage errors={errors} as={<StyledErrorMessage />} name={name} />
    </FormField>
  );
};

type RowProps = {
  col?: number;
  flex?: boolean;
  children: React.ReactNode;
  [x: string]: any;
};

export const FormRow = ({ col, flex, children, ...props }: RowProps) => {
  return (
    <FormRowGrid style={{ gridTemplateColumns: `repeat(${col || 1}, 1fr)`, display: col ? "grid" : "flex" }} {...props}>
      {children}
    </FormRowGrid>
  );
};
