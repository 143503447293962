import React, { useEffect } from "react";
import { Route } from "react-router";
import useLayout from "hooks/useLayout";
import { useAdminApi } from "hooks/useAdminApi";
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { LocationFormDialog } from "components/locations/LocationFormDialog";
import { useLocations } from "hooks/params/useLocations";
import { useHistory } from "react-router-dom";
import { LocationList } from "components/locations/LocationList";
import { LocationAssignClientListDialog } from "components/locations/LocationAssignClientListDialog";

export const LocationsPage: React.FC = () => {
  const history = useHistory();

  const { setBreadCrumbs } = useLayout();

  const { clients } = useAdminApi();
  const locations = useLocations();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: "Locations", path: "/locations" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path={["/locations/new"]}>
        <LocationFormDialog action="create" />
      </Route>

      <Route exact path={["/locations/:locationId/edit"]}>
        <LocationFormDialog action="update" />
      </Route>

      <Route exact path={["/locations/:locationId/assign"]}>
        <LocationAssignClientListDialog clients={clients} />
      </Route>

      <PageHeader title="Locations" />

      <PageMain>
        <PageContent>
          <LocationList items={locations} />
        </PageContent>
      </PageMain>
    </>
  );
};
