import gql from "graphql-tag";

export const SignatureFields = gql`
  fragment SignatureFields on Signature {
    id
    userId
    todoId
    userName
    userAAndPNumber
    entityId
    entityType
    signedDate
    pageCount
    task {
      name
    }
  }
`;

export const getSigningUserQuery = gql`
  query GetSigningUser($entityId: ID!) {
    getSigningUser(entityId: $entityId) {
      userId
      name
      projectRole
    }
  }
`;

export const listUserSignaturesQuery = gql`
  ${SignatureFields}

  query ListUserSignatures($id: ID, $nextToken: String) {
    asAdmin {
      getMe {
        signatures(userId: $id, nextToken: $nextToken) {
          items {
            ...SignatureFields
          }
          nextToken
        }
      }
    }
  }
`;

export const listSignaturesQuery = gql`
  query ListSignatures($entityId: ID!) {
    listSignatures(entityId: $entityId) {
      items {
        id
        userId
        todoId
        userName
        userAAndPNumber
        entityType
        pageCount
        signatureAttachment {
          file {
            url
          }
        }
        signedDate
      }
    }
  }
`;

export const sendSignatureSignUpEmailMutation = gql`
  mutation SendSignatureEmail($userId: ID!) {
    asAdmin {
      sendSignatureSignUpEmail(userId: $userId) {
        success
      }
    }
  }
`;

export const addSignatureMutation = gql`
  mutation AddSignature($entityId: ID!, $entityType: SigningEntityType!, $pin: String!) {
    addSignature(entityId: $entityId, entityType: $entityType, pin: $pin) {
      id
      userId
      entityType
      userName
      todoId
      userAAndPNumber
      pageCount
      signatureAttachment {
        file {
          url
        }
      }
      signedDate
    }
  }
`;

export const addSignaturesMutation = gql`
  mutation AddSignatures($entityId: ID!, $entityType: SigningEntityType!, $pin: String!, $count: Int!) {
    addSignatures(entityId: $entityId, entityType: $entityType, pin: $pin, count: $count) {
      items {
        id
        userId
        entityType
        todoId
        userAAndPNumber
        pageCount
        signatureAttachment {
          file {
            url
          }
        }
        signedDate
      }
    }
  }
`;
