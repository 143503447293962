import React from "react";
import "../../stylesheets/pdf-viewer.css";
import Palette from "../../palette.json";
import dayjs from "dayjs";
import { PDFViewer } from "./PDFViewer";
import { Page } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components";
import useTemporaryUser from "hooks/useTemporaryUser";
import RIIStamp from "../../assets/images/rii-stamp.png";
import { ProjectRole, SigningEntityType, SigningType } from "types/admin/globalTypes";

const SigOneBox = styled.div<{ selectedSignature: number }>`
  position: absolute;
  bottom: 10.5%;
  left: 6%;
  height: 3%;
  width: 30%;

  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 2px ${(props) => (props.selectedSignature === 1 ? props.theme.primary : "none")};
  }

  border: 3px ${(props) => (props.selectedSignature === 1 ? "solid" : "dotted")}
    ${(props) => (props.selectedSignature === 1 ? props.theme.primary : Palette.LighterGrey)};
  cursor: ${(props) => (props.selectedSignature === 1 ? "pointer" : "default")};
`;

const SigTwoBox = styled.div<{ selectedSignature: number }>`
  position: absolute;
  bottom: 4.5%;
  left: 6%;
  height: 3%;
  width: 30%;

  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 2px ${(props) => (props.selectedSignature === 2 ? props.theme.primary : "none")};
  }

  border: 3px ${(props) => (props.selectedSignature === 2 ? "solid" : "dotted")}
    ${(props) => (props.selectedSignature === 2 ? props.theme.primary : Palette.LighterGrey)};
  cursor: ${(props) => (props.selectedSignature === 2 ? "pointer" : "default")};
`;

const SignatureOne = styled.img`
  bottom: 10%;
  left: 6%;
  height: 4%;
  position: absolute;
`;

const SignatureTwo = styled.img`
  bottom: 4%;
  left: 6%;
  height: 4%;
  position: absolute;
`;

const NameOne = styled.div`
  position: absolute;
  font-size: clamp(6.5px, 2vw, 16px);
  bottom: 10.5%;
  left: 50%;
`;

const NameTwo = styled.div`
  position: absolute;
  font-size: clamp(6.5px, 2vw, 16px);
  bottom: 4.5%;
  left: 50%;
`;

const AAndPNumberOne = styled.div`
  position: absolute;
  color: rgb(130, 130, 130);
  font-size: clamp(5px, 1.25vw, 12px);
  bottom: 8.9%;
  left: 60%;
`;

const AAndPNumberTwo = styled.div`
  position: absolute;
  color: rgb(130, 130, 130);
  font-size: clamp(5px, 1.25vw, 12px);
  bottom: 2.9%;
  left: 60%;
`;

const DateOne = styled.div`
  position: absolute;
  font-size: clamp(6.5px, 2vw, 16px);
  bottom: 10.5%;
  left: 85%;
`;

const DateTwo = styled.div`
  position: absolute;
  font-size: clamp(6.5px, 2vw, 16px);
  bottom: 4.5%;
  left: 85%;
`;

const Stamp = styled.div<{ y: number }>`
  position: absolute;

  height: 0px;
  width: 4.9%;
  padding-top: 4.9%;

  background-image: url(${RIIStamp});
  background-size: 100%;
  background-repeat: no-repeat;

  top: calc(${(props) => props.y}% + 4px);
  right: 4%;

  transform: rotate(14deg);
`;

const StampOutline = styled.div<{ y: number; isInspector: boolean }>`
  position: absolute;

  height: 0px;
  width: 4%;
  padding-top: 4%;
  border-radius: 100%;
  border: 2px dashed ${(props) => (props.isInspector ? props.theme.primary : Palette.LightGrey)};

  &:hover {
    ${(props) => (!props.isInspector ? "" : `border-style: solid;`)}
    ${(props) => (!props.isInspector ? "" : `box-shadow: 0 0 10px 2px ${props.theme.primary};`)}
  }

  ${(props) => (props.isInspector ? "cursor: pointer" : `cursor: default`)};

  top: calc(${(props) => props.y}% + 4px);
  right: 4%;
`;

interface InspectionFormPDFViewerProps {
  pdfString: string;
  signaturesAndStamps?: any[];
  todosInfo?: any;
  taskSigningType?: SigningType;
  signingUser?: any;
  onLoadSuccess: (numPages: number) => void;
  onSignatureClick: () => void;
  onStampClick: (id: string) => void;
  currentPage?: number;
  [key: string]: any;
}

export const InspectionFormPDFViewer: React.FC<InspectionFormPDFViewerProps> = ({
  pdfString,
  signaturesAndStamps,
  todosInfo,
  taskSigningType,
  signingUser,
  onLoadSuccess,
  onSignatureClick,
  onStampClick,
  currentPage,
  ...props
}) => {
  const { user: tempUser } = useTemporaryUser();
  const [numPages, setNumPages] = React.useState(0);

  const countPages = (numPages: number) => {
    onLoadSuccess(numPages);
    setNumPages(numPages);
  };

  const signatures = signaturesAndStamps?.filter((s) => s.entityType === SigningEntityType.TaskInspectionForm) || [];
  const stamps = signaturesAndStamps?.filter((s) => s.entityType === SigningEntityType.TaskInspectionFormStamp) || [];

  const selectedSignature = (() => {
    if (signatures.length > 0) {
      if (signatures.length === 1) {
        if (signatures.find((it) => it.userId === tempUser.uid)) {
          return null;
        }
        return 2;
      } else {
        return null;
      }
    }

    return 1;
  })();

  const onClick = async (number: number) => {
    if (selectedSignature === number) {
      onSignatureClick();
    }
  };

  return (
    <div style={{ maxWidth: "900px", width: "100%", ...props.style }}>
      <PDFViewer customPages currentPage={currentPage} onLoadSuccess={countPages} pdfString={pdfString}>
        <div id="page-1" style={{ position: "relative" }}>
          <Page pageNumber={1} />
          <SigOneBox onClick={() => onClick(1)} selectedSignature={selectedSignature} />
          {taskSigningType !== SigningType.Technician && (
            <SigTwoBox onClick={() => onClick(2)} selectedSignature={selectedSignature} />
          )}

          {signatures.length > 0 && (
            <>
              <SignatureOne src={signatures[0].signatureAttachment.file.url} />
              {signatures[0].userAAndPNumber && <AAndPNumberOne>A&P#: {signatures[0].userAAndPNumber}</AAndPNumberOne>}
              <NameOne>{signatures[0].userName || ""}</NameOne>
              <DateOne>{dayjs(signatures[0].signedDate).format("MM/DD/YYYY")}</DateOne>
            </>
          )}

          {signatures.length > 1 && (
            <>
              <SignatureTwo src={signatures[1].signatureAttachment.file.url} />
              {signatures[1].userAAndPNumber && <AAndPNumberTwo>A&P#: {signatures[1].userAAndPNumber}</AAndPNumberTwo>}
              <NameTwo>{signatures[1].userName || ""}</NameTwo>
              <DateTwo>{dayjs(signatures[1].signedDate).format("MM/DD/YYYY")}</DateTwo>
            </>
          )}
        </div>

        {Array(Math.max(0, numPages - 1))
          .fill(0)
          .map((_, i) => {
            const todos = todosInfo.coordinates.filter((it) => it.page === i + 2);
            return (
              <div style={{ borderTop: "1px dashed #000", position: "relative" }} id={`page-${i + 2}`} key={i + 2}>
                <Page pageNumber={i + 2} />

                {taskSigningType === SigningType.RII &&
                  todos.map((it, i) => {
                    const stamp = stamps.find((s) => s.todoId == it.id);

                    return (
                      <div key={i}>
                        {!!stamp ? (
                          <Stamp y={(100 * it.y) / todosInfo.pageHeight} />
                        ) : (
                          <StampOutline
                            onClick={() => onStampClick(it.id)}
                            y={(100 * it.y) / todosInfo.pageHeight}
                            isInspector={signingUser?.projectRole === ProjectRole.technicianInspector}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </PDFViewer>
    </div>
  );
};
