import gql from "graphql-tag";

export const validateSignatureQuery = gql`
  query ValidateSignature($pin: String, $entityId: ID!, $entityType: SigningEntityType!) {
    validateSignature(pin: $pin, entityId: $entityId, entityType: $entityType) {
      file {
        url
      }
    }
  }
`;
