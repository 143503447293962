import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useForm, UseFormMethods } from "react-hook-form";

import { calculateMarkupCost } from "../../common";

import Palette from "../../palette.json";

import {
  BillingType,
  CreateTaskRequest,
  MarkupType,
  TaskStatus,
  TaskType,
  PartType,
  UpdateTaskRequest,
  PartEntityType,
  AssignPartInput,
  UpdatePartInput,
  SigningType,
} from "types/admin/globalTypes";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";

import { TaskFormInfoContent } from "./TaskFormInfoContent";
import { TaskFormLaborContent } from "./TaskFormLaborContent";
import { TaskFormTodoContent } from "./TaskFormTodoContent";

import { TabContainer } from "../TabContainer";

import { Button } from "../Button";
import { Form } from "components/Form";
import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import dayjs from "dayjs";
import { TemplateFields } from "types/admin/TemplateFields";
import { UsingTemplateMessage } from "../templates/UsingTemplateMessage";
import { TaskFormStepOne } from "./TaskFormStepOne";
import styled from "styled-components";
import { snackbar } from "hooks/useNotifications";
import { TaskFormToolsContent } from "./TaskFormToolsContent";
import { TaskFormPartsContent } from "./TaskFormPartsContent";
import { TaskFormSubcontractorsContent } from "./TaskFormSubcontractorsContent";
import { showErrorMessage } from "../../utilities/handleError";
import LoadingIndicator from "../LoadingIndicator";
import { useClient } from "hooks/params/useClient";
import { useSettings } from "hooks/useSettings";
import useTranslations from "hooks/useTranslations";

const CreationActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  Button:first-of-type {
    margin-bottom: 15px;
  }
`;

enum FormSteps {
  ONE,
  TWO,
}

enum CreationType {
  NEW = "new",
  TEMPLATE = "template",
}

interface TodoItem {
  origId?: string;
  name: string;
}

interface ToolValuesT {
  id?: string;
  name: string;
  number: string;
  cost: string;
  expirationDate?: string;
  leadTimeDays: string;
  quantity: string;
  isBillable: boolean;
  markupType?: MarkupType;
  markup?: string;
  markupCost?: string;
}

interface PartValuesT {
  parentPartId: string;
  name: string;
  number: string;
  cost: number;
  leadTimeDays: number;
  quantity: number;
  type: PartType;
  isBillable: boolean;
  markupType?: MarkupType;
  markup?: string;
  markupCost?: number;
}

interface SubcontractorValuesT {
  id?: string;
  subcontractorId?: string;
  name: string;
  cost: number;
  description: string;
  markupType: MarkupType;
  markup: number;
}

export interface TaskFormValuesT {
  id?: string;

  parentId: string;
  projectId: string;

  name: string;
  description: string;

  amount: number;
  billing: BillingType;

  taskType: TaskType;

  estimateManHrs: number;
  hourlyRate: number;

  dueDate: string;
  scheduledDate: string;

  signingType?: SigningType;
  status: TaskStatus;
  inReview: boolean;

  // Internally used variables.
  parentName: string;
  projectName: string;

  assignedTo: string[];

  tools: ToolValuesT[];
  parts: PartValuesT[];
  subcontractors: SubcontractorValuesT[];

  todos: TodoItem[];
}

export interface TaskFormContentProps {
  style: React.CSSProperties;
  form: UseFormMethods<TaskFormValuesT>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  isTemplate?: boolean;
  isTodo?: boolean;
  action?: string;
}

interface TaskFormDialogProps {
  action: "create" | "update";
}

const useQuery = () => new URLSearchParams(useLocation().search);

const HIDDEN_FIELD_NAMES = ["parentId", "projectId"];

export const TaskFormDialog: React.FC<TaskFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string; isNew: boolean }>();
  const query = useQuery();
  const [whichTemplate, setWhichTemplate] = useState<string | null>();
  const [theTemplate, setTheTemplate] = useState<TemplateFields | null>();
  const [creationType, setCreationType] = useState<CreationType | null>();
  const [loading, setLoading] = useState(false);

  const {
    addTask,
    assignUserToTask,
    removeTaskUser,
    updateTask,
    addSubcontractor,
    updateSubcontractor,
    deleteSubcontractor,
    tasks: allTasks,
    templates,
    templateTasks: allTemplateTasks,
    addTaskFromTemplate,
    updateTaskTemplate,
    assignPartToParent,
    assignAssemblyToParent,
    deletePart,
    updatePart,
  } = useAdminApi();
  const { tCommon } = useTranslations();
  const project = useProject();
  const { template, isTemplateTypeTask, isTemplateTypeProject } = useTemplate();
  const { task } = useTask();
  const client = useClient();
  const { settings } = useSettings();
  const templateTasks = allTemplateTasks.filter((it) => it?.parentId === template?.id);

  const isTemplate = !!template;
  const tasks = task ? task.tasks?.items || [] : allTasks.filter((it) => it.parentId === project?.id);
  const templateTodos = template ? allTasks.filter((it) => it.parentId === template?.id) : [];
  const tabs = ["Information", "Labor", tCommon("Tools"), "Subcontractors", "Todos", tCommon("Parts")];

  const existingTaskSubcontractors = task?.subcontractors?.items?.filter((it) => it?.subcontractorId);
  const existingTemplateSubcontractors = template?.subcontractors?.items?.filter((it) => it?.subcontractorId);
  const existingSubcontractors = existingTaskSubcontractors?.length
    ? existingTaskSubcontractors
    : existingTemplateSubcontractors;

  const existingTaskParts = task?.taskParts?.items?.filter((it) => it?.id);
  const existingTemplateParts = template?.parts?.items?.filter((it) => it?.id);
  const existingParts = existingTaskParts?.length ? existingTaskParts : existingTemplateParts;

  const [formStep, setFormStep] = useState<number>(action === "update" ? FormSteps.TWO : FormSteps.ONE);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(locationState && locationState.isNew ? 1 : 0);
  const [shouldCopyAssignedUsers, setShouldCopyAssignedUsers] = useState(false);

  const form = useForm<TaskFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      id: "",
      parentId: "",
      projectId: "",
      name: "",
      description: "",
      amount: 0,
      signingType: undefined,
      billing: BillingType.Fixed,
      taskType: TaskType.Scheduled,
      dueDate: dayjs().tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
      scheduledDate: dayjs().tz("UTC", true).format("YYYY-MM-DD"),
      status: TaskStatus.Upcoming,
      inReview: false,
      hourlyRate: project?.hourlyRate || 0.0,
      estimateManHrs: 0,
      projectName: "",
      parentName: "",
      assignedTo: [],
      tools: [],
      parts: [],
      subcontractors: [],
      todos: [],
    },
  });

  const { formState, register, setValue, watch, getValues } = form;
  const { dirtyFields, errors } = formState;

  const taskName = watch("name");
  const estimatedHours = watch("estimateManHrs");
  const hourlyRate = watch("hourlyRate");
  const billingType = watch("billing");

  const setupTaskFromTemplate = () => {
    if (project) {
      setValue("parentId", project.id);
      setValue("projectId", project.id);
      setValue("projectName", project.name);
    } else {
      setValue("parentId", theTemplate ? theTemplate?.id : template?.id);
    }

    // * if task template
    if (template) {
      if (isTemplateTypeTask) {
        setValue("name", template?.name);
        setValue("billing", template?.billing);
        setValue("tools", template?.tools || []);
        setValue("signingType", template?.signingType);
        setValue("parts", template?.parts?.items || []);
        const todos = templateTasks.map((it) => ({ origId: it.id, ...it }));
        setValue("todos", todos.length > 0 ? todos : null);
        setValue("subcontractors", template?.subcontractors?.items || []);
      } else {
        if (task) {
          setValue("name", task?.name);
          setValue("billing", task?.billing);
          setValue("signingType", task?.signingType);
          setValue("tools", task?.tools || []);
          setValue("parts", task?.taskParts?.items || []);
          setValue("description", task?.description);
          setValue("subcontractors", task?.subcontractors?.items || []);
        } else {
          setValue("parentId", template?.id);
        }
      }
    }

    if (task) {
      setValue("estimateManHrs", task.estimateManHrs);
      setValue("hourlyRate", task.hourlyRate);
      setValue("signingType", task.signingType);
      setValue("description", task.description);
      const todos = task.tasks?.items.map((it) => ({ origId: it.id, ...it }));
      setValue("todos", todos.length > 0 ? todos : null);
    } else {
      setValue("estimateManHrs", theTemplate ? theTemplate?.estimateManHrs : template?.estimateManHrs);
      setValue("hourlyRate", theTemplate ? theTemplate?.hourlyRate : template?.hourlyRate);
      setValue("description", theTemplate ? theTemplate?.description : template?.description);
    }
  };

  const setupTaskForProject = () => {
    if (!project) return;

    setValue("projectId", project.id);
    setValue("projectName", project.name);
    setValue("parentName", task ? `${task.orderIndex} - ${task.name}` : project.name);

    if (action === "create") {
      setValue("parentId", task ? task.id : project.id);
      setValue("taskType", TaskType.Scheduled);
      setValue("scheduledDate", dayjs(project?.startDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("dueDate", dayjs(project?.endDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("signingType", settings.defaultSigningType);
    }

    if (action === "update") {
      if (!task) return;

      setValue("id", task.id);
      setValue("parentId", task.parentId);
      setValue("name", task.name);
      setValue("description", task.description);
      setValue("taskType", task.taskType);
      setValue("dueDate", dayjs(task.dueDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("scheduledDate", dayjs(task.scheduledDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("status", task.status);
      setValue("amount", task.amount || 0);
      setValue("signingType", task.signingType);
      setValue(
        "billing",
        task.billing || client?.settings?.defaultBillingType || settings.defaultBillingType || BillingType.Fixed,
      );
      setValue("hourlyRate", task.hourlyRate ?? (project.hourlyRate || 0.0));
      setValue("estimateManHrs", task.estimateManHrs);
      setValue(
        "assignedTo",
        task.users?.items.map((it) => it.userId),
      );
      setValue("parts", task.taskParts?.items || []);
      setValue("tools", task.tools || []);
      setValue("subcontractors", task.subcontractors?.items || []);
      const todos = task.tasks?.items.map((it) => ({ origId: it.id, ...it }));
      setValue("todos", todos.length > 0 ? todos : null);
    }
  };

  const saveSubcontractors = async (id: string, subcontractors: any[]) => {
    if (!subcontractors) return;

    const removedSubcontractors = existingSubcontractors?.filter(
      (it) => !subcontractors || !subcontractors.find((u) => u.subcontractorId === it.subcontractorId),
    );

    if (removedSubcontractors && removedSubcontractors?.length) {
      await Promise.all(
        removedSubcontractors.map(async (it) => {
          if (it.subcontractorId) await deleteSubcontractor({ taskId: id, id: it.subcontractorId });
        }),
      );
    }

    for (const sub of subcontractors) {
      if (!sub) continue;

      if (!sub.name) {
        throw new Error("Name is required");
      }

      const existingSubcontractor = existingSubcontractors?.find((it) => it.subcontractorId === sub.subcontractorId);
      if (existingSubcontractor) {
        if (sub.subcontractorId) {
          const input: any = { ...sub };

          input.markupCost = calculateMarkupCost({
            markupType: sub.markupType,
            markup: sub.markup.toString(),
            cost: sub.cost.toString(),
            quantity: "1",
          });

          await updateSubcontractor({
            input,
            taskId: id,
            id: sub.subcontractorId,
          });
        }
      } else {
        await addSubcontractor({
          taskId: id,
          input: {
            name: sub.name,
            cost: sub.cost,
            description: sub.description,
            markupType: sub.markupType,
            markup: sub.markup,
            markupCost: calculateMarkupCost({
              markupType: sub.markupType,
              markup: sub.markup.toString(),
              cost: sub.cost.toString(),
              quantity: "1",
            }),
          },
        });
      }
    }
  };

  const unassignPart = async (entityType: PartEntityType, entityId: string, id: string) => {
    const input = {
      id,
      parentEntityId: entityId,
      parentEntityType: entityType,
    };
    await deletePart(input, undefined, undefined, undefined, entityId);
  };

  const saveParts = async (entityType: PartEntityType, entityId: string, parts: any[]) => {
    const removedParts = existingParts?.filter((it) => !parts?.length || !parts.find((u) => u.id === it.id));

    if (removedParts?.length) {
      await Promise.all(
        removedParts.map(async (it) => {
          if (it.id) await unassignPart(entityType, entityId, it.id);
        }),
      );
    }

    if (!parts?.length) return;

    await Promise.all(
      parts.map(async (part) => {
        const existingPart = existingParts?.find((it) => it.id === part.id);
        if (existingPart) {
          const input = {
            id: part.id,
            parentEntityId: entityId,
            input:
              part.type === PartType.Assembly
                ? {
                    name: part.name,
                    number: part.number,
                    leadTimeDays: part.leadTimeDays,
                  }
                : ({
                    name: part.name,
                    number: part.number,
                    cost: part.cost,
                    leadTimeDays: part.leadTimeDays,
                    quantity: part.type === PartType.Consumable ? part.quantity : null,
                    isBillable: part.isBillable,
                    markupType: part.markupType,
                    markup: part.markup,
                  } as UpdatePartInput),
          };
          if (entityType === PartEntityType.Task) {
            await updatePart(input, entityId);
          } else {
            await updatePart(input, undefined, entityId);
          }
        } else {
          if (part.type === PartType.Assembly) {
            const input = {
              parentPartId: part.parentPartId,
              parentEntityType: entityType,
              leadTimeDays: part.leadTimeDays,
            };
            await assignAssemblyToParent({ parentEntityId: entityId, input }, entityId, undefined);
          } else {
            const input = {
              parentPartId: part.parentPartId,
              parentEntityType: entityType,
              cost: part.cost,
              leadTimeDays: part.leadTimeDays,
              quantity: part.type === PartType.Consumable ? part.quantity : null,
              isBillable: part.isBillable,
              markupType: part.markupType,
              markup: part.markup,
            } as AssignPartInput;

            await assignPartToParent(
              { parentEntityId: entityId, input },
              undefined,
              undefined,
              undefined,
              entityId,
              undefined,
            );
          }
        }
      }),
    );
  };

  const onSubmit = async () => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      let [errorType] = Object.keys(errors);
      let error = firstError as any;
      console.log(error);
      if (errorType === "tools" || errorType === "subcontractors" || errorType === "todos") {
        // These types of errors are arrays and need to be handled differently
        for (const key of Object.keys(error)) {
          if (key) {
            [error] = Object.values(error[key]);
            break;
          }
        }
      }
      snackbar.notify({ icon: "warning", title: error.message });
      return;
    }

    setLoading(true);

    try {
      const allValues = getValues();
      const { assignedTo, parts, tools, todos, subcontractors } = allValues;

      // Tools error handling

      try {
        if (tools) {
          for (const tool of tools) {
            if (!tool.name) {
              throw new Error("Tool name is required");
            }
          }
        }
      } catch (error) {
        showErrorMessage(error);
        return;
      }

      const submission: any = (({
        parentId,
        projectId,
        name,
        description,
        amount,
        taskType,
        signingType,
        dueDate,
        scheduledDate,
        status,
        inReview,
        estimateManHrs,
        hourlyRate,
        billing,
      }) => ({
        parentId,
        projectId,
        name,
        description,
        signingType,
        amount,
        estimateManHrs: estimateManHrs || 0,
        hourlyRate,
        dueDate: (dueDate ? dayjs(dueDate, "YYYY/MM/DD") : dayjs()).tz("UTC", true).toISOString(),
        scheduledDate: (scheduledDate ? dayjs(scheduledDate, "YYYY/MM/DD") : dayjs()).tz("UTC", true).toISOString(),
        taskType,
        status,
        inReview,
        billing,
      }))(allValues);

      if (submission.billing === BillingType.Fixed) {
        submission.hourlyRate = null;
      }

      if (action === "create") {
        submission.orderIndex =
          task?.orderIndex || isTemplate
            ? templateTodos?.reduce((acc, cur) => Math.max(acc, cur?.orderIndex || 0), 0) + 1
            : tasks?.reduce((acc, cur) => Math.max(acc, cur?.orderIndex || 0), 0) + 1;

        if (theTemplate) {
          const templateParts = theTemplate?.parts
            ? theTemplate?.parts?.items.map((it) => {
                if (it) {
                  return {
                    cost: it.cost,
                    name: it.name,
                    number: it.number,
                    quantity: it.quantity || 1,
                  };
                }
              })
            : [];
          // Creating task from template
          const templateTools = theTemplate?.tools
            ? theTemplate?.tools.map((it) => {
                if (it) {
                  return {
                    cost: it.cost,
                    isBillable: it?.isBillable,
                    leadTimeDays: it.leadTimeDays,
                    markup: it.markup || 0,
                    expirationDate: it.expirationDate ? dayjs(it.expirationDate).tz("UTC", true).toISOString() : null,
                    markupCost: it?.markupCost,
                    markupType: it.markupType,
                    name: it.name,
                    number: it.number,
                    quantity: it.quantity || 1,
                  };
                }
              })
            : [];

          const response = await addTaskFromTemplate({
            templateId: theTemplate.id,
            copyUsers: shouldCopyAssignedUsers,
            input: {
              ...submission,
              signingType: submission.signingType || null,
              tools: !templateTools
                ? ([] as ToolValuesT[])
                : templateTools.map((it: any | null) => ({
                    ...it,
                    markupCost: it.isBillable ? calculateMarkupCost(it) : undefined,
                    expirationDate: it.expirationDate ? dayjs(it.expirationDate).tz("UTC", true).toISOString() : null,
                  })),
            },
          });

          if (response) {
            if (template) {
              history.replace(`/templates/${template?.id}/tasks/${response.id}/edit`, {
                ...(locationState || {}),
                isNew: true,
              });
            } else {
              history.replace(`/work-orders/${project?.id}/tasks/${response.id}/edit`, {
                ...(locationState || {}),
                isNew: true,
              });
            }
          }
        } else {
          // Creating new task NOT from a template
          if (isTemplateTypeProject) {
            submission.estimateManHrs = 0;
            delete submission.dueDate;
            delete submission.scheduledDate;
          }

          const task = await addTask(
            {
              input: {
                ...submission,
                signingType: submission.signingType || null,
              },
            },
            isTemplateTypeProject,
          );

          if (!task) return;

          const { id: taskId } = task;

          if (assignedTo) {
            await Promise.all(assignedTo.map((userId) => assignUserToTask({ taskId, userId }, task)));
          }

          if (isTemplate) {
            history.replace(`/templates/${task.parentId}/tasks/${task.id}/edit?tab=Labor`, {
              ...(locationState || {}),
              isNew: true,
            });
          } else {
            history.replace(`/work-orders/${task.projectId}/tasks/${task.id}/edit?tab=Labor`, {
              ...(locationState || {}),
              isNew: true,
            });
          }
        }

        const theID = task?.id || template?.id;
        if (theID) {
          const promises = [];
          if ("subcontractors" in dirtyFields) {
            promises.push(saveSubcontractors(theID, subcontractors));
          }
          if ("parts" in dirtyFields) {
            promises.push(saveParts(PartEntityType.Task, theID, parts));
          }
          await Promise.all(promises);
        }

        return;
      }

      if (action === "update") {
        delete submission.parentId;
        delete submission.projectId;

        if (template) {
          delete submission.dueDate;
          delete submission.scheduledDate;
        }

        if (template && isTemplateTypeTask) {
          const input = {
            ...submission,
            signingType: submission.signingType || null,
            tools: !tools
              ? ([] as ToolValuesT[])
              : tools.map((it: ToolValuesT) => ({
                  ...it,
                  markupCost: it.isBillable ? calculateMarkupCost(it) : undefined,
                  expirationDate: it.expirationDate ? dayjs(it.expirationDate).tz("UTC", true).toISOString() : null,
                })),
          };
          const promises: any[] = [
            updateTaskTemplate(template?.id, input),
            saveSubcontractors(template?.id, subcontractors),
          ];
          if ("parts" in dirtyFields) {
            promises.push(saveParts(PartEntityType.Task, template?.id, parts));
          }

          await Promise.all(promises);
          // await getTemplates({});
          history.goBack();
        }

        // Updating todos
        if ("todos" in dirtyFields) {
          const todoParent = isTemplateTypeTask ? template : task;
          if (!todoParent) return;

          try {
            await Promise.all(
              todos?.map(async ({ origId, ...it }, i) => {
                if (origId && origId !== "new") {
                  // update existing
                  const submission: UpdateTaskRequest = {
                    ...it,
                    orderIndex: i + 1,
                  };

                  return await updateTask(
                    {
                      id: origId,
                      parentId: todoParent.id,
                      input: submission,
                    },
                    true,
                    isTemplateTypeProject,
                    isTemplateTypeTask,
                  );
                } else if (origId === "new") {
                  // create new
                  const sub: CreateTaskRequest = {
                    parentId: todoParent.id,
                    ...(task?.projectId && { projectId: task.projectId as any }),
                    ...(task?.scheduledDate && { scheduledDate: task.scheduledDate }),
                    ...(task?.dueDate && { dueDate: task.dueDate }),
                    description: "",
                    ...it,
                    orderIndex: i,
                  };
                  return await addTask({ input: sub }, isTemplateTypeProject, isTemplateTypeTask);
                }
              }),
            );
          } catch (error) {
            showErrorMessage(error);
          }
        }

        if (!task) return;

        try {
          await updateTask(
            {
              id: task.id,
              parentId: task.parentId,
              input: {
                ...submission,
                signingType: submission.signingType || null,
                amount: submission.amount || 0,
                tools: !tools
                  ? ([] as ToolValuesT[])
                  : tools.map((it: ToolValuesT) => ({
                      ...it,
                      markup: it.markup || 0,
                      markupCost: it.isBillable ? calculateMarkupCost(it) : undefined,
                      expirationDate: it.expirationDate ? dayjs(it.expirationDate).tz("UTC", true).toISOString() : null,
                    })),
              },
            },
            true,
            isTemplateTypeProject,
            isTemplateTypeTask,
          );
        } catch (error) {
          showErrorMessage(error);

          if (!error.message.includes("Cannot update a task that is")) {
            return;
          }
        }

        if (!template) {
          // Updating assigned
          const { id: taskId } = task;
          const newAssignedUsers = assignedTo?.filter((it) => !task?.users?.items?.find((u) => u.userId === it));
          const unassignedUsers = task?.users?.items
            ?.filter((it) => !assignedTo?.find((u) => u === it.userId))
            ?.map((it) => it.userId);

          let promises: any[] = [];

          if (newAssignedUsers) {
            promises = [
              ...promises,
              ...newAssignedUsers.map((userId) => {
                assignUserToTask({ taskId, userId });
              }),
            ];
          }

          if (unassignedUsers) {
            promises = [
              ...promises,
              ...unassignedUsers.map((userId) => {
                removeTaskUser({ id: taskId, userId });
              }),
            ];
          }

          await Promise.all(promises);
        }

        const theID = task?.id || template?.id;
        if (theID) {
          const promises = [];
          if ("subcontractors" in dirtyFields) {
            promises.push(saveSubcontractors(theID, subcontractors));
          }
          if ("parts" in dirtyFields) {
            promises.push(saveParts(PartEntityType.Task, theID, parts));
          }
          await Promise.all(promises);
        }

        if (locationState?.referrer === "/work-orders/new") {
          history.push(`/work-orders/${project?.id}`);
          return;
        }

        history.goBack();
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const goToNextStep = async () => {
    if (formStep === 0) {
      setFormStep(1);
    } else if (formStep === 1) {
      await onSubmit();
    }
  };

  const onClose = async () => {
    history.goBack();
  };

  const onTabActivated = (ev: any) => {
    setActiveTabIndex(ev.detail.index);
  };

  const onBackClicked = () => {
    if (action === "create") {
      setCreationType(null);
      setFormStep(FormSteps.ONE);
    } else {
      setActiveTabIndex((p) => (p !== 0 ? p - 1 : p));
    }
  };

  const onNextClicked = () => {
    setActiveTabIndex((p) => (p < 3 ? p + 1 : p));
  };

  const getTitle = () => {
    if (action === "create") {
      return "Create Task";
    }
    if (action === "update") {
      if (template && isTemplateTypeTask) {
        return template?.name;
      }

      if (task?.orderIndex) {
        return `${task?.orderIndex} - ${task?.name}`;
      } else {
        return `${task?.name}`;
      }
    }
  };

  const getConfirmButtonText = () => {
    return action === "create" ? "Next" : "Save";
  };

  useEffect(() => {
    if (billingType === BillingType.Fixed && !theTemplate) return;
    setValue("amount", estimatedHours * hourlyRate, { shouldDirty: true, shouldValidate: true });
  }, [estimatedHours, hourlyRate, billingType]);

  useEffect(() => {
    if (!project) return;
    setupTaskForProject();
  }, [project, task]);

  useEffect(() => {
    if (!template && !theTemplate) return;
    setupTaskFromTemplate();
  }, [template, task, theTemplate]);

  useEffect(() => {
    const [it] = templates.filter((t) => whichTemplate === t.id);
    if (it) {
      setTheTemplate(it);
    }
  }, [whichTemplate]);

  useEffect(() => {
    if (creationType === CreationType.NEW) {
      setFormStep(FormSteps.TWO);
      setWhichTemplate("");
      setTheTemplate(null);
    }
  }, [creationType]);

  useEffect(() => {
    const tab = query.get("tab");
    const tabIndex = tabs.indexOf(tab || "");
    setActiveTabIndex(tabIndex !== -1 ? tabIndex : 0);
  }, []);

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>{getTitle()}</DialogTitle>
      {formStep === FormSteps.TWO && theTemplate?.name && (
        <UsingTemplateMessage data-test-id="using-template">
          Using template &ldquo;{theTemplate?.name}&ldquo;
        </UsingTemplateMessage>
      )}

      {formStep === FormSteps.TWO && (
        <TabContainer style={{ paddingLeft: "30px" }}>
          {action === "update" && (
            <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
              {tabs.map((it, i) => (
                <Tab key={i} data-test-id={`task-dialog-tab-${i}`}>
                  {it}
                </Tab>
              ))}
            </TabBar>
          )}
          {action === "create" && (
            <div
              style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
              onClick={(ev) => ev.stopPropagation()}
            />
          )}
        </TabContainer>
      )}

      <DialogContent>
        <div style={{ display: formStep === FormSteps.ONE ? "block" : "none" }}>
          {!creationType && (
            <CreationActions>
              <Button raised onClick={() => setCreationType(CreationType.NEW)}>
                Create New Task
              </Button>
              <Button onClick={() => setCreationType(CreationType.TEMPLATE)}>Choose a Template</Button>
            </CreationActions>
          )}
          {creationType === CreationType.TEMPLATE && (
            <TaskFormStepOne
              setWhichTemplate={setWhichTemplate}
              whichTemplate={whichTemplate}
              shouldCopyAssignedUsers={shouldCopyAssignedUsers}
              setShouldCopyAssignedUsers={setShouldCopyAssignedUsers}
            />
          )}
        </div>

        <Form
          form={form}
          onSubmit={goToNextStep}
          style={{ display: formStep === FormSteps.TWO ? "block" : "none" }}
          data-test-id="task-form"
        >
          {HIDDEN_FIELD_NAMES.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          <TaskFormInfoContent
            style={{ display: activeTabIndex === 0 ? "block" : "none" }}
            {...{ form, setActiveTabIndex, action }}
          />
          <TaskFormLaborContent
            style={{ display: activeTabIndex === 1 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormToolsContent
            style={{ display: activeTabIndex === 2 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormSubcontractorsContent
            style={{ display: activeTabIndex === 3 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormTodoContent
            style={{ display: activeTabIndex === 4 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormPartsContent
            style={{ display: activeTabIndex === 5 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton outlined onClick={onClose}>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        {creationType && <Button onClick={onBackClicked}>Back</Button>}

        {task && tabs.length > 1 && activeTabIndex !== tabs.length - 1 && creationType && (
          <Button raised onClick={onNextClicked} data-test-id="task-next">
            Next
          </Button>
        )}

        {(creationType || action === "update") && (
          <DialogButton
            raised
            onClick={goToNextStep}
            disabled={(!taskName && !whichTemplate) || loading}
            icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
            data-test-id="task-next"
          >
            {getConfirmButtonText()}
          </DialogButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
