import React from "react";
import styled from "styled-components";
import numeral from "numeral";

// components
import { DisplayFlex } from "components/Utilities";
import { Table, TableHead } from "components/Table";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import { CircularProgress } from "@rmwc/circular-progress";
import EmptyState from "components/EmptyState";
import dayjs from "dayjs";

type Props = {
  reports: any;
  isLoading: boolean;
  shrinkToFit?: boolean;
};

const CellInnerWrapper = styled.div`
  display: flex;
  padding: 8px 0;
`;

const PulseReportsTable = ({ reports, isLoading, shrinkToFit }: Props) => {
  return (
    <Table>
      <DataTableContent>
        <TableHead cols={["Date", "Name", "Work Orders/Tasks", "Reaction", "Comment"]}></TableHead>

        <DataTableBody data-test-id="pulse-reports-list">
          {reports.length === 0 ? (
            <DataTableRow>
              <DataTableCell colSpan={5}>
                {isLoading ? <CircularProgress /> : <EmptyState icon="assessment" text={"No morale reports found."} />}
              </DataTableCell>
            </DataTableRow>
          ) : (
            reports.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell style={{ verticalAlign: "top" }}>
                  <CellInnerWrapper>{dayjs(it.date).format("MM/DD/YYYY")}</CellInnerWrapper>
                </DataTableCell>
                <DataTableCell style={{ verticalAlign: "top" }}>
                  <CellInnerWrapper>{it.user?.name}</CellInnerWrapper>
                </DataTableCell>
                <DataTableCell style={{ verticalAlign: "top" }}>
                  <CellInnerWrapper>
                    <DisplayFlex flexDirection="column">
                      {it.projectsAndTasks.map((p: any, i: number) => (
                        <DisplayFlex flexDirection="column" key={i} style={{ marginTop: i === 0 ? 0 : 8 }}>
                          <div style={{ fontWeight: 500, marginBottom: 2 }}>{p.project}</div>
                          <div>
                            {p.tasks.map((pt: any, pti: number) => (
                              <div key={pti}>
                                {numeral(pt.hours)
                                  .format("00:00")
                                  .replace(/:00([^:00]*)$/, "")}{" "}
                                - {pt.task}
                              </div>
                            ))}
                          </div>
                        </DisplayFlex>
                      ))}
                    </DisplayFlex>
                  </CellInnerWrapper>
                </DataTableCell>
                <DataTableCell style={{ verticalAlign: "top" }}>
                  <CellInnerWrapper>
                    <span style={{ fontSize: 24 }}>{it.emoji}</span>
                  </CellInnerWrapper>
                </DataTableCell>
                <DataTableCell style={{ verticalAlign: "top" }}>
                  <CellInnerWrapper
                    style={{ whiteSpace: "normal", maxWidth: "800px", ...(!shrinkToFit && { minWidth: "400px" }) }}
                  >
                    {it.comment}
                  </CellInnerWrapper>
                </DataTableCell>
              </DataTableRow>
            ))
          )}
        </DataTableBody>
      </DataTableContent>
    </Table>
  );
};

export default PulseReportsTable;
