import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar, { EventApi } from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Tooltip } from "@rmwc/tooltip";
import { flattenTaskParts } from "common";
import { Select } from "components/Select";
import { TASK_STATUS_BACKGROUNDS } from "components/StatusLabel";
import { CalendarDetails } from "components/schedule/CalendarDetails";
import dayjs from "dayjs";
import { useAdminApi } from "hooks/useAdminApi";
import { dialogQueue } from "hooks/useDialogQueue";
import useMultiTenant from "hooks/useMultiTenant";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { TaskStatus } from "types/admin/globalTypes";
import CalendarWrapper from "./CalendarWrapper";

const TaskSelectWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export function TaskCalendar() {
  const { projectId } = useParams<{ projectId: string }>();
  const { theme } = useMultiTenant();
  const { tasks, projects, listTasks, listTasksAndParts, updateTask } = useAdminApi();
  const history = useHistory();

  const [startDate, setStartDate] = React.useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs | null>(null);
  const [events, setEvents] = React.useState<any[]>([]);
  const [shifts, setShifts] = React.useState<UserShiftFields[]>([]);
  const [selectedTask, setSelectedTask] = React.useState<string | null>(null);

  const calendarRef = React.useRef<FullCalendar>(null);
  const initialDate = React.useMemo(() => dayjs().format("YYYY-MM-DD"), []);
  const project = projects.find((it) => it.id === projectId);

  const filtered = tasks
    .filter((it) => it.parentId === projectId && it.status !== TaskStatus.Deleted)
    .sort((a, b) => dayjs(a.scheduledDate).diff(dayjs(b.scheduledDate)));

  const taskOptions = filtered
    .map((it) => ({
      value: it.id,
      label: it.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!projectId) return;
    if (calendarRef.current.getApi().view.type !== "dayGridWeek") {
      calendarRef.current.getApi().changeView("dayGridWeek");
    }

    setEvents((events) => [
      ...events,
      ...filtered.map((it) => ({
        title: it.name,
        start: dayjs(it.scheduledDate).tz("UTC", true).format("YYYY-MM-DD"),
        end: dayjs(it.dueDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
        allDay: true,
        color: TASK_STATUS_BACKGROUNDS[it.status],
        extendedProps: { id: it.id },
      })),
    ]);
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;
    listTasksAndParts(projectId).then((tasks) => {
      setEvents((events) => [
        ...events,
        ...flattenTaskParts(tasks).map(({ part, task, path }) => ({
          title: `${part.name}${part.serialNumber ? ` : ${part.serialNumber}` : ""}`,
          start: dayjs(dayjs(task.scheduledDate).tz("UTC", true).format("YYYY-MM-DD"))
            .subtract(part.leadTimeDays || 0, "day")
            .toISOString(),
          extendedProps: { taskId: task.id, taskName: task.name, part, path },
        })),
      ]);
    });
  }, []);

  useEffect(() => {
    if (filtered.length > 0 || !projectId) return;

    // Get tasks and set the date to start if filtered tasks is empty / still loading
    listTasks(projectId).then((tasks) => {
      const sorted = tasks
        .filter((it) => it.status !== TaskStatus.Deleted)
        .map((it) => ({
          title: it.name,
          start: dayjs(it.scheduledDate).tz("UTC", true).format("YYYY-MM-DD"),
          end: dayjs(it.dueDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
          allDay: true,
          color: TASK_STATUS_BACKGROUNDS[it.status],
          extendedProps: { id: it.id },
        }))
        .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)));
      setEvents((events) => [...events, ...sorted]);

      if (sorted[0]?.start) {
        calendarRef.current.getApi().gotoDate(sorted[0]?.start);
      }
    });
  }, []);

  const handleDrag = async ({ event, revert }) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Change task dates?",
      body: `Are you sure you want to change the dates for task?`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) {
      revert();
      return;
    }

    const { start, end } = event;
    const { id: taskId } = event.extendedProps;
    const startDate = dayjs(start);
    const endDate = dayjs(end).subtract(1, "day");
    const task = tasks.find((it) => it.id === taskId);

    updateTask({
      id: taskId,
      parentId: task.parentId,
      input: {
        scheduledDate: startDate,
        dueDate: endDate,
      },
    });
  };

  const start = dayjs(project.startDate).tz("UTC", true).format("YYYY-MM-DD");
  const end = dayjs(project.endDate).tz("UTC", true).add(1, "day").format("YYYY-MM-DD");

  //Memoize to prevent infinite loop
  const validRange = React.useMemo(() => ({ end }), [start, end]);

  return (
    <CalendarWrapper>
      {selectedTask && (
        <CalendarDetails
          task={filtered.find((it) => it.id === selectedTask)}
          project={projects.find((it) => it.id === projectId)}
          onClose={() => setSelectedTask(null)}
        />
      )}

      <TaskSelectWrapper>
        <Select
          name="selected_task"
          options={taskOptions}
          placeholder={"View by task"}
          onChange={(event) =>
            history.push(`/work-orders/${projectId}/tasks/${(event.target as HTMLInputElement).value}/schedule`)
          }
          outlined
        />
      </TaskSelectWrapper>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={"dayGridWeek"}
        contentHeight={"auto"}
        events={events}
        displayEventTime={false}
        editable
        validRange={validRange}
        eventDrop={handleDrag}
        eventResize={handleDrag}
        initialDate={initialDate}
        customButtons={{
          reset: {
            text: "Clear task view",
            click: () => {
              setSelectedTask(null);
              setShifts([]);
              calendarRef.current.getApi().changeView("dayGridWeek");
            },
          },
        }}
        headerToolbar={{
          left: "prev,next,title",
          right: "",
        }}
        eventClick={({ event }) => {
          if (event.extendedProps.part) {
            history.push(`/work-orders/${projectId}/tasks/${event.extendedProps.taskId}/${event.extendedProps.path}`);
          }
          setSelectedTask(event.extendedProps.id);
        }}
        datesSet={(dates) => {
          setStartDate(dayjs(dates.start).utc(true));
          setEndDate(dayjs(dates.end).utc(true));
        }}
        eventContent={({ event }) => {
          if (event.extendedProps.part) {
            return PartCalendarCell(event, theme.primary);
          }
        }}
        ref={calendarRef}
      />
    </CalendarWrapper>
  );
}

const PartCalendarCell = (event: EventApi, accentColor: string) => {
  return (
    <Tooltip
      content={() => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>Task: {event._def.extendedProps.taskName}</div>
          {!!event._def.extendedProps.part?.leadTimeDays && (
            <div>
              {event._def.extendedProps.part.leadTimeDays} day
              {event._def.extendedProps.part.leadTimeDays.length === 1 ? "" : "s"}
            </div>
          )}
        </div>
      )}
    >
      <div
        className="fc-event-main-frame"
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "clip",
          width: "100%",
          padding: "2px",
        }}
      >
        <div className="fc-daygrid-event-dot" style={{ borderColor: accentColor }} />
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">{event._def.title || <div>&nbsp;</div>}</div>
        </div>
      </div>
    </Tooltip>
  );
};
