import React, { useEffect } from "react";

import useLayout from "hooks/useLayout";

import { PageHeading } from "components/PageStyles";

export const ReportsPage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: "Reports", path: "/reports" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeading>Reports</PageHeading>
    </>
  );
};
