import React, { createContext, useContext, useState, useEffect } from "react";
import { TenantSettingsInput } from "types/admin/globalTypes";
import { SetTenantSettingsMutationVariables } from "types/admin/SetTenantSettingsMutation";
import { TenantSettingsFields } from "types/admin/TenantSettingsFields";
import { useAdminApi } from "./useAdminApi";

interface SettingsContextT {
  settings: TenantSettingsFields;
  updateSettings: (settings: SetTenantSettingsMutationVariables) => Promise<void>;
  fetchSettings: () => Promise<any | undefined>;
}

export const SettingsContext = createContext({} as SettingsContextT);
export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider: React.FC = ({ children }) => {
  const { getTenantSettings, setTenantSettings } = useAdminApi();
  const [settings, setSettings] = useState({} as TenantSettingsFields);

  const fetchSettings = async () => {
    const them = await getTenantSettings();
    if (them) {
      setSettings(them);
      return them;
    }
  };

  useEffect(() => {
    if (settings?.name) return;
    fetchSettings();
  }, [getTenantSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        updateSettings: setTenantSettings,
        fetchSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
