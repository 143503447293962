import React, { useState } from "react";
import { useHistory } from "react-router";

import { useFirebase } from "hooks/useFirebase";
import { snackbar } from "hooks/useNotifications";

import { Button } from "components/Button";
import { Label } from "components/Label";
import { Divider, Root, SignInTitle, TextField } from "./signin";

import styled, { css } from "styled-components";

import Palette from "../palette.json";
import useMultiTenant from "hooks/useMultiTenant";
import { CommonForm } from "components/CommonForm";
import LoadingIndicator from "components/LoadingIndicator";
import { showErrorMessage } from "../utilities/handleError";
import { useLocation } from "react-router-dom";

const ResetMessage = styled.p<{ warn?: boolean }>`
  max-width: 290px;
  margin: -0.5rem 0 1rem;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.69;
  color: #3e3f42;

  ${(p) =>
    p.warn &&
    css`
      padding: 0.5rem 1rem;
      background-color: ${Palette.LighterGrey};
      border-radius: 0.5rem;
    `}
`;

export const SignInResetPage: React.FC = () => {
  const history = useHistory();
  const { sendPasswordResetEmail } = useFirebase();
  const { resetUserPassword } = useMultiTenant();

  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect");

  const [email, setEmail] = useState<string>("");
  const [hasSent, setSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (!resetUserPassword) return;
      const success = await resetUserPassword(email);

      if (success) {
        setSent(success);
        snackbar.notify({
          title: "Recovery email has been sent.",
        });
      } else {
        showErrorMessage("Unexpected error occurred");
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Root>
      {!hasSent && (
        <>
          <CommonForm {...{ onSubmit }}>
            <SignInTitle>Password Reset</SignInTitle>

            <ResetMessage>
              Enter your email address that you used to register. We’ll send an email with a link to reset your
              password.
            </ResetMessage>

            <Label>E-Mail Address</Label>
            <TextField type="text" name="email" value={email} onChange={(ev) => setEmail(ev.target.value)} />

            <Button
              raised
              disabled={email.length < 3}
              action="submit"
              type="submit"
              icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
            >
              Submit
            </Button>

            <div style={{ height: "38px" }} />
          </CommonForm>

          <Divider style={{ marginBottom: "0.5rem" }} />

          <Button action="cancel" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </>
      )}

      {hasSent && (
        <>
          <SignInTitle>Recovery Email Sent</SignInTitle>

          <ResetMessage>Please check your email for your password reset email.</ResetMessage>
          <ResetMessage warn>
            Please check your spam/junk folder if you don&apos;t see the email in your inbox.
          </ResetMessage>

          <Button raised onClick={() => history.goBack()}>
            Go Back
          </Button>
        </>
      )}
    </Root>
  );
};
