import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { TaskStatus } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";

import { Dialog } from "../Dialog";

import { Checkbox } from "@rmwc/checkbox";
import Palette from "../../palette.json";
import "@rmwc/checkbox/styles";

import { DialogActions, DialogButton, DialogContent, DialogTitle as DialogTitleBase } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import styled from "styled-components";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import { ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list";
import { Table, TableContainer } from "../Table";
import { useUser } from "hooks/params/useUser";
import { TaskFields } from "types/admin/TaskFields";
import { useTemplate } from "hooks/params/useTemplate";

const DialogTitle = styled(DialogTitleBase)`
  display: flex;
  align-items: center;

  border: none;

  > span {
    margin: 20px 3rem 0 0;
    flex-grow: 1;
  }

  > button {
    margin: 20px 0 0;
  }
`;

export const TaskUserMultiAssignDialog: React.FC = () => {
  const history = useHistory();

  const { assignUserToTasks, removeUserFromTasks } = useAdminApi();

  const project = useProject();
  const { template } = useTemplate();
  const user = useUser();

  const { tasks: allTasks } = useAdminApi();
  let tasks: TaskFields[] = [];
  if (project) {
    tasks = allTasks.filter(
      (it) => it.projectId === project?.id && it.parentId === project.id && it.status !== TaskStatus.Deleted,
    );
  } else if (template) {
    tasks = allTasks.filter((it) => it.parentId === template.id && it.status !== TaskStatus.Deleted);
  }

  const [assigned, setAssigned] = useState<TaskFields[]>([]);
  const [assignments, setAssignments] = useState<any[]>([]);

  useEffect(() => {
    if (!user) return;

    let tasks_: TaskFields[] = [];
    if (project) {
      tasks_ = allTasks.filter(
        (it) => it.projectId === project?.id && it.parentId === project.id && it.status !== TaskStatus.Deleted,
      );
    } else if (template) {
      tasks_ = allTasks.filter((it) => it.parentId === template.id && it.status !== TaskStatus.Deleted);
    }

    const assigned = tasks_.filter((it) => it.users?.items?.map((u) => u.userId).includes(user.id));
    setAssigned(assigned);

    const assignments = [];
    for (const task of tasks_) {
      if (assigned.includes(task)) {
        assignments.push({
          task: task,
          assigned: true,
        });
      } else {
        assignments.push({
          task: task,
          assigned: false,
        });
      }
    }
    setAssignments(assignments);
  }, [user, allTasks]); // useEffect on tasks triggers multiple updates every second so allTasks is used instead

  const onSubmit = async () => {
    if (!user) return;

    const changes = assignments.filter((it) => {
      const wasAssigned = assigned.includes(it.task);
      const isAssigned = it.assigned;

      if (!wasAssigned && isAssigned) {
        return true;
      } else if (wasAssigned && !isAssigned) {
        return true;
      }
      return false;
    });

    const assignedIds = changes.filter((it) => it.assigned).map((it) => it.task.id);
    const unassignedIds = changes.filter((it) => !it.assigned).map((it) => it.task.id);
    if (assignedIds.length > 0) {
      await assignUserToTasks({ taskIds: assignedIds, userId: user.id });
    }

    if (unassignedIds.length > 0) {
      await removeUserFromTasks({ taskIds: unassignedIds, userId: user.id });
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss renderToPortal>
      <DialogTitle>
        <span>Assign User</span>
      </DialogTitle>

      <DialogContent style={{ minWidth: "30vw" }}>
        <TableContainer>
          <Table>
            <DataTableContent>
              <DataTableBody>
                {tasks.length === 0 && (
                  <DataTableRow>
                    <DataTableCell
                      colSpan={3}
                      style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                    >
                      - No Tasks -
                    </DataTableCell>
                  </DataTableRow>
                )}
                {tasks.map((it, i) => (
                  <DataTableRow key={i}>
                    <DataTableCell>
                      <ListItemText>
                        <ListItemPrimaryText style={{ marginTop: "-20px" }}>{it.name}</ListItemPrimaryText>
                        <ListItemSecondaryText>{it.orderIndex}</ListItemSecondaryText>
                      </ListItemText>
                    </DataTableCell>

                    <DataTableCell>
                      <Checkbox
                        checked={assignments.find((jt) => jt.task.id === it.id)?.assigned || false}
                        onClick={() => {
                          const checked = assignments.find((jt) => jt.task.id === it.id)?.assigned || false;
                          if (checked) {
                            setAssignments([
                              ...assignments.filter((jt) => jt.task.id !== it.id),
                              { task: it, assigned: false },
                            ]);
                          } else {
                            setAssignments([
                              ...assignments.filter((jt) => jt.task.id !== it.id),
                              { task: it, assigned: true },
                            ]);
                          }
                        }}
                      />
                    </DataTableCell>
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTableContent>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <DialogButton onClick={history.goBack}>Close</DialogButton>
        <DialogButton raised onClick={onSubmit}>
          Done
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
