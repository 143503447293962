import React from 'react'
import styled from 'styled-components';

type Props = {
  children: React.ReactNode
  enabled: boolean
}

const LineThrough = styled.span<Props>`
  text-decoration: ${props => props.enabled ? 'line-through' : 'none'};
`;

export default LineThrough