import React, { useEffect } from "react";

import { PartFields } from "types/admin/PartFields";
import { ToolFields } from "types/admin/ToolFields";

import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";

import { PartList } from "components/tasks/PartList";
import { ToolList } from "components/tasks/ToolList";
import { SummaryCard } from "components/SummaryCard";
import numeral from "numeral";
import { SubcontractorList } from "components/tasks/SubcontractorList";
import { LaborList } from "components/tasks/LaborList";
import { useHistory } from "react-router-dom";
import { getCost } from "../../../../../common";
import { PartType } from "types/admin/globalTypes";
import useTranslations from "hooks/useTranslations";

export const formatPrice = (v: number) => {
  return "$" + numeral(v).format("0,0.00");
};

export const TaskCostsPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const project = useProject();
  const { task } = useTask();
  const history = useHistory();

  const partsTotal = task?.taskParts?.items.reduce((total, part) => {
    let quantity = part.quantity;
    // If part is serializable and has children parts, the quantity is the number of children parts
    if (part.parts.items.length > 0 && (part.type === PartType.Expendable || part.type === PartType.Rotable)) {
      quantity = part.parts.items.length;
    }
    return total + (part?.isBillable ? getCost(part) || 0 : 0) * quantity;
  }, 0);

  const laborTotal = task?.amount;

  const toolsTotal = task?.tools?.reduce((total, tool) => {
    return total + (tool?.isBillable ? getCost(tool) || 0 : 0);
  }, 0);

  const subcontractorsTotal = task?.subcontractors?.items?.reduce((total, sub) => {
    return total + (getCost(sub) || 0);
  }, 0);

  const total = (partsTotal || 0) + (laborTotal || 0) + (toolsTotal || 0) + (subcontractorsTotal || 0);

  if (!project || !task) return <></>;

  const partItems = (task.taskParts?.items ?? []).map((part) => {
    if (part.parts.items.length > 0 && (part.type === PartType.Expendable || part.type === PartType.Rotable)) {
      part.quantity = part.parts.items.length;
    }
    return { ...part, taskId: part.parentEntityId, name: part.name };
  }) as any[];

  return (
    <>
      <div style={{ display: "flex", overflowX: "auto" }}>
        <SummaryCard
          label={tCommon("Parts")}
          value={partsTotal ? formatPrice(partsTotal as number) : "$0"}
          onButtonPress={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Parts`)}
        />
        <SummaryCard
          label="Labor"
          value={laborTotal ? formatPrice(laborTotal as number) : "$0"}
          onButtonPress={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Labor`)}
        />
        <SummaryCard
          label={tCommon("Tools")}
          value={toolsTotal ? formatPrice(toolsTotal as number) : "$0"}
          onButtonPress={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Tools`)}
        />
        <SummaryCard
          label="Subcontractors"
          value={subcontractorsTotal ? formatPrice(subcontractorsTotal as number) : "$0"}
          onButtonPress={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/edit?tab=Subcontractors`)}
        />
        <SummaryCard label="Total Task Costs" value={formatPrice(total as number)} />
      </div>
      <div style={{ margin: "2rem" }} />
      <PartList total={partsTotal} items={partItems} />
      <div style={{ margin: "2rem" }} />
      <LaborList total={laborTotal || 0} task={task} />
      <div style={{ margin: "2rem" }} />
      <ToolList total={toolsTotal} items={task.tools || ([] as ToolFields[])} />
      <div style={{ margin: "2rem" }} />
      <SubcontractorList total={subcontractorsTotal} items={task.subcontractors?.items || []} />
    </>
  );
};
