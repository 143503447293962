import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { useAdminApi } from "hooks/useAdminApi";
import { useTemplate } from "hooks/params/useTemplate";
import { useProject } from "hooks/params/useProject";

import { useForm } from "react-hook-form";

import { Label } from "../Label";
import { Dialog } from "../Dialog";
import { CommonForm, FormField, FormRow } from "../CommonForm";
import { TextInput } from "../TextInput";
import { TextArea } from "../TextArea";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { CreateProjectTemplateRequest } from "types/admin/globalTypes";
import { snackbar } from "hooks/useNotifications";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

interface ProjectTemplateFormDialogProps {
  action: "create" | "update";
}

export const ProjectTemplateFormDialog: React.FC<ProjectTemplateFormDialogProps> = ({ action }) => {
  const { addProjectTemplate, updateProjectTemplate } = useAdminApi();
  const { projectId, templateId } = useParams<{ projectId?: string; templateId?: string }>();
  const [copyAssignedUsers, setCopyAssignedUsers] = useState<boolean>(false);

  const history = useHistory();
  const project = useProject();
  const { template } = useTemplate();

  const form = useForm<CreateProjectTemplateRequest>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      name: "",
      estimateManHrs: 0,
      hourlyRate: 0,
    },
  });

  const { formState, register, setValue } = form;
  const { isDirty } = formState;

  const title = action === "create" ? "Create Work Order Template" : "Update Work Order Template";

  const onSubmit = form.handleSubmit(async (values) => {
    if (action === "create") {
      if (projectId) {
        const template = await addProjectTemplate(projectId, copyAssignedUsers, {
          ...values,
        });
        if (template) {
          history.push(`/templates/${template.id}`);
          snackbar.notify({ title: "Template created successfully" });
        }
      }
    } else if (action === "update") {
      if (templateId) {
        const template = await updateProjectTemplate(templateId, {
          ...values,
        });
        if (template) {
          snackbar.notify({ title: "Template updated successfully" });
          history.goBack();
        }
      }
    }
  });

  useEffect(() => {
    if (template) {
      setValue("name", `${template?.name}`, { shouldDirty: true, shouldValidate: true });
      setValue("estimateManHrs", `${template?.estimateManHrs}`, { shouldDirty: true, shouldValidate: true });
      setValue("hourlyRate", `${template?.hourlyRate}`, { shouldDirty: true, shouldValidate: true });
      setValue("description", `${template?.description}`, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue("name", `Template of ${project?.name}`, { shouldDirty: true, shouldValidate: true });
      setValue("estimateManHrs", `${project?.estimateManHrs}`, { shouldDirty: true, shouldValidate: true });
      setValue("hourlyRate", `${project?.hourlyRate}`, { shouldDirty: true, shouldValidate: true });
      setValue("description", `${project?.description}`, { shouldDirty: true, shouldValidate: true });
    }
  }, [template]);

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm onSubmit={onSubmit} data-test-id="project-template-form">
          <FormRow>
            <FormField style={{ flexGrow: 1 }}>
              <Label htmlFor="name">Template Name</Label>
              <TextInput name="name" ref={register({ required: true })} />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField style={{ flexGrow: 1 }}>
              <Label htmlFor="description">Description</Label>
              <TextArea name="description" ref={register({ required: true })} style={{ marginBottom: 0 }} />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField style={{ flexGrow: 1 }}>
              <Label htmlFor="hourlyRate">Hourly Rate</Label>
              <TextInput name="hourlyRate" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          {action === "create" && (
            <FormRow style={{ marginBottom: 0 }}>
              <FormField style={{ flexGrow: 1 }}>
                <Checkbox
                  checked={copyAssignedUsers}
                  onChange={(evt) => setCopyAssignedUsers(!!evt.currentTarget.checked)}
                >
                  Copy assigned users
                </Checkbox>
              </FormField>
            </FormRow>
          )}

          <TextInput name="estimateManHrs" disabled ref={register()} hidden />
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton onClick={history.goBack} outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        <DialogButton onClick={onSubmit} raised disabled={!isDirty}>
          {action === "create" ? "Create Template" : "Update Template"}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
