import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router";

import { TaskFields } from "types/admin/TaskFields";

import useLayout from "hooks/useLayout";

import { useTemplate } from "hooks/params/useTemplate";
import { useTask } from "hooks/params/useTask";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";

import { TabContainer } from "components/TabContainer";
import { TaskList } from "components/tasks/TaskList";
import { TemplateTaskAttachmentsPage } from "./attachments";
import { AttachmentFormDialog } from "components/attachments/AttachmentFormDialog";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";
import { TemplateType } from "types/admin/globalTypes";
import { TemplateTaskCostsPage } from "./costs";
import { TodoFormDialog } from "components/tasks/TodoFormDialog";
import { TemplateTaskPeoplePage } from "./people";

import * as Pages from "../../../../../pages";
import { TemplateTaskPartsPage } from "./parts";
import useTranslations from "hooks/useTranslations";
import { TaskFormDialog } from "components/tasks/TaskFormDialog";

export const TemplateTaskViewPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const location = useLocation();

  const { setBreadCrumbs } = useLayout();

  const { template } = useTemplate();
  const { task } = useTask();

  const resourcePrefix = `/templates/${template?.id}/tasks/${task?.id}`;

  const PAGE_TABS = [
    {
      icon: "checklist",
      label: "Todos",
      path: `${resourcePrefix}/todos`,
    },
    {
      icon: "people",
      label: "People",
      path: `${resourcePrefix}/people`,
    },
    {
      icon: "construction",
      label: tCommon("Parts"),
      path: `${resourcePrefix}/parts`,
    },
    {
      icon: "paid",
      label: "Costs",
      path: `${resourcePrefix}/costs`,
    },
    {
      icon: "upload_file",
      label: "Attachments",
      path: `${resourcePrefix}/attachments`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!template || !task) return;

    setBreadCrumbs([
      {
        label: "Templates",
        path: "/templates",
      },
      {
        label: template?.name,
        path: `/templates/${template?.id}`,
      },
      {
        label: `${task.orderIndex} - ${task.name}`,
        path: `${resourcePrefix}`,
      },
    ]);
  }, [setBreadCrumbs, template, task]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit")) return;
    history.replace(path);
  };

  if (!template || !task) {
    return <Route component={Pages.Error404} />;
  }

  return (
    <>
      <Route path="/templates/:templateId/tasks/:taskId/attachments/new">
        <AttachmentFormDialog action="create" />
      </Route>

      <Route exact path={`/templates/:templateId/tasks/:taskId/todos/new`}>
        <TodoFormDialog action="create" />
      </Route>

      <Route exact path={`/templates/:templateId/tasks/:taskId/edit`}>
        <TaskFormDialog action="update" />
      </Route>

      <PageHeader
        compact
        editable
        title={`${task.orderIndex ? `${task.orderIndex} - ` : ""}${task.name}`}
        subtitle={template.name}
        description={task.description}
        onEditClick={() => history.push(`${resourcePrefix}/edit`)}
        onSubtitleClick={() => history.push(`/templates/${template.id}`)}
        templateType={TemplateType.Project}
      />

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain style={{ paddingTop: "32px" }}>
        <PageContent>
          <Switch>
            <Route path="/templates/:templateId/tasks/:taskId/costs">
              <TemplateTaskCostsPage />
            </Route>
            <Route path="/templates/:templateId/tasks/:taskId/parts">
              <TemplateTaskPartsPage />
            </Route>
            <Route path="/templates/:templateId/tasks/:taskId/attachments">
              <TemplateTaskAttachmentsPage />
            </Route>
            <Route path="/templates/:templateId/tasks/:taskId/people">
              <TemplateTaskPeoplePage />
            </Route>

            <Route path={["/templates/:templateId/tasks/:taskId", "/templates/:templateId/tasks/:taskId/todos"]}>
              <TaskList items={task.tasks?.items as TaskFields[]} />
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
