import gql from "graphql-tag";
import { PartFields } from "./parts";
import { ProjectFields } from "./projects";

export const AircraftFields = gql`
  ${ProjectFields}
  ${PartFields}

  fragment AircraftFields on Aircraft {
    id
    type
    model
    make
    tailNumber
    serialNumber
    clientId
    airframeCycles
    airframeHours
    engine1TotalTime
    engine1Cycles
    engine2TotalTime
    engine2Cycles
    APUHobbs

    parts {
      items {
        ...PartFields
      }
    }

    activeProjects: projects(status: Accepted) {
      items {
        ...ProjectFields
      }
    }

    completedProjects: projects(status: Completed) {
      items {
        ...ProjectFields
      }
    }
  }
`;

export const getAircraftQuery = gql`
  ${AircraftFields}

  query GetAircraft($id: ID!) {
    asAdmin {
      getAircraft(id: $id) {
        ...AircraftFields
      }
    }
  }
`;

export const listAircraftQuery = gql`
  ${AircraftFields}

  query ListAircraft($clientId: ID) {
    asAdmin {
      listAircraft(clientId: $clientId) {
        items {
          ...AircraftFields
        }
      }
    }
  }
`;

export const addAircraftMutation = gql`
  ${AircraftFields}

  mutation AddAircraft($input: CreateAircraftRequest!) {
    asAdmin {
      addAircraft(input: $input) {
        ...AircraftFields
      }
    }
  }
`;

export const updateAircraftMutation = gql`
  ${AircraftFields}

  mutation UpdateAircraft($id: ID!, $input: UpdateAircraftRequest!) {
    asAdmin {
      updateAircraft(id: $id, input: $input) {
        ...AircraftFields
      }
    }
  }
`;

export const deleteAircraftMutation = gql`
  mutation DeleteAircraft($id: ID!) {
    asAdmin {
      deleteAircraft(id: $id) {
        id
      }
    }
  }
`;
