import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { snackbar } from "hooks/useNotifications";
import debounce from "lodash/debounce";
import { PROJECT_ROLE_OPTIONS, USER_ROLE_OPTIONS } from "../../common";
import { ProjectRole, UserRole, UserWorkPayType, LocationInfoInput } from "types/admin/globalTypes";
import { useAdminApi } from "hooks/useAdminApi";
import { useProject } from "hooks/params/useProject";
import { useUser } from "hooks/params/useUser";
import { Dialog } from "../Dialog";
import { Form, FormRow, Text, Select, Phone, CountryCode, Checkbox } from "components/Form";
import { Checkbox as BasicCheckbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";
import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import Palette from "../../palette.json";
import LoadingIndicator from "../LoadingIndicator";
import { showErrorMessage } from "../../utilities/handleError";
import { FormField } from "components/CommonForm";
import { AutocompleteInput } from "components/AutocompleteInput";
import { Label } from "components/Label";
import { LocationFields } from "types/admin/LocationFields";
import useMultiTenant from "hooks/useMultiTenant";

interface FormValuesT {
  id?: string;

  clientId: string;

  type?: UserWorkPayType;
  hasSigningAuthority?: boolean;

  projectId: string;
  projectRole?: ProjectRole | "Administrator";

  firstName: string;
  lastName: string;

  email: string;
  countryCode: string;
  phoneNumber: string;

  userRole: UserRole;
  payChex: {
    id: string;
    orgUnit: string;
  };

  aAndPNumber?: string;

  locations: LocationInfoInput[];
}

interface UserFormDialogProps {
  action: "create" | "update";
  renderToPortal?: boolean;
  onGoBack?: () => void;
  onAddUser?: (id: string) => void;
}
export const UserFormDialog: React.FC<UserFormDialogProps> = ({ action, renderToPortal, onGoBack, onAddUser }) => {
  const history = useHistory();
  const { theme } = useMultiTenant();
  const location = useLocation<{ referrer: string }>();

  const query = new URLSearchParams(location.search);

  const { me, addUser, updateUser, searchLocationsByName } = useAdminApi();

  const project = useProject();
  const user = useUser();

  const [doAnother, setDoAnother] = useState<boolean>(false);
  const [forceSendEmail, setForceSendEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [locationOptions, setLocationOptions] = useState<LocationFields[]>([]);

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientId: query.get("clientId") || project?.clientId || "",
      projectId: project?.id || "",
      projectRole: user?.projectRole ?? ProjectRole.technician,
      userRole: user?.userRole ?? UserRole.User,
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      countryCode: user?.phoneNumber ? user!.phoneNumber.replace(/^(\+\d{1,2})(\d{3})(\d{3})(\d{4})$/, "$1") : "+1",
      phoneNumber: user?.phoneNumber ? user!.phoneNumber.slice(-10) : "",
      payChex: {
        id: "",
        orgUnit: "",
      },
      type: user?.type || UserWorkPayType.Hourly,
      hasSigningAuthority: user?.hasSigningAuthority || false,
      locations: user?.locations || [],
      aAndPNumber: user?.aAndPNumber || "",
    },
  });
  const { formState, register, setValue, setError, watch, control } = form;
  const { isDirty } = formState;

  const userRole = watch("userRole");
  const projectRole = watch("projectRole");
  const userType = watch("type");
  const locations = watch("locations");
  const title = `${action === "create" ? "Create" : "Update"} User`;

  const locationAutocompleteOptions = locationOptions
    .filter((it) => !locations.map((it) => it.id).includes(it.id))
    .map((it) => ({
      value: it.id,
      label: it.name,
    }));

  const validatePaycheckType = (value: UserWorkPayType | undefined | null) => {
    if (!value) {
      if (projectRole !== ProjectRole.technician && projectRole !== ProjectRole.technicianInspector) {
        return true;
      }
      setError("type", { type: "manual", message: "Please select a type" });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!setValue || !user) return;

    if (query.get("clientId")) setValue("clientId", query.get("clientId"));

    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);

    setValue("email", user.email);

    setValue("userRole", user?.userRole ?? UserRole.User);
    setValue("projectRole", user?.projectRole ?? ProjectRole.technician);
    setValue("payChex.id", user.payChex?.id);
    setValue("payChex.orgUnit", user.payChex?.orgUnit);
    setValue("type", user.type || UserWorkPayType.Hourly);
    setValue("hasSigningAuthority", user?.hasSigningAuthority || false);
    setValue("locations", user?.locations || []);
    setValue("aAndPNumber", user?.aAndPNumber || "");
  }, []);

  const onLocationSelected = (locationId: string) => {
    const location = locationOptions.find((it) => it.id === locationId);
    setValue("locations", [...form.getValues().locations, { id: locationId, name: location?.name }]);
  };

  const queryByName = debounce(async (name: string) => {
    const locations = await searchLocationsByName({ name: name.toLowerCase() });
    setLocationOptions(locations);
  }, 250);

  const onClose = async () => {
    if (onGoBack) {
      onGoBack();
      return;
    }

    history.goBack();
  };

  const onSubmit = form.handleSubmit(async (formValues) => {
    if (!formValues) return;
    if (!validatePaycheckType(formValues.type)) {
      throw new Error("Please select a type");
    }

    setLoading(true);

    if (formValues.userRole === UserRole.Admin || formValues.userRole === UserRole.AdminManager) {
      delete formValues.projectRole;
    }

    const considerType =
      !project ||
      formValues.projectRole === ProjectRole.technician ||
      formValues.projectRole === ProjectRole.technicianInspector;
    const values: any = {
      ...formValues,
      locations: formValues.locations || [],
      phoneNumber: `${formValues.countryCode}${formValues.phoneNumber}`,
      type: considerType ? formValues.type : UserWorkPayType.Hourly,
    };

    if (action === "create") {
      try {
        const userFields = await addUser(values);
        snackbar.notify({
          title: !project ? "User created successfully!" : "User created and added to work order successfully!",
        });
        if (onAddUser && userFields) {
          onAddUser(userFields.id);
        }

        if (doAnother) {
          form.reset();
          return;
        } else {
          onClose();
        }
      } catch (err) {
        if (err.message.includes("phone number already exists")) {
          setError("phoneNumber", { type: "manual", message: "Number already taken" });
        } else {
          showErrorMessage(err);
        }
      } finally {
        setLoading(false);
      }
    } else if (action === "update") {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        await updateUser({
          id: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber || user.phoneNumber,
          userRole: values.userRole,
          projectRole: values.projectRole,
          payChex: {
            id: values.payChex?.id,
            orgUnit: values.payChex?.orgUnit,
          },
          type: values.type,
          resendEmail: forceSendEmail,
          hasSigningAuthority: values.hasSigningAuthority,
          aAndPNumber: values.aAndPNumber,
          locations: (values.locations || []).map((it) => ({ id: it.id, name: it.name })), // Have to map in order to get rid of __typename
        });
        snackbar.notify({ title: "User updated successfully!" });

        if (doAnother) {
          form.reset();
          return;
        } else {
          onClose();
        }
      } catch (err) {
        if (err.message.includes("phone number already exists")) {
          setError("phoneNumber", { type: "manual", message: "Number already taken" });
        } else {
          showErrorMessage(err);
        }
      } finally {
        setLoading(false);
      }
    }
  });

  const paycheckTypeOpts: { value: string; label: string }[] = [];

  for (const [k, v] of Object.entries(UserWorkPayType)) {
    paycheckTypeOpts.push({ label: k, value: v });
  }

  return (
    <Dialog open preventOutsideDismiss renderToPortal={renderToPortal}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Form form={form} onSubmit={onSubmit} data-test-id="user-form">
          <input type="hidden" name="clientId" ref={register} />
          <input type="hidden" name="projectId" ref={register} />

          <FormRow>
            <Text name="firstName" label="First Name" required />
            <Text name="lastName" label="Last Name" required />
          </FormRow>

          <FormRow>
            <Text name="email" label="Email Address" required />
          </FormRow>

          <FormRow style={{ alignItems: "end" }}>
            <CountryCode name="countryCode" fieldStyles={{ display: "none" }} hidden required />
            <Phone name="phoneNumber" label="Phone Number" required />
            <Checkbox name="hasSigningAuthority" label="Has Signing Authority" />
          </FormRow>

          <FormRow>
            <Select name="userRole" label="User Role (Type)" outlined options={USER_ROLE_OPTIONS} />
          </FormRow>

          {userRole === UserRole.User && (
            <FormRow>
              <Select name="projectRole" label="Default Role" outlined options={PROJECT_ROLE_OPTIONS} />
            </FormRow>
          )}

          {userRole !== UserRole.Admin && projectRole !== ProjectRole.customer && (
            <>
              <FormRow>
                <FormField style={{ flexGrow: 1, width: "100%" }}>
                  <Label htmlFor="Locations">Locations</Label>
                  <Controller
                    name="locations"
                    {...{ control }}
                    render={() => (
                      <AutocompleteInput
                        disabled={me?.userRole !== UserRole.Admin}
                        onChange={(val) => queryByName(val)}
                        options={[...locationAutocompleteOptions] as any}
                        select={(id) => onLocationSelected(id)}
                        absolute
                        shouldClearOnSelect
                      />
                    )}
                  />
                </FormField>
              </FormRow>

              {locations.length > 0 && (
                <FormRow style={{ marginTop: -20 }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      ...(me.userRole === UserRole.Admin && { cursor: "pointer" }),
                    }}
                  >
                    {locations.map((it, i) => (
                      <div style={{ display: "flex", alignItems: "center" }} key={i}>
                        <p
                          style={{ paddingRight: "1rem", color: theme.primary, fontSize: 14 }}
                          onClick={() => {
                            if (me.userRole !== UserRole.Admin) return;
                            setValue(
                              "locations",
                              locations.filter((jt) => jt.id !== it.id),
                            );
                          }}
                        >
                          {it.name || ""}
                        </p>

                        {i !== locations.length - 1 && (
                          <div
                            style={{
                              marginRight: "1rem",
                              backgroundColor: "black",
                              width: "5px",
                              height: "5px",
                              borderRadius: "9999px",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                  </div>
                </FormRow>
              )}
            </>
          )}

          <FormRow>
            <Text
              name="aAndPNumber"
              label="A&P Number"
              required={user?.projectRole === ProjectRole.technicianInspector || user?.hasSigningAuthority}
            />
          </FormRow>

          {(!project || projectRole === ProjectRole.technician || projectRole === ProjectRole.technicianInspector) && (
            <FormRow>
              <Text name="payChex.id" label="Payroll Identifier" required={userType === UserWorkPayType.Hourly} />
              <Text name="payChex.orgUnit" label="Org Unit" />
            </FormRow>
          )}

          <FormRow>
            {!project || projectRole === ProjectRole.technician || projectRole === ProjectRole.technicianInspector ? (
              <Select name="type" label="Payroll Pay Type" outlined options={paycheckTypeOpts} />
            ) : (
              <input type="hidden" name="type" ref={register} />
            )}
          </FormRow>
        </Form>
      </DialogContent>

      <DialogActions>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={onClose}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        {action === "create" && (
          <BasicCheckbox
            style={{ marginRight: "20px", fontSize: "14px" }}
            label="Create Another"
            checked={doAnother}
            onClick={() => setDoAnother((p) => !p)}
          />
        )}
        {action === "update" && (
          <BasicCheckbox
            style={{ marginRight: "10px" }}
            label="Resend Email"
            checked={forceSendEmail}
            onChange={(ev) => setForceSendEmail(ev.currentTarget.checked)}
          />
        )}
        <DialogButton
          raised
          disabled={!isDirty || loading}
          onClick={onSubmit}
          data-test-id="save-user"
          icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
        >
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
