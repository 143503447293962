import styled from "styled-components";

export const DiscrepancyIndicator = styled.div`
  background-color: var(--mdc-theme-error);
  border-radius: 100%;
  width: 12px;
  height: 12px;
`;

export const DiscrepancyWrapper = styled.div`
  min-width: 350px;
  margin-bottom: 16px;
  background-color: rgba(230, 73, 45, 0.05);
  border-radius: 4px;
`;

export const DiscrepancyHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(230, 73, 45, 0.1);

  ${DiscrepancyIndicator} {
    margin-right: 8px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--mdc-theme-error);
  }
`;

export const DiscrepancyDetails = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

export const DiscrepancyDates = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscrepancyDate = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  span:first-of-type {
    font-size: 12px;
    line-height: 1;
  }

  span:last-of-type {
    font-weight: 500;
  }
`;

export const DiscrepancyDescription = styled.p`
  margin-top: 12px;
  font-size: 14px;
`;

export const DiscrepancyActions = styled.div`
  margin-top: 12px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(230, 73, 45, 0.1);

  Button {
    padding: 0 10px;
    margin-left: 8px;
    font-size: 12px;
    height: 28px;
  }
`;
