import gql from "graphql-tag";

import { UserFields } from "./users";

export const TimesheetFields = gql`
  ${UserFields}

  fragment TimesheetFields on TimeSheetEntry {
    id

    projectId
    taskId
    userId

    projectSummary
    taskSummary

    status

    amountMins

    fromDT
    toDT

    date

    startLocation {
      id
      name
    }
    startLat
    startLng

    stopLocation {
      id
      name
    }
    stopLat
    stopLng

    userFromDT
    userToDT

    user {
      ...UserFields
    }

    history {
      lastChangedBy
      changes {
        fromDT
        toDT
        userId
        userName
        md
        autoClockOut
        projectId
        projectSummary
        taskId
        taskSummary
      }
    }

    autoClockOut

    discrepancy {
      description
      adminMessage
      status
      fromDTCorrected
      toDTCorrected
    }

    conflictingTimesheets {
      items {
        id
        fromDT
        toDT
        userId
        taskId
        projectId
        taskSummary
        projectSummary
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const clockInUserMutation = gql`
  ${TimesheetFields}

  mutation ClockInUser($userId: ID!, $taskId: ID!, $fromDt: AWSDateTime!) {
    asAdmin {
      clockInUser(userId: $userId, taskId: $taskId, fromDt: $fromDt) {
        ...TimesheetFields
      }
    }
  }
`;

export const clockOutUserMutation = gql`
  ${TimesheetFields}

  mutation ClockOutUser($userId: ID!, $taskId: ID!, $toDt: AWSDateTime!) {
    asAdmin {
      clockOutUser(userId: $userId, taskId: $taskId, toDt: $toDt) {
        ...TimesheetFields
      }
    }
  }
`;

export const addTimeSheetEntryMutation = gql`
  ${TimesheetFields}

  mutation AddTimeSheetEntry($input: CreateTimeEntrySheetRequest!) {
    asAdmin {
      addTimeSheetEntry(input: $input) {
        ...TimesheetFields
      }
    }
  }
`;

export const listTimesheetsQuery = gql`
  ${TimesheetFields}

  query ListTimesheets($fromDT: AWSDateTime!, $toDT: AWSDateTime!, $nextToken: String) {
    asAdmin {
      listTimeSheets(fromDT: $fromDT, toDT: $toDT, nextToken: $nextToken) {
        items {
          ...TimesheetFields
        }
        nextToken
      }
    }
  }
`;

export const listProjectTimesheetsQuery = gql`
  ${TimesheetFields}
  query ListProjectTimesheets($projectId: ID!, $nextToken: String) {
    asAdmin {
      listAllTimeSheets(status: Completed, projectId: $projectId, nextToken: $nextToken) {
        items {
          ...TimesheetFields
        }
        nextToken
      }
    }
  }
`;

export const listAllInProgressTimesheetsQuery = gql`
  ${TimesheetFields}

  query ListAllInProgressTimesheets {
    asAdmin {
      listAllTimeSheets(status: InProgress) {
        items {
          ...TimesheetFields
        }
      }
    }
  }
`;

export const updateTimeSheetEntryMutation = gql`
  ${TimesheetFields}

  mutation UpdateTimeSheetEntry($id: ID!, $userId: ID!, $input: UpdateTimeSheetEntryRequest!) {
    asAdmin {
      updateTimeSheetEntry(id: $id, userId: $userId, input: $input) {
        ...TimesheetFields
      }
    }
  }
`;

export const approveTimeSheetEntryMutation = gql`
  mutation ApproveTimeSheetEntry($id: ID!, $userId: ID!, $input: ApproveTimeSheetEntryRequest!) {
    asAdmin {
      approveTimeSheetEntry(id: $id, userId: $userId, input: $input)
    }
  }
`;

export const approveTimeSheetEntriesMutation = gql`
  mutation ApproveTimeSheetEntries($ids: [ID!]!, $userId: ID!, $input: ApproveTimeSheetEntryRequest!) {
    asAdmin {
      approveTimeSheetEntries(ids: $ids, userId: $userId, input: $input)
    }
  }
`;

export const updateTimeSheetEntryDiscrepancyMutation = gql`
  ${TimesheetFields}

  mutation UpdateTimeSheetEntryDiscrepancy($id: ID!, $userId: ID!, $input: UpdateTimeSheetDiscrepancyRequest!) {
    asAdmin {
      updateTimeSheetEntryDiscrepancy(id: $id, userId: $userId, input: $input) {
        ...TimesheetFields
      }
    }
  }
`;

export const deleteTimeSheetEntryMutation = gql`
  mutation DeleteTimeSheetEntry($id: ID!, $userId: ID!) {
    asAdmin {
      deleteTimeSheetEntry(id: $id, userId: $userId) {
        id
      }
    }
  }
`;

export const onUpdatedTimeSheetEntrySubscription = gql`
  ${TimesheetFields}

  subscription OnUpdatedTimeSheetEntry($tenantId: ID!) {
    onUpdatedTimeSheetEntry(tenantId: $tenantId) {
      event
      timesheetVal
      timesheet {
        ...TimesheetFields
      }
    }
  }
`;
