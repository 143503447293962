import React from "react";
import { Button } from "../Button";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../../palette.json";
import styled from "styled-components";
import useMultiTenant from "hooks/useMultiTenant";
import { FullScreenMessage } from "components/FullScreenMessage";

const ConfirmButton = styled(Button)<{ [props: string]: any }>`
  margin: 2rem 0 1rem 0;
  align-self: center;
`;

interface SigningConfirmationScreenProps {
  onConfirm: () => void;
  children?: any;
}

export const SigningConfirmationScreen: React.FC<SigningConfirmationScreenProps> = ({ onConfirm, children }) => {
  return (
    <FullScreenMessage
      header="Ready to submit"
      message="Press the button to confirm all your signatures. Leaving this page without confirming will result in your signatures not being saved."
      icon="question_mark"
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ConfirmButton raised onClick={onConfirm}>
          Submit
        </ConfirmButton>

        {children && children}
      </div>
    </FullScreenMessage>
  );
};
