import React, { useEffect } from "react";
import { Dialog } from "./Dialog";
import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import Palette from "../palette.json";
import get from "lodash/get";

import { Table, TableContainer, TableHead } from "./Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { useProject } from "hooks/params/useProject";
import { useAdminApi } from "hooks/useAdminApi";
import { Icon } from "@rmwc/icon";
import { TaskStatus } from "types/admin/globalTypes";
import { generateCompletedProjectReportXLSX } from "common";

interface CompleteProjectReportDialogProps {
  onClose: () => void;
}

const CompleteProjectReportDialog: React.FC<CompleteProjectReportDialogProps> = function ({ onClose }) {
  const cols: string[] = ["Actual Hours", "Estimated Hours", "Efficiency"];
  const project = useProject();
  const { tasks: allTasks, clients: allClients } = useAdminApi();
  const tasks = allTasks
    .filter((task) => task.projectId === project?.id)
    .filter((task) => task.status !== TaskStatus.Deleted);
  const [totalActualHours, setTotalActualHours] = React.useState(0);
  const [totalEstimatedHours, setTotalEstimatedHours] = React.useState(0);
  const [efficiency, setEfficiency] = React.useState(0);
  const [client] = allClients.filter((client) => client.id === project?.clientId);

  const downloadXLSX = async () => {
    await generateCompletedProjectReportXLSX(tasks, client?.name, project?.name);
  };

  // calculate summary totals
  useEffect(() => {
    // get total number of actual hours
    let actualHours = 0;
    let estimatedHours = 0;
    tasks.forEach((task) => {
      if (task && task.progress && task?.estimateManHrs) {
        const completed: number = Math.round(get(task, "progress.completedHours", 0) * 100) / 100;
        actualHours += completed;
        estimatedHours += get(task, "estimateManHrs", 0) || 0;
      }
    });

    setTotalEstimatedHours(estimatedHours);
    setTotalActualHours(actualHours);

    if (estimatedHours) {
      let calculatedEfficiency = estimatedHours / actualHours;
      calculatedEfficiency = calculatedEfficiency * 100;
      calculatedEfficiency = Math.round(calculatedEfficiency * 100) / 100;
      setEfficiency(Math.min(calculatedEfficiency, 100));
    }
  }, []);

  return (
    <Dialog open renderToPortal preventOutsideDismiss>
      <DialogTitle>Download Report for {project?.name}</DialogTitle>

      <DialogContent>
        <div>
          <p style={{ marginBottom: "12px" }}>
            Below is a summary for this work order&apos;s actual hours vs estimated hours. For a full report, download a
            CSV with an itemized list of tasks and actual vs estimated hours.
          </p>

          <TableContainer>
            <Table>
              <DataTableContent>
                <TableHead {...{ cols }} />

                <DataTableBody>
                  <DataTableRow>
                    <DataTableCell>{totalActualHours.toLocaleString()}</DataTableCell>
                    <DataTableCell>{totalEstimatedHours.toLocaleString()}</DataTableCell>
                    <DataTableCell style={{ color: efficiency > 100 ? Palette.Red : Palette.Green }}>
                      {efficiency}%
                    </DataTableCell>
                  </DataTableRow>
                </DataTableBody>
              </DataTableContent>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton onClick={onClose} outlined>
            Close
          </DialogButton>
        </ThemeProvider>

        <DialogButton raised onClick={downloadXLSX}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <Icon icon="file_download" style={{ marginRight: 8, color: "#ffffff" }} />
            Download Report
          </span>
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteProjectReportDialog;
