import React from "react";

import { useTemplate } from "hooks/params/useTemplate";
import { useTask } from "hooks/params/useTask";

import { AttachmentList } from "components/attachments/AttachmentList";

export const TemplateTaskAttachmentsPage: React.FC = () => {
  const { template } = useTemplate();
  const { task: parentTask } = useTask();

  if (!template) return <></>;

  const items = parentTask?.attachments?.items || template?.attachments?.items;

  console.log(template);

  return (
    <>
      <AttachmentList type="template" items={items || []} />
    </>
  );
};
