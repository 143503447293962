import { TabBar } from "@rmwc/tabs";
import styled from "styled-components";

export const TabContainer = styled.div`
  position: relative;

  padding-left: 32px;
  border-top: 1px solid #eaedf3;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;

  display: flex;
  flex-direction: column;

  .mdc-tab-scroller__scroll-content {
    width: 100%;
    justify-content: flex-start;
    flex: 0 0 auto;
  }

  .mdc-menu-surface--anchor {
    margin-right: 16px;
  }

  button {
    margin: 0 10px;

    padding: 20px 0 20px;
    height: auto;

    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;

    background-color: white;

    :first-of-type {
      margin-left: 0;
    }

    .mdc-tab__ripple {
      display: none;
    }

    .mdc-tab-indicator__content {
      border-top-width: 4px;
    }
  }
`;

export const TabBarWithShadow = styled<any>(TabBar)`
  .mdc-tab-scroller {
    position: relative;
    padding-right: 10px;

    @media screen and (max-width: 1440px) {
      &::after {
        content: "";
        display: block;

        /* create a shadow on the right side of the div */
        position: absolute;
        top: 0;
        z-index: 0;
        right: 0px;
        bottom: 0;
        width: 10px;
        pointer-events: none;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
      }
    }
  }
`;

export const TabBarRightActions = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  right: 62px;
  display: flex;
  align-items: center;
`;
