import jsPDF from "jspdf";
import PDFLib, { PDFDocument } from "pdf-lib";
import autoTable from "jspdf-autotable";
import { useAdminApi } from "hooks/useAdminApi";
import { TaskFields } from "types/admin/TaskFields";
import { ProjectFields } from "types/admin/ProjectFields";
import { downloadPDF, flattenParts, toDataURL } from "common";
import { useFirebase } from "./useFirebase";
import RIIStamp from "../assets/images/rii-stamp.png";
import dayjs from "dayjs";
import { SigningEntityType } from "types/admin/globalTypes";
import useTemporaryUser from "./useTemporaryUser";
import { useCorrectiveActions } from "./useCorrectiveActions";

export function truncate(string: string, length: number) {
  if (!string) return "";
  return string.length > length ? `${string.substring(0, length)}...` : string;
}

const partsTable = (doc: any, startY: number, parts: any[]) => {
  autoTable(doc, {
    startY,
    headStyles: {
      fillColor: null,
      textColor: [0, 0, 0],
      fontStyle: "bold",
      fontSize: 9,
      cellPadding: 0.8,
      minCellHeight: 8,
    },
    alternateRowStyles: { fillColor: null },
    bodyStyles: { fontSize: 9, cellPadding: 0.8, fillColor: null },
    margin: 6,
    head: [["Qty", "Part Number", "Serial Number", "Manf. Date", "Exp. Date", "Description"]],
    body:
      parts.map(({ quantity, number, serialNumber, manufactureDate, expirationDate, name }) => [
        quantity || 1,
        number,
        serialNumber,
        manufactureDate ? dayjs(manufactureDate).format("MM/DD/YYYY") : "",
        expirationDate ? dayjs(expirationDate).format("MM/DD/YYYY") : "",
        truncate(name, 28),
      ]) || [],
  });
};

export enum AttachmentType {
  TaskAttachments = "TaskAttachments",
  TaskAndPartsAttachments = "TaskAndPartsAttachments",
  PartsAttachments = "PartsAttachments",
  None = "None",
}

export default function useInspectionForm() {
  const { getCorrectiveActions } = useCorrectiveActions();
  const { clients, entities, listTaskSignatures } = useAdminApi();
  const { user } = useFirebase();
  const { user: tempUser } = useTemporaryUser();

  const generate = async (
    task: TaskFields,
    project: ProjectFields,
    data?: any,
    includeAttachments = AttachmentType.None,
    download = true,
  ) => {
    const client = data ? data.client : clients.find((client) => client.id === project?.clientId);
    const entity = data ? data.entity : entities.find((entity) => entity.id === project?.aircraftId);
    const parts = flattenParts(data ? data.task.parts : task?.taskParts?.items || []);
    const mainParts = parts.slice(0, 10);
    const partsOverflow = parts.slice(10) || [];

    const actions =
      data?.task?.correctiveActions || getCorrectiveActions ? await getCorrectiveActions({ taskId: task.id }) : [];

    const todos = data ? data.task.tasks.items : task?.tasks.items || [];

    const signaturesAndStamps = !data ? await listTaskSignatures(task.id) : undefined;
    const signatures = signaturesAndStamps
      ?.filter((it) => it.entityType === SigningEntityType.TaskInspectionForm)
      .sort((a, b) => (dayjs(a.signedDate).isBefore(dayjs(b.signedDate)) ? -1 : 1));
    const stamps = signaturesAndStamps?.filter((it) => it.entityType === SigningEntityType.TaskInspectionFormStamp);
    console.log(signaturesAndStamps);

    const doc = new jsPDF("portrait", "mm", "letter");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let verticalOffset = 6;
    let horizontalOffset = 4;
    let h = 0;

    doc.setFontSize(9);
    doc.setFont("helvetica");
    doc.text("TASK INSPECTION FORM", horizontalOffset, verticalOffset);

    //Box 1
    doc.setLineWidth(0.4);
    doc.rect(horizontalOffset, verticalOffset + 2, pageWidth - horizontalOffset * 2, 36, "S");

    //Task Name
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    h = doc.getTextDimensions(task.name).h;
    verticalOffset += h + 4;
    doc.text(task.name || "", 6, verticalOffset);

    //Task Name
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(110, 110, 110);
    h = doc.getTextDimensions(project.name).h;
    verticalOffset += h + 1;
    doc.text(project.name || "", 6, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(horizontalOffset + 2, verticalOffset + 3, pageWidth / 4, verticalOffset + 3, "S");

    //Requesting Name
    const userName = tempUser?.displayName || user?.displayName || "";
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    h = doc.getTextDimensions(userName).h;
    verticalOffset += h + 5;
    doc.text(userName, 6, verticalOffset);

    //Due date
    let text = `Due: ${dayjs(task.dueDate).format("MM/DD/YYYY")}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 6, verticalOffset);

    //Estimated time
    text = `Estimated time: ${task.estimateManHrs} hrs`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 6, verticalOffset);

    //Aircraft
    text = `${entity.make ? `${entity.make}${entity.model ? ` ${entity.model}` : ""}` : entity.type} -- ${
      entity.tailNumber || entity.serialNumber
    }`;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    h = doc.getTextDimensions(text || "").h;
    verticalOffset = h + 10;
    doc.text(text || "", 120, verticalOffset);

    //Customer
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions(client.name || "").h;
    verticalOffset += h + 2;
    doc.text(client.name || "", 120, verticalOffset);

    //Address
    if (client?.address?.street1) {
      doc.setFontSize(9);
      h = doc.getTextDimensions(client.address.street1).h;
      verticalOffset += h + 6;
      doc.text(client.address.street1, 120, verticalOffset);
    }
    if (client?.address?.street1) {
      h = doc.getTextDimensions(client.address.street2).h;
      verticalOffset += h + 1;
      doc.text(client.address.street2, 120, verticalOffset);
    }
    if (client?.address?.city && client?.address?.state) {
      text = `${client.address.city}, ${client.address.state}`;
      h = doc.getTextDimensions(text || "").h;
      verticalOffset += h + 1;
      doc.text(text || "", 120, verticalOffset);
    }
    if (client?.address?.zip) {
      h = doc.getTextDimensions(client.address.zip).h;
      verticalOffset += h + 1;
      doc.text(client.address.zip, 120, verticalOffset);
    }

    //Description box
    doc.setLineWidth(0.2);
    verticalOffset = 50;
    doc.rect(4, 50, 140, 28, "S");

    //Description heading
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions("Work Description").h;
    verticalOffset += h + 2;
    doc.text("Work Description", 6, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(horizontalOffset + 2, verticalOffset + 3, 142, verticalOffset + 3, "S");

    //Description
    doc.setFontSize(9);
    h = doc.getTextDimensions(task.description || "").h;
    verticalOffset += h + 6;
    doc.text(task.description || "", 6, verticalOffset, { maxWidth: 136 });

    //Aircraft box
    doc.setLineWidth(0.2);
    verticalOffset = 50;
    doc.rect(148, verticalOffset, 64, 70, "S");

    //Aircraft heading
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions("Aircraft Details").h;
    verticalOffset += h + 2;
    doc.text("Aircraft Details", 150, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(150, verticalOffset + 3, 150 + 60, verticalOffset + 3, "S");

    //Aircraft details
    doc.setFontSize(9);
    text = `Make: ${entity.make}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 6;
    doc.text(text || "", 150, verticalOffset);

    text = `Model: ${entity.model}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Serial Number: ${entity.serialNumber}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Tail Number: ${entity.tailNumber}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Aiframe Cycles: ${entity.airframeCycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Airframe Hours: ${entity.airframeHours}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 1 Total Time: ${entity.engine1TotalTime}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 1 Cycles: ${entity.engine1Cycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 2 Total Time: ${entity.engine2TotalTime}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `Eng. 2 Cycles: ${entity.engine2Cycles}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    text = `APU Hobbs: ${entity.APUHobbs}`;
    h = doc.getTextDimensions(text || "").h;
    verticalOffset += h + 2;
    doc.text(text || "", 150, verticalOffset);

    // //Assigned box
    // doc.setLineWidth(0.2);
    // verticalOffset = 82;
    // doc.rect(4, verticalOffset, 35, 85, "S");

    // //Assigned heading
    // doc.setFontSize(10);
    // doc.setFont("helvetica", "normal");
    // h = doc.getTextDimensions("Assigned").h;
    // verticalOffset += h + 2;
    // doc.text("Assigned", 6, verticalOffset);

    // //Line
    // doc.setLineWidth(0.4);
    // doc.line(horizontalOffset + 2, verticalOffset + 3, 35, verticalOffset + 3, "S");

    // //Assigned
    // verticalOffset += 3;
    // task?.users?.items?.forEach(({ user }) => {
    //   doc.setFontSize(9);
    //   h = doc.getTextDimensions(user.name || "").h;
    //   verticalOffset += h + 2;
    //   doc.text(user.name || "", 6, verticalOffset);
    // });

    //Corrective actions box
    doc.setLineWidth(0.2);
    verticalOffset = 82;
    horizontalOffset = 4;
    doc.rect(horizontalOffset, verticalOffset, 140, 85, "S");
    const actionsBoxHeight = verticalOffset + 85;

    //Corrective actions heading
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    h = doc.getTextDimensions("Corrective Actions").h;
    verticalOffset += h + 2;
    doc.text("Corrective Actions", horizontalOffset + 2, verticalOffset);

    //Line
    doc.setLineWidth(0.4);
    doc.line(horizontalOffset + 2, verticalOffset + 3, horizontalOffset + 140, verticalOffset + 3, "S");

    //Corrective actions
    verticalOffset += 3;
    const actionsOverflow = [];

    actions?.forEach(({ date, message, user }) => {
      const blockVOffset = verticalOffset + 4;
      const blockHOffset = 6;

      if (blockVOffset + 20 > actionsBoxHeight) {
        if (actionsOverflow.length === 0) {
          doc.setFontSize(8);
          doc.text("...continued on next page" || "", blockHOffset, verticalOffset + 5);
        }
        actionsOverflow.push({ date, message, user });
        return;
      }

      //Date
      doc.setFontSize(8);
      text = dayjs(date).format("MM/DD/YY");
      h = doc.getTextDimensions(text).h;
      verticalOffset = blockVOffset + h;
      doc.text(text || "", blockHOffset, verticalOffset);

      //Message
      const content = doc.splitTextToSize(truncate(message, 256), 87);
      h = doc.getTextDimensions(content).h;

      verticalOffset = blockVOffset + h > verticalOffset ? blockVOffset + h : verticalOffset;
      doc.text(content || "", blockHOffset + 14, blockVOffset + 3);
    });

    //Parts box
    doc.setLineWidth(0.2);
    doc.rect(4, actionsBoxHeight + 4, pageWidth - 8, 68, "S");

    //Line
    doc.setLineWidth(0.4);
    doc.line(6, actionsBoxHeight + 13, pageWidth - 6, actionsBoxHeight + 13, "S");

    //Table
    partsTable(doc, actionsBoxHeight + 6, mainParts);

    if (partsOverflow.length > 0) {
      doc.setFontSize(8);
      doc.text("...continued on next page" || "", 6, pageHeight - 42);
    }

    verticalOffset = pageHeight - 30;

    let i = 0;
    while (i < 2) {
      //X
      doc.setFontSize(14);
      doc.setFont("serif", "bold");
      doc.text("X", 6, verticalOffset);

      //Line
      doc.setLineWidth(0.2);
      doc.setDrawColor(0, 0, 0);
      verticalOffset = verticalOffset + 2;
      doc.line(4, verticalOffset, pageWidth - 4, verticalOffset, "S");

      //Signature
      doc.setFontSize(8);
      doc.setTextColor(130, 130, 130);
      doc.setFont("helvetica", "normal");
      verticalOffset += 3;
      doc.text("Signature", 8, verticalOffset);

      //Print Name
      doc.setFontSize(8);
      doc.setTextColor(130, 130, 130);
      doc.setFont("helvetica", "normal");
      doc.text("Print Name", pageWidth / 2, verticalOffset);

      //Print Date
      doc.setFontSize(8);
      doc.setTextColor(130, 130, 130);
      doc.setFont("helvetica", "normal");
      doc.text("Date", pageWidth - 25, verticalOffset);
      verticalOffset += 12;
      doc.setTextColor(0, 0, 0);
      i++;
    }

    // Actual Signatures and Signing Data
    if (signatures?.length > 0) {
      verticalOffset = pageHeight - 30;

      const signatureOne = signatures[0];

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");
      doc.text(signatureOne.userName || "", pageWidth / 2, verticalOffset);

      if (signatureOne.signedDate) {
        doc.text(dayjs(signatureOne.signedDate).format("MM/DD/YYYY"), pageWidth - 28, verticalOffset);
      }

      if (signatureOne.signatureAttachment.file.url) {
        const logoDataURL = await toDataURL(signatureOne.signatureAttachment.file.url);
        const logoProperties = doc.getImageProperties(logoDataURL);
        const whRatio = logoProperties.width / logoProperties.height;
        const logoHeight = 12;
        const logoWidth = logoHeight * whRatio;
        doc.addImage(logoDataURL, "PNG", 10, verticalOffset - 8, logoWidth, logoHeight);
      }

      if (signatureOne.userAAndPNumber) {
        doc.setFontSize(8);
        doc.setTextColor(130, 130, 130);
        doc.setFont("helvetica", "normal");
        doc.text(`A&P #: ${signatureOne.userAAndPNumber}`, pageWidth / 2 + 20, verticalOffset + 5);
      }

      if (signatures.length > 1) {
        const signatureTwo = signatures[1];

        verticalOffset += 17;

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        doc.text(signatureTwo.userName || "", pageWidth / 2, verticalOffset);

        if (signatureTwo.signedDate) {
          doc.text(dayjs(signatureTwo.signedDate).format("MM/DD/YYYY"), pageWidth - 28, verticalOffset);
        }

        if (signatureTwo.signatureAttachment.file.url) {
          const logoDataURL = await toDataURL(signatureTwo.signatureAttachment.file.url);
          const logoProperties = doc.getImageProperties(logoDataURL);
          const whRatio = logoProperties.width / logoProperties.height;
          const logoHeight = 12;
          const logoWidth = logoHeight * whRatio;
          doc.addImage(logoDataURL, "PNG", 10, verticalOffset - 8, logoWidth, logoHeight);
        }

        if (signatureTwo.userAAndPNumber) {
          doc.setFontSize(8);
          doc.setTextColor(130, 130, 130);
          doc.setFont("helvetica", "normal");
          doc.text(`A&P #: ${signatureTwo.userAAndPNumber}`, pageWidth / 2 + 20, verticalOffset + 5);
        }
      }
    }

    const horizontalMargins = { left: 4, right: 4 };

    let addedPage = false;

    if (partsOverflow.length > 0) {
      if (!addedPage) {
        doc.addPage();
      }

      // Title
      autoTable(doc, {
        startY: addedPage ? false : 6,
        head: [["Parts (continued)"]],
        headStyles: {
          fontSize: 12,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          cellPadding: { top: addedPage ? 5 : 0, bottom: 0 },
          textColor: [0, 0, 0],
        },
        margin: { ...horizontalMargins },
      });

      // Table
      autoTable(doc, {
        showHead: "firstPage",
        headStyles: {
          fontSize: 9,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          textColor: [0, 0, 0],
        },
        alternateRowStyles: { fillColor: null },
        showFoot: "never",
        bodyStyles: {
          cellPadding: 4,
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        pageBreak: "avoid",
        rowPageBreak: "avoid",
        head: [["Qty", "Part Number", "Serial Number", "Manf. Date", "Exp. Date", "Description"]],
        startY: false,
        margin: { left: 6, right: 6 },
        body:
          parts.map(({ quantity, number, serialNumber, manufactureDate, expirationDate, name }) => [
            quantity || 1,
            number,
            serialNumber,
            manufactureDate,
            expirationDate,
            truncate(name, 28),
          ]) || [],
      });

      addedPage = true;
    }

    if (actionsOverflow.length > 0) {
      if (!addedPage) {
        doc.addPage();
      }

      // Title
      autoTable(doc, {
        startY: addedPage ? false : 6,
        head: [["Corrective Actions (continued)"]],
        headStyles: {
          fontSize: 12,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          cellPadding: { top: addedPage ? 5 : 0, bottom: 0 },
          textColor: [0, 0, 0],
        },
        margin: { ...horizontalMargins },
      });

      // Table
      autoTable(doc, {
        showHead: "firstPage",
        headStyles: {
          fontSize: 9,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          textColor: [0, 0, 0],
        },
        pageBreak: "avoid",
        rowPageBreak: "avoid",
        alternateRowStyles: { fillColor: null },
        showFoot: "never",
        bodyStyles: {
          cellPadding: 4,
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        head: [["Date", "Note"]],
        startY: false,
        margin: { left: 6, right: 6 },
        body: actionsOverflow.map(({ date, message }) => [dayjs(date).format("MM/DD/YYYY"), message]) || [],
      });

      addedPage = true;
    }

    const todosInfo = { pageHeight, coordinates: [] };
    if (todos.length > 0) {
      if (!addedPage) {
        doc.addPage();
      }

      // Title
      autoTable(doc, {
        startY: addedPage ? false : 6,
        head: [["Todos"]],
        headStyles: {
          fontSize: 12,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          cellPadding: { top: addedPage ? 5 : 0, bottom: 0 },
          textColor: [0, 0, 0],
        },
        margin: { ...horizontalMargins },
      });

      // Table
      autoTable(doc, {
        headStyles: {
          fontSize: 9,
          fontStyle: "bold",
          font: "helvetica",
          fillColor: null,
          textColor: [0, 0, 0],
        },
        pageBreak: "avoid",
        rowPageBreak: "avoid",
        alternateRowStyles: { fillColor: null },
        showFoot: "never",
        bodyStyles: {
          cellPadding: 4,
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        head: [["Name"]],
        startY: false,
        margin: { left: 6, right: 6 },
        body: todos.map((todo) => [todo.name]),
        didDrawCell: (data) => {
          if (data.section === "body") {
            todosInfo.coordinates.push({
              page: data.table.startPageNumber,
              y: data.cell.y,
              id: todos[data.row.index]?.id,
            });
          }
        },
      });

      addedPage = true;
    }

    if (todosInfo.coordinates.length > 0) {
      for (const coord of todosInfo.coordinates) {
        const stamp = stamps?.find((stamp: any) => stamp.todoId === coord.id);
        if (!stamp) continue;

        doc.setPage(coord.page);
        const stampDataURL = await toDataURL(RIIStamp);
        const stampProperties = doc.getImageProperties(stampDataURL);
        const whRatio = stampProperties.width / stampProperties.height;
        const stampHeight = 11;
        const stampWidth = stampHeight * whRatio;
        doc.addImage(
          stampDataURL,
          "PNG",
          pageWidth - 8 - stampWidth,
          coord.y - 0.75,
          stampWidth,
          stampHeight,
          null,
          null,
          346,
        );
      }
    }

    // Convert pdf to PDFDocument from 'pdf-lib' library
    const newPDF = await PDFDocument.create();
    // Loading currently generated PDF doc as array buffer
    const docBlob = doc.output("arraybuffer");
    // Loading array buffer to pdf-lib library
    const docPDF = await PDFDocument.load(docBlob);
    const copiedPages = await newPDF.copyPages(
      docPDF,
      Array(docPDF.getPageCount())
        .fill(0)
        .map((it, i) => i),
    );
    // Copy pages of the generated PDF to the new PDF
    copiedPages.forEach((page) => newPDF.addPage(page));

    if (includeAttachments) {
      const attachments = (() => {
        switch (includeAttachments) {
          case AttachmentType.TaskAttachments:
            return task.attachments.items;
          case AttachmentType.PartsAttachments:
            return parts.reduce(
              (acc: any[], part) => [...acc, ...part.attachments.items.map((it) => ({ attachment: it, part }))],
              [],
            );
          case AttachmentType.TaskAndPartsAttachments:
            return [
              ...task.attachments.items,
              ...parts.reduce(
                (acc: any[], part) => [...acc, ...part.attachments.items.map((it) => ({ attachment: it, part }))],
                [],
              ),
            ];
          default:
            return [];
        }
      })();

      // Loading and adding attachments to the new PDF
      for (const attachment of attachments) {
        const url = attachment.file?.url || attachment.attachment?.file?.url;
        const attachmentArrayBuffer = await (await fetch(url)).arrayBuffer();

        const fileType = attachment.fileType || attachment.attachment?.fileType;
        const mimeType = attachment.mimeType || attachment.attachment?.mimeType;

        if (fileType === "Image" && ["image/jpeg", "image/png", "image/jpg"].includes(mimeType)) {
          const image = await (mimeType === "image/png"
            ? newPDF.embedPng(attachmentArrayBuffer)
            : newPDF.embedJpg(attachmentArrayBuffer));
          const page = newPDF.addPage();

          const { pageWidth, pageHeight } = { pageWidth: page.getWidth(), pageHeight: page.getHeight() - 10 };

          const dimensions = (() => {
            const { width, height } = image;

            if (width <= pageWidth && height <= pageHeight) return { width, height };

            const ratio = width / height;
            const maxDimension = height > width ? "h" : "w";

            if (maxDimension === "w") {
              return { width: pageWidth, height: pageWidth / ratio };
            }

            return { height: pageHeight, width: pageHeight * ratio };
          })();

          page.drawImage(image, {
            x: pageWidth / 2 - dimensions.width / 2,
            y: pageHeight / 2 - dimensions.height / 2,
            width: dimensions.width,
            height: dimensions.height,
          });

          if (attachment.part) {
            page.drawText(
              `Part: ${attachment.part.name} - ${attachment.part.number}${
                attachment.part.serialNumber ? ` - SN: ${attachment.part.serialNumber}` : ""
              }`,
              { x: 4, y: page.getHeight() - 16, size: 12 },
            );
          }
        }

        if (fileType === "Document" && mimeType === "application/pdf") {
          const pdf = await PDFDocument.load(attachmentArrayBuffer);
          const copiedPages = await newPDF.copyPages(
            pdf,
            Array(pdf.getPageCount())
              .fill(0)
              .map((it, i) => i),
          );
          copiedPages.forEach((page) => newPDF.addPage(page));
        }
      }
    }

    if (download) {
      // Save and download the new PDF
      const pdfBytes = await newPDF.save();
      downloadPDF(pdfBytes, `${task.name} Inspection Form.pdf`);
    }

    return { doc, todosInfo, fullPDF: newPDF };
  };
  return { generate };
}
