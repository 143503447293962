import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import useMultiTenant from "./useMultiTenant";
import { GetLogBookStickerData } from "types/admin/GetLogBookStickerData";
import { getLogBookStickerDataQuery } from "queries/admin/logBookSticker";
import { validateSignatureQuery } from "queries/admin/validateSignature";
import { getInspectionDataQuery } from "queries/admin/taskInspectionData";
import { GetInspectionData } from "types/admin/GetInspectionData";
import { ValidateSignature } from "types/admin/ValidateSignature";
import { SigningEntityType } from "types/admin/globalTypes";
import { AddSignature } from "types/admin/AddSignature";
import {
  addSignatureMutation,
  addSignaturesMutation,
  getSigningUserQuery,
  listSignaturesQuery,
} from "queries/admin/signatures";
import { ListSignatures } from "types/admin/ListSignatures";
import { GetSigningUser } from "types/admin/GetSigningUser";
import { AddSignatures } from "types/admin/AddSignatures";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

interface TemporaryUserContextT {
  client: AWSAppSyncClient<any> | null;
  user: firebase.User | null;
  signInWithEmailAndPassword(email: string, password: string): Promise<void>;
  getSigningUser(entityId: string): Promise<any | null>;
  getLogBookStickerData(taskId: string): Promise<any>;
  getInspectionData(taskId: string): Promise<any>;
  validateSignature(entityId: string, entityType: SigningEntityType, pin?: string): Promise<any>;
  addSignature(taskId: string, entityType: SigningEntityType, pin: string): Promise<any>;
  addSignatures(taskId: string, entityType: SigningEntityType, pin: string, count: number): Promise<any>;
  listTaskSignatures(entityId: string): Promise<any>;
}

export const TemporaryUserContext = createContext<TemporaryUserContextT>({} as TemporaryUserContextT);
export const useTemporaryUser = (): TemporaryUserContextT => useContext(TemporaryUserContext);

export const TemporaryUserProvider: React.FC = ({ children }) => {
  const { tenantId } = useMultiTenant();

  const [client, setClient] = useState<AWSAppSyncClient<any> | null>(null);
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    if (!user) return;

    const client = new AWSAppSyncClient({
      url: process.env.REACT_APP_ADMIN_API_URL,
      region: "us-east-1",
      auth: {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: () => user.getIdToken(),
      },
      disableOffline: true,
    });

    setClient(client);
  }, [user]);

  const signInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
    return new Promise((res, rej) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.NONE)
        .then(() => {
          return firebase.auth().signInWithEmailAndPassword(email, password);
        })
        .then((credentials) => setUser(credentials.user))
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error sigining in with temporary user: ", errorCode, errorMessage);

          rej(error);
        });
    });
  };

  const getSigningUser = async (entityId: string) => {
    const response = await client?.query<GetSigningUser>({
      query: getSigningUserQuery,
      variables: { entityId },
      fetchPolicy: "no-cache",
    });

    return response?.data?.getSigningUser;
  };

  const getLogBookStickerData = async (taskId: string) => {
    if (!tenantId) return;

    const response = await client?.query<GetLogBookStickerData>({
      query: getLogBookStickerDataQuery,
      variables: { taskId, tenantId },
      fetchPolicy: "no-cache",
    });

    return response?.data?.getLogBookStickerData ? JSON.parse(response.data.getLogBookStickerData) : null;
  };

  const getInspectionData = async (taskId: string) => {
    if (!tenantId) return;

    const response = await client?.query<GetInspectionData>({
      query: getInspectionDataQuery,
      variables: { taskId, tenantId },
      fetchPolicy: "no-cache",
    });

    return response?.data?.getInspectionData ? JSON.parse(response.data.getInspectionData) : null;
  };

  const validateSignature = async (entityId: string, entityType: SigningEntityType, pin?: string) => {
    const response = await client?.query<ValidateSignature>({
      query: validateSignatureQuery,
      variables: { pin, entityId, entityType },
      fetchPolicy: "no-cache",
    });

    return response?.data?.validateSignature;
  };

  const addSignature = async (taskId: string, entityType: SigningEntityType, pin: string) => {
    const response = await client?.mutate<AddSignature>({
      mutation: addSignatureMutation,
      variables: { entityId: taskId, entityType, pin },
    });

    return response?.data?.addSignature;
  };

  const addSignatures = async (taskId: string, entityType: SigningEntityType, pin: string, count: number) => {
    const response = await client?.mutate<AddSignatures>({
      mutation: addSignaturesMutation,
      variables: { entityId: taskId, entityType, pin, count },
    });

    return response?.data?.addSignatures.items;
  };

  const listTaskSignatures = async (taskId: string) => {
    const response = await client?.query<ListSignatures>({
      query: listSignaturesQuery,
      variables: { entityId: taskId },
      fetchPolicy: "no-cache",
    });

    return response?.data?.listSignatures?.items?.sort((a, b) => (a.signedDate > b.signedDate ? 1 : -1));
  };

  return (
    <TemporaryUserContext.Provider
      value={{
        client,
        user,
        signInWithEmailAndPassword,
        getSigningUser,
        getLogBookStickerData,
        getInspectionData,
        validateSignature,
        addSignature,
        addSignatures,
        listTaskSignatures,
      }}
    >
      {children}
    </TemporaryUserContext.Provider>
  );
};

export default (): TemporaryUserContextT => useContext(TemporaryUserContext);
