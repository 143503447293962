import React from "react";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import styled, { css } from "styled-components";
import Palette from "../palette.json";
import { StatusLabel } from "./StatusLabel";
import { TaskStatus, TemplateType } from "types/admin/globalTypes";
import { useTask } from "hooks/params/useTask";

interface PageHeaderPropsT {
  title: any;
  subtitle?: string;
  description?: string | null;
  compact?: boolean;
  editable?: boolean;
  templateType?: TemplateType | null;

  onEditClick?(): void;
  onSubtitleClick?(): void;
}

const Root = styled.div<{ compact?: boolean }>`
  padding: 12px 32px;

  display: flex;
  flex-direction: ${(p) => (!p.compact ? "column" : "row")};

  ${(p) =>
    p.compact &&
    css`
      align-items: center;
    `}

  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;
  z-index: 1;
`;
const Headings = styled.div`
  margin: 36px 48px 46px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PageTitle = styled.h2`
  position: relative;

  font-size: 32px;
  font-weight: bold;
  color: ${Palette.DarkGrey};

  @media screen and (max-width: 1300px) {
    font-size: 24px;
    line-height: 1.3;
  }

  .rmwc-icon {
    margin: 12px 16px;
    font-size: 20px;
    color: ${(props) => props.theme.primary};
    cursor: pointer;

    @media screen and (max-width: 1300px) {
      margin: 0;
      margin-left: 12px;
    }
  }
`;
export const PageKicker = styled.h4`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  color: #9ea0a5;
`;
export const PageDescription = styled.h4`
  max-width: 500px;
  font-size: 16px;
  line-height: 1.5;
  color: #9ea0a5;
`;
const Content = styled.div`
  display: flex;
`;

export const PageHeader: React.FC<PageHeaderPropsT> = ({
  title,
  subtitle,
  description,
  compact,
  editable,
  onEditClick,
  onSubtitleClick,
  children,
  templateType,
}) => {
  const { task } = useTask();
  const deleted = task?.status === TaskStatus.Deleted;

  const type = templateType === TemplateType.Project ? "Work Order" : templateType;

  return (
    <Root data-test-id="header" {...{ compact }}>
      <Headings>
        {templateType && (
          <div style={{ marginBottom: "12px" }}>
            <StatusLabel>{type} Template</StatusLabel>
          </div>
        )}

        {subtitle && (
          <PageKicker
            style={{ display: "flex", ...(onSubtitleClick ? { cursor: "pointer" } : {}) }}
            onClick={onSubtitleClick}
          >
            {subtitle}
            {deleted && <StatusLabel style={{ marginLeft: "2rem" }}> Deleted </StatusLabel>}
          </PageKicker>
        )}

        <PageTitle>
          {title}
          {editable && <Icon data-test-id="btn-edit-head" icon="edit" onClick={onEditClick} />}
        </PageTitle>

        {description && <PageDescription>{description}</PageDescription>}
      </Headings>

      <Content>{children}</Content>
    </Root>
  );
};
