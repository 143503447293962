import React from "react";
import styled from "styled-components";

const width = 85;

const Caption = styled.figcaption`
  font-weight: 500;
  padding: 0.5rem 0.5rem 0.5rem 0;
  max-width: ${width * 2}px;
  line-height: 1.2;
`;

interface TechnicianAppIconProps {
  showLabel?: boolean;
}

const TechnicianAppIcon: React.FC<TechnicianAppIconProps> = ({ showLabel }) => {
  return (
    <figure>
      <a
        href="https://apps.apple.com/us/app/foxtrot-insight-technician/id1561002700?itscg=30200&amp;itsct=apps_box_appicon"
        style={{
          width: `${width}px`,
          height: `${width}px`,
          borderRadius: "22%",
          overflow: "hidden",
          display: "inline-block",
          verticalAlign: "middle",
        }}
      >
        <img
          src="https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/27/26/46/2726462f-7381-03ae-b556-c413f2114570/AppIcon-0-1x_U007emarketing-0-10-0-sRGB-85-220.png/540x540bb.jpg"
          alt="FoxTrot Insight Technician"
          style={{
            width: `${width}px`,
            height: `${width}px`,
            borderRadius: "22%",
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        />
      </a>
      {showLabel && <Caption>FoxTrot Insight Technician</Caption>}
    </figure>
  );
};

export default TechnicianAppIcon;
