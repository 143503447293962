import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import "@rmwc/theme/styles";
import { useHistory } from "react-router";

import React, { useEffect, useState } from "react";

import {
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  DataCellTools,
  TitleCount,
} from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import { TextInput } from "../TextInput";

import { dialogQueue } from "hooks/useDialogQueue";
import { TemplateFields } from "types/admin/TemplateFields";
import { snackbar } from "hooks/useNotifications";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import useFiltered from "hooks/useFiltered";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import { Button } from "../Button";
import styled from "styled-components";
import { TemplateType } from "types/admin/globalTypes";
import EmptyState from "../EmptyState";
import { TEMPLATE_TYPE_TO_LABEL } from "../../common";
import { useLocation } from "react-router-dom";

const TemplateFilter = styled.div`
  .mdc-menu-surface--anchor {
    margin-right: 0;
  }
`;

export const TemplatesTable = () => {
  const FILTER_OPTS = ["All", TemplateType.Project, TemplateType.Task];

  const location = useLocation();
  const history = useHistory();
  const { templates, deleteProjectTemplate, deleteTaskTemplate } = useAdminApi();

  const [displayedTemplates, setDisplayedTemplates] = useState<TemplateFields[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [filter, setFilter] = useState<TemplateType | string | null>("All");

  const filtered = useFiltered(displayedTemplates, (it) => {
    if (searchString.length > 0) {
      if (it?.name?.toLowerCase().includes(searchString?.toLowerCase())) return true;
      if (it?.type?.toLowerCase().includes(searchString?.toLowerCase())) return true;
      return false;
    }
    if (filter && filter !== "All") {
      return it.type === filter;
    }
    return true;
  });

  const onEdit = (template: TemplateFields) => {
    if (template?.type === TemplateType.Project) {
      history.push(`${location.pathname}/${template.id}/edit-project_`);
    } else {
      history.push(`${location.pathname}/${template.id}/edit-task_`);
    }
  };

  const onDelete = async (template: TemplateFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete this template: ${template?.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    let response: string | undefined;
    if (template?.type === TemplateType.Project) {
      response = await deleteProjectTemplate({ id: template.id });
    } else {
      response = await deleteTaskTemplate({ id: template.id });
    }

    console.log(response);

    if (response) {
      setDisplayedTemplates((t) => t.filter((it) => it.id !== response));
      snackbar.notify({ title: "Template removed successfully!" });
    }
  };

  useEffect(() => {
    setDisplayedTemplates(templates);
  }, [templates]);

  return (
    <>
      <TableContainer>
        <TableTitle>
          <TableToolbarTitle>
            Templates <TitleCount>({displayedTemplates.length})</TitleCount>
          </TableToolbarTitle>

          <TextInput
            placeholder={`Search by Name and Type`}
            value={searchString}
            data-test-id="search"
            onChange={(ev) => setSearchString(ev.target.value)}
          />

          <TemplateFilter>
            <SimpleMenu
              anchorCorner="bottomStart"
              focusOnOpen={false}
              handle={
                <Button outlined trailingIcon="unfold_more">
                  {filter}
                </Button>
              }
              onSelect={(ev) => setFilter(FILTER_OPTS[ev.detail.index])}
            >
              {FILTER_OPTS.map((it, i) => (
                <MenuItem key={i}>{it}</MenuItem>
              ))}
            </SimpleMenu>
          </TemplateFilter>
        </TableTitle>

        <Table>
          <DataTableContent>
            <TableHead cols={["Name", "Type"]}></TableHead>

            <DataTableBody data-test-id="templates-list">
              {filtered.length === 0 && (
                <DataTableRow>
                  <DataTableCell colSpan={3}>
                    <EmptyState icon="file_copy" text={"No templates found."} />
                  </DataTableCell>
                </DataTableRow>
              )}

              {filtered.map((it, i) => (
                <DataTableRow key={it?.id} onClick={() => history.push(`/templates/${it.id}`)}>
                  <DataTableCell>{it?.name}</DataTableCell>
                  <DataTableCell>{TEMPLATE_TYPE_TO_LABEL[it?.type ?? TemplateType.Project]}</DataTableCell>
                  <DataTableCell>
                    <DataCellTools>
                      <IconButton
                        icon="edit"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onEdit(it);
                        }}
                      />
                      <IconButton
                        icon="delete"
                        data-test-id="btn-delete"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onDelete(it);
                        }}
                      />
                    </DataCellTools>
                  </DataTableCell>
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </Table>
      </TableContainer>
    </>
  );
};
