import React from "react";
import { Button } from "../Button";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../../palette.json";
import styled from "styled-components";
import useMultiTenant from "hooks/useMultiTenant";
import { FullScreenMessage } from "components/FullScreenMessage";

const ConfirmButton = styled(Button)<{ [props: string]: any }>`
  margin: 2rem 0 1rem 0;
`;

interface SigningFailureScreenProps {
  onClick: () => void;
  children?: any;
}

export const SigningFailureScreen: React.FC<SigningFailureScreenProps> = ({ onClick, children }) => {
  const { theme } = useMultiTenant();

  return (
    <FullScreenMessage header="Something went wrong" message="Please try again." icon="error">
      <ConfirmButton raised onClick={onClick}>
        Go Back
      </ConfirmButton>
    </FullScreenMessage>
  );
};
