import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useTask } from "./params/useTask";
import { useProject } from "./params/useProject";
import { useAdminApi } from "./useAdminApi";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import { TimesheetFields } from "types/admin/TimesheetFields";

const getInitialCompletedHours = (project?: ProjectFields, task?: TaskFields) => {
  let value = 0;
  if (typeof task?.progress?.completedHours === "number") {
    value = task?.progress?.completedHours;
  } else if (typeof project?.progress?.completedHours === "number") {
    value = project?.progress?.completedHours;
  }
  return value;
};

const getEstimate = (project?: ProjectFields, task?: TaskFields): number => {
  if (task?.estimateManHrs) {
    return task.estimateManHrs;
  } else if (project?.estimateManHrs) {
    return project.estimateManHrs;
  } else {
    return 0;
  }
};

const getAllActiveTime = (
  inProgressTimesheets: TimesheetFields[],
  project?: ProjectFields,
  task?: TaskFields,
): number => {
  const getTimeSinceFromDT = (sheet: TimesheetFields): number => {
    const { fromDT } = sheet;
    const formattedFromDT = dayjs(fromDT);
    const now = dayjs();
    const since = now.diff(formattedFromDT, "second") / 60 / 60;
    return since;
  };

  const projectSheets = inProgressTimesheets.filter((t) => t.projectId === project?.id);
  const taskSheets = inProgressTimesheets.filter((t) => t.taskId === task?.id);
  let value = 0;

  if (task) {
    taskSheets.forEach((s) => {
      value += getTimeSinceFromDT(s);
    });
  } else {
    projectSheets.forEach((s) => {
      value += getTimeSinceFromDT(s);
    });
  }

  return value;
};

const getActiveUsers = (inProgressTimesheets: TimesheetFields[], project?: ProjectFields, task?: TaskFields) => {
  const projectSheets = inProgressTimesheets.filter((t) => t.projectId === project?.id);
  const taskSheets = inProgressTimesheets.filter((t) => t.taskId === task?.id);
  return task?.id ? taskSheets.length : projectSheets.length;
};

const calculateProgress = (hoursSpent: number, estimate: number): number => {
  if (estimate === 0) return 0;
  return hoursSpent / estimate;
};

const useLiveTimeSheets = <T,>(projectId?: string | null, taskId?: string | null, aircraftId?: string | null) => {
  const { inProgressTimesheets, projects, tasks } = useAdminApi();
  const [projectFromProjects] = projects.filter((it) => it.id === projectId);
  const [taskFromTasks] = tasks.filter((it) => it.id === taskId);
  const projectFromParam = useProject();
  const { task: taskFromParam } = useTask();
  const project = projectFromProjects ? projectFromProjects : projectFromParam;
  const task = taskFromTasks ? taskFromTasks : taskFromParam;

  const [theInterval, setTheInterval] = useState<any>(null);
  const [isInProgress, setIsInProgress] = useState(false);
  const [ticker, setTicker] = useState(0);

  if (!theInterval) {
    const interval = setInterval(() => {
      setTicker((t) => t + 1);
    }, 1000);
    setTheInterval(interval);
  }

  useEffect(() => {
    return () => {
      setTicker(0);
      clearInterval(theInterval);
      setTheInterval(null);
    };
  }, []);

  return useMemo(() => {
    const completed = getInitialCompletedHours(project, task);
    const estimate = getEstimate(project, task);
    const activeTime = getAllActiveTime(inProgressTimesheets, project, task);
    const activeUsers = getActiveUsers(inProgressTimesheets, project, task);
    const totalTime = completed + activeTime;
    const progress = calculateProgress(totalTime, estimate);

    if (activeTime > 0) {
      setIsInProgress(true);
    } else {
      setIsInProgress(false);
    }

    return {
      progress,
      isInProgress,
      activeUsers,
      totalTime,
      inProgressTimesheets,
    };
  }, [task, project, inProgressTimesheets, ticker, isInProgress]);
};

export default useLiveTimeSheets;
