/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { EntityType, S3ObjectInput } from "types/admin/globalTypes";

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IndustryType {
  Aviation = "Aviation",
  Janitorial = "Janitorial",
}

export enum FileType {
  Document = "Document",
  Image = "Image",
  Video = "Video",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export interface CreateAttachmentRequest {
  id: string;
  file: S3ObjectInput;
  fileType?: FileType | null;
  fileName?: string | null;
  mimeType?: string | null;
  entityType: EntityType;
  entityId: string;
}