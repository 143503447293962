import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import dayjs from "dayjs";
import { DocumentNode } from "graphql";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useRef } from "react";
import delay from "lodash/delay";
import debounce from "lodash/debounce";
import {
  addAircraftMutation,
  deleteAircraftMutation,
  listAircraftQuery,
  getAircraftQuery,
  updateAircraftMutation,
} from "../queries/admin/aircraft";
import { addAttachmentMutation, deleteAttachmentMutation, getUploadUrlQuery } from "../queries/admin/attachments";
import { getCamerasQuery } from "../queries/admin/cameras";
import {
  addClientMutation,
  assignLocationToClientMutation,
  deleteClientMutation,
  getClientQuery,
  listClientsQuery,
  unassignLocationFromClientMutation,
  updateClientMutation,
  updateClientSettingsMutation,
} from "../queries/admin/clients";
import {
  addLocationMutation,
  deleteLocationMutation,
  listLocationsQuery,
  queryLocationsByName,
  updateLocationMutation,
} from "../queries/admin/locations";
import { addMessageMutation, onAddedMessageSubscription } from "../queries/admin/messages";
import { onNotificationSubscription } from "../queries/admin/notifications";
import {
  addProjectFromTemplateMutation,
  addProjectMutation,
  addProjectUserMutation,
  completeProjectMutation,
  deleteProjectMutation,
  getProjectAndListTasksQuery,
  getProjectQuery,
  listProjectsQuery,
  removeProjectUserMutation,
  updateProjectMutation,
} from "../queries/admin/projects";
import {
  addTaskFromTemplateMutation,
  addTaskMutation,
  assignUserToTaskMutation,
  assignUserToTasksMutation,
  deleteTaskMutation,
  getTaskQuery,
  listTasksQuery,
  removeTaskUserMutation,
  removeUserFromTasksMutation,
  updateTaskMutation,
  addSubcontractorMutation,
  updateSubcontractorMutation,
  deleteSubcontractorMutation,
  completeTaskMutation,
} from "../queries/admin/tasks";
import {
  deleteTimeSheetEntryMutation,
  updateTimeSheetEntryDiscrepancyMutation,
  updateTimeSheetEntryMutation,
  onUpdatedTimeSheetEntrySubscription,
  listAllInProgressTimesheetsQuery,
  approveTimeSheetEntryMutation,
  approveTimeSheetEntriesMutation,
  listProjectTimesheetsQuery,
  clockInUserMutation,
  clockOutUserMutation,
} from "../queries/admin/timesheets";
import { addTimeSheetEntryMutation, listTimesheetsQuery } from "../queries/admin/timesheets";
import {
  addUserMutation,
  deleteUserMutation,
  getMeQuery,
  getUserQuery,
  listUsersQuery,
  updateUserMutation,
} from "../queries/admin/users";
import {
  incrementTemplateEstimateCountMutation,
  getTenantSettingsQuery,
  setTenantSettingsMutation,
} from "../queries/admin/tenantsettings";
import { AddAircraft, AddAircraftVariables } from "types/admin/AddAircraft";
import { AddAttachment, AddAttachmentVariables } from "types/admin/AddAttachment";
import { AddClient, AddClientVariables } from "types/admin/AddClient";
import { AddLocation, AddLocationVariables } from "types/admin/AddLocation";
import { AddMessage } from "types/admin/AddMessage";
import { AddProject, AddProjectVariables } from "types/admin/AddProject";
import { AddProjectUser, AddProjectUserVariables } from "types/admin/AddProjectUser";
import { AddTask, AddTaskVariables } from "types/admin/AddTask";
import { AddSubcontractor, AddSubcontractorVariables } from "types/admin/AddSubcontractor";
import { UpdateSubcontractor, UpdateSubcontractorVariables } from "types/admin/UpdateSubcontractor";
import { DeleteSubcontractor, DeleteSubcontractorVariables } from "types/admin/DeleteSubcontractor";
import { OnNotification } from "types/admin/OnNotification";
import { AddTimeSheetEntry } from "types/admin/AddTimeSheetEntry";
import { AddUser, AddUserVariables } from "types/admin/AddUser";
import { AircraftFields } from "types/admin/AircraftFields";
import { AssignUserToTask, AssignUserToTaskVariables } from "types/admin/AssignUserToTask";
import { AttachmentFields } from "types/admin/AttachmentFields";
import { CameraFields } from "types/admin/CameraFields";
import { ClientFields } from "types/admin/ClientFields";
import { DeleteAircraft, DeleteAircraftVariables } from "types/admin/DeleteAircraft";
import { DeleteAttachment } from "types/admin/DeleteAttachment";
import { DeleteClient, DeleteClientVariables } from "types/admin/DeleteClient";
import { DeleteLocation, DeleteLocationVariables } from "types/admin/DeleteLocation";
import { DeleteProject, DeleteProjectVariables } from "types/admin/DeleteProject";
import { DeleteTask, DeleteTaskVariables } from "types/admin/DeleteTask";
import { DeleteTimeSheetEntry, DeleteTimeSheetEntryVariables } from "types/admin/DeleteTimeSheetEntry";
import { UpdateTimeSheetEntry, UpdateTimeSheetEntryVariables } from "types/admin/UpdateTimeSheetEntry";
import { DeleteUser, DeleteUserVariables } from "types/admin/DeleteUser";
import { GetCameras } from "types/admin/GetCameras";
import { GetMeQuery } from "types/admin/GetMeQuery";
import { GetUploadUrl, GetUploadUrl_asAdmin_getUploadUrl } from "types/admin/GetUploadUrl";
import {
  CreateProjectTemplateRequest,
  UpdateProjectTemplateRequest,
  CreateTimeEntrySheetRequest,
  EntityType,
  MessageCreateRequest,
  ProjectRole,
  CreateTaskTemplateRequest,
  UpdateTaskTemplateRequest,
  TaskStatus,
  NotificationEntityType,
  ProjectStatus,
  PartEntityType,
  NotificationEvent,
} from "types/admin/globalTypes";
import { ListAircraft } from "types/admin/ListAircraft";
import { GetAircraft, GetAircraftVariables } from "types/admin/GetAircraft";
import { ListClients } from "types/admin/ListClients";
import { ListLocations } from "types/admin/ListLocations";
import { ListProjects } from "types/admin/ListProjects";
import { ListTasks } from "types/admin/ListTasks";
import { ListTimesheets } from "types/admin/ListTimesheets";
import { ListUsers } from "types/admin/ListUsers";
import { LocationFields } from "types/admin/LocationFields";
import { MessageFields } from "types/admin/MessageFields";
import { OnAddedMessage } from "types/admin/OnAddedMessage";
import { ProjectFields } from "types/admin/ProjectFields";
import { RemoveProjectUser } from "types/admin/RemoveProjectUser";
import { RemoveTaskUser, RemoveTaskUserVariables } from "types/admin/RemoveTaskUser";
import { TaskFields } from "types/admin/TaskFields";
import { TaskUserFields } from "types/admin/TaskUserFields";
import { TimesheetFields } from "types/admin/TimesheetFields";
import { UpdateAircraft, UpdateAircraftVariables } from "types/admin/UpdateAircraft";
import { UpdateClient, UpdateClientVariables } from "types/admin/UpdateClient";
import { UpdateLocation, UpdateLocationVariables } from "types/admin/UpdateLocation";
import { UpdateProject, UpdateProjectVariables } from "types/admin/UpdateProject";
import { UpdateTask, UpdateTaskVariables } from "types/admin/UpdateTask";
import { UpdateUser, UpdateUserVariables } from "types/admin/UpdateUser";
import { UserFields, UserFields_locations } from "types/admin/UserFields";
import { TemplateFields } from "types/admin/TemplateFields";
import { useFirebase } from "./useFirebase";
import useMultiTenant from "./useMultiTenant";
import { sortByProperty } from "./useSorted";
import {
  UpdateTimeSheetEntryDiscrepancy,
  UpdateTimeSheetEntryDiscrepancyVariables,
} from "types/admin/UpdateTimeSheetEntryDiscrepancy";
import { AddProjectTemplate } from "types/admin/AddProjectTemplate";
import { UpdateProjectTemplate } from "types/admin/UpdateProjectTemplate";
import {
  addProjectTemplateMutation,
  updateProjectTemplateMutation,
  deleteProjectTemplateMutation,
  listTemplatesQuery,
  addTaskTemplateMutation,
  updateTaskTemplateMutation,
  deleteTaskTemplateMutation,
  assignUserToTaskTemplateMutation,
  unassignUserToTaskTemplateMutation,
  getTemplateQuery,
} from "../queries/admin/templates";
import { ListTemplates, ListTemplatesVariables } from "types/admin/ListTemplates";
import { DeleteProjectTemplate, DeleteProjectTemplateVariables } from "types/admin/DeleteProjectTemplate";
import { AddTaskTemplate } from "types/admin/AddTaskTemplate";
import { UpdateTaskTemplate } from "types/admin/UpdateTaskTemplate";
import { AddProjectFromTemplate, AddProjectFromTemplateVariables } from "types/admin/AddProjectFromTemplate";
import { AddTaskFromTemplate, AddTaskFromTemplateVariables } from "types/admin/AddTaskFromTemplate";
import { DeleteTaskTemplate, DeleteTaskTemplateVariables } from "types/admin/DeleteTaskTemplate";
import { OnUpdatedTimeSheetEntry } from "types/admin/OnUpdatedTimeSheetEntry";
import { ListAllInProgressTimesheets } from "types/admin/ListAllInProgressTimesheets";
import { ApproveTimeSheetEntryVariables, ApproveTimeSheetEntry } from "types/admin/ApproveTimeSheetEntry";
import { ApproveTimeSheetEntriesVariables, ApproveTimeSheetEntries } from "types/admin/ApproveTimeSheetEntries";
import { GetTask } from "types/admin/GetTask";
import {
  GetPulseReportsAndTimeSheets,
  GetPulseReportsAndTimeSheetsVariables,
} from "types/admin/GetPulseReportsAndTimeSheets";
import { PulseReportFields } from "types/admin/PulseReportFields";
import { IncrementTemplateEstimateCountMutation } from "types/admin/IncrementTemplateEstimateCountMutation";
import { GetTenantSettingsQuery } from "types/admin/GetTenantSettingsQuery";
import { SetTenantSettingsMutation, SetTenantSettingsMutationVariables } from "types/admin/SetTenantSettingsMutation";
import { AssignUserToTasks, AssignUserToTasksVariables } from "types/admin/AssignUserToTasks";
import { RemoveUserFromTasks, RemoveUserFromTasksVariables } from "types/admin/RemoveUserFromTasks";
import { CompleteProject, CompleteProjectVariables } from "types/admin/CompleteProject";
import { SubcontractorFields } from "types/admin/SubcontractorFields";
import { UpdateClientSettings, UpdateClientSettingsVariables } from "types/admin/UpdateClientSettings";
import { GetClient, GetClientVariables } from "types/admin/GetClient";
import { ListParts } from "types/admin/ListParts";
import {
  addPartMutation,
  assignAssemblyToParentMutation,
  assignPartToParentMutation,
  deletePartMutation,
  listPartsQuery,
  updatePartMutation,
} from "../queries/admin/parts";
import { PartFields } from "types/admin/PartFields";
import { AddPart, AddPartVariables } from "types/admin/AddPart";
import { UpdatePart, UpdatePartVariables } from "types/admin/UpdatePart";
import { DeletePart, DeletePartVariables } from "types/admin/DeletePart";
import { AssignPart, AssignPartVariables } from "types/admin/AssignPart";
import { AssignAssembly, AssignAssemblyVariables } from "types/admin/AssignAssembly";
import { TaskPartFields } from "types/admin/TaskPartFields";
import { AssignUserToTaskTemplate, AssignUserToTaskTemplateVariables } from "types/admin/AssignUserToTaskTemplate";
import {
  UnassignUserToTaskTemplate,
  UnassignUserToTaskTemplateVariables,
} from "types/admin/UnassignUserToTaskTemplate";
import { UpdatedTimesheetFields } from "common";
import { GetProject, GetProjectVariables } from "types/admin/GetProject";
import { ListProjectTimesheets } from "types/admin/ListProjectTimesheets";
import { QueryLocationsByName, QueryLocationsByNameVariables } from "types/admin/QueryLocationsByName";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { AddUserShift, AddUserShiftVariables } from "types/admin/AddUserShift";
import { UpdateUserShift, UpdateUserShiftVariables } from "types/admin/UpdateUserShift";
import { DeleteUserShift, DeleteUserShiftVariables } from "types/admin/DeleteUserShift";
import {
  addUserShiftMutation,
  deleteUserShiftMutation,
  getShiftQuery,
  listShiftsQuery,
  updateUserShiftMutation,
} from "queries/admin/usershifts";
import { ListUserShifts, ListUserShiftsVariables } from "types/admin/ListUserShifts";
import { GetUserShift, GetUserShiftVariables } from "types/admin/GetUserShift";
import { GetUserQuery } from "types/admin/GetUserQuery";
import { ShiftFields } from "types/admin/ShiftFields";
import { AddShifts, AddShiftsVariables } from "types/admin/AddShifts";
import { UpdateShifts, UpdateShiftsVariables } from "types/admin/UpdateShifts";
import { DeleteShifts, DeleteShiftsVariables } from "types/admin/DeleteShifts";
import { addShiftsMutation, updatesShiftsMutation, deleteShiftsMutation } from "queries/admin/shifts";
import { listPulseReports, listPulseReportsAndTimesheets } from "queries/admin/pulsereports";
import { ListPulseReports, ListPulseReportsVariables } from "types/admin/ListPulseReports";
import { GetProjectAndListTasks, GetProjectAndListTasksVariables } from "types/admin/GetProjectAndListTasks";
import { GetCorrectiveActions, GetCorrectiveActionsVariables } from "types/admin/GetCorrectiveActions";
import { addCorrectiveActionMutation, getCorrectiveActionsQuery } from "queries/admin/correctiveActions";
import { AddCorrectiveAction, AddCorrectiveActionVariables } from "types/admin/AddCorrectiveAction";
import { ListSignatures } from "types/admin/ListSignatures";
import {
  listSignaturesQuery,
  listUserSignaturesQuery,
  sendSignatureSignUpEmailMutation,
} from "queries/admin/signatures";
import { SendSignatureEmail } from "types/admin/SendSignatureEmail";
import { GetTemplate } from "types/admin/GetTemplate";
import { ListUserSignatures } from "types/admin/ListUserSignatures";
import { SetTermsAndConditions } from "types/admin/SetTermsAndConditions";
import { setTermsAndConditionsMutation } from "queries/admin/termsAndConditions";
import { ClockInUser, ClockInUserVariables } from "types/admin/ClockInUser";
import { AssignLocationToClient, AssignLocationToClientVariables } from "types/admin/AssignLocationToClient";
import {
  UnassignLocationFromClient,
  UnassignLocationFromClientVariables,
} from "types/admin/UnassignLocationFromClient";
import { ClockOutUserVariables, ClockOutUser } from "types/admin/ClockOutUser";
import { CompleteTask, CompleteTaskVariables } from "types/admin/CompleteTask";

type UserFilteredLocations =
  | {
      initialized: boolean;
      locationFilterActive: false;
    }
  | {
      initialized: boolean;
      locationFilterActive: true;
      locationIds: string[];
    };

export interface AdminApiContextT {
  isLoading: boolean;
  isInitialLoading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: AWSAppSyncClient<any> | null;

  me: UserFields | null;

  entities: AircraftFields[];
  clients: ClientFields[];
  parts: PartFields[];
  locations: LocationFields[];
  projects: ProjectFields[];
  tasks: TaskFields[];
  templateTasks: TaskFields[];
  users: UserFields[];
  templates: TemplateFields[];
  inProgressTimesheets: TimesheetFields[];
  updatedTimesheet: UpdatedTimesheetFields | null;
  pulseReports: PulseReportFields[];

  acceptTermsAndConditions: () => Promise<any>;

  addEntity(input: AddAircraftVariables): Promise<AircraftFields | void>;
  updateEntity(input: UpdateAircraftVariables): Promise<AircraftFields | void>;
  deleteEntity(input: DeleteAircraftVariables): Promise<void>;

  getUploadUrl(mimeType: string): Promise<GetUploadUrl_asAdmin_getUploadUrl>;
  addAttachment(input: AddAttachmentVariables, taskId?: string, templateId?: string): Promise<AttachmentFields | void>;
  deleteAttachment(id: AttachmentFields, taskId?: string, templateId?: string): Promise<void>;

  getTemplateTasks(parentId: string | undefined, nextToken?: string | null | undefined): Promise<any>;

  addUser(input: AddUserVariables): Promise<UserFields | void>;
  updateUser(input: UpdateUserVariables): Promise<UserFields | void>;
  deleteUser(input: DeleteUserVariables): Promise<void>;
  getUser(id: string): Promise<UserFields | undefined>;
  sendSignatureSignUpEmail(userId: string): Promise<void>;
  listUserSignatures(id?: string, nextToken?: string): Promise<any>;

  addClient(input: AddClientVariables): Promise<ClientFields | void>;
  updateClient(input: UpdateClientVariables): Promise<ClientFields | void>;
  deleteClient(input: DeleteClientVariables): Promise<void>;
  getClient(input: GetClientVariables): Promise<void>;
  updateClientSettings(input: UpdateClientSettingsVariables): Promise<any | undefined>;
  getEntity(input: GetAircraftVariables): Promise<void>;

  addLocation(input: AddLocationVariables): Promise<LocationFields | void>;
  updateLocation(input: UpdateLocationVariables): Promise<LocationFields | void>;
  deleteLocation(input: DeleteLocationVariables): Promise<void>;
  searchLocationsByName(input: QueryLocationsByNameVariables): Promise<LocationFields[]>;

  addMessage(input: MessageCreateRequest): Promise<MessageFields | void>;

  getProject(input: GetProjectVariables): Promise<void>;
  addProject(input: AddProjectVariables): Promise<ProjectFields | void>;
  addProjectFromTemplate(input: AddProjectFromTemplateVariables): Promise<ProjectFields | void>;
  addProjectUser(input: AddProjectUserVariables): Promise<void>;
  removeProjectUser(projectId: string, userId: string): Promise<void>;
  updateProject(input: UpdateProjectVariables): Promise<ProjectFields | void>;
  updateProjectv2(input: UpdateProjectVariables): Promise<ProjectFields | void>;
  deleteProject(input: DeleteProjectVariables): Promise<void>;
  completeProject(input: CompleteProjectVariables): Promise<ProjectFields | void>;
  completeTask(input: CompleteTaskVariables): Promise<TaskFields | void>;

  getProjectAndListTasks(input: GetProjectAndListTasksVariables): Promise<any>;

  addCorrectiveAction(input: AddCorrectiveActionVariables): Promise<any>;
  getCorrectiveActions(input: GetCorrectiveActionsVariables): Promise<any>;

  assignLocationToClient(input: AssignLocationToClientVariables): Promise<void>;
  unassignLocationFromClient(input: UnassignLocationFromClientVariables): Promise<void>;

  getTask(id: string): Promise<TaskFields>;
  listTasks(parentId: string): Promise<TaskFields[]>;
  listTasksAndParts(parentId: string): Promise<any[]>;
  addTask(
    input: AddTaskVariables,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ): Promise<TaskFields | void>;
  addTaskFromTemplate(input: AddTaskFromTemplateVariables): Promise<TaskFields | void>;
  assignUserToTask(input: AssignUserToTaskVariables, task?: TaskFields): Promise<TaskUserFields | void>;
  assignUserToTaskTemplate(input: AssignUserToTaskTemplateVariables): Promise<void>;
  unassignUserToTaskTemplate(input: UnassignUserToTaskTemplateVariables): Promise<void>;
  assignUserToTasks(input: AssignUserToTasksVariables): Promise<TaskUserFields[] | void>;
  removeTaskUser(input: RemoveTaskUserVariables): Promise<void>;
  removeUserFromTasks(input: RemoveUserFromTasksVariables): Promise<void>;
  updateTask(
    input: UpdateTaskVariables,
    updateUI?: boolean,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ): Promise<TaskFields | void>;
  deleteTask(
    input: DeleteTaskVariables,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ): Promise<void>;
  updateTaskOrder(input: TaskFields[]): Promise<void>;

  addPart(input: AddPartVariables): Promise<PartFields>;
  updatePart(input: UpdatePartVariables, taskId?: string, templateId?: string): Promise<PartFields>;
  deletePart(
    input: DeletePartVariables,
    partId?: string,
    subPartId?: string,
    subSubPartId?: string,
    taskId?: string,
    templateId?: string,
  ): Promise<void>;
  assignPartToParent(
    input: AssignPartVariables,
    partId?: string,
    subPartId?: string,
    subSubPartId?: string,
    taskId?: string,
    templateId?: string,
  ): Promise<void>;
  assignAssemblyToParent(input: AssignAssemblyVariables, taskId?: string, templateId?: string): Promise<void>;

  listTaskSignatures(entityId: string): Promise<any>;

  addSubcontractor(input: AddSubcontractorVariables): Promise<SubcontractorFields | void>;
  updateSubcontractor(input: UpdateSubcontractorVariables): Promise<SubcontractorFields | void>;
  deleteSubcontractor(input: DeleteSubcontractorVariables): Promise<void>;

  addShifts(input: AddShiftsVariables): Promise<ShiftFields[] | undefined | void>;
  updateShifts(input: UpdateShiftsVariables): Promise<ShiftFields[] | undefined | void>;
  deleteShifts(input: DeleteShiftsVariables): Promise<ShiftFields[] | undefined | void>;

  addUserShift(input: AddUserShiftVariables): Promise<UserShiftFields | undefined>;
  updateUserShift(input: UpdateUserShiftVariables): Promise<UserShiftFields | undefined>;
  deleteUserShift(input: DeleteUserShiftVariables): Promise<UserShiftFields | undefined>;
  getUserShift(input: GetUserShiftVariables): Promise<UserShiftFields | undefined>;
  listUserShifts(input: ListUserShiftsVariables): Promise<UserShiftFields[] | undefined>;

  getCameras(projectId?: string): Promise<CameraFields[]>;
  addTimesheet(input: CreateTimeEntrySheetRequest): Promise<TimesheetFields | null>;
  getTimesheets(from: dayjs.Dayjs, to: dayjs.Dayjs): Promise<TimesheetFields[]>;
  listProjectTimesheets(projectId: string, nextToken?: string): Promise<TimesheetFields[]>;
  updateTimeSheetEntry(input: UpdateTimeSheetEntryVariables): Promise<any>;
  approveTimeSheetEntry(input: ApproveTimeSheetEntryVariables): Promise<any>;
  approveTimeSheetEntries(input: ApproveTimeSheetEntriesVariables): Promise<any>;
  clockInUser(input: ClockInUserVariables): Promise<any>;
  clockOutUser(input: ClockOutUserVariables): Promise<any>;
  updateTimeSheetEntryDiscrepancy(input: UpdateTimeSheetEntryDiscrepancyVariables): Promise<any>;
  deleteTimeSheetEntry(input: DeleteTimeSheetEntryVariables): Promise<string | void>;

  addProjectTemplate(
    projectId: string,
    copyUsers: boolean,
    input: CreateProjectTemplateRequest,
  ): Promise<TemplateFields | null>;
  updateProjectTemplate(id: string, input: UpdateProjectTemplateRequest): Promise<TemplateFields | null>;

  addTaskTemplate(
    projectId: string,
    copyUsers: boolean,
    input: CreateTaskTemplateRequest,
  ): Promise<TemplateFields | null>;
  updateTaskTemplate(id: string, input: UpdateTaskTemplateRequest): Promise<TemplateFields | null>;

  getTemplates(input: ListTemplatesVariables): Promise<void>;
  getTemplate(id: string): Promise<void>;
  deleteProjectTemplate(input: DeleteProjectTemplateVariables): Promise<string | undefined>;
  deleteTaskTemplate(input: DeleteTaskTemplateVariables): Promise<string | undefined>;

  getPulseReports(input: ListPulseReportsVariables): Promise<PulseReportFields[]>;
  getPulseReportsAndTimeSheets(input: GetPulseReportsAndTimeSheetsVariables): Promise<any | undefined>;

  incrementTemplateEstimateCount(): Promise<any | undefined>;
  getTenantSettings(): Promise<any | undefined>;
  setTenantSettings(input: SetTenantSettingsMutationVariables): Promise<any | undefined>;

  userFilteredLocations: UserFilteredLocations;
  setUserFilteredLocations(input: UserFilteredLocations): void;
}

export const Context = createContext({ isLoading: true } as AdminApiContextT);
export const useAdminApi = (): AdminApiContextT => useContext(Context);

export const AdminApiProvider: React.FC = ({ children }) => {
  const { user } = useFirebase();
  const { tenantId, setTenantInfo } = useMultiTenant();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [client, setClient] = useState<AWSAppSyncClient<any> | null>(null);

  const localMeString = localStorage.getItem("FoxTrot.users.me") || null;
  let localMe = null;
  if (localMeString) {
    localMe = JSON.parse(localMeString);
  }

  const [me, setMe] = useState<UserFields | null>(localMe);
  const [aircraft, setAircraft] = useState<AircraftFields[]>([]);
  const [parts, setParts] = useState<PartFields[]>([]);
  const [clients, setClients] = useState<ClientFields[]>([]);
  // user locations
  const [locations, setLocations] = useState<LocationFields[]>([]);
  /**
   * Setup to be a more complex UI component in the future,
   * where you can select multiple locations.
   *
   * Will filter projects by locationIds when the user selects a location.
   *
   * If no filters locations, then it will show all projects.
   */
  const [userFilteredLocations, setUserFilteredLocations] = useState<UserFilteredLocations>({
    initialized: false,
    locationFilterActive: false,
  });

  /**
   * The naming between these two is to not cause any function rewrites in this file for state management.
   */
  // Used to store the unfiltered projects, so we can filter them by locationIds.
  const [_unfilteredProjects, setProjects] = useState<ProjectFields[]>([]);
  const [_unfilteredTasks, setTasksState] = useState<TaskFields[]>([]);
  // Stores the filtered objects.
  const [tasks, setFilteredTasksState] = useState<TaskFields[]>([]);
  const [projects, setFilteredProjects] = useState<ProjectFields[]>([]);
  // Stores the filtered objects.

  // Sync _unfilteredTasks with todos.
  useEffect(() => {
    console.log("EFFECT: _unfilteredTasks");
    setFilteredTasksState(_unfilteredTasks);
  }, [_unfilteredTasks]);

  // Sync projects with userFilteredLocations.locationIds.
  // This is admittedly a bad use of useEffect, but it works for now instead of rewriting all the state management.
  useEffect(() => {
    console.log("EFFECT: userFilteredLocations.locationIds");
    if (userFilteredLocations.locationFilterActive) {
      if (userFilteredLocations.locationIds.length === 0) {
        setFilteredProjects(_unfilteredProjects);
        setFilteredTasksState(_unfilteredTasks);
      } else {
        const filteredProjects = _unfilteredProjects.filter((project) => {
          if (!project.location || project.location.id === null) return false;
          return userFilteredLocations.locationIds.includes(project.location.id);
        });
        const filteredTasks = _unfilteredTasks.filter((task) => {
          return filteredProjects.some((project) => {
            // WARN: Might not be able to filter tasks by projectId, because…
            // well, it might not a thing anymore???
            // or maybe it wasn’t a thing and older tasks don’t have it???
            if (!task.projectId || !project.location || project.location.id === null) return false;
            return project.id === task.projectId;
          });
        });

        setFilteredProjects(filteredProjects);
        setFilteredTasksState(filteredTasks);
        console.log({
          filteredProjects,
          filteredTasks,
        });
      }
    } else {
      setFilteredProjects(_unfilteredProjects);
      setFilteredTasksState(_unfilteredTasks);
    }
  }, [userFilteredLocations, _unfilteredProjects]);

  const [templateTasks, setTemplateTasks] = useState<TaskFields[]>([]);
  const [users, setUsers] = useState<UserFields[]>([]);
  const [templates, setTemplates] = useState<TemplateFields[]>([]);
  const [inProgressTimesheets, setInProgressTimesheets] = useState<TimesheetFields[]>([]);
  const [updatedTimesheet, setUpdatedTimesheet] = useState<UpdatedTimesheetFields | null>(null);
  const [pulseReports, setPulseReports] = useState<PulseReportFields[]>([]);

  const tasksRef = useRef<TaskFields[]>([]);

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);

  const setTasks = (f: (p: TaskFields[]) => TaskFields[]) => {
    tasksRef.current = f(tasksRef.current);
    setTasksState(tasksRef.current);
  };

  const addMessageToTask = (mesg: MessageFields) => {
    const task = tasksRef.current.find((it) => it.id === mesg.taskId);
    if (!task) return;

    if (!task.messages) task.messages = { __typename: "MessageList", items: [] };
    // We filter against ID (as datetime) and userId as it's a composite ID.
    const found = !!task.messages.items.find((it) => it.id === mesg.id && it.userId === mesg.userId);
    if (found) return;

    task.messages.items.push(mesg);

    setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
  };

  const addOperation =
    <T, V, R>(mutation: DocumentNode, unpack: (v: T) => R, setState: React.Dispatch<React.SetStateAction<R[]>>) =>
    async (input: V): Promise<R | undefined> => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        const item = unpack(res.data);
        setState((p) => [...p, item]);
        return item;
      }
    };

  const updateOperation =
    <T, V, R extends { id: string }>(
      mutation: DocumentNode,
      unpack: (v: T) => R,
      setState: React.Dispatch<React.SetStateAction<R[]>>,
    ) =>
    async (input: V): Promise<R | undefined> => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        const item = unpack(res.data);
        setState((p) => p.map((it) => (it.id === item.id ? item : it)));
        return item;
      }
      return;
    };

  const deleteOperation =
    <T, V extends { id: string }, R extends { id: string }>(
      mutation: DocumentNode,
      unpack: (v: T) => R,
      setState: React.Dispatch<React.SetStateAction<R[]>>,
    ) =>
    async (input: V) => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        setState((p) => p.filter((it) => it.id !== input.id));
      }
      return;
    };

  useEffect(() => {
    if (!user) return;

    const client = new AWSAppSyncClient({
      url: process.env.REACT_APP_ADMIN_API_URL,
      region: "us-east-1",
      auth: {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: () => user.getIdToken(),
      },
      offlineConfig: {
        keyPrefix: "adminApiPersist",
      },
      disableOffline: true,
    });

    setClient(client);
  }, [user]);

  useEffect(() => {
    if (client === null || me === null) return;

    console.info("PROFILE:", me);
  }, [client, me]);

  useEffect(() => {
    if (!client) return;

    Promise.all([
      client
        .query<GetMeQuery>({ query: getMeQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          const me = response.data.asAdmin?.getMe || null;
          setMe(me);
          localStorage.setItem("FoxTrot.users.me", JSON.stringify(me));
        }),
      client
        .query<ListAircraft>({ query: listAircraftQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          setAircraft(response.data.asAdmin?.listAircraft.items || []);
        }),
      client
        .query<ListClients>({ query: listClientsQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          setClients(response.data?.asAdmin?.listClients.items || []);
        }),
      client
        .query<ListLocations>({ query: listLocationsQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          setLocations(response.data.asAdmin?.listLocations.items || []);
        }),
      client
        .query<ListProjects>({ query: listProjectsQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          setProjects(response.data.asAdmin?.listProjects.items || []);
        }),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      listParts(),
      client
        .query<ListUsers>({ query: listUsersQuery, errorPolicy: "ignore", fetchPolicy: "no-cache" })
        .then((response) => {
          setUsers(response.data.asAdmin?.listUsers.items || []);
        }),
      client
        .query<ListAllInProgressTimesheets>({
          query: listAllInProgressTimesheetsQuery,
          errorPolicy: "ignore",
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setInProgressTimesheets(response?.data?.asAdmin?.listAllTimeSheets.items || []);
        }),
      client
        .query<ListTemplates>({
          query: listTemplatesQuery,
          errorPolicy: "ignore",
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setTemplates(response?.data.asAdmin?.listTemplates.items || []);
        }),
    ])
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));

    client
      .subscribe<{ data?: OnAddedMessage }>({
        query: onAddedMessageSubscription,
        variables: { tenantId },
      })
      .forEach((it) => {
        const mesg = it?.data?.onAddedMessage;
        console.log("MESSAGE:", mesg);
        if (!mesg) return;
        addMessageToTask(mesg);
      });
    client
      .subscribe<{ data?: OnNotification }>({
        query: onNotificationSubscription,
        variables: { tenantId, entityType: NotificationEntityType.TASK },
      })
      .forEach((it) => {
        const task = JSON.parse(JSON.parse(it.data?.onNotification?.entity));
        if (task.id && task.projectId) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getTaskDebounced(task.id, task.projectId);
        }
      });
    client
      .subscribe<{ data?: OnNotification }>({
        query: onNotificationSubscription,
        variables: { tenantId, entityType: NotificationEntityType.TASKATTACHMENT },
      })
      .forEach((it) => {
        const attachment = JSON.parse(JSON.parse(it.data?.onNotification?.entity));
        if ((attachment.entityType = "TASK")) {
          const projectId = tasksRef.current.find((t) => t.id === attachment.entityId)?.projectId;
          if (!projectId) return;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getTaskDebounced(attachment.entityId, projectId);
        }
      });
    client
      .subscribe<{ data?: OnUpdatedTimeSheetEntry }>({
        query: onUpdatedTimeSheetEntrySubscription,
        variables: { tenantId },
      })
      .forEach((it) => {
        const entry = it?.data?.onUpdatedTimeSheetEntry;
        const isDeleted = entry?.event === NotificationEvent.REMOVE;
        if (!entry?.timesheetVal) return;

        const json = JSON.parse(JSON.parse(entry.timesheetVal));
        const sheet = json?.timesheet as TimesheetFields | undefined;

        if (!sheet) return;

        setUpdatedTimesheet({ ...sheet, isDeleted });

        if (sheet.toDT || isDeleted) {
          // Removing timesheet from inProgress list
          setInProgressTimesheets((it) => it.filter((i) => i.id !== sheet.id));
        } else {
          // Adding timesheet to inProgress list
          setInProgressTimesheets((it) => [...it, sheet]);
        }

        delay(() => {
          if (!sheet.projectId || !sheet.taskId) return;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getTaskDebounced(sheet.taskId, sheet.projectId);
        }, 2000);
      });
  }, [client]);

  useEffect(() => {
    if (!client || !projects.length) return;

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // XXX - Implement pre-emptive caching nicely, and have proper data fetch requesting.
    Promise.all(
      projects.map(async (it) => {
        const response = await client?.query<ListTasks>({
          query: listTasksQuery,
          variables: { criteria: { parentId: it.id, status: null } },
          errorPolicy: "ignore",
        });

        if (!response.data) return;

        const theTasks = (response.data.asAdmin?.listTasks.items || []) as TaskFields[];
        setTasks((p) => [...theTasks, ...p].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i));

        await sleep(1000);
      }),
    ).finally(() => {
      setIsInitialLoading(false);
    });
  }, [client, projects]);

  useEffect(() => {
    if (!client) return;

    console.log("✅ Fetching template tasks");
    // XXX - Implement pre-emptive caching nicely, and have proper data fetch requesting.
    templates.forEach(async (it) => {
      const response = await client?.query<ListTasks>({
        query: listTasksQuery,
        variables: { criteria: { parentId: it.id, status: null } },
        errorPolicy: "ignore",
      });

      if (!response.data) return;

      const theTasks = (response.data.asAdmin?.listTasks.items || []) as TaskFields[];
      setTasks((p) => [...theTasks, ...p].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i));
    });
  }, [client, templates]);

  useEffect(() => {
    setTasksState(tasksRef.current);
  }, [tasksRef.current]);

  const addAircraft = addOperation<AddAircraft, AddAircraftVariables, AircraftFields>(
    addAircraftMutation,
    (v: AddAircraft) => v.asAdmin?.addAircraft as AircraftFields,
    setAircraft,
  );

  const updateAircraft = updateOperation<UpdateAircraft, UpdateAircraftVariables, AircraftFields>(
    updateAircraftMutation,
    (v: UpdateAircraft) => v.asAdmin?.updateAircraft as AircraftFields,
    setAircraft,
  );

  const deleteAircraft = deleteOperation<DeleteAircraft, DeleteAircraftVariables, AircraftFields>(
    deleteAircraftMutation,
    (v: DeleteAircraft) => v.asAdmin?.deleteAircraft as AircraftFields,
    setAircraft,
  );

  const acceptTermsAndConditions = async () => {
    const response = await client?.mutate<SetTermsAndConditions>({
      mutation: setTermsAndConditionsMutation,
    });

    const agreement = response?.data?.asAdmin?.setTermsAndConditionsAgreement;
    if (!agreement) return;
    setTenantInfo((info) => ({ ...info, termsAndConditionsAgreement: agreement }));

    return agreement;
  };

  const getUploadUrl = async (mimeType: string) => {
    const response = await client?.query<GetUploadUrl>({
      query: getUploadUrlQuery,
      variables: { mimeType },
      fetchPolicy: "no-cache",
    });

    if (!response?.data.asAdmin?.getUploadUrl) {
      throw new Error("UPLOAD_FAILED");
    }

    return response.data.asAdmin.getUploadUrl;
  };

  const addAttachment = async (input: AddAttachmentVariables, taskId?: string, templateId?: string) => {
    const response = await client?.mutate<AddAttachment>({
      mutation: addAttachmentMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addAttachment) {
      throw new Error("FAILED");
    }

    const item = response.data.asAdmin.addAttachment;

    if (item.entityType === EntityType.PART) {
      if (taskId) {
        setTasks((p) => {
          return p.map((it) => {
            if (it.id !== taskId) return it;

            if (!it.taskParts) return it;

            it.taskParts.items = it.taskParts.items.map((part) => {
              if (part.id === item.entityId) {
                if (!part.attachments) part.attachments = { __typename: "AttachmentList", items: [] };
                part.attachments.items.push(item);
              }
              if (!part.parts?.items) return part;

              part.parts.items = part.parts.items.map((subPart) => {
                if (subPart.id === item.entityId) {
                  if (!subPart.attachments) subPart.attachments = { __typename: "AttachmentList", items: [] };
                  subPart.attachments.items.push(item);
                }
                if (!subPart.parts?.items) return subPart;

                subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                  if (subSubPart.id === item.entityId) {
                    if (!subSubPart.attachments) subSubPart.attachments = { __typename: "AttachmentList", items: [] };
                    subSubPart.attachments.items.push(item);
                  }
                  return subSubPart;
                });

                return subPart;
              });

              return part;
            });

            return it;
          });
        });
      } else if (templateId) {
        setTemplates((templates) => {
          return templates.map((it) => {
            if (it.id !== templateId) return it;

            if (!it.parts) return it;

            it.parts.items = it.parts.items.map((part) => {
              if (part.id === item.entityId) {
                if (!part.attachments) part.attachments = { __typename: "AttachmentList", items: [] };
                part.attachments.items.push(item);
              }
              if (!part.parts?.items) return part;

              part.parts.items = part.parts.items.map((subPart) => {
                if (subPart.id === item.entityId) {
                  if (!subPart.attachments) subPart.attachments = { __typename: "AttachmentList", items: [] };
                  subPart.attachments.items.push(item);
                }
                if (!subPart.parts?.items) return subPart;

                subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                  if (subSubPart.id === item.entityId) {
                    if (!subSubPart.attachments) subSubPart.attachments = { __typename: "AttachmentList", items: [] };
                    subSubPart.attachments.items.push(item);
                  }
                  return subSubPart;
                });

                return subPart;
              });

              return part;
            });

            return it;
          });
        });
      } else {
        setParts((parts) => {
          return parts.map((part) => {
            if (part.id === item.entityId) {
              if (!part.attachments) part.attachments = { __typename: "AttachmentList", items: [] };
              part.attachments.items.push(item);
            }
            if (!part.parts?.items) return part;

            part.parts.items = part.parts.items.map((subPart) => {
              if (subPart.id === item.entityId) {
                if (!subPart.attachments) subPart.attachments = { __typename: "AttachmentList", items: [] };
                subPart.attachments.items.push(item);
              }
              if (!subPart.parts?.items) return subPart;

              subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                if (subSubPart.id === item.entityId) {
                  if (!subSubPart.attachments) subSubPart.attachments = { __typename: "AttachmentList", items: [] };
                  subSubPart.attachments.items.push(item);
                }
                return subSubPart;
              });

              return subPart;
            });

            return part;
          });
        });
      }
    } else if (item.entityType === EntityType.TASK) {
      if (taskId) {
        setTasks((p) => {
          const task = p.find((it) => it.id === item.entityId);
          if (!task) return p;
          if (!task?.attachments) task.attachments = { __typename: "AttachmentList", items: [] };
          task.attachments.items.push(item);
          return p.map((it) => (it.id === item.entityId ? task : it));
        });
      } else if (templateId) {
        setTemplates((templates) => {
          return templates.map((it) => {
            if (it.id !== templateId) return it;

            if (!it.attachments) it.attachments = { __typename: "AttachmentList", items: [] };
            it.attachments.items.push(item);

            return it;
          });
        });
      }
    }

    return response.data.asAdmin.addAttachment;
  };

  const deleteAttachment = async (item: AttachmentFields, taskId?: string, templateId?: string) => {
    const response = await client?.mutate<DeleteAttachment>({
      mutation: deleteAttachmentMutation,
      variables: { id: item.id },
    });

    if (!response?.data?.asAdmin?.deleteAttachment) return;

    if (item.entityType === EntityType.PART) {
      if (taskId) {
        setTasks((p) => {
          return p.map((it) => {
            if (it.id !== taskId) return it;

            if (!it.taskParts) return it;

            it.taskParts.items = it.taskParts.items.map((part) => {
              if (part.id === item.entityId) {
                if (part.attachments) {
                  part.attachments.items = part.attachments.items.filter((it) => it.id !== item.id);
                }
              }
              if (!part.parts?.items) return part;

              part.parts.items = part.parts.items.map((subPart) => {
                if (subPart.id === item.entityId) {
                  if (subPart.attachments) {
                    subPart.attachments.items = subPart.attachments.items.filter((it) => it.id !== item.id);
                  }
                }
                if (!subPart.parts?.items) return subPart;

                subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                  if (subSubPart.id === item.entityId) {
                    if (subSubPart.attachments) {
                      subSubPart.attachments.items = subSubPart.attachments.items.filter((it) => it.id !== item.id);
                    }
                  }
                  return subSubPart;
                });

                return subPart;
              });

              return part;
            });

            return it;
          });
        });
      } else if (templateId) {
        setTemplates((templates) => {
          return templates.map((it) => {
            if (it.id !== templateId) return it;

            if (!it.parts) return it;

            it.parts.items = it.parts.items.map((part) => {
              if (part.id === item.entityId) {
                if (part.attachments) {
                  part.attachments.items = part.attachments.items.filter((it) => it.id !== item.id);
                }
              }
              if (!part.parts?.items) return part;

              part.parts.items = part.parts.items.map((subPart) => {
                if (subPart.id === item.entityId) {
                  if (subPart.attachments) {
                    subPart.attachments.items = subPart.attachments.items.filter((it) => it.id !== item.id);
                  }
                }
                if (!subPart.parts?.items) return subPart;

                subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                  if (subSubPart.id === item.entityId) {
                    if (subSubPart.attachments) {
                      subSubPart.attachments.items = subSubPart.attachments.items.filter((it) => it.id !== item.id);
                    }
                  }
                  return subSubPart;
                });

                return subPart;
              });

              return part;
            });

            return it;
          });
        });
      } else {
        setParts((parts) => {
          return parts.map((part) => {
            if (part.id === item.entityId) {
              if (part.attachments) {
                part.attachments.items = part.attachments.items.filter((it) => it.id !== item.id);
              }
            }
            if (!part.parts?.items) return part;

            part.parts.items = part.parts.items.map((subPart) => {
              if (subPart.id === item.entityId) {
                if (subPart.attachments) {
                  subPart.attachments.items = subPart.attachments.items.filter((it) => it.id !== item.id);
                }
              }
              if (!subPart.parts?.items) return subPart;

              subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                if (subSubPart.id === item.entityId) {
                  if (subSubPart.attachments) {
                    subSubPart.attachments.items = subSubPart.attachments.items.filter((it) => it.id !== item.id);
                  }
                }
                return subSubPart;
              });

              return subPart;
            });

            return part;
          });
        });
      }
    } else if (item.entityType === EntityType.TASK) {
      setTasks((p) => {
        const task = p.find((it) => it.id === item.entityId);
        if (!task || !task.attachments) return p;
        task.attachments.items = task.attachments.items.filter((it) => it.id !== item.id);
        return p.map((it) => (it.id === task.id ? task : it));
      });
    }
  };

  const addClient = addOperation<AddClient, AddClientVariables, ClientFields>(
    addClientMutation,
    (v: AddClient) => v.asAdmin?.addClient as ClientFields,
    setClients,
  );

  const updateClient = updateOperation<UpdateClient, UpdateClientVariables, ClientFields>(
    updateClientMutation,
    (v: UpdateClient) => v.asAdmin?.updateClient as ClientFields,
    setClients,
  );

  const deleteClient = deleteOperation<DeleteClient, DeleteClientVariables, ClientFields>(
    deleteClientMutation,
    (v: DeleteClient) => v.asAdmin?.deleteClient as ClientFields,
    setClients,
  );

  const addLocation = async (input: AddLocationVariables) => {
    const response = await client?.mutate<AddLocation>({
      mutation: addLocationMutation,
      variables: input,
    });

    const location = response?.data?.asAdmin?.addLocation as LocationFields;

    setLocations((it) => [...it, location]);
  };

  const updateLocation = async (input: UpdateLocationVariables) => {
    const response = await client?.mutate<UpdateLocation>({
      mutation: updateLocationMutation,
      variables: input,
    });

    const location = response?.data?.asAdmin?.updateLocation as LocationFields;

    setClients((clients) =>
      clients.map((client) => {
        const foundLocation = client.locations.items.find((l) => l.locationId === input.id);
        if (!foundLocation) return client; // Return client if their locations are unaffected

        client.locations.items = client.locations.items.map((loc) => {
          if (loc.locationId === input.id) {
            return { ...loc, location };
          }
          return loc;
        });
        return client;
      }),
    );

    setUsers((users) => {
      return users.map((user) => {
        if (!user.locations || user.locations.length === 0) return user;
        user.locations = user.locations.map((it) => {
          if (it.id === location.id) {
            it.name = location.name;
          }
          return it;
        });
        return user;
      });
    });

    setLocations(
      locations.map((it) => {
        if (it.id === location.id) return location;
        return it;
      }),
    );
  };

  const deleteLocation = async (input: DeleteLocationVariables) => {
    await client?.mutate<DeleteLocation>({
      mutation: deleteLocationMutation,
      variables: input,
    });

    setClients((clients) =>
      clients.map((client) => {
        const foundLocation = client.locations?.items?.find((l) => l.locationId === input.id);
        if (!foundLocation) return client; // Return client if their locations are unaffected

        client.locations.items = client.locations.items.filter((loc) => loc.locationId !== input.id);
        return client;
      }),
    );

    setUsers((users) => {
      return users.map((user) => {
        if (!user.locations || user.locations.length === 0) return user;
        user.locations = user.locations.filter((it) => it.id !== input.id);
        return user;
      });
    });

    setLocations(locations.filter((it) => it.id !== input.id));
  };

  const searchLocationsByName = async (input: QueryLocationsByNameVariables) => {
    const response = await client?.query<QueryLocationsByName>({
      query: queryLocationsByName,
      variables: input,
      fetchPolicy: "network-only",
    });

    return response.data.asAdmin.queryLocationsByName.items;
  };

  const addMessage = async (input: MessageCreateRequest) => {
    const response = await client?.mutate<AddMessage>({
      mutation: addMessageMutation,
      variables: { input },
    });

    if (!response?.data?.addMessage) return;

    const mesg = response.data.addMessage;
    const task = tasks.find((it) => it.id === mesg.taskId);
    if (!task) return;

    if (!task.messages) task.messages = { __typename: "MessageList", items: [] };
    task.messages.items.push(mesg);

    setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
  };

  const getProjectAndListTasks = async (input: GetProjectAndListTasksVariables) => {
    const response = await client?.query<GetProjectAndListTasks>({
      query: getProjectAndListTasksQuery,
      variables: input,
      fetchPolicy: "network-only",
    });

    const project = response.data?.asAdmin.getProject;
    setProjects((p) => [
      ...p.map((t) => {
        if (t.id === project.id) {
          return project;
        }
        return t;
      }),
      ...(!p.find((t) => t.id === project.id) ? [project] : []),
    ]);

    setTasks((p) =>
      [...p, ...(response.data?.asAdmin.listTasks.items as TaskFields[])].filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
      ),
    );

    return {
      project: response.data?.asAdmin.getProject,
      tasks: response.data?.asAdmin.listTasks,
    };
  };

  const getProject = async (input: GetProjectVariables) => {
    const response = await client?.query<GetProject>({
      query: getProjectQuery,
      variables: input,
    });

    const project = response.data.asAdmin.getProject as ProjectFields;
    if (!project) return;

    setProjects((p) => [
      ...p.map((t) => {
        if (t.id === project.id) {
          return project;
        }
        return t;
      }),
      ...(!p.find((t) => t.id === project.id) ? [project] : []),
    ]);
  };

  const addProject = addOperation<AddProject, AddProjectVariables, ProjectFields>(
    addProjectMutation,
    (v: AddProject) => v.asAdmin?.addProject as ProjectFields,
    setProjects,
  );

  const addProjectFromTemplate = async (input: AddProjectFromTemplateVariables) => {
    const response = await client?.mutate<AddProjectFromTemplate>({
      mutation: addProjectFromTemplateMutation,
      variables: input,
    });

    const newProject = response?.data?.asAdmin?.addProjectFromTemplate;

    if (!newProject) return;

    setProjects((p) => [...p, newProject]);
    return newProject;
  };

  const addProjectUser = async ({ input }: AddProjectUserVariables) => {
    const response = await client?.mutate<AddProjectUser>({
      mutation: addProjectUserMutation,
      variables: { input },
    });

    if (!response?.data?.asAdmin?.addProjectUser) return;

    const assignment = response?.data?.asAdmin?.addProjectUser;
    const project = projects.find((it) => it.id === input.projectId);
    const template = templates.find((it) => it.id === input.projectId);

    if (!assignment) return;
    if (project) {
      if (!project.users) project.users = { __typename: "ProjectUserList", items: [] };

      project.users?.items.push({ ...assignment });
      setProjects((p) => p.map((proj) => (proj.id === project.id ? project : proj)));
    } else if (template) {
      if (!template.users) template.users = { __typename: "TemplateTaskUserList", items: [] };

      template.users?.items.push({ ...assignment } as any);
      setTemplates((t) => t.map((temp) => (temp.id === template.id ? template : temp)));
    }
  };

  const removeProjectUser = async (projectId: string, userId: string) => {
    const response = await client?.mutate<RemoveProjectUser>({
      mutation: removeProjectUserMutation,
      variables: { projectId, userId },
    });

    if (!response?.data?.asAdmin?.removeProjectUser) return;

    const project = projects.find((it) => it.id === projectId);
    const template = templates.find((it) => it.id === projectId);

    if (project) {
      if (!project.users) project.users = { __typename: "ProjectUserList", items: [] };

      project.users.items = project.users.items.filter((it) => it.userId !== userId);
      setProjects((p) => p.map((proj) => (proj.id === project.id ? project : proj)));

      setTasks((t) => {
        return t.map((it) => {
          if (it.projectId === response?.data?.asAdmin?.removeProjectUser?.projectId) {
            if (it.users?.items) {
              it.users.items = it?.users?.items.filter((u) => u.userId !== userId);
            }
          }
          return it;
        });
      });
    } else if (template) {
      if (!template.users) template.users = { __typename: "TemplateTaskUserList", items: [] };

      template.users.items = template.users.items.filter((it) => it.userId !== userId);
      setTemplates((t) => t.map((temp) => (temp.id === template.id ? template : temp)));

      setTasks((t) => {
        return t.map((it) => {
          if (it.parentId === response?.data?.asAdmin?.removeProjectUser?.projectId) {
            if (it.users?.items) {
              it.users.items = it?.users?.items.filter((u) => u.userId !== userId);
            }
          }
          return it;
        });
      });
    }
  };

  async function updateProjectv2(input: UpdateProjectVariables) {
    const projectFields = await updateProject(input);
    // setTimeout to fetch after 1 second to allow for the project to be updated in the database
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const updatedProjectAndTasks = await getProjectAndListTasks({
      projectId: input.id,
      criteria: { parentId: input.id, status: null },
    });
    return projectFields;
  }

  const updateProject = updateOperation<UpdateProject, UpdateProjectVariables, ProjectFields>(
    updateProjectMutation,
    (v: UpdateProject) => v.asAdmin?.updateProject as ProjectFields,
    setProjects,
  );

  const deleteProject = deleteOperation<DeleteProject, DeleteProjectVariables, ProjectFields>(
    deleteProjectMutation,
    (v: DeleteProject) => v.asAdmin?.deleteProject as ProjectFields,
    setProjects,
  );

  const completeProject = async (input: CompleteProjectVariables) => {
    const response = await client?.mutate<CompleteProject>({
      mutation: completeProjectMutation,
      variables: input,
    });

    setProjects((p) =>
      p.map((it) => {
        if (it.id === response?.data?.asAdmin?.completeProject.id) {
          it.status = ProjectStatus.Completed;
          const users = response?.data?.asAdmin?.completeProject?.users?.items?.map((it) => ({ ...it, ct: null }));
          it.users = { ...response?.data?.asAdmin?.completeProject?.users, items: users };
        }
        return it;
      }),
    );

    setTasks((t) => {
      return t.map((it) => {
        if (it.projectId === response?.data?.asAdmin?.completeProject.id) {
          it.users = null;
          it.status = TaskStatus.Completed;
        }
        return it;
      });
    });
  };

  const completeTask = async (input: CompleteTaskVariables) => {
    const response = await client?.mutate<CompleteTask>({
      mutation: completeTaskMutation,
      variables: input,
    });

    setTasks((t) => {
      return t.map((it) => {
        if (it.id === response?.data?.asAdmin?.completeTask.id) {
          it.users = null;
          it.status = TaskStatus.Completed;
        }
        return it;
      });
    });
  };

  const assignLocationToClient = async (input: AssignLocationToClientVariables) => {
    const response = await client?.mutate<AssignLocationToClient>({
      mutation: assignLocationToClientMutation,
      variables: input,
    });

    const clientLocation = response?.data?.asAdmin?.assignLocationToClient;
    if (!clientLocation) return;

    setClients((clients) =>
      clients.map((aClient) => {
        if (input.clientId !== aClient.id) return aClient;

        if (!!aClient.locations?.items?.length) {
          aClient.locations.items.push(clientLocation);
        } else {
          aClient.locations = { __typename: "ClientLocationList", items: [clientLocation] };
        }
        return aClient;
      }),
    );

    setLocations((locations) =>
      locations.map((location) => {
        if (location.id !== input.locationId) return location;

        if (!!location.clients?.items?.length) {
          location.clients.items.push(clientLocation);
        } else {
          location.clients = { __typename: "ClientLocationList", items: [clientLocation] };
        }
        return location;
      }),
    );
  };

  const unassignLocationFromClient = async (input: UnassignLocationFromClientVariables) => {
    const response = await client?.mutate<UnassignLocationFromClient>({
      mutation: unassignLocationFromClientMutation,
      variables: input,
    });

    const clientLocation = response?.data?.asAdmin?.unassignLocationFromClient;
    if (!clientLocation) return;

    setClients((clients) =>
      clients.map((aClient) => {
        if (input.clientId !== aClient.id) return aClient;

        if (!aClient.locations?.items?.length) return aClient;
        aClient.locations.items = aClient.locations.items.filter((it) => it.locationId !== input.locationId);
        return aClient;
      }),
    );

    setLocations((locations) =>
      locations.map((location) => {
        if (location.id !== input.locationId) return location;

        location.clients.items = location.clients.items.filter((it) => it.clientId !== input.clientId);
        return location;
      }),
    );
  };

  const listTaskSignatures = async (taskId: string) => {
    const response = await client?.query<ListSignatures>({
      query: listSignaturesQuery,
      variables: { entityId: taskId },
      fetchPolicy: "no-cache",
    });

    return response?.data?.listSignatures?.items?.sort((a, b) => (a.signedDate > b.signedDate ? 1 : -1));
  };

  const addShifts = async (input: AddShiftsVariables) => {
    const response = await client?.mutate<AddShifts>({
      mutation: addShiftsMutation,
      variables: input,
    });

    const shifts = response?.data?.asAdmin?.addShifts.items;
    if (!shifts) return;
    return shifts as ShiftFields[];
  };

  const updateShifts = async (input: UpdateShiftsVariables) => {
    const response = await client?.mutate<UpdateShifts>({
      mutation: updatesShiftsMutation,
      variables: input,
    });

    const shifts = response?.data?.asAdmin?.updateShifts.items;
    if (!shifts) return;
    return shifts as ShiftFields[];
  };

  const deleteShifts = async (input: DeleteShiftsVariables) => {
    const response = await client?.mutate<DeleteShifts>({
      mutation: deleteShiftsMutation,
      variables: input,
    });

    const shifts = response?.data?.asAdmin?.deleteShifts.items;
    if (!shifts) return;
    return shifts as ShiftFields[];
  };

  const getUserShift = async (input: GetUserShiftVariables) => {
    const response = await client?.query<GetUserShift>({
      query: getShiftQuery,
      variables: input,
      fetchPolicy: "no-cache",
    });

    return response.data?.asAdmin?.getUserShift as UserShiftFields;
  };

  const listUserShifts = async (input: ListUserShiftsVariables) => {
    const response = await client?.query<ListUserShifts>({
      query: listShiftsQuery,
      variables: input,
      fetchPolicy: "no-cache",
    });

    return response.data?.asAdmin?.listUserShifts.items as UserShiftFields[];
  };

  const addUserShift = async (input: AddUserShiftVariables) => {
    const response = await client?.mutate<AddUserShift>({
      mutation: addUserShiftMutation,
      variables: input,
    });

    return response.data?.asAdmin?.addUserShift;
  };

  const updateUserShift = async (input: UpdateUserShiftVariables) => {
    const response = await client?.mutate<UpdateUserShift>({
      mutation: updateUserShiftMutation,
      variables: input,
    });

    return response.data?.asAdmin?.updateUserShift;
  };

  const deleteUserShift = async (input: DeleteUserShiftVariables) => {
    const response = await client?.mutate<DeleteUserShift>({
      mutation: deleteUserShiftMutation,
      variables: input,
    });

    return response.data?.asAdmin?.deleteUserShift;
  };

  const listParts = async () => {
    const response = await client?.query<ListParts>({ query: listPartsQuery, fetchPolicy: "no-cache" });

    let allParts: PartFields[] = [];
    const items = response?.data.asAdmin?.listParts.items || [];
    allParts = items;
    const nextToken = response?.data.asAdmin?.listParts.nextToken;

    const getMoreParts = async (nextToken: string) => {
      const response = await client?.query<ListParts>({
        query: listPartsQuery,
        fetchPolicy: "no-cache",
        variables: { nextToken },
      });
      const items = response?.data.asAdmin?.listParts.items || [];
      allParts = [...allParts, ...items];

      const newNextToken = response?.data.asAdmin?.listParts.nextToken;
      if (newNextToken) {
        await getMoreParts(nextToken);
      }
    };

    if (nextToken) {
      await getMoreParts(nextToken);
    }

    setParts(allParts);
  };

  const listTasks = async (parentId: string) => {
    const response = await client?.query<ListTasks>({
      query: listTasksQuery,
      variables: { criteria: { parentId: parentId, status: null } },
      errorPolicy: "ignore",
    });
    if (!response.data) return;
    setTasks(
      (p) =>
        [...p, ...(response.data.asAdmin?.listTasks.items || [])].filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        ) as TaskFields[],
    );

    return response.data.asAdmin?.listTasks.items || [];
  };

  const listTasksAndParts = async (parentId: string) => {
    const response = await client?.query<ListTasks>({
      query: listTasksQuery,
      variables: { criteria: { parentId: parentId, status: null } },
      fetchPolicy: "no-cache",
    });
    if (!response.data) return;

    return response.data.asAdmin?.listTasks.items || [];
  };

  const addTask = async (
    input: AddTaskVariables,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ) => {
    const response = await client?.mutate<AddTask>({
      mutation: addTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addTask) return;

    const task = response?.data?.asAdmin?.addTask as TaskFields;

    if (isTaskTemplate) {
      // Task template -> todo
      setTemplateTasks((t) => [...t, task]);
    } else if (isTodo) {
      const parent = tasks.find((it) => it.id === task.parentId);
      if (!parent) {
        setTasks((p) => [...p, task]);
        return task;
      }

      if (!parent.tasks) parent.tasks = { __typename: "TaskList", items: [] };
      parent.tasks.items = [...parent.tasks.items, task].sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    } else {
      setTasks((p) => [...p, task]);
    }

    return task;
  };

  const addTaskFromTemplate = async (input: AddTaskFromTemplateVariables) => {
    const response = await client?.mutate<AddTaskFromTemplate>({
      mutation: addTaskFromTemplateMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addTaskFromTemplate) return;

    const task = response?.data?.asAdmin?.addTaskFromTemplate as TaskFields;

    if (task.parentId === task.projectId || !task.projectId) {
      setTasks((p) => [...p, task]);
    } else {
      const parent = tasks.find((it) => it.id === task.parentId);
      if (!parent) return task;

      if (!parent.tasks) parent.tasks = { __typename: "TaskList", items: [] };
      parent.tasks.items = [...parent.tasks.items, task].sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    }

    return task;
  };

  const updateTask = async (
    input: UpdateTaskVariables,
    updateUI = true,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ) => {
    const response = await client?.mutate<UpdateTask>({
      mutation: updateTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.updateTask) return;

    const task = response?.data?.asAdmin?.updateTask as TaskFields;

    if (!updateUI) {
      return task;
    }

    if (isTaskTemplate) {
      // Task template -> todo
      setTemplateTasks((t) => t.map((it) => (it.id === task.id ? task : it)));
    } else if (isTodo) {
      const parent = tasks.find((it) => it.id === task.parentId);
      if (!parent) {
        setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
        return task;
      }

      if (!parent.tasks) parent.tasks = { __typename: "TaskList", items: [] };
      parent.tasks.items = parent.tasks.items
        .map((it) => (it.id === task.id ? task : it))
        .sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    } else {
      setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
    }

    return task;
  };

  const assignPartToParent = async (
    input: AssignPartVariables,
    partId?: string,
    subPartId?: string,
    subSubPartId?: string,
    taskId?: string,
    templateId?: string,
  ) => {
    const response = await client?.mutate<AssignPart>({
      mutation: assignPartToParentMutation,
      variables: input,
    });

    const result = response?.data?.asAdmin?.assignPartToParent;
    if (!result) return;

    if (result.parentEntityType === PartEntityType.Task || taskId) {
      setTasks((p) => {
        return p.map((it) => {
          if (it.id !== taskId) return it;

          if (!it.taskParts) it.taskParts = { __typename: "PartList", items: [] };

          if (!partId && !subPartId && !subSubPartId) {
            it.taskParts.items.push(result as TaskPartFields);
          } else {
            it.taskParts.items = it.taskParts.items.map((part) => {
              if (part.id === partId) {
                if (!subPartId) {
                  part.parts?.items.push(result as PartFields);
                }
              }

              if (part.parts?.items) {
                part.parts.items = part.parts.items.map((subPart) => {
                  if (subPart.id === subPartId) {
                    if (!subSubPartId) {
                      subPart.parts?.items.push(result as PartFields);
                    }
                  }

                  return subPart;
                });
              }

              return part;
            });
          }

          return it;
        }) as TaskFields[];
      });
    } else if (result.parentEntityType === PartEntityType.TaskTemplate || templateId) {
      setTemplates((templates) => {
        return templates.map((it) => {
          if (it.id !== templateId) return it;

          if (!it.parts) it.parts = { __typename: "PartList", items: [] };

          if (!partId && !subPartId && !subSubPartId) {
            it.parts.items.push(result as PartFields);
          } else {
            it.parts.items = it.parts.items.map((part) => {
              if (part.id === partId) {
                if (!subPartId) {
                  part.parts?.items.push(result as PartFields);
                }
              }

              if (part.parts?.items) {
                part.parts.items = part.parts.items.map((subPart) => {
                  if (subPart.id === subPartId) {
                    if (!subSubPartId) {
                      subPart.parts?.items.push(result as PartFields);
                    }
                  }

                  return subPart;
                });
              }

              return part;
            });
          }

          return it;
        });
      });
    } else {
      setParts((parts) => {
        return parts.map((part) => {
          if (part.id === partId) {
            if (!subPartId) {
              part.parts?.items.push(result as PartFields);
            }
          }

          if (part.parts?.items) {
            part.parts.items = part.parts.items.map((subPart) => {
              if (subPart.id === subPartId) {
                if (!subSubPartId) {
                  subPart.parts?.items.push(result as PartFields);
                }
              }

              return subPart;
            });
          }

          return part;
        });
      });
    }
  };

  const assignAssemblyToParent = async (input: AssignAssemblyVariables, taskId?: string, templateId?: string) => {
    const response = await client?.mutate<AssignAssembly>({
      mutation: assignAssemblyToParentMutation,
      variables: input,
    });

    const result = response?.data?.asAdmin?.assignAssemblyToParent;
    if (!result) return;

    if (result.parentEntityType === PartEntityType.Task || taskId) {
      setTasks((p) => {
        return p.map((it) => {
          if (it.id !== taskId) return it;

          if (!it.taskParts) it.taskParts = { __typename: "PartList", items: [] };

          it.taskParts.items.push(result as TaskPartFields);

          return it;
        }) as TaskFields[];
      });
    } else if (result.parentEntityType === PartEntityType.TaskTemplate || templateId) {
      setTemplates((templates) => {
        return templates.map((it) => {
          if (it.id !== templateId) return it;

          if (!it.parts) it.parts = { __typename: "PartList", items: [] };

          it.parts.items.push(result as PartFields);

          return it;
        });
      });
    }
  };

  const addPart = async (input: AddPartVariables) => {
    const response = await client?.mutate<AddPart>({
      mutation: addPartMutation,
      variables: input,
    });

    const part = response?.data?.asAdmin?.addPart as PartFields;

    setParts((p) => [...p, part]);

    return part;
  };

  const updatePart = async (input: UpdatePartVariables, taskId?: string, templateId?: string) => {
    const response = await client?.mutate<UpdatePart>({
      mutation: updatePartMutation,
      variables: input,
    });

    const updatedPart = response?.data?.asAdmin?.updatePart as PartFields;

    if (taskId) {
      setTasks((p) => {
        return p.map((it) => {
          if (it.id !== taskId) return it;
          if (!it.taskParts) return it;

          it.taskParts.items = it.taskParts.items.map((part) => {
            if (!part.parts?.items) return part;

            if (part.id === updatedPart.id) {
              return updatedPart;
            }

            part.parts.items = part.parts.items.map((subPart) => {
              if (!subPart.parts?.items) return subPart;

              if (subPart.id === updatedPart.id) {
                return updatedPart;
              }

              subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                if (subSubPart.id === updatedPart.id) {
                  return updatedPart;
                }
                return subSubPart;
              });

              return subPart;
            });

            return part;
          });

          return it;
        });
      });
    } else if (templateId) {
      setTemplates((templates) => {
        return templates.map((it) => {
          if (it.id !== templateId) return it;
          if (!it.parts) return it;

          it.parts.items = it.parts.items.map((part) => {
            if (!part.parts?.items) return part;

            if (part.id === updatedPart.id) {
              return updatedPart;
            }

            part.parts.items = part.parts.items.map((subPart) => {
              if (!subPart.parts?.items) return subPart;

              if (subPart.id === updatedPart.id) {
                return updatedPart;
              }

              subPart.parts.items = subPart.parts.items.map((subSubPart) => {
                if (subSubPart.id === updatedPart.id) {
                  return updatedPart;
                }
                return subSubPart;
              });

              return subPart;
            });

            return part;
          });

          return it;
        });
      });
    } else {
      setParts((parts) => {
        return parts.map((part) => {
          if (!part.parts?.items) return part;

          if (part.id === updatedPart.id) {
            return updatedPart;
          }

          part.parts.items = part.parts.items.map((subPart) => {
            if (!subPart.parts?.items) return subPart;

            if (subPart.id === updatedPart.id) {
              return updatedPart;
            }

            subPart.parts.items = subPart.parts.items.map((subSubPart) => {
              if (subSubPart.id === updatedPart.id) {
                return updatedPart;
              }
              return subSubPart;
            });

            return subPart;
          });

          return part;
        });
      });
    }

    return updatedPart;
  };

  const deletePart = async (
    input: DeletePartVariables,
    partId?: string,
    subPartId?: string,
    subSubPartId?: string,
    taskId?: string,
    templateId?: string,
  ) => {
    const response = await client?.mutate<DeletePart>({
      mutation: deletePartMutation,
      variables: input,
    });

    if (response?.errors) {
      console.log("Error deleting part", response.errors);
    }

    const result = response?.data?.asAdmin?.deletePart;
    if (!result) return;

    if (taskId) {
      setTasks((p) => {
        return p.map((it) => {
          if (it.id !== taskId) return it;

          if (!it.taskParts) return it;

          if (!partId && !subPartId && !subSubPartId) {
            it.taskParts.items = it.taskParts.items.filter((part) => part.id !== result.id);
          } else {
            it.taskParts.items = it.taskParts.items
              .map((part) => {
                if (!part.parts?.items) return part;
                part.parts.items = part.parts?.items
                  .map((subPart) => {
                    if (!subPart.parts?.items) return subPart;
                    subPart.parts.items = subPart.parts?.items.filter((it) => it.id !== input.id);
                    return subPart;
                  })
                  .filter((it) => it.id !== input.id);
                return part;
              })
              .filter((it) => it.id !== input.id);
          }

          return it;
        }) as TaskFields[];
      });
    } else if (templateId) {
      setTemplates((templates) => {
        return templates.map((it) => {
          if (it.id !== templateId) return it;

          if (!it.parts) return it;

          if (!partId && !subPartId && !subSubPartId) {
            it.parts.items = it.parts.items.filter((part) => part.id !== result.id);
          } else {
            it.parts.items = it.parts.items
              .map((part) => {
                if (!part.parts?.items) return part;
                part.parts.items = part.parts?.items
                  .map((subPart) => {
                    if (!subPart.parts?.items) return subPart;
                    subPart.parts.items = subPart.parts?.items.filter((it) => it.id !== input.id);
                    return subPart;
                  })
                  .filter((it) => it.id !== input.id);
                return part;
              })
              .filter((it) => it.id !== input.id);
          }

          return it;
        }) as TemplateFields[];
      });
    } else {
      setParts((parts) => {
        return parts
          .map((part) => {
            if (!part.parts?.items) return part;
            part.parts.items = part.parts?.items
              .map((subPart) => {
                if (!subPart.parts?.items) return subPart;
                subPart.parts.items = subPart.parts?.items.filter((it) => it.id !== input.id);
                return subPart;
              })
              .filter((it) => it.id !== input.id);
            return part;
          })
          .filter((it) => it.id !== input.id);
      });
    }
  };

  const assignUserToTaskTemplate = async (input: AssignUserToTaskTemplateVariables) => {
    const response = await client?.mutate<AssignUserToTaskTemplate>({
      mutation: assignUserToTaskTemplateMutation,
      variables: input,
    });

    const taskUser = response?.data?.asAdmin?.assignUserToTaskTemplate;
    if (!taskUser) return;
    setTemplates((it) => {
      return it.map((template) => {
        if (template.id !== input.taskTemplateId) return template;
        if (!template.users) template.users = { __typename: "TemplateTaskUserList", items: [] };
        template.users.items.push(taskUser);
        return template;
      });
    });
  };

  const unassignUserToTaskTemplate = async (input: UnassignUserToTaskTemplateVariables) => {
    const response = await client?.mutate<UnassignUserToTaskTemplate>({
      mutation: unassignUserToTaskTemplateMutation,
      variables: input,
    });

    const taskUser = response?.data?.asAdmin?.removeTaskTemplateUser;
    if (!taskUser) return;
    setTemplates((it) => {
      return it.map((template) => {
        if (template.id !== input.taskTemplateId) return template;
        if (!template.users) return template;
        template.users.items = template.users.items.filter((it) => it.userId !== taskUser.userId);
        return template;
      });
    });
  };

  const assignUserToTask = async (input: AssignUserToTaskVariables, taskProp?: TaskFields) => {
    const response = await client?.mutate<AssignUserToTask>({
      mutation: assignUserToTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.assignUserToTask) return;

    const { taskId, userId } = response.data.asAdmin.assignUserToTask;

    const task = taskProp || tasks.find((it) => it.id === taskId);
    if (!task) return;

    const user = users.find((it) => it.id === userId);

    const project = projects.find((it) => it.id === task.projectId);

    if (project) {
      const { id: projectId } = project;
      // Check to see if user is already a project user, if not, also add them to project.
      if (!project?.users?.items.find((it) => it.userId === userId)) {
        await addProjectUser({
          input: {
            clientId: user?.clientId || "",
            projectId,
            userId,
            role: [ProjectRole.technician, ProjectRole.technicianInspector].includes(user?.projectRole)
              ? user?.projectRole
              : ProjectRole.technician,
          },
        });
      }
    }

    setTasks((p) =>
      p.map((task) => {
        if (taskId !== task.id) return task;

        if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
        task.users.items.push({
          __typename: "TaskUser",
          userId,
          user: { name: user.name, __typename: "User" },
        });
        return task;
      }),
    );
  };

  const assignUserToTasks = async (input: AssignUserToTasksVariables) => {
    const response = await client?.mutate<AssignUserToTasks>({
      mutation: assignUserToTasksMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.assignUserToTasks) return;

    const taskIds = response.data.asAdmin.assignUserToTasks.map((it) => it.taskId);
    const userId = input.userId;
    const user = users.find((it) => it.id === userId);

    for (const taskId of taskIds) {
      const task = tasks.find((it) => it.id === taskId);
      if (!task) continue;

      const project = projects.find((it) => it.id === task.projectId);

      if (project) {
        const { id: projectId } = project;

        // Check to see if user is already a project user, if not, also add them to project.
        if (!project?.users?.items.find((it) => it.userId === userId)) {
          await addProjectUser({
            input: {
              clientId: user?.clientId || "",
              projectId,
              userId,
              role: [ProjectRole.technician, ProjectRole.technicianInspector].includes(user?.projectRole)
                ? user?.projectRole
                : ProjectRole.technician,
            },
          });
        }
      }

      setTasks((p) =>
        p.map((task) => {
          if (taskId !== task.id) return task;

          if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
          task.users.items.push({ __typename: "TaskUser", userId, user: { __typename: "User", name: user.name } });
          return task;
        }),
      );
    }
  };

  const removeTaskUser = async (input: RemoveTaskUserVariables) => {
    const response = await client?.mutate<RemoveTaskUser>({
      mutation: removeTaskUserMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.removeTaskUser) return;

    setTasks((p) =>
      p.map((task) => {
        if (input.id !== task.id) return task;
        if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
        task.users.items = task.users.items.filter((it) => it.userId !== input.userId);
        return task;
      }),
    );
  };

  const removeUserFromTasks = async (input: RemoveUserFromTasksVariables) => {
    const response = await client?.mutate<RemoveUserFromTasks>({
      mutation: removeUserFromTasksMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.removeUserFromTasks) return;

    setTasks((p) =>
      p.map((task) => {
        if (!input.taskIds.includes(task.id)) return task;
        if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
        task.users.items = task.users.items.filter((it) => it.userId !== input.userId);
        return task;
      }),
    );
  };

  const deleteTask = async (
    input: DeleteTaskVariables,
    isWorkOrderTemplate?: boolean,
    isTaskTemplate?: boolean,
    isTodo?: boolean,
  ) => {
    await client?.mutate<DeleteTask>({
      mutation: deleteTaskMutation,
      variables: input,
    });

    if (isTaskTemplate) {
      // Task template -> todo
      setTemplateTasks((t) => t.filter((it) => it.id !== input.id));
    } else if (isTodo) {
      const parent = tasks.find((it) => it.id === input.parentId);
      if (!parent) {
        setTasks((p) => p.filter((it) => it.id !== input.id));
        return;
      }

      if (!parent.tasks) return;
      parent.tasks.items = parent.tasks.items.filter((it) => it.id !== input.id).sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    } else {
      setTasks((p) => p.filter((it) => it.id !== input.id));
    }
  };

  const getTemplate = async (id: string) => {
    const response = await client?.query<GetTemplate>({
      query: getTemplateQuery,
      variables: { id },
      fetchPolicy: "no-cache",
    });

    const template = response?.data?.asAdmin?.getTemplate;
    if (!template) return;

    setTemplates((it) =>
      it.map((ts) => {
        if (ts.id === id) return template;
        return ts;
      }),
    );
  };

  const getTemplateTasks = async (parentId: string, nextToken?: string | null | undefined) => {
    const response = await client?.query<ListTasks>({
      query: listTasksQuery,
      fetchPolicy: "no-cache",
      variables: { criteria: { parentId, status: null }, nextToken: nextToken || null },
    });
    const items = response?.data.asAdmin?.listTasks.items || [];
    setTemplateTasks((p) => [...p, ...items].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i));
    return { nextToken: response?.data.asAdmin?.listTasks?.nextToken, tasks: items };
  };

  const addCorrectiveAction = async (input: AddCorrectiveActionVariables) => {
    const response = await client?.mutate<AddCorrectiveAction>({
      mutation: addCorrectiveActionMutation,
      variables: input,
    });

    return response.data?.asAdmin.addCorrectiveAction;
  };

  const getCorrectiveActions = async (input: GetCorrectiveActionsVariables) => {
    const response = await client?.query<GetCorrectiveActions>({
      query: getCorrectiveActionsQuery,
      fetchPolicy: "no-cache",
      variables: input,
    });

    const items = response?.data.asAdmin?.getTask.correctiveActions.items || [];
    return items;
  };

  const getTask = async (id: string) => {
    const response = await client?.query<GetTask>({
      query: getTaskQuery,
      fetchPolicy: "no-cache",
      variables: { id },
    });
    const it = response?.data.asAdmin?.getTask as unknown as TaskFields | null | undefined;

    if (!it) return;

    setTasks((p) => [
      ...p.map((t) => {
        if (t.id === it.id) {
          return it;
        }
        return t;
      }),
      ...(!p.find((t) => t.id === it.id) ? [it] : []),
    ]);

    return it;
  };

  const getTaskDebounced = debounce(async (id: string, parentId: string) => {
    const response = await client?.query<GetTask>({
      query: getTaskQuery,
      fetchPolicy: "no-cache",
      variables: { id, parentId },
    });
    const it = response?.data.asAdmin?.getTask as unknown as TaskFields | null | undefined;

    if (!it) return;

    setTasks((p) => [
      ...p.map((t) => {
        if (t.id === it.id) {
          return it;
        }
        return t;
      }),
      ...(!p.find((t) => t.id === it.id) ? [it] : []),
    ]);
  }, 1500);

  const getUser = async (id: string) => {
    const response = await client?.query<GetUserQuery>({
      query: getUserQuery,
      fetchPolicy: "no-cache",
      variables: { id },
    });
    const user = response?.data.asAdmin?.getUser as UserFields | null | undefined;

    if (!user) return undefined;

    setUsers((p) =>
      p.map((it) => {
        if (it.id === user.id) {
          return user;
        }
        return it;
      }),
    );

    return user;
  };

  const addUser = async (input: AddUserVariables) => {
    const response = await client?.mutate<AddUser>({
      mutation: addUserMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addUser) return;
    const user = response.data.asAdmin.addUser;

    setUsers((p) => [...p, user]);

    if (input.projectId && input.projectRole) {
      const project = projects.find((it) => it.id === input.projectId);

      if (!project) return;

      project?.users?.items.push({ __typename: "ProjectUser", userId: user.id, role: input.projectRole, ct: null });
      setProjects((p) => p.map((it) => (it.id === project.id ? project : it)));
    }

    return user;
  };

  const sendSignatureSignUpEmail = async (userId: string) => {
    const response = await client?.mutate<SendSignatureEmail>({
      mutation: sendSignatureSignUpEmailMutation,
      variables: { userId },
    });

    if (response?.data?.asAdmin?.sendSignatureSignUpEmail?.success) {
      console.log(`Send signature email to ${userId}`);
    }
  };

  const listUserSignatures = async (id?: string, nextToken?: string) => {
    const response = await client?.query<ListUserSignatures>({
      query: listUserSignaturesQuery,
      fetchPolicy: "no-cache",
      variables: { id, nextToken },
    });

    const result = response?.data.asAdmin?.getMe.signatures;

    return { items: result?.items || [], nextToken: result?.nextToken };
  };

  const deleteUser = deleteOperation<DeleteUser, DeleteUserVariables, UserFields>(
    deleteUserMutation,
    (v: DeleteUser) => v.asAdmin?.deleteUser as UserFields,
    setUsers,
  );

  const updateUser = updateOperation<UpdateUser, UpdateUserVariables, UserFields>(
    updateUserMutation,
    (v: UpdateUser) => v.asAdmin?.updateUser as UserFields,
    setUsers,
  );

  const getCameras = async (projectId?: string): Promise<CameraFields[]> => {
    const response = await client?.query<GetCameras>({
      query: getCamerasQuery,
      variables: { projectId },
    });
    return (response?.data.asAdmin?.getCameras as CameraFields[]) || [];
  };

  const addTimesheet = async (input: CreateTimeEntrySheetRequest): Promise<TimesheetFields | null> => {
    const response = await client?.mutate<AddTimeSheetEntry>({
      mutation: addTimeSheetEntryMutation,
      variables: { input: { ...input, taskId: input.taskId || "InHangar" } },
    });
    const timesheet = response?.data?.asAdmin?.addTimeSheetEntry as TimesheetFields;
    if (!timesheet) return null;

    timesheet.taskSummary = tasks.find((it) => it.id === timesheet.taskId)?.name || null;
    timesheet.projectSummary = projects.find((it) => it.id === timesheet.projectId)?.name || null;
    return timesheet;
  };

  const getTimesheets = async (from: dayjs.Dayjs, to: dayjs.Dayjs): Promise<TimesheetFields[]> => {
    const response = await client?.query<ListTimesheets>({
      query: listTimesheetsQuery,
      variables: { fromDT: from.toISOString(), toDT: to.toISOString() },
      fetchPolicy: "no-cache",
    });

    let items = response?.data.asAdmin?.listTimeSheets.items || [];
    let nextToken = response?.data.asAdmin?.listTimeSheets.nextToken;

    const getMoreData = async (newNextToken: string | null) => {
      const response = await client?.query<ListTimesheets>({
        query: listTimesheetsQuery,
        variables: { fromDT: from.toISOString(), toDT: to.toISOString(), nextToken: newNextToken },
        fetchPolicy: "no-cache",
      });
      items = [...items, ...(response?.data.asAdmin?.listTimeSheets.items || [])];
      nextToken = response?.data.asAdmin?.listTimeSheets?.nextToken;
    };

    while (nextToken) {
      await getMoreData(nextToken);
    }

    return items;
  };

  const listProjectTimesheets = async (projectId: string): Promise<TimesheetFields[]> => {
    const response = await client?.query<ListProjectTimesheets>({
      query: listProjectTimesheetsQuery,
      variables: { projectId },
    });

    let items = response?.data.asAdmin?.listAllTimeSheets.items || [];
    let nextToken = response?.data.asAdmin?.listAllTimeSheets.nextToken;

    const getMoreData = async (newNextToken: string | null) => {
      const response = await client?.query<ListProjectTimesheets>({
        query: listProjectTimesheetsQuery,
        variables: { projectId, nextToken: newNextToken },
      });
      items = [...items, ...(response?.data.asAdmin?.listAllTimeSheets.items || [])];
      nextToken = response?.data.asAdmin?.listAllTimeSheets?.nextToken;
    };

    while (nextToken) {
      await getMoreData(nextToken);
    }

    return items as TimesheetFields[];
  };

  const updateTimeSheetEntry = async (input: UpdateTimeSheetEntryVariables) => {
    const response = await client?.mutate<UpdateTimeSheetEntry>({
      mutation: updateTimeSheetEntryMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.updateTimeSheetEntry) return;
    return response?.data?.asAdmin?.updateTimeSheetEntry;
  };

  const approveTimeSheetEntry = async (input: ApproveTimeSheetEntryVariables) => {
    const response = await client?.mutate<ApproveTimeSheetEntry>({
      mutation: approveTimeSheetEntryMutation,
      variables: input,
    });

    const id = response?.data?.asAdmin?.approveTimeSheetEntry;

    if (!id) return;

    return id;
  };

  const approveTimeSheetEntries = async (input: ApproveTimeSheetEntriesVariables) => {
    const response = await client?.mutate<ApproveTimeSheetEntries>({
      mutation: approveTimeSheetEntriesMutation,
      variables: input,
    });

    const ids = response?.data?.asAdmin?.approveTimeSheetEntries;

    if (!ids) return;

    return ids;
  };

  const clockInUser = async (input: ClockInUserVariables) => {
    const response = await client?.mutate<ClockInUser>({
      mutation: clockInUserMutation,
      variables: input,
    });

    const timesheet = response?.data?.asAdmin?.clockInUser;
    return timesheet;
  };

  const clockOutUser = async (input: ClockOutUserVariables) => {
    const response = await client?.mutate<ClockOutUser>({
      mutation: clockOutUserMutation,
      variables: input,
    });

    const timesheet = response?.data?.asAdmin?.clockOutUser;
    return timesheet;
  };

  const updateTimeSheetEntryDiscrepancy = async (input: UpdateTimeSheetEntryDiscrepancyVariables) => {
    const response = await client?.mutate<UpdateTimeSheetEntryDiscrepancy>({
      mutation: updateTimeSheetEntryDiscrepancyMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.updateTimeSheetEntryDiscrepancy) return;
    return response?.data?.asAdmin?.updateTimeSheetEntryDiscrepancy;
  };

  const deleteTimeSheetEntry = async (input: DeleteTimeSheetEntryVariables) => {
    const response = await client?.mutate<DeleteTimeSheetEntry>({
      mutation: deleteTimeSheetEntryMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.deleteTimeSheetEntry) return;
    console.log("successfully deleted timesheet entry");
  };

  const addProjectTemplate = async (
    projectId: string,
    copyUsers: boolean,
    input: CreateProjectTemplateRequest,
  ): Promise<TemplateFields | null> => {
    console.log({ projectId, copyUsers, input });

    const response = await client?.mutate<AddProjectTemplate>({
      mutation: addProjectTemplateMutation,
      variables: { projectId, copyUsers, input },
    });
    const details: TemplateFields | undefined = response?.data?.asAdmin?.addProjectTemplate;

    if (details) {
      setTemplates((t: TemplateFields[]) => [...t, details]);
    }

    return details || null;
  };

  const updateProjectTemplate = async (
    id: string,
    input: CreateProjectTemplateRequest,
  ): Promise<TemplateFields | null> => {
    const response = await client?.mutate<UpdateProjectTemplate>({
      mutation: updateProjectTemplateMutation,
      variables: { id, input },
    });

    const details: TemplateFields | undefined = response?.data?.asAdmin?.updateProjectTemplate;

    if (details) {
      setTemplates((t: TemplateFields[]) =>
        t.map((it: TemplateFields) => {
          if (it.id === details.id) return details;
          return it;
        }),
      );
    }

    return details || null;
  };

  const deleteProjectTemplate = async (input: DeleteProjectTemplateVariables): Promise<string | undefined> => {
    const response = await client?.mutate<DeleteProjectTemplate>({
      mutation: deleteProjectTemplateMutation,
      variables: input,
    });
    return response?.data?.asAdmin?.deleteProjectTemplate;
  };

  const deleteTaskTemplate = async (input: DeleteTaskTemplateVariables): Promise<string | undefined> => {
    const response = await client?.mutate<DeleteTaskTemplate>({
      mutation: deleteTaskTemplateMutation,
      variables: input,
    });
    return response?.data?.asAdmin?.deleteTaskTemplate;
  };

  const addTaskTemplate = async (
    taskId: string,
    copyUsers: boolean,
    input: CreateTaskTemplateRequest,
  ): Promise<TemplateFields | null> => {
    const response = await client?.mutate<AddTaskTemplate>({
      mutation: addTaskTemplateMutation,
      variables: { taskId, copyUsers, input },
    });
    const details: TemplateFields | undefined = response?.data?.asAdmin?.addTaskTemplate;

    if (details) {
      setTemplates((t: TemplateFields[]) => [...t, details]);
    }

    return details || null;
  };

  const updateTaskTemplate = async (id: string, input: UpdateTaskTemplateRequest): Promise<TemplateFields | null> => {
    console.log("input: ", input);

    const response = await client?.mutate<UpdateTaskTemplate>({
      mutation: updateTaskTemplateMutation,
      variables: { id, input },
    });
    const details: TemplateFields | undefined = response?.data?.asAdmin?.updateTaskTemplate;

    if (details) {
      setTemplates((t: TemplateFields[]) =>
        t.map((it) => {
          if (it.id === details.id) {
            return details;
          } else {
            return it;
          }
        }),
      );
    }

    return details || null;
  };

  const getTemplates = async (input: ListTemplatesVariables) => {
    const response = await client?.query<ListTemplates>({
      query: listTemplatesQuery,
      variables: input,
    });
    setTemplates(response?.data.asAdmin?.listTemplates.items || []);
  };

  const updateTaskOrder = async (input: TaskFields[]) => {
    // * this updates the UI with correct index, no idea how/why it works though
    setTasks((it: any) => it.map((item: TaskFields) => item));

    for (const it of input) {
      await updateTask(
        {
          id: it?.id,
          parentId: it?.parentId,
          input: {
            orderIndex: it?.orderIndex,
          },
        },
        false,
      );
    }
  };

  const getPulseReports = async (input: ListPulseReportsVariables) => {
    const response = await client?.query<ListPulseReports>({
      query: listPulseReports,
      variables: input,
      fetchPolicy: "no-cache",
    });

    return response?.data.asAdmin?.getPulseReports.items || [];
  };

  const getPulseReportsAndTimeSheets = async (input: GetPulseReportsAndTimeSheetsVariables) => {
    const response = await client?.query<GetPulseReportsAndTimeSheets>({
      query: listPulseReportsAndTimesheets,
      variables: input,
    });
    return {
      pulseReports: response?.data.asAdmin?.getPulseReports.items || [],
      timeSheets: response?.data.asAdmin?.listTimeSheets.items || [],
    };
  };

  const getClient = async (input: GetClientVariables) => {
    const response = await client?.query<GetClient>({
      query: getClientQuery,
      variables: input,
    });

    const updatedClient = response?.data?.asAdmin?.getClient;
    if (!updatedClient) return;

    setClients(
      clients.map((client) => {
        if (client.id === input.id) {
          return updatedClient;
        }
        return client;
      }),
    );
  };

  const getAircraft = async (input: GetAircraftVariables) => {
    const response = await client?.query<GetAircraft>({
      query: getAircraftQuery,
      variables: input,
    });

    const updatedAircraft = response?.data?.asAdmin?.getAircraft;
    if (!updatedAircraft) return;

    if (!aircraft.find((it) => it.id === updatedAircraft.id)) {
      setAircraft((it: any) => [...it, updatedAircraft]);
      return;
    }

    setAircraft((it) => {
      return it.map((craft) => {
        if (craft.id === input.id) {
          return updatedAircraft;
        }
        return craft;
      });
    });
  };

  const updateClientSettings = async (input: UpdateClientSettingsVariables) => {
    const response = await client?.mutate<UpdateClientSettings>({
      mutation: updateClientSettingsMutation,
      variables: input,
    });

    const clientSettings = response?.data?.asAdmin?.setClientSettings;
    if (!clientSettings) return;

    setClients(
      clients.map((client) => {
        if (client.id === input.clientId) {
          client.settings = clientSettings;
        }
        return client;
      }),
    );
  };

  const incrementTemplateEstimateCount = async () => {
    const response = await client?.mutate<IncrementTemplateEstimateCountMutation>({
      mutation: incrementTemplateEstimateCountMutation,
    });
    return response?.data?.asAdmin?.incrementTemplateEstimateCount.estimateCount;
  };

  const setTenantSettings = async (input: SetTenantSettingsMutationVariables) => {
    console.log("setTenantSettings", input);
    const response = await client?.mutate<SetTenantSettingsMutation>({
      mutation: setTenantSettingsMutation,
      variables: input,
    });
    return response?.data?.asAdmin?.setTenantSettings.terms;
  };

  const getTenantSettings = async () => {
    const response = await client?.query<GetTenantSettingsQuery>({
      query: getTenantSettingsQuery,
      fetchPolicy: "no-cache",
    });
    return response?.data?.asAdmin?.getTenantSettings;
  };

  const addSubcontractor = async (input: AddSubcontractorVariables) => {
    const response = await client?.mutate<AddSubcontractor>({
      mutation: addSubcontractorMutation,
      variables: input,
    });
    return response?.data?.asAdmin?.addSubcontractor;
  };

  const updateSubcontractor = async (input: UpdateSubcontractorVariables) => {
    const response = await client?.mutate<UpdateSubcontractor>({
      mutation: updateSubcontractorMutation,
      variables: input,
    });
    return response?.data?.asAdmin?.updateSubcontractor;
  };

  const deleteSubcontractor = async (input: DeleteSubcontractorVariables) => {
    await client?.mutate<DeleteSubcontractor>({
      mutation: deleteSubcontractorMutation,
      variables: input,
    });
  };

  return (
    <Context.Provider
      value={{
        isLoading,
        isInitialLoading,
        client,
        parts,
        me,
        entities: aircraft,
        clients,
        locations,
        projects,
        tasks,
        templateTasks,
        users,
        acceptTermsAndConditions,
        addEntity: addAircraft,
        updateEntity: updateAircraft,
        deleteEntity: deleteAircraft,
        getUploadUrl,
        getTemplateTasks,
        addAttachment,
        deleteAttachment,
        addClient,
        getEntity: getAircraft,
        updateClient,
        deleteClient,
        addLocation,
        updateLocation,
        deleteLocation,
        searchLocationsByName,
        addMessage,
        getProjectAndListTasks,
        getProject,
        addProject,
        addProjectUser,
        removeProjectUser,
        updateProject,
        updateProjectv2,
        deleteProject,
        completeProject,
        completeTask,
        getCorrectiveActions,
        addCorrectiveAction,
        getTask,
        listTasks,
        listTasksAndParts,
        addTask,
        assignUserToTask,
        assignUserToTaskTemplate,
        unassignUserToTaskTemplate,
        assignUserToTasks,
        removeTaskUser,
        removeUserFromTasks,
        updateTask,
        deleteTask,
        addPart,
        updatePart,
        deletePart,
        assignPartToParent,
        assignAssemblyToParent,
        addSubcontractor,
        updateSubcontractor,
        deleteSubcontractor,
        getUser,
        sendSignatureSignUpEmail,
        listUserSignatures,
        addUser,
        updateUser,
        deleteUser,
        getCameras,
        addTimesheet,
        getTimesheets,
        listProjectTimesheets,
        deleteTimeSheetEntry,
        updateTimeSheetEntry,
        approveTimeSheetEntry,
        approveTimeSheetEntries,
        clockInUser,
        clockOutUser,
        updateTimeSheetEntryDiscrepancy,
        listTaskSignatures,
        addShifts,
        updateShifts,
        deleteShifts,
        getUserShift,
        listUserShifts,
        addUserShift,
        updateUserShift,
        deleteUserShift,
        assignLocationToClient,
        unassignLocationFromClient,
        inProgressTimesheets,
        updatedTimesheet,
        addProjectTemplate,
        updateProjectTemplate,
        deleteProjectTemplate,
        addTaskTemplate,
        updateTaskTemplate,
        templates,
        getTemplates,
        getTemplate,
        addProjectFromTemplate,
        addTaskFromTemplate,
        deleteTaskTemplate,
        updateTaskOrder,
        getPulseReports,
        getPulseReportsAndTimeSheets,
        pulseReports,
        getClient,
        updateClientSettings,
        incrementTemplateEstimateCount,
        setTenantSettings,
        getTenantSettings,
        userFilteredLocations,
        setUserFilteredLocations,
      }}
    >
      {children}
    </Context.Provider>
  );
};
