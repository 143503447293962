import gql from "graphql-tag";

export const PulseReportFields = gql`
  fragment PulseReportFields on PulseReport {
    id
    userId
    tasks {
      taskId
      projectId
    }
    date
    rating
    comment
  }
`;

export const listPulseReports = gql`
  ${PulseReportFields}

  query ListPulseReports(
    $criteria: GetPulseReportCriteria!
    $limit: Int
    $nextToken: String
    $scanIndexForward: Boolean
  ) {
    asAdmin {
      getPulseReports(criteria: $criteria, limit: $limit, nextToken: $nextToken, scanIndexForward: $scanIndexForward) {
        items {
          ...PulseReportFields
        }
      }
    }
  }
`;

export const listPulseReportsAndTimesheets = gql`
  ${PulseReportFields}

  query GetPulseReportsAndTimeSheets(
    $criteria: GetPulseReportCriteria!
    $fromDt: AWSDateTime!
    $toDt: AWSDateTime!
    $limit: Int
    $nextToken: String
    $scanIndexForward: Boolean
  ) {
    asAdmin {
      getPulseReports(criteria: $criteria, limit: $limit, nextToken: $nextToken, scanIndexForward: $scanIndexForward) {
        items {
          ...PulseReportFields
        }
      }

      listTimeSheets(fromDT: $fromDt, toDT: $toDt, status: Completed, excludeTasklessTimesheets: true) {
        items {
          id
          taskId
          projectId
          fromDT
          toDT
          amountMins
          user {
            id
          }
        }
      }
    }
  }
`;
