import React, { useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "../../stylesheets/pdf-viewer.css";
import styled from "styled-components";

const PDFViewerWrapper = styled.div<{ [props: string]: any }>`
  position: relative;
  display: block;
  max-width: 900px;
  width: 100%;
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0;
  }
  margin: 2rem;
  border: 1px solid #d3d3d3;
`;

interface PDFViewerProps {
  pdfString: string;
  customPages?: boolean;
  currentPage?: number;
  onLoadSuccess: (numPages: number) => void;
  children?: any;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({
  pdfString,
  currentPage,
  onLoadSuccess,
  customPages,
  children,
}) => {
  return (
    <PDFViewerWrapper>
      <Document file={pdfString} renderMode="svg" onLoadSuccess={({ numPages }) => onLoadSuccess(numPages)}>
        {!customPages && <Page pageNumber={currentPage} />}

        {children && <div>{children}</div>}
      </Document>
    </PDFViewerWrapper>
  );
};
