import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import dayjs from "dayjs";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import Palette from "../../palette.json";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import "@rmwc/checkbox/styles";

import styled from "styled-components";

import useMultiTenant from "hooks/useMultiTenant";
import { ProjectRole, UserRole } from "types/admin/globalTypes";
import { AutocompleteInput } from "../AutocompleteInput";
import { useAdminApi } from "hooks/useAdminApi";
import { sortByProperty } from "hooks/useSorted";
import { UserFields } from "types/admin/UserFields";
import { Label } from "../Label";
import { useProject } from "hooks/params/useProject";
import { FormField, FormRow } from "../CommonForm";
import { Select } from "../Select";
import { Checkbox } from "@rmwc/checkbox";

interface ParamsT {
  userId: string;
  activityId: string;
}

interface ActionItemPickerProps {
  type: string;
  defaultValue: any;
  label: string;
  onChange: any;
}

interface TimesheetActivityAddDialogProps {
  onSave: any;
  onBack?: () => void;
  projectId?: string;
  taskId?: string;
}

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionItemPicker = styled(TextField)<React.PropsWithChildren<ActionItemPickerProps>>`
  margin-bottom: 16px;
`;

export const TimesheetActivityAddDialog: React.FC<TimesheetActivityAddDialogProps> = ({
  onSave,
  onBack,
  projectId,
  taskId,
}) => {
  const history = useHistory();
  const { userId } = useParams<ParamsT>();
  const { tasks, projects, users } = useAdminApi();
  const { theme } = useMultiTenant();
  const projectParam = useProject();

  let sorted: any[] = users;
  if (taskId) {
    const task = tasks.find((t) => t.id === taskId);
    sorted = users.filter((it) => task.users?.items.find((jt) => jt.userId === it.id)) || [];
  } else if (projectId) {
    const project = projects.find((p) => p.id === projectId);
    sorted = users.filter((it) => project.users?.items.find((jt) => jt.userId === it.id)) || [];
  }
  sorted = sorted
    .filter(
      (it) =>
        it.projectRole !== ProjectRole.customer &&
        it.userRole !== UserRole.Admin &&
        it.userRole !== UserRole.AdminManager,
    )
    .sort(sortByProperty("name"))
    .map((it) => ({
      value: it.id,
      label: it.name,
    }));

  const [taskOptions, setTaskOptions] = useState<any[]>([]);
  const [selectedTask, setSelectedTask] = useState<any>();
  const [generalLabor, setGeneralLabor] = useState<boolean>(false);
  const [clockInDateTime, setClockInDateTime] = useState<any>(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  const [clockOutDateTime, setClockOutDateTime] = useState<any>(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  const [user, setUser] = useState<UserFields | undefined>(userId ? users?.find((i) => i.id === userId) : undefined);
  const [adminMessage, setAdminMessage] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    const options = tasks
      .filter((task) => task.projectId === projectId)
      .map((task) => ({
        label: task.name,
        value: task.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setTaskOptions(options);
    setSaveButtonDisabled(false);
  }, [projectId, tasks]);

  useEffect(() => {
    if (generalLabor) {
      setSelectedTask(undefined);
    }
  }, [generalLabor]);

  const cancel = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  const save = () => {
    if (!user || !clockInDateTime || !clockOutDateTime) return;
    if (projectId) {
      if (!taskId && !selectedTask && !generalLabor) return;
    }
    if (taskId || selectedTask || generalLabor) {
      if (!projectId) return;
    }

    const taskSummary = tasks.find((task) => task.id === taskId)?.name;
    const projectSummary = projects.find((project) => project.id === projectId)?.name;

    onSave({
      fromDT: new Date(clockInDateTime),
      toDT: new Date(clockOutDateTime),
      userId: user.id,
      ...(projectId && { projectId }),
      ...(generalLabor ? { taskId: "GeneralLabor" } : { taskId: selectedTask || undefined }),
      ...(taskId && { taskId }),
      ...(projectSummary && { projectSummary }),
      ...(taskSummary && { taskSummary }),
    });

    cancel();
  };

  const onSetUser = (id: string) => {
    const user = users.find((it) => it.id === id);
    if (!user) return;
    setUser(user);
  };

  const onClockInUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockInDateTime(datetime);
    }
  };

  const onClockOutUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockOutDateTime(datetime);
    }
  };

  const onUpdateAdminMessage = (value: any) => {
    const message = value.currentTarget.value;
    setAdminMessage(message);
  };

  useEffect(() => {
    // * if clock in and clock out datetimes have been changed, save button is enabled
    if (clockInDateTime !== undefined || clockOutDateTime !== undefined) {
      setSaveButtonDisabled(false);
    }

    // * if clock in time is greater than clock out time, save button is disabled
    if (new Date(clockInDateTime) >= new Date(clockOutDateTime)) {
      setSaveButtonDisabled(true);
    }

    if (!user || !user?.name) {
      setSaveButtonDisabled(true);
    }
  }, [clockInDateTime, clockOutDateTime, adminMessage, user]);

  return (
    <Dialog open preventOutsideDismiss renderToPortal={true}>
      <DialogTitle>Add a timesheet</DialogTitle>

      <DialogContent>
        <div>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
            <Label>User</Label>
            <AutocompleteInput
              disabled={userId !== undefined}
              placeholder="Search for a user..."
              value={user?.name ?? ""}
              options={sorted as any}
              select={(id) => onSetUser(id)}
              testId="user-search"
              absolute
              shouldClearOnSelect
            />
          </div>

          <div>
            <ActionsWrapper>
              <ActionItemPicker
                type="datetime-local"
                defaultValue={dayjs(clockInDateTime).format("YYYY-MM-DDTHH:mm:ss")}
                label={"Clock in"}
                data-test-id="clock-in"
                onChange={onClockInUpdate}
              />

              <ActionItemPicker
                type="datetime-local"
                defaultValue={dayjs(clockOutDateTime).format("YYYY-MM-DDTHH:mm:ss")}
                label={"Clock out"}
                data-test-id="clock-out"
                onChange={onClockOutUpdate}
              />
            </ActionsWrapper>
          </div>

          {projectId && !taskId && (
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div style={{ marginRight: "4px", color: theme.primary }}>General Labor</div>
              <Checkbox
                style={{ color: theme.secondary }}
                checked={generalLabor}
                onChange={() => setGeneralLabor(!generalLabor)}
              />
            </div>
          )}

          {projectParam && !taskId && !generalLabor && (
            <>
              {taskOptions.length > 0 && (
                <FormRow>
                  <FormField>
                    <Label htmlFor="project">Select Task</Label>
                    <Select
                      outlined
                      options={taskOptions}
                      onInput={(event: any) => setSelectedTask(event.target?.value)}
                    />
                  </FormField>
                </FormRow>
              )}
            </>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton danger onClick={cancel}>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        <ThemeProvider options={{ primary: Palette.Green }}>
          <DialogButton isDefaultAction raised onClick={save} disabled={saveButtonDisabled} data-test-id="btn-save">
            Save
          </DialogButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
