import React, { useState } from "react";

import { useHistory, useLocation } from "react-router";

import { useAdminApi } from "hooks/useAdminApi";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import useFiltered from "hooks/useFiltered";
import { usePagenator } from "hooks/usePagenator";

import { Button } from "components/Button";
import { SearchInput } from "../TextInput";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";

import { PagenatorControls } from "../PagenatorControls";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import EmptyState from "../EmptyState";
import { PartFields } from "types/admin/PartFields";
import { PartType } from "types/admin/globalTypes";
import numeral from "numeral";
import dayjs from "dayjs";
import { usePart } from "hooks/params/usePart";
import { showErrorMessage } from "../../utilities/handleError";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import { useAircraft } from "hooks/params/useAircraft";
import { PartIcon } from "./PartIcon";
import useTranslations from "hooks/useTranslations";

export const PartsList: React.FC = () => {
  const { tCommon, tFeature } = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const { parts, isExpendable, isRotable, partId, subPartId, subSubPartId } = usePart();
  const { task } = useTask();
  const { entity: aircraft } = useAircraft();
  const { template } = useTemplate();

  const { deletePart } = useAdminApi();

  const [search, setSearch] = useState<string>("");
  const filtered = useFiltered(
    parts,
    (it) => !!it.name?.toLowerCase().includes(search.toLowerCase()) || !!it.number?.includes(search),
  );

  const { pageRange, ...controls } = usePagenator(10, filtered.length);

  const onDelete = async (part: PartFields) => {
    let hasConfirmed = false;

    hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete part: ${part.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    try {
      await deletePart(
        { id: part.id, parentEntityId: part.parentEntityId },
        partId,
        subPartId,
        subSubPartId,
        task?.id,
        template?.id,
      );
      snackbar.notify({ title: "Part removed successfully!" });
    } catch (error) {
      showErrorMessage(error);
    }
  };

  let COLS = ["Number", "Name", "Serial Number", "Cost", "Quantity", "Exp. Date", ...(!aircraft ? [""] : [])];

  const expendableOrRotable = isExpendable || isRotable;
  if (expendableOrRotable) {
    COLS = ["Number", "Serial Number", "Exp. Date", ...(!aircraft ? [""] : [])];
  }

  const calculateCost = (part: PartFields, perPart?: boolean) => {
    if (perPart || part.type !== PartType.Consumable) {
      return "$" + numeral(part.markupCost || 0).format("0,0.00");
    }

    return "$" + numeral((part.markupCost || 0) * (part.quantity || 1)).format("0,0.00");
  };

  const getQuantity = (part: PartFields) => {
    if (!part.type) return part.quantity || 0;
    if (part.type === PartType.Assembly) return "N/A";
    if ([PartType.Expendable, PartType.Rotable].includes(part.type)) {
      return part.parts?.items?.length || 0;
    }
    return part.quantity || 0;
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {aircraft ? `${tCommon("Aircraft")} ` : ""} {tCommon("Parts")} {aircraft ? ` History ` : ""}
          <TitleCount>({parts.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search by Name"
          data-test-id="search"
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />

        {!aircraft && (
          <Button
            data-test-id="btn-create"
            raised
            onClick={() => history.push(`${location.pathname}/new`, { referrer: location.pathname })}
          >
            {expendableOrRotable ? "Add Serial Number" : tCommon("AddPart")}
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={COLS} />

          <DataTableBody data-test-id="parts-list">
            {parts.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={COLS.length}>
                  {aircraft ? (
                    <EmptyState icon="construction" text={"No parts found."} />
                  ) : (
                    <EmptyState
                      icon="construction"
                      text={
                        expendableOrRotable ? tFeature("PartsList.noSerializedParts") : tFeature("PartsList.noParts")
                      }
                      buttonText={expendableOrRotable ? "Add Serial Number" : tCommon("AddPart")}
                      onClick={() => history.push(`${location.pathname}/new`, { referrer: location.pathname })}
                    />
                  )}
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => {
              let serialNumber = it.serialNumber || "-";
              if ((it.type === PartType.Rotable || it.type === PartType.Expendable) && it.parts?.items?.length === 1) {
                serialNumber = it.parts.items[0].serialNumber || "-";
              }
              return (
                <DataTableRow
                  key={i}
                  onClick={() => {
                    history.push(`${location.pathname}/${it.id}/parts`, { referrer: location.pathname });
                  }}
                >
                  <DataTableCell>{it.number || "-"}</DataTableCell>
                  {!expendableOrRotable && (
                    <DataTableCell>
                      <PartIcon type={it.type} />
                      {it.name}
                    </DataTableCell>
                  )}
                  <DataTableCell>{serialNumber}</DataTableCell>
                  {!expendableOrRotable && (
                    <DataTableCell>{calculateCost(it, !task && !template && !partId)}</DataTableCell>
                  )}
                  {!expendableOrRotable && <DataTableCell>{getQuantity(it)}</DataTableCell>}
                  <DataTableCell>
                    {it.expirationDate ? dayjs(it.expirationDate).tz("UTC").format("MM/DD/YYYY") : "-"}
                  </DataTableCell>
                  {!aircraft && (
                    <DataTableCell>
                      <DataCellTools>
                        <IconButton
                          data-test-id="btn-edit"
                          icon="edit"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            console.log(`${location.pathname}/${it.id}/details`);
                            history.push(`${location.pathname}/${it.id}/details`, { referrer: location.pathname });
                          }}
                        />
                        <IconButton
                          data-test-id="btn-delete"
                          icon="delete"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            onDelete(it);
                          }}
                        />
                      </DataCellTools>
                    </DataTableCell>
                  )}
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
