import gql from "graphql-tag";

export const MessageFields = gql`
  fragment MessageFields on Message {
    id

    tenantId
    projectId
    taskId
    userId

    message

    postedAt

    isClientVisible
    isTechnicianVisible
  }
`;

export const addMessageMutation = gql`
  ${MessageFields}

  mutation AddMessage($input: MessageCreateRequest!) {
    addMessage(input: $input) {
      ...MessageFields
    }
  }
`;

export const onAddedMessageSubscription = gql`
  ${MessageFields}

  subscription OnAddedMessage($tenantId: String!) {
    onAddedMessage(tenantId: $tenantId) {
      ...MessageFields
    }
  }
`;
