import React, { useEffect, useState } from "react";

import { useAdminApi } from "hooks/useAdminApi";
import { TemplateType } from "types/admin/globalTypes";
import styled from "styled-components";
import { TemplateFields } from "types/admin/TemplateFields";
import { AutocompleteInput, SelectOptionT } from "../AutocompleteInput";
import { TaskFields } from "types/admin/TaskFields";
import { FormField } from "../CommonForm";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

interface TaskFormStepOneProps {
  task?: TaskFields;
  setWhichTemplate: any;
  whichTemplate: any;
  shouldCopyAssignedUsers: boolean;
  setShouldCopyAssignedUsers: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  min-width: 300px;
`;

export const TaskFormStepOne: React.FC<TaskFormStepOneProps> = ({
  task,
  setWhichTemplate,
  whichTemplate,
  shouldCopyAssignedUsers,
  setShouldCopyAssignedUsers,
}) => {
  const { templates } = useAdminApi();
  const [chooseTemplateOptions, setChooseTemplateOptions] = useState<SelectOptionT[]>([]);
  const [theTemplate, setTheTemplate] = useState<TemplateFields>();
  const [copyAssignedUsers, setCopyAssignedUsers] = useState(shouldCopyAssignedUsers);

  useEffect(() => {
    setChooseTemplateOptions(
      templates.filter((it) => it.type === TemplateType.Task).map((it) => ({ value: it.id, label: it.name })),
    );
  }, [templates]);

  useEffect(() => {
    const [it] = templates.filter((t) => whichTemplate === t.id);
    setTheTemplate(it);
  }, [whichTemplate]);

  useEffect(() => {
    setShouldCopyAssignedUsers(copyAssignedUsers);
  }, [copyAssignedUsers]);

  return (
    <Wrapper>
      <AutocompleteInput
        testId="task-template-search"
        hideable={false}
        options={chooseTemplateOptions}
        select={(id) => setWhichTemplate(id)}
      />
      {theTemplate && (
        <FormField style={{ flexGrow: 1, marginLeft: -8, marginTop: 8 }}>
          <Checkbox checked={copyAssignedUsers} onChange={(evt) => setCopyAssignedUsers(!!evt.currentTarget.checked)}>
            Copy assigned users
          </Checkbox>
        </FormField>
      )}
    </Wrapper>
  );
};
