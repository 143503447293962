import React from "react";
import "@rmwc/data-table/styles";

import styled from "styled-components";
import Palette from "../../palette.json";

import { Avatar, AvatarGroup } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import "@rmwc/badge/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { getAvatarColor } from "../../common";
import { useHistory } from "react-router";
import { LocationFields, LocationFields_clients_items } from "types/admin/LocationFields";

const AssignmentCell = styled.td`
  > div {
    display: flex;
    align-items: center;

    .mdc-icon-button {
      width: 24px;
      height: 24px;
      padding: 3px;
      color: ${Palette.LightGrey};
      font-size: 16px;
      border: 1px solid ${Palette.LightGrey};
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
`;

interface LocationClientsAvatarsProps {
  location: LocationFields;
  clients: LocationFields_clients_items[];
  onClickAvatar?: (id: string) => Promise<void>;
}

export const LocationClientsAvatars: React.FC<LocationClientsAvatarsProps> = ({
  location: theLocation,
  clients,
  onClickAvatar,
}) => {
  const history = useHistory();

  const clickAvatar = async (id: string) => {
    await onClickAvatar?.(id);
  };

  return (
    <AssignmentCell className="rmwc-data-table__cell">
      <div>
        <AvatarGroup>
          {!!clients.length &&
            clients.map((client) => (
              <Avatar
                key={client.clientId}
                onClick={(ev) => {
                  ev.stopPropagation();
                  clickAvatar(client.clientId);
                }}
                style={{
                  color: "white",
                  backgroundColor: getAvatarColor(client.clientId),
                  opacity: 1,
                }}
                title={client.clientName || ""}
                name={client.clientName || ""}
              />
            ))}
        </AvatarGroup>

        <IconButton
          icon="add"
          onClick={(ev) => {
            ev.stopPropagation();
            history.push(`locations/${theLocation.id}/assign`);
          }}
        />
      </div>
    </AssignmentCell>
  );
};
