import React from "react";
import { FullScreenMessage } from "../FullScreenMessage";

export const InvalidLinkPage: React.FC<{ expired?: boolean }> = ({ expired }) => {
  return (
    <FullScreenMessage
      icon="warning"
      header={expired ? "Expired Link" : "Invalid Link"}
      message={`This link is ${expired ? "expired" : "invalid"}. ${
        expired
          ? "Signature links expire after 24 hours."
          : "This link may have already been used. Also, make sure the URL matches exactly with the one that was sent to your email."
      }`}
      subMessage="Only a system administrator can send you a new link from the FoxTrot Administrator app."
    />
  );
};
