import React from "react";
import "../../stylesheets/pdf-viewer.css";
import Palette from "../../palette.json";
import { PDFViewer } from "./PDFViewer";
import styled from "styled-components";
import useTemporaryUser from "hooks/useTemporaryUser";
import dayjs from "dayjs";
import { SigningEntityType, SigningType } from "types/admin/globalTypes";

const SigOneBox = styled.div<{ selectedSignature: number }>`
  position: absolute;
  bottom: 10%;
  left: 9%;
  height: 6%;
  width: 15%;

  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 2px ${(props) => (props.selectedSignature === 1 ? props.theme.primary : "none")};
  }

  border: 3px ${(props) => (props.selectedSignature === 1 ? "solid" : "dotted")}
    ${(props) => (props.selectedSignature === 1 ? props.theme.primary : Palette.LighterGrey)};
  cursor: ${(props) => (props.selectedSignature === 1 ? "pointer" : "default")};
`;

const SigTwoBox = styled.div<{ selectedSignature: number }>`
  position: absolute;
  bottom: 10%;
  right: 27%;
  height: 6%;
  width: 17%;

  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 0 10px 2px ${(props) => (props.selectedSignature === 2 ? props.theme.primary : "none")};
  }

  border: 3px ${(props) => (props.selectedSignature === 2 ? "solid" : "dotted")}
    ${(props) => (props.selectedSignature === 2 ? props.theme.primary : Palette.LighterGrey)};
  cursor: ${(props) => (props.selectedSignature === 2 ? "pointer" : "default")};
`;

const SignatureOne = styled.img`
  position: absolute;
  bottom: 9.5%;
  left: 9%;
  height: 6%;
`;

const SignatureTwo = styled.img`
  position: absolute;
  bottom: 9.5%;
  left: 56%;
  height: 6%;
`;

const AAndPNumberOne = styled.div`
  position: absolute;
  bottom: 5.2%;
  left: 9.6%;
  color: rgb(181, 181, 181);
  font-size: clamp(4.5px, 1.08vw, 9px);
  font-weight: 600;
`;

const AAndPNumberTwo = styled.div`
  position: absolute;
  bottom: 5.2%;
  left: 74%;
  color: rgb(181, 181, 181);
  font-size: clamp(4.5px, 1.08vw, 9px);
  font-weight: 600;
`;

const NameOne = styled.div`
  position: absolute;
  bottom: 9.5%;
  left: 26%;
  font-size: clamp(5px, 1.5vw, 12px);
`;

const NameTwo = styled.div`
  position: absolute;
  bottom: 9.5%;
  left: 74%;
  font-size: clamp(5px, 1.5vw, 12px);
`;

const DateOne = styled.div`
  position: absolute;
  bottom: 9.5%;
  left: 40%;
  font-size: clamp(5px, 1.5vw, 12px);
`;

const DateTwo = styled.div`
  position: absolute;
  bottom: 9.5%;
  left: 88%;
  font-size: clamp(5px, 1.5vw, 12px);
`;

interface LogBookStickerPDFViewerProps {
  pdfString: string;
  signatures?: any[];
  taskSigningType?: SigningType;
  onLoadSuccess: (numPages: number) => void;
  onSignatureClick: () => void;
  currentPage?: number;
  [key: string]: any;
}

export const LogBookStickerPDFViewer: React.FC<LogBookStickerPDFViewerProps> = ({
  pdfString,
  signatures,
  taskSigningType,
  onLoadSuccess,
  onSignatureClick,
  currentPage,
  ...props
}) => {
  const { user: tempUser } = useTemporaryUser();

  const paginatedSignatures = signatures.filter((it) => it.pageCount === currentPage);

  const selectedSignature = (() => {
    if (paginatedSignatures.length > 0) {
      if (paginatedSignatures.length === 1) {
        if (paginatedSignatures.find((it) => it.userId === tempUser.uid)) {
          return null;
        }
        return 2;
      } else {
        return null;
      }
    }

    return 1;
  })();

  const onClick = async (number: number) => {
    if (selectedSignature === number) {
      onSignatureClick();
    }
  };

  return (
    <div style={{ maxWidth: "900px", width: "100%", ...props.style }}>
      <PDFViewer onLoadSuccess={onLoadSuccess} currentPage={currentPage} pdfString={pdfString}>
        <SigOneBox onClick={() => onClick(1)} selectedSignature={selectedSignature} />
        {taskSigningType !== SigningType.Technician && (
          <SigTwoBox onClick={() => onClick(2)} selectedSignature={selectedSignature} />
        )}

        {paginatedSignatures.length > 0 && (
          <>
            <SignatureOne src={paginatedSignatures[0].signatureAttachment.file.url} />
            {paginatedSignatures[0].userAAndPNumber && (
              <AAndPNumberOne>A&P#: {paginatedSignatures[0].userAAndPNumber}</AAndPNumberOne>
            )}
            <NameOne>{paginatedSignatures[0].userName || ""}</NameOne>
            <DateOne>{dayjs(paginatedSignatures[0].signedDate).format("MM/DD/YYYY")}</DateOne>
          </>
        )}

        {paginatedSignatures.length > 1 && (
          <>
            <SignatureTwo src={paginatedSignatures[1].signatureAttachment.file.url} />
            {paginatedSignatures[1].userAAndPNumber && (
              <AAndPNumberTwo>A&P#: {paginatedSignatures[1].userAAndPNumber}</AAndPNumberTwo>
            )}
            <NameTwo>{paginatedSignatures[1].userName || ""}</NameTwo>
            <DateTwo>{dayjs(paginatedSignatures[1].signedDate).format("MM/DD/YYYY")}</DateTwo>
          </>
        )}
      </PDFViewer>
    </div>
  );
};
