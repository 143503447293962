import React, { useEffect } from "react";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import "@rmwc/theme/styles";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import { Table, TableContainer, TableHead, TableTitle } from "components/Table";
import { useAdminApi } from "hooks/useAdminApi";
import useLayout from "hooks/useLayout";
import { TextInput } from "components/TextInput";
import Palette from "../../palette.json";
import { SearchInput } from "components/TextInput";
import useFiltered from "hooks/useFiltered";
import { useHistory } from "react-router-dom";
import { generateTrendXLSX } from "../../common";
import { Button } from "components/Button";
import { TimesheetFields } from "types/admin/TimesheetFields";

type TrendReportItem = {
  customerId: string;
  locationId: string;
  locationName: string;
  customerName: string;
  hrs: number;
  taskIds: string[];
  dates: {
    date: string;
    uniqueTaskIds: string[];
  }[];
};

export const TrendList = () => {
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const search = new URLSearchParams(location.search);
  const defaultFromDate = search.get("fromDate") ? dayjs(search.get("fromDate")) : dayjs().startOf("week");
  const defaultToDate = search.get("toDate") ? dayjs(search.get("toDate")) : dayjs().endOf("week");
  const [fromDate, setFromDate] = React.useState(defaultFromDate);
  const [toDate, setToDate] = React.useState(defaultToDate);
  const { projects, clients, tasks, getTimesheets } = useAdminApi();
  const [timesheets, setTimesheets] = React.useState<TimesheetFields[]>([]);

  const filteredTimesheets = timesheets.filter((it) => it.taskId && !["InHangar", "Driving"].includes(it.taskId));

  const items: TrendReportItem[] = [];

  filteredTimesheets.forEach((it) => {
    const hrs = it.amountMins / 60;
    const project = projects.find((project) => project.id === it.projectId);
    const locationId = project?.location?.id;
    const locationName = project?.location?.name;
    if (!locationId) return;
    const customerId = projects.find((project) => project.id === it.projectId)?.clientId;
    const taskId = it.taskId;
    const index = items.findIndex((it) => it.locationId === locationId);
    const date = dayjs(it.toDT).format("MM/DD/YYYY");
    if (index === -1) {
      items.push({
        locationId,
        customerId,
        customerName: clients.find((client) => client.id === customerId)?.name,
        locationName,
        hrs,
        taskIds: [taskId],
        dates: [{ date, uniqueTaskIds: [taskId] }],
      });
    } else {
      items[index].hrs += hrs;
      items[index].taskIds = [...items[index].taskIds, taskId];
      const dateIndex = items[index].dates.findIndex((it) => it.date === date);
      if (dateIndex === -1) {
        items[index].dates.push({ date, uniqueTaskIds: [taskId] });
      } else {
        const ids = items[index].dates[dateIndex].uniqueTaskIds;
        items[index].dates[dateIndex].uniqueTaskIds = ids.includes(taskId) ? ids : [...ids, taskId];
      }
    }
  });

  const formattedItems = items.map((it) => {
    const uniqueTaskIds = [...new Set(it.taskIds)];
    const sumDailyTargetHrs = uniqueTaskIds.reduce((acc, taskId) => {
      const task = tasks.find((task) => task.id === taskId);
      if (!task) return acc;
      const taskDays = dayjs(task.dueDate).diff(task.scheduledDate, "day") + 1 || 1;
      const targetHrs = task?.estimateManHrs || 0;
      return acc + targetHrs / taskDays;
    }, 0);
    const avgTargetHrs = sumDailyTargetHrs / uniqueTaskIds.length;
    const sumTasksPerDay = it.dates.reduce((acc, it) => acc + it.uniqueTaskIds.length, 0);
    let tasksPerDay = 0;
    const workDays = it.dates.length;
    if (sumTasksPerDay > 0 && workDays > 0) {
      tasksPerDay = sumTasksPerDay / workDays;
    }
    const avgHrsPerTaskPerDay = it.hrs / uniqueTaskIds.length / workDays;
    const variance = ((avgHrsPerTaskPerDay - avgTargetHrs) / avgTargetHrs) * 100;
    const varianceRounded = Math.round(variance * 100) / 100;
    return {
      ...it,
      variance: varianceRounded,
      tasksPerDay: Math.round(tasksPerDay * 100) / 100,
      hrsPerTask: workDays > 0 ? Math.round(avgHrsPerTaskPerDay * 100) / 100 : 0,
      avgTargetHrs: Math.round((sumDailyTargetHrs / uniqueTaskIds.length) * 100) / 100,
      isRed: avgTargetHrs > avgHrsPerTaskPerDay,
    };
  });

  const filteredItems = useFiltered(
    formattedItems,
    (it) => !!it.locationName?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleDownload = async () => {
    await generateTrendXLSX(fromDate.format("MM/DD/YYYY"), toDate.format("MM/DD/YYYY"), formattedItems);
  };

  const startString = fromDate.format();
  const endString = toDate.format();

  React.useEffect(() => {
    if (!startString || !endString) return;
    getTimesheets(dayjs(startString), dayjs(endString)).then((results) => {
      setTimesheets(results);
    });
  }, [startString, endString]);

  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Trend Report",
        path: "/trends",
      },
      {
        label: `${fromDate.format("MMM D")} - ${toDate.format("MMM D")}`,
        path: "/trends",
      },
    ]);
  }, [toDate, fromDate]);

  return (
    <TableContainer>
      <TableTitle>
        <TextInput
          type="date"
          value={fromDate.format("YYYY-MM-DD")}
          onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD").startOf("day"))}
          data-test-id="from-date-input"
        />
        &nbsp;⟶&nbsp;
        <TextInput
          type="date"
          value={toDate.format("YYYY-MM-DD")}
          onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD").endOf("day"))}
          data-test-id="to-date-input"
        />
        <SearchInput
          placeholder="Search by Location"
          data-test-id="search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          style={{ marginLeft: "15px" }}
        />
        <Button raised icon="cloud_download" style={{ marginLeft: "auto" }} onClick={handleDownload}>
          Download Report
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead
            cols={[
              "Variance",
              "Location",
              "Customer",
              "Avg. Tasks Per Day",
              "Avg. Hrs Per Task Per Day",
              "Avg. Target Hrs",
            ]}
          ></TableHead>
          <DataTableBody data-test-id="trend-list">
            {filteredItems.map((it, i) => {
              return (
                <DataTableRow
                  key={i}
                  onClick={() =>
                    it.locationId
                      ? history.push(
                          `/trends/${it.locationId}?fromDate=${fromDate.format("YYYY-MM-DD")}&toDate=${toDate.format(
                            "YYYY-MM-DD",
                          )}`,
                        )
                      : null
                  }
                >
                  <DataTableCell style={{ fontWeight: "bold", color: it.isRed ? Palette.Green : Palette.Red }}>
                    {it.variance}%
                  </DataTableCell>
                  <DataTableCell>{it.locationName || "None"}</DataTableCell>
                  <DataTableCell>{it.customerName}</DataTableCell>
                  <DataTableCell>{it.tasksPerDay}</DataTableCell>
                  <DataTableCell>{it.hrsPerTask}</DataTableCell>
                  <DataTableCell>{it.avgTargetHrs}</DataTableCell>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
