import React, { useEffect, useMemo } from "react";
import { Dialog } from "../Dialog";
import { ProjectFields } from "types/admin/ProjectFields";
import { TaskFields } from "types/admin/TaskFields";
import "@rmwc/theme/styles";
import { DialogActions, DialogButton, DialogContent } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import Palette from "../../palette.json";
import { useAdminApi } from "hooks/useAdminApi";
import styled from "styled-components";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import useMultiTenant from "hooks/useMultiTenant";
import tinyColor from "tinycolor2";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { getAvatarColor } from "../../common";
import { ProjectStatusLabel, TaskStatusLabel } from "components/StatusLabel";
import useLiveTimeSheets from "hooks/useLiveTimeSheets";
import { PROJECT_STATUS_BACKGROUNDS } from "components/StatusLabel";
import { MiniProgress } from "components/MiniProgress";
import { estimateTaskProgress } from "utilities/taskEstimator";
import { Tooltip } from "@rmwc/tooltip";

const Header = styled.div`
  > div {
    padding: 12px;
  }
`;

const Headings = styled.div`
  margin: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Status = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: ${Palette.DarkGrey};
`;

const Dates = styled.h4`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 16px;
  color: ${Palette.LightGrey};
`;

const PredictedEndDate = styled.div<{ color: string }>`
  font-weight: 500;
  margin-right: 8px;
  padding: 0 6px;
  font-size: 14px;
  color: ${(props) => props.color};
  border-radius: 5px;
  background-color: ${(props) => tinyColor(props.color).lighten(44).toString()};
  font-size: 14px;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${Palette.DarkGrey};
  font-size: 15px;

  .rmwc-icon {
    color: ${Palette.LightGrey};
    margin-right: 15px;
    font-size: 18px;
  }
`;

const PersonItem = styled.li`
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: flex;
  gap: 12px;
  line-height: 1.2;

  .rmwc-avatar {
    margin-top: 2px;
  }
`;

const Role = styled.strong`
  font-size: 12px;
  color: ${Palette.MediumGrey};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${Palette.LightGrey};
`;

type Props = {
  project: ProjectFields;
  task?: TaskFields;
  onClose: () => void;
};

export const CalendarDetails = ({ project, task, onClose }: Props) => {
  const { taskId } = useParams<{ taskId: string }>();
  const { users, getProjectAndListTasks } = useAdminApi();
  const history = useHistory();
  const { theme } = useMultiTenant();
  const { progress } = useLiveTimeSheets(project.id, task?.id);
  const [predictedEndDate, setPredictedEndDate] = React.useState<dayjs.Dayjs | undefined>(undefined);

  const backStrokeColor = tinyColor(theme.primary);
  backStrokeColor.lighten(50).desaturate(30).toString();
  const start = dayjs(task?.scheduledDate || project.startDate).tz("UTC");
  const end = dayjs(task?.dueDate || project.endDate).tz("UTC");
  const sameMonth = start.month() === end.month();
  const duration = end.diff(start, "day") + 1;
  const description = task ? task.description : project.description;
  const assignedUsers = task ? task.users.items : project.users.items || [];
  const url = task ? `/work-orders/${project.id}/tasks/${task.id}` : `/work-orders/${project.id}`;

  useMemo(async () => {
    if (!project) return;
    try {
      const data = await getProjectAndListTasks({ projectId: project.id, criteria: { parentId: project.id } });
      setPredictedEndDate(estimateTaskProgress(data.project, data.tasks));
    } catch (e) {
      console.log("unable to fetch project and tasks", e);
    }
  }, []);

  const predictedEndDateColor = predictedEndDate?.isAfter(dayjs(project.endDate)) ? Palette.Red : Palette.Green;

  return (
    <Dialog open onClose={onClose}>
      <Header>
        <Headings>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Status>
                {task ? (
                  <TaskStatusLabel status={task.status}>{task.status}</TaskStatusLabel>
                ) : (
                  <ProjectStatusLabel status={project.status}>{project.status}</ProjectStatusLabel>
                )}
                <MiniProgress progress={progress} color={PROJECT_STATUS_BACKGROUNDS[project.status]} />
                {parseInt((progress * 100).toString())}%
              </Status>

              <Title>{task?.name || project.name}</Title>

              <Dates>
                <div>
                  {sameMonth
                    ? `${start.format("MMMM D")} - ${end.format("D")}`
                    : `${start.format("MMMM D")} - ${end.format("MMMM D")}`}
                </div>

                {predictedEndDate && (
                  <Tooltip content="Predicted End Date">
                    <PredictedEndDate color={predictedEndDateColor}>
                      {predictedEndDate.format("MMMM D, YYYY")}
                    </PredictedEndDate>
                  </Tooltip>
                )}
              </Dates>
            </div>
          </div>
        </Headings>

        <CloseButton type="button">
          <Icon icon="close" onClick={onClose} />
        </CloseButton>
      </Header>

      <DialogContent style={{ minWidth: "30vw", padding: "10px 30px" }}>
        {description && (
          <DetailRow>
            <Icon icon="menu" />
            {description}
          </DetailRow>
        )}
        <DetailRow>
          <Icon icon="watch_later" />
          {duration} {duration === 1 ? "day" : "days"}
        </DetailRow>

        {project.location?.name && (
          <DetailRow>
            <Icon icon="place" />
            {project.location.name}
          </DetailRow>
        )}

        <DetailRow>
          <Icon icon="groups" style={{ fontSize: 22, marginRight: 12 }} />
          {assignedUsers.length} {assignedUsers.length === 1 ? "Person" : "People"} Assigned
        </DetailRow>

        <ul>
          {assignedUsers.map((user) => (
            <PersonItem key={user.userId}>
              <Avatar
                style={{
                  color: "white",
                  backgroundColor: getAvatarColor(user.userId),
                }}
                name={users.find((it) => it.id === user.userId)?.name || ""}
              />

              <div>
                <Strong>{users.find((it) => it.id === user.userId)?.name}</Strong>

                <br />

                <Role style={{ textTransform: "capitalize" }}>{user.role}</Role>
              </div>
            </PersonItem>
          ))}
        </ul>
      </DialogContent>

      <DialogActions>
        {!taskId && (
          <DialogButton
            icon={"today"}
            onClick={() => history.push(`/work-orders/${project.id}/tasks/${task.id}/schedule`)}
          >
            View Schedule
          </DialogButton>
        )}

        <DialogButton onClick={() => history.push(url)} raised>
          View {task ? "Task" : "Work Order"}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
