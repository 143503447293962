import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router";
import { Switch } from "react-router-dom";

import useLayout from "hooks/useLayout";

import { useClient } from "hooks/params/useClient";

import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";

import { TabContainer } from "components/TabContainer";

import { Tab, TabBar } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import * as Pages from "../../../pages";
import { useAircraft } from "hooks/params/useAircraft";
import { AircraftDetailsView } from "components/aircraft/AircraftDetailsView";
import { PartsList } from "components/parts/PartsList";
import { useAdminApi } from "hooks/useAdminApi";
import { WorkOrdersSummary } from "components/WorkOrdersSummary";
import { ProjectList } from "components/projects/ProjectList";
import { ProjectFields } from "types/admin/ProjectFields";
import dayjs from "dayjs";
import useTranslations from "hooks/useTranslations";

interface ParamsT {
  clientId: string;
  aircraftId: string;
}

export const AircraftViewPage: React.FC = () => {
  const { tCommon } = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const { clientId, aircraftId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { getEntity } = useAdminApi();
  const { entity: aircraft } = useAircraft();
  const client = useClient();

  const PAGE_TABS = [
    {
      icon: "info",
      label: "Details",
      path: `/clients/${clientId}/entities/${aircraftId}/details`,
    },
    {
      icon: "assignment",
      label: "Work Orders",
      path: `/clients/${clientId}/entities/${aircraftId}/work-orders`,
    },
    {
      icon: "history",
      label: `${tCommon("Parts")} History`,
      path: `/clients/${clientId}/entities/${aircraftId}/parts`,
    },
  ];

  useEffect(() => {
    getEntity({ id: aircraftId });
  }, []);

  const [activeTabIndex, setTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path) || "details"),
  );

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs || !client) return;

    setBreadCrumbs([
      {
        label: "Customers",
        path: "/clients",
      },
      {
        label: client.name,
        path: `/clients/${clientId}`,
      },
      {
        label: tCommon("Aircrafts"),
        path: `/clients/${clientId}/entities`,
      },

      {
        label: aircraft?.model || aircraft?.type || aircraft?.tailNumber || "",
        path: `/clients/${clientId}/entities/${aircraftId}`,
      },
    ]);
  }, [setBreadCrumbs, client]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/new")) return;
    history.replace(path);
  };

  if (!client || !aircraft) return <Route component={Pages.Error404} />;

  const title = aircraft.model
    ? `${aircraft.make ? `${aircraft.make} ${aircraft.model}` : `${aircraft.model}`}`
    : aircraft.type
    ? aircraft.type
    : `${client.name}'s ${tCommon("Aircraft")}`;

  return (
    <>
      <PageHeader
        compact
        editable
        title={title}
        subtitle={tCommon("Aircraft")}
        description={aircraft.tailNumber}
        onEditClick={() => history.push(`/clients/${client.id}/entities/${aircraft.id}/details`)}
      >
        <WorkOrdersSummary />
      </PageHeader>

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <Switch>
          <PageContent>
            <Route exact path="/clients/:clientId/entities/:aircraftId/work-orders">
              <ProjectList items={(aircraft.completedProjects?.items || []) as ProjectFields[]} />
            </Route>
            <Route exact path="/clients/:clientId/entities/:aircraftId/details">
              <AircraftDetailsView />
            </Route>
            <Route exact path="/clients/:clientId/entities/:aircraftId/parts">
              <PartsList />
            </Route>
          </PageContent>
        </Switch>
      </PageMain>
    </>
  );
};
