import gql from "graphql-tag";

export const CameraFields = gql`
  fragment CameraFields on Camera {
    id
    name

    status
    type

    live_snapshot

    streams {
      format
      url
    }
    snapshot {
      created_at
      url
    }
  }
`;

export const getCamerasQuery = gql`
  ${CameraFields}

  query GetCameras($projectId: ID) {
    asAdmin {
      getCameras(projectId: $projectId) {
        ...CameraFields
      }
    }
  }
`;
