import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { PROJECT_ROLE_TO_LABEL } from "../../common";

import { ProjectRole, TaskStatus, UserRole } from "types/admin/globalTypes";
import { UserFields } from "types/admin/UserFields";

import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";
import { useProject } from "hooks/params/useProject";

import useSorted, { sortByProperty } from "hooks/useSorted";
import useFiltered from "hooks/useFiltered";

import { dialogQueue } from "hooks/useDialogQueue";
import { snackbar } from "hooks/useNotifications";

import { Button } from "components/Button";
import { SearchInput } from "../TextInput";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "components/Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import Palette from "../../palette.json";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import EmptyState from "../EmptyState";

interface UserListProps {
  items: UserFields[];

  hiddenFields?: string[];
}

const COLUMN_LABELS = ["Name", "Role", "Email", "Phone Number", ""];

export const UserList: React.FC<UserListProps> = ({ items, hiddenFields = [] }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const { me, deleteUser, removeProjectUser, removeTaskUser } = useAdminApi();
  const client = useClient();
  const project = useProject();
  const { task: parentTask } = useTask();
  const { template } = useTemplate();
  const isTaskDeleted = parentTask?.status === TaskStatus.Deleted;
  const isTemplateWorkOrder = template?.type === "Project";

  const [search, setSearch] = useState<string>("");

  const sorted = useSorted(items, sortByProperty("name"));

  const filterByRoleItems = ["All", "Technician", "Inspector", "Customer", "Administrator", "Site Manager"];
  const [filterByRole, setFilterByRole] = useState<number>(0);

  const filtered = useFiltered(sorted, (it) => {
    let result = false;
    if (filterByRole === 0) result = true;
    else if (filterByRole === 1) result = it.projectRole === ProjectRole.technician;
    else if (filterByRole === 2) result = it.projectRole === ProjectRole.technicianInspector;
    else if (filterByRole === 3) result = it.projectRole === ProjectRole.customer;
    else if (filterByRole === 4) result = it.userRole === UserRole.Admin;
    else if (filterByRole === 5) result = it.userRole === UserRole.AdminManager;

    return result && !!it?.name?.toLowerCase().includes(search.toLowerCase());
  });

  const onDelete = async (user: UserFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body:
        project || isTemplateWorkOrder
          ? parentTask
            ? `Are you sure you want to unassign task user ${user.name}`
            : `Are you sure you want to remove work order user ${user.name}`
          : `Are you sure you want to delete user: ${user.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    if (parentTask) {
      await removeTaskUser({ id: parentTask.id, userId: user.id });
      snackbar.notify({ title: "User removed from task successfully!" });
      return;
    }

    if (project || isTemplateWorkOrder) {
      await removeProjectUser(project?.id || template.id, user.id);
    } else {
      try {
        await deleteUser({ id: user.id });
      } catch (err) {
        const error = err as Error;
        snackbar.notify({ title: error.message, icon: "error" });
        return;
      }
    }

    snackbar.notify({ title: "User removed successfully!" });
  };

  const onAdd = () => {
    if (project || template) {
      history.push(`${url}/add`);
      return;
    }
    history.push(`${url}/new${client ? "?clientId=" + client.id : ""}`);
  };

  function canEditPerson(item: UserFields) {
    if (item.userRole === UserRole.Admin && me.userRole !== UserRole.Admin) return false;
    if (item.userRole === UserRole.AdminManager && me.userRole !== UserRole.Admin) return false;
    return true;
  }

  function navigateToPerson(item: UserFields) {
    if (!canEditPerson(item)) return;
    history.push(`/people/${item.id}`);
  }

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          People <TitleCount>({filtered.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          data-test-id="user-list-search"
          placeholder="Search by Name"
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button data-test-id="user-list-filter-button" outlined trailingIcon="unfold_more">
              {filterByRoleItems[filterByRole]}
            </Button>
          }
          onSelect={(ev) => setFilterByRole(ev.detail.index)}
        >
          {filterByRoleItems.map((it, i) => (
            <MenuItem data-test-id={`user-filter-${it}`} key={i}>
              {it}
            </MenuItem>
          ))}
        </SimpleMenu>

        {!isTaskDeleted && (
          <Button raised onClick={onAdd} data-test-id="btn-add">
            {project ? "Manage" : "Add Person"}
          </Button>
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={COLUMN_LABELS.filter((it) => !hiddenFields.includes(it))} />

          <DataTableBody data-test-id="user-list">
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={COLUMN_LABELS.length - hiddenFields.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  <EmptyState
                    icon="people"
                    text={"No users found. Add first user."}
                    buttonText="New User"
                    onClick={onAdd}
                  />
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.map((it, i) => (
              <DataTableRow key={i}>
                {!hiddenFields.includes("Name") && (
                  <DataTableCell
                    onClick={() => {
                      navigateToPerson(it);
                    }}
                  >
                    {it.name}
                  </DataTableCell>
                )}
                {!hiddenFields.includes("Role") && (
                  <DataTableCell
                    onClick={() => {
                      navigateToPerson(it);
                    }}
                  >
                    {!project &&
                      (it.userRole === UserRole.Admin
                        ? "Administrator"
                        : it.userRole === UserRole.AdminManager
                        ? "Site Manager"
                        : it.projectRole
                        ? PROJECT_ROLE_TO_LABEL[it.projectRole]
                        : "Technician")}
                    {project &&
                      PROJECT_ROLE_TO_LABEL[
                        project.users?.items.find((jt) => jt.userId === it.id)?.role || ProjectRole.technician
                      ]}
                  </DataTableCell>
                )}
                {!hiddenFields.includes("Email") && (
                  <DataTableCell
                    onClick={() => {
                      navigateToPerson(it);
                    }}
                  >
                    {it.email}
                  </DataTableCell>
                )}
                {!hiddenFields.includes("Phone Number") && (
                  <DataTableCell
                    onClick={() => {
                      navigateToPerson(it);
                    }}
                  >
                    {it.phoneNumber?.replace("+1", "")}
                  </DataTableCell>
                )}
                <DataTableCell>
                  <DataCellTools>
                    {!project && !isTemplateWorkOrder && canEditPerson(it) && (
                      <IconButton
                        data-test-id="btn-edit"
                        icon="edit"
                        onClick={() => history.push(`/people/${it.id}/edit`)}
                      />
                    )}
                    {project &&
                      !parentTask &&
                      project.users?.items.find((jt) => jt.userId === it.id)?.role !== ProjectRole.customer && (
                        <IconButton
                          icon="group_add"
                          onClick={() => history.push(`/work-orders/${project.id}/people/${it.id}/multiassign`)}
                        />
                      )}
                    {isTemplateWorkOrder &&
                      !parentTask &&
                      template.users?.items.find((jt) => jt.userId === it.id)?.role !== ProjectRole.customer && (
                        <IconButton
                          icon="group_add"
                          onClick={() => history.push(`/templates/${template.id}/people/${it.id}/multiassign`)}
                        />
                      )}
                    <IconButton
                      data-test-id="btn-delete"
                      icon={project || isTemplateWorkOrder ? "person_remove" : "delete"}
                      onClick={() => onDelete(it)}
                    />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
