import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFirebase } from "hooks/useFirebase";
import { Button } from "components/Button";
import { Label } from "components/Label";
import styled from "styled-components";
import { CommonForm } from "components/CommonForm";
import LoadingIndicator from "components/LoadingIndicator";
import { showErrorMessage } from "../utilities/handleError";
import { Icon } from "@rmwc/icon";
import Palette from "../palette.json";

export const Root = styled.div`
  height: 100%;
  padding: 52px 30px;

  display: flex;
  flex-direction: column;
`;
export const SignInTitle = styled.h2`
  margin: 0 0 25px;

  font-size: 22px;
  font-weight: bold;

  color: #3e3f42;
`;
export const TextField = styled.input`
  padding: 8px 16px;
  border: 1px solid #e3e5ed;
  border-radius: 4px;
  background-color: white;
  outline: none;
`;
export const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
`;
const SignInMessage = styled.span`
  display: block;

  font-size: 14px;
  text-align: center;
  color: #9ea0a5;

  a {
    color: ${(props) => props.theme.primary};
    text-decoration: none;
  }
`;
const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 20px;
  > * + * {
    margin-left: 8px;
  }
`;

export const SignInPage: React.FC = () => {
  const { signInWithEmailAndPassword } = useFirebase();

  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await signInWithEmailAndPassword(email, password);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Root>
      <CommonForm {...{ onSubmit }}>
        <SignInTitle>Sign In</SignInTitle>

        <Label htmlFor="username">Email Address</Label>
        <TextField
          autoFocus
          type="text"
          name="username"
          value={email}
          style={{ margin: "8px 0 20px" }}
          onChange={(ev) => setEmail(ev.target.value)}
        />

        <Label htmlFor="password">Password</Label>
        <PasswordWrapper>
          <TextField
            style={{ width: "100%" }}
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          <Icon
            icon={{ icon: showPassword ? "visibility_off" : "visibility", size: "small" }}
            style={{ color: Palette.LightGrey, cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          />
        </PasswordWrapper>

        <div style={{ marginBottom: "12px" }}></div>

        <Button
          icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
          data-test-id="sign-in-button"
          raised
          label="Sign In"
          type="submit"
        />
      </CommonForm>

      <Divider />

      <SignInMessage>
        Forgot your password? <Link to={`/reset?redirect=${redirect}`}>Get a new password</Link>
      </SignInMessage>
    </Root>
  );
};
