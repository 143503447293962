import React from "react";

import { formatPrice, getCost } from "../../common";

import { ToolFields } from "types/admin/ToolFields";

import { Button } from "components/Button";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import Palette from "../../palette.json";
import { useProject } from "hooks/params/useProject";
import { useTask } from "hooks/params/useTask";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTemplate } from "hooks/params/useTemplate";
import useTranslations from "hooks/useTranslations";

const totalStyle = {
  fontWeight: 500,
  fontSize: "16px",
};

interface ToolListProps {
  items: (ToolFields | null)[];
  total?: number;
}

export const ToolList: React.FC<ToolListProps> = ({ items, total }) => {
  const { tCommon, tFeature } = useTranslations();
  const project = useProject();
  const { task } = useTask();
  const history = useHistory();
  const { template } = useTemplate();
  const match = useRouteMatch();

  if (!(project || task) && !template) return <></>;

  const cols: string[] = [
    tCommon("ToolNumber"),
    "Description",
    "Quantity",
    "Unit Price",
    "Lead Time",
    "Billable",
    "Cost",
  ];

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {tCommon("Tools")} <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <Button
          raised
          onClick={() =>
            history.push(
              template
                ? `/templates/${template.id}/tasks/${task?.id}/edit?tab=Tools`
                : `/work-orders/${project?.id}/tasks/${task?.id}/edit?tab=Tools`,
            )
          }
        >
          Manage
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody data-test-id="tool-list">
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={cols.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {items.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>{it?.number}</DataTableCell>
                <DataTableCell>{it?.name}</DataTableCell>
                <DataTableCell>{it?.quantity}</DataTableCell>
                <DataTableCell>{formatPrice(it?.cost as number)}</DataTableCell>
                <DataTableCell>{it?.leadTimeDays}</DataTableCell>
                <DataTableCell>
                  <Checkbox disabled checked={it?.isBillable || false} />
                </DataTableCell>
                <DataTableCell>{it?.isBillable ? formatPrice(getCost(it) as number) : "-"}</DataTableCell>
              </DataTableRow>
            ))}

            <DataTableRow>
              <DataTableCell style={totalStyle}>{tFeature("ToolList.totalLabel")}</DataTableCell>
              {[...Array(cols.length - 2).keys()].map((col, i) => (
                <DataTableCell key={i} />
              ))}
              <DataTableCell style={totalStyle}>{formatPrice(total || 0)}</DataTableCell>
            </DataTableRow>
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
