import React, { createContext, useContext, useEffect, useState } from "react";

import { createSnackbarQueue } from "@rmwc/snackbar";

import Palette from "../palette.json";
import useMultiTenant from "./useMultiTenant";

interface Notification {
  id: string;
  icon: string;
  color: string;
  label: string;
  path?: string;
  action?(): void;
}

interface NotificationsContextT {
  notifications: Notification[];
}

export const snackbar = createSnackbarQueue();

const NotificationsContext = createContext<NotificationsContextT>({ notifications: [] });

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { theme } = useMultiTenant();

  //const icons = ["local_post_office", "account_tree", "event_note", "system_update"];

  useEffect(() => {
    const notifications: Notification[] = [
      { id: "1", icon: "sms", color: theme.primary, label: "You have 5 unread messages in Boeing 230 Refurbishment." },
      {
        id: "2",
        icon: "attachment",
        color: Palette.Violet,
        label: "A new attachment was added to Boeing 230 Refurbishment.",
      },
      { id: "3", icon: "edit", color: Palette.Green, label: "5 New Tasks were added to Boeing 230 Refurbishment." },
      { id: "4", icon: "assignment_turned_in", color: Palette.Orange, label: "You have 1 new Project" },
      { id: "5", icon: "book", color: Palette.LightGrey, label: "You have 2 new Estimates" },
    ];

    setNotifications(notifications);
  }, []);

  return <NotificationsContext.Provider value={{ notifications }}>{children}</NotificationsContext.Provider>;
};

export default () => useContext(NotificationsContext);
