import gql from "graphql-tag";

export const ParentShiftFields = gql`
  fragment ParentShiftFields on Shift {
    id
    name
    startTime
    endTime
    ownerId
    parentShiftId
  }
`;

export const ShiftFields = gql`
  ${ParentShiftFields}

  fragment ShiftFields on Shift {
    id
    name
    startTime
    endTime
    ownerId
    parentShiftId
    parentShift {
      ...ParentShiftFields
    }
  }
`;

export const addShiftsMutation = gql`
  ${ShiftFields}

  mutation AddShifts($input: [CreateShiftRequest!]!) {
    asAdmin {
      addShifts(input: $input) {
        items {
          ...ShiftFields
        }
      }
    }
  }
`;

export const updatesShiftsMutation = gql`
  ${ShiftFields}

  mutation UpdateShifts($input: [UpdateShiftRequest!]!) {
    asAdmin {
      updateShifts(input: $input) {
        items {
          ...ShiftFields
        }
      }
    }
  }
`;

export const deleteShiftsMutation = gql`
  ${ShiftFields}

  mutation DeleteShifts($ids: [ID!]!) {
    asAdmin {
      deleteShifts(ids: $ids) {
        items {
          ...ShiftFields
        }
      }
    }
  }
`;
