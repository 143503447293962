import styled from "styled-components";

export const MainArea = styled.div`
  height: 100%;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

export const LocationTools = styled.div`
  padding: 8px 16px 9px;

  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  > * {
    margin-left: 1rem;
  }
`;

export const LocationInfo = styled.div`
  position: relative;

  padding: 16px 52px;
  border-top: 1px solid #dfdfdf;

  > .rmwc-icon {
    position: absolute;
    top: 32px;
    right: 16px;
  }
`;
export const LocationName = styled.h4`
  margin: 16px 0;
  font-size: 26px;

  > button {
    font-size: 20px;
    color: ${(props) => props.theme.primary};
  }
`;

export const LocationAddressItem = styled.p`
  margin: 0 0 8px;
  font-size: 14px;
`;
