import React from "react";

import { Button as MaterialButton, ButtonHTMLProps, ButtonProps } from "@rmwc/button";
import "@rmwc/button/styles";

import styled from "styled-components";

import Palette from "../palette.json";

export const Button = styled(MaterialButton)<React.PropsWithChildren<ButtonProps & ButtonHTMLProps>>`
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;

  &.mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: white;
  }

  &.mdc-button--outlined {
    color: ${Palette.DarkGrey};
  }
`;

export const PlainButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${Palette.DarkGrey} !important;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
`;
