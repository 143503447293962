import { TimesheetFields } from "types/admin/TimesheetFields";
import React from "react";
import Palette from "../../palette.json";
import { TimeSheetDiscrepancyStatus } from "types/admin/globalTypes";
import { Tooltip } from "@rmwc/tooltip";
import "@rmwc/tooltip/styles";
import { Icon } from "@rmwc/icon";

export interface Props {
  timesheet?: TimesheetFields;
}

export const TimesheetStatusIcon: React.FC<Props> = ({ timesheet }: Props) => {
  const hasPendingDiscrepancy = timesheet?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending;
  const hasAutoClockOut = timesheet?.autoClockOut;
  const isResolved = timesheet.history?.changes?.length >= 2;
  if (isResolved) {
    return (
      <Tooltip content="Action Taken">
        <Icon
          icon={{ icon: "done", size: "xsmall" }}
          style={{
            color: Palette.LightGrey,
            marginLeft: "10px",
          }}
        />
      </Tooltip>
    );
  }
  if (hasPendingDiscrepancy) {
    return (
      <Tooltip content="Discrepancy found">
        <Icon
          icon={{ icon: "warning", size: "xsmall" }}
          style={{
            color: "var(--mdc-theme-error)",
            marginLeft: "10px",
          }}
        />
      </Tooltip>
    );
  }
  if (hasAutoClockOut) {
    return (
      <Tooltip content="Clocked out automatically">
        <Icon
          icon={{ icon: "alarm_on", size: "xsmall" }}
          style={{
            color: Palette.Blue,
            marginLeft: timesheet?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending ? "6px" : "10px",
          }}
        />
      </Tooltip>
    );
  }
  return null;
};
