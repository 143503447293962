import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import "@rmwc/theme/styles";
import dayjs from "dayjs";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Route, useParams, useLocation } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { generateTimesheetsXLSX } from "../../common";
import { Button } from "components/Button";
import { DataCellTools, Table, TableContainer, TableHead, TableTitle } from "components/Table";
import { TimesheetReviewDialog } from "components/timesheets/TimesheetReviewDialog";
import { TimesheetActivityEditDialog } from "components/timesheets/TimesheetActivityEditDialog";
import { useAdminApi } from "hooks/useAdminApi";
import useLayout from "hooks/useLayout";
import { useTimeSheets } from "hooks/useTimeSheets";
import { TimeSheetDiscrepancyStatus, TimeSheetEntryStatus } from "types/admin/globalTypes";
import { TextInput } from "components/TextInput";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";
import { DisplayFlex } from "../Utilities";
import EmptyState from "../EmptyState";
import { TimesheetActivityAddDialog } from "./TimesheetActivityAddDialog";
import { ExportXLSXButton } from "../ExportXLSXButton";
import { TimesheetStatusIcon } from "./TimesheetStatusIcon";

export interface Props {
  filterType?: "project" | "task" | "InHangar";
  filterId?: string;
}

export const TimesheetTable: React.FC<Props> = ({ filterType, filterId }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const defaultFilter = parseInt(search.get("filter")) || 0;
  const defaultFromDate = search.get("fromDate") || null;
  const defaultToDate = search.get("toDate") || null;
  const { projectId, taskId } = useParams<{ projectId?: string; taskId?: string }>();
  const { tasks, users } = useAdminApi();
  const [filterByStatus, setFilterByStatus] = useState<number>(defaultFilter);
  const filterByStatusItems = ["All", "Action Required", "Action Taken"];

  const [exportXLSXLoading, setExportXLSXLoading] = useState(false);

  const { setBreadCrumbs } = useLayout();
  const {
    fetchTimeSheets,
    timesheets,
    mergedTimeSheets,
    isLoading,
    discrepancyFound,
    onTimeSheetApprove,
    onTimeSheetsApprove,
    onTimeSheetDelete,
    onTimeSheetSave,
    onTimeSheetAdd,
    onTimesheetDiscrepancyUpdate,
    toDate,
    fromDate,
    setToDate,
    setFromDate,
    setFilterType,
    setFilterId,
  } = useTimeSheets();

  useEffect(() => {
    fetchTimeSheets(fromDate, toDate);
  }, []);

  useEffect(() => {
    setFilterType(filterType);
    setFilterId(filterId);
  }, [filterType, filterId]);

  useEffect(() => {
    if (!defaultFromDate || !defaultToDate) return;
    setFromDate(dayjs(defaultFromDate));
    setToDate(dayjs(defaultToDate));
  }, [defaultFromDate, defaultToDate]);

  const doApprove = (userId: string) => {
    history.push(`${match.url}/${userId}/review`);
  };

  useEffect(() => {
    if (projectId || taskId) return;
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Timesheets",
        path: "/timesheets",
      },
      {
        label: `${fromDate.format("MMM D")} - ${toDate.format("MMM D")}`,
        path: "/timesheets",
      },
    ]);
  }, [toDate, fromDate]);

  let filteredTimesheets = mergedTimeSheets;
  if (filterByStatus === 1) {
    filteredTimesheets = timesheets.filter(
      (it) =>
        !!(it?.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending || it.autoClockOut) &&
        it.history?.changes?.filter((history) => history.userId !== it.userId)?.length === 0 &&
        it.status !== TimeSheetEntryStatus.Approved,
    );
  } else if (filterByStatus === 2) {
    filteredTimesheets = timesheets.filter((it) => {
      return it.history?.changes?.filter((history) => history.userId !== it.userId)?.length > 0;
    });
  }

  const columns = ["Employee #", "Team Member", filterType === "InHangar" ? "Hours" : "Hours / Estd.", "Org. Unit", ""];

  return (
    <>
      <Route exact path={`${match.path}/:userId/review`}>
        <TimesheetReviewDialog
          {...{ timesheets, isLoading }}
          onTimeSheetApprove={onTimeSheetApprove}
          onTimeSheetsApprove={onTimeSheetsApprove}
          onDelete={onTimeSheetDelete}
        />
      </Route>

      <Route exact path={`${match.path}/add`}>
        <TimesheetActivityAddDialog {...{ isLoading, projectId, taskId }} onSave={onTimeSheetAdd} />
      </Route>

      <Route exact path={`${match.path}/:userId/review/:activityId/edit`}>
        {timesheets.length && (
          <TimesheetActivityEditDialog
            {...{ timesheets, isLoading }}
            onSave={onTimeSheetSave}
            onDiscrepancyUpdate={onTimesheetDiscrepancyUpdate}
          />
        )}
      </Route>

      <TableContainer>
        <TableTitle>
          <TextInput
            type="date"
            value={fromDate.format("YYYY-MM-DD")}
            onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD").startOf("day"))}
            data-test-id="from-date-input"
          />
          &nbsp;⟶&nbsp;
          <TextInput
            type="date"
            value={toDate.format("YYYY-MM-DD")}
            onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD").endOf("day"))}
            data-test-id="to-date-input"
          />
          <SimpleMenu
            anchorCorner="bottomStart"
            focusOnOpen={false}
            handle={
              <Button outlined trailingIcon="unfold_more">
                {filterByStatusItems[filterByStatus]}
              </Button>
            }
            onSelect={(ev) => setFilterByStatus(ev.detail.index)}
          >
            {filterByStatusItems.map((it, i) => (
              <MenuItem key={i}>{it}</MenuItem>
            ))}
          </SimpleMenu>
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExportXLSXButton
              loading={exportXLSXLoading}
              disabled={discrepancyFound}
              onClick={async () => {
                setExportXLSXLoading(true);
                await generateTimesheetsXLSX(
                  timesheets,
                  users,
                  filterType && ["task", "project"].includes(filterType) ? "tasks" : "InHangar",
                );
                setExportXLSXLoading(false);
              }}
            />

            <Button
              raised
              style={{ marginLeft: 12 }}
              onClick={() => history.push(`${match.url}/add`)}
              data-test-id="btn-create"
            >
              New Timesheet
            </Button>
          </div>
        </TableTitle>

        <Table>
          <DataTableContent>
            <TableHead cols={columns}></TableHead>

            <DataTableBody data-test-id="timesheets-list">
              {!filteredTimesheets.length && (
                <DataTableRow>
                  <DataTableCell colSpan={5}>
                    {isLoading ? <CircularProgress /> : <EmptyState icon="today" text={"No timesheets found."} />}
                  </DataTableCell>
                </DataTableRow>
              )}

              {filteredTimesheets.map((it, i) => (
                <DataTableRow key={i}>
                  <DataTableCell>
                    {it.user?.payChex?.id ?? users.find((ut) => ut.id === it.userId)?.payChex?.id}
                  </DataTableCell>
                  <DataTableCell>{it.user?.name ?? users.find((ut) => ut.id === it.userId)?.name}</DataTableCell>
                  <DataTableCell>
                    <DisplayFlex alignItems="center">
                      <DisplayFlex>
                        {numeral(it.amountMins ? it.amountMins / 60 : 0).format("0.0")}
                        {filterType === "InHangar"
                          ? ""
                          : "/" + numeral(tasks.find((jt) => jt.id === it.taskId)?.estimateManHrs || 0).format("0.0")}
                      </DisplayFlex>
                      {filterByStatus > 0 && <TimesheetStatusIcon timesheet={it} />}
                    </DisplayFlex>
                  </DataTableCell>
                  <DataTableCell>
                    {it.user?.payChex?.orgUnit ?? users.find((ut) => ut.id === it.userId)?.payChex?.orgUnit}
                  </DataTableCell>
                  <DataTableCell>
                    <DataCellTools>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ExportXLSXButton
                          showLabel={false}
                          loading={exportXLSXLoading}
                          disabled={it.discrepancy?.status === TimeSheetDiscrepancyStatus.Pending}
                          onClick={async () => {
                            setExportXLSXLoading(true);
                            await generateTimesheetsXLSX(
                              timesheets.filter((ts) => ts.userId === it.userId),
                              users,
                              filterType && ["task", "project"].includes(filterType) ? "tasks" : "InHangar",
                            );
                            setExportXLSXLoading(false);
                          }}
                        />

                        <Button
                          style={{ width: "7rem", marginLeft: 16 }}
                          outlined={it.status !== TimeSheetEntryStatus.Approved}
                          raised={it.status === TimeSheetEntryStatus.Approved}
                          onClick={() => doApprove(it.userId || "")}
                          data-test-id="btn-review"
                        >
                          {it.status === TimeSheetEntryStatus.Approved ? "Approved" : "Review"}
                        </Button>
                      </div>
                    </DataCellTools>
                  </DataTableCell>
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </Table>
      </TableContainer>
    </>
  );
};
