import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ProjectStatus } from "types/admin/globalTypes";

import { useAdminApi } from "hooks/useAdminApi";
import { useClient } from "hooks/params/useClient";
import { useProject } from "hooks/params/useProject";

import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";

import { Button } from "../Button";
import { Label } from "../Label";
import { Dialog } from "../Dialog";
import { dialogQueue } from "hooks/useDialogQueue";
import { CommonForm, FormField, FormRow } from "../CommonForm";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { TextArea } from "../TextArea";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import Palette from "../../palette.json";
import { ProjectFormStepOne } from "./ProjectFormStepOne";
import { TemplateFields } from "types/admin/TemplateFields";
import { UpdateProjectVariables } from "types/admin/UpdateProject";
import { UsingTemplateMessage } from "../templates/UsingTemplateMessage";
import styled from "styled-components";
import { AircraftFormDialog } from "../aircraft/AircraftFormDialog";
import { ClientFormDialog } from "../clients/ClientFormDialog";
import { showErrorMessage } from "../../utilities/handleError";
import LoadingIndicator from "../LoadingIndicator";
import { ClientFields } from "types/admin/ClientFields";
import { useSettings } from "hooks/useSettings";
import { snackbar } from "hooks/useNotifications";
import useTranslations from "hooks/useTranslations";

const CreationActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  Button:first-of-type {
    margin-bottom: 15px;
  }
`;

enum FormSteps {
  ONE,
  TWO,
}

enum CreationType {
  NEW = "new",
  TEMPLATE = "template",
}

interface FormValuesT {
  id?: string;

  aircraftId?: string;
  clientId?: string;
  cameraId: string;
  locationId: string;

  name: string;
  description: string;

  hourlyRate: string;
  estimateManHrs: string;

  startDate: string;
  endDate: string;

  status: ProjectStatus;
}

interface ProjectFormDialogProps {
  action: "create" | "update";
}

export const ProjectFormDialog: React.FC<ProjectFormDialogProps> = ({ action }) => {
  const { tCommon } = useTranslations();
  const { templates, addProject, updateProject, updateProjectv2, completeProject, entities, clients, addProjectFromTemplate } =
    useAdminApi();
  const history = useHistory();
  const project = useProject();
  const location = useLocation();
  const { settings } = useSettings();
  const query = new URLSearchParams(location.search);
  const templateIdFromQuery = query.get("template");

  const [formStep, setFormStep] = useState<number>(action === "update" ? FormSteps.TWO : FormSteps.ONE);
  const [whichTemplate, setWhichTemplate] = useState<string | null>(templateIdFromQuery);
  const [theTemplate, setTheTemplate] = useState<TemplateFields | null>();
  const [creationType, setCreationType] = useState<CreationType | null>();
  const [showAircraftFormDialog, setShowAircraftFormDialog] = useState<boolean>(false);
  const [showClientFormDialog, setShowClientFormDialog] = useState<boolean>(false);
  const [shouldCopyAssignedUsers, setShouldCopyAssignedUsers] = useState(false);
  const [selectedClient, setSelectedClient] = useState({} as ClientFields);
  const [loading, setLoading] = useState(false);

  const title = action === "create" ? "Create Work Order" : "Update Work Order";

  const client = useClient();

  const clientOpts: { value: string; label: string }[] = [];
  const aircraftOpts: { value: string; label: string }[] = [];
  const statusOpts: { value: string; label: string }[] = [];

  const locationOpts =
    selectedClient.locations?.items.map((location) => ({
      value: location.locationId,
      label: location.location.name,
    })) || [];

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientId: project?.clientId || query.get("clientId") || client?.id,
      aircraftId: project?.aircraftId || query.get("aircraftId") || undefined,
      locationId: project?.location?.id || query.get("locationId") || undefined,
      cameraId: "",
      name: project?.name || "",
      description: project?.description || "",
      hourlyRate:
        `${project?.hourlyRate ?? (client?.settings?.defaultLaborHourlyRate || settings.defaultLaborHourlyRate)}` ||
        "0.0",
      estimateManHrs: `${project?.estimateManHrs}` || "0",
      startDate: project ? dayjs(project.startDate).tz("UTC", true).format("YYYY-MM-DD") : "",
      endDate: project ? dayjs(project.endDate).tz("UTC", true).format("YYYY-MM-DD") : "",
      status: (project && project.status) || ProjectStatus.Pending,
    },
  });

  const { control, formState, register, watch, setValue, getValues } = form;
  const { isDirty, isValid, errors } = formState;

  const clientId = watch("clientId");

  entities
    .filter((it) => it.clientId === clientId)
    .forEach((it) => {
      aircraftOpts.push({
        value: it.id,
        label:
          `${it.make ? `${it.make}${it.model ? ` ${it.model}` : ""}` : it.type} - ${
            it.tailNumber || it.serialNumber
          }` || "",
      });
    });

  clients.forEach((it) => {
    clientOpts.push({ value: it.id, label: it.name });
  });

  for (const [k, v] of Object.entries(ProjectStatus)) {
    statusOpts.push({ label: k, value: v });
  }

  useEffect(() => {
    const theClient = clients.find((it) => it.id === clientId);
    if (!theClient) return;
    setSelectedClient(theClient);
  }, [clientId]);

  useEffect(() => {
    if (selectedClient.settings?.defaultLaborHourlyRate && action == "create") {
      setValue("hourlyRate", selectedClient.settings?.defaultLaborHourlyRate);
    }
  }, [selectedClient]);

  const onSubmit = async () => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      let [errorType] = Object.keys(errors);
      const error = firstError as any;
      snackbar.notify({ icon: "warning", title: error.message });
      return;
    }

    const values = getValues();
    if (!values.clientId) {
      snackbar.notify({ icon: "warning", title: "Customer is required." });
      return;
    }
    if (!values.aircraftId) {
      snackbar.notify({ icon: "warning", title: `${tCommon("Aircraft")} is required.` });
      return;
    }
    setLoading(true);
    const clientId = values.clientId;
    const aircraftId = values.aircraftId;

    try {
      let response: any = null;
      if (action === "create") {
        if (theTemplate?.id) {
          response = await addProjectFromTemplate({
            templateId: theTemplate?.id,
            copyUsers: shouldCopyAssignedUsers,
            input: {
              ...values,
              clientId,
              aircraftId,
              hourlyRate: parseFloat(values.hourlyRate),
              estimateManHrs: values.estimateManHrs ? parseFloat(values.estimateManHrs) : 0,
              startDate: dayjs(values.startDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
              endDate: dayjs(values.endDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
            },
          });
        } else {
          response = await addProject({
            ...values,
            clientId,
            aircraftId,
            hourlyRate: parseFloat(values.hourlyRate),
            estimateManHrs: values.estimateManHrs ? parseFloat(values.estimateManHrs) : 0,
            startDate: dayjs(values.startDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
            endDate: dayjs(values.endDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
          });
        }

        if (response) {
          history.replace(`/work-orders/${response.id}/tasks`, { referrer: "/work-orders/new" });
        }
      } else if (action === "update" && project) {
        if (values.status === ProjectStatus.Completed) {
          let hasConfirmed = false;

          hasConfirmed = await dialogQueue.confirm({
            title: "Are you sure?",
            body: `Are you sure you want to complete this work order? All technicians will be unassigned and tasks marked as complete.`,
            acceptLabel: "Confirm",
            cancelLabel: "Cancel",
            renderToPortal: true,
          });

          if (!hasConfirmed) return;

          try {
            await completeProject({ projectId: project.id });
          } catch (error) {
            showErrorMessage(error);
            return;
          }
        }

        let updateMessage: string | null;
        if (
          dayjs(project.startDate).tz("UTC", true).format("YYYY-MM-DD") !== values.startDate ||
          dayjs(project.endDate).tz("UTC", true).format("YYYY-MM-DD") !== values.endDate
        ) {
          updateMessage = await dialogQueue.prompt({
            title: "Please Confirm",
            acceptLabel: "Confirm",
            body: `You're about to change the work order date(s). Please leave an optional message explaining why.`,
            cancelLabel: "Cancel",
            renderToPortal: true,
          });

          if (updateMessage === null) return;
        }

        await updateProjectv2({
          ...values,
          id: project.id,
          hourlyRate: parseFloat(values.hourlyRate),
          estimateManHrs: values.estimateManHrs ? parseFloat(values.estimateManHrs) : 0,
          startDate: dayjs(values.startDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
          endDate: dayjs(values.endDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
          updateMessage,
        } as UpdateProjectVariables);

        history.goBack();
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const setupProjectFromTemplate = () => {
    if (theTemplate?.hourlyRate) {
      setValue("hourlyRate", `${theTemplate?.hourlyRate}`, { shouldValidate: true, shouldDirty: true });
    }

    if (theTemplate?.estimateManHrs) {
      setValue("estimateManHrs", `${theTemplate?.estimateManHrs}`, { shouldValidate: true, shouldDirty: true });
    }

    if (theTemplate?.description) {
      setValue("description", theTemplate?.description, { shouldValidate: true, shouldDirty: true });
    }
  };

  const goToNextStep = async () => {
    if (formStep === 0) {
      setFormStep(1);

      setTimeout(() => {
        setupProjectFromTemplate();
      }, 500);
    } else if (formStep === 1) {
      await onSubmit();
    }
  };

  const onClose = () => {
    if (action === "update") {
      history.goBack();
    } else {
      history.replace("/work-orders");
    }
  };

  const getConfirmButtonText = () => {
    if (action === "create") {
      return formStep === FormSteps.ONE ? "Next" : "Create Work Order";
    } else {
      return "Update Work Order";
    }
  };

  const getConfirmButtonDisabled = () => {
    if (formStep === FormSteps.ONE) {
      return;
    }
    if (formStep === FormSteps.TWO) {
      return !isDirty || !isValid;
    }
  };

  const goBack = () => {
    if (action === "update") {
      history.goBack();
    } else {
      history.push({
        search: "",
      });
      setCreationType(null);
      setWhichTemplate("");
      setTheTemplate(null);
      setFormStep(FormSteps.ONE);
    }
  };

  useEffect(() => {
    if (!whichTemplate || !templateIdFromQuery) return;
    setCreationType(CreationType.TEMPLATE);
  }, [whichTemplate, templateIdFromQuery]);

  useEffect(() => {
    const [it] = templates.filter((t) => whichTemplate === t.id);

    if (it) {
      setTheTemplate(it);
    }
  }, [whichTemplate]);

  useEffect(() => {
    if (creationType === CreationType.NEW) {
      setFormStep(FormSteps.TWO);
      setWhichTemplate("");
      setTheTemplate(null);
    }
  }, [creationType]);

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>{title}</DialogTitle>

      {formStep === FormSteps.TWO && theTemplate?.name && (
        <UsingTemplateMessage>Using template &ldquo;{theTemplate?.name}&rdquo;</UsingTemplateMessage>
      )}

      <DialogContent>
        {formStep === FormSteps.ONE && (
          <>
            {!creationType && (
              <CreationActions>
                <Button raised onClick={() => setCreationType(CreationType.NEW)}>
                  Create New Work Order
                </Button>
                <Button onClick={() => setCreationType(CreationType.TEMPLATE)}>Choose a Template</Button>
              </CreationActions>
            )}
            {creationType === CreationType.TEMPLATE && (
              <ProjectFormStepOne
                whichTemplate={whichTemplate}
                setWhichTemplate={setWhichTemplate}
                shouldCopyAssignedUsers={shouldCopyAssignedUsers}
                setShouldCopyAssignedUsers={setShouldCopyAssignedUsers}
              />
            )}
          </>
        )}
        {formStep === FormSteps.TWO && (
          <>
            <CommonForm>
              <FormRow>
                <FormField>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label htmlFor="clientId" style={{ flexGrow: 1 }}>
                      Customer
                    </Label>
                    {action === "create" && !client && (
                      <>
                        <Button
                          type="button"
                          style={{ marginBottom: "0", padding: 0, height: "auto", fontSize: "12px" }}
                          onClick={() => {
                            setShowClientFormDialog(true);
                          }}
                        >
                          Add New
                        </Button>
                        {showClientFormDialog && (
                          <ClientFormDialog
                            action="create"
                            onClose={(clientId) => {
                              if (clientId) {
                                setValue("clientId", clientId, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }

                              setShowClientFormDialog(false);
                            }}
                            renderToPortal
                          />
                        )}
                      </>
                    )}
                  </div>
                  <Controller
                    name="clientId"
                    control={control}
                    as={<Select disabled={!!client} outlined options={clientOpts} />}
                  />
                </FormField>

                <FormField>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label htmlFor="aircraftId" style={{ flexGrow: 1 }}>
                      {tCommon("Aircraft")}
                    </Label>
                    {clientId && (
                      <>
                        <Button
                          type="button"
                          style={{ marginBottom: "0", padding: 0, height: "auto", fontSize: "12px" }}
                          onClick={() => {
                            // user needs to select a client first
                            if (clientId) {
                              setShowAircraftFormDialog(true);
                            }
                          }}
                        >
                          Add New
                        </Button>
                        {showAircraftFormDialog && (
                          <AircraftFormDialog
                            action="create"
                            renderToPortal
                            clientId={clientId}
                            onClose={(aircraftId) => {
                              if (aircraftId) {
                                setValue("aircraftId", aircraftId, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }

                              setShowAircraftFormDialog(false);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <Controller
                    name="aircraftId"
                    control={control}
                    disabled={aircraftOpts.length === 0}
                    as={<Select outlined placeholder="None" options={aircraftOpts} />}
                  />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField>
                  <Label htmlFor="locationId">Location</Label>
                  <Controller
                    name="locationId"
                    control={control}
                    disabled={locationOpts.length === 0}
                    as={<Select outlined options={locationOpts} />}
                  />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField>
                  <Label htmlFor="status">Status</Label>
                  <Controller name="status" control={control} as={<Select outlined options={statusOpts} />} />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField style={{ flexGrow: 1 }}>
                  <Label htmlFor="name">Work Order Name</Label>
                  <TextInput
                    name="name"
                    ref={register({ required: { value: true, message: "Work order name is required." } })}
                  />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField style={{ flexGrow: 1 }}>
                  <Label htmlFor="description">Description</Label>
                  <TextArea
                    name="description"
                    ref={register({ required: { value: true, message: "Description is required." } })}
                    style={{ marginBottom: 0 }}
                  />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField>
                  <Label htmlFor="startDate">Start Date</Label>
                  <Controller
                    name="startDate"
                    rules={{ required: true }}
                    control={control}
                    as={<TextField type="date" />}
                    ref={register({ required: { value: true, message: "Start date is required." } })}
                  />
                </FormField>

                <FormField>
                  <Label htmlFor="endDate">End Date</Label>
                  <Controller
                    name="endDate"
                    rules={{ required: true }}
                    control={control}
                    as={<TextField type="date" />}
                    ref={register({ required: { value: true, message: "End date is required." } })}
                  />
                </FormField>
              </FormRow>

              <FormRow>
                <FormField>
                  <Label htmlFor="hourlyRate">Hourly Rate</Label>
                  <TextInput
                    name="hourlyRate"
                    type="number"
                    ref={register({
                      required: { value: true, message: "Hourly rate is required." },
                      min: { value: 0, message: "Hourly rate must not be a negative number." },
                    })}
                    style={{ maxWidth: 176 }}
                  />
                </FormField>
              </FormRow>

              <TextInput name="estimateManHrs" type="number" disabled ref={register()} hidden />
            </CommonForm>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton onClick={onClose} outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        {(creationType || action === "update") && (
          <>
            <Button onClick={goBack}>Back</Button>
            <DialogButton
              raised
              disabled={getConfirmButtonDisabled() || loading}
              onClick={goToNextStep}
              data-test-id="save-btn"
              icon={loading && <LoadingIndicator style={{ color: "#fff" }} />}
            >
              {getConfirmButtonText()}
            </DialogButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
