import React, { useEffect } from "react";
import { UserShiftCalendar } from "components/usershifts/UserShiftCalendar";
import styled from "styled-components";
import dayjs from "dayjs";
import uniqBy from "lodash/uniqBy";
import Palette from "../../../../../palette.json";
import { useAdminApi } from "hooks/useAdminApi";
import { UserShiftFields } from "types/admin/UserShiftFields";
import { useParams } from "react-router-dom";
import { useTask } from "hooks/params/useTask";
import { formatHours } from "common";
import { useSettings } from "hooks/useSettings";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > * + * {
    margin-left: 1rem;
  }
`;

const InfoSideBar = styled.div`
  flex-basis: rem;
  background-color: #fff;
  margin-top: 54px;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid rgba(41, 22, 22, 0.12);
  border-top: 2px solid ${(props) => props.theme.primary};
  font-size: 16px;

  h2 {
    font-size: 24px;
    padding-left: 8px;
  }

  h3 {
    font-weight: bold;
    padding: 0 16px;
    margin: 16px 0;
  }
`;

const Divider = styled.div`
  height: 0;
  width: 100%;
  margin: 8px 0;
  border: 0.75px solid rgba(41, 22, 22, 0.12);
`;

const ScheduleWrapper = styled.div`
  flex-basis: 100%;
`;
const TimeRemainingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin: 18px 0;

  div {
    line-height: 20px;
  }
`;

const TimeLabel = styled.span<{ over: boolean }>`
  font-weight: bold;
  font-size: 36px;
  font-weight: 200;
  margin-right: 8px;
  color: ${(props) => (props.over ? Palette.Red : "black")};
`;

const PeopleList = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 16px;
  overflow-y: auto;

  > * + * {
    margin-top: 1rem;
  }
`;

const PersonLabel = styled.div<{ available: boolean }>`
  color: ${(props) => (props.available ? "black" : Palette.LightGrey)};
  &:hover {
    color: ${Palette.LightGrey};
    font-weight: ${(props) => (props.available ? 600 : 400)};
  }
  cursor: pointer;
`;

export const TaskSchedulePage: React.FC = () => {
  const { taskId } = useParams<{ projectId: string; taskId: string }>();
  const { listUserShifts, users } = useAdminApi();
  const { task } = useTask();
  const { settings } = useSettings();

  const [startDate, setStartDate] = React.useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs | null>(null);
  const [shifts, setShifts] = React.useState<UserShiftFields[]>([]);
  const [allTaskShifts, setAllTaskShifts] = React.useState<UserShiftFields[]>([]);
  const [taskUsers, setTaskUsers] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<string | null>("_all");

  const loadShifts = async (taskId: string, fromDt: dayjs.Dayjs, toDt: dayjs.Dayjs) => {
    const userShifts = await listUserShifts({ taskId, fromDt, toDt });
    setShifts(userShifts);
    let filteredUsers = userShifts.map((shift) => ({ ...shift.user, userId: shift.userId }));
    filteredUsers = uniqBy(filteredUsers, "userId");
    if (selectedUser !== "_all") {
      const currentSelectedUser = taskUsers.find((it) => it.userId === selectedUser);
      if (currentSelectedUser) filteredUsers = [currentSelectedUser, ...filteredUsers];
    }

    setTaskUsers(filteredUsers);
  };

  const onShiftAssigned = (shift: UserShiftFields) => {
    setShifts((shifts) => [...shifts, shift]);
    setAllTaskShifts((shifts) => [...shifts, shift]);
  };

  const onUpdatedShift = (userShift: UserShiftFields) => {
    setShifts((shifts) =>
      shifts.map((shift) => {
        if (shift.id === userShift.id) {
          return userShift;
        }
        return shift;
      }),
    );

    setAllTaskShifts((shifts) =>
      shifts.map((shift) => {
        if (shift.id === userShift.id) {
          return userShift;
        }
        return shift;
      }),
    );
  };

  const onDeletedShift = (id: string) => {
    setShifts((shifts) => shifts.filter((shift) => shift.id !== id));
    setAllTaskShifts((shifts) => shifts.filter((shift) => shift.id !== id));
  };

  useEffect(() => {
    if (!taskId) return;
    listUserShifts({ taskId }).then((shifts) => {
      setAllTaskShifts(shifts);
    });
  }, [taskId]);

  const totalTaskShiftHours = React.useMemo(
    () =>
      allTaskShifts.reduce((acc, cur) => {
        const start = dayjs(cur.fromDt);
        const end = dayjs(cur.toDt);
        const hours = end.diff(start, "hour", true);
        return acc + hours;
      }, 0),
    [JSON.stringify(allTaskShifts)],
  );

  const availabilities = React.useMemo(() => {
    if (!startDate || !endDate) return;
    const startOfWeek = dayjs(startDate.utc(false).format("YYYY-MM-DD")).startOf("week");
    const visibleDays = Array(7)
      .fill(0)
      .map((_, i) => startOfWeek.add(i, "day"));
    const taskStartDate = dayjs(dayjs(task.scheduledDate).utc(false).format("YYYY-MM-DD"));
    const taskEndDate = dayjs(dayjs(task.dueDate).utc(false).format("YYYY-MM-DD"));

    const availableUserIds = [];

    visibleDays.map((day) =>
      settings.shifts?.items.map((shift) => {
        return {
          shiftId: shift.id,
          users: task.users?.items.filter((taskUser) => {
            if (day.isBefore(taskStartDate)) return [];
            if (day.isAfter(taskEndDate)) return [];

            const user = users.find((it) => it.id === taskUser.userId);
            const couldWork = user?.availableShifts.items.find((it) => it.parentShiftId === shift.id);
            const overlappingShifts = shifts
              .filter((it) => {
                const shiftStart = dayjs(it.fromDt);
                const shiftEnd = dayjs(it.toDt);
                if (shiftStart.isSame(day, "day") || shiftEnd.isSame(day, "day")) {
                  return true;
                }
                return false;
              })
              .find((it) => {
                const isUser = it.userId === user.id;
                if (!isUser) {
                  return false;
                }

                const userShiftStart = dayjs(it.fromDt).format("HH:mm");
                const userShiftEnd = dayjs(it.toDt).format("HH:mm");

                if (
                  (userShiftStart >= shift.startTime && userShiftStart < shift.endTime) ||
                  (userShiftEnd > shift.startTime && userShiftEnd <= shift.endTime) ||
                  (shift.startTime >= userShiftStart && shift.startTime < userShiftEnd) ||
                  (shift.endTime > userShiftStart && shift.endTime <= userShiftEnd)
                ) {
                  return true;
                }

                return false;
              });

            const available = !!couldWork && !overlappingShifts;
            if (available) {
              if (!availableUserIds.includes(user.id)) {
                availableUserIds.push(user.id);
              }
            }

            return available;
          }),
        };
      }),
    );

    return availableUserIds;
  }, [startDate, endDate, JSON.stringify(settings.shifts?.items), JSON.stringify(shifts), JSON.stringify(users)]);

  return (
    <Wrapper>
      <InfoSideBar>
        <h2>Schedule Details</h2>
        <Divider />

        {(() => {
          const hrs = (task?.estimateManHrs || 0) - totalTaskShiftHours;
          return (
            <TimeRemainingWrapper>
              <div>Estimated hours remaining: </div>
              <TimeLabel over={hrs < 0}>{formatHours(hrs)}</TimeLabel>
            </TimeRemainingWrapper>
          );
        })()}
        <Divider />

        <h3>Available People:</h3>
        <PeopleList>
          {task?.users?.items.map((person, index) => (
            <PersonLabel
              key={index}
              available={availabilities?.includes(person.userId) || false}
              onClick={() => {
                if (!taskUsers.find((it) => it.userId === person.userId)) return;
                setSelectedUser(person.userId);
              }}
            >
              {person.user?.name || person.userId}
            </PersonLabel>
          ))}
        </PeopleList>
      </InfoSideBar>

      <ScheduleWrapper>
        <UserShiftCalendar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          shifts={shifts}
          users={taskUsers}
          loadShifts={loadShifts}
          onShiftAssigned={onShiftAssigned}
          onShiftUpdated={onUpdatedShift}
          onShiftDeleted={onDeletedShift}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </ScheduleWrapper>
    </Wrapper>
  );
};
