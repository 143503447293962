import React from "react";
import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "components/Table";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import Palette from "../../palette.json";
import { useHistory } from "react-router-dom";
import { useProject } from "hooks/params/useProject";

interface CorrectiveActionsListProps {
  correctionActions: any[];
}

export const CorrectiveActionsList: React.FC<CorrectiveActionsListProps> = ({ correctionActions }) => {
  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Corrective Actions <TitleCount>({correctionActions.length})</TitleCount>
        </TableToolbarTitle>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["Name"]} />

          <DataTableBody>
            {!correctionActions.length && (
              <DataTableRow>
                <DataTableCell style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {correctionActions.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell style={{ whiteSpace: "normal", padding: "5px 24px", width: "100%" }}>
                  {it?.message}
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
