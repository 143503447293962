import React from "react";

import { PartFields } from "types/admin/PartFields";
import { ToolFields } from "types/admin/ToolFields";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import { PartList } from "components/tasks/PartList";
import { ToolList } from "components/tasks/ToolList";
import { getCost } from "../../../../../common";
import { SubcontractorList } from "components/tasks/SubcontractorList";
import { PartType } from "types/admin/globalTypes";

export const TemplateTaskCostsPage: React.FC = () => {
  const { task } = useTask();
  const { template } = useTemplate();

  const parts = task?.taskParts?.items
    ? task?.taskParts?.items
    : template?.parts?.items
    ? template?.parts?.items
    : ([] as PartFields[]);

  const partsTotal = parts.reduce((acc, it) => {
    let quantity = it.quantity;
    // If part is serializable and has children parts, the quantity is the number of children parts
    if (it.parts.items.length > 0 && (it.type === PartType.Expendable || it.type === PartType.Rotable)) {
      quantity = it.parts.items.length;
    }
    return acc + (it?.isBillable ? getCost(it) || 0 : 0) * quantity;
  }, 0);

  const tools = task?.tools ? task?.tools : template?.tools ? template?.tools : ([] as ToolFields[]);

  const toolsTotal = tools.reduce((acc, it) => {
    return acc + (it?.isBillable ? getCost(it) || 0 : 0);
  }, 0);

  const subcontractors = task?.subcontractors?.items || template?.subcontractors?.items || [];

  const subcontractorsTotal = subcontractors.reduce((total, sub) => {
    return total + (getCost(sub) || 0);
  }, 0);

  return (
    <>
      <PartList items={parts} total={partsTotal} />
      <div style={{ margin: "2rem" }} />
      <ToolList items={tools} total={toolsTotal} />
      <div style={{ margin: "2rem" }} />
      <SubcontractorList items={subcontractors} total={subcontractorsTotal} />
    </>
  );
};
