import React from "react";
import { Button } from "./Button";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";
import styled from "styled-components";
import useMultiTenant from "hooks/useMultiTenant";
import { StringValueNode } from "graphql";

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  text-align: center;
  padding: 1rem 2rem;
  margin: 0 2rem;
  border-radius: 0.5rem;
  background-color: #f1f1f1;
  max-width: 500px;
`;

const Header = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.3rem;
`;

const Message = styled.p`
  font-size: 16px;
  line-height: 1.2;
`;

interface FullScreenMessageProps {
  icon?: string;
  header: string;
  message: string;
  subMessage?: string;
  children?: any;
}

export const FullScreenMessage: React.FC<FullScreenMessageProps> = ({
  icon,
  header,
  message,
  subMessage,
  children,
}) => {
  const { theme } = useMultiTenant();

  return (
    <PageWrapper>
      <TextWrapper>
        <Icon icon={icon} style={{ fontSize: "4rem", marginBottom: "2rem", color: theme.primary }} />
        <Header>{header}</Header>
        <Message>{message}</Message>
        {subMessage && <Message style={{ marginTop: "2.5rem", fontStyle: "italic" }}>{subMessage}</Message>}

        {children && <div>{children}</div>}
      </TextWrapper>
    </PageWrapper>
  );
};
