import gql from "graphql-tag";

export const TenantInfo = gql`
  fragment TenantInfo on Tenant {
    id
    name
    industryType
    prefix
    logoImageAttachment {
      file {
        url
      }
    }
    termsAndConditionsAgreement {
      userId
      acceptedAt
    }
    theme {
      primaryColor
      secondaryColor
    }
  }
`;

export const getTenantByPrefixQuery = gql`
  ${TenantInfo}

  query GetTenantByPrefix($prefix: String!) {
    asAnon {
      getTenantByPrefix(prefix: $prefix) {
        ...TenantInfo
      }
    }
  }
`;

export const getUserSecretLinkKeyQuery = gql`
  query GetUserSecretLinkKey($tenantId: ID!, $userId: ID!) {
    asAnon {
      getUserSecretLinkKey(tenantId: $tenantId, userId: $userId)
    }
  }
`;

export const getUserEmailQuery = gql`
  query GetUserEmail($tenantId: ID!, $userId: ID!) {
    asAnon {
      getUserEmail(tenantId: $tenantId, userId: $userId)
    }
  }
`;

export const updateUserSignatureMutation = gql`
  mutation UpdateUserSignature(
    $tenantId: ID!
    $userId: ID!
    $key: String!
    $pin: String!
    $attachment: CreateAttachmentRequest!
  ) {
    asAnon {
      updateUserSignature(tenantId: $tenantId, userId: $userId, key: $key, pin: $pin, attachment: $attachment) {
        id
        ct

        entityId
        entityType

        fileName
        fileType

        mimeType

        file {
          url
        }
      }
    }
  }
`;

export const getUploadUrlQuery = gql`
  query GetUploadUrl($mimeType: String!, $tenantId: String!) {
    asAnon {
      getUploadUrl(mimeType: $mimeType, tenantId: $tenantId) {
        attachmentId

        url

        region
        bucket
        key
      }
    }
  }
`;

export const resetUserPasswordQuery = gql`
  query ResetUserPassword($email: AWSEmail!, $tenantId: ID!) {
    asAnon {
      resetUserPassword(email: $email, tenantId: $tenantId) {
        success
      }
    }
  }
`;
