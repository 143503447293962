import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useAdminApi } from "hooks/useAdminApi";

import { snackbar } from "hooks/useNotifications";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { AssignPartInput, PartEntityType } from "types/admin/globalTypes";
import { usePart } from "hooks/params/usePart";
import { PartFields } from "types/admin/PartFields";
import { AutocompleteInput } from "../AutocompleteInput";
import _ from "lodash";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";

interface PartSerialNumberAssignFormDialogProps {
  renderToPortal?: boolean;
  onClose?: (part?: PartFields | undefined) => void;
  partId?: string | undefined;
}

export const PartSerialNumberAssignFormDialog: React.FC<PartSerialNumberAssignFormDialogProps> = ({
  renderToPortal,
  onClose: onTheClose,
}) => {
  const history = useHistory();
  const { task } = useTask();
  const { template } = useTemplate();
  const { allParts, currentPart, partId, subPartId, subSubPartId } = usePart();
  const { assignPartToParent } = useAdminApi();

  const [selectedPart, setSelectedPart] = useState<PartFields | null>(null);
  const flatParts = allParts
    .map((part) => [
      part,
      ...(part.parts?.items || []).map((subPart) => [
        subPart,
        ...(subPart.parts?.items || []).map((subSubPart) => subSubPart),
      ]),
    ])
    .flat(2);

  let parentPart = flatParts.find((it) => it.id === currentPart?.parentPartId) as PartFields | undefined;
  let sorted: {
    value: string;
    label: string;
  }[] = [];
  if (parentPart?.parts?.items) {
    sorted = parentPart.parts.items
      .filter((it) => !!it.serialNumber)
      .filter((it) => !currentPart?.parts?.items.find((p) => p.serialNumber === it.serialNumber))
      .sort((a, b) => a.serialNumber!.localeCompare(b.serialNumber!))
      .map((it) => ({
        value: it.id,
        label: it.serialNumber!,
      }));
  } else if (allParts.find((it: any) => (it.id = parentPart?.parentPartId))?.parts?.items) {
    parentPart = allParts.find((it: any) => (it.id = parentPart?.parentPartId));
    console.log(parentPart);
    sorted = parentPart!
      .parts!.items.filter((it) => !!it.serialNumber)
      .filter((it) => !currentPart?.parts?.items.find((p) => p.serialNumber === it.serialNumber))
      .sort((a, b) => a.serialNumber!.localeCompare(b.serialNumber!))
      .map((it) => ({
        value: it.id,
        label: it.serialNumber!,
      }));
  }

  const onPartSelected = (id: string) => {
    const part = parentPart?.parts?.items.find((it) => it.id === id);
    if (!part) return;
    setSelectedPart(part as PartFields);
  };

  const onSubmit = async () => {
    if (!currentPart) return;
    if (!selectedPart) {
      snackbar.notify({ title: "Select a valid serial number", icon: "error" });
      return;
    }

    let createdPart: PartFields | undefined;

    try {
      const input = {
        parentPartId: selectedPart.id,
        parentEntityType: PartEntityType.Part,
        serialNumber: selectedPart.serialNumber,
        cost: currentPart.cost,
        leadTimeDays: currentPart.leadTimeDays,
        quantity: null,
        isBillable: currentPart.isBillable,
        markupType: currentPart.markupType,
        markup: currentPart.markup,
        markupCost: currentPart.markupCost,
        manufactureDate: selectedPart.manufactureDate,
        expirationDate: selectedPart.expirationDate,
        hoursSinceOverhaul: selectedPart.hoursSinceOverhaul,
        hoursSinceBrush: selectedPart.hoursSinceBrush,
        hoursSinceBearing: selectedPart.hoursSinceBearing,
      } as AssignPartInput;

      await assignPartToParent(
        {
          parentEntityId: currentPart.id,
          input,
        },
        partId,
        subPartId,
        subSubPartId,
        task?.id,
        template?.id,
      );

      if (onTheClose) {
        onTheClose(createdPart);
      } else {
        history.goBack();
      }
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
      return;
    }
  };

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    if (onTheClose) {
      onTheClose();
      return;
    }

    if (ev.detail.action === "close") {
      history.goBack();
      return;
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss renderToPortal={renderToPortal}>
      <DialogTitle>Add New Part Serial Number</DialogTitle>

      <DialogContent style={{ minWidth: "30vw" }}>
        <AutocompleteInput
          dropdownMaxHeight={"200px"}
          placeholder="Search for a serial number in the inventory..."
          options={[...sorted] as any}
          select={(id) => onPartSelected(id)}
        />
      </DialogContent>

      <DialogActions>
        <DialogButton style={{ color: Palette.DarkGrey }} onClick={(event) => onClose(event as any)}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton raised onClick={onSubmit}>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
