import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";

import "@rmwc/icon-button/styles";
import useLayout from "hooks/useLayout";
import { PageHeader } from "components/PageHeader";
import { PageMain, PageContent } from "components/PageStyles";
import { usePart } from "hooks/params/usePart";
import * as Pages from "../../../../../../../../../..";
import { TabBar, Tab } from "@rmwc/tabs";
import { TabContainer } from "components/TabContainer";
import { DisplayFlex } from "components/Utilities";
import { AttachmentList } from "components/attachments/AttachmentList";
import { AttachmentFormDialog } from "components/attachments/AttachmentFormDialog";
import { Switch } from "react-router-dom";
import { PartsDetailView } from "components/parts/PartsDetailView";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import styled from "styled-components";

const AttachmentsListWrapper = styled.div`
  > * + * {
    margin-top: 2rem;
  }
`;

export const TemplateSubSubPartDetailPage: React.FC = () => {
  const history = useHistory();
  const { currentPart, parentPart, part, subPart, subSubPart, partId, subPartId, subSubPartId } = usePart();
  const { template, isTemplateTypeTask, templateId, getTemplate } = useTemplate();
  const { task, getTask, taskId, parentId } = useTask();
  const location = useLocation();
  const { setBreadCrumbs } = useLayout();

  const resourcePrefix = isTemplateTypeTask
    ? `/templates/${template?.id}/parts/${partId}/parts/${subPartId}/parts/${subSubPartId}`
    : `/templates/${template?.id}/tasks/${task?.id}/parts/${partId}/parts/${subPartId}/parts/${subSubPartId}`;
  const PAGE_TABS: { icon: string; label: string; path: string }[] = [];
  if (currentPart) {
    PAGE_TABS.push({
      icon: "info",
      label: "Details",
      path: `${resourcePrefix}/details`,
    });

    PAGE_TABS.push({
      icon: "upload_file",
      label: "Attachments",
      path: `${resourcePrefix}/attachments`,
    });
  }

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (isTemplateTypeTask) {
      if (!templateId) return;
      getTemplate(templateId);
    } else {
      if (!taskId) return;
      getTask(taskId);
    }
  }, [templateId, taskId]);

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!part || !subPart || !subSubPart || !template) return;

    if (!part || !subPart || !template) return;

    if (task) {
      setBreadCrumbs([
        { label: "Templates", path: "/templates" },
        { label: template.name, path: `/templates/${template.id}` },
        { label: `${task.orderIndex} - ${task.name}`, path: `/templates/${template.id}/tasks/${task.id}/parts` },
        { label: part.name, path: `/templates/${template.id}/tasks/${task.id}/parts/${part.id}/parts` },
        {
          label: subPart.name,
          path: `/templates/${template.id}/tasks/${task.id}/parts/${part.id}/parts/${subPart.id}/parts`,
        },
        { label: `SN: ${subSubPart.serialNumber}`, path: `${resourcePrefix}/parts` },
      ]);
    } else {
      setBreadCrumbs([
        { label: "Templates", path: "/templates" },
        { label: template.name, path: `/templates/${template.id}/parts` },
        { label: part.name, path: `/templates/${template.id}/parts/${part.id}/parts` },
        {
          label: subPart.name,
          path: `/templates/${template.id}/parts/${part.id}/parts/${subPart.id}/parts`,
        },
        { label: `SN: ${subSubPart.serialNumber}`, path: `${resourcePrefix}/parts` },
      ]);
    }
  }, [setBreadCrumbs, currentPart]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit")) return;
    history.replace(path);
  };

  if (!currentPart) return <Route component={Pages.Error404} />;

  return (
    <>
      <Route
        path={
          isTemplateTypeTask
            ? "/templates/:templateId/parts/:partId/parts/:subPartId/parts/:subSubPartId/attachments/new"
            : "/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/:subSubPartId/attachments/new"
        }
      >
        <AttachmentFormDialog action="create" />
      </Route>

      <PageHeader title={currentPart.name} description={currentPart.type} />

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              <DisplayFlex alignItems="center">
                <DisplayFlex>{it.label}</DisplayFlex>
              </DisplayFlex>
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            <Route
              path={
                isTemplateTypeTask
                  ? "/templates/:templateId/parts/:partId/parts/:subPartId/parts/:subSubPartId/details"
                  : "/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/:subSubPartId/details"
              }
            >
              <PartsDetailView />
            </Route>

            <Route
              path={
                isTemplateTypeTask
                  ? "/templates/:templateId/parts/:partId/parts/:subPartId/parts/:subSubPartId/attachments"
                  : "/templates/:templateId/tasks/:taskId/parts/:partId/parts/:subPartId/parts/:subSubPartId/attachments"
              }
            >
              <AttachmentsListWrapper>
                <AttachmentList type="template" items={currentPart.attachments?.items || []} />
                {(parentPart?.attachments?.items?.length || 0) > 0 && (
                  <AttachmentList type="parentPart" items={parentPart?.attachments?.items || []} />
                )}
              </AttachmentsListWrapper>
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
