import React from "react";

import { Button } from "./Button";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";
import styled from "styled-components";

const SummaryLabel = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: black;
  opacity: 0.8;
  margin-bottom: 1.5rem;
`;

const SummaryValue = styled.span`
  font-size: 30px;
  color: black;
  opacity: 0.8;
`;

const OptionButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.primary};
  border: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 1.4rem;
  display: block;
  text-align: left;
  cursor: pointer;
`;

interface SummaryCardProps {
  label: string;
  value: string;
  showBackground?: boolean;
  onButtonPress?: () => void;
}

export const SummaryCard: React.FC<SummaryCardProps> = ({ showBackground, label, value, onButtonPress }) => {
  const summaryCardStyle = {
    padding: "2rem",
    width: "11rem",
    marginRight: "2rem",
    ...((onButtonPress || showBackground) && {
      backgroundColor: "white",
      borderRadius: "4px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.12)",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3",
    }),
  };

  return (
    <div style={summaryCardStyle}>
      <SummaryLabel>{label}</SummaryLabel>
      <SummaryValue>{value}</SummaryValue>
      {onButtonPress && <OptionButton onClick={onButtonPress}>View more</OptionButton>}
    </div>
  );
};
