import React, { useEffect } from "react";
import { Route } from "react-router";
import useLayout from "hooks/useLayout";
import { useAdminApi } from "hooks/useAdminApi";
import { PageHeader } from "components/PageHeader";
import { PageContent, PageMain } from "components/PageStyles";
import { ProjectFormDialog } from "components/projects/ProjectFormDialog";
import styled from "styled-components";
import { ProjectList } from "./ProjectList";
import { ProjectStatus } from "types/admin/globalTypes";
import { InsightBlock } from "./InsightBlock";
import { usePeriodDates } from "./usePeriodDates";
import { Period } from "components/DateRangePicker";
import { PeriodSelect } from "./PeriodSelect";
import { useMorale } from "./useMorale";
import { useDiscrepancies } from "./useDiscrepancies";
import { Activity } from "./Activity";
import UtilizationChart from "./UtilizationChart";
import ProgressChart from "./ProgressChart";
import { useActiveUsers } from "./useActiveUsers";
import { Link } from "react-router-dom";
import { useTrendStats } from "./useTrendStats";
import Palette from "../../palette.json";

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 25px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
`;

const InsightWrapper = styled.div`
  display: flex;
  overflow: hidden;
  transition: all 250ms;
`;

export const DashboardPage: React.FC = () => {
  const { projects } = useAdminApi();

  const [projectStatus, setProjectStatus] = React.useState<ProjectStatus>(ProjectStatus.Accepted);
  const currentProjects = projects.filter((project) => project.status === projectStatus);

  const { setBreadCrumbs } = useLayout();
  const [period, setPeriod] = React.useState<Period | { startDate: Date; endDate: Date }>(Period.Last30Days);
  const { start, end, prevStart, prevEnd } = usePeriodDates(period);
  const { morale, prevMorale } = useMorale({ start, end, prevStart, prevEnd });
  const { discreps, actions } = useDiscrepancies({ start, end });
  const scrollRef = React.useRef<HTMLDivElement>();
  const [collapseInsights, setCollapseInsights] = React.useState<boolean>(false);

  const periodProjects = projects.filter((project) => {
    if (project.startDate >= start.toISOString() && project.startDate <= end.toISOString()) return true;
    if (project.endDate >= start.toISOString() && project.endDate <= end.toISOString()) return true;
    if (project.startDate <= start.toISOString() && project.endDate >= end.toISOString()) return true;
    return false;
  });

  const prevProjects = projects.filter((project) => {
    if (project.startDate >= prevStart.toISOString() && project.startDate <= prevEnd.toISOString()) return true;
    if (project.endDate >= prevStart.toISOString() && project.endDate <= prevEnd.toISOString()) return true;
    if (project.startDate <= prevStart.toISOString() && project.endDate >= prevEnd.toISOString()) return true;
    return false;
  });

  const { activeUserCount, prevUserAccount } = useActiveUsers({ periodProjects, prevProjects });
  const { average, target, variance } = useTrendStats({ start, end, prevStart, prevEnd });

  const projectCount = periodProjects.length;
  const behind = periodProjects.filter((it) => it?.endDate && new Date() > new Date(it?.endDate)).length || 0;
  const onTime = projectCount - behind;

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Dashboard",
        path: "/dashboard",
      },
    ]);
  }, [setBreadCrumbs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollRef.current?.scrollTop;
      setCollapseInsights(scrollTop > 25);
    };
    if (!scrollRef.current) return;
    scrollRef.current.addEventListener("scroll", handleScroll);
    return () => {
      scrollRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Route exact path="/work-orders/new">
        <ProjectFormDialog action="create" />
      </Route>

      <Route exact path="/work-orders/:projectId/edit">
        <ProjectFormDialog action="update" />
      </Route>

      <PageHeader
        title={
          <>
            Dashboard
            <PeriodSelect value={period} onChange={setPeriod} />
          </>
        }
      >
        <InsightWrapper style={{ height: collapseInsights ? 0 : 135 }}>
          <InsightBlock
            heading="Active Work Orders"
            value={periodProjects.length}
            oldValue={prevProjects.length}
            properties={[
              {
                label: "On Time",
                value: onTime,
              },
              {
                label: "Behind",
                value: behind,
              },
            ]}
          />
          <InsightBlock
            heading="Active Technicians"
            value={activeUserCount}
            oldValue={prevUserAccount}
            properties={[
              {
                label: "Discreps",
                value: discreps,
                url: `/timesheets?filter=1&fromDate=${start.format("YYYY-MM-DD")}&toDate=${end.format("YYYY-MM-DD")}`,
              },
              {
                label: "Actions",
                value: actions,
                url: `/timesheets?filter=2&fromDate=${start.format("YYYY-MM-DD")}&toDate=${end.format("YYYY-MM-DD")}`,
              },
            ]}
          />
          <Link
            to={`/morale?fromDate=${start.format("YYYY-MM-DD")}&toDate=${end.format("YYYY-MM-DD")}`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <InsightBlock heading="Morale" value={morale} oldValue={prevMorale} />
          </Link>
          <Link
            to={`/trends?fromDate=${start.format("YYYY-MM-DD")}&toDate=${end.format("YYYY-MM-DD")}`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <InsightBlock
              heading="Avg. Daily Burn" // Avg. Daily Burn across projects
              value={average?.current}
              oldValue={average?.prev}
              tooltip="Avg. hours per task per day"
              properties={[
                {
                  label: "Target Hours",
                  value: target,
                },
                {
                  label: "Variance",
                  value: variance,
                  suffix: "%",
                  color: average?.current > target ? Palette.Red : Palette.Green,
                },
              ]}
            />
          </Link>
        </InsightWrapper>
      </PageHeader>

      <PageMain ref={scrollRef}>
        <PageContent>
          <DashboardContainer>
            <div>
              <ProjectList items={currentProjects} projectStatus={projectStatus} setProjectStatus={setProjectStatus} />
              <Activity />
            </div>

            <div>
              {projectStatus !== ProjectStatus.Pending && <ProgressChart projects={currentProjects} />}
              <UtilizationChart startDate={start} endDate={end} />
            </div>
          </DashboardContainer>
        </PageContent>
      </PageMain>
    </>
  );
};
