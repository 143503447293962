import React, { useEffect } from "react";

import { DeepMap, FieldError, useForm } from "react-hook-form";

import { useAdminApi } from "hooks/useAdminApi";
import { Form, FormRow, Text, Number, Select, Toggle, Date as DateInput } from "components/Form";

import { snackbar } from "hooks/useNotifications";

import "@rmwc/dialog/styles";

import { MarkupType, PartEntityType, PartInput } from "types/admin/globalTypes";
import { usePart } from "hooks/params/usePart";
import { MARKUP_TYPE_OPTIONS } from "../../common";
import { useSettings } from "hooks/useSettings";
import dayjs from "dayjs";
import SimpleCard from "../SimpleCard";
import { Button } from "components/Button";
import { useTask } from "hooks/params/useTask";
import { useTemplate } from "hooks/params/useTemplate";
import { useAircraft } from "hooks/params/useAircraft";

interface PartFormValuesT {
  name: string;
  serialNumber: string | null;
  manufactureDate: string | null;
  expirationDate: string | null;
  hoursSinceOverhaul: number | null;
  hoursSinceBrush: number | null;
  hoursSinceBearing: number | null;
  number: string | null;
  cost: number | null;
  leadTimeDays: number | null;
  quantity: number | null;
  isBillable: boolean | null;
  markupType: MarkupType | null;
  markup: number | null;
}

export const PartsDetailView: React.FC = () => {
  const { settings } = useSettings();
  const { currentPart, isRotable, isExpendable, isConsumable, isAssembly } = usePart();
  const { task: parentTask } = useTask();
  const { template } = useTemplate();
  const { entity: aircraft } = useAircraft();
  const { updatePart } = useAdminApi();

  const rotableOrExtendable = isRotable || isExpendable;
  const isChildWithSerialNumber = currentPart?.parentEntityType === PartEntityType.Part;

  const form = useForm<PartFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      name: currentPart?.name || "",
      serialNumber: currentPart?.serialNumber,
      manufactureDate: dayjs(currentPart?.manufactureDate).tz("UTC", true).format("YYYY-MM-DD"),
      expirationDate: dayjs(currentPart?.expirationDate).tz("UTC", true).format("YYYY-MM-DD"),
      hoursSinceOverhaul: currentPart?.hoursSinceOverhaul,
      hoursSinceBrush: currentPart?.hoursSinceBrush,
      hoursSinceBearing: currentPart?.hoursSinceBearing,
      number: currentPart?.number,
      cost: currentPart?.cost,
      leadTimeDays: currentPart?.leadTimeDays,
      quantity: currentPart?.quantity,
      isBillable: currentPart?.isBillable,
      markupType: currentPart?.markupType,
      markup: currentPart?.markup,
    },
  });

  const { formState, setValue, watch } = form;
  const { errors } = formState;

  const isBillable = watch("isBillable");
  const markupType = watch("markupType");

  useEffect(() => {
    if (!setValue || !currentPart) return;

    setValue("name", currentPart.name);
    setValue("serialNumber", currentPart.serialNumber);
    setValue("manufactureDate", dayjs(currentPart?.manufactureDate).tz("UTC", true).format("YYYY-MM-DD"));
    setValue("expirationDate", dayjs(currentPart?.expirationDate).tz("UTC", true).format("YYYY-MM-DD"));
    setValue("hoursSinceOverhaul", currentPart.hoursSinceOverhaul);
    setValue("hoursSinceBrush", currentPart.hoursSinceBrush);
    setValue("hoursSinceBearing", currentPart.hoursSinceBearing);
    setValue("number", currentPart.number);
    setValue("cost", currentPart.cost);
    setValue("leadTimeDays", currentPart.leadTimeDays);
    setValue("quantity", currentPart.quantity);
    setValue("isBillable", currentPart.isBillable);
    setValue("markupType", currentPart.markupType);
    setValue("markup", currentPart.markup);
  }, [setValue, currentPart]);

  useEffect(() => {
    if (markupType === MarkupType.percentage) {
      setValue("markup", currentPart?.markup ?? (settings.defaultPartMarkup || 0));
    } else {
      if (currentPart?.markupType === MarkupType.dollar) {
        setValue("markup", currentPart.markup || 0);
      } else {
        setValue("markup", 0);
      }
    }
  }, [settings.defaultPartMarkup, markupType]);

  const showFormErrors = (errors: DeepMap<PartFormValuesT, FieldError>) => {
    if (Object.keys(errors)?.length > 0) {
      const [firstError] = Object.values(errors);
      // eslint-disable-next-line prefer-const
      const error = firstError as any;
      snackbar.notify({ icon: "warning", title: error.message });
      return true;
    }
    return false;
  };

  const onInvalid = (errors: any) => {
    showFormErrors(errors);
  };

  const onSubmit = async (values: PartFormValuesT) => {
    if (showFormErrors(errors)) return;

    if (!currentPart) return;

    // Set unused values to null
    let allValues: any = {
      ...values,
      expirationDate: null as any,
      manufactureDate: null as any,
      hoursSinceOverhaul: null as any,
      hoursSinceBrush: null as any,
      hoursSinceBearing: null as any,
    };

    if (rotableOrExtendable && isChildWithSerialNumber) {
      // Checking that dates are valid
      const regex = /\d{4}-\d{1,2}-\d{1,2}/;
      if ((values.manufactureDate && !values.manufactureDate.match(regex)) || !values.manufactureDate) {
        snackbar.notify({ icon: "warning", title: "Invalid manufacture date." });
        return;
      }

      if ((values.expirationDate && !values.expirationDate.match(regex)) || !values.expirationDate) {
        snackbar.notify({ icon: "warning", title: "Invalid expiration date." });
        return;
      }

      if (new Date(values.expirationDate) < new Date(values.manufactureDate)) {
        snackbar.notify({ icon: "warning", title: "Expiration date must be after manufacture date." });
        return;
      }

      // Set unused values to null
      allValues = {
        ...values,
        manufactureDate: dayjs(values.manufactureDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
        expirationDate: dayjs(values.expirationDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
        quantity: null,
      };
    }

    allValues = {
      ...allValues,
    };

    try {
      await updatePart(
        {
          id: currentPart.id,
          parentEntityId: currentPart.parentEntityId,
          input: allValues as PartInput,
        },
        parentTask?.id,
        template?.id,
      );
    } catch (err) {
      const error = err as Error;
      snackbar.notify({ title: error.message, icon: "error" });
      return;
    }

    snackbar.notify({ title: "Part successfully updated" });
  };

  const validateInt = (value: number) => {
    return value >= 0 || `Cannot be negative`;
  };

  return (
    <div style={{ maxWidth: 900 }}>
      <Form form={form} onSubmit={onSubmit} onInvalid={onInvalid}>
        <SimpleCard heading="General Information">
          <FormRow>
            <Text
              name="name"
              label="Name"
              required={!currentPart?.parentPartId}
              readOnly={!!currentPart?.parentPartId || aircraft}
            />
            <Text
              name="number"
              label="Number"
              required={!currentPart?.parentPartId}
              readOnly={!!currentPart?.parentPartId || aircraft}
            />
          </FormRow>

          {(isAssembly || (rotableOrExtendable && isChildWithSerialNumber)) && (
            <FormRow>
              <Text
                name="serialNumber"
                label="Serial Number"
                required={!rotableOrExtendable || !isChildWithSerialNumber}
                readOnly={(rotableOrExtendable && isChildWithSerialNumber) || aircraft}
              />
            </FormRow>
          )}

          {!(rotableOrExtendable && isChildWithSerialNumber) && (
            <FormRow>
              <Number
                name="leadTimeDays"
                label="Lead Time (Days)"
                validate={validateInt}
                readOnly={!!aircraft}
                required
              />
            </FormRow>
          )}
        </SimpleCard>

        {(rotableOrExtendable || isConsumable) && !isChildWithSerialNumber && (
          <SimpleCard heading="Cost">
            <FormRow>
              <Number name="cost" label="Cost" validate={validateInt} readOnly={!!aircraft} required />
              <Toggle
                name="isBillable"
                label="Billable?"
                defaultValue={currentPart?.isBillable || false}
                disabled={!!aircraft}
                onChange={(evt: any) => setValue("isBillable", !!evt.currentTarget.checked)}
              />
            </FormRow>

            <FormRow>
              <Select
                outlined
                name="markupType"
                label="Markup Type"
                options={MARKUP_TYPE_OPTIONS}
                disabled={!isBillable || aircraft}
                defaultValue={MarkupType.percentage}
              />
              <Number
                name="markup"
                label="Markup"
                validate={validateInt}
                readOnly={!!aircraft}
                required
                disabled={!isBillable}
              />
            </FormRow>
          </SimpleCard>
        )}

        {isConsumable && (
          <SimpleCard heading="Part Details">
            <FormRow>
              <Number name="quantity" label="Quantity" readOnly={!!aircraft} validate={validateInt} required />
            </FormRow>
          </SimpleCard>
        )}

        {rotableOrExtendable && isChildWithSerialNumber && (
          <SimpleCard heading="Inventory">
            <FormRow>
              <DateInput
                name="manufactureDate"
                label="Date of Manufacture"
                required={currentPart?.parentEntityId === currentPart?.parentPartId}
                readOnly={currentPart?.parentEntityId !== currentPart?.parentPartId || aircraft}
              />
              <DateInput
                name="expirationDate"
                label="Expiration Date"
                required={currentPart?.parentEntityId === currentPart?.parentPartId}
                readOnly={currentPart?.parentEntityId !== currentPart?.parentPartId || aircraft}
              />
            </FormRow>
            {isRotable && (
              <>
                <FormRow>
                  <Number
                    name="hoursSinceBrush"
                    label="Hours Since Brush"
                    validate={validateInt}
                    required={currentPart?.parentEntityId === currentPart?.parentPartId}
                    readOnly={currentPart?.parentEntityId !== currentPart?.parentPartId || aircraft}
                  />
                  <Number
                    name="hoursSinceBearing"
                    label="Hours Since Bearing"
                    validate={validateInt}
                    required={currentPart?.parentEntityId === currentPart?.parentPartId}
                    readOnly={currentPart?.parentEntityId !== currentPart?.parentPartId || aircraft}
                  />
                </FormRow>

                <FormRow>
                  <Number
                    name="hoursSinceOverhaul"
                    label="Hours Since Overhaul"
                    validate={validateInt}
                    required={currentPart?.parentEntityId === currentPart?.parentPartId}
                    readOnly={currentPart?.parentEntityId !== currentPart?.parentPartId || aircraft}
                  />
                </FormRow>
              </>
            )}
          </SimpleCard>
        )}

        {!aircraft && (
          <FormRow>
            <Button raised>Save Part</Button>
          </FormRow>
        )}
      </Form>
    </div>
  );
};
