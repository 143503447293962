import * as React from "react";
import { Button } from "components/Button";
import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";
import styled from "styled-components";

const Container = styled.div`
  button {
    min-width: unset !important;
  }
`;

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export const ReactionSelect = ({ value, onChange }: Props) => {
  const options = ["All", "🎉", "👍", "👎"];

  return (
    <Container>
      <SimpleMenu
        anchorCorner="bottomStart"
        focusOnOpen={false}
        handle={
          <Button outlined trailingIcon="unfold_more">
            {options[value]}
          </Button>
        }
        onSelect={(ev) => onChange(ev.detail.index)}
      >
        {options.map((it, i) => (
          <MenuItem key={i}>{it}</MenuItem>
        ))}
      </SimpleMenu>
    </Container>
  );
};
