import gql from "graphql-tag";

export const UserShiftFields = gql`
  fragment UserShiftFields on UserShift {
    id
    userId
    taskId
    projectId
    fromDt
    toDt

    user {
      firstName
      lastName
    }
  }
`;

export const getShiftQuery = gql`
  ${UserShiftFields}

  query GetUserShift($id: ID!, $userId: ID!) {
    asAdmin {
      getUserShift(id: $id, userId: $userId) {
        ...UserShiftFields
      }
    }
  }
`;

export const listShiftsQuery = gql`
  ${UserShiftFields}

  query ListUserShifts(
    $userId: ID
    $taskId: ID
    $projectId: ID
    $fromDt: AWSDateTime
    $toDt: AWSDateTime
    $limit: Int
    $nextToken: String
    $scanIndexForward: Boolean
  ) {
    asAdmin {
      listUserShifts(
        userId: $userId
        taskId: $taskId
        projectId: $projectId
        fromDt: $fromDt
        toDt: $toDt
        limit: $limit
        nextToken: $nextToken
        scanIndexForward: $scanIndexForward
      ) {
        items {
          ...UserShiftFields
        }
        nextToken
      }
    }
  }
`;

export const addUserShiftMutation = gql`
  ${UserShiftFields}

  mutation AddUserShift($input: CreateUserShiftRequest!) {
    asAdmin {
      addUserShift(input: $input) {
        ...UserShiftFields
      }
    }
  }
`;

export const updateUserShiftMutation = gql`
  ${UserShiftFields}

  mutation UpdateUserShift($id: ID!, $input: UpdateUserShiftRequest!) {
    asAdmin {
      updateUserShift(id: $id, input: $input) {
        ...UserShiftFields
      }
    }
  }
`;

export const deleteUserShiftMutation = gql`
  ${UserShiftFields}
  mutation DeleteUserShift($id: ID!, $userId: ID!) {
    asAdmin {
      deleteUserShift(id: $id, userId: $userId) {
        ...UserShiftFields
      }
    }
  }
`;
