import React from "react";

import { Button } from "./Button";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import Palette from "../palette.json";
import { CircularProgress } from "@rmwc/circular-progress";

interface CompleteTaskButtonProps {
  onClick: () => void;
  loading: boolean;
}

export const CompleteTaskButton: React.FC<CompleteTaskButtonProps> = ({ onClick, loading }) => {
  return (
    <Button raised onClick={onClick} data-test-id="btn-complete-project" style={{ padding: "10px", marginRight: 0 }}>
      <span style={{ display: "flex", alignItems: "center" }}>
        {!loading ? (
          <Icon icon="done_outline" style={{ marginRight: 8 }} />
        ) : (
          <CircularProgress
            className="loadingIndicator"
            style={{ marginRight: "0.5rem", color: "white" }}
            {...{ progress: 0.25 }}
          />
        )}
        Complete Task
      </span>
    </Button>
  );
};
