import React, { useEffect, useState } from "react";
import validator from "validator";
import { useHistory, useParams } from "react-router";

import { useTemplate } from "hooks/params/useTemplate";
import generateEstimate from "../../utilities/estimateGenerator";
import { useForm } from "react-hook-form";
import { useAdminApi } from "hooks/useAdminApi";

import { Label } from "../Label";
import { Dialog } from "../Dialog";
import { Form, FormRow, Text, Phone, CountryCode, Date, Textarea } from "components/Form";

import logo from "../../assets/images/foxtrot_pdf_logo.png";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogActions, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";

import "@rmwc/checkbox/styles";
import { Divider } from "../../pages/signin";
import useMultiTenant from "hooks/useMultiTenant";
import dayjs from "dayjs";
import { BreakdownCostsCheckbox } from "../BreakdownCostsCheckbox";
import { useSettings } from "hooks/useSettings";
import { ShowItemizedEstimateCheckbox } from "../ShowItemizedEstimateCheckbox";
import useTranslations from "hooks/useTranslations";

interface ProjectTemplateEstimateFormDialogProps {
  placeholder?: string;
}

export interface ProjectTemplateEstimateFormValues {
  companyName: string;
  companyAddress?: string;
  companyPhone?: string;
  companyCountryCode?: string;
  companyEmail?: string;
  customerName?: string;
  customerAddress?: string;
  date: string;
  dueDate?: string;
  terms?: string;
  tailNumber?: string;
  poNumber?: string;
  airport?: string;

  bottomTerms?: string;
}

export const ProjectTemplateEstimateFormDialog: React.FC<ProjectTemplateEstimateFormDialogProps> = () => {
  const { tCommon } = useTranslations();
  const { templateId } = useParams<{ projectId?: string; templateId?: string }>();

  const history = useHistory();
  const { template } = useTemplate();
  const { settings } = useSettings();

  const form = useForm<ProjectTemplateEstimateFormValues>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      companyName: "",
      companyAddress: "",
      companyCountryCode: settings?.phoneNumber
        ? settings.phoneNumber.replace(/^(\+\d{1,2})(\d{3})(\d{3})(\d{4})$/, "$1")
        : "+1",
      companyPhone: settings?.phoneNumber ? settings.phoneNumber.slice(-10) : "",
      companyEmail: "",
      customerName: "",
      customerAddress: "",
      date: dayjs().tz("UTC", true).format("YYYY-MM-DD"),
      terms: "",
      tailNumber: "",
      poNumber: "",
      airport: "",
    },
  });

  const { watch, setValue, clearErrors, setError } = form;

  const [breakdownCosts, setBreakdownCosts] = useState(false);
  const [showItemizedEstimateCheckboxIsChecked, setShowItemizedEstimateCheckboxIsChecked] = useState(false);

  const watchEmail = watch("companyEmail");

  const { tenantInfo } = useMultiTenant();
  const { tasks: allTasks, incrementTemplateEstimateCount, getTenantSettings, getTemplateTasks } = useAdminApi();
  const templateTasks = allTasks.filter((it) => it?.parentId === template?.id);

  const getTerms = async () => {
    const settings = await getTenantSettings();
    form.setValue("bottomTerms", settings.terms);
  };

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    if (!settings.name) return;

    console.log("settings", settings, settings?.phoneNumber?.slice(-10));
    setValue("companyName", settings.name, { shouldDirty: true, shouldValidate: true });
    setValue("companyEmail", settings.email, { shouldDirty: true, shouldValidate: true });
    setValue("companyPhone", settings?.phoneNumber?.slice(-10), { shouldDirty: true, shouldValidate: true });
    setValue(
      "companyAddress",
      `${
        settings?.address?.street2
          ? `${settings?.address?.street1}\n${settings?.address?.street2}`
          : settings?.address?.street1
      }\n${settings?.address?.city}, ${settings?.address?.state} ${settings?.address?.zip}`,
      { shouldDirty: true, shouldValidate: true },
    );
  }, [settings]);

  useEffect(() => {
    if ((watchEmail ?? []).length === 0) {
      clearErrors("companyEmail");
      return;
    }
    if (validator.isEmail(watchEmail ?? "")) {
      setValue("companyEmail", watchEmail, { shouldDirty: true, shouldValidate: true });
    } else {
      setError("companyEmail", {
        type: "manual",
        message: "Invalid email",
      });
    }
  }, [watchEmail]);

  const generateRandomId = (length = 10) => {
    let result = "";
    const pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const poolLength = pool.length;
    for (let i = 0; i < length; i++) {
      result += pool.charAt(Math.floor(Math.random() * poolLength));
    }
    return result;
  };

  const onSubmit = form.handleSubmit(async (values: ProjectTemplateEstimateFormValues) => {
    let estimateNumber: string;
    try {
      estimateNumber = (await incrementTemplateEstimateCount()).toString();
    } catch (err) {
      const error = err as Error;
      console.error(error);
      estimateNumber = generateRandomId();
    }
    values = { ...values, companyPhone: `${values.companyCountryCode}${values.companyPhone}` };

    try {
      // This updates the list of tasks to most current
      await getTemplateTasks(templateId, null);
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }

    generateEstimate(
      values,
      template?.name ?? "",
      tenantInfo?.logoImageAttachment?.file.url,
      templateTasks,
      logo,
      estimateNumber,
      breakdownCosts,
      showItemizedEstimateCheckboxIsChecked,
      () => {
        console.log("Generating estimate...");
        history.push(`/templates/${templateId}`);
      },
    );
  });

  return (
    <>
      <div style={{ zIndex: 99 }}>
        <Dialog open preventOutsideDismiss>
          <DialogTitle>Generate Estimate from Template</DialogTitle>

          <DialogContent style={{ width: "50vw" }}>
            <Form form={form} onSubmit={onSubmit}>
              <FormRow col={2}>
                <Text name="companyName" label="Company Name" required />
                <Text name="companyEmail" label="Company Email" required />
              </FormRow>
              <FormRow style={{ display: "block" }}>
                <Label htmlFor="companyPhone">Company Phone Number</Label>
                <div style={{ display: "flex", maxWidth: "200px" }}>
                  <CountryCode name="companyCountryCode" required />
                  <Phone name="companyPhone" required />
                </div>
              </FormRow>
              <FormRow>
                <Textarea name="companyAddress" label="Company Address" required />
              </FormRow>
              <Divider />
              <FormRow>
                <Text name="customerName" label="Customer Name" required />
              </FormRow>
              <FormRow>
                <Textarea name="customerAddress" label="Customer Address" required />
              </FormRow>
              <Divider />
              <FormRow>
                <Date name="date" label="Date" required />
                <Date name="dueDate" label="Due Date" required />
              </FormRow>
              <FormRow>
                <Text name="terms" label="Terms" required />
                <Text name="tailNumber" label={tCommon("TailNumber")} />
              </FormRow>
              <FormRow>
                <Text name="airport" label="Airport" required />
                <Text name="poNumber" label="PO Number" />
              </FormRow>
              <FormRow style={{ display: "block" }}>
                <Textarea name="bottomTerms" label="Terms and Conditions" style={{ marginBottom: "5px" }} />
                <Label style={{ textTransform: "none", fontSize: "13px", fontWeight: 400, lineHeight: 1.5 }}>
                  Changing the text here will only affect this estimate and will not be changed for any other admin
                  users.
                </Label>
              </FormRow>
            </Form>
          </DialogContent>

          <DialogActions>
            <ThemeProvider options={{ primary: Palette.MediumGrey }}>
              <DialogButton onClick={history.goBack} outlined>
                Cancel
              </DialogButton>
            </ThemeProvider>

            <div style={{ flexGrow: 1 }} />

            <ShowItemizedEstimateCheckbox
              checked={showItemizedEstimateCheckboxIsChecked}
              setChecked={setShowItemizedEstimateCheckboxIsChecked}
            />

            <BreakdownCostsCheckbox checked={breakdownCosts} setChecked={setBreakdownCosts} />

            <DialogButton onClick={onSubmit} raised>
              Generate Estimate
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
